import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Directive({
	selector: '[n2pShowHide]',
})
export class ShowHideDirective implements OnDestroy {
	private hasView: boolean = false;
	private sub: Subscription;
	private _n2pShowHide: Observable<boolean> | boolean;

	@Input() set n2pShowHide(value: Observable<boolean> | boolean) {
		this._n2pShowHide = value;
		this.updateView();
	}

	constructor(protected templateRef: TemplateRef<any>, protected viewContainer: ViewContainerRef) {}

	ngOnDestroy(): void {
		this.unsubscribe();
	}

	private unsubscribe(): void {
		this.sub && this.sub.unsubscribe();
	}

	private updateView(): void {
		this.unsubscribe();

		if (typeof this._n2pShowHide === 'boolean') {
			this.toggleViewIfNeed(this._n2pShowHide);
		} else {
			this.sub = this._n2pShowHide.subscribe(condition => this.toggleViewIfNeed(condition));
		}
	}

	private toggleViewIfNeed(condition: boolean): void {
		if (condition && !this.hasView) {
			this.viewContainer.createEmbeddedView(this.templateRef);
			this.hasView = true;
		} else if (!condition && this.hasView) {
			this.viewContainer.clear();
			this.hasView = false;
		}
	}
}
