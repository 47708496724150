import { Component, Inject } from '@angular/core';

import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { IFailoverPhoneNumber } from '@app/services/web-apis/phone-numbers-failover/api-phone-numbers-failover.domain';

@Component({
	selector: 'app-delete-failover',
	templateUrl: './delete-failover.component.html',
	styleUrls: ['./delete-failover.component.scss'],
})
export class DeleteFailoverComponent {
	failoverInfo: IFailoverPhoneNumber;

	constructor(
		@Inject(DIALOG_DATA) private failover: IFailoverPhoneNumber,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
	) {
		this.failoverInfo = failover;
	}

	close(): void {
		this.dialogRef.complete();
	}

	delete(): void {
		this.dialogRef.dismiss(true);
		this.close();
	}
}
