import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TemplatedPopupComponent } from './templated-popup.component';
import { SvgModule } from '@n2p';
import { EscapeHandlerModule } from '@app/directives/escape-handler';

@NgModule({
	declarations: [TemplatedPopupComponent],
	exports: [TemplatedPopupComponent],
	imports: [CommonModule, SvgModule, EscapeHandlerModule],
})
export class TemplatedPopupModule {}
