import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Data, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';

import { ClaimType } from '@app/Common';
import { JwtService } from '@app/services';

@Injectable({
	providedIn: 'root',
})
export class ClaimGuard implements CanLoad, CanActivate {
	constructor(private jwtService: JwtService, private router: Router) {}

	canLoad(route: Route): Observable<boolean> {
		return this.check(route.data);
	}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		return this.check(route.data);
	}

	private check(data: Data): Observable<boolean> {
		const claimType: ClaimType = data.claimType;

		if (!(claimType in this.ClaimMap)) {
			throw Error(`The type ${claimType} doesn't have a mapping into the claim type`);
		}

		const source$: Observable<boolean> = this.ClaimMap[claimType]();

		return source$.map(result => {
			if (!result) this.router.navigateByUrl(data.redirectUrl || '');

			return result;
		});
	}

	private ClaimMap = {
		// Impersonation is not allowed
		[ClaimType.DOES_NOT_HAVE_IMPERSONATE_CLAIM]: () => of<boolean>(!this.jwtService.isImpersonated()),
	};
}
