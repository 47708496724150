import { Component, Inject, OnInit } from '@angular/core';

import { MULTISELECT_CHIP, MULTISELECT_CHIP_CLICK_CALLBACK } from 'utils/constants/injectortokens';

// a generic chip component for screens that don't need anything special
@Component({
	selector: 'app-chip',
	templateUrl: './chip.component.html',
	styleUrls: ['./chip.component.scss'],
})
export class ChipComponent implements OnInit {
	chip: any;
	callback: any;
	text = '';
	imgUrl: string;
	constructor(@Inject(MULTISELECT_CHIP) chip: any, @Inject(MULTISELECT_CHIP_CLICK_CALLBACK) callback: any) {
		this.chip = chip;
		this.callback = callback;
		if (this.chip.type === 'tm') {
			if (this.chip.userInfo) {
				this.text = this.chip.userInfo.name;
			} else if (this.chip.name) {
				this.text = this.chip.name;
			} else {
				this.text = `${this.chip.firstName} ${this.chip.lastName}`;
			}

			if (this.chip.userInfo) {
				this.imgUrl = this.chip.userInfo.avatars[1];
			} else if (this.chip.avatars) {
				this.imgUrl = this.chip.avatars[1];
			}
		} else if (this.chip.type === 'd') {
			this.text = `${this.chip.name}`;
		} else if (this.chip.type === 'r') {
			this.text = `${this.chip.name}`;
		} else if (this.chip.type === 'ext') {
			this.text = this.chip.number;
		} else if (this.chip.type === 'txt') {
			this.text = this.chip.name;
		} else if (this.chip.type === 'empty') {
			this.text = this.chip.name;
		}
	}

	close = () => {
		this.callback(this.chip);
	};
	ngOnInit() {}
}
