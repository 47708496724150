import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

import { PhoneNumberFormatterPipe } from '@app/pipes';
import { PhoneNumbersResultsComponent } from '../phone-numbers-results/phone-numbers-results.component';
import {
	PHONE_NUMBERS_DATA,
	PHONE_NUMBERS_RESULT,
	PHONE_NUMBERS_SELETED,
	PHONE_NUMBERS_SINGLE_SELECT,
} from 'utils/constants/injectortokens';
import { IPhone } from '@app/Common/interfaces/IPhone';

@Component({
	selector: 'app-search-phone-number-input',
	templateUrl: './search-phone-number-input.component.html',
	styleUrls: ['./search-phone-number-input.component.scss'],
	providers: [PhoneNumberFormatterPipe],
})
export class SearchPhoneNumberInputComponent implements OnInit {
	searchText: string;
	@Input() disabled: boolean;
	@Input() singleSelection = false;
	@Input() label: string;
	@Input() selectedPhoneNumbers: Array<IPhone> = [];
	@Input() totalAvailable = 10;
	reducedSelected: any;
	@Output() clickedCallback: EventEmitter<any> = new EventEmitter();
	@Output() checkValue: EventEmitter<any> = new EventEmitter();
	@ViewChild('searchPhoneNumbersRef', { static: true }) searchPhoneNumbersRef: ElementRef;
	overlayRef: OverlayRef;
	timeout: any;
	phone: IPhone = { number: '' };

	constructor(
		private injector: Injector,
		private overlay: Overlay,
		private phoneNumberFormatterPipe: PhoneNumberFormatterPipe,
	) {}

	// 4 - is to check if we have at least codes (e.g. 1 732)
	isValueLengthValid(): void {
		this.checkValue.emit(this.searchText.length > 4);
	}

	searchPhoneNumbers(): void {
		clearTimeout(this.timeout);
		this.overlayRef.detach();
		this.timeout = setTimeout(() => {
			if (!this.searchText) {
				return this.overlayRef.detach();
			}

			const portalRef = new ComponentPortal(
				PhoneNumbersResultsComponent,
				undefined,
				this.createInjector(this.searchText),
			);
			this.overlayRef.attach(portalRef);
			clearTimeout(this.timeout);
			this.timeout = undefined;
		}, 500);
	}

	focused(): void {
		this.searchPhoneNumbers();
	}

	ngOnInit(): void {
		this.createOverlayRef();
	}

	private createInjector(data): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(PHONE_NUMBERS_RESULT, data);
		injectorTokens.set(PHONE_NUMBERS_SELETED, this.selectedCallback);
		injectorTokens.set(PHONE_NUMBERS_DATA, {
			selected: this.selectedPhoneNumbers,
			totalAvailable: this.totalAvailable,
		});
		injectorTokens.set(PHONE_NUMBERS_SINGLE_SELECT, this.singleSelection);

		return new PortalInjector(this.injector, injectorTokens);
	}

	private selectedCallback = (selected: IPhone) => {
		const index = this.selectedPhoneNumbers.findIndex(i => i.number === selected.number);
		if (index === -1) {
			if (!this.singleSelection) {
				this.selectedPhoneNumbers.push(selected);
			} else {
				this.selectedPhoneNumbers[0] = selected;
			}
		} else {
			this.selectedPhoneNumbers.splice(index, 1);
		}

		this.reducedSelected = this.selectedPhoneNumbers.reduce((accumulator, currentValue) => {
			const code = currentValue.number.substr(1, 3);
			(accumulator[code] = accumulator[code] || []).push(currentValue);

			return accumulator;
		}, {});

		if (this.singleSelection) {
			if (!this.selectedPhoneNumbers[0] && this.phone) {
				this.phone.number = this.phone.number.substr(1, 3);
			} else {
				this.phone = this.selectedPhoneNumbers[0];
			}
			this.overlayRef.detach();
		}
		this.clickedCallback.emit(this.reducedSelected);

		if (this.totalAvailable === 1 && this.selectedPhoneNumbers.length) {
			this.searchText = this.phoneNumberFormatterPipe.transform({ number: this.selectedPhoneNumbers[0].number });
		}

		if (this.selectedPhoneNumbers.length >= this.totalAvailable) {
			return this.overlayRef.detach();
		}

		setTimeout(() => {
			this.overlayRef.updatePosition();
		});
	};

	private createOverlayRef(): void {
		setTimeout(() => {
			this.overlayRef = this.overlay.create({
				width: this.searchPhoneNumbersRef.nativeElement.offsetWidth,
				hasBackdrop: true,
				backdropClass: 'multi-select-dropdown-data',
				positionStrategy: this.overlay
					.position()
					.connectedTo(
						this.searchPhoneNumbersRef,
						{
							originY: 'bottom',
							originX: 'start',
						},
						{
							overlayY: 'top',
							overlayX: 'start',
						},
					)
					.withOffsetY(3),
				scrollStrategy: this.overlay.scrollStrategies.reposition(),
			});

			this.overlayRef.backdropClick().subscribe(() => {
				this.overlayRef.detach();
			});
		});
	}
}
