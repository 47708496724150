import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReceptionistConsoleComponent } from './receptionist-console.component';
import { ReceptionistConsoleRoutingModule } from '@app/pages/receptionist-console/receptionist-console-routing.module';
import { FaviconsService } from '@app/services/favicons/favicons.service';

@NgModule({
	providers: [FaviconsService],
	declarations: [ReceptionistConsoleComponent],
	exports: [ReceptionistConsoleComponent],
	imports: [CommonModule, ReceptionistConsoleRoutingModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReceptionistConsoleModule {}
