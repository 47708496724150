import { Injectable } from '@angular/core';

import { IGAEvent, IGtagCustomDimensions, IGtagInternalEvent } from './google-analytics.domain';
import { SET_CUSTOM_FIELDS } from './google-analytics.constants';

// this way eventLabel is either
// 1) event label
// 2) event label - type
const DELIM: string = ' - ';

declare var gtag: Function;

@Injectable({
	providedIn: 'root',
})
export class GoogleAnalyticsService {
	private readonly GA_TRACKING_ID: string = 'G-8GDETBC12F';
	private readonly CUSTOM_MAP: object = {
		custom_map: {
			dimension1: 'impersonate',
			dimension2: 'userType',
			dimension3: 'userRole',
			dimension4: 'companyId',
			dimension5: 'companyName',
			dimension6: 'userId',
			dimension7: 'tenantId',
			dimension8: 'uniteCountry',
			dimension9: 'browserId',
		},
	};

	private currentCustomDimensions: Partial<IGtagCustomDimensions> = {};

	constructor() {
		this.gtagConfig(this.CUSTOM_MAP);
	}

	event(gaEvent: IGAEvent, label?: string | number, value?: number): void {
		const event: IGtagInternalEvent = {
			event_category: gaEvent.category,
		};
		// build event label
		if (gaEvent.label || label) {
			const a: string = gaEvent.label || '';
			const b: string = a && label ? DELIM : '';
			const c: string = label ? label.toString() : '';

			if (a || b || c) {
				event.event_label = `${a}${b}${c}`;
			}
		}
		// value
		if (value) {
			event.value = value;
		}

		this.gtagEvent(gaEvent.name, event);
	}

	customDimensions(dimensions: IGtagCustomDimensions): void {
		this.currentCustomDimensions = dimensions;
		// Setting custom variables with dataLayer.push call
		gtag(dimensions);
		// GA4 - https://developers.google.com/analytics/devguides/collection/ga4/user-id?client_type=gtag
		this.gtagConfig({ user_id: `${dimensions.userId}` });
		this.gtagEvent(SET_CUSTOM_FIELDS, dimensions);
		this.gtagSet({ user_id: `${dimensions.userId}` });
	}

	setPath(page_path: string): void {
		this.gtagConfig({ page_path });
	}

	private gtagEvent(name: string, params: IGtagInternalEvent | object): void {
		gtag('event', name, {
			...(this.currentCustomDimensions || {}),
			...(params || {}),
		});
	}

	private gtagSet(dimension: object): void {
		gtag('set', dimension);
	}

	private gtagConfig(params: object): void {
		gtag('config', this.GA_TRACKING_ID, params);
	}
}
