import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LineInputComponent } from './line-input.component';

@NgModule({
	imports: [CommonModule],
	declarations: [LineInputComponent],
	exports: [LineInputComponent],
})
export class LineInputModule {}
