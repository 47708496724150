import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared/shared.module';
import { WebApisModule } from '@app/services/web-apis.module';
import { EditVoicemailComponent } from './edit-voicemail.component';
import { EditVoicemailService } from './edit-voicemail.service';
import { AudioPlayerModule } from '@n2p/audio-player';

@NgModule({
	declarations: [EditVoicemailComponent],
	providers: [EditVoicemailService],
	imports: [CommonModule, WebApisModule, SharedModule, ReactiveFormsModule, AudioPlayerModule],
	exports: [EditVoicemailComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EditVoicemailModule {}
