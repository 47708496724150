import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '@app/services/api';
import { ISchedulesListResponse } from '@app/services/web-apis/schedules/api-schedules.domain';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { DateFormatterService, FORMATS } from '@app/services/date-formatter/date-formatter.service';
import { formatTimeString } from '@utils/helpers/functions';

@Injectable()
export class ApiScheduleService {
	// work in progress
	dataStore: {
		schedules: Array<any>;
	};
	_schedules: BehaviorSubject<Array<any>>;
	private baseUrl = '/accounts/{accountId}/schedules';

	constructor(private apiService: ApiService, private fb: FormBuilder, private dateFormatter: DateFormatterService) {
		this.dataStore = {
			schedules: [],
		};
		this._schedules = new BehaviorSubject<Array<any>>([]);
	}

	getSchedules(
		filter: string = '',
		skip: number = 0,
		take: number = 0,
		order: number = 0,
	): Observable<IRegularApiResponse<ISchedulesListResponse>> {
		const filterStr = filter ? `filter=${filter}&` : ``;
		return this.apiService.get(`${this.baseUrl}?${filterStr}skip=${skip}&take=${take}&order=${order}`);
	}

	postSchedule(schedule): Promise<any> {
		return new Promise(resolve => {
			this.apiService.post(this.baseUrl, schedule).subscribe(res => {
				resolve(res);
			});
		});
	}

	putSchedule(schedule): Promise<any> {
		return new Promise(resolve => {
			this.apiService.put(`${this.baseUrl}/${schedule.id}`, schedule).subscribe(res => {
				resolve(res);
			});
		});
	}

	removeSchedule(id: string): Observable<any> {
		return this.apiService.delete(`${this.baseUrl}/${id}`);
	}

	buildScheduleObject(scheduleForm: any, getTimezoneInOldWay: boolean = false): any {
		return {
			id: scheduleForm.value.id,
			name: scheduleForm.value.name,
			timezone: getTimezoneInOldWay ? scheduleForm.value.timezone[0].abbreviation : scheduleForm.value.timezone,
			rules: scheduleForm.value.intervals.map(interval => {
				const fromTime = formatTimeString(interval.from);
				const toTime = formatTimeString(interval.to);

				const rule = {
					name: interval.name,
					days: {
						dates: undefined,
						isRange: false,
						weekDays: [],
					},
					time: {
						start: fromTime,
						end: toTime,
					},
				};
				if (interval.weekdaysOrCalendar === 'weekdays') {
					rule.days.weekDays = interval.weekDays;
				} else {
					if (interval.dates.startDate && interval.dates.endDate) {
						// Dates should be converted from local Date object to Date string without timezone
						// because the timezone is an additional parameter
						rule.days.dates = [
							this.dateFormatter.format(interval.dates.startDate, 'YYYY-MM-DD'),
							this.dateFormatter.format(interval.dates.endDate, 'YYYY-MM-DD'),
						];
						rule.days.isRange = true;
					} else {
						rule.days.dates = interval.dates.map((d: string) => {
							// Date should be converted to generic local date string without timezone
							return this.dateFormatter.format(d, 'YYYY-MM-DD');
						});
					}
				}

				return rule;
			}),
		};
	}

	buildIntervalFromSchedule(schedule): Array<any> {
		return schedule.rules.map(rule => {
			return this.fb.group({
				name: rule.name,
				weekdaysOrCalendar: [rule.days.dates ? 'calendar' : 'weekdays'],
				weekDays: [rule.days.weekDays],
				dates: [
					rule.days.dates
						? !rule.days.isRange
							? rule.days.dates.map(date => new Date(date))
							: { startDate: new Date(rule.days.dates[0]), endDate: new Date(rule.days.dates[1]) }
						: [],
				],
				from: [
					`${rule.time.start.slice(0, 5)}${rule.time.start
						.slice(5)
						.trim()
						.toLowerCase()}`,
				],
				to: [
					`${rule.time.end.slice(0, 5)}${rule.time.end
						.slice(5)
						.trim()
						.toLowerCase()}`,
				],
			});
		});
	}
}
