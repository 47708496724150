export const COLORS = {
	primary: '#0095FF',
	'primary-hover': '#69C5FF',
	'primary-press': '#0068CB',
	'primary-light': '#CCEAFF',
	secondary: '#002540',
	'secondary-hover': '#324D6B',
	'secondary-press': '#001829',
	white: '#FFFFFF',
	'white-secondary': 'rgba(255, 255, 255, 0.5)',
	'white-disabled': 'rgba(255, 255, 255, 0.3)',
	black: '#333333',
	'black-secondary': 'rgba(51, 51, 51, 0.5)',
	'black-disabled': 'rgba(51, 51, 51, 0.25)',
	accent1: '#90CE4C',
	'accent1-hover': '#C3FF7D',
	'accent1-press': '#5E9D17',
	accent2: '#FF9800',
	'accent2-hover': '#FFC947',
	'accent2-press': '#C66900',
	accent3: '#E55A6A',
	'accent3-hover': '#FF8C98',
	'accent3-press': '#AE2540',
	accent4: '#740CE8',
	'accent4-hover': '#AC4CFF',
	'accent4-press': '#3300B5',
	'facebook-messenger': '#0084FF',
	accent5: '#43A2CA',
	facebook: '#3B5998',
	whatsapp: '#25D366',
	twitter: '#1DA1F2',
	google: '#DD4B39',
	shopify: '#96BF48',
	spotify: '#2EBD59',
	linkedin: '#0077B5',
	'gray-menu': '#FAFAFA',
	gray1: '#F2F2F2',
	gray2: '#E5E5E5',
	gray3: '#CCCCCC',
	gray4: '#A3A3A3',
	gray5: '#9B9B9B',
	gray6: '#8C8C8C',
	gray7: '#757575',
	error: '#F44349',
	'error-background': '#FDD9DB',

	// named colors
	'team-member': '#002540', // secondary
	department: '#0068CB', // primary-press
	'ring-group': '#69C5FF', // primary-hover
	'welcome-menu': '#0095FF', // primary
	'special-extension': '#1785b5', // third
	directory: '#B3EDE4', // accent-4
	ringer: '#1785b5', // third
	'call-queue': '#3C85B2',
	'virtual-fax': '#61AACA', // from UI lib tertiary-light-2
	device: '#2A6179', // from Adi

	// TODO N2P2-2226
	Open: '#69C5FF',
	Custom: '#69C5FF',
	Undefined: '#69C5FF',
};

export const isValidColor = (color: string): boolean => COLORS.hasOwnProperty(color);
