import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { MAX_ROWS } from '@app/Common';

@Component({
	selector: 'n2p-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
	@Input() resultsPerPage: number;
	@Input() resultsTotal: number;
	@Input() currentPage = 1;
	@Input() pageCount = MAX_ROWS;
	@Input() disabled = false;
	@Output() pageSelected: EventEmitter<number> = new EventEmitter();

	totalPages: number;

	ngOnInit(): void {
		this.totalPages = Math.ceil(this.resultsTotal / this.resultsPerPage);
	}

	ngOnChanges(changes): void {
		const { resultsTotal, resultsPerPage } = changes;

		if (resultsTotal || resultsPerPage) {
			this.totalPages = Math.ceil(this.resultsTotal / this.resultsPerPage);
			setTimeout(() => {
				this.fetchPage(this.currentPage);
			});
		}
	}

	get pageList(): Array<number> {
		if (!this.resultsTotal || this.resultsTotal < this.resultsPerPage) {
			return [1];
		}
		const list = [];
		const pageCount = this.totalPages;
		const startingPage = Math.floor(this.currentPage / this.pageCount) * this.pageCount;
		const endPage = pageCount > startingPage + this.pageCount ? startingPage + this.pageCount : pageCount;
		for (let i = startingPage || 1; i <= endPage; ++i) {
			list.push(i);
		}

		return list;
	}

	fetchPage = (pageNumber: number) => {
		if (this.disabled) return;

		const notWithinPaginationBounds = pageNumber < 1 || (this.totalPages && pageNumber > this.totalPages);
		if (notWithinPaginationBounds) {
			return;
		}
		if (this.currentPage !== pageNumber) {
			this.pageSelected.emit(pageNumber);
			this.currentPage = pageNumber;
		}
	};

	get activeLeft(): boolean {
		return this.currentPage > 1;
	}
	get activeRight(): boolean {
		return this.currentPage < this.totalPages;
	}
}
