import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

import { DialogDeleteEntityModule } from '@n2p/dialog/dialog-delete-entity/dialog-delete-entity.module';
import { DialogComponent } from './dialog.component';
import { DialogService } from '@app/n2p-components/dialog/dialog.service';
import { SvgModule } from '@n2p/svg';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { DialogFooterComponent } from './dialog-footer/dialog-footer.component';
import { DialogIconTitleComponent } from './dialog-icon-title/dialog-icon-title.component';
import { DialogInitialsTitleComponent } from './dialog-initials-title/dialog-initials-title.component';
import { IconModule } from '@n2p/icon';
import { EscapeHandlerModule } from '@app/directives/escape-handler';
import { DialogGenericConfirmComponent } from '@n2p/dialog/dialog-generic-confirm/dialog-generic-confirm.component';

@NgModule({
	declarations: [
		DialogComponent,
		DialogHeaderComponent,
		DialogFooterComponent,
		DialogIconTitleComponent,
		DialogInitialsTitleComponent,
		DialogGenericConfirmComponent,
	],
	imports: [CommonModule, IconModule, OverlayModule, PortalModule, SvgModule, EscapeHandlerModule],
	providers: [DialogService],
	entryComponents: [DialogComponent],
	exports: [
		DialogHeaderComponent,
		DialogFooterComponent,
		DialogIconTitleComponent,
		DialogInitialsTitleComponent,
		DialogGenericConfirmComponent,
	],
})
export class DialogModule {}
