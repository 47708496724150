import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CacheManagerService {
	private httpCacheStateSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	get httpCacheState$(): Observable<boolean> {
		return this.httpCacheStateSub.asObservable();
	}

	get httpCacheState(): boolean {
		return this.httpCacheStateSub.value;
	}

	turnOnHTTPCache(): void {
		this.httpCacheStateSub.next(true);
	}

	turnOffHTTPCache(): void {
		this.httpCacheStateSub.next(false);
	}
}
