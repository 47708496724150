import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ApiTimezoneService } from '@app/services/web-apis/timezone/api-timezone.service';
import { TimeZoneDataDropdownComponent } from '@app/shared/time-zone-data-dropdown/time-zone-data-dropdown.component';
import { ITimezone } from '@app/services/web-apis/timezone/api-timezone.domain';

@Component({
	selector: 'app-time-zone-dropdown',
	templateUrl: './time-zone-dropdown.component.html',
	styleUrls: ['./time-zone-dropdown.component.scss'],
})
export class TimeZoneDropdownComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() formControlName = 'timezone';
	@Input() label = this.translate.instant('COMPANY_PROFILE_PAGE.TIME_ZONE');
	timezones: Observable<Array<ITimezone>>;
	dropDownDataComponent = TimeZoneDataDropdownComponent;
	_selectedTimezone: ITimezone;
	private _selectedTimezoneStr: string;
	constructor(private timezoneService: ApiTimezoneService, private translate: TranslateService) {
		this.timezones = this.timezoneService.timezones$;
	}

	@Input() set selectedTimezone(value) {
		if (!value) return;
		this._selectedTimezoneStr = value;
		this.getSelected();
	}

	async ngOnInit(): Promise<any> {
		await this.timezoneService.getTimezones();
		this.getSelected();
	}

	private getSelected(): void {
		this._selectedTimezone = this.timezoneService.findTimezone(this._selectedTimezoneStr);
	}
}
