/* tslint:disable:i18n */
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/Subscription';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

import { PhoneNumberFormatterPipe } from '@app/pipes';
import { IfCallNoAnswerDataDropdownComponent } from '@n2p/if-call-no-answer-dropdown/if-call-no-answer-data-dropdown/if-call-no-answer-data-dropdown.component';
import {
	ApiDepartmentsService,
	ApiRingGroupsService,
	ApiSpecialExtensionsService,
	ApiUsersService,
	ApiWelcomeMenusService,
	CacheService,
	LicensesService,
} from '@app/services';
import { sortObjsByProp } from '@utils/helpers/functions';
import { ApiCallQueuesService } from '@app/services/web-apis/call-queues/api-call-queues.service';

@Component({
	selector: 'n2p-if-call-no-answer-dropdown',
	templateUrl: './if-call-no-answer-dropdown.component.html',
	styleUrls: ['./if-call-no-answer-dropdown.component.scss'],
	providers: [
		PhoneNumberFormatterPipe,
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => IfCallNoAnswerDropdownComponent),
		},
	],
})
export class IfCallNoAnswerDropdownComponent implements OnInit, OnDestroy, ControlValueAccessor {
	@Input() noLabel: boolean;
	@Input() hideRingGroups = true;
	@Input() hideRinger: boolean;
	@Input() hideHangUpCall: boolean;
	@Input() hideRepeatMenu = true;
	@Input() hideCompanyDirectory = true;
	@Input() hideCallQueues = true;
	@Input() forceLoading: boolean;
	@Input() required: boolean;
	dropdownData;
	dropdownControl = new FormControl([]);
	dropdownDataComponent = IfCallNoAnswerDataDropdownComponent;
	loading: boolean;
	private readonly IF_CALL_NOT_ANSWER_KEY = 'IF_CALL_NOT_ANSWER_KEY';
	private _value: Array<any>;
	private dropdownControlSubscription: Subscription;
	onChange = value => {};
	onTouch = () => {};
	constructor(
		private apiUserService: ApiUsersService,
		private apiDepartmentService: ApiDepartmentsService,
		private apiRingGroupService: ApiRingGroupsService,
		private apiWelcomeMenuService: ApiWelcomeMenusService,
		private apiSpecialExtensions: ApiSpecialExtensionsService,
		private apiCallQueuesService: ApiCallQueuesService,
		private phoneNumberFormatter: PhoneNumberFormatterPipe,
		private cacheService: CacheService,
		private translateService: TranslateService,
		private licensesService: LicensesService,
	) {}

	get value(): Array<any> {
		return this._value;
	}

	set value(val) {
		this._value = val;
	}

	writeValue(value): void {
		this.value = value;
		this.onChange(value);
	}

	registerOnChange(fn): void {
		this.onChange = fn;
	}

	registerOnTouched(fn): void {
		this.onTouch = fn;
	}

	ngOnInit(): void {
		if (this.forceLoading) this.cacheService.delete(this.IF_CALL_NOT_ANSWER_KEY);
		const cachedData = this.cacheService.get(this.IF_CALL_NOT_ANSWER_KEY);
		if (!this.forceLoading && cachedData) {
			cachedData.then ? this.waitForData(cachedData) : this.processData(cachedData);
		} else {
			const promises = [
				this.apiUserService.getAllUsersLW().toPromise(),
				this.apiDepartmentService.getAllDepartmentsLW().toPromise(),
				this.apiWelcomeMenuService.getAllWelcomeMenusLW().toPromise(),
				this.apiSpecialExtensions.getAllSpecialExtensionsLW().toPromise(),
				this.apiRingGroupService.getAllRingGroups().toPromise(),
				this.licensesService
					.getLicensesWithPrivate()
					.pipe(
						switchMap(licenses => {
							const isCallQueuesLicenseEnabled = licenses.some(license => license.licenseCode === 'customer.callqueue');
							return isCallQueuesLicenseEnabled && !this.hideCallQueues
								? this.apiCallQueuesService.getAllCallQueues()
								: of({ items: [] });
						}),
					)
					.toPromise(),
			];
			const promiseAll = Promise.all(promises);
			this.cacheService.set(this.IF_CALL_NOT_ANSWER_KEY, promiseAll);
			this.waitForData(promiseAll);
		}

		setTimeout(() => {
			if (this.value && this.value.length && !this.dropdownData) this.loading = true;
			this.dropdownControl = new FormControl(this.value);
			this.dropdownControlSubscription = this.dropdownControl.valueChanges.subscribe(value => this.writeValue(value));
		});
	}

	ngOnDestroy(): void {
		if (this.dropdownControlSubscription) {
			this.dropdownControlSubscription.unsubscribe();
		}
	}

	private waitForData(promiseAll): void {
		promiseAll.then(res => {
			this.cacheService.set(this.IF_CALL_NOT_ANSWER_KEY, res);
			this.processData(res);
		});
	}

	// tslint:disable-next-line:cyclomatic-complexity
	private processData(res): void {
		if (this.value && this.value[0] && this.value[0]._data) {
			switch (this.value[0].type) {
				case 'team-member': {
					const teamMember = res[0].data.items.find(i => i.userId === this.value[0]._data);
					if (teamMember) {
						this.value[0].value = `${teamMember.firstName} ${teamMember.lastName}`;
						this.value[0].subValue = teamMember.extension;
						this.writeValue(this.value);
					}
					break;
				}
				case 'department': {
					const department = res[1].data.items.find(i => i.deptId === this.value[0]._data);
					if (department) {
						this.value[0].value = department.name;
						this.value[0].subValue = department.extension;
						this.writeValue(this.value);
					}
					break;
				}
				case 'welcome-menu': {
					const welcomeMenu = res[2].data.items.find(i => i.id === this.value[0]._data);
					if (welcomeMenu) {
						this.value[0].value = welcomeMenu.name;
						this.value[0].subValue = welcomeMenu.extension;
						this.writeValue(this.value);
					}
					break;
				}
				case 'phone-number': {
					this.value[0].value = this.phoneNumberFormatter.transform({ number: this.value[0]._data });
					break;
				}
				case 'special-extension': {
					const specialExtension = res[3].data.items.find(i => i.id === this.value[0]._data);
					if (specialExtension) {
						this.value[0].value = specialExtension.name;
						this.value[0].subValue = specialExtension.extension;
						this.writeValue(this.value);
					}
					break;
				}
				case 'ring-group': {
					const ringGroup = res[4].data ? res[4].data.find(i => i.id === this.value[0]._data) : undefined;
					if (ringGroup) {
						this.value[0].value = ringGroup.name;
						this.value[0].subValue = ringGroup.extension;
						this.writeValue(this.value);
					}
					break;
				}
				case 'call-queue': {
					// We have to transform id to string because id type is string for RingGroups and id type is number for WelcomeMenu
					const callQueue = res[5].items
						? res[5].items.find(i => String(i.id) === String(this.value[0]._data))
						: undefined;
					if (callQueue) {
						this.value[0].value = callQueue.display_name;
						this.value[0].subValue = callQueue.extension;
						this.writeValue(this.value);
					}
					break;
				}
				default:
					break;
			}
		}

		const concatData = res[0].data.items
			.map(i => {
				const value = `${i.firstName} ${i.lastName}`;
				const redirectToVoiceMail =
					this.value && this.value[0] && this.value[0].type === 'team-member' && this.value[0].redirectToVoiceMail;

				return { ...i, value, redirectToVoiceMail, type: 'team-member', subValue: i.extension };
			})
			.concat(
				res[1].data.items.map(i => {
					const redirectToVoiceMail =
						this.value && this.value[0] && this.value[0].type === 'department' && this.value[0].redirectToVoiceMail;

					return { ...i, value: i.name, redirectToVoiceMail, type: 'department', subValue: i.extension };
				}),
			)
			.concat(
				res[2].data.items.map(i => {
					return { ...i, value: i.name, type: 'welcome-menu', name: undefined };
				}),
			)
			.concat([
				{
					value: this.translateService.instant('FORWARD_CALLS_TO_DATA.HANGUP_CALL'),
					type: 'hangup-call',
				},
			])
			.concat([
				{
					value: this.translateService.instant('FORWARD_CALLS_TO_DATA.REPEAT_MENU'),
					type: 'repeat-menu',
				},
			])
			.concat([
				{
					value: this.translateService.instant('FORWARD_CALLS_TO_DATA.COMPANY_DIRECTORY'),
					type: 'directory',
				},
			])
			.concat(this.value && this.value.length ? this.value.filter(i => i.type === 'phone-number') : [])
			.concat(
				res[3].data.items
					.filter(se => se.businessClass === 'RN')
					.map(se => ({
						...se,
						value: se.name,
						type: 'special-extension',
						subValue: se.extension,
					})),
			)
			.concat(
				res[4].data
					? res[4].data.map(i => {
							const redirectToVoiceMail =
								this.value && this.value[0] && this.value[0].type === 'ring-group' && this.value[0].redirectToVoiceMail;

							return { ...i, value: i.name, redirectToVoiceMail, type: 'ring-group', subValue: i.extension };
					  })
					: [],
			)
			.concat(
				res[5].items
					? res[5].items.map(i => {
							return { ...i, value: i['display_name'], type: 'call-queue', subValue: i.extension };
					  })
					: [],
			);

		this.dropdownData = sortObjsByProp('value', concatData);
		this.loading = false;
	}
}
