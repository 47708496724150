import { Component, ViewEncapsulation } from '@angular/core';

@Component({
	/* tslint:disable */
	selector: '*[n2p-input-icon-post]',
	templateUrl: './input-icon-post.component.html',
	styleUrls: ['./input-icon-post.component.scss'],
	encapsulation: ViewEncapsulation.None,
	/* tslint:enable */
})
export class InputIconPostComponent {}
