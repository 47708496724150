import { Component, Inject } from '@angular/core';

import { DIALOG_DATA, DIALOG_REF } from '@utils/constants/injectortokens';

@Component({
	selector: 'app-delete-schedule',
	templateUrl: './delete-schedule.component.html',
	styleUrls: ['./delete-schedule.component.scss'],
})
export class DeleteScheduleComponent {
	schedule: any;

	constructor(@Inject(DIALOG_DATA) private data: any, @Inject(DIALOG_REF) private dialogRef: any) {
		this.schedule = data;
	}

	close(): void {
		this.dialogRef.dismiss(this.data);
	}
}
