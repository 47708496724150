import { Component, Input } from '@angular/core';

import { COLORS, ICON_SIZES, iconSizes, isValidColor } from '@app/Common';

@Component({
	selector: 'n2p-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
	readonly defaultSize = ICON_SIZES.default;

	@Input() size: iconSizes;
	@Input() filled = true;
	@Input() raised = false;
	@Input() loading = false;
	@Input() border = false;
	@Input() text = '';
	@Input() iconColor: string;
	@Input() innerColor: string;

	@Input() imageHight: number;
	@Input() imageWidth: number;
	@Input() imageUrl: string;
	@Input() imageName: string;

	get sizePx(): string {
		return `${ICON_SIZES.sizes[this.size]}px`;
	}

	get fontPx(): string {
		return `${ICON_SIZES.fontSizes[this.size]}px`;
	}

	get svgHeight(): number {
		return this.imageHight || ICON_SIZES.svgSizes[this.size];
	}

	get svgWidth(): number {
		return this.imageWidth || ICON_SIZES.svgSizes[this.size];
	}

	get hasSvg(): boolean {
		return !!this.imageName && !this.hasImage;
	}

	get hasImage(): boolean {
		return !!this.imageUrl;
	}

	get hasText(): boolean {
		return !!this.text && !this.hasImage && !this.hasSvg;
	}

	get backgroundColor(): string {
		const color = this.iconColor;

		// color is passed in via hex or name
		if (color) {
			return isValidColor(color) ? COLORS[color] : color;
		}

		// defaults
		if (this.hasText) return COLORS.secondary;
		if (this.hasSvg) return COLORS['primary-light'];

		return COLORS.primary;
	}

	get primaryColor(): string {
		const color = this.innerColor;

		// color is passed in via hex or name
		if (color) {
			return isValidColor(color) ? COLORS[color] : color;
		}

		// defaults
		if (this.hasSvg) return COLORS.primary;

		return COLORS.white;
	}

	get initials(): string {
		const text = this.text.toString();
		if (!text) return '';
		if (text.length <= 2) return text;

		const splitText = text.split(' ');
		if (splitText.length === 1) return text.slice(0, 1);
		return `${splitText[0][0]}${splitText[splitText.length - 1][0]}`;
	}
}
