export enum OwnerType {
	USERS = 'users',
	DEPARTMENTS = 'departments',
	RING_GROUPS = 'ringGroups',
}

export enum FeatureType {
	RECORD = 'record',
}

export interface IFeature {
	active: boolean;
	id: FeatureType;
	value: string;
}
