import { Component, HostBinding, Inject, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import {
	MULTISELECT_DATA,
	MULTISELECT_DATA_CLICK_CALLBACK,
	MULTISELECT_DATA_FILTER,
} from '@utils/constants/injectortokens';
import { getInitialsFromString } from '@utils/helpers/dropdown';

@Component({
	selector: 'app-multi-select-data-members',
	templateUrl: './multi-select-data-members.component.html',
	styleUrls: ['./multi-select-data-members.component.scss'],
})
export class MultiSelectDataMembersComponent implements OnInit, OnDestroy {
	@HostBinding('class.n2p-dropdown-data') style = false;
	users: Array<any> = [];
	filteredUsers: Array<any> = [];
	callback: any;
	getInitials: any = getInitialsFromString;
	filterSub: any;
	constructor(
		@Inject(MULTISELECT_DATA) data: any,
		@Inject(MULTISELECT_DATA_CLICK_CALLBACK) callback: any,
		@Inject(MULTISELECT_DATA_FILTER) private filter: Observable<string>,
	) {
		this.callback = callback;
		this.users = data;
		this.filteredUsers = data;
	}

	delete() {
		this.callback();
	}

	clicked(item) {
		item.selected = !item.selected;
		this.callback({
			...item,
			type: 'tm',
			selected: item.selected,
		});
	}

	ngOnInit() {
		this.style = true;
		this.filterSub = this.filter.subscribe(str => {
			if (str) {
				this.filteredUsers = this.users.filter(u => {
					return `${u.firstName.toLowerCase()} ${u.lastName.toLowerCase()}`.indexOf(str.toLowerCase()) !== -1;
				});
			} else {
				this.filteredUsers = this.users;
			}
		});
	}

	ngOnDestroy() {
		this.filterSub.unsubscribe();
	}
}
