import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { PhoneNumbersService } from '../phone-numbers-service/phone-numbers.service';
import { CACHE_KEYS, IPhone } from '@app/Common';
import { FormControl, Validators } from '@angular/forms';
import { CacheService } from '@app/services';

@Component({
	selector: 'app-replace-phone-number',
	templateUrl: './replace-phone-number.component.html',
	styleUrls: ['./replace-phone-number.component.scss'],
})
export class ReplacePhoneNumberComponent implements OnInit {
	phone: IPhone;
	isPhoneNumberLimitReached: boolean;
	selectedPhoneNumbers: Array<IPhone> = [];
	reducedSelected = {};
	disabled = true;
	saving = false;
	changeTo: string = '';
	carrier: string = '';
	phoneNumber: FormControl;
	constructor(
		@Inject(DIALOG_DATA) data: { phone: IPhone; isPhoneNumberLimitReached: boolean },
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		private phoneNumbersService: PhoneNumbersService,
		private cacheService: CacheService,
	) {
		this.phone = data.phone;
		this.isPhoneNumberLimitReached = data.isPhoneNumberLimitReached;
	}

	ngOnInit(): void {
		if (this.phone.pendingNumber) {
			this.initPendingNumberForm();
		}
	}

	clickedCallback(selected): void {
		this.reducedSelected = selected;
		this.checkIfNumberValid();
	}

	save(): void {
		this.checkIfNumberValid();

		this.saving = true;

		let promise: Promise<void>;
		if (this.phone.pendingNumber) {
			promise = this.phoneNumbersService.replacePendingPhoneNumber(
				this.phone.pendingNumber,
				this.changeTo,
				this.phone.number,
			);
		} else {
			promise = this.phoneNumbersService.editPhoneNumber(this.phone.number, this.changeTo, this.carrier);
		}

		promise.then(
			res => {
				this.phone.number = this.changeTo;
				this.disabled = false;
				this.saving = false;
				this.cacheService.delete(CACHE_KEYS.ASSIGNED_NUMBERS_KEY);
				this.dialogRef.dismiss(res);
			},
			err => {
				this.close();
				this.disabled = false;
				this.saving = false;
			},
		);
	}

	close(): void {
		this.dialogRef.dismiss();
	}

	isLengthValid(searchTextStatus: boolean): void {
		this.disabled = !searchTextStatus;
	}

	checkIfNumberValid(): void {
		if (!this.reducedSelected) {
			this.disabled = true;
			return;
		}

		try {
			this.changeTo = this.reducedSelected[Object.keys(this.reducedSelected)[0]][0].number;
			this.carrier = this.reducedSelected[Object.keys(this.reducedSelected)[0]][0].carrier;
			this.disabled = false;
		} catch (e) {
			this.disabled = true;
		}

		if (!this.changeTo) {
			this.disabled = true;
			return;
		}
	}

	initPendingNumberForm() {
		this.phoneNumber = new FormControl([[this.phone.number], Validators.required]);

		this.phoneNumber.valueChanges.subscribe(value => {
			this.clickedCallback({ key: [{ number: value[0] }] });
			this.disabled = !value[0] || value[0] == this.phone.number;
		});
	}
}
