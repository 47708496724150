import { Component, Inject } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';

import { DELETE_VOICEMAIL_PROMPT_REF, DELETE_VOICEMAIL_PROMPT_DATA } from 'utils/constants/injectortokens';

@Component({
	selector: 'app-delete-voicemail-prompt',
	templateUrl: './delete-voicemail-prompt.component.html',
	styleUrls: ['./delete-voicemail-prompt.component.scss'],
})
export class DeleteVoicemailPromptComponent {
	constructor(
		@Inject(DELETE_VOICEMAIL_PROMPT_REF) private promptRef: OverlayRef,
		@Inject(DELETE_VOICEMAIL_PROMPT_DATA) private promptData: any,
	) {}

	get timezone(): string {
		return this.promptData.timezone;
	}

	get base64Voicemail(): string {
		return this.promptData.voicemailSrc;
	}

	get callData(): any {
		return this.promptData.callData;
	}

	confirm(): void {
		this.promptData.confirmDelete();
		this.promptRef.dispose();
	}

	cancel(): void {
		this.promptRef.dispose();
	}
}
