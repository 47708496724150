import { Component, Inject } from '@angular/core';

import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';

import { ApiDevicesService } from '@app/services';

@Component({
	selector: 'delete-devices',
	templateUrl: './delete-devices.component.html',
	styleUrls: ['./delete-devices.component.scss'],
})
export class DevicesDeleteComponent {
	public selectedUserDevice: any;

	constructor(
		@Inject(DIALOG_DATA) private data: any,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		private apiDevices: ApiDevicesService,
	) {
		this.selectedUserDevice = data;
	}

	close() {
		this.dialogRef.dismiss(this.data);
	}
}
