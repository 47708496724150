import { ActivatedRouteSnapshot, CanActivate, CanLoad, Data, Route, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiAccountsService, AuthStorageData, CommonService, FeatureFlagsService } from '@app/services';

import { FeatureType } from '@app/Common/constants/common';

@Injectable({
	providedIn: 'root',
})
export class FeatureGuard implements CanLoad, CanActivate {
	constructor(
		private featureFlagsService: FeatureFlagsService,
		private router: Router,
		private authStorageDataService: AuthStorageData,
		private accountService: ApiAccountsService,
		private commonService: CommonService,
	) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		return this.check(route.data);
	}

	canLoad(route: Route): Observable<boolean> {
		return this.check(route.data);
	}

	private check(data: Data): Observable<boolean> {
		const featureType: FeatureType = data.featureType;

		if (!(featureType in this.featureMap)) {
			throw Error(`The type ${featureType} doesn't have a mapping into the feature type`);
		}

		const source$: Observable<boolean> = this.featureMap[featureType]();

		return source$.map(result => {
			if (!result) this.router.navigateByUrl(data.redirectUrl || '');

			return result;
		});
	}

	private isOldApiIntegrationEnabled = (): Observable<boolean> => {
		return combineLatest([this.featureFlagsService.apiSetup(), this.featureFlagsService.apiIntegration()]).pipe(
			map(([isNewApiIntegrationEnabled, isOldApiIntegrationEnabled]) =>
				isNewApiIntegrationEnabled ? !isNewApiIntegrationEnabled : isOldApiIntegrationEnabled,
			),
		);
	};

	private isCallHistoryEnabled = (): Observable<boolean> => {
		return combineLatest([
			this.featureFlagsService.enableCallHistory30(),
			of(this.authStorageDataService.isV3CallServer),
		]).pipe(
			map(([isEnableCallHistory30, isV3CallServer]) => (isV3CallServer ? isEnableCallHistory30 : !isV3CallServer)),
		);
	};

	private isDirectoryEnabled = (): Observable<boolean> => {
		return combineLatest([
			this.featureFlagsService.companyDirectory(),
			this.featureFlagsService.enableCompanyDirectory30(),
			of(this.authStorageDataService.isV3CallServer),
		]).pipe(
			map(([isCompanyDirectory, isEnableDirectory30, isV3CallServer]) =>
				isV3CallServer ? isEnableDirectory30 : isCompanyDirectory,
			),
		);
	};

	private isEmergencyContactsEnabled = (): Observable<boolean> => {
		return combineLatest([this.featureFlagsService.emergencyContacts(), this.accountService.karisLawEnabled()]).pipe(
			map(([isEmergencyContactsEnabled, isKarisLawEnabled]) => {
				return isEmergencyContactsEnabled && isKarisLawEnabled;
			}),
		);
	};

	private featureMap = {
		[FeatureType.DIRECTORY]: this.isDirectoryEnabled,
		[FeatureType.API_INTEGRATION]: this.isOldApiIntegrationEnabled,
		[FeatureType.TERMS_AND_POLICIES]: this.featureFlagsService.termsAndPolicies,
		[FeatureType.PORTS]: this.featureFlagsService.porting,
		[FeatureType.MOH_UPLOAD]: this.featureFlagsService.mohUpload,
		[FeatureType.API_SETUP]: this.featureFlagsService.apiSetup,
		[FeatureType.EMERGENCY_CONTACTS]: this.isEmergencyContactsEnabled,
		[FeatureType.ENABLE_CALL_HISTORY_30]: this.isCallHistoryEnabled,
		[FeatureType.UPLOAD_VM_GREETING_USER]: this.featureFlagsService.uploadVMGreetingUser,
		[FeatureType.UPLOAD_VM_GREETING_DEP]: this.featureFlagsService.uploadVMGreetingDep,
		[FeatureType.USE_VOICEMAIL_SERVICE]: this.featureFlagsService.useVoicemailService,
		[FeatureType.SAML]: this.featureFlagsService.saml,
		[FeatureType.LICENSES]: this.featureFlagsService.webAppLicenseManagement,
		[FeatureType.OUTBOUND_CALL_BLOCKING]: this.featureFlagsService.outboundCallBlockingEnabled,
		[FeatureType.EDIT_2FA]: this.featureFlagsService.isEdit2FAEnabled,
		[FeatureType.INTERNATIONAL_CALLING]: this.featureFlagsService.internationalCalling,
		[FeatureType.VIRTUAL_FAX]: this.featureFlagsService.isVirtualFaxEnabled,
		[FeatureType.TEN_DLC]: this.featureFlagsService.isTenDlcEnabled,
		[FeatureType.DELEGATION]: this.featureFlagsService.isDelegationEnabled,
		[FeatureType.DEVICE_MANAGEMENT]: this.featureFlagsService.isDeviceManagementEnabled,
		[FeatureType.ANALYTICS_PAGE_ENABLED]: this.featureFlagsService.isAnalyticsPageEnabled,
		[FeatureType.BULK_OPERATIONS]: this.featureFlagsService.isBulkOperationsPageEnabled,
		[FeatureType.PHONE_NUMBERS_MENU]: this.featureFlagsService.phoneNumbersMenuEnabled,
	};
}
