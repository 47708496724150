import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ApiTimezoneService, ApiVoicemailsService, SnackbarService } from '@app/services';
import { DeleteVoicemailPromptComponent } from '@app/pages/call-history/components/delete-voicemail-prompt/delete-voicemail-prompt.component';
import {
	DELETE_VOICEMAIL_PROMPT_DATA,
	DELETE_VOICEMAIL_PROMPT_REF,
	VOICEMAIL_POPUP_CALL_DATA,
	VOICEMAIL_POPUP_FUNCS,
	VOICEMAIL_POPUP_REF,
} from 'utils/constants/injectortokens';
import { VoicemailOwnerType, IVoiceMailStatus } from '@app/services/web-apis/voicemails/api-voicemails.domain';

import { ITimezone } from '@app/services/web-apis/timezone/api-timezone.domain';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';

@Component({
	selector: 'app-voicemail-popup',
	templateUrl: './voicemail-popup.component.html',
	styleUrls: ['./voicemail-popup.component.scss'],
})
export class VoicemailPopupComponent implements OnInit {
	public voicemailLoading: boolean = true;
	public voicemailSrc: string;
	public transcript: string;

	timezone: ITimezone;

	constructor(
		@Inject(VOICEMAIL_POPUP_REF) private popupRef: OverlayRef,
		@Inject(VOICEMAIL_POPUP_CALL_DATA) public callData: any,
		@Inject(VOICEMAIL_POPUP_FUNCS) private injectedFuncs: any,
		private injector: Injector,
		private overlay: Overlay,
		private snackbarService: SnackbarService,
		private apiVoicemails: ApiVoicemailsService,
		private translate: TranslateService,
		private apiTimezone: ApiTimezoneService,
	) {
		this.popupRef = popupRef;
		this.callData = callData;
		this.timezone = this.apiTimezone.getCurrentUsersTimezone();
	}

	ngOnInit(): void {
		const {
			callData: {
				vmailMsgId,
				to: { userId, departmentId },
			},
			apiVoicemails,
		} = this;

		if (vmailMsgId) {
			const id = userId ? userId : departmentId;
			const type: VoicemailOwnerType = userId ? 'US' : 'DP';

			forkJoin(
				apiVoicemails.getConcreteVoicemail(id, vmailMsgId, type),
				apiVoicemails.getVoicemailLink(vmailMsgId),
			).subscribe(
				([data, voicemailSrc]) => {
					this.setVoicemailInfo(voicemailSrc, data.transcript);
				},
				() => {
					this.voicemailFailedAction();
				},
			);
		}
	}

	setVoicemailInfo(voicemailSrc: string, transcript: string): void {
		this.voicemailSrc = voicemailSrc;
		this.transcript = transcript;
		this.voicemailLoading = false;
		setTimeout(() => this.popupRef.updatePosition());
	}

	voicemailFailedAction(): void {
		this.snackbarService.createDanger(this.translate.instant('VOICEMAIL_POPUP.UNABLE_RETRIEVE'));
		this.closePopup();
	}

	download = () => {
		if (!this.voicemailLoading) {
			const tempInvisibleLink = document.createElement('a');
			tempInvisibleLink.href = this.voicemailSrc;
			tempInvisibleLink.download = `${this.callData.from.number}_vm.wav`;
			tempInvisibleLink.click();
		}
	};

	openDeletePrompt = () => {
		if (!this.voicemailLoading) {
			const positionStrategy = this.overlay
				.position()
				.global()
				.centerHorizontally()
				.centerVertically();
			const scrollStrategy = this.overlay.scrollStrategies.block();

			const config = new OverlayConfig({
				hasBackdrop: true,
				positionStrategy,
				scrollStrategy,
			});
			const deletePromptRef = this.overlay.create(config);

			const component = new ComponentPortal(
				DeleteVoicemailPromptComponent,
				null,
				this.createDeletePromptInjectors(deletePromptRef),
			);
			deletePromptRef.attach(component);
		}
	};

	deleteVoicemail = () => {
		const {
			callData: {
				vmailMsgId,
				to: { userId, departmentId },
			},
		} = this;

		if (vmailMsgId) {
			if (departmentId) {
				this.deleteVoicemailByDepartmentId(vmailMsgId, departmentId).subscribe((res: IRegularApiResponse<any>) => {
					if (!res.hasError) {
						this.deleteVoicemailSuccessAction();
					} else {
						if (userId) {
							this.deleteVoicemailByUserId(vmailMsgId, userId).subscribe((res: IRegularApiResponse<any>) => {
								!res.hasError
									? this.deleteVoicemailSuccessAction()
									: this.snackbarService.createDanger(this.translate.instant('VOICEMAIL_POPUP.UNABLE_DELETE'));
							});
						}
					}
				});
			} else if (userId) {
				this.deleteVoicemailByUserId(vmailMsgId, userId).subscribe((res: IRegularApiResponse<any>) => {
					!res.hasError
						? this.deleteVoicemailSuccessAction()
						: this.snackbarService.createDanger(this.translate.instant('VOICEMAIL_POPUP.UNABLE_DELETE'));
				});
			} else {
				this.snackbarService.createDanger(this.translate.instant('VOICEMAIL_POPUP.UNABLE_DELETE'));
			}
		}
	};

	deleteVoicemailSuccessAction(): void {
		this.snackbarService.createSuccess(this.translate.instant('VOICEMAIL_POPUP.SUCCESSFULLY_DELETED'));
		this.injectedFuncs.refreshCalls();
		this.closePopup();
	}

	deleteVoicemailByDepartmentId(vmailMsgId: any, departmentId: any): Observable<any> {
		return this.apiVoicemails.removeDepartmentVoicemail(departmentId, vmailMsgId);
	}

	deleteVoicemailByUserId(vmailMsgId: any, userId: any): Observable<any> {
		return this.apiVoicemails.removeUserVoicemail(vmailMsgId, userId);
	}

	closePopup = () => {
		this.popupRef.dispose();
	};

	audioStatusChanged = (status: string) => {
		if (status === 'playing') {
			const { vmailMsgId, to } = this.callData;
			let source$: Observable<any>;

			if (to.userId) {
				// User message

				source$ = this.apiVoicemails.updateUserVoicemail(vmailMsgId, to.userId);
			}

			if (to.departmentId) {
				// Dept message

				source$ = this.apiVoicemails.updateDepartmentVoicemail(to.departmentId, vmailMsgId);
			}

			if (source$ !== undefined) {
				source$.subscribe(res => {
					this.callData.vmailStatus = res.data.status;
				});
			}
		}
	};

	private createDeletePromptInjectors(dialogRef: OverlayRef): PortalInjector {
		// Instantiate new WeakMap for our custom injection tokens
		const injectionTokens = new WeakMap();

		// Set custom injection tokens
		injectionTokens.set(DELETE_VOICEMAIL_PROMPT_REF, dialogRef);
		injectionTokens.set(DELETE_VOICEMAIL_PROMPT_DATA, {
			base64Voicemail: this.voicemailSrc,
			confirmDelete: this.deleteVoicemail,
			callData: this.callData,
			timezone: this.timezone.format,
		});

		// Instantiate new PortalInjector
		return new PortalInjector(this.injector, injectionTokens);
	}
}
