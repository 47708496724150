import { Component, OnInit } from '@angular/core';

import { AuthStorageData } from '@app/services';
import { SUPPORTED_LANGUAGE_VALUES } from '@app/translation';
import { IDropdownOption } from 'n2p-ui-library/components/dropdown/dropdown.domain';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-impersonated-banner',
	templateUrl: './impersonated-banner.component.html',
	styleUrls: ['./impersonated-banner.component.scss'],
})
export class ImpersonatedBannerComponent implements OnInit {
	readonly show: boolean = false;
	readonly accountName: string = '';
	languages: IDropdownOption[] = [];
	selectedLanguage: string = '';

	constructor(private authStorageDataService: AuthStorageData, private translateService: TranslateService) {
		this.show = this.authStorageDataService.impersonate;
		this.accountName = this.authStorageDataService.accountName;
	}

	ngOnInit() {
		this.setLanguages();
	}

	languageChanged(selectedData: IDropdownOption) {
		this.translateService.use(selectedData.value.toString());
		this.setLanguages();
	}

	setLanguages() {
		this.languages = SUPPORTED_LANGUAGE_VALUES.map(value => ({
			title: this.translateService.instant(`COMMON_NGX.LANGUAGES.${value}`),
			value,
		}));
		this.selectedLanguage = this.translateService.currentLang || this.translateService.getDefaultLang();
	}
}
