import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { SelectDataTextComponent } from '@app/shared';
import { ApiDepartmentsService, GlobalLoaderService, Loader, SnackbarService } from '@app/services';
import { IRegularApiResponse, IErrorMessage } from '@app/services/web-apis/common-api.domain';
import {
	IForwardTo,
	ICallOptions,
	ICallOrderRules,
	IRings,
} from '@app/services/web-apis/departments/api-departments.domain';

@Component({
	selector: 'app-options-tab',
	templateUrl: './options-tab.component.html',
	styleUrls: ['./options-tab.component.scss'],
	providers: [GlobalLoaderService],
})
export class OptionsTabComponent implements OnInit {
	@Input() public model: any;
	@Input() public userMap: any;

	@Output() saveDepartment: EventEmitter<{ ruleType: string } | Event> = new EventEmitter();

	public forwardTo: any[] = [];
	public _callerIdFlag: boolean;
	public _callScreeningFlag: boolean;
	public _posAck: boolean;
	public callOrderOption: any;
	public ruleType: string;
	public selectedRingCount: any;
	private bounceMethod: any;
	public ringDataComponent: any = SelectDataTextComponent;

	public formGroup: FormGroup;
	private isLoading: boolean = true;

	@ViewChild('bodySectionRef', { static: true }) bodySectionRef: ElementRef;

	public callOrderRules: ICallOrderRules[] = [
		{ value: this.translate.instant('OPTIONS_TAB.RING_ALL'), ruleType: 'sim', selected: false },
		{ value: this.translate.instant('OPTIONS_TAB.RING_ONE'), ruleType: 'seq', selected: false },
	];

	@Input() public rings: IRings[];

	get departmentId(): string {
		return this.model.deptId;
	}

	constructor(
		private fb: FormBuilder,
		private apiDepartments: ApiDepartmentsService,
		private snackbarService: SnackbarService,
		private translate: TranslateService,
		private globalLoaderService: GlobalLoaderService,
	) {
		this.formGroup = this.fb.group({
			selectedRingCount: new FormControl([]),
			callOrderOption: new FormControl([]),
		});
		// auto save on form changes
		this.formGroup.valueChanges.subscribe((res: any) => {
			this.callOrderOption =
				res.callOrderOption && res.callOrderOption.length && res.callOrderOption[0].ruleType === 'seq'
					? this.callOrderRules[1]
					: this.callOrderRules[0];
			this.ruleType = res.callOrderOption && res.callOrderOption.length ? res.callOrderOption[0].ruleType : 'sim';
			this.selectedRingCount = this.rings[res.selectedRingCount[0].count - 1];
			if (!this.isLoading) this.debounceSave();
		});
	}

	ngOnInit(): void {
		this.setCallOptions();
	}

	setCallOptions(): void {
		let accountUsers = this.userMap;
		this.apiDepartments
			.getDepartmentCallForwardRules(this.departmentId)
			.subscribe((res: IRegularApiResponse<ICallOptions>) => {
				this._callerIdFlag = res.data.callerIdFlag || false;
				this._callScreeningFlag = res.data.callScreeningFlag || false;
				this._posAck = res.data.posAck || false;

				const forwardTo: IForwardTo[] = res.data.forwardTo || [];

				let ringCount = forwardTo.find((r: IForwardTo) => r.sequence == '1');
				if (ringCount) {
					this.rings[ringCount.rings - 1].selected = true;
					this.formGroup.controls.selectedRingCount.setValue([this.rings[ringCount.rings - 1]]);
					this.selectedRingCount = this.rings[ringCount.rings - 1];
				} else {
					// default is 5, but will only show if there are no users
					this.rings[4].selected = true;
					this.selectedRingCount = this.rings[4];
					this.formGroup.controls.selectedRingCount.setValue([this.rings[4]]);
				}

				this.forwardTo = forwardTo
					.filter((u: IForwardTo) => u.value && u.value !== '0')
					.map((u: IForwardTo) => {
						let user = accountUsers[u.value];
						return {
							...u,
							firstName: user && user.firstName,
							lastName: user && user.lastName,
							avatars: user && user.userInfo && user.userInfo.avatars,
						};
					})
					.sort((a: any, b: any) => {
						return Number(a.sequence) - Number(b.sequence);
					});

				// set curent rule type (all ring or sequentially ring)
				let ruleType = res.data.ruletype || 'sim';
				this.ruleType = ruleType;
				let ruleObj = ruleType === 'sim' ? this.callOrderRules[0] : this.callOrderRules[1];
				ruleObj.selected = true;
				this.formGroup.controls.callOrderOption.setValue([ruleObj]);
				this.callOrderOption = ruleObj;
				this.isLoading = false;
			});
	}

	get ringIndividually(): boolean {
		return this.ruleType === 'seq';
	}

	set callerIdFlag(value: boolean) {
		if (this._callerIdFlag !== value) {
			this._callerIdFlag = value;
			this.debounceSave();
		}
	}

	get callerIdFlag(): boolean {
		return this._callerIdFlag;
	}

	set callScreeningFlag(value: boolean) {
		if (this._callScreeningFlag !== value) {
			this._callScreeningFlag = value;
			this.debounceSave();
		}
	}

	get callScreeningFlag(): boolean {
		return this._callScreeningFlag;
	}

	set posAck(value: boolean) {
		if (this._posAck !== value) {
			this._posAck = value;
			this.debounceSave();
		}
	}

	get posAck(): boolean {
		return this._posAck;
	}

	public trackItem = (index: number, item: any): any => {
		return item && Number(item.sequence);
	};

	/*
    callback when an item is dropped into a new position
    here we get a new index from the event
    but we need to update our source array here
  */
	onDropSortable(event: any): void {
		const spliced = this.forwardTo.splice(event.previousIndex, 1)[0];
		this.forwardTo.splice(event.newIndex, 0, spliced);
		this.debounceSave();
	}

	debounceSave(): void {
		if (this.bounceMethod) {
			clearTimeout(this.bounceMethod);
		}
		this.bounceMethod = setTimeout(() => {
			this.autoSave();
		}, 800);
	}

	autoSave(): void {
		//if positive acceptance is not set then the call screen must be off
		if (!this.posAck) {
			this.callScreeningFlag = false;
		}

		let ruleType = this.ruleType;
		let callForwardRules = {
			ruletype: this.ruleType,
			callerIdFlag: this.callerIdFlag,
			callScreeningFlag: this.callScreeningFlag,
			posAck: this.posAck,
			forwardTo: [],
		};
		/*
      if the rule is simultanious, use selectedRingCount for all users
      otherwise use their individual ring setting
    */
		callForwardRules.forwardTo = this.forwardTo.map((u: IForwardTo, i: number) => {
			return {
				rings: ruleType === 'seq' ? u.rings : this.selectedRingCount.count,
				sequence: `${i + 1}`,
				status: 'A',
				type: 'user',
				value: u.value,
			};
		});

		this.apiDepartments
			.createDepartmentCallForwardRules(this.departmentId, callForwardRules)
			.subscribe((res: IRegularApiResponse<unknown>) => {
				if (res.hasError || res.errorMessages.length) {
					let errors = res.errorMessages.map((r: IErrorMessage) => r.message).join(' ');
					this.snackbarService.create({
						status: 'danger',
						text: errors,
						connectTo: this.bodySectionRef,
					});
				} else {
					// success state
					this.snackbarService.create({
						status: 'success',
						text: this.translate.instant('OPTIONS_TAB.RULES_SAVED'),
						connectTo: this.bodySectionRef,
					});
				}
			});
	}
}
