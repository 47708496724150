import { Injectable } from '@angular/core';
import { TransportType, DebugLevels } from 'n2p-ui-library/services/logger/logger.constants';

import { LoggerService } from '@app/services/logger/logger.service';
import { IWebAppManager } from '@app/Common/interfaces/webAppManager';

@Injectable({
	providedIn: 'root',
})
export class WebAppManager implements IWebAppManager {
	private loggerService: LoggerService = new LoggerService();

	constructor() {
		this.setConsoleInfoDebug = this.setConsoleInfoDebug.bind(this);
		this.setLogglyDebugLevels = this.setLogglyDebugLevels.bind(this);
		this.toggleTransportType = this.toggleTransportType.bind(this);
		this.toggleConsoleDebug = this.toggleConsoleDebug.bind(this);
		this.toggleLogglyDebug = this.toggleLogglyDebug.bind(this);
	}

	setConsoleInfoDebug(value: boolean): void {
		this.loggerService.setConsoleInfoDebug(value);
	}

	setLogglyDebugLevels(levels: DebugLevels): void {
		this.loggerService.setLogglyDebugLevels(levels);
	}

	toggleTransportType(transportName: TransportType.CONSOLE | TransportType.LOGGLY): void {
		this.loggerService.toggleTransportType(transportName);
	}

	toggleConsoleDebug(): void {
		this.loggerService.toggleConsoleDebug();
	}

	toggleLogglyDebug(): void {
		this.loggerService.toggleLogglyDebug();
	}
}
