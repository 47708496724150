import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'n2p-button-play',
	templateUrl: './button-play.component.html',
	styleUrls: ['./button-play.component.scss'],
})
export class ButtonPlayComponent implements OnInit {
	// TODO implement different sizes
	@Input() playing: boolean;
	@Input() loading: boolean;
	constructor() {}

	ngOnInit(): void {}
}
