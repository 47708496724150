import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { IAccountPhone } from '@app/services/web-apis/phone-numbers/api-phone-numbers.domain';

import { ApiService } from '@app/services/api/api.service';

@Injectable()
export class PhoneNumberService {
	constructor(private apiService: ApiService) {}

	get(filterUsersWithSingleLine: boolean = true, bFilterOutVirtualFax: boolean = true): Observable<any> {
		const url = `/accounts/{accountId}/phonenumbers?bFilterUsersWithSingleLine=${filterUsersWithSingleLine}&bFilterOutVirtualFax=${bFilterOutVirtualFax}`;

		return this.apiService.get(url);
	}

	getPhoneNumbersByUserId(
		userId: string | number,
		filterUsersWithSingleLine: boolean = false,
		filterOutTollFreeNumbers: boolean = false,
		bFilterOutVirtualFax: boolean = true,
	): Observable<IRegularApiResponse<IAccountPhone[]>> {
		const url = `/accounts/{accountId}/phonenumbers?bFilterUsersWithSingleLine=${filterUsersWithSingleLine}&bFilterOutTollFreeNumbers=${filterOutTollFreeNumbers}&userId=${userId}&bFilterOutVirtualFax=${bFilterOutVirtualFax}`;

		return this.apiService.get(url);
	}

	patch(phone: any, ignoreUS911: boolean = false): Observable<any> {
		const url = `/accounts/{accountId}/phonenumbers/${phone.number}?ignoreUS911=${ignoreUS911}`;

		return this.apiService.patch(url, phone);
	}

	delete(number: number | string): Observable<any> {
		const url = `/accounts/{accountId}/phonenumbers/${number}`;

		return this.apiService.delete(url);
	}
}
