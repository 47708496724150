import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'n2p-premium-banner',
	templateUrl: './premium-banner.component.html',
	styleUrls: ['./premium-banner.component.scss'],
	animations: [
		trigger('fadeInOut', [state('void', style({ opacity: 0 })), transition('void <=> *', animate(300))]),
		trigger('error', [
			transition('false => true', [
				animate('100ms', style({ transform: 'translateX(-5px)' })),
				animate('100ms', style({ transform: 'translateX(5px)' })),
				animate('100ms', style({ transform: 'translateX(0)' })),
			]),
		]),
		trigger('expand', [
			state('void', style({ height: 0, opacity: 0, padding: '0 12px', 'margin-top': 0 })),
			transition('void <=> *', animate(300)),
		]),
	],
})
export class PremiumBannerComponent implements OnChanges {
	@Input() visible = false;

	@Output() onClose = new EventEmitter();
	@Output() onContinue = new EventEmitter();

	public isTermsExpanded = false;
	public isVisible = this.visible;
	public hasError = false;
	public isChecked = false;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.visible && changes.visible.currentValue !== this.isVisible) {
			this.isVisible = changes.visible.currentValue;
		}
	}

	public hide(): void {
		this.isChecked = false;
		this.isTermsExpanded = false;
		this.isVisible = false;
		this.onClose.emit();
	}

	public continue(): void {
		if (!this.isChecked) {
			this.hasError = true;
		} else {
			this.hide();
			this.isVisible = false;
			this.onContinue.emit();
		}
	}

	public handleChangeAgreementCheckbox(value: boolean): void {
		this.isChecked = value;
	}
}
