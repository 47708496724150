import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Observable, defer } from 'rxjs';
import { map } from 'rxjs/operators';
import { IJwtClaims } from 'n2p-js-sdk';

import { AccountPolicyType, IAuthState, IJwt } from './jwt.domain';
import { getAuthUrl } from '@app/Common';
import { SdkService } from '@app/services/sdk/sdk.service';
import { AppConditionalStorageService, AppSessionStorageService } from '@app/services/storage/storage.service';

@Injectable()
export class JwtService {
	private readonly apiUrl: string = `${getAuthUrl()}/connect`;
	private authState: IAuthState | undefined = undefined;

	private location: Location = this.document.defaultView.location;

	public REDIRECT_PARAMS: URLSearchParams = new URLSearchParams({
		client_id: 'unite.webapp',
		redirect_uri: `${this.location.origin}/sso`,
		scope: 'offline_access unite.messenger unite.api account',
		response_type: 'code',
	});

	constructor(
		private httpClient: HttpClient,
		private localStorage: AppConditionalStorageService,
		private sessionStorage: AppSessionStorageService,
		private sdkService: SdkService,
		@Inject(DOCUMENT) private document: Document,
	) {}

	get state(): IAuthState | undefined {
		return this.authState;
	}

	get isAuthenticated$(): Observable<boolean> {
		return this.sdkService.isAuthenticated$;
	}

	get isAuthenticated(): boolean {
		return this.sdkService.isAuthenticated;
	}

	get accessToken$(): Observable<string> {
		return this.sdkService.accessToken$;
	}

	get accessToken(): string | undefined {
		return this.sdkService.accessToken;
	}

	get refreshToken$(): Observable<string> {
		return this.sdkService.refreshToken$;
	}

	get refreshToken(): string | undefined {
		return this.sdkService.refreshToken;
	}

	get claims(): IJwtClaims | undefined {
		return this.sdkService.claims;
	}

	public signInRedirect(state: IAuthState = { redirectUrl: this.getCurrentLocation() }): void {
		this.sdkService.auth.loginWithRedirect(state);
	}

	public signOutRedirect(state: IAuthState = { redirectUrl: this.getCurrentLocation() }): void {
		this.sdkService.auth.logout(state);
	}

	refreshAccessToken(): Observable<IJwtClaims> {
		return this.sdkService.refreshAccessToken();
	}

	public getTokenWithAuthorizationCode(): Observable<IJwt> {
		return defer(() => this.sdkService.auth.authenticateAfterRedirect()).pipe(
			map(({ accessToken, refreshToken, state }: any) => ({
				access_token: accessToken,
				refresh_token: refreshToken,
				state,
			})),
		);
	}

	public getTokenWithPassword(username: string, password: string): Observable<IJwt> {
		return defer(() => this.sdkService.auth.loginWithPassword(username, password)).pipe(
			map(({ accessToken, refreshToken }) => ({
				access_token: accessToken,
				refresh_token: refreshToken,
			})),
		);
	}

	public checkSession(): Observable<boolean> {
		const { sid = '' } = this.claims || {};
		return sid ? this.httpClient.get<boolean>(`${this.apiUrl}/session/${sid}/active`) : Observable.of(false);
	}

	public removeToken(): void {
		this.sdkService.auth.logoutLocal();
	}

	public hasScope(scope: string): boolean {
		const claims = this.claims || ({} as IJwtClaims);
		return claims && claims.scope && claims.scope.some(s => s.includes(scope));
	}

	public hasPolicy(policy: AccountPolicyType): boolean {
		const claims = this.claims || ({} as IJwtClaims);
		return claims && claims.policies && claims.policies.some(p => p.permission.startsWith(policy));
	}

	public isSAML(): boolean {
		const claims = this.claims || ({} as IJwtClaims);
		return claims && claims.amr && claims.amr.length > 0 && claims.amr.includes('saml');
	}

	public isImpersonated(): boolean {
		return this.hasScope('impersonate.webapp');
	}

	private getCurrentLocation(): string {
		return this.location.pathname + this.location.search;
	}
}
