import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AvatarModule } from '@n2p';
import { AssignedToDropdownDataComponent } from './assigned-to-dropdown-data/assigned-to-dropdown-data.component';
import { AssignedToDropdownComponent } from './assigned-to-dropdown.component';
import { SvgModule } from '@n2p/svg';
import { IconModule } from '../icon';
import { DropdownModule } from '../dropdown';

@NgModule({
	declarations: [AssignedToDropdownComponent, AssignedToDropdownDataComponent],
	imports: [CommonModule, ReactiveFormsModule, SvgModule, AvatarModule, TranslateModule, IconModule, DropdownModule],
	entryComponents: [AssignedToDropdownDataComponent],
	exports: [AssignedToDropdownComponent, AssignedToDropdownDataComponent],
})
export class AssignedToDropdownModule {}
