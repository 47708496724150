import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { GlobalLoaderComponent } from './global-loader.component';

@NgModule({
	declarations: [GlobalLoaderComponent],
	exports: [GlobalLoaderComponent],
	entryComponents: [GlobalLoaderComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LoaderModule {}
