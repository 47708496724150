import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
	selector: 'n2p-dialog-footer',
	templateUrl: './dialog-footer.component.html',
	styleUrls: ['./dialog-footer.component.scss'],
})
export class DialogFooterComponent implements OnInit {
	@HostBinding('class.footer-shadow') scrollNotAtBottom: boolean;
	mouseIsDown: boolean;
	isScrolling: any;
	private _body: any;
	constructor() {}

	@Input() set bodySection(body) {
		if (body) {
			this._body = body;
			this._body.addEventListener('mousedown', () => {
				this.mouseIsDown = true;
			});

			this._body.addEventListener('mouseup', () => {
				this.mouseIsDown = false;
				this.scrollingIsDone();
			});

			this._body.addEventListener('scroll', () => {
				clearTimeout(this.isScrolling);
				this.isScrolling = setTimeout(() => {
					if (!this.mouseIsDown) {
						this.scrollingIsDone();
					}
				}, 100);
			});
		}
	}

	get bodySection(): any {
		return this._body;
	}

	ngOnInit(): void {}

	private scrollingIsDone(): void {
		const scrollTop = this._body.scrollTop;
		this.scrollNotAtBottom = scrollTop + this.bodySection.clientHeight !== this.bodySection.scrollHeight;
	}
}
