import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import {
	DROPDOWN_DATA,
	DROPDOWN_DATA_CONFIG,
	DROPDOWN_DATA_FILTER,
	DROPDOWN_PAGE,
} from '@n2p/dropdown/dropdown.injectors';
import { matchSearchString } from '@utils/helpers/functions';

@Component({
	selector: 'app-simple-dropdown-component',
	templateUrl: './simple-dropdown-component.component.html',
	styleUrls: ['./simple-dropdown-component.component.scss'],
})
export class SimpleDropdownComponentComponent implements OnInit, OnDestroy {
	dataFormControl: FormControl;
	searchStr: string;
	pageSubscription: Subscription;
	constructor(
		@Inject(DROPDOWN_DATA) formControl: FormControl,
		@Inject(DROPDOWN_DATA_FILTER) private search: FormControl,
		@Inject(DROPDOWN_PAGE) private page: Observable<number>,
		@Inject(DROPDOWN_DATA_CONFIG) private config: any,
	) {
		this.dataFormControl = formControl;
	}

	get dropdownData(): Array<any> {
		return this.searchStr
			? this.dataFormControl.value.filter(i => matchSearchString(this.searchStr, [i.value, i.subValue]))
			: this.dataFormControl.value;
	}

	itemClicked(item): void {
		const selectedItems = this.dataFormControl.value.filter(i => i.selected);
		if (this.config.isRequired && item.selected && selectedItems.length === 1) return;
		const selected = !item.selected;
		if (this.config.singleSelection) {
			this.clearAllSelected();
		}
		item.selected = selected;
		this.dataFormControl.updateValueAndValidity();
	}

	private clearAllSelected(): void {
		for (const entity of this.dataFormControl.value) {
			entity.selected = false;
		}
	}

	ngOnInit(): void {
		this.search.valueChanges.subscribe((value: string) => (this.searchStr = value ? value.toLowerCase() : ''));
		this.pageSubscription = this.page.subscribe(p => {});
	}

	ngOnDestroy(): void {
		this.pageSubscription.unsubscribe();
	}
}
