import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OverlayRef } from '@angular/cdk/overlay';

import { POPUP_COMPONENT_DATA, POPUP_REF } from '@n2p/popup/popup.injectors';

import { noWhitespaceValidator, passwordValidator } from '@utils/index';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
	formGroup: FormGroup;
	isSaving = false;
	displayPassword = false;

	constructor(
		private fb: FormBuilder,
		@Inject(POPUP_COMPONENT_DATA) public data: { index: number; reset: Function },
		@Inject(POPUP_REF) private popupRef: OverlayRef,
	) {
		this.formGroup = fb.group({
			password: ['', [passwordValidator(), noWhitespaceValidator]],
		});
	}

	save = () => {
		this.isSaving = true;
		this.data
			.reset(this.data.index, this.formGroup.get('password').value)
			.then(response => {
				this.isSaving = false;
				this.close();
			})
			.catch(error => {
				this.isSaving = false;
			});
	};

	close = () => {
		this.popupRef.dispose();
	};
}
