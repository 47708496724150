import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownGroup } from 'n2p-ui-library/components/dropdown/dropdown.domain';
import { InitialsComponentBackground } from 'n2p-ui-library/constants';
import { IInitialsComponentProps } from 'n2p-ui-library/components/initials/initials.component';

import {
	EXTENSION_CODES,
	VALID_DEPARTMENT,
	VALID_PORTING,
	VALID_RING_GROUP,
	VALID_SPECIAL_EXTENSION,
	VALID_TEAM_MEMBER,
	VALID_UNASSIGNED,
	VALID_WELCOME_MENU,
} from '@app/Common/constants/entity-types';
import { AppSessionStorageService } from '@app/services/storage/storage.service';
import { APPLICATION_MODE_STORAGE_PARAMETER, US } from '@app/Common/constants/common';
import { JwtService } from '../web-apis/jwt/jwt.service';
import { ApiTenantService } from '../web-apis/tenant/api-tenant.service';

/**
 * This service contains constants, which depends on some services
 * For example: TranslateService
 */
@Injectable({
	providedIn: 'root',
})
export class ConstantsService {
	constructor(
		private translateService: TranslateService,
		private sessionStorage: AppSessionStorageService,
		private tenantService: ApiTenantService,
		jwtService: JwtService,
	) {
		if (this.tenantService.isUSAccount() && jwtService.hasPolicy('unite.user.callerid.port')) {
			// put "Porting numbers" group after "Unassigned numbers" groups
			const unassignedIndex = this.standardPhoneGroups.findIndex(g => g.id === VALID_UNASSIGNED);
			this.standardPhoneGroups.splice(unassignedIndex + 1, 0, {
				title: this.translateService.instant('DEVICES_PAGE2.PORTING_NUMBERS'),
				id: VALID_PORTING,
				disabledWhenEmpty: true,
				initials: this.entityTypeInitialsMap[VALID_PORTING],
				options: [],
			});
		}
	}

	entityTypeInitialsMap: { [key: string]: IInitialsComponentProps } = {
		[VALID_PORTING]: {
			iconName: 'arrows-two-way',
			background: InitialsComponentBackground.GRAY_DARK_2,
		},
		[VALID_UNASSIGNED]: {
			iconName: 'call-filled',
		},
		[VALID_TEAM_MEMBER]: {
			value: this.translateService.instant('LEFT_NAVBAR.COMPANY_BAR.TEAM_MEMBERS'),
			onlyFirstLetter: true,
			background: InitialsComponentBackground.SECONDARY,
		},
		[VALID_DEPARTMENT]: {
			value: this.translateService.instant('LEFT_NAVBAR.COMPANY_BAR.DEPARTMENTS'),
			onlyFirstLetter: true,
			background: InitialsComponentBackground.PRIMARY_LIGHT_1,
		},
		[VALID_RING_GROUP]: {
			value: this.translateService.instant('LEFT_NAVBAR.COMPANY_BAR.RING_GROUPS'),
			onlyFirstLetter: true,
			background: InitialsComponentBackground.PRIMARY_DARK_1,
		},
		[VALID_WELCOME_MENU]: {
			value: this.translateService.instant('LEFT_NAVBAR.COMPANY_BAR.WELCOME_MENUS'),
			onlyFirstLetter: true,
			background: InitialsComponentBackground.PRIMARY,
		},
		[VALID_SPECIAL_EXTENSION]: {
			value: this.translateService.instant('LEFT_NAVBAR.COMPANY_BAR.SPECIAL_EXTENSIONS'),
			onlyFirstLetter: true,
			background: InitialsComponentBackground.TERTIARY,
		},
	};

	standardPhoneGroups: IDropdownGroup[] = [
		{
			title: this.translateService.instant('EDIT_SPECIAL_EXTENSION.UNASSIGNED_NUMBERS'),
			id: VALID_UNASSIGNED,
			disabledWhenEmpty: true,
			initials: this.entityTypeInitialsMap[VALID_UNASSIGNED],
			options: [],
		},
		{
			title: this.translateService.instant('LEFT_NAVBAR.COMPANY_BAR.TEAM_MEMBERS'),
			id: VALID_TEAM_MEMBER,
			disabledWhenEmpty: true,
			initials: this.entityTypeInitialsMap[VALID_TEAM_MEMBER],
			options: [],
		},
		{
			title: this.translateService.instant('LEFT_NAVBAR.COMPANY_BAR.DEPARTMENTS'),
			id: VALID_DEPARTMENT,
			disabledWhenEmpty: true,
			initials: this.entityTypeInitialsMap[VALID_DEPARTMENT],
			options: [],
		},
		{
			title: this.translateService.instant('LEFT_NAVBAR.COMPANY_BAR.RING_GROUPS'),
			id: VALID_RING_GROUP,
			disabledWhenEmpty: true,
			initials: this.entityTypeInitialsMap[VALID_RING_GROUP],
			options: [],
		},
		{
			title: this.translateService.instant('LEFT_NAVBAR.COMPANY_BAR.WELCOME_MENUS'),
			id: VALID_WELCOME_MENU,
			disabledWhenEmpty: true,
			initials: this.entityTypeInitialsMap[VALID_WELCOME_MENU],
			options: [],
		},
		{
			title: this.translateService.instant('LEFT_NAVBAR.COMPANY_BAR.SPECIAL_EXTENSIONS'),
			id: VALID_SPECIAL_EXTENSION,
			disabledWhenEmpty: true,
			initials: this.entityTypeInitialsMap[VALID_SPECIAL_EXTENSION],
			options: [],
		},
	];

	extTypesLabels: { [key in EXTENSION_CODES]: any } = {
		FX: this.translateService.instant('ADD_SPECIAL_EXTENSION.FAX'),
		FN: this.translateService.instant('ADD_SPECIAL_EXTENSION.FIVE_NINE'),
		IC: this.translateService.instant('ADD_SPECIAL_EXTENSION.INTERCOM'),
		PG: this.translateService.instant('ADD_SPECIAL_EXTENSION.PAGER'),
		RN: this.translateService.instant('ADD_SPECIAL_EXTENSION.RINGER'),
		RMA: this.translateService.instant('ADD_SPECIAL_EXTENSION.REMOTE_VM'),
	};

	isAppMode(): boolean {
		return this.sessionStorage.getItem<boolean>(APPLICATION_MODE_STORAGE_PARAMETER);
	}
}
