import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonGroupModule } from '@app/n2p-components/button-group/button-group.module';
import { InternationalPhoneNumberModule } from '@n2p/international-phone-number/international-phone-number.module';

import { SvgComponent } from './svg/svg.component';
import { InputComponent, InputFilterComponent } from './inputs';

import { TagComponent } from './tag/tag.component';
import { AddsectionComponent } from './addsection/addsection.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ListitemComponent } from './listitem/listitem.component';
import { DialogComponent } from './dialog/dialog.component';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { MultiSelectDropdownChipComponent } from './multi-select-dropdown-chip/multi-select-dropdown-chip.component';
import { PromptComponent } from './prompt/prompt.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { SwitchComponent } from './switch/switch.component';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { PhoneNumberFormComponent } from './phone-number-form/phone-number-form.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { BasicAudioPlayerComponent } from './basic-audio-player/basic-audio-player.component';
import { ChipComponent } from './chip/chip.component';
import { MultiSelectDataMembersComponent } from './multi-select-data-members/multi-select-data-members.component';
import { MultiSelectDataForwardingComponent } from './multi-select-data-forwarding/multi-select-data-forwarding.component';
import { MultiSelectDataSimpleCheckedComponent } from './multi-select-data-simple-checked/multi-select-data-simple-checked.component';
import { MultiSelectChipTextOnlyComponent } from './multi-select-chip-text-only/multi-select-chip-text-only.component';
import { MultiSelectDropdownChipMoreComponent } from './multi-select-dropdown-chip/multi-select-dropdown-chip-more/multi-select-dropdown-chip-more.component';
import { MultiSelectDropdownChipMoreCloseBtnComponent } from './multi-select-dropdown-chip';
import { ExtensionComponent } from './extension/extension.component';
import { SelectDataTextComponent } from './select-data-text/select-data-text.component';
import { PaginationComponent } from './pagination/pagination.component';
import { AvatarIconComponent } from './avatar-icon/avatar-icon.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ModalComponent } from './modal/modal.component';
import { ApiExtensionsService } from '@app/services';
import { TextareaComponent } from './inputs/textarea/textarea.component';
import { ForwardCallsToComponent } from '@app/shared/forward-calls-to/forward-calls-to.component';
import { ForwardCallsToDataComponent } from '@app/shared/forward-calls-to/forward-calls-to-data/forward-calls-to-data.component';
import { ForwardCallsToChipComponent } from '@app/shared/forward-calls-to/forward-calls-to-chip/forward-calls-to-chip.component';
import { ForwardCallsToAddNumberComponent } from '@app/shared/forward-calls-to';
import { RemoveAccountItemDialogComponent } from '@app/shared/remove-account-item-dialog/remove-account-item-dialog.component';
import { RemoveWelcomeMenuDialogReassignDropdownComponent } from '@app/shared/remove-account-item-dialog';
import { PipesModule } from '@app/pipes';
import { DirectivesModule } from '@app/directives/directives.module';
import { CounterBarComponent } from './counterbar/counterbar.component';
import { TimeZoneDataDropdownComponent } from './time-zone-data-dropdown/time-zone-data-dropdown.component';
import { LeftNavContainerComponent } from './left-nav-container/left-nav-container.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog/unsaved-changes-dialog.component';
import { TimeZoneDropdownComponent } from './time-zone-dropdown/time-zone-dropdown.component';
import { TimeZoneChangePromptComponent } from './time-zone-change-prompt/time-zone-change-prompt.component';
import { CircleIconComponent } from './circle-icon/circle-icon.component';
import { CallQueueResetPromptComponent } from '@app/shared/call-queue-reset-prompt/call-queue-reset-prompt.component';
import { TemplatedPopupModule } from '@n2p/templated-popup/templated-popup.module';
import { TemplatedPopupComponent } from '@n2p/templated-popup/templated-popup.component';
import { DialogModule, LoaderModule } from '@n2p';
import { ExtensionFormModule } from '@app/n2p-components/extension-form/extension-form.module';
import { EscapeHandlerModule } from '@app/directives/escape-handler';
import { ImpersonatedBannerModule } from '@app/n2p-components/impersonated-banner';
import { SipDeviceInformationComponent } from '@app/shared/sip-device-information/sip-device-information.component';

@NgModule({
	declarations: [
		InputComponent,
		InputFilterComponent,
		SvgComponent,
		TagComponent,
		AddsectionComponent,
		DropdownComponent,
		ListitemComponent,
		DialogComponent,
		MultiSelectDropdownComponent,
		MultiSelectDropdownChipComponent,
		PromptComponent,
		DateRangePickerComponent,
		SwitchComponent,
		SelectDropdownComponent,
		PhoneNumberFormComponent,
		SnackbarComponent,
		TooltipComponent,
		CheckboxComponent,
		BasicAudioPlayerComponent,
		ChipComponent,
		MultiSelectDataMembersComponent,
		MultiSelectDataForwardingComponent,
		MultiSelectDataSimpleCheckedComponent,
		MultiSelectChipTextOnlyComponent,
		MultiSelectDropdownChipMoreComponent,
		MultiSelectDropdownChipMoreCloseBtnComponent,
		ExtensionComponent,
		SelectDataTextComponent,
		PaginationComponent,
		AvatarIconComponent,
		AudioPlayerComponent,
		PageHeaderComponent,
		ResetPasswordComponent,
		ModalComponent,
		TextareaComponent,
		ForwardCallsToComponent,
		ForwardCallsToDataComponent,
		ForwardCallsToChipComponent,
		ForwardCallsToAddNumberComponent,
		RemoveAccountItemDialogComponent,
		RemoveWelcomeMenuDialogReassignDropdownComponent,
		CounterBarComponent,
		TimeZoneDataDropdownComponent,
		LeftNavContainerComponent,
		FileUploaderComponent,
		UnsavedChangesDialogComponent,
		TimeZoneDropdownComponent,
		TimeZoneChangePromptComponent,
		CircleIconComponent,
		CallQueueResetPromptComponent,
		SipDeviceInformationComponent,
	],
	entryComponents: [
		DialogComponent,
		PromptComponent,
		SnackbarComponent,
		TooltipComponent,
		ChipComponent,
		MultiSelectDataMembersComponent,
		MultiSelectDataForwardingComponent,
		MultiSelectDataSimpleCheckedComponent,
		MultiSelectChipTextOnlyComponent,
		MultiSelectDropdownChipMoreComponent,
		SelectDataTextComponent,
		ResetPasswordComponent,
		ForwardCallsToDataComponent,
		ForwardCallsToChipComponent,
		ForwardCallsToAddNumberComponent,
		RemoveAccountItemDialogComponent,
		TimeZoneDataDropdownComponent,
		UnsavedChangesDialogComponent,
		TimeZoneChangePromptComponent,
		CallQueueResetPromptComponent,
	],
	imports: [
		CommonModule,
		PortalModule,
		FormsModule,
		ReactiveFormsModule,
		PipesModule,
		InternationalPhoneNumberModule,
		DirectivesModule,
		TranslateModule,
		RouterModule,
		ButtonGroupModule,
		TemplatedPopupModule,
		LoaderModule,
		ExtensionFormModule,
		EscapeHandlerModule,
		ImpersonatedBannerModule,
		DialogModule,
	],
	providers: [ApiExtensionsService],
	exports: [
		CommonModule,
		TranslateModule,
		InputComponent,
		InputFilterComponent,
		SvgComponent,
		TagComponent,
		AddsectionComponent,
		DropdownComponent,
		ListitemComponent,
		DialogComponent,
		MultiSelectDropdownComponent,
		MultiSelectDropdownChipComponent,
		PromptComponent,
		DateRangePickerComponent,
		SwitchComponent,
		SelectDropdownComponent,
		PhoneNumberFormComponent,
		CheckboxComponent,
		BasicAudioPlayerComponent,
		ExtensionComponent,
		PaginationComponent,
		AvatarIconComponent,
		AudioPlayerComponent,
		PageHeaderComponent,
		ResetPasswordComponent,
		ModalComponent,
		TextareaComponent,
		ForwardCallsToComponent,
		ForwardCallsToDataComponent,
		ForwardCallsToChipComponent,
		ForwardCallsToAddNumberComponent,
		RemoveAccountItemDialogComponent,
		RemoveWelcomeMenuDialogReassignDropdownComponent,
		InternationalPhoneNumberModule,
		CounterBarComponent,
		LeftNavContainerComponent,
		FileUploaderComponent,
		TimeZoneDropdownComponent,
		CircleIconComponent,
		TemplatedPopupComponent,
		ImpersonatedBannerModule,
		SipDeviceInformationComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
