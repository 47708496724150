import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { EventEmitter } from '@angular/core';

export class DialogRef {
	private subject: Subject<any> = new Subject<any>();
	private overlayRef: OverlayRef;

	public onChange: EventEmitter<any> = new EventEmitter();

	isDismissed: boolean = false;

	constructor(ref: OverlayRef) {
		this.overlayRef = ref;
	}

	dismiss = (data: any = undefined) => {
		this.overlayRef.dispose();
		this.isDismissed = true;
		this.subject.next(data);
	};

	onDismiss(): Observable<any> {
		return this.subject.asObservable();
	}
}
