import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'shared-counterbar',
	templateUrl: './counterbar.component.html',
	styleUrls: ['./counterbar.component.scss'],
})
export class CounterBarComponent implements OnInit {
	@Input() data: any;

	constructor() {}

	ngOnInit() {}
}
