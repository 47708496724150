import { Component, NgModule, Input } from '@angular/core';

@Component({
	selector: 'info-card',
	templateUrl: './infoCard.component.html',
	styleUrls: ['./infoCard.component.scss'],
})
@NgModule({
	declarations: [InfoCard],
})
export class InfoCard {
	constructor() {}

	@Input() public duration: string;
	@Input() public charges: string;
	@Input() public to: any;

	public cardVisible: boolean;

	showCard() {
		this.cardVisible = !this.cardVisible;
	}
}
