import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { distinctUntilChanged, map, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { fromEvent } from 'rxjs';

export enum StorageSaveMode {
	SINGLE_KEY,
	MULTIPLE_KEYS,
}

export class StorageService implements Storage {
	private readonly storage: Storage | undefined;
	private data: Object = {};
	private data$: BehaviorSubject<{
		direct: boolean;
		data: Object;
	}> = new BehaviorSubject({
		direct: true,
		data: {},
	});
	private subscriptions: Subscription[] = [];

	constructor(
		private prefix: string = 'storage',
		private storageProvider: Storage | Storage[],
		private mode: StorageSaveMode = StorageSaveMode.SINGLE_KEY,
		private window: Window,
	) {
		const storageProviders = Array.isArray(this.storageProvider) ? this.storageProvider : [this.storageProvider];
		this.storage = storageProviders.find(StorageService.checkStorageAvailability);
		// Handling issues with tests
		if (window && window.addEventListener) {
			this.subscriptions.push(
				fromEvent(window, 'storage')
					.pipe(
						filter(({ key, storageArea }: StorageEvent) => {
							return this.storage === storageArea && this.keyBelongsToStorage(key);
						}),
						map(({ key, newValue }: StorageEvent) => [this.getUnprefixedKeyForMulitpleKeysMode(key), newValue]),
					)
					.subscribe(([key, value]) => {
						const isRemoval = value === null;
						if (!isRemoval) {
							this.data[key] = this.getPrefixedStorageItem(key);
						} else {
							delete this.data[key];
						}
						this.updateSubject(false);
					}),
			);
		}
		this.initializeData();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	static checkStorageAvailability(storage: Storage): boolean {
		const ITEM_TO_CREATE = '__item__';

		try {
			storage.setItem(ITEM_TO_CREATE, ITEM_TO_CREATE);
			storage.removeItem(ITEM_TO_CREATE);
			return true;
		} catch (e) {
			return false;
		}
	}

	get length(): number {
		return Object.keys(this.data).length;
	}

	key(index: number): string {
		return Object.keys(this.data)[index];
	}

	getItem<T = any>(key: string): T {
		return this.data[key] || (this.data[key] = this.getPrefixedStorageItem(key));
	}

	setItem<T = any>(key: string, value: T): void {
		this.data[key] = value;
		this.updateSubject();
		this.setPrefixedStorageItem(key, value);
	}

	removeItem(key: string): void {
		delete this.data[key];
		if (this.mode === StorageSaveMode.SINGLE_KEY) {
			this.setStorageItem(this.prefix, this.data);
		} else {
			this.removeStorageItem(this.getPrefixedKeyForMultipleKeyMode(key));
		}
		this.updateSubject();
	}

	clear(): void {
		if (this.mode === StorageSaveMode.SINGLE_KEY) {
			this.data = {};
			this.setStorageItem(this.prefix, this.data);
		} else {
			Object.keys(this.data).map(key => this.removeStorageItem(`${this.prefix}_${key}`));
			this.data = {};
		}
		this.updateSubject();
	}

	onChange<T = any>(key: string, indirectOnly: boolean = false): Observable<T> {
		return this.data$.asObservable().pipe(
			distinctUntilChanged((a, b) => {
				const left = (a.data || {})[key];
				const right = (b.data || {})[key];
				return left === right;
			}),
			filter(change => (indirectOnly ? change.direct === false : true)),
			map(({ data }) => (data && data[key]) || undefined),
		);
	}

	private initializeData(): void {
		if (this.storage) {
			if (this.mode === StorageSaveMode.MULTIPLE_KEYS) {
				const length = this.storage.length;
				this.data = {};
				for (let i = 0; i < length; i++) {
					const key = this.storage.key(i);
					if (this.keyBelongsToStorage(key)) {
						this.data[this.getUnprefixedKeyForMulitpleKeysMode(key)] = this.getStorageItem(key);
					}
				}
			} else {
				this.data = this.getStorageForSingleKeyMode();
			}
		} else {
			this.data = {};
		}
		this.updateSubject();
	}

	private getPrefixedStorageItem<T = any>(key: string): T {
		if (this.mode === StorageSaveMode.SINGLE_KEY) {
			const data = this.getStorageForSingleKeyMode();
			return data[key] as T;
		} else {
			return this.getStorageItem(this.getPrefixedKeyForMultipleKeyMode(key)) as T;
		}
	}

	private setPrefixedStorageItem<T = any>(key: string, value: T): void {
		if (this.mode === StorageSaveMode.SINGLE_KEY) {
			const data = this.getStorageForSingleKeyMode();
			data[key] = value;
			this.setStorageItem(this.prefix, data);
		} else {
			this.setStorageItem(this.getPrefixedKeyForMultipleKeyMode(key), value);
		}
	}

	private getStorageItem<T = any>(key: string): T | string {
		if (this.storage) {
			const json = this.storage.getItem(key);
			try {
				return JSON.parse(json) as T;
			} catch (e) {
				return json;
			}
		}
		return undefined;
	}

	private setStorageItem<T = any>(key: string, value: T): void {
		if (this.storage) {
			let stringValue: string = '';
			try {
				stringValue = typeof value === 'string' ? value : JSON.stringify(value);
			} catch (e) {
				stringValue = '';
			}
			this.storage.setItem(key, stringValue);
		}
	}

	private removeStorageItem(key: string): void {
		if (this.storage) {
			this.storage.removeItem(key);
		}
	}

	private getStorageForSingleKeyMode(): {} {
		const data = this.getStorageItem(this.prefix);
		return typeof data === 'string' ? {} : data || {};
	}

	private getPrefixedKeyForMultipleKeyMode(key: string): string {
		return this.prefix ? `${this.prefix}_${key}` : key;
	}

	private getUnprefixedKeyForMulitpleKeysMode(key: string): string {
		return this.prefix ? key.replace(`${this.prefix}_`, '') : key;
	}

	private keyBelongsToStorage(key: string): boolean {
		return key === this.prefix || this.prefix ? key.startsWith(`${this.prefix}_`) : true;
	}

	private updateSubject(direct: boolean = true): void {
		this.data$.next({
			direct,
			data: { ...this.data },
		});
	}
}
