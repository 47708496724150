import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { defer, fromEventPattern, merge } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import N2PSDK, { AuthHelper, IHttpClient, IJwtScope, SDKEvent, IJwtClaims } from 'n2p-js-sdk';

import { environment } from '@env/environment';

import { getApiUrl, getAuthUrl } from '@app/Common';
import { AuthStorageData } from '@app/services/auth/auth-storage-data.service';
import { LoggerService } from '@app/services/logger/logger.service';

import { traceIdInterceptor } from '@app/services/sdk/interceptors/trace-id-interceptor';
import { appNameInterceptor } from '@app/services/sdk/interceptors/app-name-interceptor';
import { getLoggerInterceptor } from '@app/services/sdk/interceptors/logger-interceptor';
import { isImpersonated } from '@utils/helpers/impersonation';

@Injectable()
export class SdkService {
	private uniteApiSDK: N2PSDK;
	private messengerAPISDK: N2PSDK;
	private authAPISDK: N2PSDK;
	private location: Location = this.document.defaultView.location;
	private isAuthenticatedSubject: BehaviorSubject<boolean>;
	private jwtSubject: BehaviorSubject<{ accessToken: string; refreshToken: string }>;

	private subscriptions: Array<() => void> = [];

	constructor(
		private authStorageDataService: AuthStorageData,
		private loggerService: LoggerService,
		@Inject(DOCUMENT) private document: Document,
	) {
		const scope: IJwtScope[] = [
			N2PSDK.jwtScope.OFFLINE_ACCESS,
			N2PSDK.jwtScope.UNITE_MESSENGER,
			N2PSDK.jwtScope.UNITE_API,
			N2PSDK.jwtScope.ACCOUNT,
			N2PSDK.jwtScope.PUBLIC_API_V2,
			N2PSDK.jwtScope.PBX,
			N2PSDK.jwtScope.SIP_TRUNK,
		];
		const storageImplementation: 'local' | 'session' = isImpersonated ? 'session' : 'local';

		this.uniteApiSDK = new N2PSDK({
			clientId: 'unite.webapp',
			redirectUri: `${this.location.origin}/sso`,
			scope,
			apiUrl: `${getApiUrl()}api`,
			authUrl: environment.api_auth_url,
			storageImplementation,
		});

		this.isAuthenticatedSubject = new BehaviorSubject(this.isAuthenticated);
		this.jwtSubject = new BehaviorSubject({ accessToken: this.accessToken, refreshToken: this.refreshToken });
		this.subscriptions = [
			this.uniteApiSDK.on(SDKEvent.LOGIN_SUCCESS, () => {
				this.jwtSubject.next({ accessToken: this.accessToken, refreshToken: this.refreshToken });
				this.isAuthenticatedSubject.next(true);
			}),
			this.uniteApiSDK.on(SDKEvent.LOGIN_ERROR, () => {
				this.jwtSubject.next({ accessToken: '', refreshToken: '' });
				this.isAuthenticatedSubject.next(false);
			}),
			this.uniteApiSDK.on(SDKEvent.REFRESH_SUCCESS, () => {
				this.jwtSubject.next({ accessToken: this.accessToken, refreshToken: this.refreshToken });
			}),
			this.uniteApiSDK.on(SDKEvent.LOGOUT_SUCCESS, () => this.isAuthenticatedSubject.next(false)),
		];

		this.uniteApiSDK.configureHttpClientInterpolation({
			'{catalogId}': () => (this.claims ? String(this.claims['cid']) : ''),
			'{nodeId}': () => (this.claims ? String(this.claims['node.id']) : ''),
		});
		this.uniteApiSDK.http.addRequestInterceptor(traceIdInterceptor);
		this.uniteApiSDK.http.addRequestInterceptor(appNameInterceptor);
		this.uniteApiSDK.http.addResponseInterceptor(undefined, getLoggerInterceptor(this.loggerService));

		this.messengerAPISDK = new N2PSDK({
			clientId: 'unite.webapp',
			redirectUri: '',
			scope,
			apiUrl: `${environment.messenger_api_url}api`,
			authUrl: environment.api_auth_url,
			storageImplementation,
		});
		this.subscriptions.push(
			this.messengerAPISDK.on(SDKEvent.REFRESH_SUCCESS, () => {
				this.jwtSubject.next({ accessToken: this.accessToken, refreshToken: this.refreshToken });
			}),
		);
		this.messengerAPISDK.configureHttpClientInterpolation({
			'{messengerAccountId}': () => this.authStorageDataService.messengerAccountId,
		});

		this.authAPISDK = new N2PSDK({
			clientId: 'unite.webapp',
			redirectUri: '',
			scope,
			apiUrl: `${getAuthUrl()}`,
			authUrl: environment.api_auth_url,
			storageImplementation,
		});
		this.authAPISDK.configureHttpClientInterpolation({
			'{catalogId}': () => (this.claims ? String(this.claims['cid']) : ''),
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(unsubscribe => unsubscribe());
	}

	get isAuthenticated$(): Observable<boolean> {
		return this.isAuthenticatedSubject.asObservable();
	}

	get isAuthenticated(): boolean {
		return this.uniteApiSDK.auth.isAuthenticated;
	}

	get accessToken$(): Observable<string> {
		return this.jwtSubject.asObservable().pipe(map(({ accessToken }) => accessToken));
	}

	get accessToken(): string {
		return this.uniteApiSDK.auth.accessToken;
	}

	get refreshToken$(): Observable<string> {
		return this.jwtSubject.asObservable().pipe(map(({ refreshToken }) => refreshToken));
	}

	get refreshToken(): string {
		return this.uniteApiSDK.auth.refreshToken;
	}

	get claims(): IJwtClaims {
		return this.uniteApiSDK.auth.claims;
	}

	get http(): IHttpClient {
		return this.uniteApiSDK.http;
	}

	get auth(): AuthHelper {
		return this.uniteApiSDK.auth;
	}

	get messengerHttp(): IHttpClient {
		return this.messengerAPISDK.http;
	}

	get authHttp(): IHttpClient {
		return this.authAPISDK.http;
	}

	on(eventName: SDKEvent): Observable<any> {
		return merge(
			fromEventPattern(this.uniteApiSDK.on.bind(this.uniteApiSDK, eventName), (handler, unsubscribe) => unsubscribe()),
			fromEventPattern(this.messengerAPISDK.on.bind(this.uniteApiSDK, eventName), (handler, unsubscribe) =>
				unsubscribe(),
			),
			fromEventPattern(this.authAPISDK.on.bind(this.uniteApiSDK, eventName), (handler, unsubscribe) => unsubscribe()),
		).pipe(
			// Ideally, the SDKs won't fire same event, but technically it is still possible, so just in case we throttle the handler
			throttleTime(10),
		);
	}

	refreshAccessToken(): Observable<IJwtClaims> {
		return defer(() => this.uniteApiSDK.jwt.refreshAccessTokenWithTabSync()).pipe(map(() => this.claims));
	}
}
