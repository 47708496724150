import { Component, Inject } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { filter, take, map, switchMap } from 'rxjs/operators';

import {
	ApiAccountsService,
	ApiUsersService,
	AuthService,
	FeatureFlagsService,
	GlobalEventsService,
	AuthStorageData,
	LicensesService,
	JwtService,
} from '@app/services';
import { TranslationService } from '@app/translation/translation.service';
import { GoogleAnalyticsService } from '@app/services/google-analytics';
import { AppSessionStorageService } from '@app/services/storage/storage.service';
import { APPLICATION_MODE_QUERY_PARAMETER, APPLICATION_MODE_STORAGE_PARAMETER } from '@app/Common/constants/common';
import { IGtagCustomDimensions } from '@app/services/google-analytics/google-analytics.domain';
import { IGoogleAnalyticsData, INewRelicAnalyticsData, IUserType, userTypeFromUser } from '@app/app.domain';

@Component({
	selector: 'my-app',
	templateUrl: './app.component.html',
	styleUrls: ['app.component.scss'],
	moduleId: module.id,
})
export class AppComponent {
	private window: Window = this.document.defaultView;

	private subscriptions: Subscription[] = [];

	constructor(
		private authService: AuthService,
		private authStorageDataService: AuthStorageData,
		private jwtService: JwtService,
		private accountsService: ApiAccountsService,
		private usersService: ApiUsersService,
		private featureFlagsService: FeatureFlagsService,
		private router: Router,
		private translate: TranslationService,
		private gtag: GoogleAnalyticsService,
		private sessionStorage: AppSessionStorageService,
		private eventsService: GlobalEventsService,
		private licensesService: LicensesService,
		@Inject(DOCUMENT) private document: Document,
	) {
		(this.window as any).utils = {
			...((this.window as any).utils || {}),
		};

		this.newRelicSetup({
			userId: parseInt(this.authStorageDataService.userId) || 0,
			accountId: parseInt(this.authStorageDataService.accountId) || 0,
			userType: IUserType.STANDARD,
		});

		this.subscriptions = [
			// Setup analytics after successful authentication and initial data fetch
			this.waitForAnalyticsData().subscribe(data => {
				this.newRelicSetup(data);
				this.googleAnalyticsSetup(data);
			}),

			// Subscribe to router events to set google analytics path
			this.router.events.subscribe(event => {
				if (event instanceof NavigationStart) {
					this.eventsService.updateUrl(event.url);
				}
				if (event instanceof NavigationEnd) {
					this.gtag.setPath(event.urlAfterRedirects);
				}
			}),

			this.eventsService.urlChangedExternally.subscribe(url => {
				this.router.navigateByUrl(url);
			}),

			this.eventsService.notEnoughPermissionsEvent.subscribe(route => {
				this.router.navigate(['/no-permissions'], {
					queryParams: {
						route,
					},
				});
			}),
		];
	}

	ngOnInit(): void {
		const isAppMode =
			this.window &&
			this.window.location &&
			this.window.location.search.toLowerCase().includes(`${APPLICATION_MODE_QUERY_PARAMETER}=true`);
		if (isAppMode) {
			this.sessionStorage.setItem(APPLICATION_MODE_STORAGE_PARAMETER, true);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	waitForAnalyticsData(): Observable<INewRelicAnalyticsData & IGoogleAnalyticsData> {
		return this.authService.isAuthenticated$.pipe(
			filter(isAuthenticated => isAuthenticated === true),
			switchMap(() => {
				return combineLatest([
					this.accountsService.account$,
					this.usersService.user$,
					of(this.jwtService.claims),
					this.featureFlagsService.getAllCommonFeatureFlags(),
				]).pipe(
					filter(results => results.every(Boolean)),
					take(1),
					map(([account, user, claims, featureFlags]) => {
						const impersonate = claims.scope.includes('impersonate.webapp');
						this.authStorageDataService.accountName = account.company;

						return {
							impersonate,
							accountId: account.accountId,
							accountName: account.company,
							userId: impersonate ? account.accountId : user.userId,
							userType: userTypeFromUser(user),
							userRole: user.role,
							tenantId: String(claims.tid),
							uniteCountry: account.country,
							browserId: localStorage.getItem('browser_id'),
							huddleAuthorized: this.jwtService.hasPolicy('huddle'),
							featureFlags,
						};
					}),
				);
			}),
		);
	}

	private newRelicSetup({ userId, accountId, userType }: INewRelicAnalyticsData): void {
		const newrelic = (this.window as any).newrelic;
		if (newrelic && typeof newrelic.setCustomAttribute === 'function') {
			newrelic.setCustomAttribute('userId', userId);
			newrelic.setCustomAttribute('accountId', accountId);
			newrelic.setCustomAttribute('userType', userType);
		}
	}

	private googleAnalyticsSetup(data: IGoogleAnalyticsData): void {
		this.gtag.customDimensions({
			companyId: String(data.accountId),
			companyName: data.accountName,
			userId: String(data.userId),
			userType: data.userType,
			userRole: data.userRole,
			impersonate: String(data.impersonate),
			tenantId: data.tenantId,
			uniteCountry: data.uniteCountry,
			browserId: data.browserId,
		} as IGtagCustomDimensions);
	}
}
