import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
	ApiDepartmentsService,
	ApiPhoneNumbersService,
	ApiUsersService,
	CommonService,
	DialogService,
	SnackbarService,
} from '@app/services';
import { DialogService as N2PDialogService } from '@n2p';
import {
	DeleteEntityModel,
	DialogDeleteEntityComponent,
} from '@n2p/dialog/dialog-delete-entity/dialog-delete-entity.component';
import { sortObjsByProp } from '@utils/helpers/functions';
import { AddDepartmentsComponent } from './adddepartment/adddepartment.component';

import { DepartmentService } from '@app/services/department/department.service';
import { EditDepartmentComponent } from './edit-department/edit-department.component';
import { IRegularApiResponse, IErrorMessage } from '@app/services/web-apis/common-api.domain';
import {
	IDepartment,
	IDepartmentUser,
	IExtendedDepartment,
} from '@app/services/web-apis/departments/api-departments.domain';
import { IUser, IExtendedUser } from '@app/services/web-apis/users/api-users.domain';
import { IAccountPhone } from '@app/services/web-apis/phone-numbers/api-phone-numbers.domain';
import { LocalSearchService } from '@app/services/local-search/local-search.service';
import {
	GA_DEPARTMENTS_ADD,
	GA_DEPARTMENTS_DELETE,
	GA_DEPARTMENTS_EDIT,
	GoogleAnalyticsService,
} from '@app/services/google-analytics';

@Component({
	selector: 'app-departments',
	templateUrl: './departments.component.html',
	moduleId: module.id,
	styleUrls: ['./departments.component.scss'],
})
export class DepartmentsComponent implements OnInit {
	MAX_DEPARTMENTS_PER_PAGE: number = 50;

	departmentsData: IExtendedDepartment[];
	departmentsFiltered: IExtendedDepartment[];
	departmentsPagenated: IExtendedDepartment[];
	usersData: IExtendedUser[];
	numbers: IAccountPhone[];
	isAdmin: boolean;
	showloader: boolean = false;
	showloaderusers: boolean = false;
	showloaderdepartments: boolean = false;
	userDataAvlbl: boolean = false;
	counterData: { [key: string]: number }[] = [];

	constructor(
		private dialogService: DialogService,
		private n2pDialogService: N2PDialogService,
		private cService: CommonService,
		public deptService: DepartmentService,
		private snackbarService: SnackbarService,
		private apiUsers: ApiUsersService,
		private apiDepartments: ApiDepartmentsService,
		private apiPhoneNumbers: ApiPhoneNumbersService,
		private translate: TranslateService,
		private localSearchService: LocalSearchService,
		private gtag: GoogleAnalyticsService,
	) {}

	filter(searchText: string): void {
		const fieldsToMatch = ['name', 'userNames', 'extension'];

		this.departmentsFiltered = this.localSearchService.search(this.departmentsData, searchText, fieldsToMatch);
		this.setPaginatedDepartments(1);
	}

	get totalCount(): number {
		return this.departmentsFiltered ? this.departmentsFiltered.length : 0;
	}

	setCounterData(): void {
		this.counterData[0] = { key: this.translate.instant('DEPARTMENTS.TOTAL'), value: this.totalCount };
	}

	setPaginatedDepartments(pageNumber: number): void {
		const startingIndex = (pageNumber - 1) * this.MAX_DEPARTMENTS_PER_PAGE;

		if (this.departmentsFiltered) {
			this.departmentsPagenated = this.departmentsFiltered.slice(
				startingIndex,
				startingIndex + this.MAX_DEPARTMENTS_PER_PAGE,
			);
		}

		this.setCounterData();
	}

	deleteDepartment(dept: IExtendedDepartment): void {
		const lines = dept.lineNumber
			? dept.lineNumber.map((l: string) => {
					return { lineId: l, number: l };
			  })
			: [];
		const accountItem = {
			...dept,
			lines: lines,
			id: dept.deptId,
			textType: this.translate.instant('DEPARTMENTS.DEPARTMENT'),
			filteringType: 'department',
		};

		const deleteEntityModel: DeleteEntityModel = {
			entityType: 'department',
			assignedNumbers: lines,
			entity: dept,
			entityId: dept.deptId,
			entityName: dept.name,
		};
		const dialogRef = this.n2pDialogService.create(DialogDeleteEntityComponent, deleteEntityModel, {
			width: 542,
		});
		dialogRef.onDismiss().subscribe((res: unknown) => {
			if (res) {
				this.showloaderdepartments = true;
				this.deptService.deleteDepartment(dept.deptId).subscribe(
					(res: IRegularApiResponse<string>) => {
						// failure state
						if (res.hasError || res.errorMessages.length) {
							let errors = res.errorMessages.map((r: IErrorMessage) => r.message).join(' ');
							this.showloaderdepartments = false;
							this.snackbarService.create({
								status: 'danger',
								text: errors,
								connectTo: null,
							});
						} else {
							// success state
							this.snackbarService.create({
								status: 'success',
								text: `${dept.name} ` + this.translate.instant('DEPARTMENTS.DELETED'),
								connectTo: null,
							});
							this.getAllDepartments();
							this.gtag.event(GA_DEPARTMENTS_DELETE);
						}
					},
					() => this.getAllDepartments(),
				);
			}
		});
	}

	onAssignUser(users: IDepartmentUser[], dept: IExtendedDepartment): void {
		dept.members = [...users];
	}

	addDepartment(): void {
		const dialogRef = this.dialogService.create(
			AddDepartmentsComponent,
			{
				accountUsers: this.usersData,
				accountNumbers: this.numbers,
				paddings: true,
			},
			{
				width: 480,
			},
		);
		dialogRef.onDismiss().subscribe((res: unknown) => {
			if (res) {
				this.snackbarService.create({
					status: 'success',
					text: `${this.translate.instant('DASHBOARD.DEPARTMENT')} ${this.translate.instant(
						'DASHBOARD.ADDED_SUCCESSFULLY',
					)}`,
					connectTo: null,
				});
				this.getAllDepartments();
				this.gtag.event(GA_DEPARTMENTS_ADD);
			}
		});
	}

	editDepartment(dept: IExtendedDepartment): void {
		const dialogRef = this.dialogService.create(
			EditDepartmentComponent,
			{
				accountUsers: this.usersData,
				accountNumbers: this.numbers,
				department: dept,
				setNumbers: this.setNumbers,
			},
			{
				width: 600,
			},
		);
		dialogRef.onDismiss().subscribe(() => {
			this.getAllDepartments();
			this.gtag.event(GA_DEPARTMENTS_EDIT);
		});
	}

	getAllDepartments(): void {
		this.showloaderdepartments = true;

		this.apiDepartments.getAllDepartments().subscribe(
			(result: IRegularApiResponse<IDepartment[]>) => {
				this.departmentsData = result['data'] as IExtendedDepartment[];
				this.showUserDepartmentsUI();
				this.showloaderdepartments = false;
				this.departmentsFiltered = this.departmentsData;
				this.setPaginatedDepartments(1);
			},
			(error: unknown) => {},
			() => {},
		);
	}

	setNumbers(): void {
		this.apiPhoneNumbers.getAllPhoneNumbers().subscribe((res: IRegularApiResponse<IAccountPhone[]>) => {
			this.numbers = res.data || [];
		});
	}

	ngOnInit(): void {
		this.getAllDepartments();
		this.fetchUserInfo();
		this.setNumbers();

		this.isAdmin = this.cService.isAdmin();
	}

	fetchUserInfo(): void {
		let that = this;
		if (!that.userDataAvlbl) {
			that.showloaderusers = true;
			that.userDataAvlbl = true;
			this.apiUsers.getAllUsers(false).subscribe(
				(result: IRegularApiResponse<IUser[]>) => {
					that.showloaderusers = false;
					if (!result.hasError && result.data) {
						const mappedData = result.data.map((p: IUser) => {
							return {
								...p,
								value: `${p.firstName} ${p.lastName}`,
								subValue: p.extension,
							};
						});
						that.usersData = sortObjsByProp('value', mappedData) as IExtendedUser[];
					}
				},
				(error: unknown) => {
					that.showloaderusers = false;
				},
				() => {},
			);
		}
	}

	// TODO: replacing with a simple template loop, can I delete?
	// Show department users on UI
	showUserDepartmentsUI(): void {
		this.departmentsData.forEach((dep: IExtendedDepartment) => {
			dep.userNames = '';
			dep.members.forEach((member: IDepartmentUser, index: number) => {
				dep.userNames += `${index !== 0 ? ' ' : ''}${member.firstName} ${member.lastName}${
					index !== dep.members.length - 1 ? ',' : ''
				}`;
			});
		});
	}
}
