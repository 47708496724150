import { Component, HostBinding, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import {
	MULTISELECT_DATA,
	MULTISELECT_DATA_CLICK_CALLBACK,
	MULTISELECT_DATA_CONFIG,
} from 'utils/constants/injectortokens';
import { IPhone } from '@app/Common/interfaces/IPhone';
import { getInitialsFromString } from 'utils/helpers/dropdown';
import { DialogService } from '@app/services/dialog/dialog.service';
import { MULTISELECT_DATA_FILTER } from '@utils/constants/injectortokens';
import { ForwardCallsToAddNumberComponent } from '../forward-calls-to-add-number/forward-calls-to-add-number.component';

declare type forwardCallsTypes = 'tm' | 'd' | 'r' | 'w';

@Component({
	selector: 'app-forward-calls-to-data',
	templateUrl: './forward-calls-to-data.component.html',
	styleUrls: ['./forward-calls-to-data.component.scss'],
})
export class ForwardCallsToDataComponent implements OnInit, OnDestroy {
	@HostBinding('class.n2p-dropdown-data') style = false;
	users: Array<any> = [];
	departments: Array<any> = [];
	ringGroups: Array<any> = [];
	welcomeMenus: Array<IPhone> = [];
	specialExtensions: Array<any> = [];
	callback: any;
	getInitials: any = getInitialsFromString;
	showLoader = true;
	showAddForwardingNumber = true;
	showUnassignedNumber = false;
	showDeleteNumber = false;
	showRepeatMenu = false;
	showThirdLvl: boolean;
	selectedData: any;
	concatenatedEntities = [];
	filter: Observable<string>;
	filteredData: Array<any> = [];
	filterSubscription: Subscription;
	constructor(
		@Inject(MULTISELECT_DATA) data: Array<any>,
		@Inject(MULTISELECT_DATA_CLICK_CALLBACK) callback: any,
		@Inject(MULTISELECT_DATA_CONFIG) config: any,
		private dialogService: DialogService,
		private injector: Injector,
	) {
		try {
			this.filter = this.injector.get(MULTISELECT_DATA_FILTER) as Observable<string>;
		} catch (e) {
			this.filter = new Subject<string>();
		}
		this.callback = callback;
		if (config.hasOwnProperty('showAddForwardingNumber')) {
			this.showAddForwardingNumber = config.showAddForwardingNumber;
		}
		if (config.hasOwnProperty('showUnassignedNumber')) {
			this.showUnassignedNumber = config.showUnassignedNumber;
		}
		if (config.hasOwnProperty('showDeleteNumber')) {
			this.showDeleteNumber = config.showDeleteNumber;
		}
		if (config.hasOwnProperty('showRepeatMenu')) {
			this.showRepeatMenu = config.showRepeatMenu;
		}
		if (config.hasOwnProperty('showThirdLvl')) {
			this.showThirdLvl = config.showThirdLvl;
		}

		if (data && data.length) {
			this.showLoader = false;
			this.users = data[0].data.map(u => ({ ...u, selected: true }));
			this.departments = data[1].data || [];
			this.ringGroups = data[2].data || [];
			this.welcomeMenus = data[3].data || [];
			this.specialExtensions = data[4] && data[4].data ? data[4].data : [];
			this.selectedData = data[5] && data[5].length ? data[5][0] : data[5];
		}
	}

	addNumber(): void {
		const dialogRef = this.dialogService.create(ForwardCallsToAddNumberComponent, undefined, {
			width: 440,
		});

		dialogRef.onDismiss().subscribe(num => {
			if (!num) {
				return;
			}
			this.doCallback({
				selected: true,
				item: {
					id: num,
				},
				type: 'ext',
			});
		});
	}

	repeatMenu(): void {
		this.doCallback({
			selected: true,
			item: {
				number: '',
			},
			type: 'repeat',
		});
	}

	unassignedNumber(): void {
		if (this.selectedData && this.selectedData.type === 'unassigned') {
			return;
		}
		this.doCallback({
			selected: true,
			item: {
				id: 'unassigned',
				number: '',
			},
			type: 'unassigned',
		});
	}

	deleteNumber(): void {
		if (this.selectedData && this.selectedData.type === 'delete') {
			return;
		}
		this.doCallback({
			selected: true,
			item: {
				id: 'delete',
				number: '',
			},
			type: 'delete',
		});
	}

	clicked(item, type: forwardCallsTypes, isVM = false): void {
		item.selected = !item.selected;
		this.doCallback({
			isVM,
			type,
			item,
			selected: item.selected,
		});
	}

	ngOnInit(): void {
		this.style = true;
		this.concatenatedEntities = this.users.concat(this.departments.concat(this.ringGroups.concat(this.welcomeMenus)));
		this.filterSubscription = this.filter.subscribe(str => {
			if (!str) return (this.filteredData = []);
			this.filteredData = this.concatenatedEntities.filter(i => i.name.toLowerCase().indexOf(str.toLowerCase()) !== -1);
		});
	}

	ngOnDestroy(): void {
		if (this.filterSubscription && this.filterSubscription.unsubscribe) {
			this.filterSubscription.unsubscribe();
		}
	}

	private doCallback(response): void {
		this.selectedData = response;
		this.callback(response);
	}
}
