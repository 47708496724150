import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AudioPlayerService } from '@n2p/audio-player/audio-player.service';
import { ButtonPlayModule } from '@n2p/button/button-play/button-play.module';
import { AudioPlayerComponent } from './audio-player.component';

@NgModule({
	declarations: [AudioPlayerComponent],
	imports: [CommonModule, ButtonPlayModule],
	providers: [AudioPlayerService],
	exports: [AudioPlayerComponent],
})
export class AudioPlayerModule {}
