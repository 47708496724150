import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'macFormatter',
})
export class MacFormatterPipe implements PipeTransform {
	private readonly MAC_ADDRESS_LENGTH: number = 12;

	transform(value: string | number): string {
		let result = value.toString().replace(/[^a-zA-Z0-9]/g, '');

		if (result.length === this.MAC_ADDRESS_LENGTH) {
			result = result.match(/.{1,2}/g).join(':');
		}

		return result;
	}
}
