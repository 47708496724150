import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { TranslationService } from './translation.service';
import { WebpackTranslateLoader } from './translation.loaders';

export function TranslationLocaleFactory(translate: TranslateService): string {
	return translate.currentLang;
}

export function TranslationInitializerFactory(translation: TranslationService): any {
	return () => translation.initialize();
}

@NgModule({
	imports: [
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader,
			},
		}),
	],
	providers: [
		TranslateService,
		TranslationService,
		{
			provide: LOCALE_ID,
			useFactory: TranslationLocaleFactory,
			deps: [TranslateService],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: TranslationInitializerFactory,
			deps: [TranslationService],
			multi: true,
		},
	],
	exports: [TranslateModule],
})
export class TranslationModule {}
