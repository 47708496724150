import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
	ICallBlockingConfirmNumbersOptions,
	ICallBlockingConfirmPopupOptions,
} from '@app/pages/call-blocking/call-blocking.domain';

@Component({
	selector: 'app-call-blocking-confirm-popup',
	templateUrl: './call-blocking-confirm-popup.component.html',
	styleUrls: ['./call-blocking-confirm-popup.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallBlockingConfirmPopupComponent {
	@Input() options: ICallBlockingConfirmPopupOptions;
	@Input() numbersOptions: ICallBlockingConfirmNumbersOptions;
	@Input() loading: boolean;
	@Output() onCancel: EventEmitter<void> = new EventEmitter();
	@Output() onAction: EventEmitter<void> = new EventEmitter();
}
