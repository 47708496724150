import { Component, Inject, OnInit } from '@angular/core';

import { DIALOG_REF } from '@utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';

@Component({
	selector: 'app-add-welcome-menu-cancel-prompt',
	templateUrl: './add-welcome-menu-cancel-prompt.component.html',
	styleUrls: ['./add-welcome-menu-cancel-prompt.component.scss'],
})
export class AddWelcomeMenuCancelPromptComponent implements OnInit {
	constructor(@Inject(DIALOG_REF) private dialogRef: DialogRef) {}

	close(remove) {
		this.dialogRef.dismiss(remove);
	}

	ngOnInit() {}
}
