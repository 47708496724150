import { Directive, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

import { DROPDOWN_DATA_FILTER } from '@n2p/dropdown/dropdown.injectors';

@Directive({
	selector: '[n2p-dropdown-search-highlight]',
})
export class DropdownSearchHighlightDirective implements OnInit, OnDestroy {
	searchSubscription: Subscription;
	constructor(private el: ElementRef, @Inject(DROPDOWN_DATA_FILTER) private search: FormControl) {}

	ngOnInit(): void {
		this.searchSubscription = this.search.valueChanges.subscribe(this.applyHighlighting);
		setTimeout(() => this.applyHighlighting(this.search.value));
	}

	ngOnDestroy(): void {
		this.searchSubscription.unsubscribe();
	}

	private applyHighlighting = (value): void => {
		const textNodes: Array<any> = this.el.nativeElement.querySelectorAll('*[searchable]');
		for (const node of textNodes) {
			const text: string = node.innerText;
			if (!text) continue;
			if (!value) {
				node.innerHTML = text;
				continue;
			}
			const regexp = new RegExp(
				value
					.toLowerCase()
					.split('')
					.join('\\s*'),
			);
			const match = regexp.exec(text.toLowerCase());
			if (match) {
				const newText = `${text.slice(0, match.index)}<span class="highlighted">${text.substr(
					match.index,
					match[0].length,
				)}</span>${text.slice(match.index + match[0].length)}`;
				node.innerHTML = newText;
			}
		}
	};
}
