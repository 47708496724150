import { Injectable, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { TranslateService } from '@ngx-translate/core';

import { DevicesAssignedPromptComponent } from '@app/pages/devices/components/devices-assigned-prompt/devices-assigned-prompt.component';
import { DIALOG_COMPONENT, DIALOG_CONFIG, DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { PromptRef } from '@app/shared/classes/PromptRef';
import { PromptComponent } from '@app/shared/prompt/prompt.component';
import { PromptConfig } from '@app/shared/classes/PromptConfig';

@Injectable()
export class PromptService {
	constructor(private overlay: Overlay, private injector: Injector, private translate: TranslateService) {}

	create(component, params = null, options: PromptConfig = {}): PromptRef {
		const config: PromptConfig = {
			...options,
			minWidth: 400,
		};

		config.scrollStrategy = this.overlay.scrollStrategies.reposition();
		config.positionStrategy = this.overlay
			.position()
			.global()
			.centerHorizontally()
			.centerVertically();

		config.hasBackdrop = true;

		const overlayRef = this.overlay.create(config);

		overlayRef.backdropClick().subscribe(() => {});

		const promptRef = new PromptRef(overlayRef);
		const c = new ComponentPortal(PromptComponent, null, this.createInjector(params, promptRef, component, config));
		overlayRef.attach(c);

		return promptRef;
	}
	showReassignDialog(user: any): Promise<any> {
		return new Promise((resolve, reject) => {
			if (user && !user.selected && user.macId) {
				const promptRef = this.create(
					DevicesAssignedPromptComponent,
					{
						user,
					},
					{
						width: 482,
						successLabel: this.translate.instant('DEVICES_PAGE.ADD_DEVICE.CONTINUE'),
						successType: 'delete',
					},
				);

				promptRef
					.onDismiss()
					.then(resolve)
					.catch(reject);
			} else {
				resolve();
			}
		});
	}

	private createInjector(data, ref, component, config): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(DIALOG_DATA, data);
		injectorTokens.set(DIALOG_REF, ref);
		injectorTokens.set(DIALOG_CONFIG, config);
		injectorTokens.set(DIALOG_COMPONENT, component);

		return new PortalInjector(this.injector, injectorTokens);
	}
}
