import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SideNavRoute } from '@app/shared/left-nav-container/left-nav-container.domain';
import { CommonService, FeatureFlagsService, AuthStorageData } from '@app/services';
import { ROLE_SCORES } from '@app/services/web-apis/users/api-users.domain';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';

@Component({
	selector: 'app-left-nav-container',
	templateUrl: './left-nav-container.component.html',
	styleUrls: ['./left-nav-container.component.scss'],
})
export class LeftNavContainerComponent implements OnInit, OnDestroy {
	@Input() routes: SideNavRoute[] = [];

	@Input() title: string;
	@Input() subtitle: string;
	@Input() loading: boolean;
	isCallServerV3User: boolean;
	isEnableCompanyDirectory30: boolean;
	isDelegationEnabled: boolean;

	private routerSub: Subscription;

	get filteredRoutes(): SideNavRoute[] {
		this.isCallServerV3User = this.authStorageDataService.isV3CallServer;
		const userRoleScore = ROLE_SCORES.indexOf(this.commonService.userRole);

		return this.routes.filter(route => {
			const roleScore = ROLE_SCORES.indexOf(route.requiredRole);
			if (
				(route.name === 'Directory' && this.isCallServerV3User && !this.isEnableCompanyDirectory30) ||
				(route.name === 'Delegation' && !this.isDelegationEnabled)
			) {
				route.enabled = false;
			}
			return userRoleScore >= roleScore && route.enabled;
		});
	}

	public onExternalTermsAndPoliciesClick(): void {
		if (this.apiTenantService.isMexicoAccount()) {
			window.open('https://net2phone.mx/pagina-legal/aviso-de-privacidad');
		} else if (this.apiTenantService.isBrazilAccount()) {
			window.open('https://net2phone.com.br/juridico/');
		} else if (this.apiTenantService.isArgentinaAccount()) {
			window.open('https://net2phone.com.ar');
		}
	}

	constructor(
		private commonService: CommonService,
		private router: Router,
		private authStorageDataService: AuthStorageData,
		private featureFlagsService: FeatureFlagsService,
		private apiTenantService: ApiTenantService,
	) {}

	ngOnInit(): void {
		this.featureFlagsService.enableCompanyDirectory30().subscribe(res => (this.isEnableCompanyDirectory30 = res));
		this.featureFlagsService.isDelegationEnabled().subscribe(res => (this.isDelegationEnabled = res));
		this.routerSub = this.router.events.subscribe(route => {
			if (route instanceof NavigationStart) {
				this.loading = true;
			}

			if (route instanceof NavigationEnd) {
				this.loading = false;
			}
		});
	}

	ngOnDestroy(): void {
		this.routerSub.unsubscribe();
	}
}
