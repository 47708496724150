import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router } from '@angular/router';
import { environment } from '@env/environment';
import { DEFAULT_ROUTE } from '@app/Common';
import { JwtService } from '@app/services';

type AppType = 'classic' | 'admin' | 'uam' | 'sipTrunking' | 'sipTrunkingImpersonated';

const DEFAULT_ROUTES: Record<AppType, string> = {
	classic: DEFAULT_ROUTE,
	admin: '/company',
	uam: DEFAULT_ROUTE,
	sipTrunking: '/sip-trunking/trunks',
	sipTrunkingImpersonated: '/sip-trunking/trunks',
};

@Injectable({
	providedIn: 'root',
})
export class AppTypeGuard implements CanLoad, CanActivate {
	currentAppType: AppType = 'classic';

	constructor(private jwtService: JwtService, public router: Router) {
		if (this.jwtService.hasPolicy('siptrunk.account.only')) {
			this.currentAppType = this.jwtService.isImpersonated() ? 'sipTrunkingImpersonated' : 'sipTrunking';
		} else if (!environment.is_admin_app && !environment.is_pam_console) {
			this.currentAppType = 'classic';
		} else if (environment.is_admin_app && !environment.is_pam_console) {
			this.currentAppType = 'admin';
		} else if (!environment.is_admin_app && environment.is_pam_console) {
			this.currentAppType = 'uam';
		}
	}

	handleRouting(
		appTypeRouteData: Partial<{ app: Array<AppType>; customRedirects: Record<AppType, string>; redirectUrl: string }>,
	): boolean {
		if (appTypeRouteData?.app?.includes(this.currentAppType)) {
			if (Object.keys(appTypeRouteData?.customRedirects || {}).includes(this.currentAppType)) {
				this.router.navigate([appTypeRouteData.customRedirects[this.currentAppType]]);
			}
			return true;
		} else if (appTypeRouteData?.redirectUrl) {
			this.router.navigateByUrl(appTypeRouteData?.redirectUrl);
		} else {
			this.router.navigate([DEFAULT_ROUTES[this.currentAppType] || DEFAULT_ROUTE]);
		}
	}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		return this.handleRouting(route.data);
	}

	canLoad(route: Route): boolean {
		return this.handleRouting(route.data);
	}
}
