import { Component, Injector, Input, OnInit } from '@angular/core';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { MULTISELECT_CHIP, MULTISELECT_CHIP_CLICK_CALLBACK } from 'utils/constants/injectortokens';

@Component({
	selector: 'app-multi-select-dropdown-chip',
	templateUrl: './multi-select-dropdown-chip.component.html',
	styleUrls: ['./multi-select-dropdown-chip.component.scss'],
})
export class MultiSelectDropdownChipComponent implements OnInit {
	@Input() chip: any;
	@Input() chipComponent: any;
	@Input() clickCallback: any;
	chipComponentPortal: any;
	constructor(private injector: Injector) {}

	private createChipComponentInjector() {
		const injectorTokens = new WeakMap();
		injectorTokens.set(MULTISELECT_CHIP, this.chip);
		injectorTokens.set(MULTISELECT_CHIP_CLICK_CALLBACK, this.clickCallback);
		return new PortalInjector(this.injector, injectorTokens);
	}

	ngOnInit() {
		this.chipComponentPortal = new ComponentPortal(this.chipComponent, null, this.createChipComponentInjector());
	}
}
