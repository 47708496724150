import { avatarTypes } from '@app/Common';

export interface Avatar {
	size: string;
	url: string;
}

export interface CallsAnalytic {
	total: number;
	incoming: number;
	outgoing: number;
	missed: number;
	answered: number;
	avgDuration: number;
	avgResponse: number;
}

export interface UserAnalytic {
	calls: CallsAnalytic;
	name?: string;
	date?: string;
	uniteId?: string;
	active?: string;
	avatars?: Avatar[];
	role?: string;
	id?: string;
}

export interface Analytic {
	total?: number;
	calls?: CallsAnalytic;
	list?: UserAnalytic[];
}

export interface GraphAnalytic {
	id: number;
	name: string;
	totalCalls: number;
}

export interface GraphAnalytics {
	data: GraphAnalytic[];
	type: avatarTypes;
}

export interface EntityAnalytic {
	avgResponse: number;
	avgDuration: number;
}

export const emptyAnalytic = (): Analytic => ({
	calls: {
		total: 0,
		incoming: 0,
		outgoing: 0,
		missed: 0,
		answered: 0,
		avgDuration: 0,
		avgResponse: 0,
	},
	list: [],
});
