import { Inject, Injectable, InjectionToken } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { StorageSaveMode, StorageService } from '@app/services/storage/storage';

export const APP_STORAGE_IMPLEMENTATION = new InjectionToken('storageImplementation');
export const APP_STORAGE_PREFIX = new InjectionToken('storagePrefix');

@Injectable()
export class AppLocalStorageService extends StorageService {
	constructor(@Inject(APP_STORAGE_PREFIX) prefix: string, @Inject(DOCUMENT) document: Document) {
		// Safari in incognito window should fallback to sessionStorage
		super(
			prefix,
			[document.defaultView.localStorage, document.defaultView.sessionStorage],
			StorageSaveMode.MULTIPLE_KEYS,
			document.defaultView,
		);
	}
}

@Injectable()
export class AppSessionStorageService extends StorageService {
	constructor(@Inject(APP_STORAGE_PREFIX) prefix: string, @Inject(DOCUMENT) document: Document) {
		super(prefix, document.defaultView.sessionStorage, StorageSaveMode.MULTIPLE_KEYS, document.defaultView);
	}
}

@Injectable()
export class AppConditionalStorageService extends StorageService {
	constructor(
		@Inject(APP_STORAGE_IMPLEMENTATION) storageToUse: 'local' | 'session',
		@Inject(APP_STORAGE_PREFIX) prefix: string,
		@Inject(DOCUMENT) document: Document,
	) {
		super(
			prefix,
			storageToUse === 'local'
				? [document.defaultView.localStorage, document.defaultView.sessionStorage]
				: document.defaultView.sessionStorage,
			StorageSaveMode.MULTIPLE_KEYS,
			document.defaultView,
		);
	}
}
