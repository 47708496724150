import { Component, Inject, OnInit } from '@angular/core';

import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { PromptRef } from '@app/shared/classes/PromptRef';
import { IPhone } from '@app/Common/interfaces/IPhone';
import { getInitialsFromString } from 'utils/helpers/dropdown';

@Component({
	selector: 'app-assigned-numbers-prompt',
	templateUrl: './assigned-numbers-prompt.component.html',
	styleUrls: ['./assigned-numbers-prompt.component.scss'],
})
export class AssignedNumbersPromptComponent implements OnInit {
	name: string;
	type: string;
	phone: IPhone;
	targetUser: any;
	getInitialsFromString: Function = getInitialsFromString;
	constructor(
		@Inject(DIALOG_DATA) data: { phone: IPhone; name: string; type: string; targetUser?: any },
		@Inject(DIALOG_REF) promptRef: PromptRef,
	) {
		this.name = data.name;
		this.type = data.type;
		this.phone = data.phone;
		this.targetUser = data.targetUser;
	}

	ngOnInit() {}
}
