import { Component, EventEmitter, OnInit, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { IPredictedAddress } from '@n2p/predicted-address/predicted-address.domain';
import { IServiceAddress } from '@app/pages/teammembers/newserviceaddress/new-service-address.domain';

@Component({
	selector: 'app-new-service-address-us',
	templateUrl: './new-service-address-us.component.html',
	styleUrls: ['../../new-service-address.component.scss'],
})
export class NewServiceAddressComponentUs implements OnInit, OnDestroy {
	addressForm: FormGroup;

	@Output() onChange: EventEmitter<{
		valid: boolean;
		value: IServiceAddress;
	}> = new EventEmitter();

	private subscriptions: Subscription[] = [];

	ngOnInit(): void {
		this.addressForm = new FormGroup({
			zip: new FormControl('', [Validators.required]),
			locality: new FormControl(''),
			city: new FormControl('', Validators.required),
			address1: new FormControl('', Validators.required),
			address2: new FormControl(''),
			state: new FormControl('', Validators.required),
			country: new FormControl('', Validators.required),
		});

		this.subscriptions.push(
			this.addressForm.valueChanges.subscribe(value =>
				this.onChange.emit({
					valid: this.addressForm.valid,
					value,
				}),
			),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	setPredictedAddress(address: IPredictedAddress): void {
		this.addressForm.setValue({
			...this.addressForm.value,
			country: address.countryCode,
			state: address.stateCode,
			city: address.cityCode,
			locality: address.localityCode,
		});
	}

	setPredictedState(address: IPredictedAddress): void {
		this.addressForm.setValue({
			...this.addressForm.value,
			country: address.countryCode,
			state: address.stateCode,
		});
	}
}
