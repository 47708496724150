import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Data, Route, Router } from '@angular/router';

import { CommonService } from '@app/services';
import { ROLE_SCORES } from '@app/services/web-apis/users/api-users.domain';

@Injectable({
	providedIn: 'root',
})
export class RoleGuard implements CanLoad, CanActivate {
	constructor(private commonService: CommonService, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		return this.check(route.data);
	}

	canLoad(route: Route): boolean {
		return this.check(route.data);
	}

	private check(data: Data): boolean {
		const result = this.checkUserRoleScore(data);

		if (!result) {
			this.router.navigateByUrl(data.redirectUrl || '');
		}

		return result;
	}

	private checkUserRoleScore(routeData: Data): boolean {
		if (!routeData.requiredRole) {
			return true;
		}

		const userRoleScore = ROLE_SCORES.indexOf(this.commonService.userRole);
		const requiredRoleScore = ROLE_SCORES.indexOf(routeData.requiredRole);

		return userRoleScore >= requiredRoleScore;
	}
}
