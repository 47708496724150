import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { sortObjsByProp } from '@utils/helpers/functions';
import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import {
	ApiDepartmentsService,
	ApiFeaturesService,
	ApiPhoneNumbersService,
	ApiUsersService,
	GlobalLoaderService,
	Loader,
	SnackbarService,
} from '@app/services';
import { getInitialsFromString } from 'utils/helpers/dropdown';
import { PhoneNumberFormatterPipe } from '@app/pipes';
import { IErrorMessage, IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { IDepartment, IDepartmentUser } from '@app/services/web-apis/departments/api-departments.domain';
import { IUser } from '@app/services/web-apis/users/api-users.domain';
import { IAccountPhone } from '@app/services/web-apis/phone-numbers/api-phone-numbers.domain';
import { SnackbarConfigStatuses } from '@app/shared/classes/SnackbarConfig';
import { IVoicemailObject } from '@n2p/edit-voicemail/edit-voicemail.interfaces';
import { FeatureType, OwnerType } from '@app/services/web-apis/features/api-features.domain';

@Component({
	selector: 'edit-department',
	templateUrl: './edit-department.component.html',
	styleUrls: ['./edit-department.component.scss'],
	providers: [PhoneNumberFormatterPipe, GlobalLoaderService],
})
export class EditDepartmentComponent implements OnInit {
	public departmentMenuItems: string[] = [
		this.translate.instant('EDIT_DEPARTMENT.DEPARTMENT'),
		this.translate.instant('EDIT_DEPARTMENT.OPTIONS'),
		this.translate.instant('EDIT_DEPARTMENT.VOICEMAIL'),
	];
	public activeMenuItem: string = this.translate.instant('EDIT_DEPARTMENT.DEPARTMENT');
	public model: any;
	public accountNumbers: IAccountPhone[];
	public departmentNumbers: string[];
	public originalDepartmentNumbers: string[];
	public departmentMembers: IUser[];
	public originalDepartmentMembers: IUser[];
	public accountUsers: any[];
	public accountUserMap: any = {};
	public itemsProp: any;
	public data: any;
	public setNumbers: any;
	public getInitials: any = getInitialsFromString;
	public ringsCount: string = this.translate.instant('OPTIONS_TAB.RINGS_COUNT');
	public rings: Array<{ value: string; count: number; selected: boolean }>;
	public isCallRecordingActive: boolean = false;
	@Output() numbersSaved: EventEmitter<{ numbers: Array<any> } | Event> = new EventEmitter();
	loader: Loader;
	@ViewChild('bodySectionRef', { static: true }) bodySectionRef: ElementRef;
	loadingUsers: boolean = true;
	loadingPhoneNumbers: boolean = true;

	constructor(
		@Inject(DIALOG_DATA) private dialogData: any,
		@Inject(DIALOG_REF) private dialogRef: any,
		private snackbarService: SnackbarService,
		private apiPhoneNumbers: ApiPhoneNumbersService,
		private apiDepartments: ApiDepartmentsService,
		private apiUsers: ApiUsersService,
		private translate: TranslateService,
		private phoneNumberFormatterPipe: PhoneNumberFormatterPipe,
		private globalLoader: GlobalLoaderService,
		private apiFeatures: ApiFeaturesService,
	) {}

	ngOnInit(): void {
		this.showLoader();
		this.initRingsArray();
		this.model = this.dialogData.department;
		this.setNumbers = this.dialogData.setNumbers;

		this.apiUsers.getAllUsers(false).subscribe((result: IRegularApiResponse<IUser[]>) => {
			if (!result.hasError && result.data) {
				const mappedData = result.data.map((i: IUser) => ({
					...i,
					value: `${i.firstName} ${i.lastName}`,
					subValue: i.extension,
					type: 'team-member',
				}));
				this.accountUsers = sortObjsByProp('value', mappedData);
				this.accountUserMap = this.userArrayToMap(this.accountUsers);
				this.setDepartmentData(this.model);
				this.loadingUsers = false;
				this.hideLoader();
			}
		});

		this.apiPhoneNumbers.getAllPhoneNumbers().subscribe((res: IRegularApiResponse<IAccountPhone[]>) => {
			if (!res.hasError) {
				this.accountNumbers = res.data;
				const cleanNumbers = this.accountNumbers
					.filter((n: IAccountPhone) => n.routeToId === this.model.deptId)
					.map(n => n.number);
				this.originalDepartmentNumbers = [...cleanNumbers];
				this.departmentNumbers = cleanNumbers;
				this.loadingPhoneNumbers = false;
				this.hideLoader();
			}
		});

		this.apiFeatures.getFeature(OwnerType.DEPARTMENTS, this.model.deptId, FeatureType.RECORD).subscribe(result => {
			this.isCallRecordingActive = result;
		});
	}

	showLoader() {
		this.loader = this.globalLoader.create(this.bodySectionRef);
		this.loader.show();
	}
	hideLoader() {
		if (!this.loadingUsers && !this.loadingPhoneNumbers) this.loader.hide();
	}

	/*
		several API routes return user ID's, but no other user data, let's use this to reference
		TODO: move this to state store logic when it's implemeted
	*/
	userArrayToMap(users: IUser[]): any {
		let dataMap = {};
		users.forEach((u: IUser) => {
			dataMap[u.userId] = { ...u };
		});
		return dataMap;
	}

	/*
		normalize and set the source of truth for a department
		our save method does comparisons to add statuses A or D to certain or omit data
		we also currently have to omit an unchanged name/extension otherwise
		we trigger validation and web api will return "already taken" errors \(-__-.)/
	*/
	setDepartmentData(department: IDepartment): any {
		this.model = department;
		let cleanMembers = department.members
			? department.members
					.filter((m: IDepartmentUser) => m.userId)
					.map((n: IDepartmentUser) => {
						let user: any = this.accountUsers.find((u: IUser) => {
							return u.userId === n.userId;
						});
						user.selected = true;
						return {
							...user,
							type: 'team-member',
							selected: true,
							value: `${user.firstName} ${user.lastName}`,
						};
					})
			: [];
		this.departmentMembers = cleanMembers;
		this.originalDepartmentMembers = [...cleanMembers];
	}

	onVoicemailUpdate(settings: IVoicemailObject) {
		this.model.voicemailEnabled = settings.isVoicemailEnabled;
		this.model.voicemailNotification.emailNotify = settings.isNotificationEnabled;
		this.model.voicemailNotification.emailIncludeVM = settings.isFileIncluded;
		this.model.voicemailNotification.emailTranscribe = settings.isTranscriptIncluded;
		this.model.voicemailNotification.emailIncludeCallerDetails = settings.isCallerDetailsIncluded;
	}

	saveDepartment = (event: any): any => {
		// apply our api rules here before saving
		let extension = event.data && event.data.get('extension');
		let name = event.data && event.data.get('name');
		let members = event.members || this.model.members;
		let voicemailEnabled = event.voicemailEnabled;
		let voicemailPin = event.voicemailPin;
		let ruleType = event.ruleType;

		let originalList = this.originalDepartmentMembers.map((n: IUser) => {
			// if member was changed
			if (members.find((num: any) => num.userId === n.userId)) {
				// unchanged
				return {
					userId: n.userId,
					status: 'A',
				};
			} else {
				// if member was removed
				return {
					userId: n.userId,
					status: 'D',
				};
			}
		});
		// get brand new members
		let newList = members.map((n: any) => {
			if (!this.originalDepartmentMembers.find((num: any) => num.userId === n.userId)) {
				return {
					userId: n.userId,
					status: 'A',
				};
			}
		});
		// combine the member lists and save them
		let memberList = [...originalList, ...newList].filter((m: any) => m && m.userId);

		let departmentJson = {
			deptId: this.model.deptId,
			extension: extension && extension.value !== this.model.extension ? extension.value : undefined,
			name: name && name.value !== this.model.name ? name.value : undefined,
			members: memberList,
			status: 'A',
			voicemailEnabled: voicemailEnabled,
			voicemailPin: voicemailPin,
		};

		let context = this;
		this.apiDepartments.updateDepartment(departmentJson).subscribe((res: IRegularApiResponse<IDepartment>) => {
			if (res.hasError || res.errorMessages.length) {
				let errors = res.errorMessages.map((r: IErrorMessage) => r.message).join(' ');
				this.createSnackbar(errors, 'danger');
			} else {
				if (res) {
					// success state
					this.createSnackbar(
						event.snackbarMessage ? event.snackbarMessage : this.translate.instant('EDIT_DEPARTMENT.DEPARTMENT_SAVED'),
					);
					// update our source of truth
					this.setDepartmentData(res.data);
				}
			}
		});
	};

	/*
		method for member changes
		works with dropdown changes
	*/
	saveMemberChanges(event: any): any {
		const isTheSame =
			this.departmentMembers.length === event.members.length &&
			this.departmentMembers.every((m: IUser, i: number) => m.userId === event.members[i].userId);

		if (!isTheSame) {
			const users = event.members.map(m => {
				return {
					userId: m.userId,
				};
			});

			this.apiDepartments.updateDepartmentUsers(this.model.deptId, users).subscribe(res => {
				const isError = res.hasError || res.errorMessages.length;
				const status = isError ? 'danger' : 'success';
				const text = isError
					? res.errorMessages.map((r: IErrorMessage) => r.message).join(' ')
					: this.translate.instant('EDIT_DEPARTMENT.MEMBER_CHANGED');

				if (!isError) {
					this.departmentMembers = event.members;
				}

				this.createSnackbar(text, status);
			});
		}
	}

	saveNumberChanges(event: any): void {
		const departmentId = this.model.deptId;
		const newList = [];
		const numbers = event.numbers || [];
		numbers.forEach((n: any) => {
			if (!this.originalDepartmentNumbers.find(i => i === n)) {
				newList.push({
					number: n,
					routeToId: departmentId,
					routeType: 'department',
					routesTo: `${departmentId}`,
					status: 'A',
				});
			}
		});

		this.originalDepartmentNumbers.forEach(n => {
			if (!numbers.find((i: any) => i === n)) {
				newList.push({
					number: n,
					routeToId: 0,
					routesTo: 'disconnect',
					status: 'A',
				});
			}
		});

		if (!newList.length) return;
		const promises = [];
		newList.forEach((num: any, idx: any) => {
			promises.push(this.apiPhoneNumbers.updatePhoneNumber(num).toPromise());
		});

		Promise.all(promises).then((res: IRegularApiResponse<unknown>[]) => {
			if (res[0].hasError || res[0].errorMessages.length) {
				const errors = res[0].errorMessages.map((r: IErrorMessage) => r.message).join(' ');
				this.createSnackbar(errors, 'danger');
			} else {
				// success state
				this.createSnackbar(this.translate.instant('EDIT_DEPARTMENT.DEPARTMENT_SAVED'));
				this.originalDepartmentNumbers = numbers;
				this.departmentNumbers = numbers;
			}
			if (newList.length > 0) {
				this.setNumbers();
			}
		});
	}

	saveCallRecordingChanges(isActive: boolean): void {
		this.isCallRecordingActive = isActive;
		this.apiFeatures.saveFeature(OwnerType.DEPARTMENTS, this.model.deptId, FeatureType.RECORD, isActive).subscribe();
	}

	closeModal(): void {
		this.dialogRef.dismiss();
	}

	private initRingsArray(): void {
		this.rings = [];
		for (let i = 1; i < 21; i++) {
			this.rings.push({ value: `${i} ` + this.ringsCount, count: i, selected: false });
		}
	}

	private createSnackbar(text: string, status: SnackbarConfigStatuses = 'success'): void {
		this.snackbarService.create({ status, text, connectTo: this.bodySectionRef });
	}
}
