import { getInitialsFromNames } from 'n2p-ui-library';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

/**
 * List of possible types:
 * department
 * phone
 * phone-forward
 * phone-number (with routeTypes: User, Department, WelcomeMenu, and RingGroup)
 * ring-group
 * team-member
 * welcome-menu
 *
 * TODO needs implementation
 * team-member-voicemail
 * department-voicemail
 */
@Component({
	selector: 'app-avatar-icon',
	templateUrl: './avatar-icon.component.html',
	styleUrls: ['./avatar-icon.component.scss'],
})
export class AvatarIconComponent implements OnInit, OnChanges {
	@Input() data: any;

	@Input() inputImageSize = 120;
	@Input() size = 24;
	@Input() fontSize = 11;
	@Input() iconType = '';

	imageUrl = '';
	initials = '';
	isPhone = false;

	constructor() {}

	ngOnInit() {
		this.setIconType();
	}

	ngOnChanges(): void {
		this.setIconType();
	}

	private setIconType(): void {
		switch (this.iconType) {
			case 'department':
				if (!this.setName()) {
					this.initials = 'd';
				}
				break;
			case 'phone':
				this.isPhone = true;
				break;
			case 'phone-forward':
				this.isPhone = true;
				break;
			case 'phone-number':
				this.setIconFromPhoneNumber();
				break;
			case 'ring-group':
				this.initials = 'r';
				break;
			case 'team-member':
				if (!this.hasTeamMemberIcon()) {
					this.initials = 't';
				}
				break;
			case 'welcome-menu':
				this.initials = 'w';
				break;
			case 'special-extension':
				this.initials = 'e';
				break;
			case 'special-extension':
				this.initials = 'e';
				this.iconType;
				break;
			case 'call-queue':
				this.setIconFromPhoneNumber();
				break;
			default:
				this.initials = '';
				this.iconType = '';
		}
	}

	private setInitials(name: string, lastName?: string): void {
		this.initials = getInitialsFromNames(name, lastName);
	}

	private hasUrlOrInitials(): boolean {
		return !!this.imageUrl || !!this.initials;
	}

	private setIconFromPhoneNumber(): void {
		try {
			const { routeType, routesTo } = this.data;

			switch (routeType.toLowerCase()) {
				case 'department':
					this.setInitials(routesTo);
					this.iconType = 'department';
					break;
				case 'menu':
					this.initials = 'w';
					this.iconType = 'welcome-menu';
					break;
				case 'ringgroup':
					this.initials = 'r';
					this.iconType = 'ring-group';
					break;
				case 'user':
					this.iconType = 'team-member';
					if (!this.hasTeamMemberIcon()) {
						this.setInitials(
							routesTo
								.split(' ')
								.reverse()
								.join(' '),
						);
					}
					break;
				case 'specialextension':
					this.initials = 'e';
					this.iconType = 'special-extension';
					break;
				case 'callqueue':
					this.initials = 'c';
					this.iconType = 'call-queue';
					break;
				default:
					this.initials = '';
					this.iconType = '';
			}
		} catch (e) {}
	}

	private hasTeamMemberIcon(): boolean {
		try {
			this.hasUserAvatar();
		} catch (e) {}

		return this.hasUrlOrInitials();
	}

	private hasUserAvatar(): boolean {
		try {
			const { userInfo, firstName, lastName, userName } = this.data;
			let avatars = userInfo ? userInfo.avatars : this.data.avatars;

			let filteredAvatars = [];
			if (avatars && avatars.length) {
				// remove messenger generated icons
				filteredAvatars = avatars.filter(avatar => avatar.url.endsWith('.jpg'));
			}
			if (filteredAvatars.length) {
				// get requested size with fallback to original
				const selected = filteredAvatars.find(avatar => avatar.size === this.inputImageSize) || filteredAvatars[0];
				this.imageUrl = selected.url;
			} else if (firstName) {
				this.setInitials(firstName, lastName);
			} else if (userInfo.name) {
				this.setInitials(userInfo.name);
			} else if (userName) {
				this.setInitials(userName);
			}
		} catch (e) {}

		return this.hasUrlOrInitials();
	}

	private setName() {
		try {
			if (this.data.name) {
				this.setInitials(this.data.name);
			}
		} catch (e) {}

		return this.hasUrlOrInitials();
	}
}
