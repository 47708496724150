import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiScheduleService, AuthStorageData } from '@app/services';
import { ApiTimezoneService } from '@app/services/web-apis/timezone/api-timezone.service';
import { DIALOG_REF } from '@n2p/dialog/dialog.injectors';
import { DialogRef } from '@n2p/dialog/dialogRef';
import { noTrimValidator } from '@utils/helpers/validators';

@Component({
	selector: 'app-add-schedule',
	templateUrl: './add-schedule.component.html',
	styleUrls: ['./add-schedule.component.scss'],
})
export class AddScheduleComponent implements OnInit {
	@ViewChild('bodySectionRef', { static: true }) bodySectionRef: ElementRef;
	@ViewChild('footerRef', { static: true }) footerRef: ElementRef;
	scheduleForm: FormGroup;
	timezones: Array<any>;
	dialogRef: DialogRef;
	constructor(
		private fb: FormBuilder,
		private timezoneService: ApiTimezoneService,
		private apiScheduService: ApiScheduleService,
		private authStorageDataService: AuthStorageData,
		@Inject(DIALOG_REF) dialogRef: DialogRef,
	) {
		this.dialogRef = dialogRef;
		this.scheduleForm = this.fb.group({
			name: ['', [Validators.required, Validators.maxLength(50), noTrimValidator]],
			timezone: [[], Validators.required],
			intervals: this.fb.array([
				this.fb.group({
					name: 'rule1',
				}),
			]),
		});
	}

	get showStickyData(): boolean {
		return this.bodySectionRef && this.bodySectionRef.nativeElement.scrollTop > 100;
	}

	addInterval(): void {
		const formArray = this.scheduleForm.get('intervals') as FormArray;
		const length = formArray.length;
		formArray.push(
			this.fb.group({
				name: `rule${length}`,
			}),
		);
	}

	removeInterval(index): void {
		(this.scheduleForm.get('intervals') as FormArray).removeAt(index);
	}

	close(): void {
		this.dialogRef.dismiss();
	}

	async ngOnInit(): Promise<any> {
		const response = await this.timezoneService.getTimezones();
		if (response && response.length) {
			this.timezones = response.map(i => {
				return {
					value: `${this.timezoneService.getTimezoneFormattedAbbreviation(i.abbreviation)} - ${i.name}`,
					abbreviation: i.abbreviation,
					selected: false,
				};
			});
			const selectedTimezone = this.timezoneService.findTimezone(this.authStorageDataService.timezone);
			this.scheduleForm.get('timezone').setValue([
				{
					value: `${this.timezoneService.getTimezoneFormattedAbbreviation(selectedTimezone.abbreviation)} - ${
						selectedTimezone.name
					}`,
					abbreviation: selectedTimezone.abbreviation,
					selected: true,
				},
			]);
		}
	}
}
