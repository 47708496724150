import { NgModule } from '@angular/core';

import { ApiAccountsService } from '@app/services/web-apis/accounts/api-accounts.service';
import { ApiAudioService } from '@app/services/web-apis/audio/api-audio.service';
import { ApiDepartmentsService } from '@app/services/web-apis/departments/api-departments.service';
import { ApiDevicesService } from '@app/services/web-apis/devices/api-devices.service';
import { ApiChatSettingsService } from '@app/services/web-apis/api-chat-settings/api-chat-settings.service';
import { ApiExtensionsService } from '@app/services/web-apis/extensions/api-extensions.service';
import { ApiFeaturesService } from '@app/services/web-apis/features/api-features.service';
import { ApiMessengerService } from '@app/services/web-apis/messenger/api-messenger.service';
import { ApiMusicOptionsService } from '@app/services/web-apis/music-options/api-music-options.service';
import { ApiPhoneNumbersService } from '@app/services/web-apis/phone-numbers/api-phone-numbers.service';
import { ApiPlansService } from '@app/services/web-apis/plans/api-plans.service';
import { ApiRingGroupsService } from '@app/services/web-apis/ring-groups/api-ring-groups.service';
import { ApiTokenService } from '@app/services/web-apis/token/api-token.service';
import { ApiUsersService } from '@app/services/web-apis/users/api-users.service';
import { ApiVoicemailsService } from '@app/services/web-apis/voicemails/api-voicemails.service';
import { ApiWelcomeMenusService } from '@app/services/web-apis/welcome-menus/api-welcome-menus.service';
import { ApiScheduleService } from '@app/services/web-apis/schedules/api-schedules.service';
import { ApiSpecialExtensionsService } from '@app/services/web-apis/special-extensions/api-special-extensions.service';
import { ApiTimezoneService } from '@app/services/web-apis/timezone/api-timezone.service';
import { ApiService } from '@app/services/api';
import { ApiDirectoriesService } from '@app/services/web-apis/directories/api-directories.service';
import { ApiAnalyticsService } from '@app/services/web-apis/analytics/api-analytics.service';
import { ApiInternationalCallingService } from '@app/services/web-apis/international-calling/international-calling.service';
import { OutboundCallBlockingService } from './web-apis/call-blocking/outbound-call-blocking.service';
import { ApiCallQueuesService } from '@app/services/web-apis/call-queues/api-call-queues.service';
import { SdkService } from '@app/services/sdk/sdk.service';
import { ApiTenDlcService } from '@app/services/web-apis/ten-dlc/api-ten-dlc.service';
import { ApiFailoverService } from './web-apis/phone-numbers-failover/api-phone-numbers-failover.service';

@NgModule({
	providers: [
		ApiAccountsService,
		ApiAudioService,
		ApiChatSettingsService,
		ApiDepartmentsService,
		ApiDevicesService,
		ApiExtensionsService,
		ApiFeaturesService,
		ApiMessengerService,
		ApiPhoneNumbersService,
		ApiPlansService,
		ApiRingGroupsService,
		ApiTokenService,
		ApiUsersService,
		ApiVoicemailsService,
		ApiWelcomeMenusService,
		ApiService,
		SdkService,
		ApiScheduleService,
		ApiSpecialExtensionsService,
		ApiMusicOptionsService,
		ApiTimezoneService,
		ApiDirectoriesService,
		ApiAnalyticsService,
		ApiInternationalCallingService,
		OutboundCallBlockingService,
		ApiCallQueuesService,
		ApiTenDlcService,
		ApiFailoverService,
	],
})
export class WebApisModule {}
