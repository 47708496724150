import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DragAndDropFilesDirective } from '@app/directives/drag-and-drop-files/drag-and-drop-files.directive';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { EditIconBtnDirective } from './edit-icon-btn/edit-icon-btn.directive';
import { RemoveIconBtnDirective } from './remove-icon-btn/remove-icon-btn.directive';
import { DragSortDirective } from './drag-sort/drag-sort.directive';
import { VerticalScrollerDirective } from './vertical-scroller/vertical-scroller.directive';
import { NumericDirective } from './numeric/numeric.directive';
import { ScrollingCheckDirective } from './scrolling-check/scrolling-check.directive';
import { AutoFocusDirective } from './auto-focus/auto-focus.directive';
import { RestrictInputDirective } from './restrict-input/restrict-input.directive';

const directives = [
	TooltipDirective,
	EditIconBtnDirective,
	RemoveIconBtnDirective,
	DragSortDirective,
	VerticalScrollerDirective,
	DragAndDropFilesDirective,
	NumericDirective,
	ScrollingCheckDirective,
	AutoFocusDirective,
	RestrictInputDirective,
];

@NgModule({
	imports: [CommonModule],
	declarations: directives,
	exports: directives,
})
export class DirectivesModule {}
