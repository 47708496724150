import { Component, OnInit } from '@angular/core';

@Component({
	/* tslint:disable */
	selector: 'n2p-input-hint',
	templateUrl: './input-hint.component.html',
	styleUrls: ['./input-hint.component.scss'],
	/* tslint:enable */
})
export class InputHintComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
