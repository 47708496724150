import { Component, Inject, Injector, OnInit } from '@angular/core';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { DIALOG_COMPONENT, DIALOG_CONFIG, DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { PromptRef } from '../classes/PromptRef';
import { PromptConfig } from '../classes/PromptConfig';

@Component({
	selector: 'app-prompt',
	templateUrl: './prompt.component.html',
	styleUrls: ['./prompt.component.scss'],
})
export class PromptComponent implements OnInit {
	component: any;
	promptConfig: PromptConfig;
	constructor(
		@Inject(DIALOG_DATA) private data: any,
		@Inject(DIALOG_REF) private modalRef: PromptRef,
		@Inject(DIALOG_COMPONENT) private modalComponent: any,
		@Inject(DIALOG_CONFIG) private config: PromptConfig,
		private injector: Injector,
	) {
		this.promptConfig = config;
	}

	createInjector(data, ref): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(DIALOG_DATA, data);
		injectorTokens.set(DIALOG_REF, ref);
		return new PortalInjector(this.injector, injectorTokens);
	}

	close = () => {
		this.modalRef.cancel();
	};

	accept() {
		this.modalRef.accept();
	}

	reject() {
		this.close();
	}

	ngOnInit() {
		this.component = new ComponentPortal(this.modalComponent, null, this.createInjector(this.data, this.modalRef));
	}
}
