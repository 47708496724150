import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractControlValueAccessor } from '@app/Common/classes/AbstractControlValueAccessor';

@Component({
	selector: 'n2p-radio-button',
	templateUrl: './radio-button.component.html',
	styleUrls: ['./radio-button.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RadioButtonComponent),
			multi: true,
		},
	],
})
export class RadioButtonComponent extends AbstractControlValueAccessor implements OnInit {
	@Input() label: string;
	@Input() val;
	@Input() checked: boolean;
	@Input() name?: string;

	constructor() {
		super();
	}

	ngOnInit(): void {
		this.name = this.name ?? 'app-radio';
	}

	get isChecked(): boolean {
		if (typeof this.checked !== 'undefined') return this.checked;
		return this.value === this.val;
	}

	clicked() {
		this.value = this.val;
	}
}
