import { ElementRef } from '@angular/core';
import { OriginConnectionPosition, OverlayConnectionPosition } from '@angular/cdk/overlay';

export enum IDropdownPositionType {
	DROPDOWN,
	COVER,
}

export interface IDropdownOptions {
	elem: ElementRef | HTMLElement;
	positionType: IDropdownPositionType;
	widthByElem?: boolean;
}

export interface IDropdownConnectionPosition {
	originPos: OriginConnectionPosition;
	overlayPos: OverlayConnectionPosition;
}

export const DROPDOWN_CONNECTION_POSITION_MAP: { [key: string]: IDropdownConnectionPosition } = {
	[IDropdownPositionType.DROPDOWN]: {
		originPos: { originY: 'bottom', originX: 'start' },
		overlayPos: { overlayY: 'top', overlayX: 'start' },
	},
	[IDropdownPositionType.COVER]: {
		originPos: { originY: 'top', originX: 'start' },
		overlayPos: { overlayY: 'top', overlayX: 'start' },
	},
};
