import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '@app/services/api';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';

@Injectable({
	providedIn: 'root',
})
export class ApiChatSettingsService {
	private baseUrl = '/accounts/{accountId}/chat';

	constructor(private apiService: ApiService) {}

	sentEmailWithWidgetCode(emailTo: string, emailMessage: string): Observable<IRegularApiResponse<any>> {
		const requestData = { emailTo: emailTo, emailMessage: emailMessage };
		return this.apiService.post(`${this.baseUrl}/snippet/email`, requestData);
	}
}
