import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { EntityPhoneDropdownDataComponent } from '@app/pages/special-extensions/components/edit-special-extensions/entity-phone-dropdown-data/entity-phone-dropdown-data.component';
import { AvatarModule, DropdownModule, IconModule } from '@n2p';

@NgModule({
	imports: [CommonModule, SharedModule, IconModule, AvatarModule, DropdownModule],
	declarations: [EntityPhoneDropdownDataComponent],
	exports: [EntityPhoneDropdownDataComponent],
	entryComponents: [EntityPhoneDropdownDataComponent],
})
export class EntityPhoneDropdownDataModule {}
