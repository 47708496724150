import { Component, Input, OnInit } from '@angular/core';

import { CallOptionsService } from '@app/pages/teammembers/call-options/call-options.service';
import { IUser } from '@app/services/web-apis/users/api-users.domain';

@Component({
	selector: 'n2p-call-options',
	templateUrl: './call-options.component.html',
	styleUrls: ['../call-options.shared.scss'],
})
export class CallOptionsComponent implements OnInit {
	@Input() user: IUser;
	@Input() loading: boolean = false;

	private isRecordingLoaded: boolean = false;

	constructor(public callOptionsService: CallOptionsService) {}

	ngOnInit(): void {
		this.fetchData();
	}

	get isLoaded(): boolean {
		return !this.loading && this.isRecordingLoaded;
	}

	private fetchData(): void {
		this.callOptionsService.fetchCallRecording().subscribe({
			complete: (): boolean => (this.isRecordingLoaded = true),
		});
	}
}
