import { Subject } from 'rxjs/Subject';

interface ListItem {
	type: string;
	text: string;
	imgUrl?: string;
	iconName?: string;
	children?: ListItem[];
	parentList?: ListItem[];
	index: number;
	listId: string;
}

export class DropdownService {
	allowMultiSelect = false;
	addedListItems: ListItem[] = [];
	defaultItemText: string;

	addedListItemsSubject: Subject<any> = new Subject();

	addOrRemoveItem(item: ListItem) {
		const itemIndex = this.getItemIndex(item);

		if (itemIndex !== undefined) {
			//item is added, therefore remove
			this.addedListItems.splice(itemIndex, 1);
		} else if (this.allowMultiSelect) {
			//add it

			if (this.defaultItemText) {
				if (item.text == this.defaultItemText) {
					this.addedListItems = [];
				}
				//auto remove default field in case of something selected
				if (this.addedListItems.length > 0 && item.text != this.defaultItemText) {
					this.addedListItems = this.addedListItems.filter(i => i.text != this.defaultItemText);
				}
			}

			this.addedListItems.push(item);
		} else {
			//replace it
			//we must modify the existing reference array rather than replace it since the component
			//using the service sets it's reference to this addedListItems on initialization
			while (this.addedListItems.length > 0) {
				this.addedListItems.pop();
			}
			this.addedListItems.push(item);
		}

		this.addedListItemsSubject.next(this.addedListItems);
	}

	private getItemIndex(item: ListItem) {
		let itemIndex;

		for (let index in this.addedListItems) {
			const addedItem = this.addedListItems[index];
			if (addedItem.listId === item.listId) {
				itemIndex = index;
				break;
			}
		}

		return itemIndex;
	}
}
