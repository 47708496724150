import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PipesModule } from '@app/pipes';
import { AvatarModule, IconModule, LoaderModule } from '@n2p';
import { AudioPlayerModule } from '@n2p/audio-player/audio-player.module';
import { ButtonPlayModule } from '@n2p/button/button-play/button-play.module';
import { HeaderOptionBtnDirective } from '@n2p/header/help-support-btn/header-option-btn.directive';
import { HelpSupportPopupComponent } from '@n2p/header/help-support-popup/help-support-popup.component';
import { VoiceMailItemComponent } from '@n2p/header/voice-mail-popup/voice-mail-item/voice-mail-item.component';
import { LeftSideNavModule } from '@n2p/left-side-nav/left-side-nav.module';
import { SvgModule } from '@n2p/svg';
import { TooltipModule } from '@n2p/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header.component';
import { VoiceMailPopupComponent } from './voice-mail-popup/voice-mail-popup.component';
import { ProfilePopupComponent } from './profile-popup/profile-popup.component';
import { TemplatedPopupModule } from '@n2p/templated-popup/templated-popup.module';
import { CallBlockingAddNewModule } from '@n2p/call-blocking-add-new/call-blocking-add-new.module';
import { DialerComponent } from './dialer/dialer.component';
import { BlockCallerComponent } from './block-caller/block-caller.component';
import { CallBlockingConfirmPopupModule } from '@n2p/call-blocking-confirm-popup/call-blocking-confirm-popup.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PremiumBannerComponent } from './premium-banner/premium-banner.component';

@NgModule({
	declarations: [
		HeaderComponent,
		HeaderOptionBtnDirective,
		VoiceMailPopupComponent,
		HelpSupportPopupComponent,
		ProfilePopupComponent,
		VoiceMailItemComponent,
		DialerComponent,
		BlockCallerComponent,
		SidebarComponent,
		PremiumBannerComponent,
	],
	imports: [
		CommonModule,
		SvgModule,
		LeftSideNavModule,
		AvatarModule,
		OverlayModule,
		PortalModule,
		TranslateModule,
		IconModule,
		ButtonPlayModule,
		PipesModule,
		LoaderModule,
		RouterModule,
		TooltipModule,
		AudioPlayerModule,
		TemplatedPopupModule,
		CallBlockingAddNewModule,
		CallBlockingConfirmPopupModule,
	],
	exports: [HeaderComponent, VoiceMailPopupComponent, HeaderOptionBtnDirective, HelpSupportPopupComponent],
	entryComponents: [VoiceMailPopupComponent, HelpSupportPopupComponent, ProfilePopupComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderModule {}
