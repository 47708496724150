import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

import { ApiService } from '@app/services/api/api.service';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { IAccount, IAccountPolicy } from '@app/services/web-apis/accounts/api-accounts.domain';

@Injectable()
export class ApiAccountsService {
	private baseUrl: string = '/accounts/{accountId}';
	private accountSubject: BehaviorSubject<IAccount | null> = new BehaviorSubject(null);

	constructor(private apiService: ApiService) {}

	get account$(): Observable<IAccount | null> {
		return this.accountSubject.asObservable();
	}

	get account(): IAccount | null {
		return this.accountSubject.value;
	}

	canAddDevice(): Observable<boolean> {
		return this.account$.pipe(
			filter(v => !!v),
			map(
				account =>
					!Object.prototype.hasOwnProperty.call(account, 'canCreatePhysicalDevices') ||
					account.canCreatePhysicalDevices,
			),
		);
	}

	karisLawEnabled(): Observable<boolean> {
		return this.account$.pipe(
			filter(accountInfo => !!accountInfo),
			first(),
			map(accountInfo => {
				return accountInfo.country === 'US';
			}),
		);
	}

	getAccount(): Observable<IRegularApiResponse<IAccount>> {
		return this.apiService
			.get<IRegularApiResponse<IAccount>>(this.baseUrl)
			.pipe(tap(res => !res.hasError && this.accountSubject.next(res.data)));
	}

	updateAccount(account: IAccount): Observable<IRegularApiResponse<IAccount>> {
		return this.apiService
			.put(this.baseUrl, account)
			.pipe(tap(res => !res.hasError && this.accountSubject.next(res.data)));
	}

	getAccountPolicy(): Observable<IRegularApiResponse<IAccountPolicy[]>> {
		return this.apiService.get(`${this.baseUrl}/policies`);
	}
}
