import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

import { CHECK_SRC_REGEX, IAudio } from '@app/Common';
import { formatSecondsToTime } from '@utils/helpers/functions';

@Component({
	selector: 'app-basic-audio-player',
	templateUrl: './basic-audio-player.component.html',
	styleUrls: ['./basic-audio-player.component.scss'],
})
export class BasicAudioPlayerComponent implements OnInit {
	@ViewChild('audioElem', { static: true }) audioElem: ElementRef;
	@Input() loading: boolean;
	@Input('audioSrc') set audioSrc(src: IAudio | File | string) {
		if (src instanceof File) {
			this.safeAudioSrc = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(src));
		} else if (typeof src === 'object') {
			this.safeAudioSrc = this.domSanitizer.bypassSecurityTrustUrl(`data:audio/wav;base64,${src.content}`);
		} else {
			const finalSrc = src && CHECK_SRC_REGEX.test(src) ? src : `data:audio/wav;base64,${src}`;
			this.safeAudioSrc = this.domSanitizer.bypassSecurityTrustUrl(finalSrc);
		}
	}
	@Input() disabled?: boolean;
	@Input() hideTrack?: boolean;
	@Input() hideDuration?: boolean;
	@Input() width?: number;
	@Input() height?: number;
	@Output() statusChange: EventEmitter<string> = new EventEmitter<string>();
	public duration: number;
	public timeElapsed = 0;
	public timeElapsedAsPercentage = 0;
	public safeAudioSrc: SafeUrl;
	public audioState: 'playing' | 'paused' = 'paused';

	constructor(private domSanitizer: DomSanitizer) {}

	ngOnInit() {
		this.setAudioListeners();
	}

	get durationToShow(): string {
		const timeRemaining = Math.ceil(this.duration) - Math.ceil(this.timeElapsed);
		// since duration will be undefined initially, NaN may be a value for timeRemaining, thus we make sure to return 0 in that case
		// also duration may be Infinite in specific cases
		const normalizedTimeRemaining = isNaN(timeRemaining) || !isFinite(timeRemaining) ? 0 : timeRemaining;
		return formatSecondsToTime(normalizedTimeRemaining);
	}

	setAudioListeners = () => {
		const { audioElem } = this;

		// fired when audio loads and duration is known
		audioElem.nativeElement.addEventListener('durationchange', event => {
			const { srcElement } = event;
			this.duration = srcElement.duration;
		});

		// fired when time elapses on audio play
		audioElem.nativeElement.addEventListener('timeupdate', event => {
			const {
				srcElement: { currentTime },
			} = event;
			const percentPerSecUnit = 100 / this.duration;

			this.timeElapsed = currentTime;
			this.timeElapsedAsPercentage = currentTime * percentPerSecUnit;
		});

		// fired when playback ends
		audioElem.nativeElement.addEventListener('ended', event => {
			this.audioState = 'paused';
		});
	};

	toggleAudio = () => {
		const { audioElem, audioState, loading, statusChange } = this;
		if (!loading) {
			switch (audioState) {
				case 'playing':
					audioElem.nativeElement.pause();
					this.audioState = 'paused';
					statusChange.emit('paused');
					break;
				case 'paused':
					audioElem.nativeElement.play();
					this.audioState = 'playing';
					statusChange.emit('playing');
					break;
				default:
					break;
			}
		}
	};
}
