import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardComponent } from './card.component';
import { LoaderModule } from '@n2p/loader';
import { SvgModule } from '@n2p/svg';

@NgModule({
	imports: [CommonModule, SvgModule, LoaderModule],
	declarations: [CardComponent],
	exports: [CardComponent, SvgModule, LoaderModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class Card {}
