import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: '[n2p-dropdown-item]',
	templateUrl: './dropdown-item.component.html',
	styleUrls: ['./dropdown-item.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DropdownItemComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
