import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';
import { defer } from 'rxjs';
import { IHttpRequest } from 'n2p-js-sdk';

import { AuthStorageData } from '@app/services/token';
import { getApiUrl } from '@app/Common/constants/common';

import { SdkService } from '@app/services/sdk/sdk.service';

/**
 * ApiService
 * Usage: now supports ids as variables so api call '/accounts/{accountId}' is now a valid url
 * Available options:
 * - {accountId}
 * - {userId}
 * - {messengerAccountId}
 * - {messenger} prefix
 * - {catalogId}
 */
@Injectable()
export class ApiService {
	userId: string;
	accountId: string;
	messengerAccountId: string;

	private readonly API_URL: string;
	private readonly API_VERSION_HEADERS: any = {
		'x-ACCEPT-VERSION': ['v1.1'],
	};

	constructor(
		private httpClient: HttpClient,
		private authStorageDataService: AuthStorageData,
		private sdkService: SdkService,
	) {
		this.API_URL = getApiUrl();
	}

	request<T = any>(
		method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD' | 'OPTIONS',
		path: string,
		body: any = {},
		query: any = {},
	): Observable<T> {
		return defer(() =>
			this.sdkService.http
				.request<T>({
					method,
					url: path,
					params: query,
					data: body,
					headers: {
						...this.API_VERSION_HEADERS,
						'Accept-Language': this.authStorageDataService.uiLanguageCode,
					},
				})
				.then(res => res.data),
		);
	}

	head(path: string, query: object = {}, options?: IHttpRequest): Observable<any> {
		return defer(() =>
			this.sdkService.http
				.head(path, {
					...options,
					params: query,
				})
				.then(res => res.data)
				.catch(this.catchError),
		);
	}

	get<T = any>(path: string, query: object = {}, options: any = {}): Observable<T> {
		return defer(() =>
			this.sdkService.http
				.get(path, {
					...options,
					params: query,
					headers: {
						...this.API_VERSION_HEADERS,
						'Accept-Language': this.authStorageDataService.uiLanguageCode,
						...(options.headers || {}),
					},
				})
				.then(res => res.data)
				.catch(this.catchError),
		);
	}

	post<T = any>(path: string, body: any = {}, query: object = {}, options: any = {}): Observable<T> {
		return defer(() =>
			this.sdkService.http
				.post(path, body, {
					...options,
					params: query,
					headers: {
						...this.API_VERSION_HEADERS,
						'Accept-Language': this.authStorageDataService.uiLanguageCode,
						...(options.headers || {}),
					},
				})
				.then(res => res.data)
				.catch(this.catchError),
		);
	}

	put<T = any>(path: string, body: any = {}, query: object = {}, options: any = {}): Observable<T> {
		return defer(() =>
			this.sdkService.http
				.put(path, body, {
					...options,
					params: query,
					headers: {
						...this.API_VERSION_HEADERS,
						'Accept-Language': this.authStorageDataService.uiLanguageCode,
						...(options.headers || {}),
					},
				})
				.then(res => res.data)
				.catch(this.catchError),
		);
	}

	delete<T = any>(path: string, query: object = {}): Observable<T> {
		return defer(() =>
			this.sdkService.http
				.delete(path, {
					params: query,
					headers: {
						...this.API_VERSION_HEADERS,
						'Accept-Language': this.authStorageDataService.uiLanguageCode,
					},
				})
				.then(res => res.data)
				.catch(this.catchError),
		);
	}

	deleteWithBody<T = any>(path: string, body: any = {}, query: object = {}): Observable<T> {
		return this.request<T>('DELETE', path, body, query);
	}

	patch<T = any>(path: string, body: any = {}, query: object = {}, options: any = {}): Observable<T> {
		return defer(() =>
			this.sdkService.http
				.patch(path, body, {
					...options,
					params: query,
					headers: {
						...this.API_VERSION_HEADERS,
						'Accept-Language': this.authStorageDataService.uiLanguageCode,
						...(options.headers || {}),
					},
				})
				.then(res => res.data)
				.catch(this.catchError),
		);
	}

	private catchError(error: any): Observable<any> {
		const err = error && error.response && error.response.data;
		// If response has error, then handle it (400 for example)
		if (err && err.hasError) {
			return err;
		}
		// In other cases (for example 500) throw original error
		throw error;
	}
}
