import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DIALOG_DATA, DIALOG_REF } from '@utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { AuthStorageData, SnackbarService, WelcomeMenuService } from '@app/services';
import { IAudioContent } from '@app/services/web-apis/audio/api-audio.domain';
import { ApiMusicOptionsService } from '@app/services/web-apis/music-options/api-music-options.service';
import { Quality, TtsDialogData } from '@app/pages/welcomemenu/welcomemenu.components.domain';
import { TTS_HIGH_QUALITY_LIMIT } from '@app/Common';

@Component({
	selector: 'welcome-menu-tts',
	templateUrl: './welcome-menu-tts.component.html',
	styleUrls: ['./welcome-menu-tts.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class WelcomeMenuTtsComponent implements OnInit {
	@ViewChild('welcomeMenuTts') welcomeMenuTts: ElementRef;
	public transcription: string = '';
	public transcriptionPreviewed: string = '';
	public greetingBase64: string;
	public isPreviewLoading: boolean;
	public savingGreeting: boolean;

	constructor(
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		@Inject(DIALOG_DATA) public data: TtsDialogData,
		private authStorageDataService: AuthStorageData,
		private welcomeMenuService: WelcomeMenuService,
		private apiMusicOptionsService: ApiMusicOptionsService,
		private translate: TranslateService,
		private snackbarService: SnackbarService,
	) {
		this.resetDefaultData(data);
	}

	ngOnInit(): void {}

	public transcriptionChanged(value: string): void {
		this.transcription = value;
	}

	get quality(): Quality {
		return this.transcription.length > TTS_HIGH_QUALITY_LIMIT ? 'Normal' : 'High';
	}

	public autoGenerate(): void {
		let autoGeneratedTranscription = `${this.translate.instant('GREETING.AUTO_GEN_HEAD')}\n\n`;

		let sortOptions = (opt1, opt2): number => {
			return opt1.key.toString() < opt2.key.toString() ? 1 : -1;
		};

		this.data.options.sort(sortOptions).forEach(option => {
			if (
				option.key === 'timeout' ||
				option.key[0].value === 'timeout' ||
				option.forwardCallsTo.length === 0 ||
				!option.forwardCallsTo[0].value
			) {
				return;
			}
			let name = option.forwardCallsTo.map(forward => forward.value).join(', ');
			let number = option.key[0].value;

			const forContact = this.translate.instant('GREETING.FOR_CONTACT', { name, number });
			autoGeneratedTranscription += `${forContact}\n`;
		});

		this.transcription = autoGeneratedTranscription;
	}

	public reset(): void {
		this.resetDefaultData(this.data);
	}

	public erase(): void {
		this.transcription = '';
	}

	public async previewGreeting(): Promise<void> {
		if (!this.transcription) {
			return;
		}
		const transcription = this.transcription;
		this.isPreviewLoading = true;

		try {
			const resp = await this.welcomeMenuService.textToSpeech(transcription, this.quality).toPromise();
			this.transcriptionPreviewed = this.transcription;
			this.greetingBase64 = resp && resp.data && resp.data.content;
		} catch (err) {
			this.snackbarService.create({
				status: 'danger',
				text: this.translate.instant('GREETING.PREVIEW_DOES_NOT_WORK'),
				connectTo: this.welcomeMenuTts,
			});
		} finally {
			this.isPreviewLoading = false;
		}
	}

	cancel(): void {
		this.dialogRef.dismiss();
	}

	public async save(): Promise<void> {
		this.savingGreeting = true;
		try {
			await this.saveTextToSpeechGreeting();

			const data = {
				transcription: this.transcription,
				greetingBase64: this.greetingBase64,
			};
			this.dialogRef.dismiss(data);
		} catch (err) {
			this.snackbarService.create({
				status: 'danger',
				text: this.translate.instant('GREETING.NOT_SAVED'),
				connectTo: this.welcomeMenuTts,
			});
		} finally {
			this.savingGreeting = false;
		}
	}

	private resetDefaultData(data: TtsDialogData): void {
		this.transcription = data.transcription;
		this.transcriptionPreviewed = data.transcription;
		this.greetingBase64 = data.greetingBase64;
	}

	private async saveTextToSpeechGreeting(): Promise<IAudioContent | void> {
		try {
			if (this.transcription !== this.transcriptionPreviewed || !this.greetingBase64) {
				const resp = await this.welcomeMenuService.textToSpeech(this.transcription, this.quality).toPromise();
				this.greetingBase64 = resp && resp.data && resp.data.content;
				this.transcriptionPreviewed = this.transcription;
			}
			const { userId, accountId } = this.authStorageDataService;
			const audioFile = WelcomeMenuTtsComponent.base64ToWavFile(this.greetingBase64, accountId, userId);
			const formData = this.produceFormData(audioFile, this.transcriptionPreviewed);
			const uploadRes = await this.apiMusicOptionsService.uploadMediaFormData(formData).toPromise();
			if (uploadRes.hasError) {
				return Promise.reject();
			} else {
				return Promise.resolve(uploadRes.data);
			}
		} catch (err) {
			return Promise.reject();
		}
	}

	private static base64ToWavFile(audioBase64: string, accountId: string, userId: string): File {
		const byteString = atob(audioBase64); // decode from base64
		const mimeString = 'audio/wav';
		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([ia], { type: mimeString });
		return new File([blob], `${accountId}_${userId}_tts.wav`);
	}

	private produceFormData(file: File, transcription: string): FormData {
		const formData = new FormData();
		const id = this.data.id;
		formData.append('OwnerType', 'multiAttendants');
		formData.append('OwnerId', id.toString());
		formData.append('Type', 'menuGreetingFiles');
		formData.append('Content', file);
		formData.append('Transcription', transcription);
		return formData;
	}
}
