import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AvatarModule } from '@n2p';
import { SendSmsToComponent } from './send-sms-to.component';
import { SendSmsToDataComponent } from './send-sms-to-data/send-sms-to-data.component';
import { DropdownModule } from '@n2p/dropdown';
import { SvgModule } from '@n2p/svg';

@NgModule({
	declarations: [SendSmsToComponent, SendSmsToDataComponent],
	imports: [CommonModule, DropdownModule, ReactiveFormsModule, SvgModule, AvatarModule, TranslateModule],
	entryComponents: [SendSmsToDataComponent],
	exports: [SendSmsToComponent],
})
export class SendSmsToModule {}
