import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LocalSearchService {
	search(arrayToSearch: any[], searchText: string, fieldsToCheck: string[]): any[] {
		if (searchText) {
			searchText = searchText.toUpperCase();

			return arrayToSearch.filter(objectToFilter =>
				fieldsToCheck.some((field: string) => {
					if (fieldsToCheck.includes('fullName')) {
						return (
							(objectToFilter[field] &&
								objectToFilter[field]
									.toString()
									.toUpperCase()
									.includes(searchText)) ||
							`${objectToFilter.firstName} ${objectToFilter.lastName}`.toUpperCase().includes(searchText)
						);
					} else if (fieldsToCheck.includes('lines')) {
						return (
							(objectToFilter[field] &&
								objectToFilter[field]
									.toString()
									.toUpperCase()
									.includes(searchText)) ||
							objectToFilter.lines.filter(l => l.lineId.includes(searchText)).length
						);
					} else {
						return (
							objectToFilter[field] &&
							objectToFilter[field]
								.toString()
								.toUpperCase()
								.includes(searchText)
						);
					}
				}),
			);
		} else {
			return arrayToSearch;
		}
	}
}
