import { Component, ElementRef, forwardRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Subject } from 'rxjs/Subject';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import {
	DROPDOWN_DATA,
	DROPDOWN_DATA_CLICK_CALLBACK,
	DROPDOWN_DATA_FILTER,
	DROPDOWN_SELECTED_DATA,
} from 'utils/constants/injectortokens';
import { AbstractControlValueAccessor } from '@app/Common/classes/AbstractControlValueAccessor';

@Component({
	selector: 'app-select-dropdown',
	templateUrl: './select-dropdown.component.html',
	styleUrls: ['./select-dropdown.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			/* tslint:disable */
			useExisting: forwardRef(() => SelectDropdownComponent),
			/* tslint:enable */
			multi: true,
		},
	],
})
export class SelectDropdownComponent extends AbstractControlValueAccessor implements OnInit {
	@ViewChild('buttonRef', { static: true }) buttonRef: ElementRef;
	_data: Array<any>;
	_errors: any;
	isOpen: boolean = false;
	dataRef: OverlayRef;
	@Input() dropDownDataComponent: any;
	@Input() prop: string; // the readable property on the selected item to display
	@Input() itemIdProp: string; // the prop from data list to return as selected value
	@Input() label: string;
	filterSubject: Subject<any> = new Subject<any>();
	filterStr: string;
	@Input() buttonId: string = 'select-dropdown';
	@Input() readonly: boolean = false;
	@Input() onlyNumbers: boolean = false;

	private _selectedItem: any;

	constructor(private overlay: Overlay, private injector: Injector) {
		super();
		this.buttonId += `_${document.getElementsByClassName('select-dropdown').length + 1}`;
	}

	@Input('data') set data(d) {
		this._data = d;
		if (this.isOpen) {
			this.close();
			this.open();
		}
	}

	@Input('hasError') set hasError(error) {
		this._errors = error;
	}

	get hasError(): any {
		return this._errors;
	}

	@Input() set selectedItem(value) {
		if (value) {
			const filterStr = this.prop ? value[this.prop] : value;
			if (filterStr === undefined) return;
			this.filterStr = filterStr;
			this._selectedItem = value;
			this.value = this.itemIdProp ? value[this.itemIdProp] : this.filterStr;
		}
	}

	get selectedItem(): any {
		return this._selectedItem;
	}

	filterChange(): void {
		this.value = this.itemIdProp ? this.selectedItem[this.itemIdProp] : this.filterStr;
		this.filterSubject.next(this.filterStr);
	}

	clickCallback = item => {
		this.selectedItem = item;
		this.filterStr = this.prop ? item[this.prop] : item;
		this.value = this.itemIdProp ? item[this.itemIdProp] : this.filterStr;
		this.close();
	};

	open(): void {
		this.isOpen = true;
		const config = new OverlayConfig({
			backdropClass: 'multi-select-dropdown-data',
			width: this.buttonRef.nativeElement.offsetWidth,
		});

		const buttonElem = document.getElementById(this.buttonId);
		const diff = window.innerHeight - buttonElem.getBoundingClientRect()['y'];
		const onTop = diff < 220;
		config.scrollStrategy = this.overlay.scrollStrategies.reposition();
		config.positionStrategy = this.overlay
			.position()
			.connectedTo(
				this.buttonRef,
				{
					originY: onTop ? 'top' : 'bottom',
					originX: 'start',
				},
				{
					overlayY: onTop ? 'bottom' : 'top',
					overlayX: 'start',
				},
			)
			.withOffsetY(onTop ? -8 : 8);

		config.hasBackdrop = true;

		this.dataRef = this.overlay.create(config);

		this.dataRef.backdropClick().subscribe(() => {
			this.close();
		});

		const component = new ComponentPortal(this.dropDownDataComponent, undefined, this.createDataComponentInjector());
		this.dataRef.attach(component);
	}

	close(): void {
		this.isOpen = false;
		this.dataRef.dispose();
	}

	ngOnInit(): void {
		if (!this.dropDownDataComponent) throw Error('Dropdown Component must be provided');
	}

	private createDataComponentInjector(): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(DROPDOWN_DATA, this._data);
		injectorTokens.set(DROPDOWN_SELECTED_DATA, this.selectedItem || undefined);
		injectorTokens.set(DROPDOWN_DATA_FILTER, this.filterSubject.asObservable());
		injectorTokens.set(DROPDOWN_DATA_CLICK_CALLBACK, this.clickCallback);

		return new PortalInjector(this.injector, injectorTokens);
	}
}
