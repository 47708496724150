import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { delay, filter, finalize, map, retryWhen, switchMap } from 'rxjs/operators';

import { ApiAudioService } from '@app/services/web-apis/audio/api-audio.service';
import { ApiDepartmentsService } from '@app/services/web-apis/departments/api-departments.service';
import { ApiUsersService } from '@app/services/web-apis/users/api-users.service';
import { AuthStorageData, SnackbarService } from '@app/services';
import { ApiMusicOptionsService } from '@app/services/web-apis/music-options/api-music-options.service';
import { TranslateService } from '@ngx-translate/core';
import { DepartmentService } from '@app/services/department/department.service';

import { RecordingStatus, TRY_AGAIN, VoicemailType } from './edit-voicemail.constants';
import { IAudioContent } from '@app/services/web-apis/audio/api-audio.domain';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { IVoicemailSettings } from '@app/services/web-apis/voicemails/api-voicemails.domain';
import { IVoicemailObject } from '@n2p/edit-voicemail/edit-voicemail.interfaces';
import { IUser } from '@app/services/web-apis/users/api-users.domain';
import { IExtendedDepartment } from '@app/services/web-apis/departments/api-departments.domain';

@Injectable()
export class EditVoicemailService {
	private isRecording: boolean = false;
	private type: VoicemailType;

	constructor(
		private authStorageDataService: AuthStorageData,
		private departmentAudioService: DepartmentService,
		private userAudioService: ApiAudioService,
		private musicService: ApiMusicOptionsService,
		private userService: ApiUsersService,
		private departmentService: ApiDepartmentsService,
		private translate: TranslateService,
		private snackbarService: SnackbarService,
	) {}

	public useType(type: VoicemailType): void {
		this.type = type;
	}

	public getAudio(id: number): Observable<IAudioContent> {
		let source$: Observable<IRegularApiResponse<IAudioContent>>;

		if (this.type === VoicemailType.USER) {
			source$ = this.userAudioService.getUserAudio(id.toString(), '6');
		}

		if (this.type === VoicemailType.DEPARTMENT) {
			source$ = this.departmentAudioService.getVM(id, '6');
		}

		if (source$) {
			return source$.pipe(
				map(({ data, hasError }) => {
					if (hasError) throw new Error('Cannot get voicemail audio file');
					return data;
				}),
			);
		}
	}

	public defaultAudio(id: number): Observable<IAudioContent> {
		let source$: Observable<IRegularApiResponse<string>>;

		if (this.type === VoicemailType.USER) {
			source$ = this.userAudioService.resetUserAudio(id.toString());
		}

		if (this.type === VoicemailType.DEPARTMENT) {
			source$ = this.departmentAudioService.deactivateVMGreeting(id);
		}

		if (source$) {
			return source$.pipe(
				switchMap(({ hasError }) => {
					if (hasError) throw new Error('The voicemail is not set to default');
					return this.getAudio(id);
				}),
			);
		}
	}

	public uploadAudio(id: number, audio: string | File): Observable<IAudioContent> {
		const formData = new FormData();
		formData.append('OwnerType', this.type === 'user' ? 'users' : 'departments');
		formData.append('OwnerId', id.toString());
		formData.append('Type', this.type === 'user' ? 'uploadVMGreeting' : 'uploadVMDepartmentGreeting');
		formData.append('Content', audio);

		return this.musicService.uploadMediaFormData(formData).pipe(
			map(({ data, hasError }) => {
				if (hasError) throw new Error('The voicemail is not uploaded');
				return data;
			}),
		);
	}

	public recordAudio(id: number, phone: string): Observable<IAudioContent> {
		this.isRecording = true;
		let source$: Observable<IRegularApiResponse<IAudioContent>>;

		if (this.type === VoicemailType.USER) {
			source$ = this.userAudioService.createUserAudio(id.toString(), '2', phone, ' ');
		}

		if (this.type === VoicemailType.DEPARTMENT) {
			source$ = this.departmentAudioService.callPhoneNumber(id, phone);
		}

		if (source$) {
			return source$.pipe(
				switchMap(({ data, hasError }) => {
					if (hasError && !data.recordingId) throw new Error('Cannot record new audio voicemail');
					return this.checkRecordingStatus(data.recordingId).pipe(
						switchMap(status => {
							switch (status) {
								case RecordingStatus.RECORDED:
									return this.getAudio(id);
								default:
									throw new Error('The voicemail is not recorded');
							}
						}),
					);
				}),
				finalize(() => (this.isRecording = false)),
			);
		}
	}

	private checkRecordingStatus(id: number): Observable<string> {
		let source$: Observable<IRegularApiResponse<string>>;

		if (this.type === VoicemailType.USER) {
			source$ = this.userAudioService.getAudioRecordingStatus(id.toString());
		}

		if (this.type === VoicemailType.DEPARTMENT) {
			source$ = this.departmentAudioService.pingVMStatus(id);
		}

		return source$.pipe(
			map(({ data }) => {
				if (!this.isRecording) return data;

				switch (data) {
					case RecordingStatus.ACTIVE:
					case RecordingStatus.PROCESSING:
						throw TRY_AGAIN;
					default:
						return data;
				}
			}),
			retryWhen(errors =>
				errors.pipe(
					filter(error => error === TRY_AGAIN),
					delay(1000),
				),
			),
		);
	}

	public cancelRecording(): void {
		this.isRecording = false;
	}

	public updateVoicemailSettings(id: number, body: Partial<IVoicemailSettings>): Observable<IVoicemailObject> {
		let source$: Observable<IRegularApiResponse<IUser | IExtendedDepartment>>;

		if (this.type === VoicemailType.USER) {
			source$ = this.userService.updateUser(id.toString(), body);
		}

		if (this.type === VoicemailType.DEPARTMENT) {
			source$ = this.departmentService.updateDepartment({ deptId: id, ...body });
		}

		if (source$) {
			return source$.pipe(
				map(({ data, hasError, errorMessages }) => {
					if (hasError) throw errorMessages;
					if (this.type === VoicemailType.DEPARTMENT) {
						this.snackbarService.create({
							status: 'success',
							text: this.translate.instant('EDIT_DEPARTMENT.DEPARTMENT_SAVED'),
						});
					}
					return {
						id: id,
						isNotificationEnabled: data.voicemailNotification.emailNotify,
						isFileIncluded: data.voicemailNotification.emailIncludeVM,
						isTranscriptIncluded: data.voicemailNotification.emailTranscribe,
						isVoicemailEnabled: data.voicemailEnabled,
						isCallerDetailsIncluded: data.voicemailNotification.emailIncludeCallerDetails,
						isRestricted: data.voicemailNotification.emailRestrictChanges,
					};
				}),
			);
		}
	}
}
