import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '@app/services/api/api.service';
import { Quality } from '@app/pages/welcomemenu/welcomemenu.components.domain';

@Injectable()
export class WelcomeMenuService {
	readonly dataStore: {
		welcomeMenus: Array<any>;
	};
	private _welcomeMenus: BehaviorSubject<any>;
	constructor(private apiService: ApiService) {
		this.dataStore = {
			welcomeMenus: [],
		};
		this._welcomeMenus = <BehaviorSubject<any>>new BehaviorSubject<any>([]);
	}

	get welcomeMenus() {
		return this._welcomeMenus.asObservable();
	}

	get welcomeMenuCount() {
		return this.dataStore.welcomeMenus.length;
	}

	getDataStoreItem(item: 'welcomeMenus') {
		return this.dataStore[item];
	}

	getWelcomeMenus() {
		const url = '/accounts/{accountId}/multiautoattendants';
		return this.apiService.get(url);
	}

	loadWelcomeMenus() {
		return new Promise(resolve => {
			this.getWelcomeMenus()
				.map((res: any) => {
					return res.data
						? res.data.map(r => {
								return {
									id: r.id,
									name: r.name,
									lines: r.lines,
									menu: r.menu,
									extension: r.extension,
								};
						  })
						: [];
				})
				.subscribe(res => {
					this.dataStore.welcomeMenus = res;
					this._welcomeMenus.next(Object.assign({}, this.dataStore).welcomeMenus);
					resolve();
				});
		});
	}

	addWelcomeMenu(wm) {
		return new Promise((resolve, reject) => {
			const url = '/accounts/{accountId}/multiAttendants';
			this.apiService
				.post(url, wm)
				.catch(err => {
					reject();
					return Observable.throw(err);
				})
				.subscribe(res => {
					if (res.hasError) {
						return reject(res);
					}

					wm.id = res.data.id;
					this.dataStore.welcomeMenus.push(wm);
					this._welcomeMenus.next(Object.assign({}, this.dataStore).welcomeMenus);
					resolve(res);
				});
		});
	}

	editWelcomeMenu(wm) {
		return new Promise((resolve, reject) => {
			const url = `/accounts/{accountId}/multiautoattendants/${wm.id}`;
			this.apiService
				.put(url, wm)
				.catch(err => {
					reject(err);
					return Observable.throw(err);
				})
				.subscribe(res => {
					if (res.hasError) {
						return reject(res);
					}

					resolve(res);
				});
		});
	}

	removeWelcomeMenu(wm) {
		return new Promise((resolve, reject) => {
			const index = this.dataStore.welcomeMenus.findIndex(i => i.id === wm.id);
			if (index !== -1) {
				const url = `/accounts/{accountId}/multiautoattendants/${wm.id}`;
				this.apiService
					.delete(url)
					.catch(err => {
						reject();
						return Observable.throw(err);
					})
					.subscribe(() => {
						this.dataStore.welcomeMenus.splice(index, 1);
						this._welcomeMenus.next(Object.assign({}, this.dataStore).welcomeMenus);
						resolve();
					});
			}
		});
	}

	textToSpeech(text: string, quality: Quality = 'High'): Observable<any> {
		const url = '/text-to-speech';
		return this.apiService.post(url, { text, quality }).catch(err => Observable.throw(err));
	}

	uploadGreeting(id, type, base64, lineNumber = '') {
		let url = `/accounts/{accountId}/multiAttendants/${id}/audio?audioType=${type}`;
		if (lineNumber) {
			url += `&lineNumber=${encodeURIComponent(lineNumber)}`;
		}
		return this.apiService.post(url, { text: base64 });
	}

	checkOnGreeting(id, recordingId) {
		const url = `/accounts/{accountId}/multiAttendants/${id}/recordings/${recordingId}`;
		return this.apiService.get(url);
	}

	getGreeting(id) {
		const url = `/accounts/{accountId}/multiAttendants/${id}/menu/menuGreetingFiles`;
		return this.apiService.get(url);
	}

	getActiveGreeting(id) {
		const url = `/accounts/{accountId}/multiAttendants/${id}/menu/menuGreetingFiles?nGetAllAudioFiles=0`;
		return this.apiService.get(url);
	}

	filter(str) {
		str = str.toLowerCase();
		this._welcomeMenus.next(
			this.dataStore.welcomeMenus.filter(
				wm =>
					!str ||
					wm.name.toLowerCase().indexOf(str) !== -1 ||
					(wm.lines && wm.lines.filter(l => l.lineId.indexOf(str) !== -1).length),
			),
		);
	}
}
