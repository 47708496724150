import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { GlobalLoaderService, Loader, ApiPhoneNumbersService, SnackbarService } from '@app/services';
import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { IPhone } from '@app/Common/interfaces/IPhone';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { ICallerIdReplacementMode, ICallerIdReplacementOptions } from '@app/services/web-apis/phone-numbers';
import { TranslateService } from '@ngx-translate/core';
import { getValidType } from '@app/Common/constants';
import { getEntityTypeForNumberUpdate } from '../utils/type-map';

interface IEditPhoneNumberCallerIdForm {
	callerIdName: string;
	callerIdNumber: string;
	callerIdNumberMode: ICallerIdReplacementMode;
	callerIdNameMode: ICallerIdReplacementMode;
}

function whenModeNotNoneRequiredValidator(modeFile: string) {
	return (formControl: AbstractControl) => {
		if (!formControl.parent) {
			return null;
		}
		if (formControl.parent.get(modeFile).value != ICallerIdReplacementMode.NONE) {
			return Validators.required(formControl);
		}
		return null;
	};
}

@Component({
	selector: 'edit-phone-number-caller-id-settings-dialog',
	templateUrl: './edit-phone-number-caller-id-settings-dialog.component.html',
	styleUrls: ['./edit-phone-number-caller-id-settings-dialog.component.scss'],
	providers: [GlobalLoaderService],
})
export class EditPhoneNumberCallerIdSettingsDialogComponent implements OnInit, OnDestroy {
	phone: IPhone;
	formGroup: FormGroup;
	loader: Loader;
	exampleCallerIdNumber: string = '201-555-1212';
	exampleCallerIdName: string = 'STARK, TONY';
	saving = false;
	constructor(
		@Inject(DIALOG_DATA) data: { phone: IPhone; index: number },
		private fb: FormBuilder,
		private phoneNumberService: ApiPhoneNumbersService,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		private globalLoaderService: GlobalLoaderService,
		private hostElem: ElementRef,
		private snackbar: SnackbarService,
		private translate: TranslateService,
	) {
		this.phone = data.phone;
		this.formGroup = this.fb.group({
			callerIdName: [
				'',
				[
					Validators.maxLength(64),
					Validators.pattern('^[a-zA-Z0-9 ]+$'),
					whenModeNotNoneRequiredValidator('callerIdNameMode'),
				],
			],
			callerIdNumber: [
				'',
				[
					Validators.maxLength(64),
					Validators.pattern('^[0-9 ]+$'),
					whenModeNotNoneRequiredValidator('callerIdNumberMode'),
				],
			],
			callerIdNumberMode: [ICallerIdReplacementMode.NONE, []],
			callerIdNameMode: [ICallerIdReplacementMode.NONE, []],
		});

		this.formGroup.get('callerIdNumberMode').valueChanges.subscribe(_ => {
			this.formGroup.get('callerIdNumber').updateValueAndValidity();
		});

		this.formGroup.get('callerIdNameMode').valueChanges.subscribe(_ => {
			this.formGroup.get('callerIdName').updateValueAndValidity();
		});
	}

	cancel(success: boolean = false): void {
		this.dialogRef.dismiss(success);
	}

	ngOnInit(): void {
		this.loader = this.globalLoaderService.create(this.hostElem);
		this.loader.show();
		this.phoneNumberService.getCallerIdReplacementOptions(this.phone.number).subscribe(
			res => {
				this.formGroup.patchValue({
					callerIdName: res.callerIdName?.value ?? '',
					callerIdNumber: res.callerIdNumber?.value ?? '',
					callerIdNameMode: res.callerIdName?.mode,
					callerIdNumberMode: res.callerIdNumber?.mode,
				});
				this.formGroup.markAsPristine();
				this.loader.hide();
			},
			() => {
				this.loader.hide();
			},
		);
	}

	ngOnDestroy(): void {
		if (this.loader) this.loader.hide();
	}

	setCallerIdName(newName: string): void {
		this.formGroup.patchValue({
			callerIdName: newName,
		});
	}

	setCallerIdNumber(newNumber: string): void {
		this.formGroup.patchValue({
			callerIdNumber: newNumber,
		});
	}

	save(): void {
		this.saving = true;
		const formValues: IEditPhoneNumberCallerIdForm = this.formGroup.getRawValue();
		const callerIdNumberOptions: ICallerIdReplacementOptions = {
			value: formValues.callerIdNumber,
			mode: formValues.callerIdNumberMode,
		};
		const callerIdNameOptions: ICallerIdReplacementOptions = {
			value: formValues.callerIdName,
			mode: formValues.callerIdNameMode,
		};
		const validType = getValidType(this.phone.routeType);
		const updatePhoneNumberModel = {
			...this.phone,
			routeType: validType ? getEntityTypeForNumberUpdate(validType) : validType,
			callerIdNumber: callerIdNumberOptions,
			callerIdName: callerIdNameOptions,
		};
		this.phoneNumberService
			.updatePhoneNumber(updatePhoneNumberModel)
			.toPromise()
			.then(res => {
				if (res.hasError) {
					this.snackbar.createDanger(this.translate.instant('GLOBALS.GENERAL_ERROR'));
				} else {
					this.snackbar.createSuccess(this.translate.instant('GLOBALS.SAVED'));
					this.dialogRef.dismiss(res);
				}
			})
			.catch(() => {
				this.snackbar.createDanger(this.translate.instant('GLOBALS.GENERAL_ERROR'));
			})
			.finally(() => {
				this.saving = false;
			});
	}

	get isCallerIdNameModeReplace(): boolean {
		return this.formGroup.get('callerIdNameMode').value == ICallerIdReplacementMode.REPLACE;
	}

	get isCallerIdNameModeAppend(): boolean {
		return this.formGroup.get('callerIdNameMode').value == ICallerIdReplacementMode.APPEND;
	}

	get isCallerIdNameModePrepend(): boolean {
		return this.formGroup.get('callerIdNameMode').value == ICallerIdReplacementMode.PREPEND;
	}

	get isCallerIdNumberModeReplace(): boolean {
		return this.formGroup.get('callerIdNumberMode').value == ICallerIdReplacementMode.REPLACE;
	}

	get isCallerIdNumberModeAppend(): boolean {
		return this.formGroup.get('callerIdNumberMode').value == ICallerIdReplacementMode.APPEND;
	}

	get isCallerIdNumberModePrepend(): boolean {
		return this.formGroup.get('callerIdNumberMode').value == ICallerIdReplacementMode.PREPEND;
	}
}
