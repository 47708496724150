import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { DialogComponent } from '@app/shared/dialog/dialog.component';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { DIALOG_COMPONENT, DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { blockDocumentScrollStrategy } from '@app/n2p-components/dialog/dialog.strategies';

@Injectable()
export class DialogService {
	constructor(private overlay: Overlay, private injector: Injector) {}

	private createInjector(data, ref, component): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(DIALOG_DATA, data);
		injectorTokens.set(DIALOG_REF, ref);
		injectorTokens.set(DIALOG_COMPONENT, component);
		return new PortalInjector(this.injector, injectorTokens);
	}
	create(component, params = null, options: OverlayConfig = {}) {
		const config = new OverlayConfig({
			...options,
			minWidth: 400,
			hasBackdrop: false,
			scrollStrategy: blockDocumentScrollStrategy,
		});

		// config.scrollStrategy = this.overlay.scrollStrategies.reposition();
		config.positionStrategy = this.overlay
			.position()
			.global()
			.centerHorizontally();
		// .centerVertically();

		config.hasBackdrop = true;

		const overlayRef = this.overlay.create(config);

		const modalRef = new DialogRef(overlayRef);
		const c = new ComponentPortal(DialogComponent, null, this.createInjector(params, modalRef, component));
		overlayRef.attach(c);
		return modalRef;
	}
}
