import { InjectionToken } from '@angular/core';

export const DROPDOWN_DATA = new InjectionToken<{}>('DROPDOWN_DATA');
export const DROPDOWN_CHIP_MORE_REF = new InjectionToken<{}>('DROPDOWN_CHIP_MORE_REF');
export const DROPDOWN_DATA_CLICK_CALLBACK = new InjectionToken<{}>('DROPDOWN_DATA_CLICK_CALLBACK');
export const DROPDOWN_DATA_FILTER = new InjectionToken<{}>('DROPDOWN_DATA_FILTER');
export const DROPDOWN_DATA_CONFIG = new InjectionToken<{}>('DROPDOWN_DATA_CONFIG');
export const DROPDOWN_DATA_COMPONENT = new InjectionToken<{}>('DROPDOWN_DATA_COMPONENT');
export const DROPDOWN_PAGE = new InjectionToken<{}>('DROPDOWN_PAGE');
export const DROPDOWN_PAGE_NEXT = new InjectionToken<{}>('DROPDOWN_PAGE_NEXT');
export const DROPDOWN_PAGE_PREV = new InjectionToken<{}>('DROPDOWN_PAGE_PREV');
export const DROPDOWN_ADDITIONAL_DATA = new InjectionToken<{}>('DROPDOWN_ADDITIONAL_DATA');
export const DROPDOWN_DATA_HEIGHT = new InjectionToken<{}>('DROPDOWN_DATA_HEIGHT');
