import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-multi-select-dropdown-chip-more-close-btn',
	templateUrl: './multi-select-dropdown-chip-more-close-btn.component.html',
	styleUrls: ['./multi-select-dropdown-chip-more-close-btn.component.scss'],
})
export class MultiSelectDropdownChipMoreCloseBtnComponent implements OnInit {
	@Input('index') index: number;
	@Output('clickCallback') clickCallback: EventEmitter<any> = new EventEmitter<any>();
	constructor() {}

	close() {
		this.clickCallback.emit(this.index);
	}

	ngOnInit() {}
}
