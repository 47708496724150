import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CircleIconType } from '@app/shared/circle-icon/circle-icon.domain';

@Component({
	selector: 'app-circle-icon',
	templateUrl: './circle-icon.component.html',
	styleUrls: ['./circle-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleIconComponent {
	@Input() name: CircleIconType;
	@Input() size: number;
	@Input() background: string;
	@Input() fill: string;
}
