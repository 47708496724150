import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

import { ExtensionFormComponent } from './extension-form.component';
import { DropdownModule } from '../dropdown';
import { ButtonGroupModule } from '../button-group/button-group.module';

@NgModule({
	declarations: [ExtensionFormComponent],
	imports: [DropdownModule, TranslateModule, ButtonGroupModule, CommonModule],
	exports: [ExtensionFormComponent],
})
export class ExtensionFormModule {}
