import { Component, HostBinding, Inject, OnInit } from '@angular/core';

import { DROPDOWN_DATA, DROPDOWN_DATA_CLICK_CALLBACK, DROPDOWN_SELECTED_DATA } from 'utils/constants/injectortokens';

/*
  a simple text component for the select-dropdown (single select, not multi)
*/

@Component({
	selector: 'app-select-data-text',
	templateUrl: './select-data-text.component.html',
	styleUrls: ['./select-data-text.component.scss'],
})
export class SelectDataTextComponent implements OnInit {
	@HostBinding('class.n2p-dropdown-data') style = false;
	constructor(
		@Inject(DROPDOWN_DATA) public items: any,
		@Inject(DROPDOWN_DATA_CLICK_CALLBACK) private callback: any,
		@Inject(DROPDOWN_SELECTED_DATA) private selectedItem: any,
	) {
		this.selectedItem = selectedItem;
	}

	ngOnInit() {
		this.style = true;
	}

	public handleClick(item) {
		this.items.forEach((n, i) => {
			n.selected = n.text === item.text;
		});
		item.selected = true;
		this.selectedItem = item;
		this.callback(item);
	}
}
