import { ChangeDetectionStrategy, Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { IDropdownOption } from 'n2p-ui-library/components/dropdown/dropdown.domain';
import { TranslateService } from '@ngx-translate/core';

import {
	RuleType,
	RuleTypeBase,
	RuleTypeCommon,
	RuleTypeIndividual,
} from '@app/services/web-apis/users/api-users.domain';
import { CallOptionsService } from '@app/pages/teammembers/call-options/call-options.service';

@Component({
	selector: 'n2p-call-options-rule',
	templateUrl: './call-options-rule.component.html',
	styleUrls: ['./call-options-rule.component.scss', '../../call-options.shared.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallOptionsRuleComponent implements OnInit {
	ruleType: RuleType = RuleTypeBase.OFF;

	@Input('ruleType') set ruleTypeSetter(ruleType: RuleType) {
		this.ruleType = ruleType;
		this.setRuleTypeOptions();
	}

	@Output() change: EventEmitter<RuleType> = new EventEmitter();

	ruleTypeOptions: IDropdownOption[];

	readonly ruleTypeInfoMap: { [key: string]: string } = {
		[RuleTypeBase.OFF]: this.translateService.instant('CALLOPTIONS_PAGE.INFO.OFF'),
		[RuleTypeIndividual.RING_ALL]: this.translateService.instant('CALLOPTIONS_PAGE.INFO.RING_ALL'),
		[RuleTypeCommon.RING_ALL]: this.translateService.instant('CALLOPTIONS_PAGE.INFO.RING_ALL'),
		[RuleTypeIndividual.NO_ANSWER]: this.translateService.instant('CALLOPTIONS_PAGE.INFO.NO_ANSWER'),
		[RuleTypeCommon.NO_ANSWER]: this.translateService.instant('CALLOPTIONS_PAGE.INFO.NO_ANSWER'),
		[RuleTypeIndividual.FAIL_OVER]: this.translateService.instant('CALLOPTIONS_PAGE.INFO.FAILOVER'),
		[RuleTypeCommon.FAIL_OVER]: this.translateService.instant('CALLOPTIONS_PAGE.INFO.FAILOVER'),
		[RuleTypeIndividual.FORWARD_ONLY]: this.translateService.instant('CALLOPTIONS_PAGE.INFO.FORWARD_ONLY'),
		[RuleTypeCommon.FORWARD_ONLY]: this.translateService.instant('CALLOPTIONS_PAGE.INFO.FORWARD_ONLY'),
	};

	constructor(private translateService: TranslateService, public callOptionsService: CallOptionsService) {}

	ngOnInit(): void {
		this.setRuleTypeOptions();
	}

	private setRuleTypeOptions(): void {
		const Type = this.callOptionsService.isIndividualRuleType ? RuleTypeIndividual : RuleTypeCommon;

		this.ruleTypeOptions = [
			{
				title: this.translateService.instant('CALLOPTIONS_PAGE.OFF'),
				value: RuleTypeBase.OFF,
			},
			{
				title: this.translateService.instant('CALLOPTIONS_PAGE.RING_ALL'),
				value: Type.RING_ALL,
			},
			{
				title: this.translateService.instant('CALLOPTIONS_PAGE.NO_ANSWER'),
				value: Type.NO_ANSWER,
			},
			{
				title: this.translateService.instant('CALLOPTIONS_PAGE.FAILOVER'),
				value: Type.FAIL_OVER,
			},
			{
				title: this.translateService.instant('CALLOPTIONS_PAGE.FORWARD_ONLY'),
				value: Type.FORWARD_ONLY,
			},
		];
	}
}
