import { Component, Inject, OnInit } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';

import {
	MULTISELECT_CHIP_CLICK_CALLBACK,
	MULTISELECT_CHIP_MORE_REF,
	MULTISELECT_DATA,
} from '@utils/constants/injectortokens';

@Component({
	selector: 'app-multi-select-dropdown-chip-more',
	templateUrl: './multi-select-dropdown-chip-more.component.html',
	styleUrls: ['./multi-select-dropdown-chip-more.component.scss'],
})
export class MultiSelectDropdownChipMoreComponent implements OnInit {
	selectedItems: Array<any>;
	constructor(
		@Inject(MULTISELECT_DATA) data: any,
		@Inject(MULTISELECT_CHIP_CLICK_CALLBACK) private callback: any,
		@Inject(MULTISELECT_CHIP_MORE_REF) private overlayRef: OverlayRef,
	) {
		this.selectedItems = data;
	}

	clickCallback(index) {
		const item = this.selectedItems[index];
		this.callback(item);
		this.selectedItems.splice(index, 1);
		if (!this.selectedItems.length) {
			this.overlayRef.dispose();
		} else {
			setTimeout(() => this.overlayRef.updatePosition());
		}
	}

	ngOnInit() {}
}
