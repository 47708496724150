import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { IDropdownOption } from 'n2p-ui-library/components/dropdown/dropdown.domain';
import { TranslateService } from '@ngx-translate/core';

import { CallOptionsService } from '@app/pages/teammembers/call-options/call-options.service';
import { IForwardToExtended } from '@app/services/web-apis/users/api-users.domain';
import { ForwardCallsToAddNumberComponent } from '@app/shared/forward-calls-to';
import { DialogService } from '@app/services';

@Component({
	selector: 'n2p-call-options-numbers',
	templateUrl: './call-options-numbers.component.html',
	styleUrls: ['./call-options-numbers.component.scss', '../../call-options.shared.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallOptionsNumbersComponent {
	@Input() userId: number | string;

	@Input() set maxRings(maxRings: number) {
		this.setRingsOptions(maxRings);
	}

	@Output() add: EventEmitter<string> = new EventEmitter();

	@Output() update: EventEmitter<{ sequence: string; rings: number }> = new EventEmitter();

	@Output() delete: EventEmitter<number> = new EventEmitter();

	ringsOptions: IDropdownOption[] = [];

	constructor(
		private translateService: TranslateService,
		public callOptionsService: CallOptionsService,
		private dialogService: DialogService,
	) {}

	addForwardingNumber(): void {
		const dialogRef = this.dialogService.create(
			ForwardCallsToAddNumberComponent,
			{
				forwardingNumberlist: this.callOptionsService.forwardNumbers,
				userToEditId: this.userId,
				restrictedPhoneNumbers: this.callOptionsService.userPhones.map(ul => ul.lineId),
			},
			{
				width: 440,
			},
		);

		dialogRef.onDismiss().subscribe(number => {
			if (number) {
				this.add.emit(number);
			}
		});
	}

	trackNumberFn(index: number, number: IForwardToExtended): string {
		return number.value;
	}

	private setRingsOptions(maxRings: number): void {
		const options: IDropdownOption[] = [];

		for (let i = this.callOptionsService.MIN_RINGS_VALUE; i < maxRings; i++) {
			options.push({
				title: `${i} ${this.translateService.instant('CALLOPTIONS_PAGE.RINGS')}`,
				value: i,
			});
		}

		this.ringsOptions = options;
	}
}
