import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ResetPasswordComponent } from './reset-password.component';
import { SvgModule } from '@n2p/svg';
import { DirectivesModule } from '@app/directives/directives.module';
import { InputModule } from '../input';

@NgModule({
	declarations: [ResetPasswordComponent],
	imports: [CommonModule, InputModule, ReactiveFormsModule, SvgModule, DirectivesModule, TranslateModule],
	exports: [ResetPasswordComponent],
	entryComponents: [ResetPasswordComponent],
})
export class ResetPasswordModule {}
