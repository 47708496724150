import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AddDepartmentsComponent } from '@app/pages/departments/adddepartment/adddepartment.component';

import { AssignedNumbersModule, InputModule } from '@n2p';
import { InputExtensionModule } from '@n2p/input-extension/input-extension.module';
import { AssignedToDropdownModule } from '@n2p/assigned-to-dropdown';
import { SharedModule } from '@app/shared';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		ReactiveFormsModule,
		InputModule,
		InputExtensionModule,
		AssignedNumbersModule,
		AssignedToDropdownModule,
		SharedModule,
	],
	declarations: [AddDepartmentsComponent],
	exports: [AddDepartmentsComponent],
	entryComponents: [AddDepartmentsComponent],
})
export class AddDepartmentModule {}
