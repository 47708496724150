import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '@app/services/api';
import {
	IAudioFileUploadData,
	IRingGroup,
	IRingGroupLight,
} from '@app/services/web-apis/ring-groups/api-ring-groups.domain';
import { IPagingModel, IRegularApiResponse } from '@app/services/web-apis/common-api.domain';

@Injectable()
export class ApiRingGroupsService {
	private baseUrl = '/account/{accountId}/ringGroups';

	constructor(private apiService: ApiService) {}

	// ring groups

	getAllRingGroups(): Observable<IRegularApiResponse<IRingGroup[]>> {
		return this.apiService.get(`${this.baseUrl}`);
	}

	getAllRingGroupsLight(skip: number, take: number): Observable<IRegularApiResponse<IPagingModel<IRingGroupLight[]>>> {
		return this.apiService.get(`${this.baseUrl}/light?skip=${skip}&take=${take}`);
	}

	// ring group

	getRingGroup(id: string, includeCallQueue: boolean): Observable<IRegularApiResponse<IRingGroup>> {
		return this.apiService.get(`${this.baseUrl}/${id}/?includeCallQueue=${includeCallQueue}`);
	}

	createRingGroup(ringGroup: IRingGroup): Observable<IRegularApiResponse<IRingGroup>> {
		return this.apiService.post(this.baseUrl, ringGroup);
	}

	updateRingGroup(ringGroup: IRingGroup): Observable<IRegularApiResponse<IRingGroup>> {
		return this.apiService.put(`${this.baseUrl}/${ringGroup.id}`, ringGroup);
	}

	removeRingGroup(id: string | number): Observable<IRegularApiResponse<string>> {
		return this.apiService.delete(`${this.baseUrl}/${id}`);
	}

	// ring group properties

	validateRingGroupName(name: string): Observable<IRegularApiResponse<string>> {
		return this.apiService.get(`/accounts/{accountId}/ringGroups/validateName/${name}`);
	}

	getDefaultMOH(): Observable<any> {
		return this.apiService.get('/accounts/{accountId}/ringGroups/audio');
	}

	getRingGroupMOH(id): Observable<any> {
		return this.apiService.get(`/accounts/{accountId}/ringGroups/${id}/audio`);
	}

	uploadRingGroupMOH(id: number, audioFile: IAudioFileUploadData): Observable<any> {
		return this.apiService.post(`/accounts/{accountId}/ringGroups/${id}/audio`, audioFile);
	}

	switchRingGroupMOHToDefault(id): Observable<any> {
		return this.apiService.put(`/accounts/{accountId}/ringGroups/${id}/audio`, {});
	}
}
