import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { v4 } from 'uuid';

@Injectable()
export class AudioPlayerService {
	private _activeAudioPlayer: Subject<any>;
	constructor() {
		this._activeAudioPlayer = new Subject<any>();
	}

	get activeAudioPlayer(): Observable<any> {
		return this._activeAudioPlayer.asObservable();
	}

	addAudioPlayer(): string {
		const uuid = v4();

		return uuid;
	}

	playAudioPlayer(uuid): void {
		this._activeAudioPlayer.next(uuid);
	}

	pauseAllAudio(): void {
		this._activeAudioPlayer.next(null);
	}
}
