import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';

declare var $: any;

import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { ApiUsersService } from '@app/services';

@Component({
	selector: 'app-delete-team-member',
	templateUrl: './delete-team-member.component.html',
})
export class DeleteTeamMemberComponent implements OnInit {
	// @Input() userInfo: any = [];
	// @Input() deleteUserNameFromParent: string = "";
	// @Input() deleteUserEmailFromParent: string = "";
	// @Input() adminCountFromParent: number = 0;

	@Output() notifyUserDeleteStatus: EventEmitter<any> = new EventEmitter();

	public flag = false;
	public role = 'regular';
	public isExistingEmail = false;
	public userStatus: string;

	public endDate: string;

	public deleteuserErrorMsg: string;
	public isDeleteUserError = false;
	public selectedUser: string;
	public isDeleteUserSuccess = false;
	public showloader = false;
	public isLoading = false;

	public userName: string;
	public userEmail: string;

	constructor(
		@Inject(DIALOG_DATA) private data: any,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		private apiUsers: ApiUsersService,
	) {
		const user = this.data;

		this.userName = `${user.firstName} ${user.lastName}`;
		this.userEmail = user.email;
	}
	ngOnInit() {}

	// Confirm  to Delete User
	ConfirmDeleteUser(user: any) {
		this.DeleteUserFromAccount(this.data['userId']);
	}

	DismissSuccess() {
		this.isDeleteUserSuccess = false;
	}

	/**
	 * Delete user from Account
	 * @param userID
	 */
	DeleteUserFromAccount(userId: string) {
		// this.showloader = true;
		this.isLoading = true;
		this.apiUsers.removeUser(userId).subscribe(
			result => {
				if (!result['hasError']) {
					this.isDeleteUserSuccess = true;
				} else {
					this.deleteuserErrorMsg = result['errorMessages'][0]['message'];
					this.isDeleteUserError = true;
				}
				this.isLoading = false;
				this.showloader = false;
			},
			(error: any) => {
				this.isLoading = false;
			},
		);
	}

	// Dismiss error alret popup
	ErrorDismissAlert() {
		this.isDeleteUserError = false;
	}

	close() {
		this.dialogRef.dismiss(this.data);
	}
}
