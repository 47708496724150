import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import {
	ApiCallQueuesService,
	ApiDepartmentsService,
	ApiPhoneNumbersService,
	ApiRingGroupsService,
	ApiSpecialExtensionsService,
	ApiUsersService,
	ApiWelcomeMenusService,
	DialogService,
	FeatureFlagsService,
	SnackbarService,
	ApiFailoverService,
} from '@app/services';
import { CommonService } from '@app/services/Common.service';
import { ReplacePhoneNumberComponent } from './replace-phone-number/replace-phone-number.component';
import { GlobalLoaderService, Loader } from '@app/services/global-loader/global-loader.service';
import { RemovePhoneNumberDialogComponent } from './remove-phone-number-dialog/remove-phone-number-dialog.component';
import { IPhone } from '@app/Common/interfaces/IPhone';
import { matchSearchString, sortObjsByProp } from '@utils/helpers/functions';
import { PhoneNumberFormatterPipe } from '@app/pipes';
import { ValidatorReturnObject } from '@utils/helpers/validators';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { TeamMemberService } from '@app/services/caller-id/team-member.service';
import { IPutCallerIdParams } from '@app/services/devices/devices.domain';
import { CALL_QUEUE, VALID_CALL_QUEUE } from '@app/Common/constants/entity-types';
import { EditPhoneNumberCallerIdSettingsDialogComponent } from './edit-phone-number-caller-id-settings-dialog/edit-phone-number-caller-id-settings-dialog.component';
import { getEntityTypeForNumberUpdate } from './utils/type-map';
import { ActivatedRoute } from '@angular/router';
import { EditFailoverNumberComponent } from './edit-failover-number/edit-failover-number.component';
import { IFailoverPhoneNumber } from '@app/services/web-apis/phone-numbers-failover/api-phone-numbers-failover.domain';
import { tap } from 'rxjs/operators';

const ERROR_KEYS = {
	SINGLE_USER_ASSIGNED: 'singleUserAssigned',
	ASSIGNED_TO_FIVE9: 'assignedToFive9',
	NO_CALLER_ID: 'noCallerId',
	VIRTUAL_FAX_ASSIGNED: 'virtualFaxAssigned',
	ASSIGNED_TO_RMA: 'assignedToRMA',
};

const ONLY_DIGITS_REGEX = new RegExp('\\D+', 'gi');

@Component({
	selector: 'app-phone-numbers',
	templateUrl: './phone-numbers.component.html',
	styleUrls: ['./phone-numbers.component.scss'],
	providers: [PhoneNumberFormatterPipe, GlobalLoaderService],
})
export class PhoneNumbersComponent implements OnInit, OnDestroy {
	selectedPage: number = 0;
	resultsPerPage: number = 10;
	@ViewChild('phoneNumbersBodyRef', { static: true }) phoneNumbersBodyRef: ElementRef;
	phoneNumbers: Array<any>;
	filteredNumbers: Array<any>;
	userIdToLineMap: any = {};
	specialExtensionMap: any = {};
	callerIdMap: { [key: string]: string } = {};
	phoneNumberStats: { unUsedPhones: number; phoneNumbersInUse: number; maxPhoneNumbers: number };
	dropdownData: any;
	isAdmin: boolean = this.commonService.isAdmin();
	loader: Loader;
	formGroup: FormGroup;
	isBrazil: boolean = this.tenantService.isBrazilAccount();
	portingLoadError: boolean = false;
	hasUnassignedPortNumbers: boolean = false;
	hasNoCapacityForPortNumbers: boolean = false;
	isBusinessContinuityEnabled: boolean = false;

	errorKeys: typeof ERROR_KEYS = ERROR_KEYS;

	private sub: Subscription;

	searchFilter: string | undefined;

	constructor(
		private dialogService: DialogService,
		private commonService: CommonService,
		private globalLoader: GlobalLoaderService,
		private fb: FormBuilder,
		private phoneNumberService: ApiPhoneNumbersService,
		private failoverService: ApiFailoverService,
		private phoneNumberFormatter: PhoneNumberFormatterPipe,
		private apiUsers: ApiUsersService,
		private apiDepartments: ApiDepartmentsService,
		private apiRingGroups: ApiRingGroupsService,
		private apiWelcomeMenus: ApiWelcomeMenusService,
		private apiCallQueues: ApiCallQueuesService,
		private apiSpecialExtensions: ApiSpecialExtensionsService,
		public tenantService: ApiTenantService,
		private snackbarService: SnackbarService,
		private translate: TranslateService,
		public featureFlagsService: FeatureFlagsService,
		private teamMemberService: TeamMemberService,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
	) {
		this.formGroup = fb.group({
			phoneNumbers: [[]],
		});

		this.featureFlagsService
			.isBusinessContinuityEnabled()
			.pipe(tap(x => void (this.isBusinessContinuityEnabled = x)))
			.subscribe();
	}

	ngOnInit(): void {
		this.loader = this.globalLoader.create(this.phoneNumbersBodyRef);
		this.loadData();

		this.searchFilter = this.route.snapshot.queryParams['search']?.replace(ONLY_DIGITS_REGEX, '');
		this.sub = this.teamMemberService.onPutCallerId$.subscribe(this.handlePutCallerId.bind(this));
	}

	ngOnDestroy(): void {
		this.sub && this.sub.unsubscribe();
	}

	hasSingleAssignedTeamMember = (control: AbstractControl): ValidatorReturnObject => {
		const assignedTo = control.value[0];
		const assignedMappedUserLines = assignedTo && assignedTo.routeToId && this.userIdToLineMap[assignedTo.routeToId];
		const mappedUserHasOneLine = assignedMappedUserLines && assignedMappedUserLines.length === 1;

		if (!this.tenantService.accountCountryIsDidLess && mappedUserHasOneLine) {
			return { [this.errorKeys.SINGLE_USER_ASSIGNED]: true };
		}
	};

	assignedToFive9Extension = (control: AbstractControl): ValidatorReturnObject => {
		const assignedTo = control.value[0];

		const specialExtension = assignedTo && assignedTo.routeToId && this.specialExtensionMap[assignedTo.routeToId];
		const isFiveNineExtension = specialExtension && specialExtension.businessClass === 'FN';

		if (isFiveNineExtension) return { [this.errorKeys.ASSIGNED_TO_FIVE9]: true };
	};

	assignedToRMAExtension = (control: AbstractControl): ValidatorReturnObject => {
		const assignedTo = control.value[0];

		const specialExtension = assignedTo && assignedTo.routeToId && this.specialExtensionMap[assignedTo.routeToId];
		const isRMAExtension = specialExtension && specialExtension.businessClass === 'RMA';

		if (isRMAExtension) return { [this.errorKeys.ASSIGNED_TO_RMA]: true };
	};

	userHasNoCallerId = (control: AbstractControl): ValidatorReturnObject => {
		const assignedTo = control.value[0];
		const callerId = assignedTo && assignedTo.routeToId && this.callerIdMap[assignedTo.routeToId];
		const isTeamMember = assignedTo && assignedTo.type === 'team-member';
		const noCallerId = !callerId || callerId === '0';

		if (this.tenantService.accountCountryIsDidLess && isTeamMember && noCallerId) {
			return { [this.errorKeys.NO_CALLER_ID]: true };
		}
	};

	isVirtualFax = (control: AbstractControl): ValidatorReturnObject => {
		const assignedTo = control.value[0];

		if (assignedTo && assignedTo.routeType && assignedTo.routeType === 'virtualFax') {
			return { [this.errorKeys.VIRTUAL_FAX_ASSIGNED]: true };
		}
	};

	isPhoneNumberLimitReached = (): boolean => {
		return !this.phoneNumberStats?.unUsedPhones || this.phoneNumberStats?.unUsedPhones <= 0;
	};

	editPhone(phone): void {
		const isPhoneNumberLimitReached = this.isPhoneNumberLimitReached();
		const dialogRef = this.dialogService.create(
			ReplacePhoneNumberComponent,
			{
				phone,
				isPhoneNumberLimitReached,
			},
			{
				width: 510,
			},
		);

		dialogRef.onDismiss().subscribe(res => {
			if (res !== null) {
				this.loadData();
			}
		});
	}

	editFailoverNumber(numberFg: any): void {
		const model = numberFg.value;
		const failoverNumber = numberFg.get('failoverNumber').value;
		const dialogRef = this.dialogService.create(
			EditFailoverNumberComponent,
			{
				phoneNumber: model.number as string,
				failoverInfo: { ...failoverNumber } as IFailoverPhoneNumber,
			},
			{
				width: 510,
			},
		);

		dialogRef.onDismiss().subscribe((data: IFailoverPhoneNumber) => {
			data && numberFg.setValue({ ...model, failoverNumber: { ...data } });
		});
	}

	editPhoneNumberCallerIdSettings(phone): void {
		const dialogRef = this.dialogService.create(
			EditPhoneNumberCallerIdSettingsDialogComponent,
			{
				phone,
			},
			{
				width: 610,
			},
		);
	}

	deletePhone(phone: IPhone): void {
		const numberTypeMap = {
			user: 'team-member',
			department: 'department',
			ringGroup: 'ring-group',
			welcomeMenu: 'welcome-menu',
			maa: 'welcome-menu',
			menu: 'welcome-menu',
			specialExtension: 'special-extension',
			[CALL_QUEUE]: VALID_CALL_QUEUE,
		};

		const entity = this.dropdownData.find(
			e => e.type === numberTypeMap[phone.routeType] && (e.id || e.userId || e.deptId) === phone.routeToId,
		);

		if (entity && entity.lineNumber && entity.lineNumber.length) {
			phone['lineNumbers'] = entity.lineNumber.map(line => ({ number: line }));
		} else if (entity && entity.lines && entity.lines.length) {
			phone['lineNumbers'] = entity.lines.map(line => ({ number: line.lineId }));
		}
		phone['canBeDeleted'] = !(entity && entity.businessClass === 'FN');

		const dialogRef = this.dialogService.create(
			RemovePhoneNumberDialogComponent,
			{
				phone,
			},
			{
				width: 510,
			},
		);

		dialogRef.onDismiss().subscribe(res => {
			if (res !== undefined && !res) {
				// TODO show error
			} else if (res !== null && res) {
				this.loadData();
			}
		});
	}

	updateAssignedNumber(selectedItems: any[], numberFg: FormGroup): void {
		let numberToPatch;

		if (selectedItems.length) {
			const selectedItem = selectedItems[0];
			numberToPatch = {
				number: numberFg.get('number').value,
				routeToId: selectedItem.id || selectedItem.userId || selectedItem.deptId,
				routesTo: selectedItem.value,
				routeType: getEntityTypeForNumberUpdate(selectedItem.type),
				status: 'A',
			};
		} else {
			numberToPatch = {
				number: numberFg.get('number').value,
				routesTo: 'disconnect',
				status: 'A',
			};
		}

		this.phoneNumberService.updatePhoneNumber(numberToPatch).subscribe(() => {
			numberFg.setValue({ ...numberFg.value, routesTo: numberToPatch.routesTo });

			if (selectedItems.length) {
				numberFg.setValue({
					...numberFg.value,
					routesTo: numberToPatch.routesTo,
					routeToId: numberToPatch.routeToId,
					routeType: numberToPatch.routeType,
				});
			} else {
				numberFg.setValue({
					...numberFg.value,
					routesTo: '',
					routeToId: '',
					routeType: '',
				});
			}
		});
	}

	filter(str?: string): void {
		const phoneNumbers = this.formGroup.get('phoneNumbers').value;
		if (!str) {
			this.filteredNumbers = phoneNumbers;
		} else {
			this.selectedPage = 0;
			this.filteredNumbers = phoneNumbers.filter(fg =>
				matchSearchString(str, [
					fg.get('number').value,
					fg.get('formattedNumber').value,
					fg.get('pendingNumber').value,
					fg.get('formattedPendingNumber').value,
					fg.get('routesTo').value,
					fg.get('extension').value,
				]),
			);
		}
	}

	/*
	 * Method called in the template to return paginated numbers
	 */
	getPaginatedNumbers(filteredNumbers: Array<any>): Array<any> {
		if (!filteredNumbers) return;

		const skip = this.selectedPage * this.resultsPerPage;

		return filteredNumbers.slice(skip, skip + this.resultsPerPage);
	}

	shouldShowPagination(resultsTotal): boolean {
		return (
			this.filteredNumbers &&
			this.filteredNumbers.length &&
			resultsTotal &&
			Math.ceil(resultsTotal / this.resultsPerPage) > 1
		);
	}

	pageSelected(page): void {
		if (this.selectedPage === page - 1) return;
		this.selectedPage = page - 1;
	}

	numberHasError(numberFg: FormGroup, errorKey?: string): boolean {
		if (errorKey) return numberFg.get('assignedTo').hasError(errorKey);
		return Object.keys(this.errorKeys).some(key => numberFg.get('assignedTo').hasError(this.errorKeys[key]));
	}

	numberHasFailover(numberFg: FormGroup): boolean {
		return !!numberFg.value.failoverNumber?.failoverPhoneNumber;
	}

	numberHasErrorAndHovered(numberFg: FormGroup, key: string): boolean {
		return numberFg.get('assignedTo').hasError(key) && numberFg.get('hovered') && numberFg.get('hovered').value;
	}

	numberIsUsedInDidLessCountry(numberFg: FormGroup): boolean {
		const numberIsAssigned = !!numberFg.value.assignedTo && !!numberFg.value.assignedTo.length;
		const numberIsUsedAsCallerId = this.numberIsUsedAsCallerId(numberFg);

		return this.tenantService.accountCountryIsDidLess ? numberIsAssigned || numberIsUsedAsCallerId : false;
	}

	hasPendingNumber(value: FormGroup | IPhone): boolean {
		const model = value instanceof FormGroup ? (value as FormGroup).value : value;
		return !!model.pendingNumber;
	}

	hasTemporaryNumber(value: FormGroup | IPhone): boolean {
		const model = value instanceof FormGroup ? (value as FormGroup).value : value;
		return this.hasPendingNumber(model) && model.pendingNumber != model.number;
	}

	isPortWithoutTemporaryNumber(value: FormGroup | IPhone): boolean {
		return this.hasPendingNumber(value) && !this.hasTemporaryNumber(value);
	}

	numberIsUsedAsCallerId(numberFg: FormGroup): boolean {
		return Object.keys(this.callerIdMap).some(key => this.callerIdMap[key] === numberFg.value.number);
	}

	updateForbiddenAsCallerId(numberFg: FormGroup): void {
		const { number, allowedAsCallerId } = numberFg.value;

		numberFg.setValue({
			...numberFg.value,
			forbiddenAsCallerId: !allowedAsCallerId,
		});

		this.phoneNumberService.updateForbiddenAsCallerId(number, !allowedAsCallerId).subscribe(
			() => {
				this.snackbarService.createSuccess(
					`${numberFg.get('formattedNumber').value} ${this.translate.instant('GLOBALS.UPDATED_SUCCESSFULLY_MESSAGE')}`,
				);
			},
			() => {
				numberFg.setValue({
					...numberFg.value,
					forbiddenAsCallerId: !numberFg.value.forbiddenAsCallerId,
					allowedAsCallerId: !numberFg.value.allowedAsCallerId,
				});

				this.snackbarService.createDanger(this.translate.instant('GLOBALS.GENERAL_ERROR'));
			},
		);
	}

	isPendingToPortWithTemporary(numberFg: FormGroup): boolean {
		return numberFg.get('pendingNumber')?.value !== numberFg.get('number')?.value;
	}

	private mapEntities = (entityArray: Array<any>, type: string, valueFn?: Function) =>
		entityArray.map(entity => ({
			...entity,
			value: (valueFn && valueFn(entity)) || entity.name,
			selected: false,
			subValue: type !== 'welcome-menu' && type !== VALID_CALL_QUEUE ? entity.extension : undefined,
			type,
		}));

	loadData(): void {
		this.loader.show();

		const dataRequests = [
			this.apiUsers.getAllUsers().toPromise(),
			this.apiDepartments.getAllDepartmentsLW().toPromise(),
			this.apiRingGroups.getAllRingGroups().toPromise(),
			this.apiWelcomeMenus.getAllWelcomeMenusLW().toPromise(),
			this.apiSpecialExtensions.getAllSpecialExtensionsLW().toPromise(),
			this.featureFlagsService
				.isRequestNewPortFeatureEnabled()
				.toPromise()
				.then(x => this.phoneNumberService.getAllPhoneNumbers(false, false, x).toPromise()),
			this.apiCallQueues.getAllCallQueues().toPromise(),
			this.phoneNumberService.getPhoneNumberStats().toPromise(),
			this.failoverService.getFailoverNumbers().toPromise(),
		];

		Promise.all(dataRequests).then(res => {
			const users = (!res[0].hasError && res[0].data) || [];
			const departments = (!res[1].hasError && res[1].data.items) || [];
			const ringGroups = (!res[2].hasError && res[2].data) || [];
			const welcomeMenus = (!res[3].hasError && res[3].data.items) || [];
			const specialExtensions = (!res[4].hasError && res[4].data.items) || [];
			const phoneNumbers = (!res[5].hasError && res[5].data) || [];
			const phoneNumbersResponse = res[5];
			const callQueues = res[6].items || [];
			this.phoneNumberStats = (!res[7].hasError && res[7].data) || {};
			const failoverNumbers = res[8]?.data || [];

			const concatData = [
				...this.mapEntities(users, 'team-member', tm => `${tm.firstName} ${tm.lastName}`),
				...this.mapEntities(departments, 'department'),
				...this.mapEntities(ringGroups, 'ring-group'),
				...this.mapEntities(welcomeMenus, 'welcome-menu'),
				...this.mapEntities(specialExtensions, 'special-extension'),
				...this.mapEntities(callQueues, VALID_CALL_QUEUE, callQeue => callQeue.display_name),
			];

			users.forEach(user => {
				this.userIdToLineMap[user.userId] = user.lineNumber;
				this.callerIdMap[user.userId] = user.callerId;
			});

			specialExtensions.forEach(se => {
				this.specialExtensionMap[se.id] = se;
			});

			this.portingLoadError = phoneNumbersResponse?.requestedPortingData && !phoneNumbersResponse?.retrievedPortingData;
			this.phoneNumbers = phoneNumbers;
			this.phoneNumbers = this.phoneNumbers.map(phoneNumber => {
				if (phoneNumber.routeType !== 'virtualFax') return phoneNumber;

				return {
					...phoneNumber,
					routesTo: this.translate.instant('VIRTUAL_FAX_PAGE.TITLE'),
				};
			});

			const numberTypeMap = {
				user: 'team-member',
				department: 'department',
				ringGroup: 'ring-group',
				welcomeMenu: 'welcome-menu', // TODO: standardize types on the backend
				maa: 'welcome-menu',
				menu: 'welcome-menu',
				specialExtension: 'special-extension',
				virtualFax: 'virtual-fax',
				[CALL_QUEUE]: VALID_CALL_QUEUE,
			};

			this.formGroup.get('phoneNumbers').setValue(
				this.phoneNumbers.map(phoneNumber =>
					this.fb.group({
						...phoneNumber,
						routesTo: phoneNumber.routesTo ? phoneNumber.routesTo : this.translate.instant('GLOBALS.UNASSIGNED'),
						formattedPendingNumber: this.phoneNumberFormatter.transform({ number: phoneNumber.pendingNumber }),
						formattedNumber: this.phoneNumberFormatter.transform({ number: phoneNumber.number }),
						hovered: false,
						failoverNumber: failoverNumbers.find(fopn => fopn?.phoneNumber?.includes(phoneNumber.number)),
						allowedAsCallerId: !phoneNumber.forbiddenAsCallerId,
						assignedTo: [
							// we'll only want to add a selected value if the number is assigned to an entity
							phoneNumber.routesTo
								? [
										{
											...phoneNumber,
											value: phoneNumber.routesTo,
											submitValue: phoneNumber.routeToId,
											type: numberTypeMap[phoneNumber.routeType],
											subValue: phoneNumber.extension, // this is so that the dropdown will show the subtext of the selected item
											selected: true,
										},
								  ]
								: [],
							[
								this.hasSingleAssignedTeamMember,
								this.assignedToFive9Extension,
								this.assignedToRMAExtension,
								this.userHasNoCallerId,
								this.isVirtualFax,
							],
						],
					}),
				),
			);

			const unassignedPortNumbers = this.phoneNumbers.filter(x => x.pendingNumber === x.number);
			this.hasUnassignedPortNumbers = unassignedPortNumbers.length > 0;
			this.hasNoCapacityForPortNumbers =
				this.hasUnassignedPortNumbers && unassignedPortNumbers.length > this.phoneNumberStats.unUsedPhones;
			this.dropdownData = sortObjsByProp('value', concatData);
			this.selectedPage = 0;
			this.filter(this.searchFilter);
			this.loader.hide();
			this.cdr.detectChanges();
		});
	}

	private handlePutCallerId({ isActive, setting, userId }: IPutCallerIdParams): void {
		if (this.callerIdMap) {
			if (isActive) {
				this.callerIdMap = {
					...this.callerIdMap,
					[userId]: setting,
				};
			} else {
				const callerIdClone = { ...this.callerIdMap };
				delete callerIdClone[userId];
				this.callerIdMap = callerIdClone;
			}
		}
	}
}
