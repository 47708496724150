import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api/api.service';
import { Observable } from 'rxjs/Observable';

import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { IDepartment } from '@app/services/web-apis/departments/api-departments.domain';
import { IAudioContent } from '@app/services/web-apis/audio/api-audio.domain';

@Injectable()
export class DepartmentService {
	constructor(private apiService: ApiService) {}

	/*
    Get All Departments under account id
  */
	getAccountDepartments = (filterUsersWithSingleLine?: boolean): Observable<IRegularApiResponse<IDepartment[]>> => {
		const url = `/accounts/{accountId}/departments${filterUsersWithSingleLine ? '?includeLineNumbersFlag=Y' : ''}`;

		return this.apiService.get(url);
	};

	getVM = (departmentId: number, audioType?: string): Observable<IRegularApiResponse<IAudioContent>> => {
		let url = `/accounts/{accountId}/departments/${departmentId}/audio`;
		if (audioType) {
			url += `?audioType=${audioType}`;
		}

		return this.apiService.get(url);
	};

	callPhoneNumber = (departmentId: number, lineNumber: string): Observable<any> => {
		const url = `/accounts/{accountId}/departments/${departmentId}/audio?audioType=2&lineNumber=${encodeURIComponent(
			lineNumber,
		)}`;

		return this.apiService.post(url, {});
	};

	pingVMStatus = (recordingId: number): Observable<IRegularApiResponse<string>> => {
		const url = `/accounts/{accountId}/recordings/${recordingId}`;

		return this.apiService.get(url);
	};

	deactivateVMGreeting = (departmentId: number): Observable<any> => {
		const url = `/accounts/{accountId}/departments/${departmentId}/audio/deactivateVMGreeting`;

		return this.apiService.put(url);
	};

	deleteDepartment(departmentId: number): Observable<any> {
		return this.apiService.delete(`/accounts/{accountId}/departments/${departmentId}`);
	}
}
