import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { environment } from '@env/environment';
import { COLORS, isValidColor } from 'app/Common/constants/colors';

@Component({
	selector: 'n2p-svg',
	templateUrl: './svg.component.html',
	styleUrls: ['./svg.component.scss'],
})
export class SvgComponent implements OnInit, OnChanges {
	@Input() class: string;
	@Input() fill: string;
	@Input() width: number;
	@Input() height: number;
	@Input() size: number;
	@Input() hoverFill: string;
	@Input() pressFill: string;

	@Input() autoEventFills = false;

	@Input() set iconName(iconName) {
		this._url = `images/${iconName}.svg?v=${environment.version}#${iconName}`;
	}

	pressed = false;
	hovered = false;

	private _url: string;
	private _fill: string;
	private _hoverFill: string;
	private _pressFill: string;

	ngOnInit(): void {
		// set hardcoded
		this.setFills();
	}

	ngOnChanges(): void {
		this.setFills();
	}

	get url(): string {
		return this._url;
	}

	get hasWidth(): boolean {
		return this.width !== undefined || this.size !== undefined;
	}
	get hasHeight(): boolean {
		return this.height !== undefined || this.size !== undefined;
	}

	get style(): any {
		const styles = {
			'mask-image': `url(${this._url})`,
			'-webkit-mask-image': `url(${this._url})`,
			'mask-repeat': 'no-repeat',
			'-webkit-mask-repeat': 'no-repeat',
			'background-color': this._fill || 'currentColor',
		};

		if (this.hasWidth) {
			styles['width'] = `${this.width || this.size}px`;
		} else {
			styles['width'] = '100%';
		}

		if (this.hasHeight) {
			styles['height'] = `${this.height || this.size || 12}px`;
		} else {
			styles['height'] = '100%';
		}

		if (this.pressed) {
			styles['background-color'] = this._pressFill;
		} else if (this.hovered) {
			styles['background-color'] = this._hoverFill;
		}

		return styles;
	}

	setFills(): void {
		// set hardcoded
		['fill', 'hoverFill', 'pressFill'].forEach(fill => {
			const color = this[fill];
			this[`_${fill}`] = !color || !isValidColor(color) ? color : COLORS[color];
		});

		// set defaults if value not set
		if (this.autoEventFills && isValidColor(this.fill)) {
			this._fill = COLORS[this.fill];
			this._hoverFill = this._hoverFill || COLORS[`${this.fill}-hover`];
			this._pressFill = this._pressFill || COLORS[`${this.fill}-press`];
		}
	}

	hover(hovered = true): void {
		if (this._hoverFill) this.hovered = hovered;
	}
	press(pressed = true): void {
		if (this._pressFill) this.pressed = pressed;
	}
}
