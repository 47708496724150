import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DROPDOWN_UNIQUE_VALUE_DIVIDER } from 'n2p-ui-library';
import { Subscription, combineLatest } from 'rxjs';
import { IDropdownGroup, IDropdownOption } from 'n2p-ui-library/components/dropdown/dropdown.domain';
import { getDropdownSelectedValueData } from 'n2p-ui-library/components/dropdown/dropdown.helpers';

import { ApiAddressService } from '@app/services/web-apis/address/api-address.service';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { IPredictedAddress, PredictionLevel } from '@n2p/predicted-address/predicted-address.domain';

@Component({
	selector: 'n2p-predicted-address',
	templateUrl: './predicted-address.component.html',
	styleUrls: ['./predicted-address.component.scss'],
})
export class PredictedAddressComponent implements OnInit, OnDestroy {
	@Input() countryCodes: string[];
	@Input() stateCode?: string;
	@Input() cityCode?: string;
	@Input() localityCode?: string;
	@Input() predictionLevel: PredictionLevel = 'locality';
	@Input() disabled?: boolean;
	countryCode: string;
	uniqueDivider: string = DROPDOWN_UNIQUE_VALUE_DIVIDER;
	initialCountryCode: string;
	stateGroups?: IDropdownGroup[];
	stateOptions?: IDropdownOption[];
	cityOptions?: IDropdownOption[];
	localityOptions?: IDropdownOption[];

	@Output() addressSet: EventEmitter<IPredictedAddress> = new EventEmitter();

	private sub: Subscription;
	private initialized: boolean = false;
	private readonly PREDICTION_LEVELS_ORDER: PredictionLevel[] = ['state', 'city', 'locality'];

	constructor(private tenantService: ApiTenantService, private addressService: ApiAddressService) {}

	ngOnInit(): void {
		this.sub = this.tenantService.account$.subscribe(() => {
			this.countryCodes = this.countryCodes || [this.tenantService.countryCode];
			this.countryCode = this.countryCodes[0];

			this.setData(this.countryCode, this.stateCode, this.cityCode, this.localityCode);
			this.initialized = true;
		});
	}

	ngOnDestroy(): void {
		this.sub && this.sub.unsubscribe();
	}

	setData(countryCode: string, stateCode?: string, cityCode?: string, localityCode?: string): void {
		// we handle case when stateCode with CountryCode is selected e.g. US:BX or CA:AS
		if (stateCode && stateCode.includes(DROPDOWN_UNIQUE_VALUE_DIVIDER)) {
			const { value, parentIds } = getDropdownSelectedValueData(stateCode);
			stateCode = value;
			countryCode = parentIds[0];
		}

		const countryChanged = countryCode !== this.countryCode;
		const stateChanged = stateCode !== this.stateCode;
		const cityChanged = cityCode !== this.cityCode;
		const localityChanged = localityCode !== this.localityCode;

		if (!stateCode || stateChanged) {
			this.cityOptions = undefined;
			this.localityOptions = undefined;
		}

		if (!cityCode || cityChanged) {
			this.localityOptions = undefined;
		}

		this.countryCode = countryCode;
		this.stateCode = stateCode;
		this.cityCode = stateCode ? cityCode : undefined;
		this.localityCode = stateCode && cityCode ? localityCode : undefined;

		this.fetchNeedAddressItems();

		if (this.initialized && (countryChanged || stateChanged || cityChanged || localityChanged)) {
			this.addressSet.emit({ countryCode, stateCode, cityCode, localityCode });
		}
	}

	predictionLevelIsBiggerThan(minPredictionLevel: PredictionLevel): boolean {
		const currentIndex = this.PREDICTION_LEVELS_ORDER.indexOf(this.predictionLevel);
		const minIndex = this.PREDICTION_LEVELS_ORDER.indexOf(minPredictionLevel);

		return currentIndex >= minIndex;
	}

	private fetchNeedAddressItems(): void {
		const { countryCode, countryCodes, stateCode, cityCode } = this;

		if (countryCode && this.predictionLevelIsBiggerThan('state')) {
			if (countryCodes.length === 1 && !this.stateOptions) {
				this.fetchAddressItems(countryCode);
			} else if (countryCodes.length > 1 && !this.stateGroups) {
				this.fetchStatesItems(countryCodes);
			}
		}

		if (stateCode && !this.cityOptions && this.predictionLevelIsBiggerThan('city')) {
			this.fetchAddressItems(countryCode, stateCode);
		}

		if (cityCode && !this.localityOptions && this.predictionLevelIsBiggerThan('locality')) {
			this.fetchAddressItems(countryCode, stateCode, cityCode);
		}
	}

	private fetchStatesItems(countryCodes: string[]): void {
		combineLatest(countryCodes.map(cc => this.addressService.fetchAddressItems(cc))).subscribe(countriesStates => {
			this.stateGroups = countriesStates.map((countryStates, i) => ({
				title: countryCodes[i],
				id: countryCodes[i],
				options:
					countryStates &&
					countryStates.map(item => {
						return {
							title: item.name,
							value: item.code,
						};
					}),
			}));
		});
	}

	private fetchAddressItems(countryCode: string, stateCode?: string, cityCode?: string): void {
		this.addressService.fetchAddressItems(countryCode, stateCode, cityCode).subscribe(items => {
			const options: IDropdownOption[] =
				items &&
				items.map(item => {
					return {
						title: item.name,
						value: item.code,
					};
				});

			if (cityCode) {
				this.localityOptions = options;
			} else if (stateCode) {
				this.cityOptions = options;
			} else {
				this.stateOptions = options;
			}
		});
	}
}
