import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PhoneNumberFormatterPipeModule } from './phone-number-formatter';
import { DateStringFormatterPipe } from './date-string-formatter/date-string-formatter.pipe';
import { MacFormatterPipe } from './mac-formatter/mac-formatter.pipe';
import { DurationPipe } from './duration.pipe';
import { DayOfWeekPipe } from './day-of-week/day-of-week.pipe';
import { ScheduleTimePipe } from './schedule-time/schedule-time.pipe';
import { ScheduleDaysPipe } from './schedule-days/schedule-days.pipe';
import { HighlightPipe } from './highlight/highlight.pipe';
import { FileSizePipe } from './file-size/file-size.pipe';
import { FormValueChangedPipe } from './form-value-changed/form-value-changed.pipe';
import { FormatDatePipe } from '@app/pipes/date/format-date.pipe';
import { CurrencyFormatterPipe } from '@app/pipes/currency-formatter/currency-formatter.pipe';

@NgModule({
	imports: [CommonModule, PhoneNumberFormatterPipeModule],
	declarations: [
		MacFormatterPipe,
		DateStringFormatterPipe,
		DurationPipe,
		DayOfWeekPipe,
		ScheduleTimePipe,
		ScheduleDaysPipe,
		HighlightPipe,
		FileSizePipe,
		FormValueChangedPipe,
		FormatDatePipe,
		CurrencyFormatterPipe,
	],
	exports: [
		MacFormatterPipe,
		DateStringFormatterPipe,
		DurationPipe,
		DayOfWeekPipe,
		ScheduleTimePipe,
		ScheduleDaysPipe,
		HighlightPipe,
		FileSizePipe,
		FormValueChangedPipe,
		FormatDatePipe,
		CurrencyFormatterPipe,
		PhoneNumberFormatterPipeModule,
	],
})
export class PipesModule {}
