import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map, tap } from 'rxjs/operators';

import { ApiService } from '@app/services/api/api.service';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import {
	IAccountVoicemailNotificationSettings,
	IConcreteVoicemail,
	IVoicemailLink,
	IVoiceMailMessage,
	VoicemailOwnerType,
	IVoiceMailStatus,
	IVoiceMailStatusResponse,
} from '@app/services/web-apis/voicemails/api-voicemails.domain';

export const patchHeaders = {
	'Content-Type': 'application/json',
};

@Injectable()
export class ApiVoicemailsService {
	constructor(private apiService: ApiService) {}

	getVoicemailLink(voicemail: number): Observable<string> {
		return this.apiService
			.get<IVoicemailLink>(
				`/voice-mail/${voicemail}`,
				{},
				{
					headers: {
						Accept: 'application/json',
					},
				},
			)
			.pipe(map(r => r.url));
	}

	getConcreteVoicemail(id: number, voicemail: number, type: VoicemailOwnerType): Observable<IConcreteVoicemail> {
		const entityUrlPart = type === 'US' ? 'users' : 'departments';
		const url = `/accounts/{accountId}/${entityUrlPart}/${id}/voicemails/${voicemail}`;
		return this.apiService.get<IRegularApiResponse<IConcreteVoicemail>>(url).pipe(
			map(r => {
				if (r.hasError) throw new Error();

				return r.data;
			}),
		);
	}

	getAccountVoicemailStats(): Observable<any> {
		const path = '/accounts/{accountId}/voicemailstats';

		return this.apiService.get(path);
	}

	getUserVoicemailStats(): Observable<any> {
		const path = '/accounts/{accountId}/users/{userId}/voicemailstats';

		return this.apiService.get(path);
	}

	// TODO: seems like mehtod not used and should be deleted in future (N2P2-3422)
	getAccountVoicemails(
		onlyUnread: boolean = false,
		count?: number,
		startTime?: string,
		endTime?: string,
	): Observable<any> {
		const path = '/accounts/{accountId}/voicemails';
		const query = {
			type: onlyUnread ? 'Unread' : 'All',
			count,
			startTime,
			endTime,
		};

		return this.apiService.get(path, query);
	}

	updateAccountVoicemailSettings(
		body: IAccountVoicemailNotificationSettings | null,
	): Observable<IRegularApiResponse<IAccountVoicemailNotificationSettings>> {
		const path = '/accounts/{accountId}/voicemailSettings';
		return this.apiService
			.put(path, {
				EmailNotify: body.emailNotify,
				EmailIncludeVoicemail: body.emailIncludeVoicemail,
				EmailTranscribe: body.emailTranscribe,
				EmailIncludeCallerDetails: body.emailIncludeCallerDetails,
				EmailRestrictChanges: body.emailRestrictChanges,
			})
			.pipe(
				tap(({ hasError, errorMessages }) => {
					if (hasError) throw errorMessages;
				}),
			);
	}

	getAccountVoicemailSettings(): Observable<IRegularApiResponse<IAccountVoicemailNotificationSettings>> {
		const path = '/accounts/{accountId}/voicemailSettings';
		return this.apiService.get(path).pipe(
			tap(({ hasError, errorMessages }) => {
				if (hasError) throw errorMessages;
			}),
		);
	}

	getUserVoicemails(
		onlyUnread: boolean = false,
		count?: number,
		startTime?: string,
		endTime?: string,
		sorting: 'desc' | 'asc' = 'asc',
	): Observable<any> {
		const path = '/accounts/{accountId}/users/{userId}/voicemails';
		const query = {
			type: onlyUnread ? 'Unread' : 'All',
			count,
			startTime,
			endTime,
			sorting,
		};

		return this.apiService.get(path, query);
	}

	updateUserVoicemail(
		voicemailId: number | string,
		userId?: string,
		status: IVoiceMailStatus = IVoiceMailStatus.READ,
	): Observable<IRegularApiResponse<IVoiceMailStatusResponse>> {
		return this.apiService.patch(
			this.getUserVoiceMailUrl(voicemailId, userId),
			String(status),
			{},
			{ headers: patchHeaders },
		);
	}

	removeUserVoicemail(voicemailId: number | string, userId?: string): Observable<IRegularApiResponse<string>> {
		return this.apiService.delete(this.getUserVoiceMailUrl(voicemailId, userId));
	}

	getDepartmentVoicemail(
		departmentId: number | string,
		voicemailId: number | string,
	): Observable<IRegularApiResponse<IVoiceMailMessage>> {
		return this.apiService.get(this.getDepartmentVoiceMailUrl(departmentId, voicemailId));
	}

	updateDepartmentVoicemail(
		departmentId: number | string,
		voicemailId: number | string,
		status: IVoiceMailStatus = IVoiceMailStatus.READ,
	): Observable<IRegularApiResponse<IVoiceMailStatusResponse>> {
		return this.apiService.patch(
			this.getDepartmentVoiceMailUrl(departmentId, voicemailId),
			String(status),
			{},
			{ headers: patchHeaders },
		);
	}

	removeDepartmentVoicemail(
		departmentId: number | string,
		voicemailId: number | string,
	): Observable<IRegularApiResponse<string>> {
		return this.apiService.delete(this.getDepartmentVoiceMailUrl(departmentId, voicemailId));
	}

	private getUserVoiceMailUrl(voicemailId: number | string, userId: string = '{userId}'): string {
		return `/accounts/{accountId}/users/${userId}/voicemails/${voicemailId}`;
	}

	private getDepartmentVoiceMailUrl(departmentId: number | string, voicemailId: number | string): string {
		return `/accounts/{accountId}/departments/${departmentId}/voicemails/${voicemailId}`;
	}
}
