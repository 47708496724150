import { Component, Inject, OnInit } from '@angular/core';

import { DIALOG_REF } from '@n2p/dialog/dialog.injectors';
import { DialogRef } from '@n2p/dialog/dialogRef';

@Component({
	selector: 'app-domestic-number-error-alert',
	templateUrl: './domestic-number-error-alert.component.html',
	styleUrls: ['./domestic-number-error-alert.component.scss'],
})
export class DomesticNumberErrorAlertComponent implements OnInit {
	constructor(@Inject(DIALOG_REF) private dialogRef: DialogRef) {}

	ngOnInit(): void {}

	close(): void {
		this.dialogRef.dismiss();
	}
}
