import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UserAgentService {
	public isBrowser(): boolean {
		const isTeams = this.isTeams();
		if (isTeams) return false;

		return this.isChrome() || this.isSafari() || this.isEdge() || this.isIE() || this.isOpera() || this.isFirefox();
	}

	public isTeams(): boolean {
		return navigator.userAgent.toLowerCase().includes('teams');
	}

	public isOpera(): boolean {
		return navigator.userAgent.toLowerCase().includes('opera');
	}

	public isIE(): boolean {
		return navigator.userAgent.toLowerCase().includes('trident');
	}

	public isEdge(): boolean {
		return navigator.userAgent.toLowerCase().includes('edge');
	}

	public isFirefox(): boolean {
		return navigator.userAgent.toLowerCase().includes('firefox');
	}

	public isChrome(): boolean {
		return navigator.userAgent.toLowerCase().includes('chrome');
	}

	public isSafari(): boolean {
		return navigator.userAgent.toLowerCase().includes('safari');
	}
}
