import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';

import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import {
	IBulkRequest,
	ICallHistoryCSVQuery,
	ICallHistoryQuery,
	ICallHistorySummaryV2,
	ICallRecordLink,
} from '@app/services/callhistory/callhistory.domain';

@Injectable()
export class CallHistoryService {
	constructor(private apiService: ApiService) {}

	/*
    http://qa.net2phone.com/swagger/ui/index#!/Cdr/Cdr_GetCallLegs
  */
	fetchCallLegsByCallId(callId: string): Observable<any> {
		return this.apiService.post('/account/{accountId}/calldetailrecords', { callid: callId });
	}

	getRecordingSrc(recordingId: string): Observable<string> {
		return this.apiService
			.get<ICallRecordLink>(
				`/call-record/${recordingId}`,
				{},
				{
					headers: {
						Accept: 'application/json',
					},
				},
			)
			.pipe(map(r => r.url));
	}

	setRecordingsStatus(recordingIds: string[], status: any): Observable<any> {
		let url = '/accounts/{accountId}/callrecordings';
		for (let i = 0; i < recordingIds.length; i++) {
			if (i === 0) {
				url += `?recordingIds=${recordingIds[i]}`;
			} else {
				url += `&recordingIds=${recordingIds[i]}`;
			}
		}
		url += `&status=${status}`;

		return this.apiService.patch(url).pipe(
			tap((res: IRegularApiResponse<any>) => {
				if (res.hasError) {
					const message = res.errorMessages ? res.errorMessages[0].message : 'Unknown Error';
					throw new Error(message);
				}
			}),
		);
	}

	fetchCallHistoryV2(queryData?: ICallHistoryQuery): Observable<IRegularApiResponse<ICallHistorySummaryV2>> {
		return this.apiService.post('/account/{accountId}/callhistorysummaryv2', queryData);
	}

	getCSV(queryData?: ICallHistoryCSVQuery): Observable<any> {
		return this.apiService.post(
			'/account/{accountId}/callhistorysummaryv2/csv',
			queryData,
			{},
			{
				responseType: 'text',
			},
		);
	}

	sendBulkDownload(payload: IBulkRequest): Observable<IRegularApiResponse<any>> {
		return this.apiService.post('/account/{accountId}/users/{userId}/download/bulk', payload);
	}

	bulkDelete(payload: IBulkRequest): Observable<IRegularApiResponse<any>> {
		return this.apiService.deleteWithBody('/account/{accountId}/users/{userId}/delete/bulk', payload);
	}
}
