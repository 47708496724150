import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

import { DropdownData } from '@app/n2p-components/dropdown/dropdown.component';
import { SCHEDULE_INTERVAL_FORMGROUP } from '@app/n2p-components/schedule-interval/schedule-interval.injectors';
import { makeTwoDigits } from '@utils/helpers/functions';
import { DateFormatterService } from '@app/services';

@Component({
	selector: 'app-from-to-popup',
	templateUrl: './from-to-popup.component.html',
	styleUrls: ['./from-to-popup.component.scss'],
})
export class FromToPopupComponent implements OnInit, OnDestroy {
	fromToFormGroup: FormGroup;
	fromToFormGroupInner: FormGroup;
	//TODO this needs to changes once i figure out why the dropdown is keeping the same reference
	fromTimes: Array<DropdownData>;
	toTimes: Array<DropdownData>;
	fromSubscription: Subscription;
	toSubscription: Subscription;
	formGroupSubscription: Subscription;
	is24HoursMode: boolean = this.dateFormatter.isMilitaryTime;
	constructor(
		@Inject(SCHEDULE_INTERVAL_FORMGROUP) formGroup: FormGroup,
		private fb: FormBuilder,
		private dateFormatter: DateFormatterService,
	) {
		this.fromToFormGroup = formGroup;
		const fromAmPm = this.fromToFormGroup.get('fromAmPm').value || 'am';
		const toAmPm = this.fromToFormGroup.get('toAmPm').value || 'am';
		this.fromToFormGroupInner = this.fb.group({
			from: [this.timeTo24Hours(this.fromToFormGroup.get('from').value, fromAmPm), this.validateTime.bind(this)],
			fromAmPm: [fromAmPm],
			to: [this.timeTo24Hours(this.fromToFormGroup.get('to').value, toAmPm), this.validateTime.bind(this)],
			toAmPm: [toAmPm],
		});

		this.formGroupSubscription = this.fromToFormGroupInner.valueChanges.subscribe(value => {
			if (this.fromToFormGroupInner.valid) {
				const { from, fromAmPm, to, toAmPm } = value;
				this.fromToFormGroup.get('from').setValue(this.from24Hours(from));
				this.fromToFormGroup.get('fromAmPm').setValue(fromAmPm);
				this.fromToFormGroup.get('to').setValue(this.from24Hours(to));
				this.fromToFormGroup.get('toAmPm').setValue(toAmPm);
			}
		});
	}

	setAmPm(direction, value): void {
		const from = this.fromToFormGroupInner.get('from').value;
		const to = this.fromToFormGroupInner.get('to').value;
		if ((direction === 'fromAmPm' && !from) || (direction === 'toAmPm' && !to)) return;
		const fromAmPm = direction === 'fromAmPm' ? value : this.fromToFormGroupInner.get('fromAmPm').value;
		const toAmPm = direction === 'toAmPm' ? value : this.fromToFormGroupInner.get('toAmPm').value;
		if (`${from}${fromAmPm}` !== `${to}${toAmPm}`) this.fromToFormGroupInner.get(direction).setValue(value);
	}

	buildTimes = (): Array<DropdownData> => {
		const arr = [];
		const FROM = this.is24HoursMode ? 0 : 8;
		const TO = this.is24HoursMode ? 23 : 19;
		for (let i = FROM; i <= TO; i++) {
			for (let j = 0; j < 4; j++) {
				const hours24 = i === 0 ? '0' : makeTwoDigits(i);
				const hours12 = `${i < 10 ? `0${i}` : `${i > 12 ? `${makeTwoDigits(i - 12)}` : `${i}`}`}`;
				const minutes = `${j === 0 ? '00' : 15 * j}`;
				arr.push({
					value: `${this.is24HoursMode ? hours24 : hours12}:${minutes}`,
					selected: false,
					hours: i,
					minutes: j === 0 ? 0 : 15 * j,
				});
			}
		}

		return arr;
	};

	ngOnInit(): void {
		this.fromTimes = this.buildTimes();
		this.toTimes = this.buildTimes();

		this.fromSubscription = this.fromToFormGroupInner.get('from').valueChanges.subscribe(from => {
			if (this.is24HoursMode) {
				const [hours] = from.split(':');
				this.fromToFormGroupInner.get('fromAmPm').setValue(parseInt(hours) < 12 ? 'am' : 'pm');
			} else {
				const fromAmPm = this.fromToFormGroupInner.get('fromAmPm').value;
				const to = this.fromToFormGroupInner.get('to').value;
				const toAmPm = this.fromToFormGroupInner.get('toAmPm').value;
				if (fromAmPm && `${from}${fromAmPm}` === `${to}${toAmPm}`) {
					this.fromToFormGroupInner.get('fromAmPm').setValue(fromAmPm === 'am' ? 'pm' : 'am');
				}
			}
		});

		this.toSubscription = this.fromToFormGroupInner.get('to').valueChanges.subscribe(to => {
			if (this.is24HoursMode) {
				const [hours] = to.split(':');
				this.fromToFormGroupInner.get('toAmPm').setValue(parseInt(hours) < 12 ? 'am' : 'pm');
			} else {
				const toAmPm = this.fromToFormGroupInner.get('toAmPm').value;
				const from = this.fromToFormGroupInner.get('from').value;
				const fromAmPm = this.fromToFormGroupInner.get('fromAmPm').value;
				if (toAmPm && `${to}${toAmPm}` === `${from}${fromAmPm}`) {
					this.fromToFormGroupInner.get('toAmPm').setValue(toAmPm === 'am' ? 'pm' : 'am');
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.formGroupSubscription.unsubscribe();
		this.fromSubscription.unsubscribe();
		this.toSubscription.unsubscribe();
	}

	private checkBoundaries(time: string) {
		const middleIndex = time.indexOf(':');
		const hour = time.substr(0, middleIndex);
		const minutes = time.substr(middleIndex + 1, time.length);
		if (
			!hour ||
			!minutes ||
			hour.length > 2 ||
			minutes.length !== 2 ||
			Number(hour) > (this.is24HoursMode ? 24 : 12) ||
			Number(minutes) > 59
		) {
			return false;
		}
		return true;
	}

	private validateTime(control: AbstractControl): { invalidTime: boolean } | null {
		const regexpMatch = control.value.match('[a-zA-Z]+');
		const includesColon = control.value.indexOf(':') !== -1;

		if ((!control.value && !control.dirty) || (includesColon && !regexpMatch && this.checkBoundaries(control.value))) {
			return null;
		}

		return { invalidTime: true };
	}

	private timeTo24Hours(time: string, amPm: string): string {
		if (this.is24HoursMode && time) {
			const [hourStr, minuteStr] = (time || '').split(':');
			const hour = parseInt(hourStr, 10);
			const minute = parseInt(minuteStr, 10);
			return `${amPm === 'pm' && hour !== 12 ? hour + 12 : hour === 12 ? 0 : makeTwoDigits(hour)}:${makeTwoDigits(
				minute,
			)}`;
		}
		return time;
	}

	private from24Hours(time: string): string {
		if (this.is24HoursMode && time) {
			const [hourStr, minuteStr] = (time || '').split(':');
			const hour = parseInt(hourStr, 10);
			const minute = parseInt(minuteStr, 10);
			return `${hour > 12 ? makeTwoDigits(hour - 12) : makeTwoDigits(hour)}:${makeTwoDigits(minute)}`;
		}
		return time;
	}
}
