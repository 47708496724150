import { IVoicemailSettings } from '@app/services/web-apis/voicemails/api-voicemails.domain';
import { IScheduleRule } from '@app/services/web-apis/schedules/api-schedules.domain';

export interface IUserAvatar {
	size: string;
	type: string;
	url: string;
}

export interface IUserDepartment {
	id: number;
	name: string;
	businessClass: string;
	ownerId: number;
	status: string;
}

export interface IUserInfo {
	userId: string;
	name: string;
	email: string;
	avatars: IUserAvatar[];
}

export interface IUserLight {
	avatars?: IUserAvatar[];
	userId: number;
	firstName: string;
	lastName: string;
	email: string;
	extension: string;
}

export interface IUserLightModified extends IUserLight {
	avatarStr?: string;
	deleted?: boolean;
}

export enum IUserRole {
	REGULAR = 'regular',
	ADMIN = 'admin',
}

export interface ICompDir {
	enabled: boolean;
	audioType: number;
}

export const ROLE_SCORES: IUserRole[] = [IUserRole.REGULAR, IUserRole.ADMIN];

export interface IUser extends IUserLight, IVoicemailSettings {
	businessClass: string;
	creditLimit: number;
	lineNumber?: string[];
	members: IUserDepartment[];
	portalLoginFlg: boolean;
	role: IUserRole;
	serviceAddressId: number;
	sipDeviceRings: number;
	status: string;
	timeZone: string;
	uiLanguageCode: string;
	userInfo?: IUserInfo;
	hasCustomMusicOnHold: boolean;
	isRingGroupCallsEnabled: boolean;
	canCreatePhysicalDevices: boolean;
	compDir: ICompDir;
	userId: number;
	firstName: string;
	lastName: string;
	email: string;
	extension: string;
	FullName: string;
	deptNames: string;
	ninjaId: string;
}

export interface IExtendedUser extends IUser {
	value: string;
	subValue: string;
}

export interface IUserStatistic {
	userId: number;
	extension: number;
	firstName: string;
	lastName: string;
	status: string;
	totalCalls: number;
	totalVMs: number;
	userInfo: IUserInfo;
	role: string;
}

export enum ForwardStatus {
	CREATED = 'C',
	ACTIVE = 'A',
	DELETED = 'D',
}

export enum ForwardType {
	PHONE = 'phone',
}

export interface IForwardTo {
	sequence: string | number;
	type: string;
	value: string;
	rings: number;
	status: ForwardStatus;
}

export interface IForwardToExtended extends IForwardTo {
	user?: IUserLight;
	avatarStr?: string;
	name?: string;
}

/**
 * START
 * We have different sets of rule type if call forward is individual or not
 * For individual: [ringbothseq, sipnoansseq, sipnotregseq, phoneseq]
 * For non individual (common): [sim, sipnoanssim, sipnotregsim, phonesim]
 *
 * So when we switch rings count from 'Individual' to any other like: '2 rings', '10 rings', etc.
 * We not always should update 'rings' property in call forward rules
 * But we always should update 'ruletype' property (for example RuleTypeIndividual.RING_ALL become RuleTypeCommon.RING_ALL)
 * And vice versa
 */
export enum RuleTypeBase {
	OFF = 'noforward',
}

export enum RuleTypeIndividual {
	RING_ALL = 'ringbothseq',
	NO_ANSWER = 'sipnoansseq',
	FAIL_OVER = 'sipnotregseq',
	FORWARD_ONLY = 'phoneseq',
}

export enum RuleTypeCommon {
	RING_ALL = 'sim',
	NO_ANSWER = 'sipnoanssim',
	FAIL_OVER = 'sipnotregsim',
	FORWARD_ONLY = 'phonesim',
}

export type RuleType = RuleTypeBase | RuleTypeIndividual | RuleTypeCommon;

/**
 * END
 */

export interface ICallForwardingSchedule {
	name: string;
	timezone: string;
	rules: IScheduleRule[];
}

export interface ICallForwardingRules {
	callForwardToggle: boolean;
	callScreeningFlag: boolean;
	callerIdFlag: boolean;
	forwardRuleUpdate: boolean;
	forwardTo: IForwardTo[];
	rings: number;
	sequence: string;
	status: string;
	type: string;
	value: string;
	posAck?: boolean;
	ruletype: RuleType;
	sipDeviceRings: number;
	forwardSchedule?: ICallForwardingSchedule;
}

export interface IAllowedCallPickupUser {
	id: number;
	type: 'department' | 'team-member';
}

export interface ICallPickupUsers {
	isActive: boolean;
	allowedFor: IAllowedCallPickupUser[];
}

export interface IPreferredApplicationInfo {
	preferred_app: string;
	should_reload: boolean;
}

export interface IUserLine {
	lineId: string;
	status: string;
}
