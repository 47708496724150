import { Component, Input, OnInit } from '@angular/core';
import { DateFormatterService, FORMATS } from '@app/services';
import { PhoneNumberFormatterPipe } from '@app/pipes';

@Component({
	selector: 'app-phone-number-tooltip',
	templateUrl: './phone-number-tooltip.component.html',
	styleUrls: ['./phone-number-tooltip.component.scss'],
})
export class PhoneNumberTooltipComponent implements OnInit {
	@Input() pendingPhoneNumber: string;
	@Input() temporaryPhoneNumber: string;
	@Input() portingDate: string;
	isPendingToPortWithTemporary: boolean = false;

	constructor(
		private dateFormatter: DateFormatterService,
		private phoneNumberFormatterPipe: PhoneNumberFormatterPipe,
	) {}

	ngOnInit(): void {
		this.isPendingToPortWithTemporary = this.pendingPhoneNumber !== this.temporaryPhoneNumber;
		this.pendingPhoneNumber = this.phoneNumberFormatterPipe.transform(this.pendingPhoneNumber);
		this.temporaryPhoneNumber = this.phoneNumberFormatterPipe.transform(this.temporaryPhoneNumber);
		this.portingDate = this.dateFormatter.formatTz(this.portingDate, FORMATS.DATETIME_MED);
	}
}
