import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/fromEvent';
import { isSameDay } from 'n2p-ui-library/utils/date.util';

import { DateFormatterService, FORMATS } from '@app/services/date-formatter/date-formatter.service';

@Component({
	selector: 'n2p-date-range-picker',
	templateUrl: './date-range-picker.component.html',
	styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, OnDestroy {
	dayNames: Array<String> = [];

	constructor(
		private translate: TranslateService,
		private elementRef: ElementRef,
		private dateFormatter: DateFormatterService,
	) {}

	@Input() selectedStart: any;
	@Input() selectedEnd: any;
	@Input() viewYear = new Date().getFullYear();
	@Input() viewMonth: number = new Date().getMonth();
	@Input() maxEnd: any;
	@Input() maxStart: any;
	@Input() showToggle: boolean;
	@Input() rangeSingleToggle: 'range' | 'single' = 'range';
	@Input() singleDaysSelected: Array<any> = [];
	@Input() onlyOneDate = false;

	@Output() rangeChange = new EventEmitter();

	private listening: boolean;
	private globalClick: Subscription;

	ngOnInit(): void {
		this.dayNames = this.dateFormatter.weekdays;

		this.globalClick = Observable.fromEvent(document, 'click').subscribe((event: MouseEvent) => {
			this.onGlobalClick(event);
		});
	}

	ngOnDestroy(): void {
		this.globalClick.unsubscribe();
	}

	get currentDate(): Date {
		return new Date(this.viewYear, this.viewMonth, 1, 0, 0, 0);
	}

	get previousDate(): Date {
		const dt = new Date(this.currentDate);
		const year = dt.getFullYear();
		const month = dt.getMonth();
		return new Date(month !== 0 ? year : year - 1, month !== 0 ? month - 1 : 11, 1, 0, 0, 0);
	}

	get nextDate(): Date {
		const dt = new Date(this.currentDate);
		const year = dt.getFullYear();
		const month = dt.getMonth();
		return new Date(month !== 11 ? year : year + 1, month !== 11 ? month + 1 : 0, 1, 0, 0, 0);
	}

	get leftMonthName(): string {
		return this.dateFormatter.format(this.previousDate, FORMATS.MONTH_OF_YEAR_MED);
	}

	get rightMonthName(): string {
		return this.dateFormatter.format(this.currentDate, FORMATS.MONTH_OF_YEAR_MED);
	}

	get monthLeft(): Array<any> {
		return this.dayList(this.previousDate.getFullYear(), this.previousDate.getMonth());
	}

	get monthRight(): Array<any> {
		return this.dayList();
	}

	get nextMonthAvailable(): boolean {
		const startOfMaxEndMonth = new Date(this.maxEnd.getFullYear(), this.maxEnd.getMonth(), 1, 0, 0, 0);
		return !this.maxEnd || this.currentDate < startOfMaxEndMonth;
	}

	/*
    generate a calendar
  */
	dayList(
		year = this.viewYear,
		month = this.viewMonth,
		selectedStart = this.selectedStart,
		selectedEnd = this.selectedEnd,
	): Array<any> {
		const firstDayOfMonth = new Date(year, month, 1, 0, 0, 0, 0);
		let viewMonth = month,
			days = [[], [], [], [], [], []];

		for (let i = 0; i < 42; i++) {
			let week = Math.floor(i / 7),
				offset = firstDayOfMonth.getDay(),
				dayObj: any = {};
			const day = new Date(year, viewMonth, i + 1 - offset, 0, 0, 0, 0);
			const start = new Date(selectedStart).setHours(0, 0, 0, 0);
			const end = new Date(selectedEnd).setHours(0, 0, 0, 0);

			if (selectedStart) {
				dayObj.selectedStart = start === day.getTime();
			}

			if (selectedEnd) {
				dayObj.selectedEnd = end === day.getTime();
			}

			if (selectedStart && selectedEnd) {
				dayObj.highlighted = day.getTime() >= start && day.getTime() <= end;
			}

			if (this.maxEnd) {
				const maxEndDate = new Date(this.maxEnd).setHours(0, 0, 0, 0);
				dayObj.disabled = maxEndDate - day.getTime() < 0;
			}

			dayObj = {
				...dayObj,
				number: day.getDate(),
				ref: day,
				inMonth: day.getMonth() === viewMonth,
			};
			days[week].push(dayObj);
		}

		return days;
	}

	/*
    select a new day
  */
	get typeRange(): boolean {
		return this.rangeSingleToggle === 'range';
	}
	get typeSingle(): boolean {
		return this.rangeSingleToggle === 'single';
	}

	/*
    differ function for rendered days and weeks
  */
	trackByIndex(index: number): number {
		return index;
	}

	selectDay(day: any): void {
		if (!this.maxEnd || day < this.maxEnd) {
			this.typeRange ? this.selectRangeDay(day) : this.selectSingleDay(day);
		}
	}

	nextMonth(): void {
		// dont view past the max date
		if (!this.nextMonthAvailable) {
			return;
		}

		const nextDate = this.nextDate;
		this.viewMonth = nextDate.getMonth();
		this.viewYear = nextDate.getFullYear();
	}

	prevMonth(): void {
		const previousDate = this.previousDate;

		this.viewMonth = previousDate.getMonth();
		this.viewYear = previousDate.getFullYear();
	}

	dayIsSelected(day): boolean {
		return this.singleDaysSelected.findIndex(i => isSameDay(new Date(i), new Date(day))) !== -1;
	}

	toggleRangeSingleBtnGroup(selection): void {
		this.rangeSingleToggle = selection;
		this.singleDaysSelected = [];
		this.selectedStart = undefined;
		this.selectedEnd = undefined;
		this.rangeChange.emit([]);
	}

	private selectRangeDay(day): void {
		if (!this.selectedStart) {
			this.selectedStart = day;
			this.selectedEnd = undefined;

			return;
		}

		if (this.selectedEnd) {
			// TODO compare with maxStart
			this.selectedEnd = undefined;
			this.selectedStart = day;

			return;
		}

		// out of acceptable range
		const maxEndDate = new Date(new Date(this.maxEnd).setHours(0, 0, 0, 0));
		if (this.maxEnd && maxEndDate.getTime() - day.getTime() < 0) {
			return;
		}

		// dont allow selected an end before the current start
		if (day.getTime() - this.selectedStart.getTime() < 0) {
			this.selectedEnd = this.selectedStart;
			this.selectedStart = day;
		} else {
			// TODO compare with max end
			this.selectedEnd = day;
		}

		this.rangeChange.emit({
			startDate: this.selectedStart,
			endDate: this.selectedEnd,
		});
	}

	private selectSingleDay(day): void {
		const index = this.singleDaysSelected.findIndex(i => isSameDay(new Date(i), new Date(day)));

		if (this.onlyOneDate) {
			this.singleDaysSelected = index === -1 ? [day] : [];
		} else {
			if (index === -1) {
				this.singleDaysSelected.push(day);
			} else {
				this.singleDaysSelected.splice(index, 1);
			}
		}

		this.rangeChange.emit(this.singleDaysSelected);
	}

	private onGlobalClick(event: MouseEvent): void {
		// required setup since opening click is outside of datepicker
		if (!this.listening) {
			this.listening = true;
			return;
		}

		if (event instanceof MouseEvent && this.listening && !this.elementRef.nativeElement.contains(event.target)) {
			this.rangeChange.emit();
		}
	}
}
