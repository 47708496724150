import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { get } from 'scriptjs';
import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';

import { CommonService, FeatureFlagsService, GlobalEventsService, AuthStorageData, JwtService } from '@app/services';

import { environment } from '@env/environment';
import { LoggerService } from '@app/services/logger/logger.service';
import { getUniteUrl, getAuthUrl } from '@app/Common';

interface IEnvMap {
	qa: string;
	development: string;
	production: string;
	staging: string;
}

@Component({
	selector: 'app-dialer',
	templateUrl: './dialer.component.html',
	styleUrls: ['./dialer.component.scss'],
})
export class DialerComponent implements OnInit, OnDestroy {
	@ViewChild('dialerRef', { static: true }) dialerRef: ElementRef;
	@Input() messengerUserId: string;

	private subscriptions: Subscription[] = [];

	constructor(
		private jwtService: JwtService,
		private featureFlagsService: FeatureFlagsService,
		private cService: CommonService,
		private authStorageDataService: AuthStorageData,
		private globalEventsService: GlobalEventsService,
		private loggerService: LoggerService,
	) {}

	ngOnInit(): void {
		this.load();
	}

	private static EnvMap: IEnvMap = {
		qa: 'Qa',
		development: 'Dev',
		staging: 'Staging',
		production: 'Prod',
	};

	ngOnDestroy(): void {
		window.dialer.stopSIP();
		window.dialer.unmount();
		this.loggerService.info('dialer_removed');
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	private load(): void {
		if (this.isScriptLoaded(environment.web_dialer_url)) {
			this.loggerService.info('dialer_bundle_loaded');
			this.init();
		} else {
			this.loggerService.info('dialer_bundle_loading');
			get(environment.web_dialer_url, () => this.init());
		}
	}

	// TODO is this expensive and we should use the global ready event?
	private isScriptLoaded = (url: string): boolean => {
		const scripts = Array.from(document.querySelectorAll('script'));

		return scripts.some(script => script.src.indexOf(url) >= 0);
	};

	private removeLastSlash = (str: string): string => {
		return str.slice(-1) === '/' ? str.slice(0, -1) : str;
	};

	private init(): void {
		// TODO: Refactor dialerReady logic https://idtjira.atlassian.net/browse/UI-1567
		const formattedEnv = DialerComponent.EnvMap[environment.env];
		const pathName = window.location.pathname;

		if (window.dialer && !pathName.includes('receptionist-console')) {
			// @ts-ignore
			window.dialer.mount(this.dialerRef.nativeElement, {
				env: environment.env,
				token: this.jwtService.accessToken,
				apiConfig: {
					API_MESSENGER: this.removeLastSlash(environment.messenger_api_url),
					API_UNITE: this.removeLastSlash(getUniteUrl(formattedEnv)),
					AUTH_URL: this.removeLastSlash(getAuthUrl(formattedEnv)),
				},
			});
		}
		combineLatest([
			this.featureFlagsService.can3wayCall(),
			this.featureFlagsService.isWarmTransferEnabled(),
			this.globalEventsService.dialerReady,
		]).subscribe(([is3wayEnabled, isWarmTransferEnabled, ready]) => {
			if (ready) {
				setTimeout(() => {
					this.globalEventsService.appendOptions({
						is3wayEnabled,
						isWarmTransferEnabled,
						isScalable: true,
					});

					this.subscriptions.push(
						this.jwtService.accessToken$
							.pipe(filter(Boolean))
							.subscribe((token: string) => this.globalEventsService.updateAccessToken(token)),
					);
				}, 500);
			}
		});
	}
}
