// To properly define this constant, this file should be imported as soon as the app starts, while we still have the query parameter
export const isImpersonated: boolean = (() => {
	try {
		return (
			window.location.href.includes('login_type=impersonate') || window.sessionStorage.getItem('impersonate') === 'true'
		);
	} catch (e) {
		return false;
	}
})();

export const getStorageOutsideOfAngular = (): Storage => {
	return isImpersonated ? window.sessionStorage : window.localStorage;
};
