import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'n2p-dropdown-option',
	templateUrl: './dropdown-option.component.html',
	styleUrls: ['./dropdown-option.component.scss'],
})
export class DropdownOptionComponent implements OnInit {
	@Input() option: { label?: string; value: string; subValue?: string; selected?: boolean };
	@Input() disabled: boolean;
	@Output() optionClicked = new EventEmitter();
	@ViewChild('dropdownText', { static: true }) dropdownText: ElementRef;
	tooltip: string;
	constructor() {}

	clicked(): void {
		this.optionClicked.emit();
	}

	ngOnInit(): void {
		if (!this.option) throw Error('option is required');

		setTimeout(() => {
			if (
				this.dropdownText &&
				this.dropdownText.nativeElement.scrollWidth > this.dropdownText.nativeElement.clientWidth
			) {
				this.tooltip = this.option.subValue || this.option.value;
			}
		});
	}
}
