import { Component, Input } from '@angular/core';

@Component({
	selector: 'n2p-dialog-initials-title',
	templateUrl: './dialog-initials-title.component.html',
	styleUrls: ['./dialog-initials-title.component.scss'],
})
export class DialogInitialsTitleComponent {
	@Input() title: string;
	@Input() iconText: string;
	@Input() circleColor: string;
}
