import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { SCHEDULE_INTERVAL_FORMCONTROL } from '@app/n2p-components/schedule-interval/schedule-interval.injectors';

@Component({
	selector: 'app-calendar-popup',
	templateUrl: './calendar-popup.component.html',
	styleUrls: ['./calendar-popup.component.scss'],
})
export class CalendarPopupComponent implements OnInit {
	daysFormControl: FormControl;
	startDate;
	endDate;
	singleDaysSelected: Array<any> = [];
	rangeSingleToggle: 'range' | 'single' = 'range';
	showToggle: boolean = true;
	constructor(@Inject(SCHEDULE_INTERVAL_FORMCONTROL) formControl: FormControl) {
		this.daysFormControl = formControl;
		if (this.daysFormControl.value.startDate && this.daysFormControl.value.endDate) {
			this.startDate = this.daysFormControl.value.startDate;
			this.endDate = this.daysFormControl.value.endDate;
			this.rangeSingleToggle = 'range';
		} else if (this.daysFormControl.value.length) {
			this.singleDaysSelected = this.daysFormControl.value;
			this.rangeSingleToggle = 'single';
		}
	}

	setDates(dates): void {
		this.daysFormControl.setValue(dates);
	}

	ngOnInit(): void {}
}
