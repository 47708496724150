import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BorderInputComponent } from './border-input.component';

@NgModule({
	imports: [CommonModule],
	declarations: [BorderInputComponent],
	exports: [BorderInputComponent],
})
export class BorderInputModule {}
