import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Component width is inherited so it can be styled if it is given a css class with a width property
 * e.g: html: <shared-inputfilter class="some-class" ></shared-inputfilter>      css:  .some-class{ width: 100px; }
 */
@Component({
	selector: 'shared-inputfilter',
	templateUrl: './inputfilter.component.html',
	styleUrls: ['./inputfilter.component.scss'],
})
export class InputFilterComponent {
	@Input() placeholder: string;
	@Input() inProgress: boolean;
	@Output() valueChanged: EventEmitter<string> = new EventEmitter();
	@Output() onEnter: EventEmitter<string> = new EventEmitter();

	inputText: string;
	searching: boolean;

	constructor(private translate: TranslateService) {
		this.translate.stream('INPUTFILTER.SEARCH').subscribe((res: string) => {
			this.placeholder = res;
		});
	}

	handleChange(event: Event): void {
		this.inputText = (<HTMLInputElement>event.target).value;
		this.valueChanged.emit(this.inputText);
	}

	onKeydown(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			this.onEnter.emit((event.target as HTMLInputElement).value);
		}
	}
}
