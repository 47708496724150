import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'dateStringFormatter',
})
export class DateStringFormatterPipe implements PipeTransform {
	transform(seconds: number): string {
		let timeString = '';
		let plural = '';

		const times = {
			Year: 31557600,
			Month: 2629746,
			Day: 86400,
			Hour: 3600,
			Min: 60,
			Sec: 1,
		};

		Object.keys(times).forEach(key => {
			const count = times[key];
			const value = Math.floor(seconds / count);

			if (value > 0) {
				plural = value > 1 ? 's' : '';

				timeString += `${value} ${key}${plural} `;
				seconds = seconds - count * value;
			}
		});

		// remove trailing space
		timeString = timeString.slice(0, -1);

		return timeString;
	}
}
