import { Observable } from 'rxjs/Observable';
import { shareReplay, tap } from 'rxjs/operators';

interface IShareableCache {
	get: (key: string, observable: Observable<unknown>) => Observable<unknown>;
	remove: (key: string) => boolean;
}

const cacheFactory = (): IShareableCache => {
	const cache: Map<any, Observable<unknown>> = new Map();
	return {
		get: (key: string, observable: Observable<unknown>): Observable<unknown> => {
			const res = cache.get(key);
			if (!res) {
				cache.set(key, observable);
				return observable;
			}
			return res;
		},
		remove: (key: string): boolean => cache.delete(key),
	};
};

export function Cacheable(cacheKeyResolver: (args: any[]) => any = (args): any => args[0]): MethodDecorator {
	return function<T>(target: any, propertyKey: string, descriptor: PropertyDescriptor): void {
		const cache = cacheFactory();
		const method = descriptor.value as (...args: any) => Observable<T>;
		descriptor.value = function(...args: any): Observable<T> {
			const cacheKey = cacheKeyResolver(args);
			return cache.get(
				cacheKey,
				method.apply(this, args).pipe(
					shareReplay(1),
					tap({
						error: (): any => cache.remove(cacheKey),
					}),
				),
			) as Observable<T>;
		};
	};
}
