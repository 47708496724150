import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconComponent } from '@n2p/icon/icon.component';
import { SvgModule } from '@n2p/svg';

@NgModule({
	imports: [CommonModule, SvgModule],
	declarations: [IconComponent],
	exports: [IconComponent, SvgModule],
})
export class IconModule {}
