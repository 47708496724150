import { ControlValueAccessor } from '@angular/forms';
import { Input } from '@angular/core';

export class AbstractControlValueAccessor implements ControlValueAccessor {
	onChange: any = () => {};
	onTouched: any = () => {};
	@Input('value') _value;
	@Input('errors') _errors;

	get value() {
		return this._value;
	}

	set value(val) {
		this._value = val;
		this.onChange(val);
		this.onTouched();
	}

	get errors() {
		return this._errors;
	}

	set errors(err) {
		this._errors = err;
	}

	registerOnChange(fn) {
		this.onChange = fn;
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

	writeValue(value) {
		if (value) {
			this.value = value;
		}
	}
}
