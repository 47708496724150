import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';

@Component({
	selector: 'shared-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
	pfocus: boolean;
	showPassword: boolean = true;
	passwordMaxLength: number = 10;
	control: FormControl = new FormControl('', [Validators.required, Validators.pattern(/^\d{4,10}$/)]);

	constructor(@Inject(DIALOG_DATA) private passwordOptions: any, @Inject(DIALOG_REF) private dialogRef: DialogRef) {}

	close(): void {
		this.dialogRef.dismiss({ cancelled: true });
	}

	submit(): void {
		if (this.control.valid) {
			this.dialogRef.dismiss({ cancelled: false, newPassword: this.control.value });
		}
	}
}
