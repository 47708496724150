import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { ApiService } from '@app/services';
import {
	IAccountContact,
	IAccountContactMethod,
	AccountContactPartial,
	IAccountContactType,
} from '@app/services/web-apis/contacts/api-contacts.domain';

@Injectable({
	providedIn: 'root',
})
export class ApiContactsService {
	private baseUrl: string = '/accounts/{accountId}/contacts';

	constructor(private apiService: ApiService) {}

	getContacts(contactMethod?: IAccountContactMethod, contactType?: IAccountContactType): Observable<IAccountContact[]> {
		const query = { contactMethod, contactType };

		return this.apiService.get<IRegularApiResponse<IAccountContact[]>>(this.baseUrl, query).pipe(
			map(res => {
				if (res.hasError) {
					throw new Error();
				}

				return res.data;
			}),
		);
	}

	updateContact(contacts: AccountContactPartial[]): Observable<IRegularApiResponse<IAccountContact[]>> {
		return this.apiService.put(this.baseUrl, contacts);
	}

	createContact(contact: AccountContactPartial[]): Observable<IRegularApiResponse<IAccountContact[]>> {
		return this.apiService.post('/accounts/{accountId}/contacts', contact);
	}
}
