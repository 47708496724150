import { Pipe, PipeTransform } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CurrencyFormatter } from 'n2p-ui-library/services/currency/currency.service';

import { ApiAccountsService, ApiUsersService } from '@app/services';

@Pipe({
	name: 'currencyFormatterPipe',
	pure: false,
})
export class CurrencyFormatterPipe implements PipeTransform {
	private formatter: CurrencyFormatter = new CurrencyFormatter('en-US', 'USD');
	private lastNumber: number | string;
	private lastResult: number | string;
	private lastLocale: string;
	private lastCurrency: string;
	private subscription: Subscription = new Subscription();

	constructor(private accountService: ApiAccountsService, private usersService: ApiUsersService) {
		this.subscription = combineLatest([
			this.accountService.account$.pipe(filter(v => !!v)),
			this.usersService.user$.pipe(
				filter(v => !!v),
				map(usr => usr.uiLanguageCode),
			),
		]).subscribe(([{ country, currency }, languageCode]) => {
			const locale = `${languageCode}-${country}`;

			this.lastLocale = null;
			this.lastCurrency = null;

			this.formatter = new CurrencyFormatter(locale, currency);
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public transform(number: number = 0, customLocale?: string, customCurrency?: string): number | string {
		const effectiveLocale = customLocale || this.formatter.locale;
		const effectiveCurrency = customCurrency || this.formatter.currency;

		if (number !== this.lastNumber || effectiveLocale !== this.lastLocale || effectiveCurrency !== this.lastCurrency) {
			this.lastNumber = number;
			this.lastResult = this.formatter.format(number, customLocale, customCurrency);

			this.lastLocale = effectiveLocale;
			this.lastCurrency = effectiveCurrency;
		}

		return this.lastResult;
	}
}
