import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	/* tslint:disable */
	selector: 'n2p-form-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	encapsulation: ViewEncapsulation.None,
	/* tslint:enable */
})
export class InputComponent implements OnInit {
	private inputElement: HTMLElement;
	private _disabled: boolean;
	private _hasError: boolean;

	@Input() set disabled(isIt) {
		this._disabled = isIt;
		this.updateDisabled();
	}

	get disabled(): boolean {
		return this._disabled;
	}

	@Input() set hasError(hasIt) {
		this._hasError = hasIt;
	}

	get hasError(): boolean {
		return this._hasError;
	}

	ngOnInit(): void {
		this.inputElement = document.querySelector('input[n2p-line-input], input[n2p-border-input]');
		this.updateDisabled();
	}

	private updateDisabled(): void {
		if (this.inputElement && this._disabled) {
			this.inputElement.setAttribute('disabled', 'true');
		} else if (this.inputElement && !this._disabled) {
			this.inputElement.removeAttribute('disabled');
		}
	}
}
