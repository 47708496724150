import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PhoneNumberComponent } from './phone-number.component';
import { OnlyNumberDirective } from './only-number.directive';
import { CountryPipe } from './country.pipe';
import { DirectivesModule } from '@app/directives/directives.module';

@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule, DirectivesModule],
	declarations: [PhoneNumberComponent, OnlyNumberDirective, CountryPipe],
	exports: [PhoneNumberComponent, CountryPipe],
})
export class InternationalPhoneNumberModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: InternationalPhoneNumberModule,
		};
	}
}
