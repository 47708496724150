import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractControlValueAccessor } from '@app/Common/classes/AbstractControlValueAccessor';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckboxComponent),
			multi: true,
		},
	],
})
export class CheckboxComponent extends AbstractControlValueAccessor implements OnInit {
	id = 'n2p-checkbox-';
	@Input() label: string;
	@Input('isDisabled') disabled = false;
	constructor() {
		super();
	}

	@Input() set checked(value) {
		this.value = value;
	}

	clicked() {
		this.value = !this.value;
	}

	ngOnInit() {
		this.id += document.querySelectorAll('*[id*=n2p-checkbox-]').length;
		this.checked = this.value;
	}
}
