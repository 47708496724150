import { Pipe, PipeTransform } from '@angular/core';

import { DateFormatterService } from '@app/services';

@Pipe({
	name: 'dayOfWeek',
})
export class DayOfWeekPipe implements PipeTransform {
	constructor(private formatter: DateFormatterService) {}

	transform(num: any, args?: any): any {
		return this.formatter.weekdays[num - 1];
	}
}
