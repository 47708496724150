import { environment } from '@env/environment';

// window event keys
export const N2P_EVENT = 'n2p_event';
export const DISPATCHER = 'webapp';
// storage event keys
export enum STORAGE {
	EVENT = 'storage',
	KEY = 'n2p',
}

export enum EVENTS {
	LOGIN = 'login',
	LOGOUT = 'logout',
	APPEND_OPTIONS = 'append_options',
	APPEND_COUNTRY = 'append_country',
	DIALER_READY = 'ready',
	DIALER = 'dialer_event',
	BLOCK_NUMBER = 'block_number',
	UNBLOCK_NUMBER = 'unblock_number',
	REFRESH_SESSION = 'refresh_session',
	SESSION_EXPIRED = 'session_expired',
	NOT_ENOUGH_PERMISSIONS = 'not_enough_permissions',
	DIALER_IS_CONNECTED = 'dialer_is_connected',
	LANGUAGE_CHANGE = 'language_change',
	RCON_READY = 'rcon_ready',
	MESSENGER_BLOCK_OUTBOUND_NUMBERS = 'messenger_block_outbound_numbers',
	WEB_MESSENGER_READY = 'web_messenger_ready',
	WEB_MESSENGER_RELOAD_REQUESTED = 'messenger_reload_requested',
	UPDATE_ACESS_TOKEN = 'update_access_token',
	UPDATE_URL_TO_EXTERNAL = 'update_url_to_external',
	UPDATE_URL_FROM_EXTERNAL = 'update_url_from_external',
}

export enum DIALER_EVENTS {
	CALL = 'create_call',
}

export const DIALER_CONFIG: any = {
	mode: 'standard',
	mountClass: 'n2p-web-dialer',
};

export interface N2P_STORAGE {
	rconOpen: boolean;
}

export interface N2P_STORAGE_EVENT {
	new: N2P_STORAGE;
	old: N2P_STORAGE;
}

export const DEFAULT_N2P_STORAGE: N2P_STORAGE = {
	rconOpen: false,
};
