import { IGAEvent, IGAEventCategory } from './google-analytics.domain';

export const SET_CUSTOM_FIELDS: string = 'set_custom_fields';

// top navigation
export const TOP_NAVIGATION_HUDDLE: IGAEvent = {
	category: IGAEventCategory.TOP_NAVIGATION,
	name: 'top_navigation_click',
	label: 'huddle',
};

export const TOP_NAVIGATION_RCON: IGAEvent = {
	category: IGAEventCategory.TOP_NAVIGATION,
	name: 'top_navigation_click',
	label: 'rcon',
};

export const TOP_NAVIGATION_WALLBOARD: IGAEvent = {
	category: IGAEventCategory.TOP_NAVIGATION,
	name: 'top_navigation_click',
	label: 'wallboard',
};

export const TOP_NAVIGATION_WEB_DIALER: IGAEvent = {
	category: IGAEventCategory.TOP_NAVIGATION,
	name: 'top_navigation_click',
	label: 'web_dialer',
};

export const TOP_NAVIGATION_VOICEMAIL: IGAEvent = {
	category: IGAEventCategory.TOP_NAVIGATION,
	name: 'top_navigation_click',
	label: 'voicemail',
};

export const TOP_NAVIGATION_HELP: IGAEvent = {
	category: IGAEventCategory.TOP_NAVIGATION,
	name: 'top_navigation_click',
	label: 'help',
};

export const TOP_NAVIGATION_USER_PROFILE: IGAEvent = {
	category: IGAEventCategory.TOP_NAVIGATION,
	name: 'top_navigation_click',
	label: 'user_profile',
};

// analytics
export const GA_ANALYTICS_REFRESH_INTERVAL: IGAEvent = {
	category: IGAEventCategory.ANALYTICS,
	name: 'analytics_refresh_interval',
	label: 'Refresh interval',
};
export const GA_ANALYTICS_REPORT_TYPE: IGAEvent = {
	category: IGAEventCategory.ANALYTICS,
	name: 'analytics_report_type',
	label: 'Report type',
};

// downloads
export const GA_DOWNLOADS_CALL_HISTORY: IGAEvent = {
	category: IGAEventCategory.DOWNLOADS,
	name: 'download_call_history',
	label: 'Call history type',
};

// team members
export const GA_TEAM_MEMBERS_ADD: IGAEvent = {
	category: IGAEventCategory.TEAM_MEMBERS,
	name: 'team_member_add',
};
export const GA_TEAM_MEMBERS_EDIT: IGAEvent = {
	category: IGAEventCategory.TEAM_MEMBERS,
	name: 'team_member_edit',
};
export const GA_TEAM_MEMBERS_DELETE: IGAEvent = {
	category: IGAEventCategory.TEAM_MEMBERS,
	name: 'team_member_delete',
};

// departments
export const GA_DEPARTMENTS_ADD: IGAEvent = {
	category: IGAEventCategory.DEPARTMENTS,
	name: 'department_add',
};
export const GA_DEPARTMENTS_EDIT: IGAEvent = {
	category: IGAEventCategory.DEPARTMENTS,
	name: 'department_edit',
};
export const GA_DEPARTMENTS_DELETE: IGAEvent = {
	category: IGAEventCategory.DEPARTMENTS,
	name: 'department_delete',
};

// welcome_menus
export const GA_WELCOME_MENUS_ADD: IGAEvent = {
	category: IGAEventCategory.WELCOME_MENUS,
	name: 'welcome_menu_add',
};
export const GA_WELCOME_MENUS_EDIT: IGAEvent = {
	category: IGAEventCategory.WELCOME_MENUS,
	name: 'welcome_menu_edit',
};
export const GA_WELCOME_MENUS_DELETE: IGAEvent = {
	category: IGAEventCategory.WELCOME_MENUS,
	name: 'welcome_menu_delete',
};

// ring_groups
export const GA_RING_GROUPS_ADD: IGAEvent = {
	category: IGAEventCategory.RING_GROUPS,
	name: 'ring_group_add',
};
export const GA_RING_GROUPS_EDIT: IGAEvent = {
	category: IGAEventCategory.RING_GROUPS,
	name: 'ring_group_edit',
};
export const GA_RING_GROUPS_DELETE: IGAEvent = {
	category: IGAEventCategory.RING_GROUPS,
	name: 'ring_group_delete',
};

// authentication
export const GA_AUTHENTICATION_RESET_PASSWORD: IGAEvent = {
	category: IGAEventCategory.AUTHENTICATION,
	name: 'password_reset',
	label: 'Password length',
};
export const GA_AUTHENTICATION_SET_NEW_PASSWORD: IGAEvent = {
	category: IGAEventCategory.AUTHENTICATION,
	name: 'password_set_new',
	label: 'Password length',
};
export const GA_AUTHENTICATION_SEND_RESET_EMAIL: IGAEvent = {
	category: IGAEventCategory.AUTHENTICATION,
	name: 'password_send_reset_email',
};

// bulk actions - download & delete
export const GA_DOWNLOADS_BULK_DOWNLOAD: IGAEvent = {
	category: IGAEventCategory.DOWNLOADS,
	name: 'bulk_download',
	label: 'Download type',
};
export const GA_DOWNLOADS_BULK_DELETE: IGAEvent = {
	category: IGAEventCategory.DOWNLOADS,
	name: 'bulk_delete',
	label: 'Delete type',
};

// TODO implement
// downloads
export const GA_DOWNLOADS_RECORDINGS: IGAEvent = {
	category: IGAEventCategory.DOWNLOADS,
	name: 'download_call_recordings',
};
export const GA_DOWNLOADS_VOICEMAILS: IGAEvent = {
	category: IGAEventCategory.DOWNLOADS,
	name: 'download_voicemails',
};

// SAML Settings
export const GA_SAML_ENABLE: IGAEvent = {
	category: IGAEventCategory.SAML,
	name: 'saml_enable',
};

export const GA_SAML_DISABLE: IGAEvent = {
	category: IGAEventCategory.SAML,
	name: 'saml_disable',
};

export const GA_SAML_SUBMIT: IGAEvent = {
	category: IGAEventCategory.SAML,
	name: 'saml_submit',
};

// licenses
export const GA_LICENSE_PROVIDED: IGAEvent = {
	category: IGAEventCategory.LICENSES,
	name: 'licenses_provided',
};

export const GA_LICENSE_REVOKED: IGAEvent = {
	category: IGAEventCategory.LICENSES,
	name: 'licenses_revoked',
};

// Call History
export const GA_CALL_HISTORY_NUMBER_CLICK: IGAEvent = {
	category: IGAEventCategory.CALL_HISTORY,
	name: 'call_history_number_click',
};

export const GA_CALL_HISTORY_NUMBER_COPY: IGAEvent = {
	category: IGAEventCategory.CALL_HISTORY,
	name: 'call_history_number_copy',
};

export const GA_CALL_HISTORY_FILTER_BY_TM: IGAEvent = {
	category: IGAEventCategory.CALL_HISTORY,
	name: 'filter_by_TM',
};

// Profile
export const GA_PROFILE_PICKUP_CALL_TO_USER: IGAEvent = {
	category: IGAEventCategory.RESTRICT_CALL_PICKUP,
	name: 'restrcit_to_user',
};
export const GA_PROFILE_PICKUP_CALL_TO_DEPT: IGAEvent = {
	category: IGAEventCategory.RESTRICT_CALL_PICKUP,
	name: 'resrict_to_dept',
};
export const GA_PROFILE_PICKUP_ALLOW_ANYONE: IGAEvent = {
	category: IGAEventCategory.RESTRICT_CALL_PICKUP,
	name: 'allow_anyone',
};

// Virtual fax
export const GA_VIRTUAL_FAX_ADD: IGAEvent = {
	category: IGAEventCategory.VIRTUAL_FAX,
	name: 'virtual_fax_add',
};
export const GA_VIRTUAL_FAX_EDIT: IGAEvent = {
	category: IGAEventCategory.VIRTUAL_FAX,
	name: 'virtual_fax_edit',
};
export const GA_VIRTUAL_FAX_DELETE: IGAEvent = {
	category: IGAEventCategory.VIRTUAL_FAX,
	name: 'virtual_fax_delete',
};

// Delegate
export const GA_DELEGATE_ADD: IGAEvent = {
	category: IGAEventCategory.DELEGATE,
	name: 'delegate_add',
};
export const GA_DELEGATE_EDIT: IGAEvent = {
	category: IGAEventCategory.DELEGATE,
	name: 'delegate_edit',
};
export const GA_DELEGATE_DELETE: IGAEvent = {
	category: IGAEventCategory.DELEGATE,
	name: 'delegate_delete',
};
export const GA_DELEGATE_RESEND_INVITATION: IGAEvent = {
	category: IGAEventCategory.DELEGATE,
	name: 'delegate_resend_invitation',
};
export const GA_DELEGATE_ACCEPT_REQUEST: IGAEvent = {
	category: IGAEventCategory.DELEGATE,
	name: 'delegate_accept_request',
};

// Webhooks
export const GA_PLAY_VOICE_MAIL: IGAEvent = {
	category: IGAEventCategory.WEBHOOKS,
	name: 'play_vm',
};

export const GA_PLAY_CALL_RECORDING: IGAEvent = {
	category: IGAEventCategory.WEBHOOKS,
	name: 'play_cr',
};
