import { ComponentPortal, DomPortalOutlet, PortalInjector } from '@angular/cdk/portal';
import {
	ApplicationRef,
	ComponentFactoryResolver,
	Directive,
	ElementRef,
	Injector,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

import { InputFieldErrorEmptyComponent } from '@n2p/input/input-field-errors/input-field-error-empty/input-field-error-empty.component';
import { InputFieldErrorInUseComponent } from '@n2p/input/input-field-errors/input-field-error-in-use/input-field-error-in-use.component';
import { InputFieldErrorMaxLengthComponent } from '@n2p/input/input-field-errors/input-field-error-max-length/input-field-error-max-length.component';
import { InputFieldErrorRequiredComponent } from '@n2p/input/input-field-errors/input-field-error-required/input-field-error-required.component';
import { INPUT_ERROR_MESSAGE, INPUT_ERROR_PROP_NAME, INPUT_MAX_LENGTH } from '@n2p/input/input.injectors';

@Directive({
	selector: '[n2pInputFieldErrors]',
})
export class InputFieldErrorsDirective implements OnInit, OnDestroy {
	@Input('n2pInputFieldErrors') propName: string;
	@Input('requiredMessage') requiredMessage: string;
	@Input('inUseMessage') inUseMessage: string;
	@Input('inUseMessage') emptyMessage: string;
	@Input('maxLengthMessage') maxLengthMessage: string;

	portal: ComponentPortal<InputFieldErrorRequiredComponent>;
	subscriptions: Array<Subscription> = [];
	constructor(
		private control: NgControl,
		private el: ElementRef,
		private _componentFactoryResolver: ComponentFactoryResolver,
		private _appRef: ApplicationRef,
		private injector: Injector,
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.control.control.statusChanges.subscribe(status => {
				if (status === 'INVALID') {
					if (!this.control.control.errors) return;
					this.appendError();
				} else if (this.portal) this.detach();
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	private appendError(): void {
		if (this.portal) this.detach();
		const domPortalOutlet = new DomPortalOutlet(
			this.el.nativeElement.parentNode,
			this._componentFactoryResolver,
			this._appRef,
			undefined,
		);

		if (this.control.control.errors.required) {
			this.portal = new ComponentPortal(
				InputFieldErrorRequiredComponent,
				undefined,
				this.createInjector(this.requiredMessage),
			);
		} else if (this.control.control.errors.maxlength) {
			this.portal = new ComponentPortal(
				InputFieldErrorMaxLengthComponent,
				undefined,
				this.createMaxLengthInjector(this.maxLengthMessage),
			);
		} else if (this.control.control.errors.inUse) {
			this.portal = new ComponentPortal(
				InputFieldErrorInUseComponent,
				undefined,
				this.createInjector(this.inUseMessage),
			);
		} else if (this.control.control.errors.empty) {
			this.portal = new ComponentPortal(
				InputFieldErrorEmptyComponent,
				undefined,
				this.createInjector(this.emptyMessage),
			);
		}
		if (this.portal) {
			this.portal.attach(domPortalOutlet);
		}
	}

	private detach(): void {
		this.portal.detach();
		this.portal = undefined;
	}

	private createInjector(errorMessage: string): any {
		const injectorTokens = new WeakMap();
		injectorTokens.set(INPUT_ERROR_PROP_NAME, this.propName || '');
		injectorTokens.set(INPUT_ERROR_MESSAGE, errorMessage || '');

		return new PortalInjector(this.injector, injectorTokens);
	}

	private createMaxLengthInjector(errorMessage: string): any {
		const injectorTokens = new WeakMap();
		injectorTokens.set(INPUT_ERROR_PROP_NAME, this.propName || '');
		injectorTokens.set(INPUT_MAX_LENGTH, this.control.control.errors.maxlength.requiredLength);
		injectorTokens.set(INPUT_ERROR_MESSAGE, errorMessage || '');

		return new PortalInjector(this.injector, injectorTokens);
	}
}
