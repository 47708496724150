import { TranslateModule } from '@ngx-translate/core';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CallRecordingToggleComponent } from './call-recording-toggle.component';

@NgModule({
	imports: [TranslateModule, FormsModule],
	declarations: [CallRecordingToggleComponent],
	exports: [CallRecordingToggleComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CallRecordingToggleModule {}
