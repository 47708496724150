import { OverlayRef } from '@angular/cdk/overlay';

export enum Decision {
	ACCEPT = 'ACCEPT',
	CANCEL = 'CANCEL',
}

export class PromptRef {
	resolve: any;
	promise: any;
	private overlayRef: OverlayRef;
	constructor(ref: OverlayRef) {
		this.overlayRef = ref;
		this.promise = new Promise(resolve => {
			this.resolve = resolve;
		});
	}

	onDismiss(): Promise<any> {
		return this.promise;
	}

	accept() {
		this.resolve(Decision.ACCEPT);
		this.overlayRef.dispose();
	}

	cancel() {
		this.resolve(Decision.CANCEL);
		this.overlayRef.dispose();
	}
}
