import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Component, ElementRef, HostBinding, Inject, Injector, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import {
	DROPDOWN_DATA,
	DROPDOWN_DATA_COMPONENT,
	DROPDOWN_DATA_CONFIG,
	DROPDOWN_DATA_FILTER,
	DROPDOWN_DATA_HEIGHT,
	DROPDOWN_PAGE,
	DROPDOWN_PAGE_NEXT,
	DROPDOWN_PAGE_PREV,
} from '@n2p/dropdown/dropdown.injectors';

@Component({
	selector: 'n2p-dropdown-data',
	templateUrl: './dropdown-data.component.html',
	styleUrls: ['./dropdown-data.component.scss'],
})
export class DropdownDataComponent implements OnInit {
	dataComponentPortal: any;
	dataFormControl: FormControl;
	page = 0;
	pageObservable = new BehaviorSubject(this.page);
	showSelectedItems: boolean;
	dropdownConfig: { singleSelection: boolean; showSelectedSection: boolean; overwriteSelectedSection: boolean };
	constructor(
		@Inject(DROPDOWN_DATA) private formControl: FormControl,
		@Inject(DROPDOWN_DATA_FILTER) private search: FormControl,
		@Inject(DROPDOWN_DATA_COMPONENT) private dataComponent: any,
		@Inject(DROPDOWN_DATA_CONFIG) private config,
		@Inject(DROPDOWN_DATA_HEIGHT) private dropdownDataHeight: number,
		private injector: Injector,
		private el: ElementRef,
	) {
		this.el.nativeElement.style.maxHeight = this.dropdownDataHeight + 'px';
		this.dataComponentPortal = new ComponentPortal(this.dataComponent, undefined, this.createInjector());
		this.dataFormControl = this.formControl;
		this.dropdownConfig = this.config;
	}

	get selectedItems(): Array<any> {
		if (this.dataFormControl.value) {
			return this.dataFormControl.value.filter(i => i.selected);
		}
	}

	showSelected(): void {
		if (this.selectedItems.length === 1) return;
		if (!this.dropdownConfig.overwriteSelectedSection) {
			this.showSelectedItems = true;
		}
		this.nextPage();
	}

	nextPage(): void {
		this.page++;
		this.pageObservable.next(this.page);
	}

	previousPage(): void {
		this.page--;
		if (this.page === 0) this.showSelectedItems = false;
		this.pageObservable.next(this.page);
	}

	removeSelectedItem(item): void {
		if (this.config.isRequired && this.selectedItems.length === 1) return;
		item.selected = false;
		this.dataFormControl.updateValueAndValidity();
	}

	ngOnInit(): void {}

	// https://idtjira.atlassian.net/browse/UI-3402
	@HostBinding('class.n2p-dropdown-data-wrapper') n2pDropdownDataWrapper: boolean = true;

	private createInjector(): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(DROPDOWN_DATA, this.formControl);
		injectorTokens.set(DROPDOWN_DATA_FILTER, this.search);
		injectorTokens.set(DROPDOWN_PAGE, this.pageObservable.asObservable());
		injectorTokens.set(DROPDOWN_PAGE_NEXT, this.nextPage.bind(this));
		injectorTokens.set(DROPDOWN_PAGE_PREV, this.previousPage.bind(this));
		injectorTokens.set(DROPDOWN_DATA_CONFIG, this.dropdownConfig);

		return new PortalInjector(this.injector, injectorTokens);
	}
}
