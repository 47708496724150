import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CallOptionsService } from '@app/pages/teammembers/call-options/call-options.service';
import { ICallForwardingRules, IUser, RuleType, RuleTypeBase } from '@app/services/web-apis/users/api-users.domain';
import { DIALOG_REF } from '@n2p/dialog/dialog.injectors';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { FeatureFlagsService, SnackbarService, LicensesService } from '@app/services';
import { IScheduleRule } from '@app/services/web-apis/schedules/api-schedules.domain';

@Component({
	selector: 'n2p-call-forwarding',
	templateUrl: './call-forwarding.component.html',
	styleUrls: ['./call-forwarding.component.scss', '../call-options.shared.scss'],
})
export class CallForwardingComponent implements OnInit, OnDestroy {
	@Input() user: IUser;
	@Input() loading: boolean = false;

	pristine: boolean = true;

	ruleType: RuleType = RuleTypeBase.OFF;
	maxRings: number = this.callOptionsService.maxRings;
	isRulesLoaded: boolean = false;
	isSaving: boolean = false;

	public callForwardingRules: ICallForwardingRules;
	public scheduleRules: IScheduleRule[] | null = null;

	public callQueuesNoteShown$: Observable<boolean> = combineLatest([
		this.licensesService.getLicensesWithPrivate(),
		this.callOptionsService.noForwardNumbers$,
	]).pipe(
		map(([licenses, noForwardNumbers]) => {
			const isCallQueuesLicenseEnabled = licenses.some(license => license.licenseCode === 'customer.callqueue');
			return isCallQueuesLicenseEnabled && !noForwardNumbers;
		}),
	);

	private subs: Subscription[] = [];

	constructor(
		@Inject(DIALOG_REF) public dialogRef: DialogRef,
		public callOptionsService: CallOptionsService,
		private featureFlagsService: FeatureFlagsService,
		private snackbarService: SnackbarService,
		private licensesService: LicensesService,
	) {}

	ngOnInit(): void {
		this.fetchData();
		this.subscribe();
	}

	ngOnDestroy(): void {
		this.subs.forEach(s => s.unsubscribe());
	}

	get isLoaded(): boolean {
		return !this.loading && this.isRulesLoaded;
	}

	get isScheduleAvailable(): Observable<boolean> {
		return this.featureFlagsService.callForwardingSchedule();
	}

	get isSaveButtonDisabled(): boolean {
		return !this.isValid || this.pristine;
	}

	get isValid(): boolean {
		const callForwarding = this.callOptionsService.callForwardingRulesNext;
		if (callForwarding) {
			const schedule = callForwarding.forwardSchedule && callForwarding.forwardSchedule.rules;
			return (
				!schedule ||
				(schedule &&
					schedule.length > 0 &&
					schedule.every(scheduleRule => {
						const weekDays = scheduleRule.days.weekDays || [];
						const dates = scheduleRule.days.dates || [];
						const time = scheduleRule.time;
						return Boolean(
							scheduleRule.name && (weekDays.length > 0 || dates.length > 0) && time && time.start && time.end,
						);
					}))
			);
		}
		return false;
	}

	save(): void {
		this.isSaving = true;
		this.callOptionsService.commitCallForwardingRules().subscribe({
			next: (): void => {
				this.dialogRef.dismiss();
				this.isSaving = false;
			},
			error: (err: Error): void => {
				this.snackbarService.createDanger(err.message);
				this.isSaving = false;
			},
		});
	}

	changeOptionServiceValue<T extends Array<any>>(method: string, ...args: T): void {
		this.callOptionsService[method](...args);
		this.pristine = false;
	}

	private fetchData(): void {
		this.isRulesLoaded = false;
		forkJoin([
			this.callOptionsService.fetchCallForwardingRules(),
			this.callOptionsService.fetchAccountPolicy(),
			this.callOptionsService.fetchUsers(),
		]).subscribe({
			complete: (): boolean => (this.isRulesLoaded = true),
		});
	}

	private subscribe(): void {
		this.subs = [
			this.callOptionsService.ruleType$.subscribe(ruleType => {
				this.ruleType = ruleType;
			}),

			this.callOptionsService.maxRings$.subscribe(maxRings => {
				this.maxRings = maxRings;
			}),

			this.callOptionsService.callForwardingRules$
				.pipe(filter(rules => !!rules))
				.subscribe((rules: ICallForwardingRules) => {
					this.scheduleRules = rules.forwardSchedule ? rules.forwardSchedule.rules || [] : null;
				}),
		];
	}
}
