import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/services/api/api.service';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { DEFAULT_PUBLIC_FEATURE_FLAGS, IPublicFeatureFlags, IPublicFeatureFlagsResponse } from './features.domain';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class PublicFeatureFlagsService {
	constructor(private apiService: ApiService) {}

	private fetchFeatureFlagsFromAPI(): Observable<IPublicFeatureFlags> {
		return this.apiService.get<IRegularApiResponse<IPublicFeatureFlagsResponse>>(`/features/public`).pipe(
			map(res => {
				if (!res.data || res.data.Items.length <= 0) {
					return DEFAULT_PUBLIC_FEATURE_FLAGS;
				}

				return res.data.Items.reduce((acc: IPublicFeatureFlags, curr) => {
					acc[curr.Name] = curr.Flag;

					return acc;
				}, {} as IPublicFeatureFlags);
			}),
			// Adjust staging environment flags
			map(flags => {
				if (flags.hasOwnProperty('UseStagingIdentityServer')) {
					if (environment.env === 'staging') {
						flags['UseIdentityServer'] = flags['UseStagingIdentityServer'];
						flags['UseSSOLoginPage'] = flags['UseStagingSSOLoginPage'];
					}

					delete flags['UseStagingIdentityServer'];
					delete flags['UseStagingSSOLoginPage'];
				}

				return flags;
			}),
		);
	}
}
