import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AddScheduleComponent } from '@app/pages/schedules/add-schedule/add-schedule.component';
import { DeleteScheduleComponent } from '@app/pages/schedules/delete-schedule/delete-schedule.component';
import { EditScheduleComponent } from '@app/pages/schedules/edit-schedule/edit-schedule.component';
import { DialogService } from '@n2p/dialog/dialog.service';
import { PromptService } from '@n2p/prompt/prompt.service';
import { ScheduleService } from './schedules.service';
import { ApiScheduleService, ApiTimezoneService, CommonService, SnackbarService } from '@app/services';
import { ISchedule } from '@app/services/web-apis/schedules/api-schedules.domain';
import { Decision } from '@app/shared/classes/PromptRef';
import { DateFormatterService, FORMATS } from '@app/services/date-formatter/date-formatter.service';

@Component({
	selector: 'app-schedules',
	templateUrl: './schedules.component.html',
	styleUrls: ['./schedules.component.scss'],
})
export class SchedulesComponent implements OnInit {
	MAX_SCHEDULES_PER_PAGE = 10;
	usersData: Array<any> = [];
	schedules: Array<ISchedule> = [];
	isAdmin: boolean;
	dataLoadedSchedules = false;
	dataLoading = false;
	dataSearching = false;
	counterData: { key: string; value: number }[] = [];
	totalSchedules = 0;
	searchText: string;
	currentPage = 0;
	timeout: any;

	constructor(
		private cService: CommonService,
		private dialogService: DialogService,
		private promptService: PromptService,
		private snackbarService: SnackbarService,
		private apiScheduleService: ApiScheduleService,
		private scheduleService: ScheduleService,
		private translate: TranslateService,
		private dateFormatter: DateFormatterService,
		private timezoneService: ApiTimezoneService,
	) {}

	ngOnInit(): void {
		this.isAdmin = this.cService.isAdmin();

		this.dataLoadedSchedules = false;

		this.getSchedules();
	}

	filter(searchText: string): void {
		//give the user a chance to finish typing
		this.searchText = searchText;
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.dataSearching = true;
			this.getSchedules(0, searchText);

			clearTimeout(this.timeout);
			this.timeout = undefined;
		}, 500);
	}

	get totalCount(): number {
		return this.totalSchedules;
	}

	setPaginatedSchedules = (pageNumber: number): void => {
		const startingIndex = (pageNumber - 1) * this.MAX_SCHEDULES_PER_PAGE;

		this.dataLoading = true;

		this.getSchedules(startingIndex, this.searchText);
	};

	setCounterData(): void {
		this.counterData[0] = { key: 'Total', value: this.totalCount };
	}

	showAddSchedule(): void {
		const dialogRef = this.dialogService.create(AddScheduleComponent);
		const text = this.translate.instant('SCHEDULES_PAGE.ADD.SUCCESS', {
			message: this.translate.instant('GLOBALS.ADDED_SUCCESSFULLY_MESSAGE'),
		});

		dialogRef.onDismiss().subscribe(reload => {
			if (reload) {
				this.dataLoading = true;
				this.getSchedules();
				this.snackbarService.create({
					status: 'success',
					text,
					connectTo: undefined,
				});
			}
		});
	}

	showEditSchedule(schedule): void {
		const dialogRef = this.dialogService.create(EditScheduleComponent, JSON.parse(JSON.stringify(schedule)));
		const message = this.translate.instant('GLOBALS.SAVED_SUCCESSFULLY_MESSAGE');

		dialogRef.onDismiss().subscribe(reload => {
			if (reload) {
				this.dataLoading = true;
				this.getSchedules(this.currentPage, this.searchText);
				this.snackbarService.create({
					status: 'success',
					text: `${schedule.name} ${message}`,
					connectTo: undefined,
				});
			}
		});
	}

	showDeleteSchedule(schedule): void {
		const dialogRef = this.promptService.create(DeleteScheduleComponent, schedule, {
			width: 510,
			successLabel: this.translate.instant('GLOBALS.DELETE'),
			successType: 'delete',
		});

		const scheduleId = schedule.id;
		const scheduleName = schedule.name;
		const message = this.translate.instant('GLOBALS.DELETED_SUCCESSFULLY_MESSAGE');

		dialogRef.onDismiss().then(decision => {
			if (decision === Decision.CANCEL) return;

			this.dataLoading = true;
			this.apiScheduleService
				.removeSchedule(scheduleId)

				.subscribe(
					result => {
						if (result.hasError || result.errorMessages.length) {
							this.dataLoading = false;
							const errors = result.errorMessages.join(' ');
							this.snackbarService.create({
								status: 'danger',
								text: errors,
								connectTo: undefined,
							});
						} else {
							this.snackbarService.create({
								status: 'success',
								text: `${scheduleName} ${message}`,
								connectTo: undefined,
							});
							this.getSchedules(0, this.searchText);
						}
					},
					error => {
						this.dataLoading = false;
						const errors = error.errorMessages.join(' ');

						this.snackbarService.create({
							status: 'danger',
							text: errors,
							connectTo: undefined,
						});
					},
				);
		});
	}

	getSchedules = (pageNumber: number = 0, filter: string = undefined, order: number = 0): void => {
		this.apiScheduleService.getSchedules(filter, pageNumber, this.MAX_SCHEDULES_PER_PAGE, order).subscribe(
			(result: any) => {
				this.dataLoading = this.dataSearching = false;
				this.dataLoadedSchedules = true;

				if (!result.hasError && (!result.errorMessages || !result.errorMessages.length)) {
					this.totalSchedules = result.data.total;
					this.schedules = result.data.schedules;
					this.currentPage = pageNumber;
					this.setCounterData();
				}
			},
			(error: any) => {
				this.dataLoading = this.dataSearching = false;
				this.dataLoadedSchedules = true;
			},
		);
	};

	canEdit = (schedule: ISchedule): boolean => {
		return this.isAdmin && schedule.type !== '24/7';
	};

	canDelete = (schedule: ISchedule): boolean => {
		//if the schedule is not used by any "entity", it can be deleted
		if (
			schedule &&
			schedule.used &&
			(!schedule.used.users || schedule.used.users.length === 0) &&
			(!schedule.used.departments || schedule.used.departments.length === 0) &&
			(!schedule.used.ringGroups || schedule.used.ringGroups.length === 0) &&
			(!schedule.used.welcomeMenus || schedule.used.welcomeMenus.length === 0) &&
			schedule.type !== '24/7' &&
			schedule.type !== 'Open'
		) {
			return this.isAdmin && true;
		} else {
			return false;
		}
	};

	isDisabled = (schedule: ISchedule): boolean => {
		//if you can't delete the schedule and its not a 24/7, then display as disabled
		return this.isAdmin && !this.canDelete(schedule) && schedule.type !== '24/7' && schedule.type !== 'Open';
	};

	ruleDaysTooltip = (rule, max = 2): number => {
		let numberOfExtraDates = 0;
		if (rule && rule.days && rule.days.dates && !rule.days.isRange && rule.days.dates.length > max) {
			numberOfExtraDates = rule.days.dates.length - max;
		}

		return numberOfExtraDates;
	};

	getRuleDaysTooltip = (rule, start): string => {
		let dayList = '';
		if (rule && rule.days && rule.days.dates && !rule.days.isRange && rule.days.dates.length > start) {
			let i = 0;
			rule.days.dates.forEach(d => {
				if (i >= start) {
					if (dayList) {
						dayList += ', ';
					}
					dayList += this.dateFormatter.format(d, FORMATS.DATE_MED);
				}
				i += 1;
			});
		}

		return dayList;
	};

	getRuleDays = (rule): string => {
		return this.scheduleService.getRuleDays(rule);
	};

	getDayOfWeekRange = (days): string => {
		return this.scheduleService.getDayOfWeekRange(days);
	};

	getDayOfWeek = (day): string => {
		return this.scheduleService.getDayOfWeek(day);
	};

	getTimeZoneName(timezone: string): string {
		return this.timezoneService.getTimezoneFormattedAbbreviation(timezone);
	}
}
