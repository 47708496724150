import { Injectable } from '@angular/core';

@Injectable()
export class LeftSideNavService {
	private _leftSideNavOpened: boolean;
	constructor() {}

	set leftSideNavOpened(opened) {
		this._leftSideNavOpened = opened;
	}

	get leftSideNavOpened(): boolean {
		return this._leftSideNavOpened;
	}
}
