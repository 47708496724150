import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { getApiUrl } from '@app/Common';
import { ICallBlockingRule, CallBlockingRuleResponse, ISchedule } from './outbound-call-blocking.domain';
import { ApiService } from '@app/services';

@Injectable()
export class OutboundCallBlockingService {
	private readonly baseUrl: string;

	constructor(private apiService: ApiService) {
		this.baseUrl = `${getApiUrl()}api/call-blocking/outbound`;
	}

	public fetchRules(): Observable<ICallBlockingRule[]> {
		return this.apiService.get<CallBlockingRuleResponse>(this.baseUrl).pipe(
			map(({ data, hasError, errorMessages }) => {
				if (hasError) {
					if (errorMessages.length > 0) {
						throw Error(errorMessages[0].message);
					} else {
						throw Error('Unknown error');
					}
				}

				return data;
			}),
		);
	}

	public createRule(
		rule: Pick<ICallBlockingRule, Exclude<keyof ICallBlockingRule, 'id'>>,
	): Observable<ICallBlockingRule[]> {
		return this.apiService.post<CallBlockingRuleResponse>(this.baseUrl, rule).pipe(
			map(({ data, hasError, errorMessages }) => {
				if (hasError) {
					if (errorMessages.length > 0) {
						throw Error(errorMessages[0].message);
					} else {
						throw Error('Unknown error');
					}
				}

				return data;
			}),
		);
	}

	public editRule(rule: ICallBlockingRule): Observable<ICallBlockingRule[]> {
		return this.apiService.put<CallBlockingRuleResponse>(`${this.baseUrl}/${rule.id}`, rule).pipe(
			map(({ data, hasError, errorMessages }) => {
				if (hasError) {
					if (errorMessages.length > 0) {
						throw Error(errorMessages[0].message);
					} else {
						throw Error('Unknown error');
					}
				}

				return data;
			}),
		);
	}

	public deleteRules(rule: ICallBlockingRule): Observable<ICallBlockingRule[]> {
		return this.apiService.delete<CallBlockingRuleResponse>(`${this.baseUrl}/${rule.id}`).pipe(
			map(({ data, hasError, errorMessages }) => {
				if (hasError) {
					if (errorMessages.length > 0) {
						throw Error(errorMessages[0].message);
					} else {
						throw Error('Unknown error');
					}
				}

				return data;
			}),
		);
	}

	public isScheduleRange(schedule: ISchedule): boolean {
		if (schedule.effectiveFrom && schedule.effectiveTo) {
			return true;
		} else {
			return (
				!schedule.daysOfWeek ||
				schedule.daysOfWeek.length === 0 ||
				(schedule.daysOfWeek.length > 1 &&
					schedule.daysOfWeek.every((day, index, self) => {
						if (index > 0) {
							return day - self[index - 1] === 1;
						}
						return true;
					}))
			);
		}
	}
}
