import {
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Injector,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

import { DropdownDataComponent } from '@app/n2p-components/dropdown/dropdown-data/dropdown-data.component';
import { DropdownHiddenDataPopupComponent } from '@app/n2p-components/dropdown/dropdown-hidden-data-popup/dropdown-hidden-data-popup.component';
import { SimpleDropdownComponentComponent } from '@app/n2p-components/dropdown/simple-dropdown-component/simple-dropdown-component.component';
import {
	DROPDOWN_ADDITIONAL_DATA,
	DROPDOWN_CHIP_MORE_REF,
	DROPDOWN_DATA,
	DROPDOWN_DATA_CLICK_CALLBACK,
	DROPDOWN_DATA_COMPONENT,
	DROPDOWN_DATA_CONFIG,
	DROPDOWN_DATA_FILTER,
	DROPDOWN_DATA_HEIGHT,
} from '@n2p/dropdown/dropdown.injectors';

export interface DropdownData {
	label?: string;
	value: string;
	selected?: boolean;
	subValue?: string;
}

export interface DropdownDataConfig {
	singleSelection: boolean;
	showSelectedSection: boolean;
}

@Component({
	/* tslint:disable */
	selector: 'n2p-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => DropdownComponent),
		},
	],
	/* tslint:enable */
})
export class DropdownComponent implements OnDestroy, OnInit, ControlValueAccessor {
	readonly DROPDOWN_HEIGHT: number = 44;
	readonly DROPDOWN_DATA_ITEM_HEIGHT: number = 44;
	dropdownData: Array<DropdownData>;
	dataFormControl: FormControl = new FormControl();
	dataFormControlSubscription: Subscription;
	@ViewChild('n2pDropdownWrapper', { static: true }) n2pDropdownWrapper: ElementRef;
	@ViewChild('n2pDropdown', { static: true }) n2pDropdown: ElementRef;
	@ViewChild('searchInput', { static: false }) searchInput: ElementRef;
	@ViewChild('selectedWrapper', { static: true }) selectedWrapper: ElementRef;
	@ViewChild('moreBubble', { static: true }) moreBubble: ElementRef;
	@Input() showSubValue: boolean = true;
	@Input() isDisabled: boolean = false;
	@Input() label: string;
	@Input() dropDownComponent: any;
	@Input() singleSelection: boolean;
	@Input() showSelectedSection: boolean = true;
	@Input() additionalDropdownData: any;
	@Input() isTypeHover: boolean = false;
	@Input() showArrow: boolean = false;
	isHovered: boolean = false;
	@Input() required: boolean = true;
	@Input() hasError: boolean;
	@Input() valueLoading: boolean;
	@Input() placeholder: string;
	@Input() overwriteSelectedSection: boolean;
	@Input() dropdownDataHeight: number = this.DROPDOWN_DATA_ITEM_HEIGHT * 6;
	@Input() singleLevel: boolean = false;
	@Input() onlyNumbers: boolean = false;
	@Input() uniqueFieldName: string = 'value';
	@Input() showMoreBubble: boolean = true;
	@Output() onClosed: EventEmitter<any> = new EventEmitter();
	searchIsOnFocus: boolean;
	overlayRef: OverlayRef;
	search: FormControl = new FormControl('');
	hiddenSelectedData: Array<any>;
	popupOverlayRef: OverlayRef;
	dropdownHiddenDataPopupComponent = DropdownHiddenDataPopupComponent;
	private _value: Array<DropdownData>;
	private _subscriptions: Array<Subscription> = [];
	onChanged = (value: any): void => {};
	onTouched = (): void => {};
	constructor(private overlay: Overlay, private n2pDrodownRef: ElementRef, private injector: Injector) {}
	set value(val: Array<DropdownData>) {
		this._value = val;
		this.onChanged(val);
	}

	get value(): Array<DropdownData> {
		return this._value;
	}

	writeValue(value: any): void {
		if (value) this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChanged = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	@Input() set data(d: Array<DropdownData>) {
		// this is needed because of an angular bug calling ngOnInit before write value
		if (d) {
			setTimeout(() => {
				this.data.forEach((i: DropdownData) => (i.selected = false));
				if (this.value && this.value.length) {
					this.value.forEach((item: DropdownData) => {
						const index = this.data.findIndex(
							(i: DropdownData) => i[this.uniqueFieldName] === item[this.uniqueFieldName],
						);
						if (index !== -1) {
							this.data[index].selected = true;
						}
					});
				}
				this.dataFormControl = this.buildDataFormControl();
				this.subscribeToChange();
				this.getHiddenSelectedData();
				if (this.overlayRef) {
					this.close();
					this.open();
				}
			});
		}
		this.dropdownData = d;
	}

	get data(): Array<DropdownData> {
		return this.dropdownData;
	}

	get selectedData(): Array<any> {
		if (!this.dataFormControl.value) return [];

		return this.dataFormControl.value.filter((i: DropdownData) => i.selected);
	}

	wordsToAddInPopup(): Array<any> {
		const allWords = this.selectedData;
		const spans = this.selectedWrapper && this.selectedWrapper.nativeElement.querySelectorAll('span.n2p-dropdown-item');

		if (!spans || !spans.length) return;

		let currentWord;
		let currentSpan;
		const wrapperWidth = this.n2pDropdownWrapper.nativeElement.offsetWidth - 64; // 64 is addition of the margins
		let totalWidth = 0;
		const array = [];
		this.hiddenSelectedData = [];
		for (let i = 0; i < allWords.length; i++) {
			currentWord = allWords[i];
			currentSpan = spans[i];
			if (!currentSpan) {
				continue;
			}
			totalWidth += currentSpan.offsetWidth;

			if (totalWidth > wrapperWidth) {
				array.push(currentWord);
			}
		}

		return array;
	}

	selectedDataText(): void {
		if (!this.selectedWrapper) {
			return;
		}

		this.hiddenSelectedData = [];
		const selectedData = this.selectedData;
		// const container = document.createElement('ng-container')
		this.selectedWrapper.nativeElement.innerHTML = '';
		for (let i = 0; i < selectedData.length; i++) {
			const span = document.createElement('span');
			const textNode = document.createTextNode(
				selectedData.length === i + 1 ? selectedData[i].value : `${selectedData[i].value}, `,
			);
			span.appendChild(textNode);
			this.selectedWrapper.nativeElement.appendChild(span);
			// container.appendChild(span)
			if (this.selectedWrapper.nativeElement.offsetWidth < this.selectedWrapper.nativeElement.scrollWidth) {
				this.hiddenSelectedData.push(selectedData[i]);
			}
		}
	}

	close(): void {
		if (!this.overlayRef) return;

		this.overlayRef.dispose();
		this.overlayRef = undefined;
		this.isHovered = false;
		this.search.reset();
		if (this.onClosed) this.onClosed.emit(this.value);
	}

	open(): void {
		this.close();

		const diff =
			window.innerHeight - (this.n2pDropdown.nativeElement.getBoundingClientRect()['y'] + (this.DROPDOWN_HEIGHT + 4));
		const onTop = diff < this.dropdownDataHeight;
		this.overlayRef = this.overlay.create({
			backdropClass: 'multi-select-dropdown-data',
			width: this.n2pDropdownWrapper.nativeElement.offsetWidth,
			hasBackdrop: true,
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			positionStrategy: this.overlay
				.position()
				.connectedTo(
					this.n2pDrodownRef,
					{
						originY: onTop ? 'top' : 'bottom',
						originX: 'start',
					},
					{
						overlayY: onTop ? 'bottom' : 'top',
						overlayX: 'start',
					},
				)
				.withOffsetY(onTop ? -4 : 4),
		});
		this.overlayRef.backdropClick().subscribe(() => {
			this.close();
		});
		const portalComponent = new ComponentPortal(DropdownDataComponent, undefined, this.createInjector());
		this.overlayRef.attach(portalComponent);
		this.searchIsOnFocus = true;
		setTimeout(() => {
			if (this.searchInput && this.searchInput.nativeElement) this.searchInput.nativeElement.focus();
		});
	}

	showHiddenDataPopup(ev: any): void {
		ev.stopPropagation();
		this.popupOverlayRef = this.overlay.create({
			backdropClass: 'multi-select-dropdown-data',
			hasBackdrop: true,
			positionStrategy: this.overlay
				.position()
				.connectedTo(
					this.moreBubble,
					{
						originY: 'bottom',
						originX: 'center',
					},
					{
						overlayY: 'top',
						overlayX: 'center',
					},
				)
				.withOffsetY(16),
		});
		this.popupOverlayRef.backdropClick().subscribe(() => {
			this.popupOverlayRef.dispose();
			this.popupOverlayRef = undefined;
		});
		const portalComponent = new ComponentPortal(
			DropdownHiddenDataPopupComponent,
			undefined,
			this.createHiddenPopupInjector(this.popupOverlayRef),
		);
		this.popupOverlayRef.attach(portalComponent);
	}

	ngOnInit(): void {
		if (!this.showSelectedSection) this.dropdownDataHeight -= this.DROPDOWN_DATA_ITEM_HEIGHT;
	}

	ngOnDestroy(): void {
		if (this.dataFormControlSubscription) this.dataFormControlSubscription.unsubscribe();
		this.close();
	}

	private buildDataFormControl(): any {
		return new FormControl(
			this.data.map((d: DropdownData) => {
				return { ...d };
			}),
		);
	}

	private createInjector(): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(DROPDOWN_DATA, this.dataFormControl);
		injectorTokens.set(DROPDOWN_DATA_FILTER, this.search);
		injectorTokens.set(DROPDOWN_DATA_HEIGHT, this.dropdownDataHeight);
		injectorTokens.set(DROPDOWN_DATA_CONFIG, {
			isRequired: this.required,
			singleSelection: this.singleSelection,
			showSelectedSection: this.showSelectedSection,
			overwriteSelectedSection: this.overwriteSelectedSection,
		});
		injectorTokens.set(DROPDOWN_DATA_COMPONENT, this.dropDownComponent || SimpleDropdownComponentComponent);
		if (this.additionalDropdownData) {
			injectorTokens.set(DROPDOWN_ADDITIONAL_DATA, this.additionalDropdownData);
		}

		return new PortalInjector(this.injector, injectorTokens);
	}

	private createHiddenPopupInjector(hiddenOverlayRef: OverlayRef): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(DROPDOWN_DATA, this.hiddenSelectedData);
		injectorTokens.set(DROPDOWN_CHIP_MORE_REF, hiddenOverlayRef);
		injectorTokens.set(DROPDOWN_DATA_CLICK_CALLBACK, (item: DropdownData) => {
			const index = this.dataFormControl.value.findIndex(
				(i: DropdownData) => i[this.uniqueFieldName] === item[this.uniqueFieldName],
			);
			this.dataFormControl.value[index].selected = false;
			setTimeout(() => {
				this.hiddenSelectedData = this.wordsToAddInPopup();
				if (!this.hiddenSelectedData.length) hiddenOverlayRef.dispose();
			});
		});

		return new PortalInjector(this.injector, injectorTokens);
	}

	private subscribeToChange(): void {
		this.dataFormControlSubscription = this.dataFormControl.valueChanges.subscribe(() => {
			this.writeValue(this.selectedData);
			if (this.singleSelection) this.close();
			this.getHiddenSelectedData();
		});
	}

	private getHiddenSelectedData(): void {
		setTimeout(() => {
			this.hiddenSelectedData = this.wordsToAddInPopup();
		});
	}
}
