import { Component, Inject } from '@angular/core';

import { DIALOG_REF, DIALOG_DATA } from 'utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { AuthStorageData } from '@app/services';

@Component({
	selector: 'app-forward-calls-to-add-number',
	templateUrl: './forward-calls-to-add-number.component.html',
	styleUrls: ['./forward-calls-to-add-number.component.scss'],
})
export class ForwardCallsToAddNumberComponent {
	isExternalTab: boolean = true;
	forwardingNumberlist: string[];
	userToEditId: number;
	restrictedPhoneNumbers: string[];
	onlyUsNumbers: boolean = false;

	constructor(
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		@Inject(DIALOG_DATA) private dialogData: any,
		private authStorageDataService: AuthStorageData,
	) {
		this.forwardingNumberlist = dialogData.forwardingNumberlist.map(i => i.value);
		this.onlyUsNumbers = dialogData.onlyUsNumbers;
		this.restrictedPhoneNumbers = dialogData.restrictedPhoneNumbers;

		if (dialogData.userToEditId) {
			this.userToEditId = dialogData.userToEditId;
		}
	}

	onSubmit(phoneNumber: string): void {
		this.dialogRef.dismiss(phoneNumber);
	}

	onCancel(): void {
		this.dialogRef.dismiss();
	}

	toggle(): void {
		this.isExternalTab = !this.isExternalTab;
	}
}
