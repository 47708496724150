import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ApiDevicesService, SnackbarService } from '@app/services';
import { macAddressValidator, noTrimValidator } from '@utils/helpers/validators';
import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';

@Component({
	selector: 'add-edit-devices',
	templateUrl: './add-edit-devices.component.html',
	styleUrls: ['./add-edit-devices.component.scss'],
})
export class DevicesAddEditComponent implements OnInit {
	/* Old */
	title;
	selectedUserId;
	selectedUserDevice;

	/*New*/
	deviceTypes: Array<{
		id: number;
		name: string;
		manufacturer: string;
		status: string;
	}>;

	saving: boolean = false;

	formGroup: FormGroup;
	users: Array<any>;
	deviceTypeData: Array<{
		id: number;
		name: string;
		manufacturer: string;
	}>;
	manufacturer;
	@ViewChild('bodySectionRef', { static: true }) anchor: ElementRef;

	constructor(
		@Inject(DIALOG_DATA) private data: any,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		private snackbarService: SnackbarService,
		private apiDevices: ApiDevicesService,
		private translate: TranslateService,
		private fb: FormBuilder,
	) {
		this.users = data.users;

		this.manufacturer = {
			yealink: 'Yealink',
			aastra: 'Aastra',
			fanvil: 'Fanvil',
			grandstream: 'Grandstream',
			panasonic: 'Panasonic',
			polycom: 'Polycom',
			unsupported: 'unsupported',
		};

		this.selectedUserId = data.userId;
		this.selectedUserDevice = data.device;

		this.title = this.translate.instant(
			this.selectedUserDevice ? 'DEVICES_PAGE.ADD_DEVICE.TITLE_SETTINGS' : 'DEVICES_PAGE.ADD_DEVICE.TITLE_ADD',
		);

		this.formGroup = fb.group({
			deviceType: [
				this.selectedUserDevice && this.selectedUserDevice.deviceType && this.selectedUserDevice.deviceType.id > 0
					? [
							{
								...this.selectedUserDevice.deviceType,
								value: this.selectedUserDevice.deviceType.name,
								selected: true,
							},
					  ]
					: [],
			],
			mac: [this.selectedUserDevice ? this.selectedUserDevice.macId : '', macAddressValidator],
			serialNumber: [
				this.shouldIncludeSerialNumber(this.selectedUserDevice?.deviceType?.manufacturer)
					? this.selectedUserDevice?.serialNumber || null
					: null,
				[Validators.required, noTrimValidator],
			],
			assignedTo: [[{ userId: this.selectedUserId }]],
		});
	}

	ngOnInit(): void {
		this.loadDeviceTypes();
	}

	shouldIncludeSerialNumber(manufacturer: string): boolean {
		return String(manufacturer).toLowerCase() === String(this.manufacturer.yealink).toLowerCase();
	}

	getErrorText(response: any): string {
		return response.errorMessages.some(err => (err.code = '-1'))
			? this.translate.instant('DEVICES_PAGE.ADD_DEVICE.MAC_IS_TAKEN')
			: this.translate.instant('GLOBALS.GENERAL_ERROR');
	}

	loadDeviceTypes(): void {
		this.apiDevices
			.getDeviceTypes()
			.toPromise()
			.then(response => {
				if (!response.hasError) {
					this.deviceTypes = response.data;

					//if the users are populated (Device Page) then do not display the unsupported device
					const unsupportedDeviceIndex = this.deviceTypes.findIndex(f => f.id < 0);
					if (this.users) {
						this.deviceTypes.splice(unsupportedDeviceIndex, 1);
					} else {
						this.deviceTypes[unsupportedDeviceIndex].name = this.translate.instant(
							'EDIT_SPECIAL_EXTENSION.UNSUPPORTED_DEVICE',
						);
					}

					this.deviceTypeData = this.deviceTypes
						.filter(f => f.status === 'A' || f.id < 0)
						.map(deviceType => ({
							...deviceType,
							selected: false,
							value: deviceType.name,
							type: 'device',
						}));
				}
			});
	}

	saveSettings(): void {
		const assignedTo = this.formGroup.get('assignedTo').value[0];
		const userId = assignedTo && assignedTo.userId;
		const macId = this.formGroup.get('mac').value;
		const serialNumber = this.formGroup.get('serialNumber').value;
		const deviceType = this.formGroup.get('deviceType').value[0];
		const deviceTypeId = deviceType && deviceType.id;
		const deviceTypeManufacturer = deviceType && deviceType.manufacturer;

		const requestData = {
			userId,
			macId,
			deviceTypeId,
			deviceTypeManufacturer,
		};

		if (this.shouldIncludeSerialNumber(deviceTypeManufacturer) && serialNumber) {
			requestData['serialNumber'] = serialNumber;
		}

		if (this.selectedUserDevice) {
			this.updateDevice(requestData);
		} else {
			if (userId) {
				this.createUserDevice(requestData);
			} else {
				this.createDevice(requestData);
			}
		}
	}

	createDevice(data: any): void {
		const { macId, deviceTypeId, deviceTypeManufacturer, serialNumber } = data;

		this.saving = true;

		this.apiDevices
			.createDevice(macId, deviceTypeId, deviceTypeManufacturer, serialNumber)
			.toPromise()
			.then(response => {
				this.saving = false;
				if (response.hasError) {
					this.snackbarService.create({
						status: 'danger',
						text: this.getErrorText(response),
						connectTo: this.anchor,
					});
				} else {
					this.onDismiss(response.data);

					this.snackbarService.create({
						status: 'success',
						text: this.translate.instant('DEVICES_PAGE.ADD_DEVICE.SUCCESS_ADD'),
					});
				}
			})
			.catch(error => {
				this.saving = false;
				this.snackbarService.create({
					status: 'danger',
					text: error.errorMessages.join(' '),
					connectTo: this.anchor,
				});
			});
	}

	createUserDevice(data: any): void {
		const { userId, macId, deviceTypeId, deviceTypeManufacturer, serialNumber } = data;

		this.saving = true;

		this.apiDevices
			.createUserDevice(userId, macId, deviceTypeId, deviceTypeManufacturer, serialNumber)
			.toPromise()
			.then(response => {
				this.saving = false;
				if (response.hasError) {
					this.snackbarService.create({
						status: 'danger',
						text: this.getErrorText(response),
						connectTo: this.anchor,
					});
				} else {
					this.onDismiss(response.data);

					this.snackbarService.create({
						status: 'success',
						text: this.translate.instant('DEVICES_PAGE.ADD_DEVICE.SUCCESS_ADD'),
					});
				}
			})
			.catch(error => {
				this.saving = false;
				this.snackbarService.create({
					status: 'danger',
					text: error.errorMessages.join(' '),
					connectTo: this.anchor,
				});
			});
	}

	updateDevice(data: any): void {
		const { macId, deviceTypeId, deviceTypeManufacturer, serialNumber } = data;

		this.saving = true;
		const isUnsupported =
			this.selectedUserDevice.deviceType && this.selectedUserDevice.deviceType.manufacturer === 'unsupported';

		this.apiDevices
			.editUserDevice(
				this.selectedUserId,
				isUnsupported ? 'unsupported' : this.selectedUserDevice.macId,
				macId,
				deviceTypeId,
				deviceTypeManufacturer,
				serialNumber,
			)
			.toPromise()
			.then(response => {
				this.saving = false;
				if (response.hasError) {
					this.snackbarService.create({
						status: 'danger',
						text: this.getErrorText(response),
						connectTo: this.anchor,
					});
				} else {
					this.onDismiss(response.data);

					this.snackbarService.create({
						status: 'success',
						text: this.translate.instant('DEVICES_PAGE.ADD_DEVICE.SUCCESS_UPDATE'),
					});
				}
			})
			.catch(error => {
				this.saving = false;
				this.snackbarService.create({
					status: 'danger',
					text: error.errorMessages.join(' '),
					connectTo: this.anchor,
				});
			});
	}

	get canSubmit(): boolean {
		const formDirty = this.formGroup.dirty;
		const typeFormControl = this.formGroup.get('deviceType');
		const macFormControl = this.formGroup.get('mac');
		const serialNumberFormControl = this.formGroup.get('serialNumber');
		const selectedType = typeFormControl.value[0];
		const selectedManufacturer = selectedType && selectedType.manufacturer;
		const changedToUnsupported = typeFormControl.dirty && selectedManufacturer === 'unsupported';
		const changedToValidSerialNumber = this.shouldIncludeSerialNumber(selectedManufacturer)
			? formDirty && serialNumberFormControl.valid
			: true;
		const changedToValidMacId = selectedManufacturer !== 'unsupported' && formDirty && macFormControl.valid;

		return changedToUnsupported || (changedToValidSerialNumber && changedToValidMacId);
	}

	cancelDevice(): void {
		this.onDismiss(undefined);
	}

	onDismiss(value): void {
		this.dialogRef.dismiss(value);
	}
}
