import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { pairwise } from 'rxjs/operators';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';

import { ApiDepartmentsService, ApiUsersService, FeatureFlagsService } from '@app/services';
import { IAllowedCallPickupUser, ICallPickupUsers, IUser } from '@app/services/web-apis/users/api-users.domain';
import {
	GoogleAnalyticsService,
	GA_PROFILE_PICKUP_CALL_TO_USER,
	GA_PROFILE_PICKUP_CALL_TO_DEPT,
	GA_PROFILE_PICKUP_ALLOW_ANYONE,
} from '@app/services/google-analytics';

const userTypeMap = {
	['team-member']: 'user',
	department: 'department',
};

const callPickupOptions = {
	everybody: 'Everybody',
	nobody: 'Nobody',
	specificUsers: 'SpecificUsers',
};

@Component({
	selector: 'n2p-call-options-pickup',
	templateUrl: './call-options-pickup.html',
	styleUrls: ['./call-options-pickup.component.scss'],
})
export class CallOptionsPickupComponent {
	subscriptions: Subscription = new Subscription();
	isActive: boolean = true;
	displayCallPickupLogic: boolean = false;
	fetchData: boolean = false;
	formGroup: FormGroup = this.fb.group({
		callPickup: callPickupOptions.everybody,
		assignedUsers: [[], [Validators.required]],
	});
	availableEntityTypes: string[] = ['team-member', 'department'];
	@Input() user: IUser;

	constructor(
		private featureFlagsService: FeatureFlagsService,
		private userService: ApiUsersService,
		private apiDepartments: ApiDepartmentsService,
		private fb: FormBuilder,
		private GA: GoogleAnalyticsService,
	) {}

	private mapEntities = (entityArray: Array<any>, type: string, valueFn?: Function): Array<any> =>
		entityArray.map(entity => ({
			...entity,
			value: (valueFn && valueFn(entity)) || entity.name,
			selected: false,
			subValue: type !== 'welcome-menu' ? entity.extension : undefined,
			type,
		}));

	ngOnInit(): void {
		this.featureFlagsService.callPickupEnabled().subscribe(res => (this.displayCallPickupLogic = res));

		if (this.displayCallPickupLogic) {
			combineLatest([this.apiDepartments.getAllDepartments(), this.userService.getAllUsers()]).subscribe(
				([departments, users]) => {
					const concatData = [
						...this.mapEntities(users.data, 'user', tm => `${tm.firstName} ${tm.lastName}`),
						...this.mapEntities(departments.data, 'department'),
					];

					this.subscriptions.add(
						this.userService.getAvailableCallPickupUsers(this.user.userId.toString()).subscribe(payload => {
							this.isActive = payload.data.isActive;

							this.formGroup.setValue({
								callPickup: this.getEventRadioType(payload.data),
								assignedUsers: concatData.filter(data =>
									payload.data.allowedFor.find(user => user.id === (data.userId || data.deptId)),
								),
							});

							this.subscriptions.add(
								this.formGroup.valueChanges.pipe(pairwise()).subscribe(([prevValue, value]) => {
									const selectedUser = value.assignedUsers.find(obj => prevValue.assignedUsers.indexOf(obj) === -1);
									const unselectedUser = prevValue.assignedUsers.find(obj => value.assignedUsers.indexOf(obj) === -1);

									if (this.formGroup.touched) {
										this.updateCallPickUpUsers(value.assignedUsers, selectedUser || unselectedUser);
									}
								}),
							);
						}),
					);
				},
			);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	getEventRadioType(data: ICallPickupUsers): string {
		if (!data.isActive) {
			return callPickupOptions.everybody;
		} else if (data.isActive && data.allowedFor && data.allowedFor.length > 0) {
			return callPickupOptions.specificUsers;
		} else {
			return callPickupOptions.nobody;
		}
	}

	sendGoogleEvent(user: IAllowedCallPickupUser): void {
		!this.isActive && this.GA.event(GA_PROFILE_PICKUP_ALLOW_ANYONE);

		if (user) {
			user.type === 'department' && this.GA.event(GA_PROFILE_PICKUP_CALL_TO_DEPT);
			user.type === 'team-member' && this.GA.event(GA_PROFILE_PICKUP_CALL_TO_USER);
		}
	}

	updateCallPickUpUsers(userList: any[], user: IAllowedCallPickupUser): void {
		const selectedSpecificUsersOption = this.formGroup.get('callPickup').value === callPickupOptions.specificUsers;

		if (!this.fetchData && (!selectedSpecificUsersOption || (selectedSpecificUsersOption && userList.length > 0))) {
			this.fetchData = true;
			this.isActive = this.formGroup.get('callPickup').value !== callPickupOptions.everybody;

			const allowedFor = selectedSpecificUsersOption
				? userList.map(user => ({
						id: user.deptId || user.userId,
						type: userTypeMap[user.type] || user.type,
				  }))
				: [];

			this.sendGoogleEvent(user);

			this.userService
				.putAvailableCallPickupUsers(this.user.userId.toString(), { isActive: this.isActive, allowedFor })
				.subscribe(
					data => {
						this.isActive = data.isActive;
						this.fetchData = false;
					},
					() => (this.fetchData = false),
				);
		}
	}
}
