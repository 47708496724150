import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ReceptionistConsoleComponent } from '@app/pages/receptionist-console/receptionist-console.component';

@NgModule({
	imports: [CommonModule, RouterModule.forChild([{ path: '', component: ReceptionistConsoleComponent }])],
	declarations: [],
	exports: [RouterModule],
})
export class ReceptionistConsoleRoutingModule {}
