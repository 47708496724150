import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import {
	DROPDOWN_INPUT_CONTROL,
	DROPDOWN_INPUT_DATA,
	DROPDOWN_INPUT_REF,
} from '@n2p/input/dropdown-input/dropdown-input-injectors';

@Component({
	selector: 'n2p-simple-dropdown-input-data',
	templateUrl: './simple-dropdown-input-data.component.html',
	styleUrls: ['./simple-dropdown-input-data.component.scss'],
})
export class SimpleDropdownInputDataComponent implements OnInit {
	dropdownData: Array<any>;
	value: FormControl;
	constructor(
		@Inject(DROPDOWN_INPUT_DATA) data: any,
		@Inject(DROPDOWN_INPUT_REF) private overlayRef: OverlayRef,
		@Inject(DROPDOWN_INPUT_CONTROL) control: FormControl,
	) {
		this.dropdownData = data;
		this.value = control;
	}

	itemClicked(item): void {
		this.value.setValue(item.value);
		this.overlayRef.dispose();
		this.overlayRef = undefined;
	}

	ngOnInit() {}
}
