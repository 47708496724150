import { Component, Inject, OnInit } from '@angular/core';

import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { PromptRef } from '@app/shared/classes/PromptRef';

@Component({
	selector: 'app-devices-assigned-prompt',
	templateUrl: './devices-assigned-prompt.component.html',
	styleUrls: ['./devices-assigned-prompt.component.scss'],
})
export class DevicesAssignedPromptComponent implements OnInit {
	user: any;

	constructor(@Inject(DIALOG_DATA) data: { user: any }, @Inject(DIALOG_REF) promptRef: PromptRef) {
		this.user = data.user;
	}

	ngOnInit() {}
}
