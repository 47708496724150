import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';

@Injectable()
export class AudioPlayerService {
	private dataStore = {
		welcomeMenus: {},
	};

	// observable source
	private playerStartedSource = new Subject<string>();

	// observable stream
	playerStarted$ = this.playerStartedSource.asObservable(); // tslint:disable-line:member-ordering

	constructor(private apiService: ApiService) {}

	// command
	startPlayer(id: string): void {
		this.playerStartedSource.next(id);
	}

	getWelcomeMenu = (id: number): Observable<any> => {
		const url = `/accounts/{accountId}/multiAttendants/${id}/menu/menuGreetingFiles?nGetAllAudioFiles=0`;

		if (this.dataStore.welcomeMenus[id]) {
			return of(this.dataStore.welcomeMenus[id]);
		}

		const call = this.apiService.get(url);

		call.subscribe(result => {
			if (!result.hasError) {
				this.dataStore.welcomeMenus[id] = result;
			}
		});

		return call;
	};
}
