import { Directive, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
	selector: '[appScrollingCheck]',
})
export class ScrollingCheckDirective implements OnInit, OnDestroy {
	subscription: Subscription = new Subscription();
	source$: Subject<number> = new Subject();
	@Output() isScrolled: EventEmitter<boolean> = new EventEmitter<boolean>();

	@HostListener('scroll', ['$event']) scrollHandler(evt: any): void {
		this.source$.next(evt.target.scrollTop);
	}

	constructor() {}

	ngOnInit(): void {
		this.subscription = this.source$.pipe(debounceTime(40)).subscribe((number: number) => {
			this.isScrolled.emit(number > 0);
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
