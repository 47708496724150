import { ApplicationRef, ComponentFactoryResolver, ElementRef, Injectable, Injector } from '@angular/core';
import { ComponentPortal, DomPortalOutlet, Portal, PortalInjector } from '@angular/cdk/portal';

import { SnackbarComponent } from '@app/shared/snackbar/snackbar.component';
import { SnackbarConfig } from '@app/shared/classes/SnackbarConfig';
import { SNACKBAR_DATA } from 'utils/constants/injectortokens';

@Injectable()
export class SnackbarService {
	timer: any;
	portal: Portal<any>;
	constructor(
		private injector: Injector,
		private _componentFactoryResolver: ComponentFactoryResolver,
		private _appRef: ApplicationRef,
	) {}

	private callback(config: SnackbarConfig): void {
		if (config.callback) {
			config.callback();
		}
		this.detach();
	}

	private createInjector(config: SnackbarConfig): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(SNACKBAR_DATA, {
			callback: this.callback.bind(this, config),
			status: config.status,
			text: config.text,
			connectTo: config.connectTo,
		});
		return new PortalInjector(this.injector, injectorTokens);
	}

	private detach(): void {
		this.portal.detach();
		this.portal = null;
		this.clearTimer();
	}

	private clearTimer(): void {
		if (this.timer) {
			clearTimeout(this.timer);
			this.timer = null;
		}
	}

	private startTimer(config: SnackbarConfig): void {
		this.clearTimer();
		this.timer = setTimeout(this.detach.bind(this), config.status === 'success' ? 6000 : 8000);
	}

	create(config: SnackbarConfig): void {
		if (this.portal) {
			this.portal.detach();
			this.portal = null;
		}

		const domPortalOutlet = new DomPortalOutlet(
			config.connectTo
				? config.connectTo.nativeElement
					? config.connectTo.nativeElement
					: config.connectTo
				: document.body,
			this._componentFactoryResolver,
			this._appRef,
			null,
		);
		this.portal = new ComponentPortal(SnackbarComponent, null, this.createInjector(config));
		this.startTimer(config);
		this.portal.attach(domPortalOutlet);
	}

	createDanger(text: string, connectTo?: ElementRef, callback?: any): void {
		this.create({ text, connectTo, callback, status: 'danger' });
	}

	createSuccess(text: string, connectTo?: ElementRef, callback?: any): void {
		this.create({ text, connectTo, callback, status: 'success' });
	}
}
