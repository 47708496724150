import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Component width is inherited so it can be styled if it is given a css class with a width property
 * e.g: html: <shared-input class="some-class" ></shared-input>      css:  .some-class{ width: 100px; }
 */

@Component({
	selector: 'shared-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
	@Input() disabled: boolean;
	@Input() type = 'text';
	@Input() label: string;
	@Input() name: string;
	@Input() maxLength: number;
	@Input() value = '';
	@Input() placeholder: string;
	@Input() autofocus: boolean;
	@Input() externalError = false;
	@Input() errorMessage = '';
	@Input() infoMessage = '';
	@Input() showRemainingChars = false;
	@Input() hidePasswordToggle = false;
	@Output() valueChanged: EventEmitter<{ value: string; isValid: boolean }> = new EventEmitter();
	@Output() focused = new EventEmitter<any>();
	@Output() blurred = new EventEmitter<any>();
	@Input() isEdit = false;
	@ViewChild('inputView', { static: true }) inputView: ElementRef;

	displayPassword = false;
	inputText: string;
	isFocused: boolean;
	hasMaxError: boolean;

	private maxErrorMessage: string;

	get hasError(): boolean {
		return this.hasMaxError || this.externalError || !!this.errorMessage;
	}

	constructor(private translate: TranslateService) {}

	ngOnInit(): void {
		if (this.autofocus) {
			this.inputView.nativeElement.focus();
		}
		this.translate
			.get('DEVICES_PAGE.ADD_DEVICE.VALUE_OVERHEAD_MESSAGE', { value: `${this.name}` })
			.subscribe((res: string) => {
				this.maxErrorMessage = res;
			});
	}

	toggleFocus(ev): void {
		this.isFocused = !this.isFocused;
		if (this.isFocused) this.focused.emit(ev);
		else this.blurred.emit(ev);
	}

	handleChange(event: Event): void {
		const value = (event.target as HTMLInputElement).value;
		this.hasMaxError = this.maxLength !== undefined && value.length > this.maxLength;

		if (this.hasMaxError) {
			this.errorMessage = this.maxErrorMessage;
		} else if (this.errorMessage === this.maxErrorMessage) {
			this.errorMessage = undefined;
		}

		this.valueChanged.emit({
			value,
			isValid: !this.hasError,
		});
	}

	togglePasswordDisplay = (): void => {
		this.displayPassword = !this.displayPassword;
	};
}
