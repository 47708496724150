import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputFieldErrorsModule } from '@n2p/input/input-field-errors/input-field-errors.module';
import { InputHintModule } from '@n2p/input/input-hint/input-hint.module';
import { InputComponent } from './input.component';
import { LineInputModule } from '@app/n2p-components/input/line-input/line-input.module';
import { InputLabelComponent } from './input-label/input-label.component';
import { BorderInputModule } from '@app/n2p-components/input/border-input/border-input.module';
import { InputIconPostComponent } from './input-icon-post/input-icon-post.component';

@NgModule({
	imports: [CommonModule],
	declarations: [InputComponent, InputLabelComponent, InputIconPostComponent],
	exports: [
		LineInputModule,
		BorderInputModule,
		InputComponent,
		InputHintModule,
		InputLabelComponent,
		InputFieldErrorsModule,
		InputIconPostComponent,
	],
})
export class InputModule {}
