import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from '@app/directives/directives.module';
import { ButtonGroupModule } from '@app/n2p-components/button-group/button-group.module';
import { DropdownModule } from '@app/n2p-components/dropdown/dropdown.module';
import { SharedModule } from '@app/shared';
import { DropdownInputModule } from '@n2p/input/dropdown-input/dropdown-input.module';
import { ScheduleIntervalComponent } from './schedule-interval.component';
import { FromToPopupComponent } from './from-to-popup/from-to-popup.component';
import { CalendarPopupComponent } from './calendar-popup/calendar-popup.component';
import { SvgModule } from '@n2p/svg';
import { PipesModule } from '@app/pipes';

@NgModule({
	declarations: [ScheduleIntervalComponent, FromToPopupComponent, CalendarPopupComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		OverlayModule,
		PortalModule,
		DropdownModule,
		ButtonGroupModule,
		SharedModule,
		DirectivesModule,
		DropdownInputModule,
		SvgModule,
		PipesModule,
	],
	exports: [ScheduleIntervalComponent],
	entryComponents: [FromToPopupComponent, CalendarPopupComponent],
})
export class ScheduleIntervalModule {}
