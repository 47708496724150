import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvgModule } from '@n2p';
import { ButtonPlayComponent } from './button-play.component';

@NgModule({
	declarations: [ButtonPlayComponent],
	imports: [CommonModule, SvgModule],
	exports: [ButtonPlayComponent],
})
export class ButtonPlayModule {}
