import { ScrollStrategy } from '@angular/cdk/overlay';

export class BlockDocumentScrollStrategy implements ScrollStrategy {
	static SCROLL_BLOCK_CLASS: string = 'document--scroll-block';

	private attachCount: number = 0;

	constructor(private document: Document = window.document) {}

	attach(): number {
		return this.attachCount++;
	}

	enable(): void {
		this.document.documentElement.classList.add(BlockDocumentScrollStrategy.SCROLL_BLOCK_CLASS);
	}

	disable(): void {
		this.attachCount--;
		if (this.attachCount <= 0) {
			this.document.documentElement.classList.remove(BlockDocumentScrollStrategy.SCROLL_BLOCK_CLASS);
		}
	}
}

export const blockDocumentScrollStrategy = new BlockDocumentScrollStrategy();
