import { Injectable, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { DIALOG_COMPONENT, DIALOG_CONFIG, DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';

import { PromptRef } from './promptRef';
import { PromptComponent } from './prompt.component';
import { PromptConfig } from './promptConfig';

@Injectable()
export class PromptService {
	constructor(private overlay: Overlay, private injector: Injector) {}

	private createInjector(data, ref, component, config): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(DIALOG_DATA, data);
		injectorTokens.set(DIALOG_REF, ref);
		injectorTokens.set(DIALOG_CONFIG, config);
		injectorTokens.set(DIALOG_COMPONENT, component);
		return new PortalInjector(this.injector, injectorTokens);
	}
	create(component, params = null, options: PromptConfig = {}) {
		const config: PromptConfig = {
			...options,
			minWidth: 400,
		};

		config.scrollStrategy = this.overlay.scrollStrategies.reposition();
		config.positionStrategy = this.overlay
			.position()
			.global()
			.centerHorizontally()
			.centerVertically();

		config.hasBackdrop = true;

		const overlayRef = this.overlay.create(config);

		overlayRef.backdropClick().subscribe(() => {});

		const promptRef = new PromptRef(overlayRef);
		const c = new ComponentPortal(PromptComponent, null, this.createInjector(params, promptRef, component, config));
		overlayRef.attach(c);
		return promptRef;
	}
}
