import { FeaturesInfo } from '@app/services/feature-flags/features.domain';
import { ITimezone } from '@app/services/web-apis/timezone/api-timezone.domain';
import { IUserRole } from '@app/services/web-apis/users/api-users.domain';
import { NavigationExtras } from '@angular/router';

type ENV = 'qa' | 'staging' | 'production' | 'development';

export interface IExternalAppConfig {
	env: ENV;
	apiUrl: string;
	authUrl: string;
	accountId: string;
	userId: string;
	userRole: IUserRole;
	lang: string;
	countryCode: string;
	timezone: ITimezone;
	featureFlags: FeaturesInfo;
	baseHref?: string; // `undefined` for local running app
	callbackFunction?: () => void;
	navigate?: (commands: any[], extras?: NavigationExtras) => Promise<boolean>;
	applications?: string[];
	additionalData?: any;
}

export interface IExternalApp {
	initialize: (element: Element, config: IExternalAppConfig) => void;
	destroy: () => void;
}

export const EXTERNAL_APPS_NAMESPACE = 'n2pExternalApps';

export const EXTERNAL_APPS_VENDORS_CHUNK_NAME = 'apps-vendor';
