import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '@app/services/api';
import { IPagingApiResponse, IRegularApiResponse } from '../common-api.domain';
import {
	ISpecialExtensionLight,
	ISpecialExtension,
	ICallForwardRules,
	ICallerIdResponse,
	ICallerId,
} from './api-special-extensions.domain';
import { IDevice } from '../devices/api-devices.domain';

@Injectable()
export class ApiSpecialExtensionsService {
	private baseUrl = '/accounts/{accountId}/specialextensions';

	constructor(private apiService: ApiService, private fb: FormBuilder) {}

	getSpecialExtensions = (): Observable<IRegularApiResponse<ISpecialExtension[]>> =>
		this.apiService.get(`${this.baseUrl}`);

	getAllSpecialExtensionsLW = (
		skip: number = 0,
		take: number = 0,
	): Observable<IPagingApiResponse<ISpecialExtensionLight>> =>
		this.apiService.get(`${this.baseUrl}/light?skip=${skip}&take=${take}`);

	createSpecialExtension = (specialExtension: ISpecialExtension): Observable<IRegularApiResponse<ISpecialExtension>> =>
		this.apiService.post(this.baseUrl, specialExtension);

	saveSpecialExtension = (specialExtension: ISpecialExtension): Observable<IRegularApiResponse<ISpecialExtension>> => {
		return this.apiService.put(`${this.baseUrl}/${specialExtension.id}`, specialExtension);
	};

	removeSpecialExtensions = (id: string): Observable<IRegularApiResponse<string>> => {
		return this.apiService.delete(`${this.baseUrl}/${id}`);
	};

	getCallForwardRules = (extensionId: string): Observable<IRegularApiResponse<ICallForwardRules>> => {
		return this.apiService.get(`${this.baseUrl}/${extensionId}/callForwardRules`);
	};

	updateCallForwardRules = (
		extensionId: string,
		rulesObject: any,
	): Observable<IRegularApiResponse<ICallForwardRules>> =>
		this.apiService.put(`${this.baseUrl}/${extensionId}/callForwardRules`, rulesObject);

	getCallerId = (extensionId: string): Observable<IRegularApiResponse<ICallerIdResponse>> =>
		this.apiService.get(`${this.baseUrl}/${extensionId}/cndinfo`);

	updateCallerId = (extensionId: string, cndObject: any): Observable<IRegularApiResponse<ICallerId>> =>
		this.apiService.put(`${this.baseUrl}/${extensionId}/cnd`, cndObject);

	getExtensionDevice = (extensionId: string): Observable<IRegularApiResponse<IDevice[]>> =>
		this.apiService.get(`${this.baseUrl}/${extensionId}/devices`);

	createDeviceType = (extensionId: string, updatedDevice: any): Observable<IRegularApiResponse<IDevice[]>> =>
		this.apiService.post(`${this.baseUrl}/${extensionId}/devices`, updatedDevice);

	updateDeviceType = (
		extensionId: string,
		updatedDevice: any,
		deviceType: string,
	): Observable<IRegularApiResponse<IDevice[]>> =>
		this.apiService.put(`${this.baseUrl}/${extensionId}/devices/${deviceType}`, updatedDevice);

	updateDevicePassword = (
		extensionId: string,
		deviceId: string,
		newPassword: string,
	): Observable<IRegularApiResponse<IDevice[]>> =>
		this.apiService.put(`${this.baseUrl}/${extensionId}/devices/${deviceId}/savepassword?password=${newPassword}`);
}
