import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { PhoneNumberFormatterPipe } from '@app/pipes';
import { CommonService } from '@app/services';

@Component({
	selector: 'app-welcome-menu-assigned-numbers',
	templateUrl: './welcome-menu-assigned-numbers.component.html',
	styleUrls: ['./welcome-menu-assigned-numbers.component.scss'],
	providers: [PhoneNumberFormatterPipe],
})
export class WelcomeMenuAssignedNumbersComponent implements OnInit, OnDestroy {
	@Input() wm: any;
	@Output() updateAssignedNumber: EventEmitter<any> = new EventEmitter();
	assignedNumbers: FormControl;
	isAdmin: boolean = this.commonService.isAdmin();
	private sub: Subscription;
	constructor(private commonService: CommonService) {}

	assignedNumbersClosed(): void {
		const lines = [];
		this.assignedNumbers.value.forEach(line => {
			if (!this.wm.lines.find(l => l.lineId === line)) {
				lines.push({
					lineId: line,
					status: 'A',
				});
			}
		});
		this.wm.lines.forEach(line => {
			if (!this.assignedNumbers.value.find(l => l === line.lineId)) {
				lines.push({
					lineId: line.lineId,
					status: 'D',
				});
			}
		});

		if (lines.length) {
			const restLines = this.wm.lines
				.filter(l => lines.every(line => line.lineId !== l.lineId))
				.map(l => ({ lineId: l.lineId, status: 'A' }));
			this.updateAssignedNumber.emit([...lines, ...restLines]);
		}
	}

	ngOnInit(): void {
		this.assignedNumbers = new FormControl(this.wm.lines.map(line => line.lineId));

		this.sub = this.assignedNumbers.valueChanges.subscribe(() => this.assignedNumbersClosed());
	}

	ngOnDestroy(): void {
		this.sub && this.sub.unsubscribe();
	}
}
