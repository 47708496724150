import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { GlobalLoaderService, Loader, ApiPhoneNumbersService } from '@app/services';
import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { IPhone } from '@app/Common/interfaces/IPhone';
import { AssignedNumbersChipComponent } from '@app/pages/welcomemenu/addwelcomemenu/assigned-numbers/assigned-numbers-chip/assigned-numbers-chip.component';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { PhoneNumberFormatterPipe } from '@app/pipes';

@Component({
	selector: 'app-remove-phone-number-dialog',
	templateUrl: './remove-phone-number-dialog.component.html',
	styleUrls: ['./remove-phone-number-dialog.component.scss'],
	providers: [GlobalLoaderService],
})
export class RemovePhoneNumberDialogComponent implements OnInit, OnDestroy {
	phoneNumbers: Array<IPhone> = [];
	phone: IPhone;
	formGroup: FormGroup;
	chipComponent = AssignedNumbersChipComponent;
	showAssignDropdown = false;
	removing = false;
	newCallerIds: Array<any>;
	loader: Loader;
	constructor(
		@Inject(DIALOG_DATA) data: { phone: IPhone; index: number },
		private fb: FormBuilder,
		private phoneNumberService: ApiPhoneNumbersService,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		private translateService: TranslateService,
		private globalLoaderService: GlobalLoaderService,
		private phoneNumberFormatter: PhoneNumberFormatterPipe,
		private hostElem: ElementRef,
	) {
		this.phone = data.phone;

		this.formGroup = this.fb.group({
			name: [this.phone.routesTo],
			type: [this.phone.routeType],
			lineNumbers: [(data.phone['lineNumbers'] || []).filter(i => i.number !== data.phone.number)],
			assignedNumbers: [[{ number: this.translateService.instant('PHONE_NUMBERS_PAGE.UNASSIGNED') }]],
		});

		this.showAssignDropdown = this.formGroup.get('lineNumbers').value <= 1 && !!this.phone.routesTo;
	}

	findIndex(selected: Array<IPhone>, phone: IPhone): number {
		return selected.findIndex(p => p.number === phone.number);
	}

	chipRemoved(phone: IPhone): void {
		const index = this.phoneNumbers.findIndex(p => p.number === phone.number);
		this.phoneNumbers[index].selected = false;
		if (!this.phoneNumbers.some(p => p.selected)) {
			setTimeout(() =>
				this.formGroup
					.get('assignedNumbers')
					.setValue([{ number: this.translateService.instant('PHONE_NUMBERS_PAGE.UNASSIGNED') }]),
			);
		}
	}

	remove(): void {
		const assignedNumbers = this.formGroup
			.get('assignedNumbers')
			.value.filter(p => p.number !== this.translateService.instant('PHONE_NUMBERS_PAGE.UNASSIGNED'));
		if (assignedNumbers.length) {
			this.removing = true;
			assignedNumbers.forEach((i: IPhone) => {
				i.routesTo = this.phone.routesTo;
				i.userInfo = this.phone.userInfo || undefined;
				i.routeToId = this.phone.routeToId;
				i.routeType = this.phone.routeType;

				const model = { ...i, routesTo: i.routeToId.toString() };
				this.phoneNumberService.updatePhoneNumber(model).subscribe();
			});
			this.doRemoveAction();
		} else {
			this.doRemoveAction();
		}
	}

	cancel(success: boolean = false): void {
		this.dialogRef.dismiss(success);
	}

	ngOnInit(): void {
		this.loader = this.globalLoaderService.create(this.hostElem);
		this.loader.show();
		this.phoneNumberService.getCallerIdUsers(this.phone.number).subscribe(res => {
			if (!res.hasError) this.newCallerIds = res.data;
			this.loader.hide();
			this.loader = undefined;
		});
	}

	ngOnDestroy(): void {
		if (this.loader) this.loader.hide();
	}

	private doRemoveAction(): void {
		this.removing = true;
		this.phoneNumberService
			.deletePhoneNumber(this.phone.number)
			.toPromise()
			.then(
				(res: any) => {
					this.removing = false;
					this.cancel(true);
				},
				() => {
					this.removing = false;
					this.cancel(false);
				},
			);
	}

	getWarningMessage(): string {
		return !this.phone.pendingNumber
			? this.translateService.instant('PHONE_NUMBERS_PAGE.REMOVE_NUMBER.WARNING')
			: this.translateService.instant('PHONE_NUMBERS_PAGE.REMOVE_NUMBER.HAS_TEMPORARY_NUMBER', {
					number: this.phoneNumberFormatter.transform(this.phone.number),
					portingNumber: this.phoneNumberFormatter.transform(this.phone.pendingNumber),
			  });
	}
}
