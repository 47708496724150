import { Component, OnInit, Input, Inject, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { SnackbarService } from '@app/services';
import { DIALOG_REF } from 'utils/constants/injectortokens';
import { ChipComponent } from '@app/shared';
import { DepartmentService } from '@app/services/department/department.service';
import { IRegularApiResponse, IErrorMessage } from '@app/services/web-apis/common-api.domain';
import { IExtendedUser } from '@app/services/web-apis/users/api-users.domain';
import { IExtendedDepartment } from '@app/services/web-apis/departments/api-departments.domain';
import { IAccountPhone } from '@app/services/web-apis/phone-numbers/api-phone-numbers.domain';
import {
	DeleteEntityModel,
	DialogDeleteEntityComponent,
} from '@n2p/dialog/dialog-delete-entity/dialog-delete-entity.component';
import { DialogService } from '@n2p';

@Component({
	selector: 'app-department-tab',
	templateUrl: './department-tab.component.html',
	styleUrls: ['./department-tab.component.scss'],
})
export class DepartmentTabComponent implements OnInit, OnDestroy {
	@Input() public model: any;
	@Input() public numbers: IAccountPhone[];
	@Input() public users: IExtendedUser[];
	@Input() public isCallRecordingActive: boolean;
	public chipComponent: any = ChipComponent;
	public bounceMethod: any;
	public departmentForm: FormGroup;
	departmentLabel: string = this.translate.instant('DEPARTMENTS.DEPARTMENT');
	@Output() saveDepartment: EventEmitter<{ data: any; members: IExtendedUser[] } | Event> = new EventEmitter();
	@Output() saveNumberChanges: EventEmitter<{ numbers: string[] }> = new EventEmitter();
	@Output() saveMemberChanges: EventEmitter<{ members: IExtendedUser[] } | Event> = new EventEmitter();
	@Output() saveCallRecordingChanges: EventEmitter<boolean> = new EventEmitter();

	private subscription: Subscription;

	constructor(
		@Inject(DIALOG_REF) private dialogRef: any,
		private dialogService: DialogService,
		private fb: FormBuilder,
		private deptService: DepartmentService,
		private snackbarService: SnackbarService,
		private translate: TranslateService,
	) {
		this.departmentForm = this.fb.group({
			name: ['', [Validators.required, Validators.maxLength(30)]],
			extension: ['', Validators.required],
			assignedNumbers: [[]],
			assignedTo: [[]],
		});
	}

	_departmentMembers: IExtendedUser[] = [];

	get departmentMembers(): IExtendedUser[] {
		return this._departmentMembers;
	}

	@Input() set departmentMembers(value: IExtendedUser[]) {
		if (value) {
			this._departmentMembers = value;
			this.departmentForm.get('assignedTo').setValue(value);
		}
	}

	_departmentNumbers: string[] = [];

	get departmentNumbers(): any[] {
		return this._departmentNumbers;
	}

	@Input() set departmentNumbers(value: any[]) {
		if (value) {
			this._departmentNumbers = value;
			this.departmentForm.get('assignedNumbers').setValue(value);
		}
	}

	ngOnInit(): void {
		this.departmentForm.setValue({
			name: this.model.name,
			extension: this.model.extension,
			assignedNumbers: this._departmentNumbers,
			assignedTo: this._departmentMembers,
		});

		this.subscribeAssignedNumberChanges();
	}

	ngOnDestroy(): void {
		this.subscription && this.subscription.unsubscribe();
	}

	nameChange(event: any): void {
		if (event.value && event.value.trim()) {
			const leftTrimmedName = event.value.replace(/^\s+/, '');
			this.departmentForm.patchValue({ name: leftTrimmedName });
			this.debounceSave();
		} else {
			clearTimeout(this.bounceMethod);
		}
	}

	memberSelect(event: any): void {
		this.saveMemberChanges.emit({ members: this.departmentForm.get('assignedTo').value });
	}

	debounceSave(): void {
		if (this.bounceMethod) {
			clearTimeout(this.bounceMethod);
		}

		this.bounceMethod = setTimeout(() => {
			if (this.departmentForm.valid) {
				this.saveDepartment.emit({
					data: this.departmentForm,
					members: this.departmentMembers,
				});
			}
		}, 300);
	}

	deleteDepartmentModal(dept: IExtendedDepartment): void {
		const lines = dept.lineNumber
			? dept.lineNumber.map((l: string) => {
					return { lineId: l, number: l };
			  })
			: [];
		const accountItem = {
			...dept,
			lines,
			id: dept.deptId,
			textType: this.translate.instant('DEPARTMENTS.DEPARTMENT'),
			filteringType: 'department',
		};
		const deleteEntityModel: DeleteEntityModel = {
			entityType: 'department',
			assignedNumbers: lines,
			entity: dept,
			entityId: dept.deptId,
			entityName: dept.name,
		};
		const dialogRef = this.dialogService.create(DialogDeleteEntityComponent, deleteEntityModel, {
			width: 542,
		});
		dialogRef.onDismiss().subscribe((res: unknown) => {
			if (res) {
				this.deptService.deleteDepartment(dept.deptId).subscribe(
					(res: IRegularApiResponse<unknown>) => {
						// failure state
						if (res.hasError || res.errorMessages.length) {
							let errors = res.errorMessages.map((r: IErrorMessage) => r.message).join(' ');
							this.snackbarService.create({
								status: 'danger',
								text: errors,
								connectTo: null,
							});
						} else {
							// success state
							this.snackbarService.create({
								status: 'success',
								text: this.translate.instant('DEPARTMENTS.DELETED'),
								connectTo: null,
							});
							this.closeModal();
						}
					},
					(res: IRegularApiResponse<unknown>) => {
						const errors = res.errorMessages.map((r: IErrorMessage) => r.message).join(' ');
						this.snackbarService.create({
							status: 'danger',
							text: errors,
							connectTo: null,
						});
					},
				);
			}
		});
	}

	callRecordingToggled(e: any): void {
		this.saveCallRecordingChanges.emit(e);
	}

	closeModal(): void {
		this.dialogRef.dismiss();
	}

	private subscribeAssignedNumberChanges(): void {
		const assignedNumbersControl = this.departmentForm.get('assignedNumbers');

		this.subscription = assignedNumbersControl.valueChanges.subscribe(numbers => {
			if (assignedNumbersControl.touched) {
				this.saveNumberChanges.emit({ numbers });
			}
		});
	}
}
