import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import { TranslateService } from '@ngx-translate/core';

import { ExtensionTypes } from '@app/services';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';

@Component({
	selector: 'n2p-input-extension',
	templateUrl: './input-extension.component.html',
	styleUrls: ['./input-extension.component.scss'],
})
export class InputExtensionComponent implements OnInit {
	@Input() extensionControl: FormControl;
	@Input() type: ExtensionTypes;
	@Input() testId: string = 'extension';

	@Output() changeAndValid: EventEmitter<void> = new EventEmitter<void>();
	@Output() validating: EventEmitter<boolean> = new EventEmitter<boolean>();

	isEdit: boolean;
	refreshExtSubject: Subject<void> = new Subject();
	loading: boolean;
	public placeholder: string = '';
	constructor(private translate: TranslateService, private apiTenantService: ApiTenantService) {}

	refreshExt(): void {
		this.refreshExtSubject.next();
	}

	get extensionErrorTooltip(): number {
		return this.translate.instant('EXTENSION.INVALID_MESSAGE', {
			amount: this.apiTenantService.accountMaxExtensionLength,
		});
	}

	isLoading(loading: boolean): void {
		this.loading = loading;
		this.validating.emit(loading);
		// Hack for resolving `ExpressionChangedAfterItHasBeenCheckedError` error
		setTimeout(() => {
			this.placeholder = loading ? this.translate.instant('EXTENSION.LOADING') : '';
		});
	}

	ngOnInit(): void {
		this.isEdit = !!this.extensionControl.value;
	}
}
