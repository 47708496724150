import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '@app/services/api/api.service';
import { IRegularApiResponse } from '../common-api.domain';
import { IAudioContent } from '@app/services/web-apis/audio/api-audio.domain';
import { MusicAudioType } from './api-music-options.constant';

@Injectable()
export class ApiMusicOptionsService {
	constructor(private api: ApiService) {}

	getAccountAudioFile(
		audioType: MusicAudioType.MOH | MusicAudioType.MOF,
	): Observable<IRegularApiResponse<IAudioContent>> {
		return this.api.get(`/accounts/{accountId}/audio?audioType=${audioType}`);
	}

	resetAccountAudioFile(audioType: MusicAudioType.MOH | MusicAudioType.MOF): Observable<IRegularApiResponse<string>> {
		return this.api.put(`/accounts/{accountId}/audio?audioType=${audioType}`);
	}

	getUserAudioFile(
		userId: string | number,
		audioType: MusicAudioType.MOH | MusicAudioType.VM,
	): Observable<IRegularApiResponse<IAudioContent>> {
		return this.api.get(`/accounts/{accountId}/users/${userId}/audio?audioType=${audioType}`);
	}

	resetUserAudioFile(
		userId: string | number,
		audioType: MusicAudioType.MOH | MusicAudioType.VM,
	): Observable<IRegularApiResponse<string>> {
		return this.api.post(`/accounts/{accountId}/users/${userId}/audio/reset?audioType=${audioType}`);
	}

	uploadMediaFile(
		audioContent: string,
		fileName: string,
		contentType: string,
	): Observable<IRegularApiResponse<IAudioContent>> {
		return this.api.post('/accounts/{accountId}/mediaservice/audio', {
			content: audioContent,
			name: fileName,
			contentType,
		});
	}

	uploadMediaFormData(content: FormData): Observable<IRegularApiResponse<IAudioContent>> {
		return this.api.post('/accounts/{accountId}/media', content);
	}
}
