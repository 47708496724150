import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { ApiUsersService, CacheService } from '@app/services';
import { IPagingApiResponse } from '@app/services/web-apis/common-api.domain';
import { IUserLight } from '@app/services/web-apis/users/api-users.domain';
import { DropdownData } from '../dropdown/dropdown.component';

@Component({
	selector: 'n2p-extension-form',
	templateUrl: './extension-form.component.html',
	styleUrls: ['./extension-form.component.scss'],
})
export class ExtensionFormComponent implements OnInit {
	@Output() submitForm: EventEmitter<string> = new EventEmitter();
	@Output() cancelForm: EventEmitter<string> = new EventEmitter();

	@Input() forwardingNumberlist: string[];
	@Input() userToEditId: number;

	data: DropdownData[];
	selectedTeamMembers: DropdownData[];
	CACHE_USERS_KEY: string = 'team_members_light';
	isDuplicateNumber: boolean = false;

	constructor(private apiUserService: ApiUsersService, private cacheService: CacheService) {}

	ngOnInit(): void {
		const cachedData = this.cacheService.get(this.CACHE_USERS_KEY);
		if (cachedData) {
			this.setUsersData(cachedData);
		} else {
			this.apiUserService.getAllUsersLW().subscribe((res: IPagingApiResponse<IUserLight>) => {
				if (res && res.data && res.data.items) {
					this.cacheService.set(this.CACHE_USERS_KEY, res.data.items);
					this.setUsersData(res.data.items);
				}
			});
		}
	}

	setUsersData(data: IUserLight[]): void {
		this.data = data
			.filter(i => i.userId !== this.userToEditId)
			.map(i => ({ value: `${i.firstName} ${i.lastName}`, subValue: i.extension }));
	}

	onSubmit(e: Event): void {
		if (this.selectedTeamMembers) {
			this.submitForm.emit(this.selectedTeamMembers[0].subValue);
		}
	}

	onCancel(e: Event): void {
		this.cancelForm.emit();
	}

	saveSelectedTeamMembers = (selectedItem: DropdownData[]): void => {
		this.isDuplicateNumber = selectedItem && this.forwardingNumberlist.some(i => i === selectedItem[0].subValue);

		this.selectedTeamMembers = this.isDuplicateNumber ? undefined : selectedItem; //clearing previous value for dup case
	};
}
