import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EscapeHandlerDirective } from './escape-handler.directive';

const directives = [EscapeHandlerDirective];

@NgModule({
	imports: [CommonModule],
	declarations: directives,
	exports: directives,
})
export class EscapeHandlerModule {}
