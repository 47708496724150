import { Component, Input } from '@angular/core';

import { getInitialsFromString } from 'utils/helpers/dropdown';

// this component only pertains to the icons shown in callhistory, there are a possible maximum of three stacked icons
// see designs for callhistory for reference

// TODO: refactor to simplify logic once call server changes are made and cdrs are accurately represented in cdr json

@Component({
	selector: 'app-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
	public type: any;
	@Input('type') set _type(type: string) {
		const types = {
			user: {
				primary: 'user',
			},
			'dep-user': {
				primary: 'user',
				secondary: 'department',
			},
			'menu-user': {
				primary: 'user',
				secondary: 'welcome-menu',
			},
			'rg-user': {
				primary: 'user',
				secondary: 'ring-group',
			},
			'special-extension': {
				primary: 'special-extension',
			},
			'ring-group': {
				primary: 'ring-group',
			},
			'welcome-menu': {
				primary: 'welcome-menu',
			},
			department: {
				primary: 'department',
			},
		};
		this.type = types[type || 'user'];
	}
	@Input() textPrimary?: string;
	@Input() textSecondary?: string;
	@Input() imgUrl: string;
	@Input() allowMultiple = true;
	@Input() showNew: boolean;

	public getInitialsFromString: Function = getInitialsFromString;

	constructor() {}

	get shouldShowImage() {
		return this.imgUrl !== undefined && this.imgUrl !== '';
	}

	get shouldShowIcon() {
		return !this.shouldShowImage && !this.textPrimary && !this.textSecondary;
	}

	get shouldShowInitialsPrimary() {
		return !this.shouldShowImage && !this.shouldShowIcon && this.textPrimary;
	}

	get shouldShowInitialsSecondary() {
		return this.allowMultiple && this.textSecondary;
	}
}
