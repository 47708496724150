import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '@app/services/api';

import { ICallQueueListResponse } from '@app/services/web-apis/call-queues/api-call-queues.domain';

@Injectable()
export class ApiCallQueuesService {
	private baseUrl: string = '/v2/call-queues';

	constructor(private apiService: ApiService) {}

	getAllCallQueues(): Observable<ICallQueueListResponse> {
		return this.apiService.get(`${this.baseUrl}`, {
			limit: 100,
		});
	}
}
