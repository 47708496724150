import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { interval } from 'rxjs/observable/interval';
import { finalize } from 'rxjs/operators/finalize';
import { dateSubtract, DateUnit, isSameDay } from 'n2p-ui-library/utils/date.util';

import { ApiService } from '@app/services/api/api.service';
import { Analytic, GraphAnalytic, Report, ReportInterval, ReportQuery, ReportType } from '@app/pages/analytics/models';
import { avatarTypes, MAX_ROWS } from '@app/Common';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { AuthStorageData } from '@app/services/token';

@Injectable({
	providedIn: 'root',
})
export class ApiAnalyticsService implements OnDestroy {
	private baseUrl: string = '/analytics/accounts/{accountId}';
	private _pollingIntervalMinutes: number = 0;

	report: Report = this.getInitialReport();

	private readonly typeMap = {
		department: 'departments',
		'team-member': 'users',
		'ring-group': 'ringGroups',
		'welcome-menu': 'welcomeMenus',
	};

	// observable source
	private reloadSource: Subject<void> = new Subject();

	// observable stream
	readonly reload: Observable<void> = this.reloadSource.asObservable();

	// loading for disabling selector
	private _loading: boolean = false;
	get loading(): boolean {
		return this._loading;
	}

	// long polling
	private pollingSubscription: Subscription;

	constructor(private apiService: ApiService, private authStorageDataService: AuthStorageData) {
		this.updateData();
	}

	ngOnDestroy(): void {
		this.removePolling();
	}

	get isHourly(): boolean {
		const start: Date = this.report.startDate ? new Date(this.report.startDate) : new Date();
		const end: Date = this.report.endDate ? new Date(this.report.endDate) : new Date();

		return this.report.type === ReportType.TODAY || isSameDay(start, end) || start.toISOString() === end.toISOString();
	}

	get query(): ReportQuery {
		const start = this.report.startDate ? new Date(this.report.startDate) : new Date();
		start.setHours(0, 0, 0, 0);
		const end = this.report.endDate ? new Date(this.report.endDate) : new Date();
		end.setHours(23, 59, 59, 999);
		return {
			startDate: start.toISOString(),
			endDate: end.toISOString(),
		};
	}

	get queryWithInterval(): ReportQuery {
		return {
			...this.query,
			interval: this.isHourly ? ReportInterval.HOURLY : ReportInterval.DAILY,
		};
	}

	set pollingIntervalMinutes(min: number) {
		// remove current polling interval
		this.removePolling();

		// reload so user knows something happened
		this.reloadSource.next();

		// set instance var
		this._pollingIntervalMinutes = min;

		// only add new polling interval if it's defined
		if (!min) return;

		// set new polling interval
		this.pollingSubscription = interval(min * 60 * 1000).subscribe(() => {
			if (!this._loading) {
				this.reloadSource.next();
			}
		});
	}

	get pollingIntervalMinutes(): number {
		return this._pollingIntervalMinutes;
	}

	private removePolling(): void {
		if (this.pollingSubscription) {
			this.pollingSubscription.unsubscribe();
		}
	}

	updateData(): void {
		this.authStorageDataService.analyticsReportType = this.report;
		this.reloadSource.next();
	}

	getAccountData(): Observable<IRegularApiResponse<Analytic>> {
		this._loading = true;
		return this.apiService.get(this.baseUrl, this.queryWithInterval).pipe(
			finalize(() => {
				this._loading = false;
			}),
		);
	}

	getActiveData(type: avatarTypes): Observable<IRegularApiResponse<Array<GraphAnalytic>>> {
		return this.apiService.get(`${this.typePath(type)}/active`, this.query);
	}

	getTableData(type: avatarTypes, page = 1): Observable<IRegularApiResponse<Analytic>> {
		const query = {
			...this.query,
			skip: (page - 1) * MAX_ROWS,
			take: MAX_ROWS,
		};

		return this.apiService.get(this.typePath(type), query);
	}

	getSummaryData(type: avatarTypes): Observable<IRegularApiResponse<Analytic>> {
		return this.apiService.get(`${this.typePath(type)}/summary`, this.query);
	}

	private typePath(type: avatarTypes): string {
		return `${this.baseUrl}/${this.typeMap[type]}`;
	}

	private getInitialReport(): Report {
		const storedReport: Report = this.authStorageDataService.analyticsReportType;
		if (storedReport && typeof storedReport === 'object') {
			const startDate = new Date(storedReport.startDate);
			const endDate = new Date(storedReport.startDate);
			storedReport.startDate = isNaN(startDate.getTime()) ? new Date() : startDate;
			storedReport.endDate = isNaN(endDate.getTime()) ? new Date() : endDate;
		}

		if (!storedReport) {
			return ApiAnalyticsService.createStandardReport();
		}

		if (storedReport.type === ReportType.CUSTOM) {
			return storedReport;
		}

		return ApiAnalyticsService.createStandardReport(storedReport.type);
	}

	static createStandardReport(type: ReportType = ReportType.TODAY): Report {
		let startDate: Date;

		switch (type) {
			case ReportType.TODAY:
				startDate = new Date();
				break;
			case ReportType.WEEKS_1:
				startDate = dateSubtract(new Date(), 1, DateUnit.WEEK);
				break;
			case ReportType.WEEKS_2:
				startDate = dateSubtract(new Date(), 2, DateUnit.WEEK);
				break;
			case ReportType.MONTH:
				startDate = dateSubtract(new Date(), 1, DateUnit.MONTH);
				break;
			default:
				break;
		}

		return {
			type,
			endDate: new Date(),
			startDate: startDate,
		};
	}
}
