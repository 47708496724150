import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ApiDirectoriesService, FeatureFlagsService, SnackbarService } from '@app/services';
import { DIALOG_DATA, DIALOG_REF } from '@n2p/dialog/dialog.injectors';

import { formatBytes } from '@utils/helpers/functions';
import { IAudio } from '@app/Common';
import { FileSizePipe } from '@app/pipes/file-size/file-size.pipe';

@Component({
	selector: 'n2p-file-uploader-popup',
	templateUrl: './file-uploader-popup.component.html',
	styleUrls: ['./file-uploader-popup.component.scss'],
})
export class FileUploaderPopupComponent implements OnInit {
	uploading: boolean = false;

	maxFileSize: number = 4e6;
	title: string;
	subtitle: string;
	fileType: 'image' | 'audio' = 'audio';
	uploadAction: string;
	userId: string;
	audioContent: string;
	error: string;

	file: File;
	oldUrlFile: IAudio;
	// array of valid file extensions. Set in ngOnInit
	private validFileTypes: Array<string>;
	// formatted string of valid file extensions. Set in ngOnInit
	private validFileTypesFormatted: string;

	@ViewChild('fileInput', { static: true }) fileInput: ElementRef;
	@ViewChild('bodySectionRef', { static: true }) bodySectionRef: ElementRef;

	constructor(
		@Inject(DIALOG_DATA) private dialogData: any,
		@Inject(DIALOG_REF) private dialogRef: any,
		// TODO need scalable options for all calls
		private apiDirectory: ApiDirectoriesService,
		private snackbarService: SnackbarService,
		private translateService: TranslateService,
		private featureFlagsService: FeatureFlagsService,
	) {
		if (this.dialogData) {
			const { title, subtitle, maxFileSize, fileType, uploadAction, userId } = this.dialogData;

			// required
			this.title = title;
			this.uploadAction = uploadAction;
			this.userId = userId;

			if (!this.title) {
				throw Error('title is required');
			}

			if (!this.uploadAction) {
				throw Error('uploadAction is required');
			}

			// optional
			if (subtitle) this.subtitle = subtitle;
			if (maxFileSize) this.maxFileSize = maxFileSize;
			if (fileType) this.fileType = fileType;
		}
	}

	ngOnInit(): void {
		// set valid file extensions
		this.validFileTypes = {
			audio: ['mp3', 'm4a', 'wav'],
			image: ['png', 'jpg', 'jpeg'],
		}[this.fileType];
		this.validFileTypesFormatted = this.validFileTypes.map(ext => `.${ext}`).join(', ');
	}

	/**
	 * Event handler for file added via drag and drop or file selections
	 * @param event
	 */
	handleFileChange(event: any): void {
		const files = event instanceof FileList ? event : event.target.files;
		if (!files || !files.length) return;

		this.file = files[0];

		// reset errors
		this.error = undefined;

		// validate file size
		if (this.file.size > this.maxFileSize) {
			this.error = this.translateService.instant('GLOBALS.MOH.FILE_TOO_LARGE', {
				size: new FileSizePipe().transform(this.maxFileSize),
			});
		}

		// validate file type
		const fileType = this.file.name
			.toString()
			.split('.')
			.pop()
			.toLowerCase();
		if (this.validFileExtensions.filter(ext => fileType.includes(ext)).length < 1) {
			this.error = this.translateService.instant('VALIDATION_ERRORS.INVALID_FILE_FORMAT');
		}

		// remove file if it has an error
		if (this.error) {
			this.file = undefined;
		}
	}

	handleUploadClick(): void {
		if (!this.file) return;

		this.uploading = true;

		this.uploadFile();
	}

	uploadFile(): void {
		const args: Array<IAudio | string> = [this.oldUrlFile];
		if (this.userId) args.push(this.userId);

		const source$ = this.apiDirectory[this.uploadAction](this.file, this.userId);

		source$.subscribe(res => {
			if (res.hasError) {
				this.snackbarService.create({
					status: 'danger',
					text: this.translateService.instant('DIRECTORIES_PAGE.EDIT.GREETING_UPLOAD_FAIL'),
					connectTo: this.bodySectionRef,
				});
			} else {
				this.uploading = false;
				this.closeModal(true);
			}
		});
	}

	clearFile(): void {
		this.oldUrlFile = undefined;
		this.file = undefined;
	}

	closeModal(value: boolean = false): void {
		if (!this.uploading) this.dialogRef.dismiss(value ? this.file : undefined);
	}

	get readableMaxFileSize(): string {
		return formatBytes(this.maxFileSize);
	}

	get validFileExtensions(): string[] {
		return this.validFileTypes;
	}

	get acceptFileTypes(): string {
		return this.validFileTypesFormatted;
	}

	get isAudio(): boolean {
		return this.fileType === 'audio';
	}
}
