import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@app/shared';
import { HorizontalSelectModule } from '@n2p/horizontal-select/horizontal-select.module';
import { CallBlockingAddNewComponent } from '@n2p/call-blocking-add-new/call-blocking-add-new.component';
@NgModule({
	imports: [ReactiveFormsModule, TranslateModule, SharedModule, HorizontalSelectModule],
	declarations: [CallBlockingAddNewComponent],
	exports: [CallBlockingAddNewComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CallBlockingAddNewModule {}
