import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { getInitialsFromString } from 'utils/helpers/dropdown';
import * as ListItemTypes from '@app/Common/constants/entity-types';
import { DropdownService } from '../dropdown/dropdown.service';

interface ListItem {
	type: string;
	text: string;
	imgUrl?: string;
	iconName?: string;
	children: ListItem[];
	parentList: ListItem[];
	index: number;
	listId: string;
}

@Component({
	selector: 'shared-listitem',
	templateUrl: './listitem.component.html',
	styleUrls: ['./listitem.component.scss'],
})
export class ListitemComponent implements OnInit {
	@Input() item: ListItem;
	@Input() addedListItems: ListItem[] = [];
	@Input() parentList: ListItem[];
	@Input() selectedItems: ListItem[]; //all leafs the user has selected
	@Input() showChildren = false;
	@Input() position = 'right';
	@Input() index: number;

	activeChildItemIndex: number;
	text: string;
	isLeaf: boolean;
	type: string;
	children: object[];
	isFirstItem: boolean;
	isLastItem: boolean;
	imgUrl: string;
	iconName: string;
	getInitialsFromString: Function = getInitialsFromString;

	@Output() private innerSelectedEmitter: EventEmitter<ListItem> = new EventEmitter();
	@Output() itemSelected: EventEmitter<ListItem> = new EventEmitter();

	constructor(private dropdownService: DropdownService) {}

	ngOnInit() {
		this.isFirstItem = this.index === 0;
		this.isLastItem = this.index === this.parentList.length - 1;
		this.type = this.item.type;
		this.text = this.item.text;
		this.imgUrl = this.item.imgUrl;
		this.iconName = this.item.iconName;
		this.isLeaf = !this.item.children || (this.item.children && !this.item.children.length);
		this.children = this.item.children;
	}

	get showOnlyText(): boolean {
		return this.type === ListItemTypes.RING_COUNT;
	}

	get shouldShowIcon(): boolean {
		return this.iconName !== undefined && this.iconName !== '';
	}

	get shouldShowImage(): boolean {
		return this.imgUrl !== undefined && this.imgUrl !== '';
	}

	get shouldShowInitials(): boolean {
		return !this.shouldShowIcon && !this.shouldShowImage && this.text !== undefined && this.text !== '';
	}

	get isAddedItem(): boolean {
		return this.addedListItems.some(i => i.listId === this.item.listId && this.item.listId !== undefined);
	}

	setActiveListItemIndex(event: any, index: number, childItem: ListItem) {
		//if item was clicked and it was already active, hide it, else switch item index
		this.activeChildItemIndex = index !== this.activeChildItemIndex ? index : undefined;

		//let's prevent this event from also being handled by the parent
		event.stopPropagation();
	}

	addOrRemoveItem() {
		if (this.isLeaf && this.item.listId !== undefined) {
			this.dropdownService.addOrRemoveItem({
				...this.item,
				listId: this.item.listId,
			});
		}
	}

	toggleChildren(value: boolean) {
		if (value !== undefined) this.showChildren = value;
		else this.showChildren = !this.showChildren;
	}
}
