import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { getInitialsFromNames } from 'n2p-ui-library';

import { DIALOG_DATA, DIALOG_REF } from '@n2p/dialog/dialog.injectors';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { IVoicemailObject } from '@n2p/edit-voicemail/edit-voicemail.interfaces';
import { FeatureFlagsService, JwtService } from '@app/services';
import { CallOptionsService } from '@app/pages/teammembers/call-options/call-options.service';

@Component({
	selector: 'app-edit-team-member',
	templateUrl: './edit-team-member.component.html',
	moduleId: module.id,
	styleUrls: ['./edit-team-member.component.scss'],
})
export class EditTeamMemberComponent implements OnInit {
	@ViewChild('editTeamMemberHeader', { static: true }) header: ElementRef;
	@ViewChild('editTeamMemberBody', { static: true }) body: ElementRef;
	licensesEnabled: Observable<boolean>;
	showTeamMemberCustomMOHUpload: Observable<boolean>;
	selectedTab = 'profile';
	showVMContent = false;
	editUserId = '';
	user = this.data;
	canCreateDidlessUsers: boolean;
	isSipTrunkingApp: boolean;
	public dataLoading: boolean = false;

	constructor(
		@Inject(DIALOG_DATA) private data: any,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		featureFlagService: FeatureFlagsService,
		private callOptionsService: CallOptionsService,
		private jwtService: JwtService,
	) {
		this.isSipTrunkingApp = this.jwtService.hasPolicy('siptrunk.account.only');
		this.editUserId = this.user.userId;
		this.canCreateDidlessUsers = this.user.canCreateDidlessUsers;
		this.licensesEnabled = featureFlagService.webAppLicenseManagement();
		this.showTeamMemberCustomMOHUpload = featureFlagService.showTeamMemberCustomMOHUpload();
	}

	ngOnInit(): void {
		this.callOptionsService.userId = this.editUserId;
	}

	changeTab(tab: string) {
		this.selectedTab = tab;
	}

	/**
   Play/Pause audio player
   */
	ShowHidePlayer(showStatus) {
		this.showVMContent = showStatus;
	}

	onVoicemailUpdate(settings: IVoicemailObject): void {
		this.user.voicemailEnabled = settings.isVoicemailEnabled;
		this.user.voicemailNotification.emailNotify = settings.isNotificationEnabled;
		this.user.voicemailNotification.emailIncludeVM = settings.isFileIncluded;
		this.user.voicemailNotification.emailTranscribe = settings.isTranscriptIncluded;
		this.user.voicemailNotification.emailIncludeCallerDetails = settings.isCallerDetailsIncluded;
	}

	get userName(): string {
		const { firstName, lastName } = this.user;
		return [firstName, lastName].join(' ');
	}

	get iconText(): string {
		const { firstName, lastName } = this.user;
		return getInitialsFromNames(firstName, lastName);
	}
}
