import { Injectable } from '@angular/core';

export type FaviconsType = 'default' | 'rcon';

@Injectable()
export class FaviconsService {
	private readonly favicons: Record<FaviconsType, string>;
	private readonly node: HTMLLinkElement;

	constructor() {
		this.node = document.head.querySelector('link[rel="icon"]') as HTMLLinkElement;
		this.favicons = { default: this.node.href, rcon: '/images/rcon-favicon.svg' };
	}

	use(type: FaviconsType): void {
		this.node.setAttribute('href', this.favicons[type]);
	}
}
