import { Component, Input } from '@angular/core';

import { Alignment } from '@n2p/common';

@Component({
	selector: 'n2p-th',
	templateUrl: './table-header.component.html',
	styleUrls: ['./table-header.component.scss'],
})
export class TableHeaderComponent {
	@Input() align: Alignment = Alignment.RIGHT;
	@Input() text: string;
}
