import {
	VALID_SPECIAL_EXTENSION,
	VALID_CALL_QUEUE,
	VALID_TEAM_MEMBER,
	VALID_DEPARTMENT,
	VALID_RING_GROUP,
	VALID_WELCOME_MENU,
	CALL_QUEUE,
	MULTI_AUTO_ATTENDANT,
	SPECIAL_EXTENSION,
	DEPARTMENT,
	RING_GROUP,
	TEAM_MEMBER,
} from '@app/Common/constants';

const typeMap = {
	[VALID_TEAM_MEMBER]: TEAM_MEMBER,
	[VALID_DEPARTMENT]: DEPARTMENT,
	[VALID_RING_GROUP]: RING_GROUP,
	[VALID_WELCOME_MENU]: MULTI_AUTO_ATTENDANT,
	[VALID_SPECIAL_EXTENSION]: SPECIAL_EXTENSION,
	[VALID_CALL_QUEUE]: CALL_QUEUE,
};
export function getEntityTypeForNumberUpdate(type: string) {
	return typeMap[type];
}
