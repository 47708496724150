export enum ReportType {
	TODAY = 'today',
	WEEKS_1 = '7 days',
	WEEKS_2 = '14 days',
	MONTH = 'month',
	CUSTOM = 'custom',
}

export interface Report {
	type: ReportType;
	startDate: Date;
	endDate: Date;
}

export interface ReportQuery {
	startDate: string;
	endDate: string;
	interval?: ReportInterval;
}

export enum ReportInterval {
	HOURLY,
	DAILY,
}
