import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs/Subscription';

import { AudioPlayerService } from '@n2p/audio-player/audio-player.service';

import { CHECK_SRC_REGEX, IAudio } from '@app/Common';
import { formatSecondsToTime } from '@utils/helpers/functions';

@Component({
	selector: 'n2p-audio-player',
	templateUrl: './audio-player.component.html',
	styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit, OnDestroy, OnChanges {
	@Input() duration: number = 0;
	@Input() showTrack: boolean;
	@Output() audioStateChanged: EventEmitter<any> = new EventEmitter();
	@ViewChild('audioElem', { static: true }) audioElem: ElementRef;
	id: string;
	durationToDisplay: string;
	timeElapsed: number = 0;
	timeElapsedAsPercentage: number = 0;
	safeAudioSrc: SafeUrl;
	@Input() loading: boolean;
	audioState: 'playing' | 'paused' = 'paused';
	activeAudioPlayerSubscription: Subscription;
	constructor(private domSanitizer: DomSanitizer, private audioPlayerService: AudioPlayerService) {}

	@Input() set audioSrc(src: IAudio | File | string) {
		if (src === undefined || src === null) return;

		if (src instanceof File) {
			this.safeAudioSrc = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(src));
		} else if (typeof src === 'object') {
			this.safeAudioSrc = this.domSanitizer.bypassSecurityTrustUrl(`data:audio/wav;base64,${src.content}`);
		} else {
			const finalSrc = src && CHECK_SRC_REGEX.test(src) ? src : `data:audio/wav;base64,${src}`;
			this.safeAudioSrc = this.domSanitizer.bypassSecurityTrustUrl(finalSrc);
		}
	}

	toggleAudio(): void {
		if (!this.loading) {
			switch (this.audioState) {
				case 'playing':
					this.pause();
					break;
				case 'paused':
					this.play();
					break;
				default:
					throw Error(`Invalid audio state!`);
			}
		}
	}

	ngOnInit(): void {
		this.id = this.audioPlayerService.addAudioPlayer();
		this.durationToDisplay = formatSecondsToTime(this.duration, true);
		this.setAudioListeners();
		this.setAudioState();
		this.activeAudioPlayerSubscription = this.audioPlayerService.activeAudioPlayer.subscribe(id => {
			if (id !== this.id) this.pause();
		});
	}

	ngOnDestroy(): void {
		this.activeAudioPlayerSubscription.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		const { loading } = changes;
		if (loading && !loading.previousValue && loading.currentValue && this.audioState === 'playing') {
			this.audioState = 'paused';
		}
	}

	private setAudioState(ended = false): void {
		this.audioStateChanged.emit({
			durationToDisplay: this.durationToDisplay,
			timeElapsedAsPercentage: this.timeElapsedAsPercentage,
			audioState: this.audioState,
			ended,
		});
	}

	private play(): void {
		setTimeout(() => {
			this.audioPlayerService.playAudioPlayer(this.id);
			this.audioElem.nativeElement.play();
			this.audioState = 'playing';
		});
	}

	//We need to be able to stop audio when parent component downloads music
	public pause(): void {
		this.audioElem.nativeElement.pause();
		this.audioState = 'paused';
	}

	private setAudioListeners(): void {
		const { audioElem } = this;

		// fired when audio loads and duration is known
		audioElem.nativeElement.addEventListener('durationchange', event => {
			const { srcElement } = event;
			this.duration = srcElement.duration;
		});

		// fired when time elapses on audio play
		audioElem.nativeElement.addEventListener('timeupdate', event => {
			const {
				srcElement: { currentTime },
			} = event;
			const percentPerSecUnit = 100 / this.duration;

			this.timeElapsed = currentTime;
			this.timeElapsedAsPercentage = currentTime * percentPerSecUnit;
			this.durationToDisplay = formatSecondsToTime(this.durationToShow, true);

			this.setAudioState();
		});

		// fired when playback ends
		audioElem.nativeElement.addEventListener('ended', event => {
			this.audioState = 'paused';
			setTimeout(() => {
				this.durationToDisplay = formatSecondsToTime(Math.floor(this.duration), true);
				this.timeElapsedAsPercentage = 0;
				this.setAudioState(true);
			}, 600);
		});
	}

	private get durationToShow(): number {
		const timeRemaining = Math.round(this.duration - this.timeElapsed);
		// since duration will be undefined initially, NaN may be a value for timeRemaining, thus we make sure to return 0 in that case

		return isNaN(timeRemaining) ? this.duration : timeRemaining;
	}

	onLoadMetaData(): void {
		this.duration = Math.round(this.audioElem.nativeElement.duration);
		this.durationToDisplay = formatSecondsToTime(this.duration, true);
	}
}
