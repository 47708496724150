import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IHorizontalSelectOption, HorizontalSelectValue } from '@n2p/horizontal-select/horizontal-select.domain';

@Component({
	selector: 'n2p-horizontal-select',
	templateUrl: './horizontal-select.component.html',
	styleUrls: ['./horizontal-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => HorizontalSelectComponent),
			multi: true,
		},
	],
})
export class HorizontalSelectComponent implements ControlValueAccessor {
	@Input() options: IHorizontalSelectOption[];
	@Input() selectedValue: HorizontalSelectValue;
	@Output() selectedValueChange: EventEmitter<HorizontalSelectValue> = new EventEmitter();

	private formControlChangeFn: (value: HorizontalSelectValue) => any;
	private formControlTouchFn: () => any;

	setActiveValue(option: IHorizontalSelectOption): void {
		if (option.value !== this.selectedValue) {
			this.selectedValue = option.value;
			this.selectedValueChange.emit(this.selectedValue);

			this.formControlTouchFn && this.formControlTouchFn();
			this.formControlChangeFn && this.formControlChangeFn(this.selectedValue);
		}
	}

	registerOnChange(fn: any): void {
		this.formControlChangeFn = fn;
	}

	registerOnTouched(fn: any): void {
		this.formControlTouchFn = fn;
	}

	writeValue(value: HorizontalSelectValue): void {
		this.selectedValue = value;
	}
}
