import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { ApiUsersService, JwtService } from '@app/services';
import { environment } from '@env/environment';

@Component({
	selector: 'app-changepassword',
	templateUrl: './admin-reset-password.component.html',
	styleUrls: ['./admin-reset-password.component.scss'],
})
export class AdminResetPasswordComponent implements OnInit {
	@Input() changePasswordStatus: any;
	@Input() addPhoneNumberStatus: any;
	public number: string;
	public isExistingEmail: boolean;
	public isSubmitting = false;

	addressForm: FormGroup;

	userName: string;
	userEmail: string;
	address: any = {};

	constructor(
		@Inject(DIALOG_DATA) private data: any,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		private jwtService: JwtService,
		private apiUsersService: ApiUsersService,
	) {
		const user = this.data;

		this.userName = `${user.firstName} ${user.lastName}`;
		this.userEmail = user.email;
	}

	ngOnInit() {
		this.addressForm = new FormGroup({
			number: new FormControl('', Validators.required),
		});
	}

	close() {
		this.dialogRef.dismiss({ cancelled: true });
	}

	submit() {
		this.isSubmitting = true;
		const callback = `${environment.api_auth_url}/connect/authorize?${this.jwtService.REDIRECT_PARAMS.toString()}`;
		this.apiUsersService
			.resetUserPasswordByAdmin(this.userEmail, callback, this.data?.uiLanguageCode ?? 'en')
			.subscribe(
				(result: any) => {
					this.isSubmitting = false;
					this.dialogRef.dismiss({ error: result ? result.hasError : false });
				},
				error => {
					this.isSubmitting = false;
					this.dialogRef.dismiss({ error: true });
				},
			);
	}
}
