import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { PredictedAddressComponent } from '@n2p/predicted-address/predicted-address.component';

@NgModule({
	declarations: [PredictedAddressComponent],
	exports: [PredictedAddressComponent],
	imports: [CommonModule, TranslateModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PredictedAddressModule {}
