import { Component, Input, OnInit } from '@angular/core';

import { getValidType, isValidType, VALID_PHONE_NUMBER } from '@app/Common/constants/entity-types';

@Component({
	selector: 'n2p-dropdown-item-icon',
	templateUrl: './dropdown-item-icon.component.html',
	styleUrls: ['./dropdown-item-icon.component.scss'],
})
export class DropdownItemIconComponent implements OnInit {
	@Input() item: any;
	constructor() {}

	ngOnInit(): void {}

	get getValidAvatarItemType(): string {
		return getValidType(this.item.type);
	}

	/**
	 * Checks if `voicemail-icon-wrapper` should be used
	 */
	get isWrappedAvatarItemType(): boolean {
		const { type } = this.item;
		return type && isValidType(type) && type !== VALID_PHONE_NUMBER;
	}
}
