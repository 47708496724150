import { Component, EventEmitter, Input, AfterViewInit, OnInit, Output, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { debounceTime, filter, tap, map, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { ICountry } from 'n2p-ui-library/utils/countries.domain';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';

import { CallBlockingHelperService } from '@app/services/call-blocking-helper/call-blocking-helper.service';
import { CommonService, SnackbarService } from '@app/services';
import { ApiCallBlockingService } from '@app/services/web-apis/call-blocking/api-call-blocking.service';
import { IBlockedNumber } from '@app/services/web-apis/call-blocking/api-call-blocking.domain';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { notOneOfTheStrings, noTrimValidator } from '@utils/helpers/validators';

@Component({
	selector: 'app-call-blocking-add-new',
	templateUrl: './call-blocking-add-new.component.html',
	styleUrls: ['./call-blocking-add-new.component.scss'],
})
export class CallBlockingAddNewComponent implements OnInit, AfterViewInit, OnDestroy {
	private NUMBER_FIELD_VALIDATORS: ValidatorFn[] = [
		Validators.required,
		noTrimValidator,
		notOneOfTheStrings(['*', '+*']),
		Validators.pattern(/^\+?[0-9*\s]+$/),
	];

	isAdmin: boolean;
	formGroup: FormGroup;
	phoneNumberControl: FormControl = new FormControl('', this.NUMBER_FIELD_VALIDATORS);
	descriptionControl: FormControl = new FormControl('', [Validators.maxLength(30)]);
	levelControl: FormControl = new FormControl(this.callBlockingHelperService.defaultAppliedOnValue, []);

	loading: boolean = false;
	accountCountry$: Observable<string> = this.apiTenantService.countryCode$.pipe(startWith('US'));
	isNationalFormatEnabled$: Observable<boolean> = this.apiTenantService.isBrazilAccount$();

	@Input() phoneNumber: string;
	@Input() countries: ICountry;

	@Output() onSuccess: EventEmitter<IBlockedNumber> = new EventEmitter();
	@Output() onFail: EventEmitter<void> = new EventEmitter();
	@Output() onCancel: EventEmitter<void> = new EventEmitter();

	private subscriptions: Subscription[] = [];

	constructor(
		public callBlockingHelperService: CallBlockingHelperService,
		private commonService: CommonService,
		private apiCallBlockingService: ApiCallBlockingService,
		private snackbarService: SnackbarService,
		private apiTenantService: ApiTenantService,
		private translateService: TranslateService,
	) {}

	ngOnInit(): void {
		this.isAdmin = this.commonService.isAdmin();
		this.formGroup = new FormGroup({});
		this.phoneNumberControl = new FormControl(this.phoneNumber || '', this.NUMBER_FIELD_VALIDATORS);
		this.descriptionControl = new FormControl('', [Validators.maxLength(30)]);
		this.levelControl = new FormControl(this.callBlockingHelperService.defaultAppliedOnValue, []);
		this.formGroup.addControl('Number', this.phoneNumberControl);
		this.formGroup.addControl('Description', this.descriptionControl);
		this.formGroup.addControl('Level', this.levelControl);

		this.subscriptions.push(
			this.phoneNumberControl.valueChanges
				.pipe(
					tap(value => {
						this.phoneNumberControl.markAsTouched();
						this.loading = !!value;
					}),
					filter(v => !!v),
					debounceTime(300),
					map(value => String(value).trim()),
				)
				.subscribe(value => {
					if (this.phoneNumberControl.valid) {
						this.apiCallBlockingService.checkIsBlockingNumberDuplicate(0, value).subscribe({
							next: (res: boolean): void => {
								if (res) {
									this.phoneNumberControl.setErrors({ duplicatedNumber: true });
								}
							},
							complete: (): boolean => (this.loading = false),
						});
					} else {
						this.phoneNumberControl.setErrors({ invalidNumber: true });
						this.loading = false;
					}
				}),
		);
	}

	ngAfterViewInit(): void {
		this.loading = false;
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	addPhoneNumber(): void {
		if (this.formGroup.valid) {
			this.loading = true;

			this.apiCallBlockingService.addBlockedNumber(this.formGroup.value).subscribe(
				blockedNumber => {
					this.onSuccess.emit(blockedNumber);
					this.loading = false;
				},
				(error: Error) => {
					this.snackbarService.createDanger(error.message);
					this.onFail.emit();
					this.loading = false;
				},
			);
		}
	}

	getPhoneNumberInvalidMessage(): string {
		if (!this.phoneNumberControl.invalid || !this.formGroup.touched) {
			return '';
		} else {
			if (this.phoneNumberControl.errors?.duplicatedNumber) {
				return this.translateService.instant('VALIDATION_ERRORS.ALREADY_BLOCKED');
			} else {
				return this.translateService.instant('PHONE_NUMBER_FORM.INVALID');
			}
		}
	}
}
