import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
	/* tslint:disable */
	selector: 'input[n2p-border-input]',
	templateUrl: './border-input.component.html',
	styleUrls: ['./border-input.component.scss'],
	encapsulation: ViewEncapsulation.None,
	/* tslint:enable */
})
export class BorderInputComponent {
	@HostBinding('class.has-error') _hasError: boolean;
	@Input() set hasError(hasIt) {
		this._hasError = hasIt;
	}
}
