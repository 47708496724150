import { Injectable } from '@angular/core';
import { LoggerService as Logger } from 'n2p-ui-library/services/logger/logger.service';
import { TransportType, DebugLevels } from 'n2p-ui-library/services/logger/logger.constants';

import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root',
})
export class LoggerService {
	private loggerService: Logger;

	constructor() {
		this.loggerService = new Logger({
			appId: 'n2p-webapp',
			environment: environment.env,
			isLocalEnv: environment.local,
		});
		this.info = this.info.bind(this);
	}

	info(value: string, data?: string | object): void {
		this.loggerService.logInfo(value, data);
	}

	error(value: string, data?: string | object): void {
		this.loggerService.logError(value, data);
	}

	critical(value: string, data?: string | object): void {
		this.loggerService.logCritical(value, data);
	}

	toggleConsoleDebug(): void {
		this.loggerService.toggleConsoleDebug();
	}

	toggleLogglyDebug(): void {
		this.loggerService.toggleLogglyDebug();
	}

	toggleTransportType(transportName: TransportType): void {
		this.loggerService.toggleTransportType(transportName);
	}

	setConsoleInfoDebug(value: boolean): void {
		this.loggerService.setConsoleInfoDebug(value);
	}

	setLogglyDebugLevels(levels: DebugLevels): void {
		this.loggerService.setLogglyDebugLevels(levels);
	}
}
