import { Address } from './address';
import { IAccountContactMethod, IAccountContactType } from '@app/services/web-apis/contacts/api-contacts.domain';

export class Contact {
	address = new Address();
	contactMethod = IAccountContactMethod.ADDRESS;
	contactType = IAccountContactType.SERVICE;
	parentContactId = '';
	externalId = '';
	contactName = '';
	companyName = '';
	firstName = '';
	lastName = '';
	phone = '';
	email = '';
	comments = '';

	constructor(address?: Address | object) {
		if (address) {
			typeof address === 'object' ? (this.address.set = address) : (this.address = address);
		}
	}

	set set(data: object) {
		Object.keys(data).forEach(key => {
			if (this.hasOwnProperty(key) && data[key]) {
				this[key] = data[key];
			}
		});
	}

	get toJSON(): object {
		const json = Object.assign({}, this, this.address.toJSON);
		delete json.address;

		return json;
	}
}
