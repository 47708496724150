import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IAddressItem } from '@app/services/web-apis/address/api-address.domain';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { ApiService } from '@app/services';

@Injectable({
	providedIn: 'root',
})
export class ApiAddressService {
	constructor(private apiService: ApiService) {}

	fetchAddressItems(countryCode: string, stateCode?: string, cityCode?: string): Observable<IAddressItem[]> {
		const url = this.getAddressItemsUrl(countryCode, stateCode, cityCode);

		return this.apiService.get<IRegularApiResponse<IAddressItem[]>>(url).pipe(
			map(res => {
				return res.data;
			}),
		);
	}

	private getAddressItemsUrl(countryCode: string, stateCode?: string, cityCode?: string): string {
		let str = `/countries/${countryCode}/states`;

		if (stateCode) {
			str = `${str}/${stateCode}/cities`;
		}

		if (cityCode) {
			str = `${str}/${cityCode}/localities`;
		}

		return str;
	}
}
