import { PortalModule } from '@angular/cdk/portal';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';

import { DirectivesModule } from '@app/directives/directives.module';
import { AvatarModule } from '@n2p/avatar';
import { IconModule } from '@n2p/icon';
import { DialogModule } from '@n2p/dialog/dialog.module';
import { PopupModule } from '@n2p/popup/popup.module';
import { DropdownComponent } from './dropdown.component';
import { SimpleDropdownComponentComponent } from './simple-dropdown-component/simple-dropdown-component.component';
import { DropdownDataComponent } from './dropdown-data/dropdown-data.component';
import { DropdownHiddenDataPopupComponent } from './dropdown-hidden-data-popup/dropdown-hidden-data-popup.component';
import { DropdownItemComponent } from './dropdown-item/dropdown-item.component';
import { SvgModule } from '@n2p/svg';
import { LoaderModule } from '@n2p/loader';
import { AddForwardingNumberDropdownItemComponent } from './add-forwarding-number-dropdown-item/add-forwarding-number-dropdown-item.component';
import { DropdownItemIconComponent } from './dropdown-item-icon/dropdown-item-icon.component';
import { DropdownOptionComponent } from './dropdown-option/dropdown-option.component';
import { DropdownNoSearchResultsComponent } from './dropdown-no-search-results/dropdown-no-search-results.component';
import { DropdownSearchHighlightDirective } from './dropdown-search-highlight/dropdown-search-highlight.directive';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		PortalModule,
		SvgModule,
		LoaderModule,
		OverlayModule,
		DialogModule,
		IconModule,
		AvatarModule,
		TranslateModule,
		DirectivesModule, // TODO remove once TooltipModule is merged in
		PopupModule,
	],
	declarations: [
		DropdownComponent,
		DropdownDataComponent,
		SimpleDropdownComponentComponent,
		DropdownHiddenDataPopupComponent,
		DropdownItemComponent,
		AddForwardingNumberDropdownItemComponent,
		DropdownItemIconComponent,
		DropdownOptionComponent,
		DropdownNoSearchResultsComponent,
		DropdownSearchHighlightDirective,
	],
	entryComponents: [DropdownDataComponent, SimpleDropdownComponentComponent, DropdownHiddenDataPopupComponent],
	exports: [
		DropdownComponent,
		DropdownItemComponent,
		DropdownOptionComponent,
		AddForwardingNumberDropdownItemComponent,
		DropdownNoSearchResultsComponent,
		DropdownSearchHighlightDirective,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DropdownModule {}
