import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, first, map } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { IDropdownOption } from 'n2p-ui-library/components/dropdown/dropdown.domain';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';

import { DialogRef } from '@app/shared/classes/DialogRef';
import { DIALOG_DATA, DIALOG_REF } from 'utils';
import {
	ApiAccountsService,
	ApiPhoneNumbersService,
	ApiUsersService,
	FeatureFlagsService,
	SnackbarService,
} from '@app/services';
import { IPhone } from '@app/Common/interfaces/IPhone';
import { emailRegex, EMAIL_MAX_LENGTH, DID_LESS_COUNTRIES } from '@app/Common';
import { ConstantsService } from '@app/services/constants/constants.service';
import { TeamMemberService } from '@app/services/caller-id/team-member.service';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';

@Component({
	selector: 'app-add-team-member',
	templateUrl: './add-team-member.component.html',
	styleUrls: ['./add-team-member.component.scss'],
})
export class AddTeamMemberComponent implements OnInit, OnDestroy {
	unUsedPhones: number = 0;
	phones: IPhone[];
	compDirFeatureEnabled: boolean = false;
	canCreateDidlessUsers: boolean;
	showCallerIdPopup: boolean;
	subscriptions: Subscription = new Subscription();

	isAdding: boolean = false;

	error: any = {
		hasError: false,
		field: '',
		message: '',
	};

	formGroup: FormGroup;

	allowAddingPhone: Observable<boolean> = this.tenantService
		.isAccountCountries$(DID_LESS_COUNTRIES)
		.pipe(map(res => !res));

	constructor(
		@Inject(DIALOG_DATA) private data: any,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		public constantsService: ConstantsService,
		private apiAccounts: ApiAccountsService,
		private apiPhoneNumbers: ApiPhoneNumbersService,
		private usersAPI: ApiUsersService,
		private fb: FormBuilder,
		private featureFlags: FeatureFlagsService,
		private teamMemberService: TeamMemberService,
		private tenantService: ApiTenantService,
		private snackbarService: SnackbarService,
		private translate: TranslateService,
	) {
		this.canCreateDidlessUsers = data.canCreateDidlessUsers;

		this.formGroup = this.fb.group({
			firstName: ['', [Validators.required, Validators.maxLength(15)]],
			lastName: ['', [Validators.required, Validators.maxLength(15)]],
			email: new FormControl('', [
				Validators.required,
				Validators.maxLength(EMAIL_MAX_LENGTH),
				Validators.pattern(emailRegex),
			]),
			extension: ['', Validators.min(100)],
			phone: new FormControl([], this.canCreateDidlessUsers ? [] : [Validators.required]),
			admin: false,
			address: [undefined, Validators.required],
			type: 'User',
			callerId: new FormControl(''),
		});
	}

	get firstName(): AbstractControl {
		return this.formGroup.get('firstName');
	}

	get lastName(): AbstractControl {
		return this.formGroup.get('lastName');
	}

	get fullName(): string {
		return `${this.firstName.value} ${this.lastName.value}`;
	}

	setFirstName(newName: string): void {
		this.firstName.setValue(newName);
		this.firstName.markAsTouched();
	}

	setLastName(newName: string): void {
		this.lastName.setValue(newName);
		this.lastName.markAsTouched();
	}

	validateFirstName(): string {
		if (this.firstName.invalid) {
			if (this.firstName.errors.maxlength) {
				return this.translate.instant('PROFILE_PAGE.FIRST_NAME_TOO_LONG');
			}

			if (this.firstName.errors.required) {
				return this.translate.instant('PROFILE_PAGE.FIRST_NAME_REQUIRED');
			}
		}

		return '';
	}

	validateLastName(): string {
		if (this.lastName.invalid) {
			if (this.lastName.errors.maxlength) {
				return this.translate.instant('PROFILE_PAGE.LAST_NAME_TOO_LONG');
			}

			if (this.lastName.errors.required) {
				return this.translate.instant('PROFILE_PAGE.LAST_NAME_REQUIRED');
			}
		}

		return '';
	}

	get email(): AbstractControl {
		return this.formGroup.get('email');
	}

	get extension(): AbstractControl {
		return this.formGroup.get('extension');
	}

	get phone(): AbstractControl {
		return this.formGroup.get('phone');
	}

	get admin(): AbstractControl {
		return this.formGroup.get('admin');
	}

	get address(): AbstractControl {
		return this.formGroup.get('address');
	}

	get isValid(): boolean {
		return this.formGroup.status === 'VALID';
	}

	get callerId(): AbstractControl {
		return this.formGroup.get('callerId');
	}

	ngOnInit(): void {
		this.subscriptions.add(
			this.featureFlags.companyDirectory().subscribe(enabled => (this.compDirFeatureEnabled = enabled)),
		);
		this.subscriptions.add(this.teamMemberService.getCallerIdPhoneOptions(0).subscribe());
	}

	ngOnDestroy(): void {
		this.teamMemberService.clearSubjects();
		this.subscriptions.unsubscribe();
	}

	onDismiss(value?: any): void {
		this.dialogRef.dismiss(value);
	}

	onExtensionChange({ value, isValid }: { value: string; isValid: boolean }): void {
		this.extension.setValue(value);
		this.extension.markAsTouched();
		if (!isValid) {
			this.extension.setErrors({ invalid: true });
		}
	}

	change(event: string | boolean, field: string): void {
		this[field].setValue(event);
		this[field].markAsTouched();
	}

	onCreate(): void {
		if (!this.formGroup.valid) return;
		this.isAdding = true;
		this.error.hasError = false;

		const firstName = this.firstName.value.trim();
		const lastName = this.lastName.value.trim();

		const data = {
			firstName,
			lastName,
			extension: this.extension.value,
			email: this.email.value,
			role: this.admin.value ? 'admin' : 'regular',
			phone: {
				phone: this.phone.value[0] || '',
			},
			callerId: this.callerId.value,
			serviceAddressId: this.address.value,
		};

		this.apiAccounts.account$
			.pipe(
				filter(v => !!v),
				first(),
			)
			.subscribe(account => {
				if (Object.prototype.hasOwnProperty.call(account, 'canCreatePhysicalDevices')) {
					data['canCreatePhysicalDevices'] = account.canCreatePhysicalDevices;

					this.usersAPI.createUser(data).subscribe(
						result => {
							if (result.hasError) {
								const errData = {
									field: '',
									message: '',
								};
								const errMsg = result.errorMessages?.[0]?.message.toLowerCase() || '';
								if (errMsg.includes('email') && errMsg.includes('use')) {
									errData.field = 'email';
									errData.message = this.translate.instant('ADD_TEAM_MEMBER_PAGE.EMAIL_IN_USE_ERROR');
								}
								if (errMsg.includes('last') && errMsg.includes('null')) {
									errData.field = 'last';
									errData.message = this.translate.instant('ADD_TEAM_MEMBER_PAGE.LAST_NAME_ERROR');
								}

								this.error = {
									hasError: true,
									field: errMsg.field,
									message: errData.message,
								};
								if (errData.message) {
									this.snackbarService.createDanger(errData.message);
								}
							} else {
								this.onDismiss(result.data);
							}

							this.isAdding = false;
						},
						() => {
							this.snackbarService.createDanger(this.translate.instant('GLOBALS.GENERAL_ERROR'));
							this.isAdding = false;
						},
					);
				}
			});
	}

	getError(control: AbstractControl, field: string): string {
		const touched = control && control.touched;

		return touched && this.error.hasError && this.error.field === field ? this.error.message : '';
	}

	openCallerIdPopup(): void {
		this.showCallerIdPopup = true;
	}

	handleBlurFirstName(): void {
		this.firstName.setValue(this.firstName.value.trim());
	}

	handleBlurLastName(): void {
		this.lastName.setValue(this.lastName.value.trim());
	}

	closeCallerIdPopup(): void {
		this.formGroup.get('callerId').setValue('');
		this.showCallerIdPopup = false;
	}

	setCallerId(option?: IDropdownOption): void {
		this.formGroup.get('callerId').setValue(option ? option.value : '');
	}

	emailBlur(): void {
		// blind set error message if present
		if (this.email.errors) {
			this.error = {
				hasError: true,
				field: 'email',
				message: this.translate.instant('VALIDATION_ERRORS.EMAIL_INVALID'),
			};
		} else if (this.error?.field === 'email') {
			// unset error message if email is valid
			this.error = {
				hasError: false,
				field: '',
				message: '',
			};
		}
	}
}
