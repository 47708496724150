import { Component, Inject, OnInit } from '@angular/core';

import { TOOLTIP_POSITION, TOOLTIP_TEXT } from '@n2p/tooltip/tooltip.injectors';

@Component({
	selector: 'app-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
	text: string;
	position: string;
	constructor(@Inject(TOOLTIP_TEXT) tooltipText: string, @Inject(TOOLTIP_POSITION) position: string) {
		this.text = tooltipText;
		this.position = position;
	}

	ngOnInit(): void {}
}
