import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-addphonenumber',
	templateUrl: './addphonenumber.component.html',
	styleUrls: ['./addphonenumber.component.scss'],
})
export class AddphonenumberComponent implements OnInit {
	@Input() addPhoneNumberStatus: any;

	public number: string;
	addressForm: FormGroup;
	constructor() {}
	//models
	address: any = {};

	ngOnInit() {
		this.addressForm = new FormGroup({
			number: new FormControl('', Validators.required),
		});
	}
	private addPhoneNumber: string;

	AddPhoneNumberStatus() {
		this.addPhoneNumber = 'Yes';
	}
}
