import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { CallOptionsService } from '@app/pages/teammembers/call-options/call-options.service';

@Component({
	selector: 'n2p-call-options-flag',
	templateUrl: './call-options-flag.component.html',
	styleUrls: ['./call-options-flag.component.scss', '../../call-options.shared.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallOptionsFlagComponent {
	@Input() title: string;
	@Input() description: string;

	@Input() state$: Observable<boolean>;
	@Output() onToggle: EventEmitter<void> = new EventEmitter();

	@Input() isDependOnNumbers: boolean = true;

	constructor(private callOptionsService: CallOptionsService) {}

	get disabled(): boolean {
		return (
			this.isDependOnNumbers &&
			(this.callOptionsService.isCallForwardingOff || this.callOptionsService.noForwardNumbers)
		);
	}

	get isMessageShown(): boolean {
		return (
			this.isDependOnNumbers && !this.callOptionsService.isCallForwardingOff && this.callOptionsService.noForwardNumbers
		);
	}
}
