import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LicensesService } from '@app/services';
import { ILicenseBase } from '@app/services/licenses/licenses.domain';
import { SnackbarService } from '@app/services';

@Component({
	selector: 'app-edit-team-member-licenses',
	templateUrl: './edit-team-member-licenses.component.html',
	styleUrls: ['./edit-team-member-licenses.component.scss'],
})
export class EditTeamMemberLicensesComponent implements OnInit {
	usersLicenses: ILicenseBase[] = [];
	requestPending: boolean = true;
	descString: string;
	@Input() userId: number;

	constructor(
		private licensesService: LicensesService,
		private snackbarService: SnackbarService,
		private translate: TranslateService,
	) {}

	ngOnInit(): void {
		this.licensesService.getLicensesForRegularUser(this.userId).subscribe(
			res => {
				this.usersLicenses = res;
				if (this.usersLicenses.length) {
					this.descString = this.translate.instant('LICENSES.USERS_LICENSES_DESC_REGULAR');
				} else {
					this.descString = this.translate.instant('LICENSES.NO_LICENSE_FOR_USER');
				}
			},
			err => {
				this.snackbarService.createDanger(this.translate.instant(err.message));
			},
			() => {
				this.requestPending = false;
			},
		);
	}
}
