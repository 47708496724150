import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SnackbarService } from '@app/services';
import { ApiMusicOptionsService } from '@app/services/web-apis/music-options/api-music-options.service';
import { MusicAudioType } from '@app/services/web-apis/music-options/api-music-options.constant';
import { IAudioContentRsp } from '@app/services/web-apis/music-options/api-music-options.domain';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DataStorageUnit } from 'n2p-ui-library';

import { ICustomMoHForm } from './edit-team-member-custom-moh.domain';

import { MohUploadedType } from './edit-team-member-custom-moh.constants';

@Component({
	selector: 'app-edit-team-member-custom-moh',
	templateUrl: './edit-team-member-custom-moh.component.html',
	moduleId: module.id,
	styleUrls: ['./edit-team-member-custom-moh.component.scss'],
})
export class EditTeamMemberCustomMohComponent implements OnInit, OnDestroy {
	MOH_DEFAULT = MohUploadedType.DEFAULT;
	MOH_CUSTOM = MohUploadedType.CUSTOM;
	MOH_DATA_STORAGE_UNIT = DataStorageUnit.MEGABYTE;

	form: FormGroup;
	subscriptions = new Subscription();

	mohType: MohUploadedType = MohUploadedType.DEFAULT;
	defaultMoH: Partial<IAudioContentRsp> | null = null;
	customMoH: Partial<IAudioContentRsp> | null = null;
	newCustomMoHFile: null | string | File = null;

	isDefaultMoHLoading = false;
	isCustomMoHLoading = false;
	isMoHResettingToDefault = false;
	isNewCustomMoHLoading = false;

	isCustomMohModalOpen = false;

	@Input() editUserId: string | number;

	@ViewChild('container', { static: true }) container: ElementRef;

	constructor(
		private fb: FormBuilder,
		private apiMusicOptionsService: ApiMusicOptionsService,
		private translateService: TranslateService,
		private snackbarService: SnackbarService,
	) {
		this.initForm();
		this.initMoHType();
	}

	ngOnInit(): void {
		Promise.all([this.getDefaultMoH(), this.getCustomMoH()]);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	private initForm(): void {
		this.form = this.fb.group(<ICustomMoHForm>{
			mohType: this.mohType,
		});
	}

	private initMoHType(): void {
		this.subscriptions.add(
			this.form.get('mohType').valueChanges.subscribe(mohType => {
				if (mohType === MohUploadedType.DEFAULT) {
					this.isCustomMohModalOpen = false;
					this.resetMohToDefault();
				}
			}),
		);
	}

	private getDefaultMoH(): void {
		this.isDefaultMoHLoading = true;
		this.apiMusicOptionsService
			.getAccountAudioFile(MusicAudioType.MOH)
			.pipe(
				finalize(() => {
					this.isDefaultMoHLoading = false;
				}),
			)
			.subscribe(res => {
				this.defaultMoH = res.data;
			});
	}

	private getCustomMoH(): void {
		this.isCustomMoHLoading = true;
		this.apiMusicOptionsService
			.getUserAudioFile(this.editUserId, MusicAudioType.MOH)
			.pipe(
				finalize(() => {
					this.isCustomMoHLoading = false;
				}),
			)
			.subscribe(res => {
				this.customMoH = res.data;

				if (this.customMoH.audioContent) {
					this.mohType = MohUploadedType.CUSTOM;
					this.form.patchValue({ mohType: this.mohType });
				}
			});
	}

	private resetMohToDefault(): void {
		this.isMoHResettingToDefault = true;

		this.apiMusicOptionsService
			.resetUserAudioFile(this.editUserId, MusicAudioType.MOH)
			.pipe(
				finalize(() => {
					this.isCustomMohModalOpen = false;
					this.isNewCustomMoHLoading = false;
				}),
			)
			.subscribe(
				resp => {
					this.isMoHResettingToDefault = false;
					this.mohType = MohUploadedType.DEFAULT;
					this.getDefaultMoH();
					this.snackbarService.createSuccess(
						this.translateService.instant('EDIT_CUSTOM_MOH.DEFAULT_MOH_SUCCESS'),
						this.container,
					);
				},
				() => {
					this.isMoHResettingToDefault = false;
					this.snackbarService.createDanger(this.translateService.instant('EDIT_CUSTOM_MOH.FAILED'), this.container);
				},
			);
	}

	openCustomMohModal(): void {
		this.isCustomMohModalOpen = true;
	}

	closeCustomMohModal(): void {
		this.isCustomMohModalOpen = false;
		this.newCustomMoHFile = null;

		if (this.mohType !== this.form.get('mohType').value) {
			this.form.patchValue({ mohType: this.mohType }, { emitEvent: false });
		}
	}

	loadCustomMoHFile(file: File): void {
		this.newCustomMoHFile = file;
	}

	onCustomMoHFileError(error: Error): void {
		this.snackbarService.createDanger(error.message);
	}

	uploadNewCustomMoH(): void {
		this.isNewCustomMoHLoading = true;

		const formData = new FormData();
		formData.append('OwnerType', 'users');
		formData.append('OwnerId', String(this.editUserId));
		formData.append('Type', 'moh');
		formData.append('Content', this.newCustomMoHFile);

		this.apiMusicOptionsService
			.uploadMediaFormData(formData)
			.pipe(
				finalize(() => {
					this.isCustomMohModalOpen = false;
					this.isNewCustomMoHLoading = false;
				}),
			)
			.subscribe(
				resp => {
					// TODO: remove comments when API will return correct audio url
					// this.mohType = MohUploadedType.CUSTOM;
					// this.customMoH = resp.data;

					// TODO: delete call when API will return correct audio url
					this.getCustomMoH();

					this.newCustomMoHFile = null;
					this.snackbarService.createSuccess(
						this.translateService.instant('EDIT_CUSTOM_MOH.CUSTOM_MOH_UPLOADED'),
						this.container,
					);
				},
				() => {
					this.form.patchValue({ mohType: this.mohType }, { emitEvent: false });
					this.snackbarService.createDanger(this.translateService.instant('EDIT_CUSTOM_MOH.FAILED'), this.container);
				},
			);
	}

	get isMoHInitialLoading(): boolean {
		return (
			(this.isDefaultMoHLoading && this.defaultMoH === null) || (this.isCustomMoHLoading && this.customMoH === null)
		);
	}

	get audioSrc(): null | string {
		if (this.mohType === MohUploadedType.DEFAULT && this.defaultMoH.audioContent) {
			return String(this.defaultMoH.audioContent);
		}

		if (this.mohType === MohUploadedType.CUSTOM && this.customMoH.audioContent) {
			return String(this.customMoH.audioContent);
		}

		return null;
	}
}
