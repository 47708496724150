/* tslint:disable:i18n */
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Inject } from '@angular/core';

import { POPUP_COMPONENT_DATA, POPUP_REF } from '@n2p/popup/popup.injectors';

@Component({
	/* tslint:disable */
	selector: 'n2p-dropdown-hidden-data-popup',
	templateUrl: './dropdown-hidden-data-popup.component.html',
	styleUrls: ['./dropdown-hidden-data-popup.component.scss'],
	/* tslint:enable */
})
export class DropdownHiddenDataPopupComponent {
	hiddenSelectedData: Array<any>;
	hiddenOverlayRef: OverlayRef;
	constructor(@Inject(POPUP_COMPONENT_DATA) private data: any, @Inject(POPUP_REF) private overlayRef: OverlayRef) {
		this.hiddenSelectedData = data;
		this.hiddenOverlayRef = overlayRef;
	}

	removeItem(i, item): void {
		this.hiddenSelectedData.splice(i, 1);
		if (!this.hiddenSelectedData.length) {
			this.hiddenOverlayRef.dispose();
		} else {
			setTimeout(() => this.hiddenOverlayRef.updatePosition());
		}
	}
}
