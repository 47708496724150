import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'app-menu-option-sticky-header-item',
	templateUrl: './menu-option-sticky-header-item.component.html',
	styleUrls: ['./menu-option-sticky-header-item.component.scss'],
})
export class MenuOptionStickyHeaderItemComponent implements OnInit {
	@Input() formGroup: FormGroup;
	iconFill: string;
	imgUrl: string;
	text: string;
	type: string;
	isVM: boolean;

	ngOnInit(): void {
		const control = this.formGroup.get('forwardCallsTo');
		this.buildChip(control.value);
		control.valueChanges.subscribe(value => {
			this.buildChip(value);
		});
	}

	private buildChip(value): void {
		if (!value || !value[0]) {
			return;
		}

		const control = value[0];
		this.type = control.type;

		switch (this.type) {
			case 'team-member': {
				this.text = control.value;
				const avatar = control.avatars && control.avatars.length ? control.avatars[1] : undefined;

				if (avatar) {
					this.imgUrl = avatar.url;
				}
				this.isVM = control.redirectToVoiceMail;

				break;
			}
			case 'department': {
				this.text = control.value;
				this.isVM = control.redirectToVoiceMail;
				break;
			}
			case 'ring-group': {
				this.text = control.value;
				break;
			}
			case 'welcome-menu': {
				this.text = control.value;
				break;
			}
			case 'phone-number': {
				this.text = control.value;
				break;
			}
			default: {
				break;
			}
		}
	}
}
