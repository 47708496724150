import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
	IDropdownOption,
	ISelectedDropdownOption,
	IDropdownGroup,
} from 'n2p-ui-library/components/dropdown/dropdown.domain';
import {
	getDropdownSelectedValueData,
	getDropdownUniqueValue,
} from 'n2p-ui-library/components/dropdown/dropdown.helpers';
import { Subscription } from 'rxjs/Subscription';
import { combineLatest } from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ConstantsService } from '@app/services/constants/constants.service';

import { TeamMemberService } from '@app/services/caller-id/team-member.service';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { PhoneNumberFormatterPipe } from '@app/pipes';

@Component({
	selector: 'app-caller-id-main',
	templateUrl: './caller-id-main.component.html',
	styleUrls: ['./caller-id-main.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => CallerIdMainComponent),
		},
	],
})
export class CallerIdMainComponent implements OnInit, OnDestroy {
	@Input() userId: any;
	@Input() sendCallerIdImmediately: boolean;

	isCallerIdRequired: boolean;
	phoneOptions: IDropdownOption[];
	phoneGroups: IDropdownGroup[];
	assignedCallerId: string;
	assignedCallerType: string;
	subscriptions: Subscription = new Subscription();
	onChangeFn: (value: string) => void;
	onTouchFn: () => void;

	get selectedValues(): string[] {
		if (this.assignedCallerId) {
			const parentIds = this.assignedCallerType ? [this.assignedCallerType] : [];
			return [getDropdownUniqueValue(this.assignedCallerId, parentIds)];
		}
		return [];
	}

	constructor(
		public constantsService: ConstantsService,
		public tenantService: ApiTenantService,
		private teamMemberService: TeamMemberService,
		private phoneNumberFormatter: PhoneNumberFormatterPipe,
	) {}

	writeValue(value: string): void {}

	registerOnChange(fn: any): void {
		this.onChangeFn = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchFn = fn;
	}

	ngOnInit(): void {
		this.subscriptions.add(
			combineLatest([
				this.teamMemberService.phoneOptions$,
				this.teamMemberService.phoneGroups$,
				this.teamMemberService.assignedCallerId$,
			]).subscribe(([phoneOptions, phoneGroups, assignedCallerId]) => {
				this.phoneOptions = phoneOptions;
				this.phoneGroups = phoneGroups.map(phoneGroup => ({
					...phoneGroup,
					options: phoneGroup.options.map(option => ({
						...option,
						title: this.phoneNumberFormatter.transform({ number: option.title }),
					})),
				}));

				this.assignedCallerId = assignedCallerId;

				this.phoneGroups.forEach(group => {
					const option = group.options && group.options.find(o => o.value === this.assignedCallerId);
					if (option) {
						this.assignedCallerType = String(group.id);
					}
				});
			}),
		);
		this.subscriptions.add(
			this.teamMemberService.isCallerIdRequired$.subscribe(value => (this.isCallerIdRequired = value)),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	putCallerId(option?: ISelectedDropdownOption): void {
		const uniqueValue = option ? option.uniqueValue : '';
		const {
			value,
			parentIds: [type],
		} = getDropdownSelectedValueData(uniqueValue);

		const prevAssignedCallerId = this.assignedCallerId;

		if (option) {
			this.assignedCallerId = value;
			this.assignedCallerType = type;
		} else {
			this.assignedCallerId = '';
			this.assignedCallerType = '';
		}

		if (this.sendCallerIdImmediately) {
			this.teamMemberService.putCallerId(this.userId, option, prevAssignedCallerId);
		} else {
			if (this.onChangeFn) {
				this.onChangeFn(value);
			}
		}
	}
}
