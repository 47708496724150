import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InitialsComponentBackground } from 'n2p-ui-library/constants';

import { ISpecialExtensionLight } from '@app/services/web-apis/special-extensions/api-special-extensions.domain';

export interface IReassignNumbersPrompt {
	number: string;
	numberAssignedTo: string;
	assignToEntity: string;
	assignToEntityInitials?: string;
	specialExtension: ISpecialExtensionLight;
}

@Component({
	selector: 'n2p-reassign-numbers-prompt',
	templateUrl: './reassign-numbers-prompt.component.html',
	styleUrls: ['./reassign-numbers-prompt.component.scss'],
})
export class ReassignNumbersPromptComponent {
	@Input() reassignedNumber: IReassignNumbersPrompt;
	@Input() initialsBgColor: InitialsComponentBackground;

	@Output() onAccept: EventEmitter<void> = new EventEmitter();

	@Output() onCancel: EventEmitter<void> = new EventEmitter();
	isRemoteRMAOrFive9: boolean;

	ngOnInit(): void {
		this.isRemoteRMAOrFive9 = Boolean(
			this.reassignedNumber.specialExtension &&
				(this.reassignedNumber.specialExtension.businessClass === 'FN' ||
					this.reassignedNumber.specialExtension.businessClass === 'RMA'),
		);
	}
}
