import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SvgModule } from '@n2p/svg';
import { DropdownInputComponent } from './dropdown-input.component';
import { SimpleDropdownInputDataComponent } from './simple-dropdown-input-data/simple-dropdown-input-data.component';

@NgModule({
	declarations: [DropdownInputComponent, SimpleDropdownInputDataComponent],
	imports: [CommonModule, ReactiveFormsModule, SvgModule, OverlayModule],
	exports: [DropdownInputComponent],
	entryComponents: [SimpleDropdownInputDataComponent],
})
export class DropdownInputModule {}
