import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'app-ring-group-calls-status',
	templateUrl: './ring-group-calls-status.component.html',
	styleUrls: ['./ring-group-calls-status.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RingGroupCallsStatusComponent {
	@Input() on: boolean = false;
}
