import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { VoicemailType, VoicemailUploadedType } from '@n2p/edit-voicemail/edit-voicemail.constants';
import { FeatureFlagsService, SnackbarService, AuthStorageData } from '@app/services';
import { IUser } from '@app/services/web-apis/users/api-users.domain';
import { ResetPasswordComponent } from '@app/shared/reset-password/reset-password.component';
import { DialogService } from '@app/services/dialog/dialog.service';
import { IVoicemailForm, IVoicemailObject } from '@n2p/edit-voicemail/edit-voicemail.interfaces';
import { IExtendedDepartment } from '@app/services/web-apis/departments/api-departments.domain';
import { IVoicemailSettings } from '@app/services/web-apis/voicemails/api-voicemails.domain';
import { EditVoicemailService } from '@n2p/edit-voicemail/edit-voicemail.service';

@Component({
	selector: 'n2p-edit-voicemail',
	templateUrl: './edit-voicemail.component.html',
	styleUrls: ['./edit-voicemail.component.scss'],
})
export class EditVoicemailComponent implements OnInit, OnDestroy {
	GREETING_DEFAULT = VoicemailUploadedType.DEFAULT;
	GREETING_CUSTOM = VoicemailUploadedType.CUSTOM;
	GREETING_RECORDED = VoicemailUploadedType.RECORDED;

	form: FormGroup;
	subscriptions = new Subscription();

	type: VoicemailUploadedType;
	isVoicemailEnabled: boolean;
	isNotificationEnabled: boolean;
	isCustomUploaderEnabled: boolean;
	isGreetingRecordingEnabled: boolean;
	isCallerDetailsEnabled: boolean;
	isRestricted: boolean;

	isDataLoading = true;
	isRecording = false;
	isUploading = false;
	isVoicemailLoading = false;

	uploaded: null | string | File = null;
	audio: null | string | File = null;
	model: IVoicemailObject;

	@Input() user?: IUser;
	@Input() department?: IExtendedDepartment;

	@Output() update = new EventEmitter<IVoicemailObject>();

	@ViewChild('container', { static: true }) container: ElementRef;

	constructor(
		private fb: FormBuilder,
		private dialogService: DialogService,
		private snackbarService: SnackbarService,
		private translateService: TranslateService,
		private authStorageDataService: AuthStorageData,
		private featureFlagService: FeatureFlagsService,
		private editVoicemailService: EditVoicemailService,
	) {
		this.initForm();
		this.initFeatureFlags();
		this.initFormFlags();
		this.initUploadedType();
	}

	ngOnInit(): void {
		this.initData();
	}

	ngOnDestroy(): void {
		this.cancelRecording();
		this.subscriptions.unsubscribe();
	}

	defaultVoicemail(isInitial: boolean = true): void {
		this.isVoicemailLoading = true;
		this.editVoicemailService
			.defaultAudio(this.model.id)
			.pipe(finalize(() => (this.isVoicemailLoading = false)))
			.subscribe(
				({ audioContent }) => {
					this.type = VoicemailUploadedType.DEFAULT;
					this.audio = audioContent;
					if (isInitial) return;
					this.snackbarService.createSuccess(
						this.translateService.instant('EDIT_VOICEMAIL.PLAYER.DEFAULT_SUCCESS'),
						this.container,
					);
				},
				() => {
					this.form.patchValue({ type: this.type }, { emitEvent: false });
					if (isInitial) return;
					this.snackbarService.createDanger(
						this.translateService.instant('EDIT_VOICEMAIL.PLAYER.FAILED'),
						this.container,
					);
				},
			);
	}

	recordNewAudioVoicemail(phone: string): void {
		this.isVoicemailLoading = true;

		this.editVoicemailService
			.recordAudio(this.model.id, phone)
			.pipe(
				finalize(() => {
					this.closeUploadingModal();
				}),
			)
			.subscribe(
				({ audioContent }) => {
					this.type = VoicemailUploadedType.RECORDED;
					this.audio = audioContent;
					this.snackbarService.createSuccess(
						this.translateService.instant('EDIT_VOICEMAIL.PLAYER.RECORD_SUCCESS'),
						this.container,
					);
				},
				() => {
					this.form.patchValue({ type: this.type }, { emitEvent: false });
					this.snackbarService.createDanger(
						this.translateService.instant('EDIT_VOICEMAIL.PLAYER.FAILED'),
						this.container,
					);
				},
			);
	}

	cancelRecording(): void {
		this.isRecording = false;
		this.isVoicemailLoading = false;
		this.editVoicemailService.cancelRecording();

		if (this.type !== this.form.get('type').value) {
			this.form.patchValue({ type: this.type }, { emitEvent: false });
		}
	}

	uploadNewAudioVoicemail(): void {
		this.isUploading = true;
		this.isVoicemailLoading = true;

		this.editVoicemailService
			.uploadAudio(this.model.id, this.uploaded)
			.pipe(
				finalize(() => {
					this.closeUploadingModal();
				}),
			)
			.subscribe(
				() => {
					this.type = VoicemailUploadedType.CUSTOM;
					this.audio = this.uploaded;
					this.uploaded = null;
					this.snackbarService.createSuccess(
						this.translateService.instant('EDIT_VOICEMAIL.PLAYER.CUSTOM_SUCCESS'),
						this.container,
					);
				},
				() => {
					this.form.patchValue({ type: this.type }, { emitEvent: false });
					this.snackbarService.createDanger(
						this.translateService.instant('EDIT_VOICEMAIL.PLAYER.FAILED'),
						this.container,
					);
				},
			);
	}

	loadNewAudioVoicemail(file: File): void {
		this.uploaded = file;
	}

	onVoicemailFileError(error: Error): void {
		this.snackbarService.createDanger(error.message);
	}

	closeUploadingModal(): void {
		this.isVoicemailLoading = false;
		this.isUploading = false;
		this.uploaded = null;

		if (this.type !== this.form.get('type').value) {
			this.form.patchValue({ type: this.type }, { emitEvent: false });
		}
	}

	showResetPassword(): void {
		const dialogRef = this.dialogService.create(
			ResetPasswordComponent,
			{},
			{
				width: 510,
				height: 356,
			},
		);

		dialogRef.onDismiss().subscribe(res => {
			if (res && !res.cancelled && res.newPassword) {
				this.updateModel(this.model.id, { voicemailPin: res.newPassword }).subscribe(
					() => {
						this.snackbarService.createSuccess(
							this.translateService.instant('EDIT_VOICEMAIL.PASSWORD.SUCCESS'),
							this.container,
						);
					},
					() => {
						this.snackbarService.createDanger(
							this.translateService.instant('EDIT_VOICEMAIL.PASSWORD.FAILED'),
							this.container,
						);
					},
				);
			}
		});
	}

	private updateModel(id: number, body: Partial<IVoicemailSettings>): Observable<IVoicemailObject> {
		this.isDataLoading = true;

		return this.editVoicemailService.updateVoicemailSettings(id, body).pipe(
			tap(updated => {
				this.isNotificationEnabled = updated.isNotificationEnabled;
				this.isVoicemailEnabled = updated.isVoicemailEnabled;
				this.isRestricted = updated.isRestricted;
				this.model.isNotificationEnabled = updated.isNotificationEnabled;
				this.model.isFileIncluded = updated.isFileIncluded;
				this.model.isTranscriptIncluded = updated.isTranscriptIncluded;
				this.model.isVoicemailEnabled = updated.isVoicemailEnabled;
				this.model.isCallerDetailsIncluded = updated.isCallerDetailsIncluded;
			}),
			catchError(error => {
				this.form.patchValue({
					isFileIncluded: this.model.isFileIncluded,
					isTranscriptIncluded: this.model.isTranscriptIncluded,
					isCallerDetailsIncluded: this.model.isCallerDetailsIncluded,
					isNotificationEnabled: this.model.isNotificationEnabled,
					isVoicemailEnabled: this.model.isVoicemailEnabled,
				});

				throw error;
			}),
			finalize(() => {
				this.isDataLoading = false;
			}),
		);
	}

	private initData(): void {
		if (this.user) {
			this.model = {
				id: this.user.userId,
				isNotificationEnabled: this.user.voicemailNotification.emailNotify,
				isFileIncluded: this.user.voicemailNotification.emailIncludeVM,
				isCallerDetailsIncluded: this.user.voicemailNotification.emailIncludeCallerDetails,
				isTranscriptIncluded: this.user.voicemailNotification.emailTranscribe,
				isVoicemailEnabled: this.user.voicemailEnabled,
				isRestricted: this.user.voicemailNotification.emailRestrictChanges,
			};

			this.editVoicemailService.useType(VoicemailType.USER);
		}

		if (this.department) {
			this.model = {
				id: this.department.deptId,
				isNotificationEnabled: this.department.voicemailNotification.emailNotify,
				isFileIncluded: this.department.voicemailNotification.emailIncludeVM,
				isCallerDetailsIncluded: this.department.voicemailNotification.emailIncludeCallerDetails,
				isTranscriptIncluded: this.department.voicemailNotification.emailTranscribe,
				isVoicemailEnabled: this.department.voicemailEnabled,
				isRestricted: this.department.voicemailNotification.emailRestrictChanges,
			};

			this.editVoicemailService.useType(VoicemailType.DEPARTMENT);
		}

		if (this.model) {
			this.isDataLoading = true;

			const {
				isFileIncluded,
				isTranscriptIncluded,
				isNotificationEnabled,
				isVoicemailEnabled,
				isCallerDetailsIncluded,
				isRestricted,
			} = this.model;

			this.editVoicemailService
				.getAudio(this.model.id)
				.pipe(
					finalize(() => {
						this.form.patchValue({
							isFileIncluded,
							isTranscriptIncluded,
							isNotificationEnabled,
							isVoicemailEnabled,
							isCallerDetailsIncluded,
						});

						this.isVoicemailEnabled = isVoicemailEnabled;
						this.isNotificationEnabled = isNotificationEnabled;
						this.isRestricted = isRestricted;
						this.isVoicemailLoading = false;
						this.isDataLoading = false;
					}),
				)
				.subscribe(
					({ audioContent, defaultFile }) => {
						this.type = defaultFile ? VoicemailUploadedType.DEFAULT : VoicemailUploadedType.CUSTOM;

						this.form.patchValue({ type: this.type }, { emitEvent: false });
						this.audio = audioContent;
					},
					() => (this.audio = null),
				);
		}
	}

	private initForm(): void {
		this.form = this.fb.group(<IVoicemailForm>{
			isNotificationEnabled: false,
			isFileIncluded: false,
			isTranscriptIncluded: false,
			isCallerDetailsIncluded: false,
			isVoicemailEnabled: false,
			type: null,
		});
	}

	private initUploadedType(): void {
		this.subscriptions.add(
			this.form.get('type').valueChanges.subscribe(type => {
				if (this.type === undefined) this.type = type;

				switch (type) {
					case VoicemailUploadedType.CUSTOM:
						this.isRecording = false;
						this.isUploading = true;
						break;
					case VoicemailUploadedType.RECORDED:
						this.isUploading = false;
						this.isRecording = true;
						break;
					case VoicemailUploadedType.DEFAULT:
					default:
						this.isUploading = false;
						this.isRecording = false;
						this.defaultVoicemail(!this.type);
				}
			}),
		);
	}

	private initFormFlags(): void {
		this.subscriptions.add(
			combineLatest([
				this.form.get('isVoicemailEnabled').valueChanges,
				this.form.get('isNotificationEnabled').valueChanges,
				this.form.get('isTranscriptIncluded').valueChanges,
				this.form.get('isFileIncluded').valueChanges,
				this.form.get('isCallerDetailsIncluded').valueChanges,
			]).subscribe(
				([isVoicemailEnabled, isNotificationEnabled, isTranscriptIncluded, isFileIncluded, isCallerDetails]) => {
					const body = {
						voicemailEnabled: isVoicemailEnabled,
						voicemailNotification: {
							emailNotify: isNotificationEnabled,
							emailIncludeVM: isFileIncluded,
							emailTranscribe: isTranscriptIncluded,
							emailIncludeCallerDetails: isCallerDetails,
						},
					};

					const isChanged =
						this.model.isVoicemailEnabled !== body.voicemailEnabled ||
						this.model.isNotificationEnabled !== body.voicemailNotification.emailNotify ||
						this.model.isFileIncluded !== body.voicemailNotification.emailIncludeVM ||
						this.model.isTranscriptIncluded !== body.voicemailNotification.emailTranscribe ||
						this.model.isCallerDetailsIncluded !== body.voicemailNotification.emailIncludeCallerDetails;

					if (isChanged) {
						this.updateModel(this.model.id, body)
							.pipe(
								finalize(() => {
									this.update.emit(this.model);
								}),
							)
							.subscribe(
								() => {},
								() => {
									this.snackbarService.createDanger(
										this.translateService.instant('EDIT_VOICEMAIL.VOICEMAIL_UPDATE.FAILED'),
										this.container,
									);
								},
							);
					}
				},
			),
		);
	}

	private initFeatureFlags(): void {
		this.featureFlagService.uploadVMGreetingUser().subscribe(isEnabled => (this.isCustomUploaderEnabled = isEnabled));
		this.featureFlagService.useVoicemailService().subscribe(isEnabled => (this.isCallerDetailsEnabled = isEnabled));
		this.featureFlagService
			.enableRecordViaPhone30()
			.pipe(map(isEnabled => !this.authStorageDataService.isV3CallServer || isEnabled))
			.subscribe(isEnabled => (this.isGreetingRecordingEnabled = isEnabled));
	}
}
