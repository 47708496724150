import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { DirectivesModule } from '@app/directives/directives.module';
import { SharedModule } from '@app/shared';
import { SchedulesRoutingModule } from '@app/pages/schedules/schedules-routing.module';
//n2p components
import { AvatarModule } from '@n2p/avatar';
import { DialogModule } from '@n2p/dialog/dialog.module';
import { DropdownModule } from '@n2p/dropdown';
import { IconModule } from '@n2p/icon';
import { InputModule } from '@n2p/input/input.module';
import { LoaderModule } from '@n2p/loader';
import { PopupModule } from '@n2p/popup/popup.module';
import { PromptModule } from '@n2p/prompt';
import { ScheduleIntervalModule } from '@n2p/schedule-interval/schedule-interval.module';
//page components
import { SchedulesComponent } from './schedules.component';
import { AddScheduleComponent } from './add-schedule/add-schedule.component';
import { EditScheduleComponent } from './edit-schedule/edit-schedule.component';
import { SvgModule } from '@n2p/svg';
import { SaveBtnComponent } from './components/save-btn/save-btn.component';
import { DeleteScheduleComponent } from './delete-schedule/delete-schedule.component';
//services
import { ScheduleService } from './schedules.service';
import { PipesModule } from '@app/pipes';

@NgModule({
	imports: [
		CommonModule,
		DirectivesModule,
		ReactiveFormsModule,
		SchedulesRoutingModule,
		SharedModule,
		AvatarModule,
		DialogModule,
		DropdownModule,
		IconModule,
		InputModule,
		LoaderModule,
		PromptModule,
		ScheduleIntervalModule,
		SvgModule,
		PopupModule,
		TranslateModule,
		PipesModule,
	],
	declarations: [
		SchedulesComponent,
		AddScheduleComponent,
		EditScheduleComponent,
		SaveBtnComponent,
		DeleteScheduleComponent,
	],
	entryComponents: [AddScheduleComponent, EditScheduleComponent, DeleteScheduleComponent],
	providers: [ScheduleService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SchedulesModule {}
