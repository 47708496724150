import { Component, Input } from '@angular/core';

import { AVATAR_TYPES, avatarTypes, ICON_SIZES } from '@app/Common';
import { Alignment } from '@n2p/common';

@Component({
	selector: 'n2p-td',
	templateUrl: './table-data.component.html',
	styleUrls: ['./table-data.component.scss'],
})
export class TableDataComponent {
	@Input() data: any;
	@Input() type: 'text' | 'avatar' | 'actions' | 'content' = 'text';
	@Input() align: Alignment = Alignment.RIGHT;
	@Input() highlighted = false;
	@Input() dimmed = false;
	@Input() avatarType: avatarTypes = AVATAR_TYPES.default as avatarTypes;

	get avatarSize(): string {
		return ICON_SIZES.names[`medium-${this.highlighted ? 3 : 1}`];
	}

	get isText(): boolean {
		return this.type === 'text';
	}
	get isAvatar(): boolean {
		return this.type === 'avatar';
	}
}
