import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/services/api/api.service';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import {
	IAccountPhone,
	IAccountPhoneCallerIdReplacementOptions,
} from '@app/services/web-apis/phone-numbers/api-phone-numbers.domain';
import { formatAsE164 } from '@app/pages/phone-numbers/utils/formatters.utils';
import { FeatureFlagsService } from '@app/services/feature-flags/feature-flags.service';

@Injectable()
export class ApiPhoneNumbersService {
	private baseUrl: string = '/accounts/{accountId}/phonenumbers';
	private _usePhoneNumberService: boolean;

	constructor(private apiService: ApiService, private featureFlagsService: FeatureFlagsService) {
		this.featureFlagsService.usePhoneNumberService().subscribe(res => (this._usePhoneNumberService = res));
	}

	getAllPhoneNumbers(
		excludeSingleLineUsers: boolean = true,
		excludeVirtualFax: boolean = true,
		includePortingData: boolean = false,
	): Observable<IRegularApiResponse<IAccountPhone[]>> {
		const query = {
			bFilterUsersWithSingleLine: excludeSingleLineUsers,
			bFilterOutVirtualFax: excludeVirtualFax,
			bIncludePendingPortNumbers: includePortingData,
		};

		return this.apiService.get(this.baseUrl, query);
	}

	getCallerIdUsers(lineNumber: string): Observable<any> {
		const url = `/accounts/{accountId}/callerId/${lineNumber}/users`;

		return this.apiService.get(url);
	}

	getPhoneNumberStats(): Observable<any> {
		return this.apiService.get(`${this.baseUrl}/stats`);
	}

	updatePhoneNumber(phone: { number: number | string }): Observable<any> {
		return this.apiService.patch(`${this.baseUrl}/${phone.number}`, phone);
	}

	updateForbiddenAsCallerId(phoneNumber: string, isActive: boolean): Observable<void> {
		return this.apiService
			.patch<IRegularApiResponse<string>>(`${this.baseUrl}/${phoneNumber}/forbidAsCallerId`, { isActive })
			.pipe(
				map(res => {
					if (res.hasError || res.data !== 'success') {
						throw new Error();
					}
				}),
			);
	}

	deletePhoneNumber = (num: number | string): Observable<any> => {
		if (this._usePhoneNumberService) {
			return this.apiService.delete('/catalogs/{catalogId}/phone-numbers', {
				phoneNumber: formatAsE164(num.toString()),
			});
		} else {
			return this.apiService.delete(`${this.baseUrl}/${num}`);
		}
	};

	getCallerIdReplacementOptions(lineNumber: string): Observable<IAccountPhoneCallerIdReplacementOptions> {
		const url = `/accounts/{accountId}/phonenumbers/${lineNumber}/callerIdReplacementOptions`;

		return this.apiService.get<IRegularApiResponse<IAccountPhoneCallerIdReplacementOptions>>(url).pipe(
			map(res => {
				if (res.hasError) {
					throw new Error();
				}
				return res.data;
			}),
		);
	}
}
