import { Component, ElementRef, HostBinding, Inject, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { SNACKBAR_DATA } from 'utils/constants/injectortokens';

@Component({
	selector: 'app-snackbar',
	templateUrl: './snackbar.component.html',
	styleUrls: ['./snackbar.component.scss'],
	animations: [
		trigger('snackbarState', [
			state(
				'inactive',
				style({
					opacity: '0',
				}),
			),
			state(
				'active',
				style({
					opacity: '1',
				}),
			),
			transition('inactive => active', animate('500ms ease-in')),
			transition('active => inactive', animate('500ms ease-out')),
		]),
	],
})
export class SnackbarComponent implements OnInit {
	state = 'inactive';
	@HostBinding('class.snackbar-absolute') isAbsolute = true;
	@HostBinding('class.snackbar-fixed') isFixed = false;
	constructor(
		// tslint:disable-next-line: prefer-inline-decorator
		@Inject(SNACKBAR_DATA)
		public snackbarData: {
			status: 'success' | 'danger';
			callback: any;
			text: string;
			connectTo: ElementRef;
		},
	) {}

	callbackClicked() {
		this.snackbarData.callback();
	}

	ngOnInit() {
		this.isFixed = !this.snackbarData.connectTo;
		setTimeout(() => {
			this.state = 'active';
		}, 2000);
	}
}
