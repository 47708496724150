import { Component, Input, EventEmitter, Output } from '@angular/core';

/*
  Component expands height with increased content
  requires an initial css height if content is bound from some other source
*/

@Component({
	selector: 'shared-textarea',
	templateUrl: './textarea.component.html',
	styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent {
	@Input() label: string;
	@Input() name: string;
	@Input() value: string;
	@Output() valueChanged: EventEmitter<{ value: string }> = new EventEmitter();

	isFocused: boolean;
	elementHeight: string;

	toggleFocus() {
		this.isFocused = !this.isFocused;
	}

	handleChange(event: Event) {
		let target = <HTMLTextAreaElement>event.target;
		let value = target.value;
		// hmmm runloop issues
		setTimeout(() => {
			this.elementHeight = 'auto';
		});
		setTimeout(() => {
			this.elementHeight = `${target.scrollHeight}px`;
		});

		this.valueChanged.emit({ value });
	}
}
