import { Pipe, PipeTransform } from '@angular/core';
import { ICountry } from 'n2p-ui-library/utils/countries.domain';

@Pipe({
	name: 'country',
})
export class CountryPipe implements PipeTransform {
	transform(value: Array<ICountry>, args?: string): any {
		const searchText = new RegExp(args, 'ig');

		if (value) {
			return value.filter((country: ICountry) => country.name.search(searchText) !== -1);
		}
	}
}
