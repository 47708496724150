import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { InputFieldErrorInUseComponent } from '@n2p/input/input-field-errors/input-field-error-in-use/input-field-error-in-use.component';
import { InputFieldErrorMaxLengthComponent } from '@n2p/input/input-field-errors/input-field-error-max-length/input-field-error-max-length.component';
import { InputFieldErrorRequiredComponent } from '@n2p/input/input-field-errors/input-field-error-required/input-field-error-required.component';
import { InputFieldErrorsDirective } from '@n2p/input/input-field-errors/input-field-errors.directive';
import { InputHintModule } from '@n2p/input/input-hint/input-hint.module';
import { InputFieldErrorEmptyComponent } from './input-field-error-empty/input-field-error-empty.component';

@NgModule({
	declarations: [
		InputFieldErrorsDirective,
		InputFieldErrorRequiredComponent,
		InputFieldErrorMaxLengthComponent,
		InputFieldErrorInUseComponent,
		InputFieldErrorEmptyComponent,
	],
	imports: [CommonModule, InputHintModule, TranslateModule],
	exports: [InputFieldErrorsDirective],
	entryComponents: [
		InputFieldErrorRequiredComponent,
		InputFieldErrorMaxLengthComponent,
		InputFieldErrorInUseComponent,
		InputFieldErrorEmptyComponent,
	],
})
export class InputFieldErrorsModule {}
