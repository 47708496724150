import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { translateService } from 'n2p-ui-library/translate/translate.service';

import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGE_VALUES, SupportedLanguageCode } from './translation.config';
import { AuthStorageData } from '@app/services/token';
import { ApiAccountsService, ApiUsersService, GlobalEventsService } from '@app/services';

import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators/take';
import { filter } from 'rxjs/operators/filter';

@Injectable()
export class TranslationService {
	constructor(
		private translateService: TranslateService,
		private authStorageDataService: AuthStorageData,
		private globalEventsService: GlobalEventsService,
		private accountsService: ApiAccountsService,
		private usersService: ApiUsersService,
	) {}

	get languages(): string[] {
		return this.translateService.getLangs();
	}

	get currentLanguage(): SupportedLanguageCode {
		return this.translateService.currentLang as SupportedLanguageCode;
	}

	initialize(): void {
		this.initializeServices();
		this.setLanguage(this.getSupportedLanguageFromLanguageString(this.authStorageDataService.uiLanguageCode));

		combineLatest([
			this.usersService.user$.pipe(
				filter(acc => Boolean(acc)),
				take(1),
			),
			this.accountsService.account$.pipe(
				filter(acc => Boolean(acc)),
				take(1),
			),
		]).subscribe(([{ uiLanguageCode: userLanguage }, { country }]) => {
			const countrySpecificLanguage = this.getSupportedLanguageFromLanguageString(`${userLanguage}-${country}`);
			const currentLanguage = this.translateService.currentLang;

			if (countrySpecificLanguage && currentLanguage !== countrySpecificLanguage) {
				this.setLanguage(countrySpecificLanguage);
			}
		});

		this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
			const language: SupportedLanguageCode =
				this.languages.indexOf(event.lang) !== -1 ? (event.lang as SupportedLanguageCode) : DEFAULT_LANGUAGE;
			this.setLanguage(language);
			this.globalEventsService.languageChange(language);
		});
	}

	private initializeServices(): void {
		this.translateService.addLangs(SUPPORTED_LANGUAGE_VALUES);
		this.translateService.setDefaultLang(DEFAULT_LANGUAGE);
		if (!this.authStorageDataService.uiLanguageCode) {
			this.authStorageDataService.uiLanguageCode = DEFAULT_LANGUAGE;
		}
	}

	private setLanguage(language: SupportedLanguageCode): void {
		this.translateService.use(language);
		translateService.setDefaultLanguage(language as any);
	}

	private getSupportedLanguageFromLanguageString(language: string): SupportedLanguageCode {
		const strippedCountryLanguage = language.split('-')[0];
		const isLanguageSupported = (SUPPORTED_LANGUAGE_VALUES as string[]).includes(language);
		const isStrippedCountrySupported = (SUPPORTED_LANGUAGE_VALUES as string[]).includes(strippedCountryLanguage);
		return isLanguageSupported
			? (language as SupportedLanguageCode)
			: isStrippedCountrySupported
			? (strippedCountryLanguage as SupportedLanguageCode)
			: DEFAULT_LANGUAGE;
	}
}
