import { CountryCode } from 'libphonenumber-js';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ARGENTINA, BRAZIL, MEXICO, US } from '@app/Common';

import { ApiAccountsService } from '@app/services/web-apis/accounts/api-accounts.service';
import { IAccount } from '@app/services/web-apis/accounts/api-accounts.domain';

@Injectable({
	providedIn: 'root',
})
export class ApiTenantService {
	constructor(private accountsService: ApiAccountsService) {}

	get account$(): Observable<IAccount> {
		return this.accountsService.account$;
	}

	get account(): IAccount {
		return this.accountsService.account;
	}

	get countryCode$(): Observable<CountryCode | null> {
		return this.accountsService.account$.pipe(map(account => (account ? account.country : null)));
	}

	get countryCode(): CountryCode | null {
		const { account } = this.accountsService;

		return account ? account.country : null;
	}

	isAccountCountries$(countryCodes: CountryCode[]): Observable<boolean> {
		return this.countryCode$.pipe(map(country => countryCodes.includes(country)));
	}

	isAccountCountries(restrictedCountries: CountryCode[]): boolean {
		return restrictedCountries.includes(this.countryCode);
	}

	isBrazilAccount$(): Observable<boolean> {
		return this.isAccountCountries$(BRAZIL);
	}

	isMexicoAccount$(): Observable<boolean> {
		return this.isAccountCountries$(MEXICO);
	}

	isUSAccount$(): Observable<boolean> {
		return this.isAccountCountries$(US);
	}

	isArgentinaAccount$(): Observable<boolean> {
		return this.isAccountCountries$(ARGENTINA);
	}

	isBrazilAccount(): boolean {
		return this.isAccountCountries(BRAZIL);
	}

	isMexicoAccount(): boolean {
		return this.isAccountCountries(MEXICO);
	}

	isUSAccount(): boolean {
		return this.isAccountCountries(US);
	}

	isArgentinaAccount(): boolean {
		return this.isAccountCountries(ARGENTINA);
	}

	get accountCountryIsDidLess$(): Observable<boolean> {
		return this.account$.pipe(map(account => account.canCreateDidlessUsers));
	}

	get accountCountryIsDidLess(): boolean {
		return this.account.canCreateDidlessUsers;
	}

	get accountMaxExtensionLength(): number {
		return this.account.accountPolicies[0].maxExtensionLength;
	}
}
