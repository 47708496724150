import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { TranslateModule } from '@ngx-translate/core';

import { PromptService } from '@n2p/prompt/prompt.service';
import { PromptComponent } from './prompt.component';
import { SvgModule } from '@n2p/svg';
import { EscapeHandlerModule } from '@app/directives/escape-handler';

@NgModule({
	declarations: [PromptComponent],
	imports: [CommonModule, OverlayModule, PortalModule, TranslateModule, SvgModule, EscapeHandlerModule],
	providers: [PromptService],
	entryComponents: [PromptComponent],
	exports: [],
})
export class PromptModule {}
