import { Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@utils/constants/injectortokens';

@Component({
	selector: 'app-unsaved-changes-dialog',
	templateUrl: './unsaved-changes-dialog.component.html',
	styleUrls: ['./unsaved-changes-dialog.component.scss'],
})
export class UnsavedChangesDialogComponent {
	type: string;
	constructor(@Inject(DIALOG_DATA) data: any) {
		this.type = data.type ?? 'error';
	}
}
