import { Component, Input } from '@angular/core';

@Component({
	selector: 'n2p-dialog-icon-title',
	templateUrl: './dialog-icon-title.component.html',
	styleUrls: ['./dialog-icon-title.component.scss'],
})
export class DialogIconTitleComponent {
	@Input() title: string;
	@Input() iconName: string;
	@Input() circleColor: string;
	@Input() iconColor: string;
}
