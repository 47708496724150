import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, pipe } from 'rxjs';

import { CommonService, FeatureFlagsService } from '@app/services';
import { CallOptionsService } from '@app/pages/teammembers/call-options/call-options.service';

@Component({
	selector: 'n2p-call-options-recording',
	templateUrl: './call-options-recording.component.html',
	styleUrls: ['./call-options-recording.component.scss', '../../call-options.shared.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallOptionsRecordingComponent implements OnInit {
	constructor(
		public callOptionsService: CallOptionsService,
		private commonService: CommonService,
		private featureFlagsService: FeatureFlagsService,
	) {}

	ngOnInit(): void {}

	get showToggle(): Observable<boolean> {
		return this.featureFlagsService
			.showOnlyCallRecordingStatus()
			.map(pipe(showOnlyCallRecordingStatus => !showOnlyCallRecordingStatus || this.commonService.isAdmin()));
	}
}
