import { Component, Output, EventEmitter, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

import { IScheduleRule } from '@app/services/web-apis/schedules/api-schedules.domain';
import { DateFormatterService } from '@app/services';
import { formatTimeString, getAmPmFromTimeString, getHourMinuteFromTimeString } from '@utils/helpers/functions';

enum ScheduleType {
	ALL_DAY,
	CUSTOM,
}

enum ScheduleIntervalType {
	WEEKDAYS = 'weekdays',
	CALENDAR = 'calendar',
}

interface IScheduleRange {
	startDate: Date;
	endDate: Date;
}

interface IScheduleIntervalFormValue {
	name: string;
	from?: string;
	to?: string;
	weekDays?: number[];
	dates?: IScheduleRange | Date[];
}

@Component({
	selector: 'n2p-call-options-schedule',
	templateUrl: './call-options-schedule.component.html',
	styleUrls: ['./call-options-schedule.component.scss', '../../call-options.shared.scss'],
})
export class CallOptionsScheduleComponent implements OnInit {
	@Output() change: EventEmitter<IScheduleRule[]> = new EventEmitter();

	ScheduleType: typeof ScheduleType = ScheduleType;
	ScheduleIntervalType: typeof ScheduleIntervalType = ScheduleIntervalType;

	@Input() rules: IScheduleRule[] = [];
	@Input() showCalendarToggle: boolean = true;

	public currentScheduleType: ScheduleType = ScheduleType.ALL_DAY;
	public form: FormGroup;

	private subscription: Subscription;

	constructor(private fb: FormBuilder, private dateFormatter: DateFormatterService) {}

	get intervals(): FormArray {
		return this.form.get('intervals') as FormArray;
	}

	ngOnInit(): void {
		this.currentScheduleType = this.rules ? ScheduleType.CUSTOM : ScheduleType.ALL_DAY;
		this.createForm(this.rules);
	}

	ngOnDestroy(): void {
		this.unsubscribe();
	}

	switchScheduleType([type]: [ScheduleType], rules: IScheduleRule[] = this.rules): void {
		this.currentScheduleType = type;
		this.createForm(rules);
		this.change.emit(type === ScheduleType.ALL_DAY ? undefined : this.rules || []);
	}

	addInterval(type: ScheduleIntervalType): void {
		const length = this.intervals.length;
		this.intervals.push(
			this.fb.group({
				name: `rule${length}`,
				type,
			}),
		);
	}

	removeInterval(index: number): void {
		this.intervals.removeAt(index);
	}

	private createForm(rules: IScheduleRule[] = this.rules): void {
		this.form = this.fb.group({
			intervals: this.fb.array(
				(rules || []).map((rule: IScheduleRule) => {
					const value: any = {
						name: rule.name,
						type: rule.days && rule.days.weekDays ? ScheduleIntervalType.WEEKDAYS : ScheduleIntervalType.CALENDAR,
					};
					if (rule.time) {
						value.from = [rule.time.start, Validators.required];
						value.to = [rule.time.end, Validators.required];
						value.fromToFormGroup = this.fb.group({
							from: [getHourMinuteFromTimeString(rule.time.start)],
							fromAmPm: [getAmPmFromTimeString(rule.time.start)],
							to: [getHourMinuteFromTimeString(rule.time.end)],
							toAmPm: [getAmPmFromTimeString(rule.time.end)],
						});
					}
					if (rule.days && rule.days.weekDays) {
						value.weekDays = [rule.days.weekDays];
					} else if (rule.days && rule.days.isRange) {
						const dates = rule.days.dates || [];
						value.dates = [
							{
								startDate: new Date(dates[0] || 0),
								endDate: new Date(dates[1] || 0),
							},
						];
					} else if (Array.isArray(rule.days.dates) && !rule.days.isRange) {
						value.dates = [rule.days.dates.map(dt => new Date(dt))];
					}
					return this.fb.group(value);
				}),
			),
		});
		this.unsubscribe();
		this.subscription = this.form.valueChanges.subscribe(value => this.onFormUpdate(value));
	}

	private onFormUpdate(value: { intervals: IScheduleIntervalFormValue[] }): void {
		this.change.emit(
			value.intervals.map(interval => ({
				name: interval.name || '',
				time: {
					start: formatTimeString(interval.from || ''),
					end: formatTimeString(interval.to || ''),
				},
				days: {
					weekDays: interval.weekDays || null,
					dates: Array.isArray(interval.dates)
						? interval.dates.map(date => this.dateFormatter.format(date, 'YYYY-MM-DD'))
						: typeof interval.dates === 'object'
						? [
								interval.dates.startDate ? this.dateFormatter.format(interval.dates.startDate, 'YYYY-MM-DD') : '',
								interval.dates.endDate ? this.dateFormatter.format(interval.dates.endDate, 'YYYY-MM-DD') : '',
						  ]
						: undefined,
					isRange: !!(
						typeof interval.dates === 'object' &&
						(interval.dates as IScheduleRange).startDate &&
						(interval.dates as IScheduleRange).endDate
					),
				},
			})),
		);
	}

	private unsubscribe(): void {
		this.subscription && this.subscription.unsubscribe();
	}
}
