import { Component, Inject, OnInit } from '@angular/core';

import { DIALOG_REF } from '@n2p/dialog/dialog.injectors';
import { DialogRef } from '@n2p/dialog/dialogRef';

@Component({
	selector: 'n2p-add-forwarding-number',
	templateUrl: './add-forwarding-number.component.html',
	styleUrls: ['./add-forwarding-number.component.scss'],
})
export class AddForwardingNumberComponent implements OnInit {
	constructor(@Inject(DIALOG_REF) private dialogRef: DialogRef) {}

	onSubmit(num): void {
		this.dialogRef.dismiss(num);
	}

	onCancel(): void {
		this.dialogRef.dismiss();
	}

	ngOnInit(): void {}
}
