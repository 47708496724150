import { IAvatar } from '@app/services/callhistory/callhistory.domain';

export interface IVoiceMailMessage {
	message: string;
}

export type VoicemailOwnerType = 'US' | 'DP';

export interface IConcreteVoicemail {
	message: string;
	from: string;
	fromName: string;
	date: string;
	transcript: string;
}

export interface IUnreadVoicemails {
	voicemailId: number;
	ownerId: number;
	from: { number: string };
	to: {
		name: string;
		departments: number[];
		avatars: IAvatar[];
	};
	duration: number;
	voicemailTime: string;
	type: string;
}

export enum IVoiceMailStatus {
	NEW = 'N',
	READ = 'S',
	DELETED = 'D',
}

export interface IVoiceMailStatusResponse {
	accountId: number;
	status: IVoiceMailStatus;
	ownerId: number;
	messageId: number;
	businessClass: string;
}

export interface IVoicemailSettings {
	voicemailPin?: string;
	voicemailEnabled: boolean;
	voicemailNotification: IUserVoicemailNotificationSettings;
}

export interface IVoicemailNotificationSettings {
	emailNotify: boolean;
	emailTranscribe: boolean;
	emailIncludeCallerDetails: boolean;
	emailRestrictChanges?: boolean;
}

export interface IAccountVoicemailNotificationSettings extends IVoicemailNotificationSettings {
	emailIncludeVoicemail: boolean;
}

export interface IUserVoicemailNotificationSettings extends IVoicemailNotificationSettings {
	emailIncludeVM: boolean;
}

export interface IVoicemailLink {
	url: string;
}
