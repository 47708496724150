import { InjectionToken } from '@angular/core';

export const DIALOG_DATA = new InjectionToken<{}>('DIALOG_DATA');
export const DIALOG_REF = new InjectionToken<{}>('DIALOG_REF');
export const DIALOG_CONFIG = new InjectionToken<{}>('DIALOG_CONFIG');
export const DIALOG_COMPONENT = new InjectionToken<{}>('DIALOG_COMPONENT');

export const MULTISELECT_FORM_GROUP = new InjectionToken<{}>('MULTISELECT_FORM_GROUP');

export const MULTISELECT_DATA = new InjectionToken<{}>('MULTISELECT_DATA');
export const MULTISELECT_DATA_COMPONENT = new InjectionToken<{}>('MULTISELECT_DATA_COMPONENT');
export const MULTISELECT_DATA_FILTER = new InjectionToken<{}>('MULTISELECT_DATA_FILTER');
export const MULTISELECT_DATA_CLICK_CALLBACK = new InjectionToken<{}>('MULTISELECT_DATA_CLICK_CALLBACK');
export const MULTISELECT_DATA_CHIP_CLOSED = new InjectionToken<{}>('MULTISELECT_DATA_CHIP_CLOSED');
export const MULTISELECT_DATA_CONFIG = new InjectionToken<{}>('MULTISELECT_DATA_CONFIG');
export const MULTISELECT_PAGE = new InjectionToken<{}>('MULTISELECT_PAGE');

export const MULTISELECT_CHIP = new InjectionToken<{}>('MULTISELECT_CHIP');
export const MULTISELECT_CHIP_CLICK_CALLBACK = new InjectionToken<{}>('MULTISELECT_CHIP_CLICK_CALLBACK');
export const MULTISELECT_CHIP_MORE_REF = new InjectionToken<{}>('MULTISELECT_CHIP_MORE_REF');

export const DROPDOWN_DATA = new InjectionToken<{}>('DROPDOWN_DATA');
export const DROPDOWN_SELECTED_DATA = new InjectionToken<{}>('DROPDOWN_SELECTED_DATA');
export const DROPDOWN_DATA_CLICK_CALLBACK = new InjectionToken<{}>('DROPDOWN_DATA_CLICK_CALLBACK');
export const DROPDOWN_DATA_FILTER = new InjectionToken<{}>('DROPDOWN_DATA_FILTER');

export const SNACKBAR_DATA = new InjectionToken<{}>('SNACKBAR_DATA');

export const PHONE_NUMBERS_RESULT = new InjectionToken<{}>('PHONE_NUMBERS_RESULT');
export const PHONE_NUMBERS_SELETED = new InjectionToken<{}>('PHONE_NUMBERS_SELETED');
export const PHONE_NUMBERS_DATA = new InjectionToken<{}>('PHONE_NUMBERS_DATA');
export const PHONE_NUMBERS_SINGLE_SELECT = new InjectionToken<{}>('PHONE_NUMBERS_SINGLE_SELECT');

export const TOOLTIP_TEXT = new InjectionToken<{}>('TOOLTIP_TEXT');
export const TOOLTIP_POSITION = new InjectionToken<{}>('TOOLTIP_POSITION');
export const TOOLTIP_COMPONENT = new InjectionToken<{}>('TOOLTIP_COMPONENT');

export const ADDITIONAL_OPTIONS = new InjectionToken<{}>('ADDITIONAL_OPTIONS');

/*
 * Voicemail Popup
 */
export const VOICEMAIL_POPUP_REF = new InjectionToken<{}>('VOICEMAIL_POPUP_REF');
export const VOICEMAIL_POPUP_CALL_DATA = new InjectionToken<{}>('VOICEMAIL_POPUP_CALL_DATA');
export const VOICEMAIL_POPUP_FUNCS = new InjectionToken<{}>('VOICEMAIL_POPUP_FUNCS');
export const DELETE_VOICEMAIL_PROMPT_REF = new InjectionToken<{}>('DELETE_VOICEMAIL_PROMPT_REF');
export const DELETE_VOICEMAIL_PROMPT_DATA = new InjectionToken<{}>('DELETE_VOICEMAIL_PROMPT_DATA');

export const POPUP_DATA = new InjectionToken<{}>('POPUP_DATA');
export const POPUP_REF = new InjectionToken<{}>('POPUP_REF');
export const POPUP_CALLBACK = new InjectionToken<{}>('POPUP_CALLBACK');

/*
 * Stepper
 */
export const STEPPER_REF = new InjectionToken<{}>('STEPPER_REF');
export const STEPPER_DATA = new InjectionToken<{}>('STEPPER_DATA');
export const STEPPER_FUNCS = new InjectionToken<{}>('STEPPER_FUNCS');
