import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'n2p-call-options-rg-status',
	templateUrl: './call-options-rg-status.component.html',
	styleUrls: ['./call-options-rg-status.component.scss', '../../call-options.shared.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallOptionsRgStatusComponent {
	@Input() isEnabled: boolean;
}
