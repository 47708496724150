import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

import { DialogService as N2PDialogService } from '@n2p';
import {
	DeleteEntityModel,
	DialogDeleteEntityComponent,
} from '@n2p/dialog/dialog-delete-entity/dialog-delete-entity.component';
import { DialogService } from '@app/services/dialog/dialog.service';
import { AddwelcomemenuComponent } from './addwelcomemenu/addwelcomemenu.component';
import { WelcomeMenuService } from '@app/services/welcome-menu/welcome-menu.service';
import { EditWelcomeMenuComponent } from './edit-welcome-menu/edit-welcome-menu.component';
import { CommonService } from '@app/services/Common.service';
import { IPhone } from '@app/Common/interfaces/IPhone';
import { GlobalLoaderService, Loader } from '@app/services/global-loader/global-loader.service';
import { SnackbarService } from '@app/services/snackbar/snackbar.service';
import {
	GA_WELCOME_MENUS_ADD,
	GA_WELCOME_MENUS_DELETE,
	GA_WELCOME_MENUS_EDIT,
	GoogleAnalyticsService,
} from '@app/services/google-analytics';

@Component({
	selector: 'app-welcomemenu',
	templateUrl: './welcomemenu.component.html',
	styleUrls: ['./welcomemenu.component.scss'],
	providers: [GlobalLoaderService],
})
export class WelcomemenuComponent implements OnInit, OnDestroy {
	@ViewChild('welcomeMenuRef', { static: true }) welcomeMenuRef: ElementRef;
	welcomeMenu: Observable<any>;
	counterData: Array<{ key: string; value: number }> = [];
	phones: Array<IPhone> = [];
	loader: Loader;
	audio = new Audio();
	isAdmin = this.commonService.isAdmin();
	_subscriptions: Array<Subscription> = [];

	constructor(
		private dialogService: DialogService,
		public welcomeMenuService: WelcomeMenuService,
		private commonService: CommonService,
		private globalLoaderService: GlobalLoaderService,
		private snackbarService: SnackbarService,
		private translate: TranslateService,
		private n2pDialogService: N2PDialogService,
		private gtag: GoogleAnalyticsService,
	) {
		this.welcomeMenu = this.welcomeMenuService.welcomeMenus.pipe(
			tap(welcomeMenu => this.setCounterData(welcomeMenu.length)),
		);
	}

	showAddWelcomeMenuDialog(): void {
		this.pauseAllWM();
		const dialogRef = this.dialogService.create(
			AddwelcomemenuComponent,
			{},
			{
				width: 554,
			},
		);
		dialogRef.onDismiss().subscribe(res => {
			if (res && res.hasError) {
				this.snackbarService.create({
					status: 'danger',
					text: res.message,
					connectTo: undefined,
				});
			} else if (res) {
				this.loadWelcomeMenu();
				this.snackbarService.create({
					status: 'success',
					text: this.translate.instant('WELCOMEMENU.WM_ADDED'),
					connectTo: undefined,
				});
				this.gtag.event(GA_WELCOME_MENUS_ADD);
			}
		});
	}

	showEditWelcomeMenuDialog(wm): void {
		this.pauseAllWM();
		const dialogRef = this.dialogService.create(EditWelcomeMenuComponent, wm, {
			width: 554,
		});
		dialogRef.onDismiss().subscribe(res => {
			if (res && res.hasError) {
				this.snackbarService.create({
					status: 'danger',
					text: this.translate.instant('WELCOMEMENU.TRY_AGAIN'),
					connectTo: undefined,
				});
			} else if (res) {
				this.loadWelcomeMenu();
				this.snackbarService.create({
					status: 'success',
					text: this.translate.instant('WELCOMEMENU.CHANGES'),
					connectTo: undefined,
				});
				this.gtag.event(GA_WELCOME_MENUS_EDIT);
			}
		});
	}

	remove(wm): void {
		this.pauseAllWM();
		const entityModel: DeleteEntityModel = {
			entityId: wm.id,
			entityName: wm.name,
			entityType: 'welcome-menu',
			assignedNumbers: wm.lines,
			entity: { ...wm },
		};
		const promptRef = this.n2pDialogService.create(DialogDeleteEntityComponent, entityModel, {
			width: 542,
		});
		promptRef.onDismiss().subscribe(res => {
			if (res) {
				this.loader.show();
				this.welcomeMenuService.removeWelcomeMenu(wm).then(
					() => {
						this.snackbarService.create({
							status: 'success',
							text: this.translate.instant('WELCOMEMENU.WM_REMOVED'),
							connectTo: undefined,
						});
						this.loadWelcomeMenu();
						this.gtag.event(GA_WELCOME_MENUS_DELETE);
					},
					() => {
						this.loader.hide();
						this.snackbarService.create({
							status: 'danger',
							text: this.translate.instant('WELCOMEMENU.UNABLE'),
							connectTo: undefined,
						});
					},
				);
			}
		});
	}

	play(wm): void {
		this.pauseAllWM();
		wm.playFillColor = 'rgba(51, 51, 51, 0.5)';
		wm.audioIsPlaying = true;
		if (wm.audioIsReady) {
			wm.audio.play();
		} else {
			wm.audioIsLoading = true;
			this.welcomeMenuService.getActiveGreeting(wm.id).subscribe(res => {
				wm.audioIsLoading = false;
				wm.audioIsReady = true;
				wm.audio = new Audio();
				wm.audio.src = `data:audio/wav;base64,${res.data[0].file}`;
				wm.audio.addEventListener('ended', () => {
					wm.audioIsPlaying = false;
					wm.audioIsReady = false;
					wm.audio = undefined;
				});
				wm.audio.play();
			});
		}
	}

	pause(wm): void {
		wm.audioIsPlaying = false;
		wm.audio.pause();
	}

	filter(str): void {
		this.welcomeMenuService.filter(str);
	}

	updateAssignedNumbers(lines, wm): void {
		this.loader.show();
		this.welcomeMenuService
			.editWelcomeMenu({
				id: wm.id,
				name: wm.name,
				lines,
			})
			.then(() => this.loadWelcomeMenu());
	}

	ngOnInit(): void {
		this.loader = this.globalLoaderService.create(this.welcomeMenuRef);
		this.loadWelcomeMenu();
	}

	ngOnDestroy(): void {
		this._subscriptions.forEach(s => s.unsubscribe());
		this.loader.hide();
		this.pauseAllWM();
	}

	private loadWelcomeMenu(): void {
		this.loader.show();
		this.welcomeMenuService.loadWelcomeMenus().then(() => this.loader.hide());
	}

	private pauseAllWM(): void {
		this.welcomeMenuService.getDataStoreItem('welcomeMenus').forEach(i => {
			if (i.audioIsPlaying) {
				this.pause(i);
			}
		});
	}

	private setCounterData(count: number): void {
		this.counterData[0] = { key: this.translate.instant('TEAMMEMBERS_PAGE.TOTAL'), value: count };
	}
}
