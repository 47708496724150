import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';

import { ApiService } from '@app/services/api';

import { ICampaignPhoneRegistrationsResponse } from '@app/services/web-apis/ten-dlc/ten-dlc.domain';

@Injectable()
export class ApiTenDlcService {
	private campaignsBaseUrl: string = '/v2/campaign-registry-campaigns/';

	constructor(private apiService: ApiService) {}

	getPhoneNumberRegistrations(): Observable<ICampaignPhoneRegistrationsResponse> {
		return this.apiService.get(`${this.campaignsBaseUrl}-/phone-number-registrations`, {
			page_index: 0,
			page_size: 100,
		});
	}

	checkPhoneNumberRegistration(phoneNumber: string | string[]): Observable<string[]> {
		const phoneNumbers = Array.isArray(phoneNumber) ? phoneNumber : [phoneNumber];
		const searches = phoneNumbers.map(phoneNumber => String(phoneNumber).replace(/[^0-9]/g, ''));
		return this.getPhoneNumberRegistrations().pipe(
			map(resp => {
				const tenDlcPhoneNumbers = (resp.items || []).reduce((acc, registration) => {
					return [...acc, ...(registration.phone_numbers || []).map((number: any) => number.phone_number)];
				}, []);
				let unregistered: string[] = [];
				searches.forEach(number => {
					const registered = tenDlcPhoneNumbers.includes(number);
					if (!registered) {
						unregistered = [...unregistered, number];
					}
				});
				return unregistered;
			}),
		);
	}
}
