import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

import {
	DROPDOWN_DATA,
	DROPDOWN_DATA_CONFIG,
	DROPDOWN_DATA_FILTER,
	DROPDOWN_PAGE,
	DROPDOWN_PAGE_NEXT,
} from '@n2p/dropdown/dropdown.injectors';
import { matchSearchString } from '@utils/helpers/functions';

@Component({
	selector: 'app-send-sms-to-data',
	templateUrl: './send-sms-to-data.component.html',
	styleUrls: ['./send-sms-to-data.component.scss'],
})
export class SendSmsToDataComponent implements OnInit {
	smsToControl: FormControl;
	pageSubject: Observable<number>;
	entityToShow: string;
	searchStr: string;
	required;
	constructor(
		@Inject(DROPDOWN_DATA) data: any,
		@Inject(DROPDOWN_PAGE) page: Observable<number>,
		@Inject(DROPDOWN_PAGE_NEXT) private nextPage: any,
		@Inject(DROPDOWN_DATA_CONFIG) private config: any,
		@Inject(DROPDOWN_DATA_FILTER) private searchControl: FormControl,
	) {
		this.pageSubject = page;
		this.smsToControl = data;
		this.required = this.config.isRequired;
	}

	get smsToData(): Array<any> {
		return this.searchStr
			? this.smsToControl.value.filter(i =>
					matchSearchString(this.searchStr, [i.value, i.subValue, i.number, i.extension]),
			  )
			: this.smsToControl.value.filter(i => i.type === this.entityToShow);
	}

	showTeamMembers(): void {
		this.entityToShow = 'team-member';
		this.nextPage();
	}

	showDepartments(): void {
		this.entityToShow = 'department';
		this.nextPage();
	}

	itemClicked(item): void {
		if (this.required && this.smsToControl.value.filter(i => i.selected).length === 1 && item.selected) return;
		if (this.config.singleSelection) {
			this.clearAllSelected(item);
		}
		item.selected = !item.selected;
		this.smsToControl.updateValueAndValidity();
	}

	allCompanyClicked(): void {
		const index = this.smsToControl.value.findIndex(i => i.type === 'all-company');
		if (this.required && this.smsToControl.value[index].selected) return;
		this.clearAllSelected(this.smsToControl.value[index]);
		this.smsToControl.value[index].selected = !this.smsToControl.value[index].selected;
		this.smsToControl.updateValueAndValidity();
	}

	ngOnInit(): void {
		this.searchControl.valueChanges.subscribe(value => (this.searchStr = value));
	}

	private clearAllSelected(item): void {
		for (let i = 0; i < this.smsToControl.value.length; i++) {
			if (this.smsToControl.value[i].value !== item.value) {
				this.smsToControl.value[i].selected = false;
			}
		}
	}
}
