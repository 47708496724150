import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonGroupComponent } from './button-group.component';
import { ButtonGroupItemComponent } from './button-group-item/button-group-item.component';

@NgModule({
	declarations: [ButtonGroupComponent, ButtonGroupItemComponent],
	imports: [CommonModule],
	exports: [ButtonGroupComponent, ButtonGroupItemComponent],
})
export class ButtonGroupModule {}
