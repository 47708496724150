import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';

import { ApiPlansService, CommonService } from '@app/services';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
@Component({
	selector: 'app-page-header',
	templateUrl: './page-header.component.html',
	styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
	@Input() showToggle = true;

	@Output() isAccountSelected: EventEmitter<boolean> = new EventEmitter();

	isAdmin = false;
	title = '';
	billingStartDate = '';
	billingEndDate = '';
	filterList = ['Mine', 'Company'];
	selectedFilter = this.filterList[0];
	public isBrazilAccount$: Observable<boolean> = this.apiTenantService.isBrazilAccount$();
	public isMexicoAccount$: Observable<boolean> = this.apiTenantService.isMexicoAccount$();

	constructor(
		private plansAPI: ApiPlansService,
		private translate: TranslateService,
		private commonService: CommonService,
		private router: Router,
		private apiTenantService: ApiTenantService,
	) {}

	ngOnInit(): void {
		this.getAccountPlan();
		this.getTitle();
		this.isAdmin = this.commonService.isAdmin();
	}

	getAccountPlan(): void {
		this.plansAPI.getAccountPlan().subscribe(result => {
			if (result.data) {
				this.billingStartDate = result.data[0].currBillingDate;
				this.billingEndDate = result.data[0].nextBillingDate;
			}
		});
	}

	getTitle(): void {
		const url = this.router.url.split('?')[0].split('/');
		// TODO add logic as other pages are added
		switch (url[1]) {
			case 'callhistory':
				this.title = 'call history';
				break;
			case 'dashboard':
				this.title = this.translate.instant('DASHBOARD.TITLE');
				break;
			case 'analytics':
				this.title = this.translate.instant('ANALYTICS.TITLE');
				break;
			default:
				this.title = url[1];
		}
	}

	changeFilter(newFilter: string): void {
		if (this.selectedFilter !== newFilter) {
			this.selectedFilter = newFilter;
			this.isAccountSelected.emit(this.filterList[1] === newFilter);
		}
	}
}
