import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared';
import { WelcomemenuComponent } from '@app/pages/welcomemenu/welcomemenu.component';
import { AddwelcomemenuComponent } from '@app/pages/welcomemenu/addwelcomemenu/addwelcomemenu.component';
import { AddphonenumberComponent } from '@app/pages/welcomemenu/addwelcomemenu/addphonenumber/addphonenumber.component';
import { AssignedNumbersChipComponent } from '@app/pages/welcomemenu/addwelcomemenu/assigned-numbers/assigned-numbers-chip/assigned-numbers-chip.component';
import { AssignedNumbersPromptComponent } from '@app/pages/welcomemenu/addwelcomemenu/assigned-numbers/assigned-numbers-prompt/assigned-numbers-prompt.component';
import { WelcomeMenuTtsComponent } from '@app/pages/welcomemenu/addwelcomemenu/welcome-menu-tts/welcome-menu-tts.component';
import { MenuOptionsComponent } from '@app/pages/welcomemenu/addwelcomemenu/menu-options/menu-options.component';
import { MenuOptionsKeysDataComponent } from '@app/pages/welcomemenu/addwelcomemenu/menu-options/menu-options-keys-data/menu-options-keys-data.component';
import { MenuOptionsItemComponent } from '@app/pages/welcomemenu/addwelcomemenu/menu-options/menu-options-item/menu-options-item.component';
import { GreetingComponent } from '@app/pages/welcomemenu/addwelcomemenu/greeting/greeting.component';
import { AddWelcomeMenuCancelPromptComponent } from '@app/pages/welcomemenu/addwelcomemenu';
import { EditWelcomeMenuComponent } from '@app/pages/welcomemenu/edit-welcome-menu/edit-welcome-menu.component';
import { MenuOptionsStickyHeaderComponent } from '@app/pages/welcomemenu/addwelcomemenu/menu-options-sticky-header/menu-options-sticky-header.component';
import { MenuOptionStickyHeaderItemComponent } from '@app/pages/welcomemenu/addwelcomemenu/menu-options-sticky-header/menu-option-sticky-header-item/menu-option-sticky-header-item.component';
import { WelcomeMenuAssignedNumbersComponent } from '@app/pages/welcomemenu/welcome-menu-assigned-numbers/welcome-menu-assigned-numbers.component';
import { DirectivesModule } from '@app/directives/directives.module';
import { PipesModule } from '@app/pipes';
import { AssignedNumbersModule, DialogModule, DropdownModule, InputModule, LoaderModule } from '@n2p';
import { DialogDeleteEntityModule } from '@n2p/dialog/dialog-delete-entity/dialog-delete-entity.module';
import { IfCallNoAnswerDropdownModule } from '@n2p/if-call-no-answer-dropdown/if-call-no-answer-dropdown.module';
import { SendSmsToModule } from '@n2p/send-sms-to/send-sms-to.module';
import { RadioButtonModule } from '@n2p/radio-button/radio-button.module';
import { ShowHideModule } from '@app/directives/show-hide/show-hide.module';
import { InputExtensionModule } from '@n2p/input-extension/input-extension.module';

@NgModule({
	imports: [
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
		PipesModule,
		InputModule,
		AssignedNumbersModule,
		SendSmsToModule,
		IfCallNoAnswerDropdownModule,
		DropdownModule,
		DialogModule,
		DialogDeleteEntityModule,
		RadioButtonModule,
		LoaderModule,
		ShowHideModule,
		InputExtensionModule,
	],
	declarations: [
		WelcomemenuComponent,
		AddwelcomemenuComponent,
		AddphonenumberComponent,
		AssignedNumbersChipComponent,
		AssignedNumbersPromptComponent,
		WelcomeMenuTtsComponent,
		MenuOptionsComponent,
		MenuOptionsKeysDataComponent,
		MenuOptionsItemComponent,
		GreetingComponent,
		AddWelcomeMenuCancelPromptComponent,
		EditWelcomeMenuComponent,
		MenuOptionsStickyHeaderComponent,
		MenuOptionStickyHeaderItemComponent,
		WelcomeMenuAssignedNumbersComponent,
	],
	entryComponents: [
		WelcomemenuComponent,
		AddwelcomemenuComponent,
		AssignedNumbersChipComponent,
		AssignedNumbersPromptComponent,
		WelcomeMenuTtsComponent,
		MenuOptionsKeysDataComponent,
		AddWelcomeMenuCancelPromptComponent,
		EditWelcomeMenuComponent,
	],
	exports: [WelcomemenuComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WelcomemenuModule {}
