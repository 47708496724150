import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiService } from '../api';

@Injectable({ providedIn: 'root' })
export class ProfileSettingsService {
	private baseUrl: string;

	constructor(private apiService: ApiService) {
		this.baseUrl = environment.api_profile_settings;
	}

	setSPOGPreferredWebApplication() {
		return this.apiService.post(`${this.baseUrl}/personal-settings:set-preferred-web-application`, {
			preferred_web_application: 'spog',
		});
	}
}
