import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
	selector: '[appRemoveIconBtn]',
})
export class RemoveIconBtnDirective implements OnInit {
	div;
	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@HostListener('mouseover') onMouseOver(): void {
		this.renderer.setStyle(this.div, 'color', '#ff8c98');
	}

	@HostListener('mousedown') onMouseDown(): void {
		this.renderer.setStyle(this.div, 'color', '#ae2540');
	}

	@HostListener('mouseleave') onMouseLeave(): void {
		this.renderer.setStyle(this.div, 'color', 'rgba(51, 51, 51, 0.5)');
	}

	ngOnInit(): void {
		this.div = this.el.nativeElement.querySelector('div');
		setTimeout(() => this.renderer.setStyle(this.div, 'color', 'rgba(51, 51, 51, 0.5)'));
	}
}
