import { Inject, Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	CanLoad,
	Route,
	Router,
	RouterStateSnapshot,
	UrlSegment,
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { tap, take } from 'rxjs/operators';

import { AuthService } from '@app/services/auth';
import { environment } from '@env/environment';
import { isImpersonated } from '@utils/helpers/impersonation';
import { DOCUMENT } from '@angular/common';
import { JwtService } from '@app/services';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
	constructor(
		private authService: AuthService,
		private jwtService: JwtService,
		private router: Router,
		@Inject(DOCUMENT) private document: Document,
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.checkIfAuthorized();
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.checkIfAuthorized();
	}

	canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
		return this.checkIfAuthorized();
	}

	private checkIfAuthorized(): Observable<boolean> {
		if (this.getQueryParam('clearAuth') === 'true') {
			this.jwtService.removeToken();
		}
		return this.authService.isAuthenticated$.pipe(
			take(1),
			tap(isAuthenticated => {
				if (environment.is_pam_console) {
					if (!isImpersonated || !isAuthenticated) {
						this.router.navigate(['impersonation-error']);
					}
				} else if (!isAuthenticated) {
					this.jwtService.signInRedirect();
				}
			}),
		);
	}

	private getQueryParam(paramName: string): string {
		// We are using global window to read query params, because angular router does not always keep query param when redirects
		// https://github.com/angular/angular/issues/13315
		return new URLSearchParams(this.document.defaultView.location.search).get(paramName) || '';
	}
}
