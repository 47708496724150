import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { IDropdownOption } from 'n2p-ui-library/components/dropdown/dropdown.domain';
import { TranslateService } from '@ngx-translate/core';

import { CallOptionsService } from '@app/pages/teammembers/call-options/call-options.service';

@Component({
	selector: 'n2p-call-options-rings',
	templateUrl: './call-options-rings.component.html',
	styleUrls: ['./call-options-rings.component.scss', '../../call-options.shared.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallOptionsRingsComponent {
	ringsOptions: IDropdownOption[] = [];

	@Input() set maxRings(maxRings: number) {
		this.setRingOptions(maxRings);
	}

	@Output() change: EventEmitter<number> = new EventEmitter();

	constructor(private translateService: TranslateService, public callOptionsService: CallOptionsService) {
		this.setRingOptions();
	}

	private setRingOptions(maxRings: number = 0): void {
		const options: IDropdownOption[] = [
			{
				title: this.translateService.instant('CALLOPTIONS_PAGE.SEQUENTIALLY'),
				value: this.callOptionsService.INDIVIDUAL_RINGS_VALUE,
			},
		];

		for (let i = 2; i <= maxRings; i++) {
			options.push({
				title: `${i} ${this.translateService.instant('CALLOPTIONS_PAGE.RINGS')}`,
				value: i,
			});
		}

		this.ringsOptions = options;
	}
}
