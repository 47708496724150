import { Component, forwardRef, Input } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractControlValueAccessor } from '@app/Common/classes/AbstractControlValueAccessor';
import { IAccountPhone } from '@app/services/web-apis/phone-numbers/api-phone-numbers.domain';
import { IUser } from '@app/services/web-apis/users/api-users.domain';

@Component({
	selector: 'app-company-tab-phone-numbers',
	templateUrl: './company-tab-phone-numbers.component.html',
	styleUrls: ['./company-tab-phone-numbers.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CompanyTabPhoneNumbersComponent),
			multi: true,
		},
	],
})
export class CompanyTabPhoneNumbersComponent extends AbstractControlValueAccessor {
	@Input() user: IUser;
	@Input() isRequired: boolean;
	@Input() disabled: boolean = false;
	@Input() formGroup: FormGroup;
	@Input() formControlName: string;
	@Input() phoneNumbers: IAccountPhone[];

	get userName(): string {
		const { user } = this;
		return user ? `${user.firstName}${user?.lastName ? ` ${user.lastName}` : ''}` : '';
	}
}
