import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared';
import { SvgModule } from '@n2p';
import { AddForwardingNumberComponent } from './add-forwarding-number.component';

@NgModule({
	declarations: [AddForwardingNumberComponent],
	imports: [
		CommonModule,
		SharedModule, // TODO this needs to be refactored
		SvgModule,
	],
	entryComponents: [AddForwardingNumberComponent],
	exports: [AddForwardingNumberComponent],
})
export class AddForwardingNumberModule {}
