import { getInitialsFromNames } from 'n2p-ui-library';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AVATAR_TYPES, avatarTypes, ICON_SIZES, iconSizes } from '@app/Common';
import { getAvatarUrlFromData } from '@utils/helpers/functions';

@Component({
	selector: 'n2p-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
	constructor(private translateService: TranslateService) {}

	@Input() data: any;
	@Input() type: avatarTypes = AVATAR_TYPES.default as avatarTypes;
	@Input() category: 'single' | 'voicemail' | 'call-history' | 'menu-option' | 'multiple' | 'tier' = 'single';
	@Input() avatarSize = 120; // smallest available by default
	@Input() dimmed = false;
	@Input() showRole = false;

	// pass through params
	@Input() size: iconSizes = ICON_SIZES.default as iconSizes;
	@Input() raised = false;
	@Input() showName = false;

	get isMultiple(): boolean {
		return this.category === 'multiple';
	}

	get isTeamMember(): boolean {
		return this.type === 'team-member';
	}

	get isHidden(): boolean {
		return this.isMultiple && !this.count;
	}

	get imageUrl(): string {
		if (!this.isTeamMember) {
			return;
		}

		return getAvatarUrlFromData(this.data, this.avatarSize.toString());
	}

	get isAdmin(): boolean {
		let admin = false;
		try {
			admin = this.data.role === 'admin';
		} catch (e) {}

		return admin;
	}

	get name(): string {
		if (!this.data) {
			return '';
		}

		const { firstName, lastName, name, userInfo, value } = this.data;
		if (name) {
			return name;
		}
		if (firstName) {
			return lastName ? `${firstName} ${lastName}` : firstName;
		}
		if (userInfo?.name) {
			return userInfo.name;
		}
		if (value) {
			return value;
		}
		return '';
	}

	get concatenatedName(): string {
		return this.name.length > 30 ? `${this.name.slice(0, 30)}...` : this.name;
	}

	get initials(): string {
		switch (this.type) {
			case AVATAR_TYPES.specialExtension:
				return this.translateService.instant('FORWARD_CALLS_TO_DATA.ICON_SPECIAL_EXTENSION');
			case AVATAR_TYPES.welcomeMenu:
				return this.translateService.instant('FORWARD_CALLS_TO_DATA.ICON_WELCOME_MENUS');
			case AVATAR_TYPES.ringGroup:
				return this.translateService.instant('FORWARD_CALLS_TO_DATA.ICON_RING_GROUPS');
			case AVATAR_TYPES.directory:
				return this.translateService.instant('FORWARD_CALLS_TO_DATA.ICON_COMPANY_DIRECTORY');
			case AVATAR_TYPES.callQueue:
				return this.translateService.instant('FORWARD_CALLS_TO_DATA.ICON_CALL_QS');
			case AVATAR_TYPES.ringer:
				return this.translateService.instant('FORWARD_CALLS_TO_DATA.ICON_RINGER');
			case AVATAR_TYPES.virtualFax:
				return 'V';
			default: {
				if (!this.data) {
					return this.type[0];
				}

				const { firstName, lastName, name, userInfo, value } = this.data;
				return firstName
					? getInitialsFromNames(firstName, lastName)
					: getInitialsFromNames(name || userInfo?.name || value || '');
			}
		}
	}

	get count(): any {
		if (!Array.isArray(this.data)) return false;

		return this.data.length > 99 ? '99+' : this.data.length;
	}

	get secondarySize(): string {
		if (this.isMultiple) {
			return ICON_SIZES.names['small-1'] as iconSizes;
		}
	}
}
