import { Component, OnInit, Inject } from '@angular/core';

import { MULTISELECT_CHIP, MULTISELECT_CHIP_CLICK_CALLBACK } from 'utils/constants/injectortokens';

@Component({
	selector: 'app-multi-select-chip-text-only',
	templateUrl: './multi-select-chip-text-only.component.html',
	styleUrls: ['./multi-select-chip-text-only.component.scss'],
})
export class MultiSelectChipTextOnlyComponent implements OnInit {
	constructor(
		@Inject(MULTISELECT_CHIP) public item: any,
		@Inject(MULTISELECT_CHIP_CLICK_CALLBACK) private callback: any,
	) {}

	ngOnInit() {}
}
