import { Component, Input, OnInit } from '@angular/core';

import { ILicenseBase } from '@app/services/licenses/licenses.domain';

@Component({
	selector: 'app-editable-licenses-list',
	templateUrl: './editable-licenses-list.component.html',
	styleUrls: ['./editable-licenses-list.component.scss'],
})
export class EditableLicensesListComponent implements OnInit {
	@Input() licenses: ILicenseBase[];

	constructor() {}

	ngOnInit(): void {}

	createIconClass(iconClass: string): string {
		return iconClass ? `n2pc-${iconClass} icon` : 'n2pc-icon-certificate icon';
	}
}
