import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfoMessageComponent } from './info-message.component';
import { IconModule } from '@n2p';

@NgModule({
	declarations: [InfoMessageComponent],
	imports: [CommonModule, IconModule],
	exports: [InfoMessageComponent],
})
export class InfoMessageModule {}
