import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators/debounceTime';
import { Subscription } from 'rxjs/Subscription';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { of } from 'rxjs/observable/of';
import { TranslateService } from '@ngx-translate/core';

import { ApiExtensionsService, ExtensionTypes, SnackbarService } from '@app/services';
import { IErrorMessage } from '@app/services/web-apis/common-api.domain';
import { ValidationExceptions } from '@app/Common/constants/errors';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';

@Component({
	selector: 'shared-extension',
	templateUrl: './extension.component.html',
	styleUrls: ['./extension.component.scss'],
})
export class ExtensionComponent implements OnInit, OnDestroy {
	public isValid: boolean;
	public tooltip: string;
	public message: string;
	public overwriteClasses: Record<string, string> = {
		label: 'extension-label',
		tooltipIcon: 'extension-label-tooltip',
		labelHint: 'extension-label-auto',
	};
	public style: string = 'secondary';
	public touched: boolean = false;
	public valueSubject: BehaviorSubject<string> = new BehaviorSubject('');
	public value$: Observable<string> = this.valueSubject.asObservable();
	private subscriptions: Subscription = new Subscription();
	private previous: string;

	@Input() set value(value: number | string) {
		this.previous = `${value}`;
	}

	@Input() type: ExtensionTypes;
	@Input() border: boolean = false;

	@Output() onChange: EventEmitter<{ value: string; isValid: boolean }> = new EventEmitter();

	constructor(
		private apiExtensions: ApiExtensionsService,
		private snackbarService: SnackbarService,
		private translate: TranslateService,
		private apiTenantService: ApiTenantService,
	) {}

	ngOnInit(): void {
		const subscription = this.valueSubject
			.pipe(
				debounceTime(750),
				distinctUntilChanged(),
				switchMap(value => {
					const result = { value, isValid: true, message: '', tooltip: '' };

					if (value === this.previous) return of(result);
					return this.apiExtensions.validateExtension(value).pipe(
						map(({ hasError, errorMessages: [error0, error1] }) => {
							return {
								...result,
								isValid: !hasError,
								message: error0 ? this.getMessageTranslationKey(error0) : '',
								tooltip: error1 ? this.getTooltipTranslation(error1) : '',
							};
						}),
					);
				}),
			)
			.subscribe(({ value, isValid, message, tooltip }) => {
				this.onChange.emit({ value, isValid });
				this.isValid = isValid;
				this.message = message;
				this.tooltip = tooltip;
			});

		this.subscriptions.add(subscription);

		if (this.previous === undefined) {
			this.updateExtension();
		} else {
			this.valueSubject.next(this.previous);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	handleChange(value: string): void {
		this.touched = true;
		this.valueSubject.next(value);
	}

	updateExtension(): void {
		this.valueSubject.next('');
		this.apiExtensions.getNextExtension(this.type).subscribe(
			({ data }) => this.valueSubject.next(data || ''),
			() => {
				this.isValid = false;
				this.touched = true;
				this.snackbarService.createDanger(
					this.translate.instant('GLOBALS.FETCH_ERROR', {
						entity: this.translate.instant('EXTENSION.EXT'),
					}),
				);
			},
		);
	}

	getMessageTranslationKey(error: IErrorMessage) {
		if (error.code === ValidationExceptions.extensionInUse) {
			return 'EXTENSION.IN_USE';
		} else if (error.code === ValidationExceptions.extensionUnsupported) {
			return 'EXTENSION.UNSUPPORTED';
		} else {
			return 'EXTENSION.INVALID';
		}
	}

	getTooltipTranslation(error: IErrorMessage) {
		if (error.code === ValidationExceptions.extensionInUse) {
			return this.translate.instant('EXTENSION.IN_USE');
		} else if (error.code === ValidationExceptions.extensionUnsupported) {
			return this.translate.instant('EXTENSION.UNSUPPORTED_MESSAGE');
		} else {
			return this.translate.instant('EXTENSION.INVALID_MESSAGE', {
				amount: this.apiTenantService.accountMaxExtensionLength,
			});
		}
	}
}
