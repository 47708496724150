import {
	ICallForwardingRules,
	IForwardToExtended,
	IUserLight,
	RuleType,
	RuleTypeBase,
	RuleTypeCommon,
	RuleTypeIndividual,
} from '@app/services/web-apis/users/api-users.domain';
import { getAvatarUrlFromData } from '@utils/helpers/functions';

export const INDIVIDUAL_RULE_TYPES: RuleType[] = [
	RuleTypeIndividual.RING_ALL,
	RuleTypeIndividual.NO_ANSWER,
	RuleTypeIndividual.FAIL_OVER,
	RuleTypeIndividual.FORWARD_ONLY,
];

/**
 * We have different sets of rule type if call forward is individual or not
 * For individual: [ringbothseq, sipnoansseq, sipnotregseq, phoneseq]
 * For non individual (common): [sim, sipnoanssim, sipnotregsim, phonesim]
 *
 * So when we switch rings count from 'Individual' to any other like: '2 rings', '10 rings', etc.
 * We not always should update 'rings' property in call forward rules
 * But we always should update 'ruletype' property (for example RuleTypeIndividual.RING_ALL become RuleTypeCommon.RING_ALL)
 * And vice versa
 */
export const RULE_TYPES_MAP = {
	[RuleTypeBase.OFF]: RuleTypeBase.OFF,
	[RuleTypeIndividual.RING_ALL]: RuleTypeCommon.RING_ALL,
	[RuleTypeIndividual.NO_ANSWER]: RuleTypeCommon.NO_ANSWER,
	[RuleTypeIndividual.FAIL_OVER]: RuleTypeCommon.FAIL_OVER,
	[RuleTypeIndividual.FORWARD_ONLY]: RuleTypeCommon.FORWARD_ONLY,
	[RuleTypeCommon.RING_ALL]: RuleTypeIndividual.RING_ALL,
	[RuleTypeCommon.NO_ANSWER]: RuleTypeIndividual.NO_ANSWER,
	[RuleTypeCommon.FAIL_OVER]: RuleTypeIndividual.FAIL_OVER,
	[RuleTypeCommon.FORWARD_ONLY]: RuleTypeIndividual.FORWARD_ONLY,
};

export const isIndividualRuleType = (ruleType: RuleType): boolean => {
	return INDIVIDUAL_RULE_TYPES.includes(ruleType);
};

export const processCallForwardNumbers = (
	callForwardingRules: ICallForwardingRules,
	users: IUserLight[],
): IForwardToExtended[] => {
	const forwardNumbers: IForwardToExtended[] = callForwardingRules ? [...callForwardingRules.forwardTo] : [];

	forwardNumbers.forEach(number => {
		const user = users.find(user => user.extension === number.value);

		if (user) {
			number.user = user;
			number.avatarStr = getAvatarUrlFromData(user);
			number.name = `${user.firstName} ${user.lastName}`;
		}
	});

	return forwardNumbers;
};
