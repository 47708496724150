import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';

import { ApiService } from '@app/services/api';
import { ICompanyDirectory } from './api-directories.domain';
import { IRegularApiResponse } from '../common-api.domain';
import { IAudioContentRsp } from '../music-options/api-music-options.domain';
import { ApiMusicOptionsService } from '@app/services/web-apis/music-options/api-music-options.service';

@Injectable()
export class ApiDirectoriesService {
	private baseUrl = '/accounts/{accountId}';
	private userUrl = '/accounts/{accountId}/users/{userId}';

	constructor(private apiService: ApiService, private apiMusicOptionsService: ApiMusicOptionsService) {}

	// observable source
	private reloadSource = new Subject<any>();

	// observable stream
	reloadedData = this.reloadSource.asObservable();

	reloadData = (): void => {
		this.getCompanyDirectory().subscribe(result => this.reloadSource.next(result));
	};

	getCompanyDirectory = (): Observable<IRegularApiResponse<ICompanyDirectory>> => {
		return this.apiService.get(`${this.baseUrl}/compDir`);
	};

	setCompanyDirectorySearch = (searchByLastName: boolean): Observable<IRegularApiResponse<string>> => {
		return this.apiService.put(`${this.baseUrl}/dirSearch`, { searchByLastName });
	};

	createCompanyDirectoryPrompt = (audio: any): Observable<IRegularApiResponse<IAudioContentRsp>> => {
		// audio could be File | IAudio
		const body = new FormData();

		body.append('Content', audio);
		body.append('Type', 'directoryGreetings');
		body.append('ownerType', '');
		body.append('ownerId', '');

		return this.apiMusicOptionsService.uploadMediaFormData(body);
	};

	resetCompanyDirectoryPrompt = (): Observable<IRegularApiResponse<string>> => {
		const query = { audioType: 10 };

		return this.apiService.put(`${this.baseUrl}/audio`, {}, query);
	};

	getUserDirectoryPrompt = (userId: string = '{userId}'): Observable<IRegularApiResponse<IAudioContentRsp>> => {
		const query = { audioType: 9 };

		return this.apiService.get(`${this.baseUrl}/users/${userId}/audio`, query);
	};

	createUserDirectoryPrompt = (
		audio: any,
		userId: string = '{userId}',
	): Observable<IRegularApiResponse<IAudioContentRsp>> => {
		const body = new FormData();
		body.append('Content', audio);
		body.append('Type', 'uploadUserName');
		body.append('ownerType', 'users');
		body.append('ownerId', userId);

		return this.apiMusicOptionsService.uploadMediaFormData(body);
	};
}
