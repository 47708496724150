export enum PhoneCategory {
	Unassigned = 'unassigned',
	TeamMember = 'team-member',
	Department = 'department',
	RingGroup = 'ring-group',
	WelcomeMenu = 'welcome-menu',
	SpecialExtension = 'special-extension',
	Porting = 'porting',
}

export enum PhoneNumberType {
	default = 'n2p-phone-number',
	porting = 'n2p-phone-number-porting',
}
