import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '@app/services/api/api.service';
import { IAccountPlan } from './api-plans.domain';
import { IRegularApiResponse } from '../common-api.domain';

@Injectable()
export class ApiPlansService {
	constructor(private apiService: ApiService) {}

	getAccountPlan(): Observable<IRegularApiResponse<IAccountPlan[]>> {
		return this.apiService.get('/accounts/{accountId}/plans');
	}
}
