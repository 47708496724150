import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable()
export class FileDownloadService {
	constructor() {}

	saveFile(data: any, type: string, fileName: string, extension: string): void {
		saveAs(new Blob([data], { type }), `${fileName}.${extension}`);
	}

	saveCSV(data: any, fileName: string = 'data'): void {
		this.saveFile(data, 'text/csv;charset=utf-8', fileName, 'csv');
	}
}
