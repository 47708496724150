import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ForwardCallsToDataComponent } from '@app/shared/forward-calls-to/forward-calls-to-data/forward-calls-to-data.component';
import { ForwardCallsToChipComponent } from '@app/shared/forward-calls-to/forward-calls-to-chip/forward-calls-to-chip.component';

@Component({
	selector: 'app-remove-account-item-dialog-reassign-dropdown',
	templateUrl: './remove-account-item-dialog-reassign-dropdown.component.html',
	styleUrls: ['./remove-account-item-dialog-reassign-dropdown.component.scss'],
})
export class RemoveWelcomeMenuDialogReassignDropdownComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() data;
	@Input() line: any;
	@Input() index: number;
	@Input() unassignedType: any;
	config = { showUnassignedNumber: true, showAddForwardingNumber: false, showDeleteNumber: true };
	dataComponent: any = ForwardCallsToDataComponent;
	chipComponent: any = ForwardCallsToChipComponent;
	dropdownData: any;
	constructor() {}

	findIndex = (selected: Array<any>, data) => {
		return selected.findIndex(i => i.item['id'] === data.item['id']);
	};

	selectedChanged(data) {
		this.dropdownData[5] = data;
	}

	ngOnInit() {
		this.dropdownData = [this.data[0], this.data[1], this.data[2], this.data[3], this.data[4], this.unassignedType];
	}
}
