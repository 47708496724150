import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SnackbarService } from '@app/services';

@Component({
	selector: 'app-file-uploader',
	templateUrl: './file-uploader.component.html',
	styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent {
	@Input() maxFileSize = 4e6;
	@Input() loading = false;
	@Input() supportedFileTypes = ['.mp3', '.m4a', '.wav'];
	@Input() message = 'Drag and drop here to upload';
	@Output() fileChanged = new EventEmitter<FileList>();
	@ViewChild('dropZone', { static: true }) dropZone: ElementRef;
	@ViewChild('fileInput', { static: false }) fileInput: ElementRef;
	constructor(private snackbarService: SnackbarService, private translate: TranslateService) {}

	handleFileChange(ev: Event): void {
		const target: HTMLInputElement = ev.target as HTMLInputElement;
		if (!target.files || !target.files.length) return;
		if (target.files[0].size > this.maxFileSize) {
			this.snackbarService.create({
				text: this.translate.instant('FILE_UPLOADER.LARGE_FILE_ERROR'),
				status: 'danger',
			});

			return;
		}

		const fileExt = target.files[0].name.substr(target.files[0].name.lastIndexOf('.'));
		if (this.supportedFileTypes.findIndex(i => i === fileExt.toLowerCase()) === -1) {
			this.snackbarService.create({
				text: this.translate.instant('FILE_UPLOADER.INVALID_FILE'),
				status: 'danger',
			});

			return;
		}

		this.handleChange(target.files);
	}

	handleChange(files: FileList): void {
		this.fileChanged.emit(files);
	}

	selectFile(): void {
		this.fileInput.nativeElement.click();
	}
}
