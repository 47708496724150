import { ContentChild, Directive, ElementRef, HostListener, Injector, Input, OnInit, OnDestroy } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { DialogRef } from '@app/shared/classes/DialogRef';
import { TooltipComponent } from '@app/shared/tooltip/tooltip.component';
import { TOOLTIP_COMPONENT, TOOLTIP_POSITION, TOOLTIP_TEXT } from 'utils/constants/injectortokens';

/* tslint:disable */
@Directive({
	selector: '[appTooltip], [tooltip]',
})
/* tslint:enable */
export class TooltipDirective implements OnInit, OnDestroy {
	/* tslint:disable */
	@Input('appTooltip') toolTipText: string;
	/* tslint:enable */
	@Input() position: 'top' | 'right' | 'left' | 'bottom' = 'top';
	@Input() maxTooltipWidth: number;
	@ContentChild('tooltip', { static: true }) toolTipComponent: ElementRef;
	overlayRef: OverlayRef;
	constructor(private overlay: Overlay, private elementRef: ElementRef, private injector: Injector) {}

	ngOnInit(): void {
		if (this.toolTipComponent) {
			this.toolTipComponent.nativeElement.style.display = 'none';
		}
	}

	ngOnDestroy(): void {
		if (this.overlayRef) {
			this.overlayRef.dispose();
		}
	}

	@HostListener('mouseout') onMouseLeave(): void {
		this.overlayRef.dispose();
	}

	@HostListener('mouseover') onMouseOver(): any {
		const diff = window.innerHeight - this.elementRef.nativeElement.getBoundingClientRect()['y'];
		const onTop = diff < 260;
		const onBottom = this.elementRef.nativeElement.getBoundingClientRect()['y'] < 260;

		if (this.position === 'top' && onBottom) {
			this.position = 'bottom';
		} else if (this.position === 'bottom' && onTop) {
			this.position = 'top';
		}

		const config = new OverlayConfig();

		if (this.maxTooltipWidth) {
			config.maxWidth = this.maxTooltipWidth;
		}
		config.positionStrategy = this.overlay
			.position()
			.connectedTo(this.elementRef, this.getOriginPosition(), this.getOverlayPosition())
			.withOffsetX(this.getOffsetX())
			.withOffsetY(this.getOffsetY());

		config.hasBackdrop = false;

		this.overlayRef = this.overlay.create(config);

		this.overlayRef.backdropClick().subscribe();

		const modalRef = new DialogRef(this.overlayRef);
		const c = new ComponentPortal(TooltipComponent, undefined, this.createInjector());
		this.overlayRef.attach(c);

		return modalRef;
	}

	private createInjector(): PortalInjector {
		const injectorTokens = new WeakMap();

		injectorTokens.set(TOOLTIP_TEXT, this.toolTipText || '');
		injectorTokens.set(TOOLTIP_POSITION, this.position);
		injectorTokens.set(TOOLTIP_COMPONENT, this.toolTipComponent || {});

		return new PortalInjector(this.injector, injectorTokens);
	}

	private getOriginPosition(): any {
		switch (this.position) {
			case 'top': {
				return { originY: 'top', originX: 'center' };
			}
			case 'bottom': {
				return { originY: 'bottom', originX: 'center' };
			}
			case 'left': {
				return { originY: 'center', originX: 'start' };
			}
			case 'right': {
				return { originY: 'center', originX: 'end' };
			}
			default: {
				return { originY: 'top', originX: 'center' };
			}
		}
	}

	private getOverlayPosition(): any {
		switch (this.position) {
			case 'top': {
				return { overlayY: 'bottom', overlayX: 'center' };
			}
			case 'bottom': {
				return { overlayY: 'top', overlayX: 'center' };
			}
			case 'left': {
				return { overlayY: 'center', overlayX: 'end' };
			}
			case 'right': {
				return { overlayY: 'center', overlayX: 'start' };
			}
			default: {
				return { overlayY: 'bottom', overlayX: 'center' };
			}
		}
	}

	private getOffsetY(): number {
		switch (this.position) {
			case 'top': {
				return -10;
			}
			case 'bottom': {
				return 10;
			}
			default: {
				return 0;
			}
		}
	}

	private getOffsetX(): number {
		switch (this.position) {
			case 'left': {
				return -10;
			}
			case 'right': {
				return 10;
			}
			default: {
				return 0;
			}
		}
	}
}
