import { Component, HostBinding, Inject, OnInit } from '@angular/core';

import { DROPDOWN_DATA, DROPDOWN_DATA_CLICK_CALLBACK, DROPDOWN_SELECTED_DATA } from '../../../utils';
import { ITimezone } from '@app/services/web-apis/timezone/api-timezone.domain';

@Component({
	selector: 'app-time-zone-data-dropdown',
	templateUrl: './time-zone-data-dropdown.component.html',
	styleUrls: ['./time-zone-data-dropdown.component.scss'],
})
export class TimeZoneDataDropdownComponent implements OnInit {
	@HostBinding('class.n2p-dropdown-data') style = false;
	timezones: Array<any>;
	constructor(
		@Inject(DROPDOWN_DATA) data: Array<ITimezone>,
		@Inject(DROPDOWN_DATA_CLICK_CALLBACK) private dataClickCallback: any,
		@Inject(DROPDOWN_SELECTED_DATA) private selected: ITimezone,
	) {
		this.timezones = data;
	}

	clicked(item): void {
		this.timezones.forEach(i => {
			if (i === item) i.selected = true;
			i.selected = false;
		});
		this.dataClickCallback(item);
	}

	ngOnInit(): void {
		this.style = true;
		const index = this.timezones.findIndex((i: ITimezone) => i.abbreviation === this.selected.abbreviation);
		if (index !== -1) this.timezones[index].selected = true;
	}
}
