import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { ApiUsersService } from '@app/services';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '@app/Common';

@Component({
	selector: 'app-update-password',
	templateUrl: './update-password.component.html',
	styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent {
	passwords = {
		old: {
			value: '',
			focused: false,
			error: '',
		},
		new: {
			value: '',
			focused: false,
		},
		confirm: {
			value: '',
			focused: false,
		},
	};
	isSubmitting = false;
	logoutUser: boolean = true;

	constructor(
		@Inject(DIALOG_DATA) private data: any,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		private apiUsers: ApiUsersService,
		private translate: TranslateService,
	) {}

	get validationErrors(): any {
		const { new: newPass, confirm } = this.passwords;
		const errors = {
			new:
				newPass.value && newPass.value.length < 8
					? this.translate.instant('UPDATE_PASSWORD.MUST_BE')
					: newPass.focused && newPass.value && confirm.value && newPass.value !== confirm.value
					? this.translate.instant('UPDATE_PASSWORD.PASSWORDS_MUST')
					: '',
			confirm:
				confirm.value && newPass.value && confirm.value !== newPass.value
					? this.translate.instant('UPDATE_PASSWORD.PASSWORDS_MUST')
					: '',
		};

		return errors;
	}

	newPasswordIsValid(): boolean {
		return (
			this.passwords.old.value &&
			this.passwords.new.value &&
			this.passwords.new.value.length >= PASSWORD_MIN_LENGTH &&
			this.passwords.new.value.length <= PASSWORD_MAX_LENGTH &&
			this.passwords.new.value === this.passwords.confirm.value
		);
	}

	onPasswordChange = (inputName, { value, isValid }): void => {
		this.passwords[inputName].value = value;
	};

	toggleFocus = (inputName, isFocused): void => {
		this.passwords[inputName].focused = isFocused;
	};

	close = (): void => {
		this.dialogRef.dismiss({ cancelled: true });
	};

	submit = (): void => {
		if (this.newPasswordIsValid()) {
			const user = this.data;

			this.isSubmitting = true;

			this.apiUsers
				.updateUserPassword({
					email: user.email,
					oldPassword: this.passwords.old.value,
					password: this.passwords.new.value,
					logoutUser: this.logoutUser,
				})
				.subscribe(
					response => {
						this.isSubmitting = false;
						this.dialogRef.dismiss({ error: false });
					},
					error => {
						this.isSubmitting = false;
						this.dialogRef.dismiss({ error: true });
					},
				);
		}
	};
}
