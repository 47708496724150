import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
	MULTISELECT_DATA,
	MULTISELECT_DATA_CLICK_CALLBACK,
	MULTISELECT_DATA_CONFIG,
} from 'utils/constants/injectortokens';

import { getInitialsFromString } from 'utils/helpers/dropdown';
import { DialogService } from '@app/services/dialog/dialog.service';
import { ForwardCallsToAddNumberComponent } from '@app/shared/forward-calls-to/forward-calls-to-add-number/forward-calls-to-add-number.component';

declare type forwardCallsTypes = 'tm' | 'd' | 'r' | 'w' | 'txt' | 'ext';

@Component({
	selector: 'app-multi-select-data-forwarding',
	templateUrl: './multi-select-data-forwarding.component.html',
	styleUrls: ['./multi-select-data-forwarding.component.scss'],
})
export class MultiSelectDataForwardingComponent implements OnInit {
	@HostBinding('class.n2p-dropdown-data') style = false;
	users: Array<any> = [];
	welcomeMenus: Array<any> = [];
	departments: Array<any> = [];
	ringGroups: Array<any> = [];
	callback: any;
	getInitials: any = getInitialsFromString;
	showLoader = true;
	showHangup = false;
	showAddForwardingNumber = true;
	showDisconnectNumber = false;
	showThirdLvl = false;
	constructor(
		@Inject(MULTISELECT_DATA) data: any,
		@Inject(MULTISELECT_DATA_CLICK_CALLBACK) callback: any,
		@Inject(MULTISELECT_DATA_CONFIG) config: any,
		private dialogService: DialogService,
		private translate: TranslateService,
	) {
		this.callback = callback;
		if (config.hasOwnProperty('showAddForwardingNumber')) {
			this.showAddForwardingNumber = config.showAddForwardingNumber;
		}
		if (config.hasOwnProperty('showDisconnectNumber')) {
			this.showDisconnectNumber = config.showDisconnectNumber;
		}
		if (config.hasOwnProperty('showHangup')) {
			this.showHangup = config.showHangup;
		}
		if (config.hasOwnProperty('showThirdLvl')) {
			this.showThirdLvl = config.showThirdLvl;
		}

		if (data) {
			this.showLoader = false;
			if (data.users) {
				this.users = data.users;
			}
			if (data.departments) {
				this.departments = data.departments;
			}
			if (data.ringGroups) {
				this.ringGroups = data.ringGroups;
			}
			if (data.welcomeMenus) {
				this.welcomeMenus = data.welcomeMenus;
			}
		}
	}

	addNumber() {
		const dialogRef = this.dialogService.create(ForwardCallsToAddNumberComponent, null, {
			width: 440,
		});

		dialogRef.onDismiss().subscribe(number => {
			if (!number) return;
			this.callback({
				selected: true,
				item: {
					number: number,
					id: number,
				},
				number: number,
				type: 'ext',
			});
		});
	}

	clearNumber() {
		this.deselectAllItems();
		this.callback({
			type: 'empty',
			name: this.translate.instant('FORWARD_CALLS_TO_DATA.HANGUP_CALL'),
			selected: true,
		});
	}

	deselectAllItems() {
		let clear = function(list: any[]) {
			if (!list) {
				return;
			}
			list.forEach(u => {
				u.selected = false;
			});
		};
		clear(this.users);
		clear(this.ringGroups);
		clear(this.departments);
		clear(this.welcomeMenus);
	}

	disconnectNumber() {
		this.callback();
	}

	clicked(item, type: forwardCallsTypes, isVM = false) {
		item.selected = !item.selected;
		this.callback({
			...item,
			isVM: isVM,
			type: type,
			selected: item.selected,
		});
	}

	ngOnInit() {
		this.style = true;
	}
}
