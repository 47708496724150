import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { PhoneNumberFormatterPipe } from '@app/pipes';
import { getEntityObjectByType } from '@utils/helpers/functions';
import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { AddWelcomeMenuCancelPromptComponent } from '@app/pages/welcomemenu/addwelcomemenu';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { getEntityType } from 'utils/helpers/functions';
import { CacheService, DialogService, AuthStorageData, WelcomeMenuService, FeatureFlagsService } from '@app/services';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-edit-welcome-menu',
	templateUrl: './edit-welcome-menu.component.html',
	styleUrls: ['./edit-welcome-menu.component.scss'],
	providers: [PhoneNumberFormatterPipe],
})
export class EditWelcomeMenuComponent implements OnInit, OnDestroy {
	@ViewChild('editWelcomeMenuHeader', { static: true }) header: ElementRef;
	@ViewChild('editWelcomeMenuBody', { static: true }) body: ElementRef;
	@ViewChild('editWelcomeMenuFooter', { static: true }) footer: ElementRef;
	isScrolling = undefined;
	mouseIsDown = false;
	scrollAtTop = true;
	scrollAtBottom = false;
	formGroup: FormGroup;
	saving = false;
	oldDialogDismiss: any;
	originalSendSMSTo: any;
	private bounceMethod: any;
	private isExtensionValidating: boolean = false;
	allCompanyLabel: string;
	typesWithZeroDataId = ['repeat', 'phone', 'directory'];
	typesWithNoVoiceMail = ['repeat', 'directory'];
	readonly assignedNumbers: Array<any>;
	readonly options: Array<any>;
	showAdvancedWelcomeMenuConfiguration: Observable<boolean>;
	constructor(
		@Inject(DIALOG_DATA) private data: any,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		private fb: FormBuilder,
		private welcomeMenuService: WelcomeMenuService,
		private cacheService: CacheService,
		private dialogService: DialogService,
		private phoneNumberFormatterPipe: PhoneNumberFormatterPipe,
		private translateService: TranslateService,
		private authStorageDataService: AuthStorageData,
		private tenantService: ApiTenantService,
		private featureFlagService: FeatureFlagsService,
	) {
		this.allCompanyLabel = this.translateService.instant('SEND_SMS_TO.ALL');
		this.assignedNumbers = this.data.lines.map(i => ({ number: i.lineId }));
		this.originalSendSMSTo = this.data.menu.smsDestination
			? { ...this.data.menu.smsDestination, status: 'D' }
			: undefined;
		this.options = this.data.menu.multiAutoAttendantMenuItem.map(option => {
			return {
				id: option.id,
				key: option.orderBy,
				forwardCallsTo: [option].map(this.menuOptionMap),
			};
		});

		this.showAdvancedWelcomeMenuConfiguration = featureFlagService.isAdvancedWelcomeMenuConfigurationEnabled();

		this.formGroup = this.fb.group({
			id: [this.data.id],
			name: [this.data.name, [Validators.required, Validators.maxLength(30)]],
			assignedNumbers: [this.data.lines && this.data.lines.length ? this.data.lines.map(i => i.lineId) : []],
			menuOptionSelected: [true],
			destinationListid: this.data.menu.destinationListid,
			destinationid: this.data.menu.destinationid,
			menuId: this.data.menu.id,
			extensionDialingEnabled: this.data.menu.businessFeatures?.extensionDialingEnabled,
			playPromptAfterSelectionEnabled: this.data.menu.businessFeatures?.playPromptAfterSelectionEnabled,
			bargingMenuPromptAllowed: this.data.menu.businessFeatures?.bargingMenuPromptAllowed,
			options: this.fb.array(
				!this.data.menu.multiAutoAttendantMenuItem || !this.data.menu.multiAutoAttendantMenuItem.length
					? [
							this.fb.group({
								key: new FormControl('timeout', [Validators.required]),
								forwardCallsTo: [[], [Validators.required]],
							}),
					  ]
					: this.data.menu.multiAutoAttendantMenuItem
							.sort(i => i.orderBy)
							.map(option => {
								return this.fb.group({
									id: new FormControl(option.id),
									key: new FormControl([{ value: option.orderBy, selected: true }], [Validators.required]),
									forwardCallsTo: [[option].map(this.menuOptionMap), [Validators.required]],
								});
							}),
			),
			greeting: [''],
			recordGreeting: [''],
			customGreeting: [''],
			greetingBase64: [''],
			updateGreeting: [false],
			greetingFile: [''],
			extension: [this.data.extension, Validators.required],
			footer: [],
			sendSMSTo: [
				this.data.menu.smsDestination
					? [
							{
								type: getEntityType(this.data.menu.smsDestination.type),
								_data: Number(this.data.menu.smsDestination.data),
								value: getEntityType(this.data.menu.smsDestination.type) === 'all-company' ? this.allCompanyLabel : '',
							},
					  ]
					: [{ value: this.allCompanyLabel, type: 'all-company' }],
			], // {id: -999, type: 'all-company'}
			transcription: [''],
		});
	}

	nameChange(ev): void {
		this.formGroup.get('name').patchValue(ev.value);
	}

	save(): void {
		this.saving = true;
		const wm = this.buildWMModel();
		this.doSaveAction(wm).then(
			res => {
				this.saving = false;
				this.oldDialogDismiss(res);
			},
			() => (this.saving = false),
		);
	}

	cancel(): void {
		const promptRef = this.dialogService.create(AddWelcomeMenuCancelPromptComponent, undefined, {
			width: 440,
			height: 278,
		});
		promptRef.onDismiss().subscribe(res => {
			if (res) {
				this.oldDialogDismiss();
			}
		});
	}

	extensionValidating(validating: boolean): void {
		this.isExtensionValidating = validating;
	}

	ngOnInit(): void {
		this.formGroup.get('footer').setValue(this.footer);
		this.oldDialogDismiss = this.dialogRef.dismiss;
		this.dialogRef.dismiss = this.cancel.bind(this);
		this.body.nativeElement.addEventListener('mousedown', () => {
			this.mouseIsDown = true;
		});

		this.body.nativeElement.addEventListener('mouseup', () => {
			this.mouseIsDown = false;
			this.scrollingIsDone();
		});

		this.body.nativeElement.addEventListener('scroll', () => {
			clearTimeout(this.isScrolling);
			this.isScrolling = setTimeout(() => {
				if (!this.mouseIsDown) {
					this.scrollingIsDone();
				}
			}, 100);
		});

		this.welcomeMenuService.getGreeting(this.formGroup.get('id').value).subscribe(res => {
			this.formGroup.get('greetingBase64').setValue(res.data.filter(i => i.status === 'A')[0].file);
			switch (res.data[0].fileType) {
				case 'mainCB': {
					this.formGroup.get('greeting').setValue('two');
					break;
				}
				case 'mainUL': {
					const transcription = res.data[0].transcription;
					if (!transcription) {
						this.formGroup.get('greeting').setValue('three');
					} else {
						this.formGroup.get('greeting').setValue('four');
						this.formGroup.get('transcription').setValue(this.normalizeTranscription(transcription));
					}
					break;
				}
				case 'default': {
					this.formGroup.get('greeting').setValue('');
					break;
				}
				default: {
					break;
				}
			}
		});
	}

	ngOnDestroy(): void {
		if (this.cacheService && this.cacheService.delete) {
			this.cacheService.delete('forward_calls_to_data');

			/*We have to clear cache here because the data updates often and create bugs.
			The profit of using it is boosting performance in case of multiple components on the page*/
			this.cacheService.delete('IF_CALL_NOT_ANSWER_KEY');
		}
	}

	private normalizeTranscription(transcription: string): string {
		return transcription.replace(/\r\n/g, '\n');
	}

	private getmultiAutoAttendantMenuItem = (i): any => {
		const entity = getEntityObjectByType(i.forwardCallsTo[0]);

		return {
			id: i.id,
			posAck: true,
			rings: 5,
			orderBy: Array.isArray(i.key) ? i.key[0].value : i.key,
			dataid: this.typesWithZeroDataId.includes(entity.type) ? 0 : entity.data,
			data: entity.data.toString(),
			type: entity.type,
			redirectToVoicemail: this.typesWithNoVoiceMail.includes(entity.type)
				? false
				: i.forwardCallsTo[0].redirectToVoiceMail,
		};
	};

	private buildWMModel = () => {
		let lines = [];
		let multiAutoAttendantMenuItem = [];
		const sendExtension = this.data.extension !== this.formGroup.get('extension').value;

		this.assignedNumbers.forEach(item => {
			if (this.formGroup.get('assignedNumbers').value.findIndex(i => i === item.number) === -1) {
				lines.push({ lineId: item.number, status: 'D' });
			}
		});
		lines = lines.concat(
			this.formGroup
				.get('assignedNumbers')
				.value.filter(p => p !== 'unassigned')
				.map(n => {
					return {
						lineId: n,
						status: 'A',
					};
				}),
		);

		this.options.forEach(item => {
			const index = this.formGroup.get('options').value.findIndex(i => {
				return i.id === item.id;
			});
			if (index === -1) {
				multiAutoAttendantMenuItem.push({ ...this.getmultiAutoAttendantMenuItem(item), status: 'D' });
			}
		});

		multiAutoAttendantMenuItem = multiAutoAttendantMenuItem.concat(
			this.formGroup.get('options').value.map(i => {
				return {
					...this.getmultiAutoAttendantMenuItem(i),
					status: 'A',
				};
			}),
		);

		return {
			id: this.formGroup.get('id').value,
			name: this.formGroup.get('name').value,
			lines,
			// we should check if extension has been updated cause server will send an error if we will send
			// extension that already exists even in welcome menu that we are creating now
			...(sendExtension && { extension: this.formGroup.get('extension').value }),
			menu: {
				id: this.formGroup.get('menuId').value,
				status: 'A',
				destinationListid: this.formGroup.get('destinationListid').value,
				destinationid: this.formGroup.get('destinationid').value,
				multiAutoAttendantMenuItem,
				businessFeatures: {
					extensionDialingEnabled: this.formGroup.get('extensionDialingEnabled').value,
					playPromptAfterSelectionEnabled: this.formGroup.get('playPromptAfterSelectionEnabled').value,
					bargingMenuPromptAllowed: this.formGroup.get('bargingMenuPromptAllowed').value,
				},
				smsDestination:
					!this.tenantService.accountCountryIsDidLess &&
					this.formGroup.get('sendSMSTo').value &&
					this.formGroup.get('sendSMSTo').value.length
						? this.formGroup.get('sendSMSTo').value.map(i => {
								return getEntityObjectByType(i, 'A', this.authStorageDataService.accountId);
						  })[0]
						: this.originalSendSMSTo,
			},
		};
	};

	private doSaveAction(wm): any {
		return this.welcomeMenuService.editWelcomeMenu(wm);
	}

	private scrollingIsDone(): void {
		const scrollTop = this.body.nativeElement.scrollTop;
		const isAtBottom = scrollTop + this.body.nativeElement.clientHeight === this.body.nativeElement.scrollHeight;

		this.scrollAtTop = scrollTop === 0;

		this.scrollAtBottom = isAtBottom;
	}

	private menuOptionMap = (i): any => {
		switch (i.type) {
			case 'user': {
				return {
					redirectToVoiceMail: i.redirectToVoicemail,
					type: 'team-member',
					selected: true,
					userId: i.dataid,
					_data: Number(i.dataid),
				};
			}
			case 'department': {
				return {
					redirectToVoiceMail: i.redirectToVoicemail,
					type: 'department',
					selected: true,
					deptId: i.dataid,
					_data: Number(i.dataid),
				};
			}
			case 'ringGroup': {
				return {
					type: 'ring-group',
					selected: true,
					id: i.dataid,
					_data: Number(i.dataid),
				};
			}
			case 'phone': {
				return {
					type: 'phone-number',
					selected: true,
					value: this.phoneNumberFormatterPipe.transform({ number: i.data }),
					id: i.data,
					number: i.data,
				};
			}
			case 'maa': {
				return {
					type: 'welcome-menu',
					selected: true,
					id: i.dataid,
					_data: Number(i.dataid),
				};
			}
			case 'repeat': {
				return {
					value: this.translateService.instant('FORWARD_CALLS_TO_DATA.REPEAT_MENU'),
					type: 'repeat-menu',
					selected: true,
				};
			}
			case 'directory': {
				return {
					value: this.translateService.instant('FORWARD_CALLS_TO_DATA.COMPANY_DIRECTORY'),
					type: 'directory',
					selected: true,
				};
			}
			case 'callQueue': {
				return {
					type: 'call-queue',
					selected: true,
					id: i.dataid,
					_data: String(i.dataid),
				};
			}
			default: {
				return {};
			}
		}
	};
}
