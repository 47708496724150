import { IFormatParams } from 'n2p-ui-library/utils/phone-number/phone-number.domain';
import { CountryCallingCode, CountryCode, NumberFormat } from 'libphonenumber-js';

type AdditionalFormatter = (libResult: string, params: ITransformParams) => string;

type PhoneNumberFormat = 'simple' | 'national' | NumberFormat;

export interface ICountry {
	code: CountryCode;
	prefix: CountryCallingCode;
}

export interface ITransformParams extends Omit<IFormatParams, 'numberFormat'> {
	numberFormat?: PhoneNumberFormat;
}

export const ADDITIONAL_FORMATTERS: { [country: string]: AdditionalFormatter } = {
	MX: (libResult: string, params: ITransformParams): string => {
		const MEXICO_LIBPHONENUMBER_RESULT_REGEXP = /^((?:\d\d)|(?:\d\d\d))( \d\d\d\d? \d\d\d\d?)$/;
		if (MEXICO_LIBPHONENUMBER_RESULT_REGEXP.test(libResult)) {
			return libResult.replace(MEXICO_LIBPHONENUMBER_RESULT_REGEXP, '($1)$2');
		}
		return libResult;
	},
	// https://idtjira.atlassian.net/wiki/spaces/NET/pages/3274277107/Argentina+3.0+Web+app+localization#Terms-%26-definitions%E2%80%A6
	AR: (libResult: string, params: ITransformParams): string => {
		if (params.numberFormat === 'simple') return libResult;
		let result = libResult.replace(new RegExp('\\D', 'gi'), '');
		const argentinaDigitNumberSize = 10;
		return result.slice(-argentinaDigitNumberSize);
	},
};
