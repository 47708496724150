import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { TranslateService } from '@ngx-translate/core';

import { SendSmsToDataComponent } from '@n2p/send-sms-to/send-sms-to-data/send-sms-to-data.component';
import { ApiDepartmentsService, ApiUsersService } from '@app/services';
import { sortObjsByProp } from '@utils/helpers/functions';

@Component({
	selector: 'n2p-send-sms-to',
	templateUrl: './send-sms-to.component.html',
	styleUrls: ['./send-sms-to.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => SendSmsToComponent),
		},
	],
})
export class SendSmsToComponent implements OnInit, ControlValueAccessor {
	@Input() singleSelection = true;
	@Input() dataPromises: Array<any>;
	@Input() required = true;
	@Input() messageRecipient: string;
	smsToData: Array<any>;
	smsToControl = new FormControl([]);
	smsToDataComponents = SendSmsToDataComponent;
	loading: boolean;
	private smsToControlSubscription: Subscription;

	private _value: Array<any>;

	get value(): Array<any> {
		return this._value;
	}

	set value(val) {
		this._value = val;
	}

	constructor(
		private apiUserService: ApiUsersService,
		private apiDepartmentService: ApiDepartmentsService,
		private translate: TranslateService,
	) {}

	onChange = value => {
		// do nothing
	};

	onTouch = () => {
		// do nothing
	};

	writeValue(value): void {
		this.value = value;
		this.onChange(value);
	}

	registerOnChange(fn): void {
		this.onChange = fn;
	}

	registerOnTouched(fn): void {
		this.onTouch = fn;
	}

	ngOnInit(): void {
		const promiseAll = this.dataPromises || [
			this.apiUserService.getAllUsersLW().toPromise(),
			this.apiDepartmentService.getAllDepartmentsLW().toPromise(),
		];

		Promise.all(promiseAll).then(res => {
			const teamMembers = res[0].data && res[0].data.items ? res[0].data.items : [];
			const departments = res[1].data && res[1].data.items ? res[1].data.items : [];
			if (this.value && this.value[0] && this.value[0]._data) {
				if (this.value[0].type === 'team-member') {
					const teamMember = teamMembers.find(i => i.userId === this.value[0]._data);
					if (teamMember) {
						this.value[0].value = `${teamMember.firstName} ${teamMember.lastName}`;
						this.value[0].subValue = teamMember.extension;
						this.writeValue(this.value);
					}
				} else if (this.value[0].type === 'department') {
					const department = departments.find(i => i.deptId === this.value[0]._data);
					if (department) {
						this.value[0].value = department.name;
						this.value[0].subValue = department.extension;
						this.writeValue(this.value);
					}
				}
			}

			const concatData = teamMembers
				.map(i => {
					const value = `${i.firstName} ${i.lastName}`;

					return {
						...i,
						value,
						type: 'team-member',
						subValue: i.extension,
					};
				})
				.concat(
					departments.map(i => {
						return {
							...i,
							value: i.name,
							type: 'department',
							subValue: i.extension,
						};
					}),
				)
				.concat([
					{
						value: this.translate.instant('SEND_SMS_TO.ALL'),
						type: 'all-company',
					},
				]);

			this.smsToData = sortObjsByProp('value', concatData);
			this.loading = false;
		});

		setTimeout(() => {
			if (this.value && this.value.length && !this.smsToData) this.loading = true;
			this.smsToControl = new FormControl(this.value);
			this.smsToControlSubscription = this.smsToControl.valueChanges.subscribe(value => this.writeValue(value));
		});
	}
}
