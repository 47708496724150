import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '@app/services/api';
import { IPagingApiResponse, IRegularApiResponse } from '../common-api.domain';
import { IMultiAutoAttendantLight, IPostMultiAutoAttendant } from './api-welcome-menus.domain';

@Injectable()
export class ApiWelcomeMenusService {
	private baseUrl = '/accounts/{accountId}/multiautoattendants';

	constructor(private apiService: ApiService) {}

	getAllWelcomeMenus(): Observable<IRegularApiResponse<IPostMultiAutoAttendant[]>> {
		return this.apiService.get(this.baseUrl);
	}

	getAllWelcomeMenusLW(skip = 0, take = 0): Observable<IPagingApiResponse<IMultiAutoAttendantLight>> {
		const url = `${this.baseUrl}/light?skip=${skip}&take=${take}`;

		return this.apiService.get(url);
	}
}
