import { Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { DateFormatterService } from '@app/services';

@Pipe({
	name: 'formatDate',
	pure: false,
})
export class FormatDatePipe implements PipeTransform {
	private subscription: Subscription;
	private forceChange: boolean = true;
	private value: string = '';
	private lastArgs: [Date | number | string, string, boolean] = ['', '', false];

	constructor(private formatter: DateFormatterService) {
		this.subscription = formatter.locale$.subscribe(() => (this.forceChange = true));
	}

	transform(...args: [Date | number | string, string, boolean]): string {
		const [value, format, useCurrentUserTimezone] = args;
		if (
			this.forceChange ||
			this.lastArgs[0] !== value ||
			this.lastArgs[1] !== format ||
			this.lastArgs[2] !== useCurrentUserTimezone
		) {
			this.value = value
				? useCurrentUserTimezone
					? this.formatter.formatTz(value, format)
					: this.formatter.format(value, format)
				: '';
			this.forceChange = false;
		}
		this.lastArgs = args;
		return this.value;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
