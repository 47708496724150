export type avatarTypes =
	| 'department'
	| 'ring-group'
	| 'team-member'
	| 'welcome-menu'
	| 'special-extension'
	| 'directory'
	| 'call-queue'
	| 'virtual-fax'
	| 'ringer'
	| 'device';

export const AVATAR_TYPES = {
	values: [
		'department',
		'ring-group',
		'team-member',
		'welcome-menu',
		'special-extension',
		'directory',
		'call-queue',
		'virtual-fax',
		'ringer',
		'device',
	],
	default: 'team-member',
	names: {
		department: 'ANALYTICS.DEPARTMENT',
		'ring-group': 'ANALYTICS.RING_GROUP',
		'welcome-menu': 'ANALYTICS.WELCOME_MENU',
		'team-member': 'ANALYTICS.TEAM_MEMBER',
		'special-extension': 'ANALYTICS.SPECIAL_EXTENSION',
		directory: 'ANALYTICS.DIRECTORY',
		'call-queue': 'ANALYTICS.CALL_QUEUE',
		'virtual-fax': 'VIRTUAL_FAX_PAGE.TITLE',
	},
	cardTitle: {
		department: 'ANALYTICS.DEPARTMENT_CARD_TITLE',
		'ring-group': 'ANALYTICS.RING_GROUP_CARD_TITLE',
		'welcome-menu': 'ANALYTICS.WELCOME_MENU_CARD_TITLE',
	},
	tableTitle: {
		department: 'ANALYTICS.DEPARTMENT_TABLE_TITLE',
		'ring-group': 'ANALYTICS.RING_GROUP_TABLE_TITLE',
		'welcome-menu': 'ANALYTICS.WELCOME_MENU_TABLE_TITLE',
		'team-member': 'ANALYTICS.TEAM_MEMBER_TABLE_TITLE',
	},
	department: 'department',
	ringGroup: 'ring-group',
	teamMember: 'team-member',
	welcomeMenu: 'welcome-menu',
	specialExtension: 'special-extension',
	directory: 'directory',
	callQueue: 'call-queue',
	virtualFax: 'virtual-fax',
	ringer: 'ringer',
	device: 'device',
};
