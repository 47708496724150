import { Component, OnInit, Inject } from '@angular/core';

import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';

@Component({
	selector: 'app-call-queue-reset-prompt',
	templateUrl: './call-queue-reset-prompt.component.html',
	styleUrls: ['./call-queue-reset-prompt.component.scss'],
})
export class CallQueueResetPromptComponent {
	isCallQueueToggled: boolean;

	constructor(@Inject(DIALOG_DATA) data: { isCallQueueToggled: boolean }) {
		this.isCallQueueToggled = data.isCallQueueToggled;
	}
}
