import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Injector, Input } from '@angular/core';

import { TOOLTIP_POSITION, TOOLTIP_TEXT } from '@n2p/tooltip/tooltip.injectors';
import { TooltipComponent } from './tooltip.component';
/* tslint:disable */
@Directive({
	selector: '[n2pTooltip]',
})
/* tslint:enable */
export class TooltipDirective {
	/* tslint:disable */
	@Input('n2pTooltip') toolTipText: string;
	/* tslint:enable */
	@Input() position: 'top' | 'right' | 'left' | 'bottom' = 'top';
	overlayRef: OverlayRef;
	constructor(private overlay: Overlay, private elementRef: ElementRef, private injector: Injector) {}

	@HostListener('mouseout') onMouseLeave(): void {
		if (this.overlayRef) {
			this.overlayRef.dispose();
		}
	}

	@HostListener('mouseover') onMouseOver(): any {
		if (!this.toolTipText) return;

		const diff = window.innerHeight - this.elementRef.nativeElement.getBoundingClientRect()['y'];
		const onTop = diff < 260;
		const onBottom = this.elementRef.nativeElement.getBoundingClientRect()['y'] < 260;

		if (this.position === 'top' && onBottom) {
			this.position = 'bottom';
		} else if (this.position === 'bottom' && onTop) {
			this.position = 'top';
		}

		const config = new OverlayConfig();

		config.positionStrategy = this.overlay
			.position()
			.connectedTo(this.elementRef, this.getOriginPosition(), this.getOverlayPosition())
			.withOffsetX(this.getOffsetX())
			.withOffsetY(this.getOffsetY());

		config.hasBackdrop = false;

		this.overlayRef = this.overlay.create(config);

		this.overlayRef.backdropClick().subscribe();

		const componentPortal = new ComponentPortal(TooltipComponent, undefined, this.createInjector());
		this.overlayRef.attach(componentPortal);
	}

	private createInjector(): PortalInjector {
		const injectorTokens = new WeakMap();

		injectorTokens.set(TOOLTIP_TEXT, this.toolTipText || '');
		injectorTokens.set(TOOLTIP_POSITION, this.position);

		return new PortalInjector(this.injector, injectorTokens);
	}

	private getOriginPosition(): any {
		switch (this.position) {
			case 'top': {
				return { originY: 'top', originX: 'center' };
				break;
			}
			case 'bottom': {
				return { originY: 'bottom', originX: 'center' };
				break;
			}
			case 'left': {
				return { originY: 'center', originX: 'start' };
				break;
			}
			case 'right': {
				return { originY: 'center', originX: 'end' };
				break;
			}
			default: {
				return { originY: 'top', originX: 'center' };
			}
		}
	}

	private getOverlayPosition(): any {
		switch (this.position) {
			case 'top': {
				return { overlayY: 'bottom', overlayX: 'center' };
				break;
			}
			case 'bottom': {
				return { overlayY: 'top', overlayX: 'center' };
				break;
			}
			case 'left': {
				return { overlayY: 'center', overlayX: 'end' };
				break;
			}
			case 'right': {
				return { overlayY: 'center', overlayX: 'start' };
				break;
			}
			default: {
				return { overlayY: 'bottom', overlayX: 'center' };
			}
		}
	}

	private getOffsetY(): number {
		switch (this.position) {
			case 'top': {
				return -10;
				break;
			}
			case 'bottom': {
				return 10;
				break;
			}
			default: {
				return 0;
			}
		}
	}

	private getOffsetX(): number {
		switch (this.position) {
			case 'left': {
				return -10;
				break;
			}
			case 'right': {
				return 10;
				break;
			}
			default: {
				return 0;
			}
		}
	}
}
