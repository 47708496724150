import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { DIALOG_DATA, DIALOG_REF } from '@n2p/dialog/dialog.injectors';
import { ApiTimezoneService } from '@app/services/web-apis/timezone/api-timezone.service';
import { DialogRef } from '@n2p/dialog/dialogRef';

@Component({
	selector: 'app-edit-schedule',
	templateUrl: './edit-schedule.component.html',
	styleUrls: ['./edit-schedule.component.scss'],
})
export class EditScheduleComponent implements OnInit {
	@ViewChild('bodySectionRef', { static: true }) bodySectionRef: ElementRef;
	@ViewChild('footerRef', { static: true }) footerRef: ElementRef;
	scheduleForm: FormGroup;
	schedule: any;
	timezones: Array<any>;
	dialogRef: DialogRef;
	constructor(
		private fb: FormBuilder,
		@Inject(DIALOG_DATA) schedule: any,
		private timezoneService: ApiTimezoneService,
		@Inject(DIALOG_REF) private ref: DialogRef,
		private translate: TranslateService,
	) {
		this.dialogRef = ref;
		this.schedule = schedule;

		this.scheduleForm = this.fb.group({
			id: [schedule.id],
			type: [schedule.type],
			name: [schedule.name, [Validators.required, Validators.maxLength(50)]],
			ownerId: [schedule.ownerId],
			ownerType: [schedule.ownerType],
			timezone: [[], [Validators.required]],
			intervals: this.fb.array(
				schedule.rules.map(rule => {
					return this.fb.group({
						name: rule.name,
						weekdaysOrCalendar: [rule.days.dates ? 'calendar' : 'weekdays'],
						weekDays: [rule.days.weekDays || [], !rule.days.dates ? Validators.required : undefined],
						dates: [
							rule.days.dates
								? !rule.days.isRange
									? rule.days.dates.map(date => new Date(date))
									: { startDate: new Date(rule.days.dates[0]), endDate: new Date(rule.days.dates[1]) }
								: [],
						],
						from: [
							`${rule.time.start.slice(0, 5)}${rule.time.start
								.slice(5)
								.trim()
								.toLowerCase()}`,
						],
						to: [
							`${rule.time.end.slice(0, 5)}${rule.time.end
								.slice(5)
								.trim()
								.toLowerCase()}`,
						],
						fromToFormGroup: this.fb.group({
							from: [this.getHourMinute(rule.time.start)],
							fromAmPm: [this.getAmPm(rule.time.start)],
							to: [this.getHourMinute(rule.time.end)],
							toAmPm: [this.getAmPm(rule.time.end)],
						}),
					});
				}),
			),
			doneInit: [false],
		});
	}

	get showStickyData(): boolean {
		return this.bodySectionRef && this.bodySectionRef.nativeElement.scrollTop > 100;
	}

	get nonEditableScheduleName(): string {
		return (this.schedule?.type === 'Open' ? this.translate.instant('SCHEDULES_PAGE.OPEN') : this.schedule?.name) || '';
	}

	addInterval(): void {
		(this.scheduleForm.get('intervals') as FormArray).push(this.fb.group({}));
	}

	cancel(): void {
		this.dialogRef.dismiss();
	}

	removeInterval(index): void {
		(this.scheduleForm.get('intervals') as FormArray).removeAt(index);
	}

	async ngOnInit(): Promise<any> {
		const response = await this.timezoneService.getTimezones();
		if (response && response.length) {
			this.timezones = response.map(i => {
				return {
					value: `${this.timezoneService.getTimezoneFormattedAbbreviation(i.abbreviation)} - ${i.name}`,
					selected: false,
					abbreviation: i.abbreviation,
				};
			});
			const selectedTimezone = this.timezoneService.findTimezone(this.schedule.timezone);
			this.scheduleForm.get('timezone').setValue([
				{
					value: `${this.timezoneService.getTimezoneFormattedAbbreviation(selectedTimezone.abbreviation)} - ${
						selectedTimezone.name
					}`,
					selected: true,
					abbreviation: selectedTimezone.abbreviation,
				},
			]);
			this.scheduleForm.get('doneInit').setValue(true);
		}
	}

	private getHour = (time: string) => {
		return Number(time.substr(0, time.indexOf(':')));
	};

	private getMinute = (time: string) => {
		return time.substr(time.indexOf(':') + 1, 2);
	};

	private getHourMinute = (time: string): string => {
		const hour = this.getHour(time);
		const minute = this.getMinute(time);

		return `${hour}:${minute}`;
	};

	private getAmPm = (time: string) => {
		return time.substr(time.indexOf(' ') + 1).toLowerCase();
	};
}
