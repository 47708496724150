import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { TimeZoneDropdownComponent } from './time-zone-dropdown.component';
import { DropdownModule } from '@app/n2p-components/dropdown';

@NgModule({
	declarations: [TimeZoneDropdownComponent],
	imports: [CommonModule, DropdownModule, ReactiveFormsModule, TranslateModule],
	exports: [TimeZoneDropdownComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TimeZoneDropdownModule {}
