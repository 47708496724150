import { Subscription } from 'rxjs/Subscription';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Injector,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { CalendarPopupComponent } from '@app/n2p-components/schedule-interval/calendar-popup/calendar-popup.component';
import { FromToPopupComponent } from '@app/n2p-components/schedule-interval/from-to-popup/from-to-popup.component';
import {
	SCHEDULE_INTERVAL_FORMCONTROL,
	SCHEDULE_INTERVAL_FORMGROUP,
} from '@app/n2p-components/schedule-interval/schedule-interval.injectors';
import { DateFormatterService } from '@app/services';
import { IScheduleRuleTimeSetting } from '@app/services/web-apis/schedules/api-schedules.domain';

@Component({
	selector: 'n2p-schedule-interval',
	templateUrl: './schedule-interval.component.html',
	styleUrls: ['./schedule-interval.component.scss'],
})
export class ScheduleIntervalComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('fromToDropdown', { static: true }) fromToDropdown: ElementRef;
	@ViewChild('calendar', { static: false }) calendar: ElementRef;
	@Input() type: 'weekdays' | 'calendar' | null = null;
	@Input() intervalFormGroup: FormGroup;
	@Input() canRemove: boolean;
	@Input() showCalendarToggle: boolean = true;
	@Output() removeInterval = new EventEmitter();
	weekDays: Array<{ text: string; value: number }>;
	fromToOverlayRef: OverlayRef;
	calendarOverlayRef: OverlayRef;
	hiddenDates: Array<any> = [];

	private subscription: Subscription = new Subscription();

	constructor(
		private fb: FormBuilder,
		private overlay: Overlay,
		private injector: Injector,
		private dateFormatter: DateFormatterService,
	) {
		this.weekDays = this.dateFormatter.weekdays.map((day, index) => ({
			text: day[0].toUpperCase(),
			value: index + 1,
		}));
		this.intervalFormGroup = this.fb.group({
			weekDays: [[], Validators.required],
			from: ['', Validators.required],
			to: ['', Validators.required],
			fromToFormGroup: this.fb.group({
				from: [],
				fromAmPm: [],
				to: [],
				toAmPm: [],
			}),
		});
	}

	get fromToTime(): IScheduleRuleTimeSetting | string {
		const fromTime = this.intervalFormGroup.get('from').value;
		const toTime = this.intervalFormGroup.get('to').value;
		if (!fromTime || !toTime) {
			return '';
		} else {
			return {
				start: fromTime,
				end: toTime,
			};
		}
	}

	toggleWeekdaysOrCalendar(selection: 'weekdays' | 'calendar'): void {
		if (selection === 'weekdays') {
			this.intervalFormGroup.contains('weekDays')
				? this.intervalFormGroup.get('weekDays').setErrors(Validators.required)
				: this.intervalFormGroup.addControl('weekDays', new FormControl([], Validators.required));
			this.intervalFormGroup.removeControl('dates');
			this.hiddenDates = [];
		} else {
			this.intervalFormGroup.contains('dates')
				? this.intervalFormGroup.get('dates').setErrors(Validators.required)
				: this.intervalFormGroup.addControl('dates', new FormControl([], Validators.required));
			this.intervalFormGroup.removeControl('weekDays');
			this.subscribeHiddenDates();
		}
		this.intervalFormGroup.get('weekdaysOrCalendar').setValue(selection);
	}

	subscribeHiddenDates(): void {
		this.subscription.add(
			this.intervalFormGroup.get('dates').valueChanges.subscribe(() => {
				setTimeout(() => this.getHiddenDates());
			}),
		);
	}

	dayIsSelected(day: number): boolean {
		return this.intervalFormGroup.get('weekDays').value.findIndex(i => i === day) !== -1;
	}

	selectDay(day: number): void {
		const weekDays = this.intervalFormGroup.get('weekDays').value;
		const index = weekDays.findIndex(i => i === day);
		if (index === -1) weekDays.push(day);
		else weekDays.splice(index, 1);
		this.intervalFormGroup.get('weekDays').setValue(weekDays.sort((a, b) => a - b));
	}

	openFromToPopup(): void {
		this.fromToOverlayRef = this.overlay.create({
			hasBackdrop: true,
			backdropClass: '',
			width: this.fromToDropdown.nativeElement.offsetWidth,
			positionStrategy: this.overlay
				.position()
				.connectedTo(
					this.fromToDropdown,
					{
						originY: 'bottom',
						originX: 'start',
					},
					{
						overlayY: 'top',
						overlayX: 'start',
					},
				)
				.withOffsetY(4),
		});
		this.fromToOverlayRef.backdropClick().subscribe(() => {
			this.fromToOverlayRef.dispose();
			this.fromToOverlayRef = undefined;
		});
		const portalComponent = new ComponentPortal(FromToPopupComponent, undefined, this.createInjector());
		this.fromToOverlayRef.attach(portalComponent);
	}

	openCalendar(): void {
		this.calendarOverlayRef = this.overlay.create({
			hasBackdrop: true,
			backdropClass: '',
			positionStrategy: this.overlay
				.position()
				.connectedTo(
					this.calendar,
					{
						originY: 'top',
						originX: 'start',
					},
					{
						overlayY: 'bottom',
						overlayX: 'start',
					},
				)
				.withOffsetY(-4),
		});
		this.calendarOverlayRef.backdropClick().subscribe(() => {
			this.calendarOverlayRef.dispose();
			this.calendarOverlayRef = undefined;
		});
		const portalComponent = new ComponentPortal(CalendarPopupComponent, undefined, this.createCalendarInjector());
		const ref = this.calendarOverlayRef.attach(portalComponent);
		ref.instance.showToggle = this.showCalendarToggle;
	}

	allDay(ev): void {
		ev.stopPropagation();
		// 00:00:00 - 23:59:59
		this.intervalFormGroup.get('from').setValue('12:00 am');
		this.intervalFormGroup.get('to').setValue('11:59 pm');
		this.intervalFormGroup.get('fromToFormGroup').setValue({
			from: '12:00',
			fromAmPm: 'am',
			to: '11:59',
			toAmPm: 'pm',
		});
	}

	removeClicked(): void {
		this.removeInterval.emit();
	}

	ngOnInit(): void {
		this.intervalFormGroup.addControl(
			'weekdaysOrCalendar',
			new FormControl(this.type === null ? 'weekdays' : this.type),
		);
		if (this.intervalFormGroup.get('weekdaysOrCalendar').value === 'weekdays') {
			this.intervalFormGroup.addControl('weekDays', new FormControl([], [Validators.required]));
		} else {
			this.intervalFormGroup.addControl('dates', new FormControl([], Validators.required));
		}
		this.intervalFormGroup.addControl('from', new FormControl('', [Validators.required]));
		this.intervalFormGroup.addControl('to', new FormControl('', [Validators.required]));
		this.intervalFormGroup.addControl(
			'fromToFormGroup',
			this.fb.group({
				from: [''],
				fromAmPm: [],
				to: [''],
				toAmPm: [],
			}),
		);

		this.subscription.add(
			this.intervalFormGroup.get('fromToFormGroup').valueChanges.subscribe(value => {
				const { from, fromAmPm, to, toAmPm } = value;
				if (this.intervalFormGroup.get('fromToFormGroup').valid && from && to) {
					const fromValue = this.getIntervalValue(from, fromAmPm);
					const toValue = this.getIntervalValue(to, toAmPm);
					this.intervalFormGroup.get('from').setValue(fromValue);
					this.intervalFormGroup.get('to').setValue(toValue);
				} else {
					this.intervalFormGroup.get('from').setValue('');
					this.intervalFormGroup.get('to').setValue('');
				}
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngAfterViewInit(): void {
		if (
			this.intervalFormGroup.contains('dates') &&
			this.intervalFormGroup.get('weekdaysOrCalendar')?.value === 'calendar'
		) {
			setTimeout(() => {
				this.getHiddenDates();
				this.subscribeHiddenDates();
			});
		}
	}

	private getHiddenDates(): void {
		const dates = this.intervalFormGroup.get('dates').value;
		const datesDiv = this.calendar.nativeElement.querySelector('div.dates');
		const countDivWidth = this.hiddenDates.length ? 30 : -30;
		const datesDivWidth = datesDiv.offsetWidth + countDivWidth;
		const spans = datesDiv.querySelectorAll('span');
		const hidden = [];
		let spansTotalWidth = 0;
		spans.forEach((span, index) => {
			spansTotalWidth += span.offsetWidth;
			if (spansTotalWidth > datesDivWidth) hidden.push(dates[index]);
		});

		this.hiddenDates = hidden;
	}

	private createInjector(): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(SCHEDULE_INTERVAL_FORMGROUP, this.intervalFormGroup.get('fromToFormGroup'));

		return new PortalInjector(this.injector, injectorTokens);
	}

	private createCalendarInjector(): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(SCHEDULE_INTERVAL_FORMCONTROL, this.intervalFormGroup.get('dates'));

		return new PortalInjector(this.injector, injectorTokens);
	}

	private getIntervalValue(time: string, amPm: string | void): string {
		const cleanTime = time.replace(/\\s/g, '');
		// We add `0` at the begging for time like `8:00`, because backend supports `08:00` only
		const formattedTime = cleanTime.indexOf(':') === 1 ? `0${cleanTime}` : cleanTime;

		return `${formattedTime}${amPm ? amPm.toLowerCase() : ''}`;
	}
}
