import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '@app/services/api/api.service';
import { IRegularApiResponse } from '../common-api.domain';
import { IAccountExtension } from './api-extensions.domain';

export type ExtensionTypes = 'autoAttendant' | 'teamMember' | 'department' | 'ringGroup' | 'specialExtension';

@Injectable()
export class ApiExtensionsService {
	constructor(private apiService: ApiService) {}

	getExtensions(): Observable<IRegularApiResponse<IAccountExtension>> {
		const url = '/account/{accountId}/extensions';

		return this.apiService.get(url);
	}

	getNextExtension(extensionType: ExtensionTypes = 'teamMember'): Observable<IRegularApiResponse<string>> {
		const url = `/account/{accountId}/extensions/${extensionType}/next`;

		return this.apiService.get(url);
	}

	validateExtension(extension: number | string = 0): Observable<IRegularApiResponse<string>> {
		const url = `/account/{accountId}/validateExtension/${extension}`;

		return this.apiService.get(url);
	}

	validateExtensionName(name: string): Observable<IRegularApiResponse<string>> {
		const url = `/accounts/{accountId}/specialExtensions/validateName/${name}`;

		return this.apiService.get(url);
	}
}
