import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { getCountryCallingCode } from 'libphonenumber-js';
import { Subscription } from 'rxjs/Subscription';
import { LoaderUtil } from 'n2p-ui-library/utils/loader/loader.util';

import { environment } from '@env/environment';
import { FaviconsService } from '@app/services/favicons/favicons.service';
import { ApiAccountsService, GlobalEventsService } from '@app/services';
import { IAccount } from '@app/services/web-apis/accounts/api-accounts.domain';
import { LoggerService } from '@app/services/logger/logger.service';

@Component({
	selector: 'n2p-receptionist-console',
	templateUrl: './receptionist-console.component.html',
	styleUrls: ['./receptionist-console.component.scss'],
})
export class ReceptionistConsoleComponent implements OnInit, OnDestroy {
	private country: any;

	private subscription: Subscription = new Subscription();

	constructor(
		private elementRef: ElementRef,
		private faviconsService: FaviconsService,
		private globalEventsService: GlobalEventsService,
		private accountService: ApiAccountsService,
		private loggerService: LoggerService,
	) {}

	ngOnInit(): void {
		this.faviconsService.use('rcon');
		this.loadRconScript();

		this.subscription.add(
			this.globalEventsService.rconReady.subscribe(ready => {
				if (ready) {
					this.globalEventsService.appendCountry({ country: this.country });
				}
			}),
		);
		this.subscription.add(
			this.accountService.account$.subscribe((accountInfo: IAccount) => {
				if (accountInfo) {
					this.country = { code: accountInfo.country, prefix: getCountryCallingCode(accountInfo.country) };
				}
			}),
		);
	}

	ngOnDestroy(): void {
		this.faviconsService.use('default');
		this.subscription.unsubscribe();
		window.rconManager.unmount();
	}

	private loadRconScript(): void {
		this.loggerService.info('rcon_bundle_loading');

		LoaderUtil.loadScript({ src: environment.rcon_url }).then(() => {
			if (window.rconManager) {
				this.loggerService.info('rcon_bundle_loaded');
				window.rconManager.mount(this.elementRef.nativeElement.querySelector('.mount-point'));
			}
		});
	}
}
