import { Component, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { AuthStorageData } from '@app/services';
import { Policies } from '@app/Common';

@Component({
	selector: 'app-container',
	templateUrl: './app-container.component.html',
	styleUrls: ['./app-container.component.scss'],
})
export class AppContainerComponent {
	readonly impersonate: boolean = false;

	hideMainNav: boolean = false;
	isRconPage: boolean = false;

	@ViewChild(RouterOutlet, { static: true }) private routerOutlet: RouterOutlet;

	constructor(private authStorageDataService: AuthStorageData) {
		this.impersonate = this.authStorageDataService.impersonate;
	}

	onActivate(): void {
		this.hideMainNav = !!this.routerOutlet.activatedRouteData.hideMainNav;
		this.isRconPage = this.routerOutlet.activatedRouteData.policy === Policies.RCON;
	}
}
