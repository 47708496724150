import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/services/api';
import { IRegularApiResponse } from '../common-api.domain';
import { FeatureType, IFeature, OwnerType } from './api-features.domain';

@Injectable()
export class ApiFeaturesService {
	private baseUrl: string = '/accounts/{accountId}';

	//owners
	FEATURE_OWNERS_USERS: string = 'users';
	FEATURE_OWNERS_DEPARTMENTS: string = 'departments';
	FEATURE_OWNERS_RING_GROUPS: string = 'ringGroups';

	//supported settings
	public FeatureSettingId_Record: string = 'record';

	constructor(private apiService: ApiService) {}

	getFeature = (ownerType: OwnerType, ownerId: number | string, feature: FeatureType): Observable<boolean> => {
		let url = `${this.baseUrl}/${ownerType}/${ownerId}/features?features=${feature}`;

		return this.apiService.get<IRegularApiResponse<IFeature[]>>(url).pipe(
			map(response => {
				if (!response.hasError && response.data) {
					const record = response.data.find(f => f.id === feature);

					return !!record && record.active;
				}
			}),
		);
	};

	saveFeature = (
		ownerType: OwnerType,
		ownerId: number | string,
		feature: FeatureType,
		active: boolean,
	): Observable<string> => {
		const features: Array<Partial<IFeature>> = [{ id: feature, active }];
		let url = `${this.baseUrl}/${ownerType}/${ownerId}/features`;

		return this.apiService.put<IRegularApiResponse<string>>(url, features).pipe(map(response => response.data));
	};
}
