import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import {
	MultiSelectDataSimpleCheckedComponent,
	MultiSelectChipTextOnlyComponent,
	SelectDataTextComponent,
} from '@app/shared';

@Component({
	selector: 'app-sortable-user',
	templateUrl: './sortable-user.component.html',
	styleUrls: ['./sortable-user.component.scss'],
})
export class SortableUserComponent implements OnInit {
	@Input() item: any;
	@Input() itemIndex: number;
	@Input() sortableElSelector: string;

	@Output() saveChanges: EventEmitter<any> = new EventEmitter();

	public selectedRingCount: any;
	public canSave: boolean;

	public callOrderDataComponent: any = MultiSelectDataSimpleCheckedComponent;
	public chipComponent: any = MultiSelectChipTextOnlyComponent;
	public ringDataComponent: any = SelectDataTextComponent;

	public formGroup: FormGroup;

	@Input() public rings: Array<{ value: string; count: number; selected: boolean }>;

	constructor(private fb: FormBuilder) {
		this.formGroup = this.fb.group({
			selectedRingCount: [[]],
		});
		this.formGroup.valueChanges.subscribe((res: any) => {
			if (this.item.rings !== res.selectedRingCount[0].count && this.canSave) {
				this.selectedRingCount = this.rings[res.selectedRingCount[0].count - 1];
				this.item.rings = res.selectedRingCount[0].count;
				this.saveChanges.emit(this.item);
			}
		});
	}

	ngOnInit(): void {
		const ringCount = this.item.rings;
		this.selectedRingCount = { ...this.rings[ringCount - 1], selected: true };
		this.rings[ringCount - 1].selected = true;
		this.formGroup.controls.selectedRingCount.setValue([this.selectedRingCount]);
		this.canSave = true;
	}
}
