import { CountryCode } from 'libphonenumber-js';

import { environment } from '@env/environment';

export const URL_PATTERN: string =
	'^(?:(?:https?):\\/\\/)(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-5]))|(?:(?:[a-z\u00a1-\\uffff0-9]-*)*[a-z\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\u00a1-\\uffff0-9]-*)*[a-z\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\u00a1-\\uffff]{2,})))(?::\\d{2,5})?(?:\\/\\S*)?$';

export enum FeatureType {
	DIRECTORY = 'directory',
	API_INTEGRATION = 'api-integration',
	TERMS_AND_POLICIES = 'termsandpolicies',
	OUTBOUND_CALL_BLOCKING = 'outbound-call-blocking',
	PORTS = 'ports',
	MOH_UPLOAD = 'moh-upload',
	API_SETUP = 'api-setup',
	EMERGENCY_CONTACTS = 'emergency-contacts',
	ENABLE_CALL_HISTORY_30 = 'enable-call-history-30',
	UPLOAD_VM_GREETING_USER = 'upload-vm-greeting-user',
	UPLOAD_VM_GREETING_DEP = 'upload-vm-greeting-dep',
	USE_VOICEMAIL_SERVICE = 'use-voicemail-service',
	SAML = 'saml',
	LICENSES = 'licenses',
	EDIT_2FA = 'edit-2fa',
	INTERNATIONAL_CALLING = 'international-calling',
	TEN_DLC = 'ten-dlc',
	VIRTUAL_FAX = 'virtual-fax',
	DELEGATION = 'delegation',
	DEVICE_MANAGEMENT = 'device-management',
	ANALYTICS_PAGE_ENABLED = 'analytics-page-enabled',
	BULK_OPERATIONS = 'bulk-operations',
	SIP_TIE_LINE = 'sip-tie-line',
	PHONE_NUMBERS_MENU = 'phone-numbers-menu',
}

export enum ClaimType {
	DOES_NOT_HAVE_IMPERSONATE_CLAIM = 'does-not-have-impersonate-claim',
	SIP_TRUNKING_MANAGEMENT = 'sip-trunking-management',
}

export enum Policies {
	RCON = 'receptionistconsole',
	SIP_TRUNKING = 'siptrunk',
	SIP_TRUNKING_ONLY = 'siptrunk.account.only',
}

export enum FileSizeLevels {
	KILOBYTE = 1024,
	MEGABYTE = 1024 * 1024,
	GIGABYTE = 1024 * 1024 * 1024,
}

export const PASSWORD_MIN_LENGTH: number = 8;
export const PASSWORD_MAX_LENGTH: number = 100;
export const EMAIL_MAX_LENGTH: number = 100;

export const getApiUrl = (): string => {
	return environment.enableProxy ? '/proxyUrl/' : environment.api_url;
};

export const getAuthUrl = (postfix?: string): string => {
	if (!environment.enableProxy) return environment.api_auth_url;

	return postfix ? `/proxyAuthUrl${postfix}` : '/proxyAuthUrl';
};

export const getUniteUrl = (postfix?: string): string => {
	if (!environment.enableProxy) return environment.api_url;

	return postfix ? `/proxyUniteUrl${postfix}` : '/proxyUniteUrl';
};

export const getPublicURLs = (): string[] => {
	return [`${getAuthUrl()}/connect/token`, `${getAuthUrl()}/connect/session`, `${getApiUrl()}api/features/public`];
};

export const isPublicURL = (url: string): boolean => {
	const urls = getPublicURLs();
	const reducer = (result: boolean, item: string) => result || url.includes(item);
	return urls.reduce(reducer, false);
};

export const DID_LESS_COUNTRIES: CountryCode[] = ['BR', 'MX', 'AR'];
export const BRAZIL: CountryCode[] = ['BR'];
export const US: CountryCode[] = ['US'];
export const MEXICO: CountryCode[] = ['MX'];
export const ARGENTINA: CountryCode[] = ['AR'];

export const APPLICATION_MODE_QUERY_PARAMETER = 'app';
export const APPLICATION_MODE_STORAGE_PARAMETER = 'app';

export const TTS_HIGH_QUALITY_LIMIT = 400;

export const CACHE_KEYS = {
	ASSIGNED_NUMBERS_KEY: 'ASSIGNED_NUMBERS_KEY',
};
