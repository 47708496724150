export enum IGAEventCategory {
	ANALYTICS = 'analytics',
	TEAM_MEMBERS = 'team_members',
	DEPARTMENTS = 'departments',
	WELCOME_MENUS = 'welcome_menus',
	RING_GROUPS = 'ring_groups',
	AUTHENTICATION = 'authentication',
	DOWNLOADS = 'downloads',
	SAML = 'saml',
	LICENSES = 'licenses',
	CALL_HISTORY = 'call_history',
	RESTRICT_CALL_PICKUP = 'restrict_call_pickup',
	VIRTUAL_FAX = 'virtual_fax',
	DELEGATE = 'delegate',
	WEBHOOKS = 'webhooks',
	TOP_NAVIGATION = 'top_navigation',
}

export interface IGAEvent {
	category: IGAEventCategory;
	name: string;
	label?: string;
}

export interface IGtagInternalEvent {
	event_category: string;
	event_label?: string;
	value?: number;
}

export interface IGtagCustomDimensions {
	impersonate: string;
	userType: string;
	userRole: string;
	companyId: string;
	companyName: string;
	userId: string;
	tenantId: string;
	uniteCountry: string;
	browserId: string;
}
