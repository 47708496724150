import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { AddForwardingNumberComponent } from '@n2p/add-forwarding-number/add-forwarding-number.component';
import { DialogService } from '@n2p/dialog/dialog.service';

@Component({
	selector: 'n2p-add-forwarding-number-dropdown-item',
	templateUrl: './add-forwarding-number-dropdown-item.component.html',
	styleUrls: ['./add-forwarding-number-dropdown-item.component.scss'],
})
export class AddForwardingNumberDropdownItemComponent implements OnInit {
	@Output() onClick = new EventEmitter();
	constructor(private dialogService: DialogService) {}

	showAddForwardingNumber(): void {
		const dialogRef = this.dialogService.create(AddForwardingNumberComponent);
		dialogRef.onDismiss().subscribe(num => {
			this.onClick.emit(num);
		});
	}

	ngOnInit(): void {}
}
