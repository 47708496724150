import { LoggerService } from '@app/services/logger/logger.service';

export const loggerInterceptor = (logger: LoggerService, error: any) => {
	const data = {
		reason: error && error.message ? error.message : '',
		status: error && error.status,
	};

	logger.critical('http_error_request', data);

	throw error;
};

export const getLoggerInterceptor = (logger: LoggerService) => {
	return loggerInterceptor.bind(undefined, logger);
};
