export type iconSizes = 's1' | 's2' | 's3' | 'm1' | 'm2' | 'm3' | 'l1' | 'l2' | 'l3' | 'x1';
export const ICON_SIZES = {
	values: ['s1', 's2', 's3', 'm1', 'm2', 'm3', 'l1', 'l2', 'l3', 'x1'],
	default: 's3',
	names: {
		'small-1': 's1',
		'small-2': 's2',
		'small-3': 's3',
		'medium-1': 'm1',
		'medium-2': 'm2',
		'medium-3': 'm3',
		'large-1': 'l1',
		'large-2': 'l2',
		'large-3': 'l3',
		'extra-1': 'x1',
	},
	sizes: {
		s1: 16,
		s2: 22,
		s3: 24,
		m1: 30,
		m2: 36,
		m3: 40,
		l1: 45,
		l2: 50,
		l3: 60,
		x1: 120,
	},
	svgSizes: {
		s1: 8,
		s2: 10,
		s3: 12,
		m1: 15,
		m2: 16,
		m3: 18,
		l1: 19,
		l2: 22,
		l3: 24,
		x1: 50,
	},
	fontSizes: {
		s1: 8,
		s2: 11,
		s3: 11,
		m1: 13,
		m2: 16,
		m3: 18,
		l1: 21,
		l2: 22,
		l3: 24,
		x1: 40,
	},
};
