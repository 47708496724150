import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IDropdownOption } from 'n2p-ui-library/components/dropdown/dropdown.domain';

import { ApiTimezoneService } from '@app/services/web-apis/timezone/api-timezone.service';
import { Subscription } from 'rxjs/Subscription';
import { ApiAccountsService } from '@app/services';

@Component({
	selector: 'n2p-time-zone-dropdown',
	templateUrl: './time-zone-dropdown.component.html',
	styleUrls: ['./time-zone-dropdown.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => TimeZoneDropdownComponent),
		},
	],
})
export class TimeZoneDropdownComponent implements ControlValueAccessor, OnInit {
	@Input() defaultToAccount: boolean;
	@Input() disabled: boolean;

	onChangeFn: (value: string) => void;
	onTouchFn: () => void;

	timezoneOptions: IDropdownOption[];
	selectedTimezoneAbbr: string[];

	constructor(private timezoneService: ApiTimezoneService, private accountService: ApiAccountsService) {}

	writeValue(value: string): void {
		this.selectedTimezoneAbbr = value && [value];
	}

	registerOnChange(fn: any): void {
		this.onChangeFn = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchFn = fn;
	}

	async ngOnInit(): Promise<any> {
		let account;
		if (this.defaultToAccount) {
			account = await this.accountService.getAccount().toPromise();
		}
		const response = await this.timezoneService.getTimezones();

		if (response && response.length) {
			this.timezoneOptions = response.map(i => {
				return {
					title: `${this.timezoneService.getTimezoneFormattedAbbreviation(i.abbreviation)} - ${i.name}`,
					value: i.abbreviation,
				};
			});

			if (this.defaultToAccount && !account.hasError && !this.selectedTimezoneAbbr) {
				this.selectedTimezoneAbbr = [account.data.timeZone];
				this.onChangeFn && this.onChangeFn(this.selectedTimezoneAbbr[0]);
			}
		}
	}

	handleChange(event: CustomEvent<IDropdownOption[]>): void {
		this.onTouchFn && this.onTouchFn();

		const value = event.detail[0] && event.detail[0].value.toString();
		this.onChangeFn && this.onChangeFn(value);
	}
}
