import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
	selector: '[app-vertical-scroller]',
})
export class VerticalScrollerDirective implements OnInit {
	_showScroller: boolean;
	upElement: HTMLElement;
	upArrowElement: HTMLElement;
	downElement: HTMLElement;
	downArrowElement: HTMLElement;
	scrollUpInterval: any;
	scrollDownInterval: any;
	readonly SCROLL_SPEED = 44;
	readonly ACTIVE_COLOR = '#333333';
	readonly DISABLED_COLOR = 'rgba(51, 51, 51, 0.25)';
	readonly WHITE_COLOR = '#ffffff';
	readonly HOVER_COLOR = '#f2f2f2';
	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@Input() set showScroller(show) {
		this._showScroller = show;
		if (!this.upElement || !this.downElement) return;
		if (show) {
			this.upElement.style.display = 'flex';
			this.downElement.style.display = 'flex';
		} else {
			this.upElement.style.display = 'none';
			this.downElement.style.display = 'none';
		}
	}

	get showScroller(): boolean {
		return this._showScroller;
	}

	ngOnInit(): void {
		this.renderer.listen(this.el.nativeElement, 'scroll', () => {
			this.checkScrollPositions();
		});

		this.createUpElement(this.showScroller ? 'flex' : 'none');
		this.createDownElement(this.showScroller ? 'flex' : 'none');
	}

	private scrollAtTop = (): boolean => this.el.nativeElement.scrollTop === 0;
	private scollAtBottom = (): boolean =>
		this.el.nativeElement.scrollHeight - this.el.nativeElement.scrollTop === this.el.nativeElement.clientHeight;

	private disableTop = () => {
		this.upArrowElement.style.borderColor = this.DISABLED_COLOR;
		this.upElement.style.backgroundColor = this.WHITE_COLOR;
	};

	private disableBottom = () => {
		this.downArrowElement.style.borderColor = this.DISABLED_COLOR;
		this.downElement.style.backgroundColor = this.WHITE_COLOR;
	};

	private checkScrollPositions = (): void => {
		this.scrollAtTop() ? this.disableTop() : (this.upArrowElement.style.borderColor = this.ACTIVE_COLOR);
		this.scollAtBottom() ? this.disableBottom() : (this.downArrowElement.style.borderColor = this.ACTIVE_COLOR);
	};

	private createUpElement = (display): void => {
		const up = document.createElement('div');
		up.style.display = display;
		up.style.flexDirection = 'column';
		up.style.justifyContent = 'center';
		up.style.alignItems = 'center';
		up.style.height = '24px';
		const upArrow = document.createElement('div');
		upArrow.style.width = '10px';
		upArrow.style.height = '10px';
		upArrow.style.borderTop = `1px solid ${this.DISABLED_COLOR}`;
		upArrow.style.borderRight = `1px solid ${this.DISABLED_COLOR}`;
		upArrow.style.transform = 'rotate(-45deg)';
		up.appendChild(upArrow);
		this.upElement = up;
		this.upArrowElement = upArrow;

		up.addEventListener('mouseover', () => {
			if (this.scrollAtTop()) return;

			up.style.backgroundColor = this.HOVER_COLOR;
			this.scrollUpInterval = setInterval(() => {
				if (!this.scrollAtTop()) {
					this.el.nativeElement.scrollTop -= this.SCROLL_SPEED;
				}
			}, 200);
		});
		up.addEventListener('mouseout', () => {
			up.style.backgroundColor = this.WHITE_COLOR;
			clearInterval(this.scrollUpInterval);
		});

		this.renderer.insertBefore(this.el.nativeElement.parentNode, up, this.el.nativeElement);
	};

	private createDownElement = (display): void => {
		const down = document.createElement('div');
		down.style.display = display;
		down.style.flexDirection = 'column';
		down.style.justifyContent = 'center';
		down.style.alignItems = 'center';
		down.style.height = '24px';
		const downArrow = document.createElement('div');
		downArrow.style.width = '10px';
		downArrow.style.height = '10px';
		downArrow.style.borderRight = `1px solid ${this.ACTIVE_COLOR}`;
		downArrow.style.borderBottom = `1px solid ${this.ACTIVE_COLOR}`;
		downArrow.style.transform = 'rotate(45deg)';
		down.appendChild(downArrow);
		this.downElement = down;
		this.downArrowElement = downArrow;

		down.addEventListener('mouseover', () => {
			if (this.scollAtBottom()) return;

			down.style.backgroundColor = this.HOVER_COLOR;
			this.scrollDownInterval = setInterval(() => {
				this.el.nativeElement.scrollTop += this.SCROLL_SPEED;
			}, 200);
		});
		down.addEventListener('mouseout', () => {
			down.style.backgroundColor = this.WHITE_COLOR;
			clearInterval(this.scrollDownInterval);
		});

		this.renderer.appendChild(this.el.nativeElement.parentNode, down);
	};
}
