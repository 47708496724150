import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MenuOptionsKeysDataComponent } from '../menu-options-keys-data/menu-options-keys-data.component';

@Component({
	selector: 'app-menu-options-item',
	templateUrl: './menu-options-item.component.html',
	styleUrls: ['./menu-options-item.component.scss'],
})
export class MenuOptionsItemComponent implements OnInit {
	@Input() formGroup: FormGroup;
	@Input() keys: Array<any>;
	@Input() index: number;
	@Output() removeCallback: EventEmitter<any> = new EventEmitter<any>();
	keysDropDownDataComponent: any = MenuOptionsKeysDataComponent;
	_trashFillColor = '#333333';
	_selectedKeys = [];

	@Input() set selectedKeys(keys) {
		this._selectedKeys = keys || [];
	}

	set trashFillColor(color) {
		this._trashFillColor = color;
	}

	get trashFillColor(): string {
		return this._trashFillColor;
	}

	remove(): void {
		const value = this.formGroup.get('key').value.toString();
		if (value) {
			const i = this.keys.findIndex(k => k.key.toString() === value);
			if (i !== -1) {
				this.keys[i].selected = false;
			}
		}

		this.removeCallback.emit(this.index);
	}

	ngOnInit(): void {
		setTimeout(() => {
			if (!this.formGroup.get('key').value.length && !this._selectedKeys.includes(this.keys[this.index].value)) {
				this.formGroup.get('key').setValue([
					{
						value: this.keys[this.index].key,
						selected: true,
					},
				]);
				this.keys[this.index].selected = true;
			}
		});
	}
}
