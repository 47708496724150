import { Injectable } from '@angular/core';

import { MAX_ROWS } from '@app/Common';

@Injectable()
export class PaginationService {
	private dataStore = {};

	setData(key: string, data: Array<any>, pageSize: number = MAX_ROWS, currentPage: number = 1): Array<any> {
		if (!data || !data.length) return [];

		this.dataStore[key] = {
			data,
			pageSize,
			currentPage,
			lastPage: Math.ceil(data.length / pageSize),
			paginatedData: [],
		};

		return this.getPage(key, 1);
	}

	length(key: string): number {
		return this.dataStore[key] ? this.dataStore[key].data.length : 0;
	}
	hasData(key: string): boolean {
		return this.length(key) > 0;
	}
	canPage(key: string): boolean {
		return this.length(key) ? this.dataStore[key].lastPage > 1 : false;
	}
	currentPage(key: string): number {
		return this.dataStore[key].currentPage;
	}

	getPage(key: string, page: number): Array<any> {
		if (!this.length(key)) return [];

		const { data, lastPage, pageSize, currentPage, paginatedData } = this.dataStore[key];

		// set min / max pages
		if (page < 1) page = 1;
		if (page > data.lastPage) page = lastPage;

		// check if already calculated
		if (page === currentPage && paginatedData.length) {
			return paginatedData;
		}

		// calculate start and end
		const startIndex = (page - 1) * pageSize;
		const endIndex = Math.min(startIndex + pageSize - 1, data.length - 1);

		// TODO add logic to set the pagination < 1 2 3 4 5 > numbers in pagination component
		// pager = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i)

		// set values
		this.dataStore[key].currentPage = page;
		this.dataStore[key].paginatedData = data.slice(startIndex, endIndex + 1);

		// return paginated data array
		return this.dataStore[key].paginatedData;
	}
}
