import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { BehaviorSubject, defer } from 'rxjs';

import { ApiService } from '@app/services/api';
import { Address, ICompanyObject } from '@app/Common';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import {
	ICallForwardingRules,
	ICallPickupUsers,
	IPreferredApplicationInfo,
	IUser,
	IUserLight,
	IUserLine,
} from '@app/services/web-apis/users/api-users.domain';
import { AuthStorageData } from '../../auth/auth-storage-data.service';
import { getStorageOutsideOfAngular } from '@utils/helpers/impersonation';
import { IHttpClient } from 'n2p-js-sdk';
import { SdkService } from '@app/services/sdk/sdk.service';
import { DOCUMENT } from '@angular/common';

export interface UpdatePasswordPayload {
	email: string;
	password: string;
	oldPassword: string;
	logoutUser: boolean;
}

@Injectable()
export class ApiUsersService {
	private baseUrl: string = '/accounts/{accountId}/users';
	private userSubject: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
	private authHttpClient: IHttpClient = this.sdkService.authHttp;

	constructor(
		private apiService: ApiService,
		private authStorageDataService: AuthStorageData,
		private sdkService: SdkService,
		@Inject(DOCUMENT) private document: Document,
	) {}

	get user$(): Observable<IUser> {
		return this.userSubject.asObservable();
	}

	canAddDevice(): Observable<boolean> {
		return this.user$.pipe(
			filter(v => !!v),
			map(
				user =>
					!Object.prototype.hasOwnProperty.call(user, 'canCreatePhysicalDevices') || user.canCreatePhysicalDevices,
			),
		);
	}

	getUser = (userId: string = '{userId}'): Observable<IRegularApiResponse<IUser>> => {
		return this.apiService
			.get<IRegularApiResponse<IUser>>(`${this.baseUrl}/${userId}`)
			.pipe(tap(res => !res.hasError && this.userSubject.next(res.data)));
	};

	getCurrentUserApplicationPreference = (): Observable<IPreferredApplicationInfo> => {
		const wnd = this.document.defaultView;
		return this.apiService.get(`${wnd.location.origin}/check-app-preference`).pipe(
			map(resp => {
				return {
					should_reload: resp?.should_reload || false,
					preferred_app: resp?.preferred_app || 'classic',
				};
			}),
			catchError(() => {
				return Observable.of({
					should_reload: false,
					preferred_app: 'classic',
				});
			}),
		);
	};

	createUser = (data: any): Observable<any> => {
		return this.apiService.post(this.baseUrl, data);
	};

	removeUser = (userId: string): Observable<IRegularApiResponse<any>> => {
		return this.apiService.delete(`${this.baseUrl}/${userId}`);
	};

	updateUser = (
		userId: string = '{userId}',
		body: Partial<IUser>,
		accountId: string = '{accountId}',
	): Observable<IRegularApiResponse<IUser>> => {
		return this.apiService
			.put(`/accounts/${accountId}/users/${userId}`, body)
			.pipe(tap(res => !res.hasError && this.userSubject.next(res.data)));
	};

	validateUserExists(emailAddress: string, appToken: string = ''): Observable<any> {
		return this.apiService.head(
			'/users',
			{ emailAddress },
			{
				headers: {
					'Unite.API_AccessToken': appToken,
				},
			},
		);
	}

	// user properties

	insertUserAvatar = (
		userId: string = '{userId}',
		avatar: string,
		mediaType: string = 'image/jpeg',
	): Observable<any> => {
		return this.apiService.post(`${this.baseUrl}/${userId}/avatars`, { avatar }, { mediaType });
	};

	getUserLines = (userId: number | string = '{userId}'): Observable<IUserLine[]> => {
		return this.apiService.get<IRegularApiResponse<IUserLine[]>>(`${this.baseUrl}/${userId}/lines`).pipe(
			map(response => {
				return response.data;
			}),
		);
	};

	updateUserCompanyInfo = (
		userId: string = '{userId}',
		companyData: ICompanyObject,
	): Observable<IRegularApiResponse<any>> => {
		return this.apiService.put(`${this.baseUrl}/${userId}/companyData`, companyData);
	};

	updateUserPassword = (payload: UpdatePasswordPayload): Observable<any> => {
		return defer(() => this.authHttpClient.put(`api/accounts/users`, payload).then(res => res.data));
	};

	resetUserPasswordByAdmin = (username: string, callback: string, language: string): Observable<any> => {
		return defer(() =>
			this.authHttpClient
				.patch(`account/resetPassword/admin?language=${language}`, {
					username,
					callback,
				})
				.then(res => res.data),
		);
	};

	putAvailableCallPickupUsers = (
		userId: string = '{userId}',
		payload: ICallPickupUsers,
	): Observable<ICallPickupUsers> => {
		return this.apiService.put(`${this.baseUrl}/${userId}/cpr`, payload);
	};

	getAvailableCallPickupUsers = (userId: string = '{userId}'): Observable<IRegularApiResponse<ICallPickupUsers>> => {
		return this.apiService.get(`${this.baseUrl}/${userId}/cpr`);
	};

	getUserCallForwardRulesOld = (userId: string = '{userId}'): Observable<IRegularApiResponse<ICallForwardingRules>> => {
		return this.apiService.get<IRegularApiResponse<ICallForwardingRules>>(`${this.baseUrl}/${userId}/callForwardRules`);
	};

	getUserCallForwardRules(userId: number | string = '{userId}'): Observable<ICallForwardingRules> {
		return this.apiService
			.get<IRegularApiResponse<ICallForwardingRules>>(`${this.baseUrl}/${userId}/callForwardRules`)
			.pipe(
				map(response => {
					return response.data;
				}),
			);
	}

	createUserCallForwardRules = (userId: string = '{userId}', rule: object): Observable<any> => {
		return this.apiService.post(`${this.baseUrl}/${userId}/callForwardRules`, rule);
	};

	updateUserCallForwardRulesOld = (userId: string = '{userId}', rule: any): Observable<any> => {
		// TODO fix the passed in rule to be a single rule
		return this.apiService.put(`${this.baseUrl}/${userId}/callForwardRules`, rule[0]);
	};

	updateUserCallForwardRules(
		userId: number | string = '{userId}',
		callForwardingRules: ICallForwardingRules,
	): Observable<ICallForwardingRules> {
		return this.apiService
			.put<IRegularApiResponse<ICallForwardingRules>>(`${this.baseUrl}/${userId}/callForwardRules`, callForwardingRules)
			.pipe(
				tap(response => {
					if (response.hasError) {
						const { errorMessages = [] } = response;
						throw new Error(errorMessages.map(msg => msg.message).join('\n'));
					}
				}),
				map(response => {
					return response.data;
				}),
			);
	}

	resetUserPassword(newPassword: string, appToken: string = ''): Observable<any> {
		const localOrSessionStorage = getStorageOutsideOfAngular();
		const accountId = localOrSessionStorage.getItem('account_id');
		const userId = localOrSessionStorage.getItem('user_id');

		return this.apiService.patch(
			`/accounts/${accountId}/users/${userId}/resetpassword`,
			{ newPassword },
			{},
			{
				headers: {
					'Unite.API_AccessToken': appToken,
				},
			},
		);
	}

	// users

	getAllUsers = (includeLineNumbers: boolean = true, includeDepartmentUser: boolean = true): Observable<any> => {
		const query = {
			includeLineNumbersFlag: includeLineNumbers ? 'Y' : 'N',
			includeDepartmentUser,
		};

		return this.apiService.get(this.baseUrl, query);
	};

	getAllUsersLW(skip: number = 0, take: number = 0): Observable<any> {
		return this.apiService.get(`${this.baseUrl}/light?skip=${skip}&take=${take}`);
	}

	getAllUsersLight(): Observable<IUserLight[]> {
		return this.getAllUsersLW().pipe(
			map(res => {
				if (res.hasError) {
					const message = res.errorMessages ? res.errorMessages[0].message : 'Unknown Error';
					throw new Error(message);
				}
				return res.data.items;
			}),
		);
	}

	validateContactAddress = (address: Address): Observable<any> => {
		return this.apiService.post('/phonenumbers/validateaddress', address.toJSON);
	};
}
