import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ContentChild, Directive, ElementRef, HostListener, Injector, Input, OnDestroy, OnInit } from '@angular/core';

import { PopupComponent, PopupConfig } from '@n2p/popup/popup.component';
import {
	POPUP_COMPONENT,
	POPUP_COMPONENT_DATA,
	POPUP_CONFIG,
	POPUP_CONTENT,
	POPUP_HOVERED,
	POPUP_REF,
} from '@n2p/popup/popup.injectors';

@Directive({
	selector: '[n2p-popup]',
})
export class PopupDirective implements OnInit, OnDestroy {
	@Input() component: any;
	@Input() componentData: any;
	@Input() position: 'top' | 'right' | 'bottom' | 'left' = 'top';
	@Input() action: 'hover' | 'click' = 'hover';
	@ContentChild('popupContent', { static: true }) popupContent: ElementRef;
	private overlayRef: OverlayRef;
	private popupHovered: boolean;
	private calculatedPosition: string;
	constructor(private el: ElementRef, private overlay: Overlay, private injector: Injector) {}

	ngOnInit(): void {
		if (!this.component && !this.popupContent) throw new Error('Component or Content is required');
		if (this.popupContent && this.popupContent.nativeElement) this.popupContent.nativeElement.style.display = 'none';
	}

	ngOnDestroy(): void {
		if (this.overlayRef) this.overlayRef.dispose();
	}

	@HostListener('mouseleave') onMouseLeave(): void {
		setTimeout(() => {
			if (this.overlayRef && this.action === 'hover' && !this.popupHovered) {
				this.overlayRef.dispose();
			}
		});
	}

	@HostListener('mouseover') onMouseOver(): void {
		if (this.action !== 'hover' || this.overlayRef) return;
		this.overlayRef = this.createOverlay();
	}

	@HostListener('click') onClick(): void {
		if (this.action !== 'click') return;
		if (this.overlayRef) return (this.overlayRef = undefined);
		this.overlayRef = this.createOverlay();
	}

	private createOverlay(): OverlayRef {
		const overlayRef = this.overlay.create({
			hasBackdrop: this.action === 'click',
			backdropClass: '',
			positionStrategy: this.getPositionStrategy(),
		});
		overlayRef.backdropClick().subscribe(() => {
			this.overlayRef.dispose();
		});
		overlayRef.detachments().subscribe(() => {
			this.overlayRef = undefined;
			this.popupHovered = false;
		});
		overlayRef.attach(new ComponentPortal(PopupComponent, undefined, this.createPopupInjector(overlayRef)));

		return overlayRef;
	}

	private getPositionStrategy(): any {
		this.calculatedPosition = this.position;
		if (this.calculatedPosition === 'top') {
			if (this.el.nativeElement.getBoundingClientRect()['y'] < 260) this.calculatedPosition = 'bottom';
		} else if (this.calculatedPosition === 'bottom') {
			const diff = window.innerHeight - this.el.nativeElement.getBoundingClientRect()['y'];
			if (diff < 260) this.calculatedPosition = 'top';
		}

		const positionStrategy = this.overlay.position().connectedTo(
			this.el,
			{
				originY: this.calculatedPosition === 'top' ? 'top' : this.calculatedPosition === 'bottom' ? 'bottom' : 'center',
				originX: this.calculatedPosition === 'left' ? 'start' : this.calculatedPosition === 'right' ? 'end' : 'center',
			},
			{
				overlayY:
					this.calculatedPosition === 'top' ? 'bottom' : this.calculatedPosition === 'bottom' ? 'top' : 'center',
				overlayX: this.calculatedPosition === 'left' ? 'end' : this.calculatedPosition === 'right' ? 'start' : 'center',
			},
		);

		return positionStrategy;
	}

	private createPopupInjector(overlayRef): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(POPUP_REF, overlayRef);
		injectorTokens.set(POPUP_CONTENT, this.popupContent);
		if (this.component) {
			injectorTokens.set(
				POPUP_COMPONENT,
				new ComponentPortal(this.component, undefined, this.createComponentInjector(overlayRef)),
			);
		}
		injectorTokens.set(POPUP_CONFIG, {
			action: this.action,
			margin: this.el.nativeElement.offsetHeight,
			position: this.calculatedPosition,
		} as PopupConfig);
		injectorTokens.set(POPUP_HOVERED, () => {
			this.popupHovered = true;
		});

		return new PortalInjector(this.injector, injectorTokens);
	}

	private createComponentInjector(overlayRef: OverlayRef): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(POPUP_COMPONENT_DATA, this.componentData || {});
		injectorTokens.set(POPUP_REF, overlayRef);

		return new PortalInjector(this.injector, injectorTokens);
	}
}
