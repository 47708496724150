import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';

import { ApiVoicemailsService } from '@app/services';
import { DateFormatterService, FORMATS } from '@app/services/date-formatter/date-formatter.service';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { BRAZIL, MEXICO } from '@app/Common';

@Component({
	selector: 'n2p-voice-mail-item',
	templateUrl: './voice-mail-item.component.html',
	styleUrls: ['./voice-mail-item.component.scss'],
})
export class VoiceMailItemComponent implements OnInit {
	@Input() voiceMail: any;
	type: string;
	date: string;
	message: string;
	loading: boolean = true;
	voiceMailAudioState: any = {
		durationToDisplay: '',
		timeElapsedAsPercentage: 0,
		ended: false,
	};

	public isCountryWithSimpleFormatting$: Observable<boolean> = this.apiTenantService.isAccountCountries$([
		...BRAZIL,
		...MEXICO,
	]);

	constructor(
		private apiVoiceMailsService: ApiVoicemailsService,
		private domSanitizer: DomSanitizer,
		private translateService: TranslateService,
		private dateFormatter: DateFormatterService,
		private apiTenantService: ApiTenantService,
	) {}

	voiceMailAudioStateChanged(state: any): void {
		this.voiceMailAudioState = state;
		if (this.voiceMailAudioState.ended) {
			this.updateVoiceMailStatus();
		}
	}

	ngOnInit(): void {
		this.type = this.getVoiceMailType();
		this.date = this.getDate();
		this.fetchMessage();
	}

	private fetchMessage(): void {
		this.apiVoiceMailsService.getVoicemailLink(this.voiceMail.voicemailId).subscribe(
			message => {
				this.message = message;
				this.loading = false;
			},
			err => (this.loading = false),
		);
	}

	private getDate(): string {
		const voiceMailDate = new Date(this.voiceMail.voicemailTime);
		//if (this.isToday(voiceMailDate)) return this.translateService.instant('GLOBALS.TODAY');

		return this.dateFormatter.formatTz(voiceMailDate, FORMATS.DATE_MED);
	}

	private isToday(voiceMailDate: Date): boolean {
		const today = new Date();

		return (
			voiceMailDate.getDate() === today.getDate() &&
			voiceMailDate.getMonth() === today.getMonth() &&
			voiceMailDate.getFullYear() === today.getFullYear()
		);
	}

	private getVoiceMailType(): string {
		const type = this.voiceMail.type.toLowerCase();

		switch (type) {
			case 'departments':
			case 'dept':
				return 'department';
			default:
				return type;
		}
	}

	private updateVoiceMailStatus(): void {
		if (this.type === 'user') {
			this.apiVoiceMailsService.updateUserVoicemail(this.voiceMail.voicemailId, this.voiceMail.ownerId).subscribe();
		} else if (this.type === 'department') {
			this.apiVoiceMailsService
				.updateDepartmentVoicemail(this.voiceMail.ownerId, this.voiceMail.voicemailId)
				.subscribe();
		}
	}
}
