import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { getAvatarUrlFromData } from '@utils/helpers/functions';

@Component({
	selector: 'n2p-dialog-header',
	templateUrl: './dialog-header.component.html',
	styleUrls: ['./dialog-header.component.scss'],
})
export class DialogHeaderComponent implements OnInit {
	@HostBinding('class.header-shadow') scrollNotAtTop: boolean;
	mouseIsDown: boolean;
	isScrolling: any;
	private _body: any;
	constructor() {}

	@Input() iconName: string;
	@Input() iconText: string;
	@Input() avatarData: string;
	@Input() title: string;
	@Input() subtitle: string;
	@Input() iconColor: string;
	@Input() circleColor: string;

	@Input() set bodySection(body) {
		if (body) {
			this._body = body.nativeElement || body;
			this._body.addEventListener('mousedown', () => {
				this.mouseIsDown = true;
			});

			this._body.addEventListener('mouseup', () => {
				this.mouseIsDown = false;
				this.scrollingIsDone();
			});

			this._body.addEventListener('scroll', () => {
				clearTimeout(this.isScrolling);
				this.isScrolling = setTimeout(() => {
					if (!this.mouseIsDown) {
						this.scrollingIsDone();
					}
				}, 100);
			});
		}
	}

	get bodySection(): any {
		return this._body;
	}

	get avatarUrl(): string {
		return getAvatarUrlFromData(this.avatarData, '120');
	}

	ngOnInit(): void {}

	private scrollingIsDone(): void {
		const scrollTop = this.bodySection.scrollTop;
		this.scrollNotAtTop = scrollTop !== 0;
	}
}
