import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'duration',
})
export class DurationPipe implements PipeTransform {
	transform(value: number): string {
		let valueInSeconds = Math.round(value);
		const hours = Math.floor(valueInSeconds / 3600);
		valueInSeconds -= hours * 3600;
		const minutes = Math.floor(valueInSeconds / 60);
		valueInSeconds -= minutes * 60;
		const seconds = valueInSeconds;

		return `${hours ? `${hours}h ` : ''}${minutes}m ${seconds}s`;
	}
}
