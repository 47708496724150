import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs/Observable';
import { combineLatest, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SDKEvent, IJwtClaims } from 'n2p-js-sdk';

import { AuthStorageData } from '@app/services/token';
import { FeatureFlagsService } from '@app/services/feature-flags/feature-flags.service';
import { GoogleAnalyticsService } from '@app/services/google-analytics';
import { ApiAccountsService } from '@app/services/web-apis/accounts/api-accounts.service';
import { ApiUsersService } from '@app/services/web-apis/users/api-users.service';
import { GlobalEventsService } from '@app/services/global-events';
import { IAuthState, IJwt } from '@app/services/web-apis/jwt/jwt.domain';
import { SIGN_IN_GRANT_TYPE } from './auth.domain';
import { isImpersonated } from '@utils/helpers/impersonation';
import { SdkService } from '@app/services/sdk/sdk.service';
import { environment } from '@env/environment';
import { JwtService } from '../web-apis/jwt/jwt.service';

@Injectable()
export class AuthService implements OnDestroy {
	public isAuthenticated$: Observable<boolean> = this.jwtService.isAuthenticated$;
	public initialized: boolean = false;

	private subscriptions: Subscription[] = [];
	private impersonatedSession: boolean = false;

	constructor(
		private jwtService: JwtService,
		private authStorageDataService: AuthStorageData,
		private router: Router,
		private route: ActivatedRoute,
		private featureFlags: FeatureFlagsService,
		private apiAccountsService: ApiAccountsService,
		private apiUsersService: ApiUsersService,
		private GA: GoogleAnalyticsService,
		private eventsService: GlobalEventsService,
		private translateService: TranslateService,
		private sdkService: SdkService,
		@Inject(DOCUMENT) private document: Document,
	) {
		this.subscriptions = [
			Observable.fromEvent(document.defaultView, 'beforeunload')
				.pipe(switchMap(() => this.isAuthenticated$.pipe(take(1))))
				.subscribe(isAuthenticated => this.onBeforePageUnload(isAuthenticated)),
		];

		// TODO: remove location check, the subscription should not work for impersonate, only for ordinary user
		// TODO: The problem is localstorage impersonate flag is overriden and subscription fires for ordinary user sometimes
		if (!document.defaultView.location.href.includes('login_type=impersonate')) {
			this.subscriptions.push(
				Observable.fromEvent(document.defaultView, 'visibilitychange')
					.pipe(
						filter(() => !document.hidden),
						switchMap(() => this.isAuthenticated$.pipe(take(1))),
					)
					.subscribe(isAuthenticated => this.onTabVisibilityChange(isAuthenticated, this.impersonatedSession)),
			);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	/**
	 * Init method is called once when the app is initialized
	 * If initialization logic has to be expanded, please expand onInit method
	 */
	init(): Observable<Partial<IJwtClaims> | undefined> {
		if (!this.jwtService.isAuthenticated || this.initialized) {
			return of(this.jwtService.claims);
		} else {
			return this.onInit().pipe(
				tap(() => (this.initialized = true)),
				switchMap(claims => this.onAfterSignIn(claims)),
			);
		}
	}

	signIn(
		grantType: SIGN_IN_GRANT_TYPE,
		payload: { username: string; password: string } | { code: string; state: string },
	): Observable<IAuthState | undefined> {
		const authenticate = (): Observable<IJwt> => {
			switch (grantType) {
				case SIGN_IN_GRANT_TYPE.PASSWORD: {
					const { username = '', password = '' } = payload as { username: string; password: string };
					return this.jwtService.getTokenWithPassword(username, password);
				}
				case SIGN_IN_GRANT_TYPE.CODE: {
					return this.jwtService.getTokenWithAuthorizationCode();
				}
				default:
					return Observable.throwError(new Error('Unknown grant type'));
			}
		};
		return authenticate().pipe(
			switchMap(({ state }) => {
				const claims = this.jwtService.claims || ({} as IJwtClaims);
				const { 'node.kind': nodeKind = '' } = claims;
				const isUniteUser: boolean = String(nodeKind)
					.toLowerCase()
					.includes('unite');
				return isUniteUser
					? Observable.of([claims, state])
					: throwError(new Error('User is not authorized to use the app'));
			}),
			switchMap(([claims, state]) => this.onAfterSignIn(claims).pipe(map(() => state || undefined))),
			catchError(error => {
				this.removeStoredData();
				return throwError(error);
			}),
		);
	}

	signInWithPassword(username: string, password: string): Observable<IAuthState | undefined> {
		return this.signIn(SIGN_IN_GRANT_TYPE.PASSWORD, { username, password });
	}

	signInWithCode(code: string, state?: string): Observable<IAuthState | undefined> {
		return this.signIn(SIGN_IN_GRANT_TYPE.CODE, { code, state });
	}

	signOut(removeAuthCookie: boolean = true, state?: IAuthState): void {
		this.removeStoredData();
		this.eventsService.logout();
		if (this.impersonatedSession) {
			this.router.navigate(['impersonation-error']);
		} else {
			if (removeAuthCookie) {
				this.jwtService.signOutRedirect(state);
			} else {
				this.jwtService.signInRedirect(state);
			}
		}
	}

	/**
	 * Method is called when user is considered authenticated when the app starts
	 * Provide any additional logic that has to be done before the app starts for authenticated user, ie refresh token
	 * @private
	 */
	private onInit(): Observable<Partial<IJwtClaims> | undefined> {
		if (!isImpersonated) {
			// Refresh token on every auth init
			return this.jwtService.refreshAccessToken().pipe(
				catchError((error: { response: { status: number } }) => {
					const status = error?.response?.status;
					// In case of 4xx error (400, 401, 403) – logout
					if (/^4\d\d$/.test(String(status))) {
						this.signOut(false);
						return throwError(error);
					}
					// Return old token in case of other errors
					return of(this.jwtService.accessToken);
				}),
				switchMap(() => of(this.jwtService.claims)),
			);
		} else {
			return of(this.jwtService.claims);
		}
	}

	/**
	 * Method is called on init before after login logic
	 * Used to check application preference and may reload the page if necessary
	 * @param checkReload {Boolean} - indicated if reload flag should be checked, if false then the page will not be reloaded
	 * @private
	 */
	private checkApplicationPreferenceAndReloadIfNecessary(checkReload: boolean = true): Observable<boolean> {
		const wnd = this.document.defaultView;
		const reloadTimes = parseInt(new URLSearchParams(window.location.search).get('reloadTimes')) || 0;
		const shouldNotCheckPreference =
			wnd.location.hostname.includes('localhost') ||
			this.impersonatedSession ||
			environment.is_admin_app ||
			environment.is_pam_console ||
			reloadTimes >= 3;

		const getApplicationPreference$ = shouldNotCheckPreference
			? Observable.of({ should_reload: false, preferred_app: 'classic' })
			: this.apiUsersService.getCurrentUserApplicationPreference();

		return getApplicationPreference$.pipe(
			map(({ should_reload: shouldReload, preferred_app: preferredApp }) => ({ shouldReload, preferredApp })),
			switchMap(({ shouldReload, preferredApp }) => {
				if (checkReload && (shouldReload || preferredApp !== 'classic')) {
					wnd.location.replace(`${wnd.location.origin}?reloadTimes=${reloadTimes + 1}&clearAuth=true`);
					// return empty observable to wait indefinitely, because we're reloading the page anyway
					return new Observable<boolean>();
				}
				return Observable.of(shouldReload);
			}),
		);
	}

	/**
	 * Method is called when user successfully logs in or already authenticated when the app starts
	 * Used to fill in storage data and receive any additional information required for successful application start
	 * @param claims
	 * @private
	 */
	private onAfterSignIn(claims: Partial<IJwtClaims> | undefined): Observable<Partial<IJwtClaims> | undefined> {
		this.impersonatedSession = claims && claims.scope ? claims.scope.includes('impersonate.webapp') : false;
		this.authStorageDataService.accountId = claims && claims.aid.toString();
		this.authStorageDataService.userId = claims && claims.uid.toString();
		this.subscriptions.push(this.sdkService.on(SDKEvent.REFRESH_ERROR).subscribe(() => this.signOut(false)));
		return this.checkApplicationPreferenceAndReloadIfNecessary().pipe(
			switchMap(() => {
				return combineLatest([
					this.apiAccountsService.getAccount().pipe(map(accountRes => accountRes.data)),
					this.apiUsersService.getUser().pipe(map(userRes => userRes.data)),
					this.featureFlags.initFlags().pipe(catchError(err => Observable.of(undefined))),
				]).pipe(
					tap(([account, user]) => {
						this.authStorageDataService.impersonate = this.impersonatedSession;
						this.authStorageDataService.userRole = user.role;
						this.authStorageDataService.accountName = account.company;
						this.authStorageDataService.timezone = account.timeZone;
						this.authStorageDataService.messengerAccountId = account.ninjaId;
						this.authStorageDataService.userName = `${user.firstName} ${user.lastName}`;
						this.authStorageDataService.callServerVersion = claims['node.kind'] || 'unite';
						this.authStorageDataService.rememberLogin =
							this.impersonatedSession || this.jwtService.hasScope('offline_access');
						this.authStorageDataService.uiLanguageCode = user.uiLanguageCode;
						this.authStorageDataService.timezone = user.timeZone;
					}),
					map(() => claims),
				);
			}),
		);
	}

	/**
	 * Method is called when tab is unloaded
	 * Used for cleaning app any storage data if needed. Should not contain any xhr
	 * @param isAuthenticated
	 * @private
	 */
	private onBeforePageUnload(isAuthenticated: boolean): void {
		if (isAuthenticated && !this.authStorageDataService.rememberLogin) {
			this.removeStoredData();
		}
	}

	/**
	 * Method is called when tab visibility is changed
	 * Used to check if user session is still active
	 * @param isAuthenicated
	 * @param isImpersonated
	 * @private
	 */
	private onTabVisibilityChange(isAuthenicated: boolean, isImpersonated: boolean): void {
		if (isAuthenicated && !isImpersonated) {
			this.jwtService.checkSession().subscribe(result => !result && this.signOut(false));
		}
	}

	private removeStoredData(): void {
		this.authStorageDataService.reset();
		this.jwtService.removeToken();
	}
}
