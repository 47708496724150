export class CacheUtil<T> {
	private static readonly DEFAULT_DELAY: number = 300;
	private cache: Map<string, T> = new Map<string, T>();
	private cacheHandlers: Map<string, number> = new Map<string, number>();

	constructor(private globalCacheTime: number = CacheUtil.DEFAULT_DELAY) {}

	add(key: string, value: T, cacheTime?: number): void {
		if (!this.cache.has(key)) this.set(key, value, cacheTime);
	}

	set(key: string, value: T, cacheTime?: number): void {
		this.cache.set(key, value);

		if (this.cacheHandlers.has(key)) clearTimeout(this.cacheHandlers.get(key));

		this.cacheHandlers.set(key, this.getTimeoutForCacheItem(key, cacheTime));
	}

	has(key: string): boolean {
		return this.cache.has(key);
	}

	get(key: string): T {
		return this.cache.get(key);
	}

	remove(key: string): void {
		this.cache.delete(key);
		clearTimeout(this.cacheHandlers.get(key));
		this.cacheHandlers.delete(key);
	}

	clear(): void {
		this.cache.clear();
		this.cacheHandlers.forEach((id: number) => clearTimeout(id));
		this.cacheHandlers.clear();
	}

	private getTimeoutForCacheItem(key: string, cacheTime: number = this.globalCacheTime): number {
		return window.setTimeout(() => {
			this.remove(key);
		}, cacheTime * 1000);
	}
}
