import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CountryCode } from 'libphonenumber-js';
import { map } from 'rxjs/operators';

import { ShowHideDirective } from '@app/directives/show-hide/show-hide.directive';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';

@Directive({
	selector: '[n2pUnlessCountries]',
})
export class UnlessCountriesDirective extends ShowHideDirective {
	@Input() set n2pUnlessCountries(countries: CountryCode[]) {
		this.n2pShowHide = this.tenantService.isAccountCountries$(countries).pipe(map(value => !value));
	}

	constructor(templateRef: TemplateRef<any>, viewContainer: ViewContainerRef, private tenantService: ApiTenantService) {
		super(templateRef, viewContainer);
	}
}
