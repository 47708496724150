import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Data, Route, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';

@Injectable({
	providedIn: 'root',
})
export class CountryGuard implements CanLoad, CanActivate, OnDestroy {
	isRestricted: boolean = false;
	subscription: Subscription = new Subscription();

	constructor(private tenantService: ApiTenantService, private router: Router) {}

	canLoad(route: Route): boolean {
		return this.check(route.data);
	}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		return this.check(route.data);
	}

	private check(data: Data): boolean {
		let subscription;

		if (data.restrictedToCountries) {
			subscription = this.tenantService.isAccountCountries$(data.restrictedToCountries).subscribe(value => {
				this.isRestricted = value;

				if (this.isRestricted) {
					this.router.navigateByUrl(data.redirectUrl || '');
				}
			});
		} else if (data.allowedToContries) {
			subscription = this.tenantService.isAccountCountries$(data.allowedToContries).subscribe(value => {
				this.isRestricted = !value;

				if (this.isRestricted) {
					this.router.navigateByUrl(data.redirectUrl || '');
				}
			});
		}

		this.subscription.add(subscription);

		return !this.isRestricted;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
