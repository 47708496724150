import { IUserInfo } from '../users/api-users.domain';

export interface IPhoneNumberResponseModel {
	phoneNumbers: Array<IAccountPhone>;
	requestedPortingData: boolean;
	retrievedPortingData: boolean;
}

export interface IAccountPhone {
	carrier: string;
	number: string;
	countryCode: number;
	status: string;
	extension: string;
	routeToId: number;
	routesTo: string;
	routeType: string;
	userInfo: IUserInfo;
	portingInfo: IPortingPhoneModel;
	forbiddenAsCallerId?: boolean;
	pendingNumber?: string | null;
}

export interface IPortingPhoneModel {
	requestedFocDate: string;
	confirmedFocDate: string;
}

export interface IAccountLineExStats {
	accountId: string;
	maxUserPhoneNumbers: number;
	maxDepartmentPhoneNumbers: number;
	maxRingPhoneNumbers: number;
	maxWelcomeMenuPhoneNumbers: number;
	maxPhoneNumbers: number;
	phoneNumbersInUse: number;
	unUsedPhones: number;
}

export interface IParsedLineNumber {
	countryCode: string;
	lineNumber: string;
	addOnParsedInfoList: Array<string>;
}

export enum ICallerIdReplacementMode {
	NONE = 'none',
	APPEND = 'append',
	PREPEND = 'prepend',
	REPLACE = 'replace',
}

export interface ICallerIdReplacementOptions {
	mode: ICallerIdReplacementMode;
	value: string;
}

export interface IAccountPhoneCallerIdReplacementOptions {
	callerIdNumber: ICallerIdReplacementOptions;
	callerIdName: ICallerIdReplacementOptions;
}
