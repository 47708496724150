import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IScheduleRuleDateSetting } from '@app/services/web-apis/schedules/api-schedules.domain';
import { DateFormatterService, FORMATS } from '@app/services/date-formatter/date-formatter.service';

@Pipe({
	name: 'scheduleDays',
})
export class ScheduleDaysPipe implements PipeTransform {
	constructor(private translate: TranslateService, private formatter: DateFormatterService) {}

	get days(): string[] {
		return this.formatter.weekdays;
	}

	transform(setting: IScheduleRuleDateSetting): string {
		if (!setting.weekDays && !setting.dates) return '';

		return setting.weekDays ? this.transformWeekDays(setting) : this.transformDates(setting);
	}

	private transformWeekDays(setting: IScheduleRuleDateSetting): string {
		if (setting.isRange) {
			const firstDayIndex = setting.weekDays[0] - 1;
			const lastDayIndex = setting.weekDays[setting.weekDays.length - 1] - 1;
			const firstDay = this.translate.instant(this.days[firstDayIndex]);
			const lastDay = this.translate.instant(this.days[lastDayIndex]);

			return `${firstDay} - ${lastDay}`;
		} else {
			return setting.weekDays.map(i => this.translate.instant(this.days[i - 1])).join(', ');
		}
	}

	private transformDates(setting: IScheduleRuleDateSetting): string {
		if (setting.isRange) {
			const [start = new Date(), end = new Date()] = setting.dates;
			return `${this.formatter.format(new Date(start), FORMATS.DATE_MED)} - ${this.formatter.format(
				new Date(end),
				FORMATS.DATE_MED,
			)}`;
		} else {
			return setting.dates.map(d => this.formatter.format(new Date(d), FORMATS.DATE_MED)).join(', ');
		}
	}
}
