import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HorizontalSelectComponent } from './horizontal-select.component';

@NgModule({
	declarations: [HorizontalSelectComponent],
	exports: [HorizontalSelectComponent],
	imports: [CommonModule],
})
export class HorizontalSelectModule {}
