import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { InputModule } from '@n2p';
import { TooltipModule } from '@n2p/tooltip/tooltip.module';
import { InputExtensionDirective } from './input-extension.directive';
import { InputExtensionComponent } from './input-extension.component';
import { DirectivesModule } from '@app/directives/directives.module';

@NgModule({
	declarations: [InputExtensionDirective, InputExtensionComponent],
	imports: [CommonModule, InputModule, ReactiveFormsModule, TranslateModule, TooltipModule, DirectivesModule],
	exports: [InputExtensionComponent, InputExtensionDirective],
})
export class InputExtensionModule {}
