import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, Subject, Subscription, BehaviorSubject } from 'rxjs';
import { EVENTS as LIB_EVENTS } from 'n2p-ui-library';

import {
	DEFAULT_N2P_STORAGE,
	DIALER_EVENTS,
	DISPATCHER,
	EVENTS,
	N2P_EVENT,
	N2P_STORAGE,
	N2P_STORAGE_EVENT,
	STORAGE,
} from './global-events.domain';
import { IEventBlockNumber, IEventUnblockNumber } from '@app/services/web-apis/call-blocking/api-call-blocking.domain';
import { IDialerOptions } from '@app/Common/interfaces/dialer';
import { IRconOptions } from '@app/Common/interfaces/rcon';
import { IJwt } from '@app/services/web-apis/jwt/jwt.domain';

@Injectable({
	providedIn: 'root',
})
export class GlobalEventsService implements OnDestroy {
	private subscription: Subscription = new Subscription();

	blockCallerEvent: Subject<IEventBlockNumber> = new Subject();
	unblockCallerEvent: Subject<IEventUnblockNumber> = new Subject();
	rconOpenEvent: Subject<boolean> = new Subject();
	dialerConnected: BehaviorSubject<boolean> = new BehaviorSubject(false);
	refreshTokenEvent: Subject<any> = new Subject();
	notEnoughPermissionsEvent: Subject<string> = new Subject();
	rconReady: Subject<boolean> = new Subject();
	dialerReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
	messengerReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
	messengerSocketReconnected: Subject<void> = new Subject();
	urlChangedExternally: Subject<any> = new Subject();

	constructor() {
		this.subscribe();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	initializeStorage(key: string = 'rconOpen'): void {
		if (!localStorage.getItem(STORAGE.KEY)) {
			localStorage.setItem(STORAGE.KEY, JSON.stringify(DEFAULT_N2P_STORAGE));
		}

		const options: N2P_STORAGE = JSON.parse(localStorage.getItem(STORAGE.KEY)) as N2P_STORAGE;
		this.rconOpenEvent.next(options[key]);
	}

	// TODO not working
	login(auth: IJwt): void {
		GlobalEventsService.publishEvent(EVENTS.LOGIN, auth);
	}

	logout(): void {
		GlobalEventsService.publishEvent(EVENTS.LOGOUT);
	}

	appendOptions(options: Partial<IDialerOptions>): void {
		GlobalEventsService.publishEvent(EVENTS.APPEND_OPTIONS, options);
	}

	appendCountry(options: Partial<IRconOptions>): void {
		GlobalEventsService.publishEvent(EVENTS.APPEND_COUNTRY, options);
	}

	makeCall(callTo: string): void {
		GlobalEventsService.publishEvent(DIALER_EVENTS.CALL, { callTo });
	}

	refreshSession(data: { result: boolean; token: string }): void {
		GlobalEventsService.publishEvent(EVENTS.REFRESH_SESSION, { result: data.result, token: data.token });
	}

	languageChange(lang: string): void {
		GlobalEventsService.publishEvent(EVENTS.LANGUAGE_CHANGE, { lang });
	}

	showSnackbar(event: LIB_EVENTS, data: { text: string; icon: string }): void {
		GlobalEventsService.publishEvent(event, data);
	}

	blockMessengerOutboundNumbers(numbers: string[]): void {
		GlobalEventsService.publishEvent(EVENTS.MESSENGER_BLOCK_OUTBOUND_NUMBERS, numbers);
	}

	updateAccessToken(accessToken: string): void {
		GlobalEventsService.publishEvent(EVENTS.UPDATE_ACESS_TOKEN, { accessToken });
	}

	updateUrl(url: string): void {
		GlobalEventsService.publishEvent(EVENTS.UPDATE_URL_TO_EXTERNAL, { url });
	}

	static publishEvent(event: EVENTS | DIALER_EVENTS | LIB_EVENTS, data?: object): void {
		window.dispatchEvent(
			new CustomEvent(N2P_EVENT, {
				detail: {
					data,
					dispatcher: DISPATCHER,
					event: event,
				},
			}),
		);
	}

	private subscribe(): void {
		this.subscription.add(fromEvent(window, N2P_EVENT).subscribe((e: CustomEvent<any>) => this.windowEventHandler(e)));
		this.subscription.add(fromEvent(window, STORAGE.EVENT).subscribe((e: StorageEvent) => this.storageEventHandler(e)));
	}

	private windowEventHandler(n2pEvent: CustomEvent<any>): void {
		const { event, dispatcher, data } = n2pEvent.detail;

		// don't do anything for webapp events
		if (dispatcher === DISPATCHER) return;

		switch (event) {
			case EVENTS.BLOCK_NUMBER:
				this.blockCallerEvent.next(data as IEventBlockNumber);
				break;
			case EVENTS.UNBLOCK_NUMBER:
				this.unblockCallerEvent.next(data as IEventUnblockNumber);
				break;
			case EVENTS.SESSION_EXPIRED:
				this.refreshTokenEvent.next();
				break;
			case EVENTS.DIALER_IS_CONNECTED:
				this.dialerConnected.next(data.connected as boolean);
				break;
			case EVENTS.NOT_ENOUGH_PERMISSIONS:
				this.notEnoughPermissionsEvent.next(data.route);
				break;
			case EVENTS.RCON_READY:
				this.rconReady.next(true);
				break;
			case EVENTS.DIALER_READY:
				this.dialerReady.next(true);
				break;
			case EVENTS.WEB_MESSENGER_READY:
				this.messengerReady.next(true);
				break;
			case EVENTS.WEB_MESSENGER_RELOAD_REQUESTED:
				this.messengerReady.next(false);
				this.messengerSocketReconnected.next();
				break;
			case EVENTS.UPDATE_URL_FROM_EXTERNAL:
				this.urlChangedExternally.next(data.url);
			default:
			// do nothing?
		}
	}

	private storageEventHandler(n2pEvent: StorageEvent): void {
		// don't do anything for other keys
		if (n2pEvent.key !== STORAGE.KEY) return;

		// make sure new value is valid json
		let event: N2P_STORAGE_EVENT;
		try {
			event = {
				new: JSON.parse(n2pEvent.newValue) as N2P_STORAGE,
				old: JSON.parse(n2pEvent.oldValue) as N2P_STORAGE,
			};
		} catch (e) {
			return;
		}

		if (event.new.rconOpen !== event.old.rconOpen) {
			this.rconOpenEvent.next(event.new.rconOpen);
			return;
		}
	}
}
