import { Subject } from 'rxjs/Subject';
import { OverlayRef } from '@angular/cdk/overlay';
import { EventEmitter } from '@angular/core';

export class DialogRef {
	private subject: Subject<any> = new Subject<any>();
	private overlayRef: OverlayRef;

	public onChange: EventEmitter<any> = new EventEmitter();

	constructor(ref: OverlayRef) {
		this.overlayRef = ref;
	}

	// tslint:disable-next-line:no-null-keyword
	dismiss = (data: any = null) => {
		this.overlayRef.dispose();
		this.subject.next(data);
	};

	complete = () => {
		this.overlayRef.dispose();
		this.subject.complete();
	};

	onDismiss(): any {
		return this.subject.asObservable();
	}
}
