import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IAccountContact } from '@app/services/web-apis/contacts/api-contacts.domain';
import { IServiceAddress } from '@app/pages/teammembers/newserviceaddress/new-service-address.domain';

@Component({
	selector: 'app-new-service-address',
	templateUrl: './new-service-address.component.html',
	styleUrls: ['./new-service-address.component.scss'],
})
export class NewServiceAddressComponent {
	addressForm: FormGroup;

	public valid: boolean = false;

	public address1: string;
	public address2: string;
	public locality: string;
	public city: string;
	public state: string;
	public zip: string;
	public country: string;

	public showAddDevice: boolean = false;

	@Output() onCancel: EventEmitter<void> = new EventEmitter();
	@Output() onSuccess: EventEmitter<IAccountContact> = new EventEmitter();

	onAddressChange({ valid, value: address }: { valid: boolean; value: IServiceAddress }): void {
		this.valid = valid;
		this.address1 = address.address1;
		this.address2 = address.address2;
		this.locality = address.locality;
		this.city = address.city;
		this.state = address.state;
		this.zip = address.zip;
		this.country = address.country;
	}

	changeServiceAddress(): void {
		if (this.valid) {
			this.showAddDevice = true;
		}
	}

	cancel(): void {
		this.onCancel.emit();
	}

	close(): void {
		this.showAddDevice = false;
	}

	submit(serviceAddress: IAccountContact): void {
		this.showAddDevice = false;
		this.onSuccess.emit(serviceAddress);
	}
}
