import { Component, Inject, Injector, OnInit } from '@angular/core';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { DialogRef } from '../classes/DialogRef';
import { DIALOG_COMPONENT, DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';

@Component({
	selector: 'app-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
	component: any;
	constructor(
		@Inject(DIALOG_DATA) private data: any,
		@Inject(DIALOG_REF) private modalRef: DialogRef,
		@Inject(DIALOG_COMPONENT) private modalComponent: any,
		private injector: Injector,
	) {}

	createInjector(data, ref): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(DIALOG_DATA, data);
		injectorTokens.set(DIALOG_REF, ref);
		return new PortalInjector(this.injector, injectorTokens);
	}
	close = () => {
		this.modalRef.dismiss();
	};
	ngOnInit() {
		this.component = new ComponentPortal(this.modalComponent, null, this.createInjector(this.data, this.modalRef));
	}
}
