import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InputHintComponent } from '@n2p/input/input-hint/input-hint.component';

@NgModule({
	imports: [CommonModule],
	declarations: [InputHintComponent],
	exports: [InputHintComponent],
})
export class InputHintModule {}
