import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

import { Alignment } from '@n2p/common';

const sizes = ['small', 'medium', 'large'];

@Component({
	selector: 'n2p-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
	@Input() disabled = false;
	@Input() loading = false;
	@Input() hasData = true;
	@Input() showFooter = true;

	@Input() align: Alignment = Alignment.LEFT;
	@Input() size: 'small' | 'medium' | 'large' = 'medium';
	@Input() width: string;
	@Input() height: string;

	@Input() title: string;
	@Input() noDataSvg: string;
	@Input() noDataText: string;
	@Input() actionLabel: string;

	@Output() actionClicked: EventEmitter<any> = new EventEmitter();

	@HostBinding('class.large') large = false;
	@HostBinding('class.medium') medium = true;
	@HostBinding('class.small') small = false;

	ngOnInit(): void {
		sizes.forEach(size => {
			this[size] = this.size === size;
		});
	}

	actionClick(): void {
		if (!this.actionDisabled) {
			this.actionClicked.emit();
		}
	}

	get actionDisabled(): boolean {
		return !this.actionLabel || this.loading || this.disabled;
	}

	get footerCursor(): string {
		if (this.actionDisabled) {
			return !!this.actionLabel ? 'not-allowed' : 'default';
		}

		return 'pointer';
	}
}
