import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import {
	DEFAULT_CALL_BLOCKING_DATA,
	IBlockAnonymousCallers,
	IBlockedNumber,
	ICallBlockingData,
	ICallBlockingDataExtended,
} from '@app/services/web-apis/call-blocking/api-call-blocking.domain';
import { ApiService } from '@app/services';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';

@Injectable({
	providedIn: 'root',
})
export class ApiCallBlockingService implements Resolve<ICallBlockingData> {
	private readonly baseUrl: string = '/accounts/{accountId}/user/{userId}/callblocking';

	private blockingDataSub: BehaviorSubject<ICallBlockingDataExtended> = new BehaviorSubject(DEFAULT_CALL_BLOCKING_DATA);
	private fetchStartSub: Subject<void> = new Subject();
	private fetchEndSub: Subject<void> = new Subject();

	private pageSize: number = 10;

	get blockingData$(): Observable<ICallBlockingDataExtended> {
		return this.blockingDataSub.asObservable();
	}

	get blockingData(): ICallBlockingDataExtended {
		return this.blockingDataSub.value;
	}

	get fetchStart$(): Observable<void> {
		return this.fetchStartSub.asObservable();
	}

	get fetchEnd$(): Observable<void> {
		return this.fetchEndSub.asObservable();
	}

	constructor(private apiService: ApiService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICallBlockingDataExtended> {
		return this.fetchBlockingData();
	}

	toggleAnonymousBlocking(value: IBlockAnonymousCallers): Observable<void> {
		return this.apiService.post<IRegularApiResponse<string>>(`${this.baseUrl}/anonymous`, value).pipe(
			map(res => {
				if (res.hasError) {
					const message = res.errorMessages ? res.errorMessages[0].message.replace(/<[^>]*>/g, '') : 'Unknown Error';
					throw new Error(message);
				}
			}),
		);
	}

	addBlockedNumber(blockedNumber: IBlockedNumber): Observable<IBlockedNumber> {
		return this.apiService.post<IRegularApiResponse<IBlockedNumber>>(this.baseUrl, blockedNumber).pipe(
			map(res => {
				if (res.hasError) {
					const message = res.errorMessages ? res.errorMessages[0].message : 'Unknown Error';
					throw new Error(message);
				} else {
					const blockingData = { ...this.blockingData };
					const isNotDuplicate = !this.blockingData.BlockedNumbers.find(n => n.Id === res.data.Id);

					if (isNotDuplicate) {
						if (this.isLastPage && !this.currentPageIsFull) {
							blockingData.BlockedNumbers.push(res.data);
						}

						blockingData.Total += 1;

						this.blockingDataSub.next(blockingData);
					}

					return res.data;
				}
			}),
		);
	}

	changeBlockedNumber(blockedNumber: IBlockedNumber): Observable<void> {
		return this.apiService.put<IRegularApiResponse<string>>(this.baseUrl, blockedNumber).pipe(
			map(res => {
				if (res.hasError) {
					const message = res.errorMessages ? res.errorMessages[0].message.replace(/<[^>]*>/g, '') : 'Unknown Error';
					throw new Error(message);
				}
			}),
		);
	}

	deleteBlockedNumbers(blockedNumbers: IBlockedNumber[]): Observable<void> {
		return this.apiService.deleteWithBody<IRegularApiResponse<string>>(this.baseUrl, blockedNumbers).pipe(
			map(res => {
				if (res.hasError) {
					const message = res.errorMessages ? res.errorMessages[0].message : 'Unknown Error';
					throw new Error(message);
				} else {
					if (!this.isLastPage) {
						this.fetchBlockingData(this.blockingData.pageNumber).subscribe(() => {});
					} else if (
						!(this.blockingData.BlockedNumbers.length - blockedNumbers.length) &&
						this.blockingData.pageNumber
					) {
						this.fetchBlockingData(this.blockingData.pageNumber - 1).subscribe(() => {});
					} else {
						const blockedNumbersIds = blockedNumbers.map(n => n.Number);
						const blockingData = { ...this.blockingData };

						blockingData.BlockedNumbers = blockingData.BlockedNumbers.filter(
							n => blockedNumbersIds.indexOf(n.Number) === -1,
						);

						blockingData.Total -= 1;

						this.blockingDataSub.next(blockingData);
					}
				}
			}),
		);
	}

	fetchBlockingData(
		pageNumber: number = 0,
		searchQuery: string = '',
		pageSize: number = this.pageSize,
	): Observable<ICallBlockingDataExtended> {
		const queryParams = { pageNumber, searchQuery, pageSize };

		this.fetchStartSub.next();

		return this.apiService.get<IRegularApiResponse<ICallBlockingData>>(this.baseUrl, queryParams).pipe(
			tap(() => this.fetchEndSub.next()),
			map(res => {
				if (res.hasError) {
					const message = res.errorMessages ? res.errorMessages[0].message : 'Unknown Error';
					throw new Error(message);
				} else {
					const extendedData: ICallBlockingDataExtended = { ...res.data, ...queryParams };
					this.blockingDataSub.next(extendedData);
					return extendedData;
				}
			}),
		);
	}

	checkIsBlockingNumberDuplicate(
		pageNumber: number = 0,
		searchQuery: string = '',
		pageSize: number = this.pageSize,
	): Observable<boolean> {
		const queryParams = { pageNumber, searchQuery, pageSize };

		return this.apiService.get<IRegularApiResponse<ICallBlockingData>>(this.baseUrl, queryParams).pipe(
			map(res => {
				if (res.hasError) {
					const message = res.errorMessages ? res.errorMessages[0].message : 'Unknown Error';
					throw new Error(message);
				} else {
					return res.data.BlockedNumbers.some(blockedNumberObj => blockedNumberObj.Number.toString() === searchQuery);
				}
			}),
		);
	}

	private get pagesCount(): number {
		return Math.ceil(this.blockingData.Total / this.blockingData.pageSize);
	}

	private get isLastPage(): boolean {
		return this.blockingData.pageNumber >= this.pagesCount - 1;
	}

	private get currentPageIsFull(): boolean {
		return this.blockingData.BlockedNumbers.length >= this.blockingData.pageSize;
	}
}
