import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { map, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';

import { ApiService } from '@app/services/api';
import { AuthStorageData } from '@app/services/token';
import { ITimezone } from './api-timezone.domain';
import { DEFAULT_TIMEZONE, SUPPORTED_DATETIME_TIMEZONES } from '@app/Common/constants/date-time-locales';

@Injectable()
export class ApiTimezoneService {
	private timezoneList: ITimezone[] | null = null;

	private fetchTimezonesPromise: Promise<ITimezone[]>;

	private timezonesSubject: BehaviorSubject<ITimezone[]>;

	private currentTimezoneSubject: BehaviorSubject<ITimezone>;

	private subscription: Subscription = new Subscription();

	constructor(private apiService: ApiService, private authStorageDataService: AuthStorageData) {
		this.timezonesSubject = new BehaviorSubject<ITimezone[]>([]);
		this.currentTimezoneSubject = new BehaviorSubject<ITimezone>(this.getCurrentUsersTimezone());
		this.subscription.add(
			this.authStorageDataService.onChange('timezone').subscribe(() => {
				this.onTimezoneChange();
			}),
		);
		this.fetchTimezonesPromise = this.fetchTimezones().toPromise();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	get timezones$(): Observable<ITimezone[]> {
		return this.timezonesSubject.asObservable();
	}

	get currentTimezone$(): Observable<ITimezone> {
		return this.currentTimezoneSubject.asObservable();
	}

	getTimezones(): Promise<ITimezone[]> {
		return this.timezoneList ? Promise.resolve(this.timezoneList) : Promise.resolve(this.fetchTimezonesPromise);
	}

	findTimezone(abbreviation: string): ITimezone {
		return (this.timezoneList || []).find(i => i.abbreviation === abbreviation) || DEFAULT_TIMEZONE;
	}

	getCurrentUsersTimezone(): ITimezone {
		const apiTimezone = this.findTimezone(this.authStorageDataService.timezone);
		return apiTimezone || SUPPORTED_DATETIME_TIMEZONES[this.authStorageDataService.timezone] || DEFAULT_TIMEZONE;
	}

	getTimezoneFormattedAbbreviation(abbreviation: string): string {
		return abbreviation.replace(/^\w+_/, '');
	}

	private fetchTimezones(): Observable<ITimezone[]> {
		return this.apiService.get('/timezones').pipe(
			map(res => {
				return res.hasError ? [] : res.data;
			}),
			tap(timezoneList => {
				this.timezoneList = timezoneList;
				this.timezonesSubject.next(this.timezoneList);
				this.currentTimezoneSubject.next(this.getCurrentUsersTimezone());
			}),
		);
	}

	private onTimezoneChange(): void {
		this.currentTimezoneSubject.next(this.getCurrentUsersTimezone());
	}
}
