import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import {
	PHONE_NUMBERS_DATA,
	PHONE_NUMBERS_RESULT,
	PHONE_NUMBERS_SELETED,
	PHONE_NUMBERS_SINGLE_SELECT,
} from 'utils/constants/injectortokens';
import { PhoneNumbersService } from '../phone-numbers-service/phone-numbers.service';
import { IPhone } from '@app/Common/interfaces/IPhone';

@Component({
	selector: 'app-phone-numbers-results',
	templateUrl: './phone-numbers-results.component.html',
	styleUrls: ['./phone-numbers-results.component.scss'],
})
export class PhoneNumbersResultsComponent implements OnInit {
	@HostBinding('class.n2p-dropdown-data') style = false;
	phoneNumbers: Array<IPhone> = [];
	cachedPhoneNumbers: Array<IPhone> = [];
	searching = false;
	phoneNumbersToReturn = 0;
	singleSelect: boolean;
	constructor(
		@Inject(PHONE_NUMBERS_RESULT) private areaCode: any,
		@Inject(PHONE_NUMBERS_SELETED) private selectedCallback: any,
		@Inject(PHONE_NUMBERS_DATA) private data: { selected: Array<string>; totalAvailable: number },
		@Inject(PHONE_NUMBERS_SINGLE_SELECT) singleSelect: boolean,
		private phoneNumbersService: PhoneNumbersService,
	) {
		this.singleSelect = singleSelect;
	}

	select(phone) {
		if (this.data.selected.length >= this.data.totalAvailable && !phone.selected) {
			// TODO show toast warning
			return;
		}
		phone.selected = !phone.selected;
		if (this.singleSelect) {
			this.phoneNumbers.forEach(p => {
				if (p.number !== phone.number) {
					p.selected = false;
				}
			});
		}
		this.selectedCallback(phone);
	}

	loadMore() {
		if (this.searching) {
			return;
		}
		this.searching = true;
		this.phoneNumbersToReturn += 5;
		this.phoneNumbersService
			.getAvailablePhoneNumbers(this.areaCode, this.phoneNumbersToReturn)
			.pipe(
				map(res =>
					res.data.phones.map(number => {
						return {
							carrier: res.data.carrier,
							number,
							selected: this.data.selected.filter((i: any) => i.number === number).length > 0,
						};
					}),
				),
				tap(() => (this.searching = false)),
			)
			.subscribe(res => {
				if (res && res.length) {
					this.cachedPhoneNumbers = this.cachedPhoneNumbers.concat(
						res.filter(r => this.cachedPhoneNumbers.findIndex(p => p.number === r.number) === -1),
					);
					this.phoneNumbers = this.cachedPhoneNumbers.slice(this.cachedPhoneNumbers.length - 5);
				} else {
					this.phoneNumbers = [];
				}
			});
	}

	ngOnInit() {
		this.style = true;
		this.loadMore();
	}
}
