import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'button[n2p-button-group-item]',
	templateUrl: './button-group-item.component.html',
	styleUrls: ['./button-group-item.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ButtonGroupItemComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
