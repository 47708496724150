import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

import { CallOptionsComponent } from './call-options/call-options.component';
import { CallForwardingComponent } from './call-forwarding/call-forwarding.component';
import { CallOptionsRuleComponent } from './components/call-options-rule/call-options-rule.component';
import { CallOptionsRingsComponent } from './components/call-options-rings/call-options-rings.component';
import { CallOptionsRgStatusComponent } from './components/call-options-rg-status/call-options-rg-status.component';
import { CallOptionsFlagComponent } from './components/call-options-flag/call-options-flag.component';
import { CallOptionsPickupComponent } from './components/call-options-pickup/call-options-pickup.component';
import { CallOptionsService } from '@app/pages/teammembers/call-options/call-options.service';
import { CallOptionsNumbersComponent } from './components/call-options-numbers/call-options-numbers.component';
import { PipesModule } from '@app/pipes';
import { CallOptionsRecordingComponent } from './components/call-options-recording/call-options-recording.component';
import { CallOptionsScheduleComponent } from './components/call-options-schedule/call-options-schedule.component';
import { ScheduleIntervalModule } from '@n2p/schedule-interval/schedule-interval.module';
import { AssignedToDropdownModule } from '@n2p/assigned-to-dropdown';
import { RadioButtonModule } from '@n2p/radio-button/radio-button.module';

@NgModule({
	declarations: [
		CallOptionsComponent,
		CallForwardingComponent,
		CallOptionsRuleComponent,
		CallOptionsRingsComponent,
		CallOptionsRgStatusComponent,
		CallOptionsPickupComponent,
		CallOptionsFlagComponent,
		CallOptionsNumbersComponent,
		CallOptionsRecordingComponent,
		CallOptionsScheduleComponent,
	],
	exports: [CallOptionsComponent, CallForwardingComponent, CallOptionsScheduleComponent],
	imports: [
		CommonModule,
		TranslateModule,
		PipesModule,
		ScheduleIntervalModule,
		ReactiveFormsModule,
		AssignedToDropdownModule,
		RadioButtonModule,
	],
	providers: [CallOptionsService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CallOptionsModule {}
