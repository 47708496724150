import {
	Component,
	ElementRef,
	EventEmitter,
	Injector,
	Input,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';
import {
	DROPDOWN_CONNECTION_POSITION_MAP,
	IDropdownConnectionPosition,
	IDropdownOptions,
} from '@n2p/templated-popup/templated-popup.domain';

@Component({
	selector: 'app-templated-popup',
	templateUrl: './templated-popup.component.html',
	styleUrls: ['./templated-popup.component.scss'],
})
export class TemplatedPopupComponent implements OnInit, OnDestroy {
	/**
	 * Setting dropdownOptions activates 'dropdown' mode: place template according to anchor element position
	 */
	@Input() dropdownOptions: IDropdownOptions;
	@Input() hasCloseButton: boolean = true;
	@Input() hasTransparentBackdrop: boolean = false;

	@Output() backdropClick: EventEmitter<void> = new EventEmitter();
	@Output() closeClick: EventEmitter<void> = new EventEmitter();
	/**
	 * Any 'cancel' action click like: close button, backdrop
	 */
	@Output() anyCancelClick: EventEmitter<void> = new EventEmitter();

	private portal: TemplatePortal<TemplatedPopupComponent>;
	private overlayRef: OverlayRef;
	private subscription: Subscription;

	@ViewChild('template', { static: true }) private templateRef: TemplateRef<TemplatedPopupComponent>;

	constructor(private injector: Injector, private overlay: Overlay, private viewContainerRef: ViewContainerRef) {}

	private get anchorElement(): HTMLElement {
		if (this.dropdownOptions.elem instanceof ElementRef) {
			return this.dropdownOptions.elem.nativeElement;
		} else {
			return this.dropdownOptions.elem;
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
		this.overlayRef.dispose();
	}

	onCloseClick = (): void => {
		this.closeClick.emit();
		this.anyCancelClick.emit();
	};

	private createPositionStrategy(): PositionStrategy {
		return this.dropdownOptions ? this.createPositionStrategyByAnchor() : this.createGlobalPositionStrategy();
	}

	private get anchorElementRef(): ElementRef {
		if (this.dropdownOptions.elem instanceof ElementRef) {
			return this.dropdownOptions.elem;
		} else {
			return new ElementRef(this.dropdownOptions.elem);
		}
	}

	private createGlobalPositionStrategy(): PositionStrategy {
		return this.overlay
			.position()
			.global()
			.centerHorizontally()
			.centerVertically();
	}

	ngOnInit(): void {
		const overlayConfig: OverlayConfig = {
			hasBackdrop: true,
			positionStrategy: this.createPositionStrategy(),
			panelClass: 'templated-popup-wrapper',
		};

		if (this.hasTransparentBackdrop || this.dropdownOptions) {
			overlayConfig.backdropClass = 'cdk-transparent-backdrop';
		}

		if (this.dropdownOptions && this.dropdownOptions.widthByElem) {
			overlayConfig.width = this.anchorElement.offsetWidth;
		}

		this.overlayRef = this.overlay.create(overlayConfig);
		this.portal = new TemplatePortal(this.templateRef, this.viewContainerRef);
		this.overlayRef.attach(this.portal);

		this.subscription = this.overlayRef.backdropClick().subscribe(() => {
			this.backdropClick.emit();
			this.anyCancelClick.emit();
		});
	}

	private createPositionStrategyByAnchor(): PositionStrategy {
		const connectionPosition = DROPDOWN_CONNECTION_POSITION_MAP[this.dropdownOptions.positionType];
		return this.overlay
			.position()
			.connectedTo(this.anchorElementRef, connectionPosition.originPos, connectionPosition.overlayPos);
	}
}
