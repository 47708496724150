import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { DROPDOWN_ADDITIONAL_DATA, DROPDOWN_DATA, DROPDOWN_DATA_FILTER } from '@n2p/dropdown/dropdown.injectors';

class Key {
	value: any;
	key: any;
	selected: boolean;
}

@Component({
	selector: 'app-menu-options-keys-data',
	templateUrl: './menu-options-keys-data.component.html',
	styleUrls: ['./menu-options-keys-data.component.scss'],
})
export class MenuOptionsKeysDataComponent implements OnInit {
	searchStr: string;
	initialKey: Key;
	selectedKeys: Array<any>;
	filterSelectedKeys: Array<any>;
	constructor(
		@Inject(DROPDOWN_DATA) private dataControl: FormControl,
		@Inject(DROPDOWN_DATA_FILTER) private filter: FormControl,
		@Inject(DROPDOWN_ADDITIONAL_DATA) private additionData: any,
	) {}

	get dropdownData(): Array<any> {
		return this.searchStr
			? this.dataControl.value.filter(i => i.value.toString().toLowerCase() === this.searchStr)
			: this.dataControl.value;
	}

	disabledKey(key): boolean {
		return !key.selected && this.filterSelectedKeys && this.filterSelectedKeys.includes(key.value);
	}

	select(key: Key): void {
		if (this.selectedKeys.includes(key.value)) return;
		this.dropdownData.forEach(i => {
			if (i.value !== key.value) i.selected = false;
		});
		key.selected = !key.selected;
		this.dataControl.updateValueAndValidity();
	}

	ngOnInit(): void {
		this.initialKey = this.dataControl.value.find(i => i.selected);
		this.selectedKeys = this.additionData.selectedKeys;
		if (this.initialKey) {
			this.filterSelectedKeys = this.selectedKeys.filter(i => i.toString() !== this.initialKey.value.toString());
		}
		this.filter.valueChanges
			.map((value: string) => (value ? value.toLowerCase() : ''))
			.subscribe((value: string) => (this.searchStr = value));
	}
}
