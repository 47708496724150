import { registerLocaleData } from '@angular/common';

import { SUPPORTED_LANGUAGE_VALUES } from './translation.config';

export function registerSupportedLocales(): void {
	for (const lang of SUPPORTED_LANGUAGE_VALUES) {
		const locale = `@angular/common/locales/${lang}`;
		const localeExtra = `@angular/common/locales/extra/${lang}`;
		registerLocaleData(locale, localeExtra);
	}
}
