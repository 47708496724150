import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';

import { ShowHideDirective } from '@app/directives/show-hide/show-hide.directive';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';

@Directive({
	selector: '[n2pIfDidlessCountry]',
})
export class IfDidlessCountryDirective extends ShowHideDirective {
	constructor(templateRef: TemplateRef<any>, viewContainer: ViewContainerRef, private tenantService: ApiTenantService) {
		super(templateRef, viewContainer);

		this.n2pShowHide = this.tenantService.accountCountryIsDidLess$;
	}
}
