import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
	IDropdownAction,
	IDropdownConfirmData,
	IDropdownOption,
} from 'n2p-ui-library/components/dropdown/dropdown.domain';
import { DropdownActionType } from 'n2p-ui-library/constants';
import { Observable } from 'rxjs';
import { first, share, tap } from 'rxjs/operators';
import { InitialsComponentBackground } from 'n2p-ui-library/constants';

import { ApiSpecialExtensionsService, CacheService, DialogService } from '@app/services';
import { ApiPhoneNumbersService } from '@app/services/web-apis/phone-numbers/api-phone-numbers.service';

import { PhoneNumberFormatterPipe } from '@app/pipes';

import { IAccountPhone } from '@app/services/web-apis/phone-numbers/api-phone-numbers.domain';
import {
	getValidType,
	VALID_DEPARTMENT,
	VALID_DIRECTORY,
	VALID_RING_GROUP,
	VALID_SPECIAL_EXTENSION,
	VALID_TEAM_MEMBER,
	VALID_WELCOME_MENU,
	VALID_CALL_QUEUE,
} from '@app/Common/constants/entity-types';
import { IReassignNumbersPrompt } from '@n2p/assigned-numbers/components/reassign-numbers-prompt/reassign-numbers-prompt.component';

import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import {
	ISpecialExtension,
	ISpecialExtensionLight,
} from '@app/services/web-apis/special-extensions/api-special-extensions.domain';
import { CACHE_KEYS } from '@app/Common';

enum AssignedNumbersActionsIds {
	UNASSIGNED,
	ADD_PHONE_NUMBER,
}

@Component({
	selector: 'n2p-assigned-numbers',
	templateUrl: './assigned-numbers.component.html',
	styleUrls: ['./assigned-numbers.component.scss'],
	providers: [
		PhoneNumberFormatterPipe,
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => AssignedNumbersComponent),
		},
	],
})
export class AssignedNumbersComponent implements OnInit, OnDestroy, ControlValueAccessor {
	private static phoneNumbers$: Observable<IRegularApiResponse<IAccountPhone[]>>;
	private static specialExtensions$: Observable<IRegularApiResponse<ISpecialExtension[]>>;
	@Input() initialsBgColor: InitialsComponentBackground = InitialsComponentBackground.PRIMARY_DARK_1;
	@Input() disabled: boolean = false;
	@Input() parentEntityName: string;
	@Input() parentEntityInitials?: string;
	@Input() label: string;
	@Input() required: boolean = true;
	@Input() listenToCache: boolean = false;
	@Input() hasError: boolean;
	@Input() phoneNumbers: IAccountPhone[];
	@Input() multi: boolean = true;
	@Input() tableMode: boolean = false;
	@Input() enableAddPhone: boolean = false;
	@Input() specialExtension: ISpecialExtensionLight;
	@Input() hint: string = '';
	@Input() isPhoneNumberLimitReached: boolean = false;
	@Input() excludeSingleLineUsers: boolean = true;
	phoneOptions: IDropdownOption[];
	reassignedNumber: IReassignNumbersPrompt;
	reassignCallback: (result: boolean) => void;
	selectedValues: Array<string | number>;
	phoneActions: IDropdownAction[];
	onChangeFn: (values: Array<string | number>) => void;
	onTouchFn: () => void;
	isPhonesLoading: boolean = false;
	specialExtensions: Array<ISpecialExtension> = [];
	private readonly ASSIGNED_NUMBERS_KEY: string = CACHE_KEYS.ASSIGNED_NUMBERS_KEY;
	private subscriptions: any[] = [];
	showAddPhoneNumbers: boolean = false;

	constructor(
		private phoneNumbersService: ApiPhoneNumbersService,
		private phoneNumberFormatterPipe: PhoneNumberFormatterPipe,
		private cacheService: CacheService,
		private translateService: TranslateService,
		private dialogService: DialogService,
		private apiSpecialExtensions: ApiSpecialExtensionsService,
	) {}

	writeValue(value: Array<string | number>): void {
		this.selectedValues = value;
	}

	registerOnChange(fn: any): void {
		this.onChangeFn = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchFn = fn;
	}

	ngOnInit(): void {
		this.setPhoneOptions();
		this.required || this.setUnassignedAction();
		this.enableAddPhone && this.setAddPhoneAction();
		this.setSpecialExtensions();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
		this.reassignCallback = undefined;
	}

	onChange(event: CustomEvent<IDropdownOption[]>): void {
		this.selectedValues = event.detail.map(d => d.value);
	}

	onClose(): void {
		this.onTouchFn && this.onTouchFn();
		this.onChangeFn && this.onChangeFn(this.selectedValues);
	}

	onConfirmSelect(event: CustomEvent<IDropdownConfirmData>): void {
		const { option, callback } = event.detail;

		if (option.subTitle) {
			this.reassignedNumber = {
				number: option.title,
				numberAssignedTo: option.subTitle,
				assignToEntity: this.parentEntityName,
				assignToEntityInitials: this.parentEntityInitials,
				specialExtension: this.specialExtensions.find(ext => ext.lines.some(line => line.lineId === option.value)),
			};
			this.reassignCallback = callback;
		} else {
			callback(true);
		}
	}

	onAction(event: CustomEvent<IDropdownAction>): void {
		const action = event.detail;

		switch (action.id) {
			case AssignedNumbersActionsIds.ADD_PHONE_NUMBER:
				return this.showAddPhoneNumberPopup();
			case AssignedNumbersActionsIds.UNASSIGNED:
			default:
				return this.resetNumbers();
		}
	}

	cancelReassign(): void {
		this.reassignCallback(false);
		this.reassignedNumber = undefined;
		this.reassignCallback = undefined;
	}

	acceptReassign(): void {
		this.reassignCallback(true);
		// Hack for resolving `ExpressionChangedAfterItHasBeenCheckedError` error
		setTimeout(() => {
			this.reassignedNumber = undefined;
			this.reassignCallback = undefined;
		});
	}

	private setPhoneOptions(): void {
		const cachedData = this.cacheService.get(this.ASSIGNED_NUMBERS_KEY);

		if (this.phoneNumbers) {
			this.phoneOptions = this.generatePhonesOptions(this.phoneNumbers);
			this.setCache();
		} else if (cachedData && this.listenToCache) {
			// TODO discuss what to do about caching data
			this.phoneOptions = cachedData;
			this.subscribeToCache();
		} else {
			this.loadPhoneNumbers();
		}
	}

	private loadPhoneNumbers(): void {
		this.isPhonesLoading = true;
		this.fetchPhoneNumbers().subscribe(res => {
			this.isPhonesLoading = false;
			this.phoneOptions = this.generatePhonesOptions(res.data);
			this.phoneNumbers = res.data;
			this.setCache();
		});
	}

	setSpecialExtensions(): void {
		this.subscriptions.push(
			this.fetchSpecialExtensions().subscribe((result: IRegularApiResponse<ISpecialExtension[]>) => {
				if (!result.hasError && (!result.errorMessages || !result.errorMessages.length)) {
					this.specialExtensions = result.data;
				}
			}),
		);
	}

	private setUnassignedAction(): void {
		this.phoneActions = [
			{
				title: this.translateService.instant('GLOBALS.UNASSIGNED'),
				type: DropdownActionType.PRIMARY,
				id: AssignedNumbersActionsIds.UNASSIGNED,
				disabled: this.isPhoneNumberLimitReached,
			},
		];
	}

	private setAddPhoneAction(): void {
		this.phoneActions = [
			{
				title: this.translateService.instant('GLOBALS.ADD_PHONE_NUMBER'),
				type: DropdownActionType.PRIMARY,
				id: AssignedNumbersActionsIds.ADD_PHONE_NUMBER,
				disabled: this.isPhoneNumberLimitReached,
			},
		];
	}

	private generatePhonesOptions(phones: IAccountPhone[]): IDropdownOption[] {
		return this.generateSortedPhones(phones).map(this.generatePhoneOption.bind(this));
	}

	private generatePhoneOption(phone: IAccountPhone): IDropdownOption {
		return {
			title: this.phoneNumberFormatterPipe.transform({ number: phone.number }),
			value: phone.number,
			subTitle: phone.extension ? `${phone.routesTo} • ${phone.extension}` : phone.routesTo,
			hint: phone.pendingNumber ? this.phoneNumberFormatterPipe.transform({ number: phone.pendingNumber }) : undefined,
			hintIconName: phone.pendingNumber ? 'arrows-two-way' : undefined,
			initials: {
				iconName: 'call-filled',
			},
		};
	}

	private generateSortedPhones(phones: IAccountPhone[]): IAccountPhone[] {
		const sortPhones = (a: IAccountPhone, b: IAccountPhone): number => {
			return a.routesTo > b.routesTo ? 1 : -1;
		};

		return [
			...phones.filter(p => !p.routeToId).sort(sortPhones),
			...phones.filter(p => getValidType(p.routeType) === VALID_TEAM_MEMBER).sort(sortPhones),
			...phones.filter(p => getValidType(p.routeType) === VALID_DEPARTMENT).sort(sortPhones),
			...phones.filter(p => getValidType(p.routeType) === VALID_RING_GROUP).sort(sortPhones),
			...phones.filter(p => getValidType(p.routeType) === VALID_WELCOME_MENU).sort(sortPhones),
			...phones.filter(p => getValidType(p.routeType) === VALID_SPECIAL_EXTENSION).sort(sortPhones),
			...phones.filter(p => getValidType(p.routeType) === VALID_DIRECTORY).sort(sortPhones),
			...phones.filter(p => getValidType(p.routeType) === VALID_CALL_QUEUE).sort(sortPhones),
		];
	}

	private resetNumbers(): void {
		this.phoneOptions = this.phoneOptions.map(option => {
			if (this.selectedValues.includes(option.value)) {
				option.subTitle = undefined;
			}
			return option;
		});
		this.selectedValues = [];
		this.onTouchFn && this.onTouchFn();
		this.onChangeFn && this.onChangeFn(this.selectedValues);
	}

	private showAddPhoneNumberPopup(): void {
		this.showAddPhoneNumbers = true;
	}

	private setCache(): void {
		this.cacheService.set(this.ASSIGNED_NUMBERS_KEY, this.phoneOptions);
		this.subscribeToCache();
	}

	private subscribeToCache(): void {
		if (!this.listenToCache) return;
		this.subscriptions.push(
			this.cacheService.subscribeToKey(this.ASSIGNED_NUMBERS_KEY).subscribe(value => {
				this.phoneOptions = value;
			}),
		);
	}

	private fetchPhoneNumbers(): Observable<IRegularApiResponse<IAccountPhone[]>> {
		if (!AssignedNumbersComponent.phoneNumbers$) {
			AssignedNumbersComponent.phoneNumbers$ = this.phoneNumbersService
				.getAllPhoneNumbers(this.excludeSingleLineUsers)
				.pipe(
					share(),
					tap(() => {
						AssignedNumbersComponent.phoneNumbers$ = undefined;
					}),
					first(),
				);
		}

		return AssignedNumbersComponent.phoneNumbers$;
	}

	private fetchSpecialExtensions(): Observable<IRegularApiResponse<ISpecialExtension[]>> {
		if (!AssignedNumbersComponent.specialExtensions$) {
			AssignedNumbersComponent.specialExtensions$ = this.apiSpecialExtensions.getSpecialExtensions().pipe(
				share(),
				tap(() => {
					AssignedNumbersComponent.specialExtensions$ = undefined;
				}),
				first(),
			);
		}

		return AssignedNumbersComponent.specialExtensions$;
	}

	onAddPhoneNumber(): void {
		this.showAddPhoneNumbers = false;
		this.loadPhoneNumbers();
	}

	onCloseAddPhoneNumber = () => {
		this.showAddPhoneNumbers = false;
	};
}
