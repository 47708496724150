import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { dateSubtract, DateUnit, isSameDay } from 'n2p-ui-library/utils/date.util';

import { POPUP_CLICK_CALLBACK } from '@n2p/popup/popup.injectors';
import { ReportType } from '@app/pages/analytics/models';

@Component({
	selector: 'app-download-dropdown',
	templateUrl: './download-dropdown.component.html',
	styleUrls: ['./download-dropdown.component.scss'],
})
export class DownloadDropdownComponent implements OnInit {
	showDateRangePicker: boolean = false;
	selectedReportType: ReportType;
	rangeMax: Date = new Date();
	rangeEnd: Date = new Date();
	rangeStart: Date = dateSubtract(new Date(), 1, DateUnit.MONTH);

	readonly reports: { title: string; type: ReportType }[] = [
		{
			title: 'CALLS_TABLE_PAGE.GENERATE_TODAY',
			type: ReportType.TODAY,
		},
		{
			title: 'CALLS_TABLE_PAGE.GENERATE_WEEKS_1',
			type: ReportType.WEEKS_1,
		},
		{
			title: 'CALLS_TABLE_PAGE.GENERATE_WEEKS_2',
			type: ReportType.WEEKS_2,
		},
		{
			title: 'CALLS_TABLE_PAGE.GENERATE_MONTH',
			type: ReportType.MONTH,
		},
	];

	constructor(@Inject(POPUP_CLICK_CALLBACK) private callback: Function) {}

	@HostListener('click', ['$event']) onClick(ev): void {
		ev.stopPropagation();
	}

	close(reportType: ReportType): void {
		if (reportType) {
			this.selectedReportType = reportType;
			this.setPresetDates();
			this.updateData();
		} else {
			this.showDateRangePicker = false;
			this.callback();
		}
	}

	setPresetDates(): void {
		this.rangeEnd = new Date();

		switch (this.selectedReportType) {
			case ReportType.TODAY:
				this.rangeStart = new Date();
				break;
			case ReportType.WEEKS_1:
				this.rangeStart = dateSubtract(new Date(), 1, DateUnit.WEEK);
				break;
			case ReportType.WEEKS_2:
				this.rangeStart = dateSubtract(new Date(), 2, DateUnit.WEEK);
				break;
			case ReportType.MONTH:
				this.rangeStart = dateSubtract(new Date(), 1, DateUnit.MONTH);
				break;
			default:
				break;
		}
	}

	// TODO refactor duplicate logic from analytics
	setCustomDates(dates: { startDate: Date; endDate: Date }): void {
		if (!dates) {
			this.showDateRangePicker = false;
			this.callback();
			return;
		}

		this.rangeStart = dates.startDate;
		this.rangeEnd = dates.endDate;

		// set correct report type
		if (isSameDay(this.rangeStart, new Date())) {
			this.selectedReportType = ReportType.TODAY;
		} else if (isSameDay(this.rangeStart, dateSubtract(this.rangeEnd, 1, DateUnit.WEEK))) {
			this.selectedReportType = ReportType.WEEKS_1;
		} else if (isSameDay(this.rangeStart, dateSubtract(this.rangeEnd, 2, DateUnit.WEEK))) {
			this.selectedReportType = ReportType.WEEKS_2;
		} else if (isSameDay(this.rangeStart, dateSubtract(this.rangeEnd, 1, DateUnit.MONTH))) {
			this.selectedReportType = ReportType.MONTH;
		} else {
			this.selectedReportType = ReportType.CUSTOM;
		}

		this.updateData();
	}

	private updateData(): void {
		this.showDateRangePicker = false;

		this.callback({
			type: this.selectedReportType,
			startDate: new Date(this.rangeStart.setHours(0, 0, 0, 0)),
			endDate: new Date(this.rangeEnd.setHours(23, 59, 59, 999)),
		});
	}

	ngOnInit(): void {}
}
