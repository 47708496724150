import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { IHttpClient } from 'n2p-js-sdk';

import { ISamlConfig, ISamlTest } from '@app/services/web-apis/saml/saml.domain';
import { SdkService } from '@app/services/sdk/sdk.service';

@Injectable({
	providedIn: 'root',
})
export class SamlService {
	private readonly baseUrl: string = '/saml/settings/{catalogId}';
	private httpClient: IHttpClient = this.sdkService.authHttp;

	constructor(private sdkService: SdkService) {}

	fetchConfig(): Observable<ISamlConfig> {
		return defer(() => this.httpClient.get<ISamlConfig>(this.baseUrl).then(res => res.data));
	}

	createConfig(config: ISamlConfig): Observable<void> {
		return defer(() => this.httpClient.post<void>(this.baseUrl, config).then(res => res.data));
	}

	setConfig(config: ISamlConfig): Observable<void> {
		return defer(() => this.httpClient.put<void>(this.baseUrl, config).then(res => res.data));
	}

	testConfig(config: ISamlConfig): Observable<ISamlTest> {
		return defer(() => this.httpClient.post<ISamlTest>(`${this.baseUrl}/test`, config).then(res => res.data));
	}
}
