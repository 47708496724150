import { Component, HostBinding, Inject, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';

import {
	MULTISELECT_DATA,
	MULTISELECT_DATA_CLICK_CALLBACK,
	MULTISELECT_DATA_FILTER,
} from '@utils/constants/injectortokens';
import { PromptService } from '@app/services';
import { getInitialsFromString } from '@utils/helpers/dropdown';
import { DevicesAssignedPromptComponent } from '../../../../devices/components/devices-assigned-prompt/devices-assigned-prompt.component';
import { Decision } from '@app/shared/classes/PromptRef';

@Component({
	selector: 'app-assign-to-data-members',
	templateUrl: './assign-to-data-members.component.html',
	styleUrls: ['./assign-to-data-members.component.scss'],
})
export class AssignToDataMembersComponent implements OnInit, OnDestroy {
	@HostBinding('class.n2p-dropdown-data') style = false;
	users: Array<any> = [];
	filteredUsers: Array<any> = [];
	callback: any;
	getInitials: any = getInitialsFromString;
	filterSub: any;
	constructor(
		@Inject(MULTISELECT_DATA) data: any,
		@Inject(MULTISELECT_DATA_CLICK_CALLBACK) callback: any,
		@Inject(MULTISELECT_DATA_FILTER) private filter: Observable<string>,
		private promptService: PromptService,
		private translate: TranslateService,
	) {
		this.callback = callback;
		this.users = data;
		this.filteredUsers = data;
	}

	delete() {
		this.callback();
	}

	clicked(item) {
		if ((item.selected === undefined || !item.selected) && item.macId) {
			this.showReassignDialog(item);
		} else {
			item.selected = !item.selected;
			this.callback({
				...item,
				type: 'tm',
				selected: item.selected,
			});
		}
	}

	ngOnInit() {
		this.style = true;
		this.filterSub = this.filter.subscribe(str => {
			if (str) {
				this.filteredUsers = this.users.filter(u => {
					return `${u.firstName.toLowerCase()} ${u.lastName.toLowerCase()}`.indexOf(str.toLowerCase()) !== -1;
				});
			} else {
				this.filteredUsers = this.users;
			}
		});
	}

	ngOnDestroy() {
		this.filterSub.unsubscribe();
	}

	private showReassignDialog(item: any) {
		const promptRef = this.promptService.create(
			DevicesAssignedPromptComponent,
			{
				user: item,
			},
			{
				width: 482,
				successLabel: this.translate.instant('DEVICES_PAGE.ADD_DEVICE.CONTINUE'),
				successType: 'delete',
			},
		);

		promptRef.onDismiss().then(decision => {
			if (decision === Decision.CANCEL) {
				item.selected = false;
			}

			if (decision === Decision.ACCEPT) {
				this.callback({
					...item,
					type: 'tm',
					selected: item.selected,
				});
			}
		});
	}
}
