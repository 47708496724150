import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[onlyNumber]',
})
export class OnlyNumberDirective {
	@Input() onlyNumber: boolean;

	regexStr = '^[0-9]*$';

	constructor(private el: ElementRef) {}

	@HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
		const e = event as KeyboardEvent;
		if (this.onlyNumber) {
			if (
				[46, 8, 9, 27, 13, 110, 190, 32, 173, 107, 187, 35, 36, 37, 38, 39].includes(e.keyCode) ||
				(e.ctrlKey === true && [65, 67, 86, 88].includes(e.keyCode)) ||
				(e.shiftKey === true && e.keyCode === 61)
			) {
				// let it happen, don't do anything
				return;
			}
			const ch = String.fromCharCode(e.keyCode);
			const regEx = new RegExp(this.regexStr);
			if (regEx.test(ch) && !e.shiftKey) {
				return;
			} else if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
				e.preventDefault();
			}
		}
	}
}
