import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '@app/services/api/api.service';
import * as ListItemTypes from '@app/Common/constants/entity-types';

@Injectable()
export class AccountService {
	constructor(private api: ApiService) {}

	getConsolidatedEntities(
		entity:
			| 'all'
			| 'user'
			| 'department'
			| 'ringGroup'
			| typeof ListItemTypes.MULTI_AUTO_ATTENDANT
			| typeof ListItemTypes.SPECIAL_EXTENSION = 'all',
		includeUserLines = false,
	): Observable<any> {
		const url = `/account/{accountId}/consolidatedAttributes?type=${entity}&bIncludeUsersLineFlag=${includeUserLines}`;

		return this.api.get(url);
	}
}
