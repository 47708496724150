import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ApiUsersService, SnackbarService } from '@app/services';
import { Contact } from '@app/Common';
import { ApiContactsService } from '@app/services/web-apis/contacts/api-contacts.service';
import {
	IAccountContactMethod,
	AccountContactPartial,
	IAccountContactType,
} from '@app/services/web-apis/contacts/api-contacts.domain';

@Component({
	selector: 'app-serviceaddressconfirmation',
	templateUrl: './serviceaddressconfirmation.component.html',
	styleUrls: ['./serviceaddressconfirmation.component.scss'],
})
export class ServiceaddressconfirmationComponent {
	// TODO get this as an address from parent component
	@Input() address1: string = '';
	@Input() address2: string = '';
	@Input() locality: string = '';
	@Input() city: string = '';
	@Input() state: string = '';
	@Input() country: string = '';
	@Input() zip: string = '';
	@Input() changeServiceAddress: any;
	@ViewChild('snackbar_anchor', { static: true }) snackbarAnchor: ElementRef;

	failure = false;
	isSubmitting = false;
	@Output() cancelFromConfiramtion: EventEmitter<any> = new EventEmitter();
	@Output() submitFromConfiramtion: EventEmitter<any> = new EventEmitter();
	private contact = new Contact();

	constructor(
		private snackbarService: SnackbarService,
		private apiUsers: ApiUsersService,
		private apiContactsService: ApiContactsService,
		private translate: TranslateService,
	) {}

	// validating the service address and passing the official address to the table
	validate(): void {
		this.isSubmitting = true;

		this.contact.address.set = this;

		this.apiUsers.validateContactAddress(this.contact.address).subscribe(result => {
			if (!result.data) {
				this.snackbarService.create({
					status: 'danger',
					text: this.translate.instant('NEW_SERVICE_ADDRESS.INVALID_ADDRESS_PLEASE_VERIFY'),
					connectTo: this.snackbarAnchor,
				});
				this.isSubmitting = false;
			} else {
				this.contact.address.set = result.data;
				this.save();
			}
		});
	}

	cancel(): void {
		this.cancelFromConfiramtion.emit();
	}

	save(): void {
		const newContact: AccountContactPartial = {
			contactMethod: IAccountContactMethod.ADDRESS,
			contactType: IAccountContactType.SERVICE,
			address1: this.address1,
			address2: this.address2,
			locality: this.locality,
			city: this.city,
			state: this.state,
			zip: this.zip,
			country: this.country || 'US',
			externalId: this.contact.address.profileId,
		};

		this.apiContactsService.createContact([newContact]).subscribe(result => {
			this.isSubmitting = false;
			if (!result.hasError) {
				const serviceAddress = result.data[0];
				this.snackbarService.create({
					status: 'success',
					text: this.translate.instant('NEW_SERVICE_ADDRESS.NEW_ADDRESS_ADDED'),
					connectTo: this.snackbarAnchor,
					callback: () => this.submitFromConfiramtion.emit(serviceAddress),
				});
				setTimeout(() => this.submitFromConfiramtion.emit(serviceAddress), 1500);
			} else {
				this.snackbarService.create({
					status: 'danger',
					text: this.translate.instant('NEW_SERVICE_ADDRESS.UNABLE_TO_SAVE_ADDRESS'),
					connectTo: this.snackbarAnchor,
				});
			}
		});
	}
}
