import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';

@Component({
	selector: 'modal-component',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements AfterViewInit {
	@Input() isVisible: boolean;
	@Input() size: string;

	@Output() closed: EventEmitter<boolean> = new EventEmitter();
	@Output() bind: EventEmitter<boolean> = new EventEmitter();

	toggleModal() {
		this.isVisible = !this.isVisible;
		this.closed.emit(this.isVisible);
	}

	ngAfterViewInit() {
		this.bind.emit();
	}

	stopPropagation(event) {
		event.stopPropagation();
	}
}
