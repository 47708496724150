import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaginationComponent } from './pagination.component';
import { SvgModule } from '@n2p/svg';
import { PaginationService } from './pagination.service';

@NgModule({
	imports: [CommonModule, SvgModule],
	providers: [PaginationService],
	declarations: [PaginationComponent],
	exports: [PaginationComponent, SvgModule],
})
export class PaginationModule {}
