import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PipesModule } from '@app/pipes';
import { DialogModule, SvgModule } from '@n2p';
import { AssignedToDropdownModule } from '@n2p/assigned-to-dropdown';
import { DialogDeleteEntityComponent } from './dialog-delete-entity.component';

@NgModule({
	declarations: [DialogDeleteEntityComponent],
	imports: [
		CommonModule,
		AssignedToDropdownModule,
		ReactiveFormsModule,
		SvgModule,
		PipesModule,
		DialogModule,
		TranslateModule,
	],
	entryComponents: [DialogDeleteEntityComponent],
	exports: [DialogDeleteEntityComponent],
})
export class DialogDeleteEntityModule {}
