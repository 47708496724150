import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '@app/Common';
import { environment } from '@env/environment';

export type ValidatorReturnObject = { [key: string]: any } | undefined;

export const macAddressValidator = (control: AbstractControl): ValidatorReturnObject => {
	const macValue = control.value;
	const isValid =
		macValue &&
		macValue.replace(/([\s-:])+/g, '').length === 12 &&
		macValue.match(/^([a-fA-F0-9]{2}[\s:-]?){5}([a-fA-F0-9]{2})$/);

	if (!isValid) return { invalidMac: true };
};

export const noLeadTrailWhitespaceValidator = (control: AbstractControl): ValidatorReturnObject => {
	const isWhitespace = (control.value || '').trim() === '';

	if (isWhitespace) return { leadTrailWhitespace: true };
};

export const noWhitespaceValidator = (control: AbstractControl): ValidatorReturnObject => {
	const hasWhitespace = /\s/g.test(control.value);

	if (hasWhitespace) return { whitespace: true };
};

export const mustHaveAtLeastOneDomesticNumberValidator = (control: AbstractControl): ValidatorReturnObject => {
	const value = control.value;
	if (value.length === 1 && value[0].indexOf('+') !== -1) return { mustHaveDomesticNumber: true };
};

export const notOneOfTheStrings = (values: string[]): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors => {
		if (control.dirty && values.indexOf(control.value) > -1) {
			return { oneOfTheStrings: true };
		}
	};
};

export const noTrimValidator = (control: AbstractControl): ValidationErrors => {
	return (control.value || '').trim() ? null : { trim: true };
};

export const inRange = (checkControl: AbstractControl, range: number): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors => {
		const a = parseInt(checkControl.value);
		const b = parseInt(control.value);
		const diff = Math.abs(a - b);

		// + 1 used because phone numbers starts from 0 index
		if (diff + 1 > range) {
			return { notInRange: { range, diff } };
		}
	};
};

export const lessThan = (from: AbstractControl): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors => {
		const a = parseInt(from.value);
		const b = parseInt(control.value);

		if (a >= b) {
			return { notLessThan: true };
		}
	};
};

export const passwordValidator = () => {
	return Validators.compose([
		Validators.required,
		Validators.minLength(PASSWORD_MIN_LENGTH),
		Validators.maxLength(PASSWORD_MAX_LENGTH),
	]);
};
