import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ICdr } from '@app/services/callhistory/callhistory.domain';
import { ApiUsersService, FeatureFlagsService } from '@app/services';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { ARGENTINA, BRAZIL, MEXICO } from '@app/Common';
import { environment } from '@env/environment';

@Component({
	selector: 'app-details-to',
	templateUrl: './details-to.component.html',
	styleUrls: ['./details-to.component.scss'],
})
export class DetailsToComponent {
	@Input() call: ICdr;

	public isCountryWithSimpleFormatting$: Observable<boolean> = this.apiTenantService.isAccountCountries$([
		...BRAZIL,
		...MEXICO,
		...ARGENTINA,
	]);

	shouldShowClickToCall$: Observable<boolean> = combineLatest([
		this.apiUsersService.user$.pipe(map(user => user.userId)),
		this.featureFlagsService.callHistoryClickToCall(),
		of(environment.is_admin_app),
		of(environment.is_pam_console),
	]).pipe(
		map(([currentUserId, featureEnabled, isAdminApp, isPamConsole]) => {
			const to = this.call && this.call.to;
			return !isAdminApp && !isPamConsole && to && featureEnabled && (!to.userId || currentUserId !== to.userId);
		}),
	);

	constructor(
		private apiUsersService: ApiUsersService,
		private featureFlagsService: FeatureFlagsService,
		private apiTenantService: ApiTenantService,
	) {}

	get callRules(): any {
		const { call } = this;
		const shouldShowSpecialExtension = call.to.userBusinessClass && call.to.userBusinessClass !== 'US';
		const shouldShowNumOnly = !call.to.userDisplayName || (call.dnis && call.dnis[0] === '*');
		const shouldShowExt =
			call.to.number && call.to.number.length <= this.apiTenantService.accountMaxExtensionLength && !shouldShowNumOnly;
		const shouldShowWM = call.to.menuDisplayName && call.to.menuDisplayName;
		const shouldShowRG = !!call.to.ringGroupDisplayName;
		const shouldShowNum = call.dnis && !shouldShowExt && (!call.to.userDisplayName || call.to.userDisplayName);
		const shouldShowDirect = !shouldShowExt && call.to.userDisplayName;

		const callRules = {
			top: {
				shouldShowDepartment: !!call.to.departmentDisplayName,
				shouldShowRG: shouldShowRG && shouldShowWM, // only show on top if welcome menu is also included
				shouldShowSpecialExtension,
			},
			bot: {
				shouldShow: shouldShowExt || shouldShowWM || shouldShowRG || shouldShowNum,
				shouldShowExt,
				shouldShowWM,
				shouldShowRG: shouldShowRG && !shouldShowWM,
				shouldShowNum,
				shouldShowNumOnly,
				shouldShowDirect,
			},
		};

		return callRules;
	}
}
