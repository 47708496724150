import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { from } from 'rxjs';

import { IRegularMessengerResponse } from './api-messenger.domain';
import { SdkService } from '@app/services/sdk/sdk.service';

@Injectable()
export class ApiMessengerService {
	constructor(private sdkService: SdkService) {}

	getMessengerAccount(): Observable<IRegularMessengerResponse> {
		return from(
			this.sdkService.messengerHttp
				.post<IRegularMessengerResponse>('/accounts/search?unite_id={accountId}', {})
				.then(res => res.data),
		);
		// return this.apiService.post('{messenger}/accounts/search?unite_id={accountId}');
	}

	updateMessengerAccount(account: any): Observable<IRegularMessengerResponse> {
		return from(
			this.sdkService.messengerHttp
				.put<IRegularMessengerResponse>('/accounts/{messengerAccountId}', account)
				.then(res => res.data),
		);
	}

	getWidgetSnippet(): Observable<string> {
		return from(
			this.sdkService.messengerHttp.get<string>('/accounts/{messengerAccountId}/snippet').then(res => res.data),
		);
	}
}
