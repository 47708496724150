import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AvatarComponent } from './avatar.component';
import { IconModule } from '@n2p/icon';

@NgModule({
	imports: [CommonModule, IconModule],
	declarations: [AvatarComponent],
	exports: [AvatarComponent],
})
export class AvatarModule {}
