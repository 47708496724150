import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountryNumberUtil } from 'n2p-ui-library/utils/country-number.util';
import { ICountry } from 'n2p-ui-library/utils/countries.domain';

import {
	IBlockedNumberExtended,
	ICallBlockingConfirmPopupOptions,
} from '@app/pages/call-blocking/call-blocking.domain';
import { IHorizontalSelectOption } from '@n2p/horizontal-select/horizontal-select.domain';
import { IBlockedNumber, IBlockedNumberLevel } from '@app/services/web-apis/call-blocking/api-call-blocking.domain';
import { ApiUsersService } from '@app/services';
import { PhoneNumberFormatterPipe } from '@app/pipes';
import { preparePhoneNumberForCall } from 'n2p-ui-library';

@Injectable({
	providedIn: 'root',
})
export class CallBlockingHelperService {
	readonly levelOptions: IHorizontalSelectOption[];
	readonly levelOptionsWithTips: IHorizontalSelectOption[];
	readonly defaultAppliedOnValue: IBlockedNumberLevel = IBlockedNumberLevel.MINE;

	readonly confirmAppliedOnOptions: ICallBlockingConfirmPopupOptions;
	readonly confirmRemoveAllOptions: ICallBlockingConfirmPopupOptions;
	readonly confirmDeleteMultipleOptions: ICallBlockingConfirmPopupOptions;
	readonly confirmDeleteFromCallHistory: ICallBlockingConfirmPopupOptions;

	readonly userText$: Observable<string>;

	readonly maxDescriptionLength: number = 30;

	constructor(
		private translateService: TranslateService,
		private apiUsersService: ApiUsersService,
		private phoneNumberFormatterPipe: PhoneNumberFormatterPipe,
	) {
		const tips = [
			this.translateService.instant('CALL_BLOCKING_PAGE.TIPS.BLOCK_ENTIRE_ACC'),
			this.translateService.instant('CALL_BLOCKING_PAGE.TIPS.BLOCK_ONLY_ME'),
		];

		this.levelOptions = [
			{
				title: this.translateService.instant('CALL_BLOCKING_PAGE.ADD_NEW.COMPANY'),
				value: IBlockedNumberLevel.ALL,
			},
			{
				title: this.translateService.instant('GLOBALS.MINE'),
				value: IBlockedNumberLevel.MINE,
			},
		];

		this.levelOptionsWithTips = this.levelOptions.map((o, i) => ({ ...o, tip: tips[i] }));

		this.confirmAppliedOnOptions = {
			title: this.translateService.instant('CALL_BLOCKING_PAGE.APPLIED_ON_REQUEST.TITLE'),
			description: this.translateService.instant('CALL_BLOCKING_PAGE.APPLIED_ON_REQUEST.DESCRIPTION'),
			cancelButton: { type: 'close', text: this.translateService.instant('GLOBALS.CANCEL') },
			actionButton: { type: 'primary', text: this.translateService.instant('GLOBALS.APPLY') },
		};

		this.confirmRemoveAllOptions = {
			title: this.translateService.instant('CALL_BLOCKING_PAGE.REMOVE_FROM_ALL_REQUEST.TITLE'),
			description: this.translateService.instant('CALL_BLOCKING_PAGE.REMOVE_FROM_ALL_REQUEST.DESCRIPTION'),
			cancelButton: { type: 'close', text: this.translateService.instant('GLOBALS.CANCEL') },
			actionButton: { type: 'primary', text: this.translateService.instant('GLOBALS.REMOVE') },
		};

		this.confirmDeleteMultipleOptions = {
			title: this.translateService.instant('CALL_BLOCKING_PAGE.DELETE_MULTIPLE_REQUEST.TITLE'),
			description: this.translateService.instant('CALL_BLOCKING_PAGE.DELETE_MULTIPLE_REQUEST.DESCRIPTION'),
			cancelButton: { type: 'close', text: this.translateService.instant('GLOBALS.CANCEL') },
			actionButton: { type: 'delete', text: this.translateService.instant('GLOBALS.DELETE') },
		};

		this.confirmDeleteFromCallHistory = {
			title: this.translateService.instant('CALLS_TABLE_PAGE.BLOCK_NUMBER.UNBLOCK_TITLE'),
			description: this.translateService.instant('CALLS_TABLE_PAGE.BLOCK_NUMBER.UNBLOCK_DESCRIPTION'),
			cancelButton: { type: 'close', text: this.translateService.instant('GLOBALS.CANCEL') },
			actionButton: { type: 'primary', text: this.translateService.instant('GLOBALS.UNBLOCK') },
		};

		this.userText$ = this.apiUsersService.user$.pipe(
			map(user => {
				return user ? `${this.translateService.instant('GLOBALS.YOU')} (${user.firstName} ${user.lastName})` : '';
			}),
		);
	}

	extendBlockedNumber(blockedNumber: IBlockedNumber, countries: ICountry[]): IBlockedNumberExtended {
		const isInternational =
			preparePhoneNumberForCall(blockedNumber.Number) !==
			this.phoneNumberFormatterPipe.transform({ number: blockedNumber.Number, numberFormat: 'national' });
		return {
			...blockedNumber,
			checked: false,
			countryCode: isInternational ? CountryNumberUtil.getCountryCodeByNumber(blockedNumber.Number, countries) : null,
			isMine: blockedNumber.Level === IBlockedNumberLevel.MINE,
		};
	}

	trimExtendedBlockedNumber(blockedNumber: IBlockedNumberExtended): IBlockedNumber {
		return {
			Id: blockedNumber.Id,
			Number: blockedNumber.Number,
			Description: blockedNumber.Description,
			Level: blockedNumber.Level,
		};
	}
}
