import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Component, ElementRef, forwardRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import {
	DROPDOWN_INPUT_CONTROL,
	DROPDOWN_INPUT_DATA,
	DROPDOWN_INPUT_REF,
} from '@n2p/input/dropdown-input/dropdown-input-injectors';
import { SimpleDropdownInputDataComponent } from '@n2p/input/dropdown-input/simple-dropdown-input-data/simple-dropdown-input-data.component';

@Component({
	// tslint:disable
	selector: 'n2p-dropdown-input',
	templateUrl: './dropdown-input.component.html',
	styleUrls: ['./dropdown-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => DropdownInputComponent),
		},
	],
	// tslint:enable
})
export class DropdownInputComponent implements OnInit, ControlValueAccessor {
	@Input() dropdownComponent = SimpleDropdownInputDataComponent;
	@Input() label: string;
	@Input() hasError: boolean;
	@Input() placeholder = '';
	@ViewChild('n2pDropdownInput', { static: true }) n2pDropdownInput: ElementRef;
	@ViewChild('textValue', { static: true }) textValue: ElementRef;
	value: FormControl;
	overlayRef: OverlayRef;
	dropdownData: Array<any>;
	onChange = val => {};
	onTouch = val => {};
	constructor(private overlay: Overlay, private injector: Injector) {}

	@Input() set data(d) {
		this.dropdownData = d;
		if (this.overlayRef) {
			this.closeDropdown();
			this.openDropdown();
		}
	}

	get data(): Array<any> {
		return this.dropdownData;
	}

	writeValue(val): void {
		if (!this.value) this.initValue(val);
		this.onChange(val);
	}

	registerOnChange(fn): void {
		this.onChange = fn;
	}

	registerOnTouched(fn): void {
		this.onTouch = fn;
	}

	closeDropdown(): void {
		this.overlayRef.dispose();
		this.overlayRef = undefined;
	}

	openDropdown(): void {
		setTimeout(() => this.textValue.nativeElement.focus());
		const diff = window.innerHeight - (this.n2pDropdownInput.nativeElement.getBoundingClientRect()['y'] + 44);
		const onTop = diff < 220;
		this.overlayRef = this.overlay.create({
			backdropClass: '',
			width: this.n2pDropdownInput.nativeElement.offsetWidth,
			hasBackdrop: true,
			positionStrategy: this.overlay
				.position()
				.connectedTo(
					this.n2pDropdownInput,
					{
						originY: onTop ? 'top' : 'bottom',
						originX: 'start',
					},
					{
						overlayY: onTop ? 'bottom' : 'top',
						overlayX: 'start',
					},
				)
				.withOffsetY(onTop ? -4 : 4),
		});
		this.overlayRef.backdropClick().subscribe(() => {
			this.overlayRef.dispose();
		});
		this.overlayRef.detachments().subscribe(() => {
			this.overlayRef = undefined;
		});
		const portalComponent = new ComponentPortal(this.dropdownComponent, undefined, this.createInjector());
		this.overlayRef.attach(portalComponent);
	}

	ngOnInit(): void {}

	private initValue(value): void {
		this.value = new FormControl(value);
		this.value.valueChanges.subscribe(val => this.writeValue(val));
	}

	private createInjector(): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(DROPDOWN_INPUT_DATA, this.data);
		injectorTokens.set(DROPDOWN_INPUT_REF, this.overlayRef);
		injectorTokens.set(DROPDOWN_INPUT_CONTROL, this.value);

		return new PortalInjector(this.injector, injectorTokens);
	}
}
