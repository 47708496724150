import { Component, Inject, OnInit } from '@angular/core';

import { ApiDevicesService } from '@app/services';
import { ISipDeviceInfo } from '@app/services/web-apis/devices/api-devices.domain';
import { DIALOG_DATA, DIALOG_REF } from '@utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';

@Component({
	selector: 'app-sip-device-information',
	templateUrl: './sip-device-information.component.html',
	styleUrls: ['./sip-device-information.component.scss'],
})
export class SipDeviceInformationComponent implements OnInit {
	extension: string;
	name: string;
	user: any;
	isLoading: boolean;
	sipDevicesInfo: ISipDeviceInfo[] = [];

	constructor(
		private apiDevices: ApiDevicesService,
		@Inject(DIALOG_DATA) private data: any,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
	) {
		this.extension = data.extension;
		this.name = data.name;
		this.user = data.user;
	}

	ngOnInit(): void {
		this.getSipDeviceInfo();
	}

	getSipDeviceInfo(): void {
		this.isLoading = true;
		this.apiDevices.getSipDeviceInfoByExtension(this.extension).subscribe(x => {
			if (x?.items?.length) {
				this.sipDevicesInfo = x.items;
				this.sipDevicesInfo.forEach(x => {
					x.registered_at_time = new Date(x.registered_at_time);
					x.expires_at_time = new Date(x.expires_at_time);
				});
			}
			this.isLoading = false;
		});
	}

	getBeautifiedUserAgent(userAgent: string): string {
		if (userAgent.toLowerCase().startsWith('com.net2phone.unite')) {
			return 'mobile';
		}

		if (userAgent.toLowerCase().startsWith('n2p.dialer')) {
			return 'WEBRTC';
		}

		return userAgent;
	}

	onClose(): void {
		this.dialogRef.dismiss();
	}
}
