import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TooltipDirective } from './tooltip.directive';
import { TooltipComponent } from './tooltip.component';

@NgModule({
	declarations: [TooltipDirective, TooltipComponent],
	imports: [CommonModule, OverlayModule, PortalModule],
	entryComponents: [TooltipComponent],
	exports: [TooltipDirective],
})
export class TooltipModule {}
