import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
	selector: '[appRestrictInput]',
})
export class RestrictInputDirective implements OnChanges {
	@Input() appRestrictInput: string | RegExp;

	private navigationKeys = [
		'Backspace',
		'Delete',
		'Tab',
		'Escape',
		'Enter',
		'Home',
		'End',
		'ArrowLeft',
		'ArrowRight',
		'Clear',
		'Copy',
		'Paste',
	];

	private inputElement: HTMLInputElement;

	private restrictedRegExp: RegExp;

	constructor(public el: ElementRef) {
		this.inputElement = el.nativeElement;
		this.restrictedRegExp = new RegExp(this.appRestrictInput || '');
	}

	@HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent): void {
		if (
			this.navigationKeys.includes(e.key) || // Allow: navigation keys: backspace, delete, arrows etc.
			(['a', 'c', 'v', 'x'].includes(e.key) && // Allow: Ctrl or Cmd + (A,C,V,X)
				e.ctrlKey === true) ||
			e.metaKey === true
		) {
			// let it happen, don't do anything
			return;
		}
		// Ensure that it is a number and stop the keypress
		if (!this.restrictedRegExp.test(e.key)) {
			e.preventDefault();
		}
	}

	@HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
		const pastedInput: string = event.clipboardData.getData('text/plain');
		if (document.execCommand('insertText', false, this.matchValueAndReturnMatched(pastedInput))) {
			event.preventDefault();
		}
	}

	@HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
		const textData = event.dataTransfer.getData('text');
		this.inputElement.focus();
		if (document.execCommand('insertText', false, this.matchValueAndReturnMatched(textData))) {
			event.preventDefault();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.appRestrictInput) {
			const value = changes.appRestrictInput.currentValue;
			this.restrictedRegExp = new RegExp(value);
		}
	}

	private matchValue(value: string): boolean {
		const values = value.split('');
		return values.every(symbol => this.restrictedRegExp.test(symbol));
	}

	private matchValueAndReturnMatched(value: string): string {
		const values = value.split('');
		return values.filter(symbol => this.matchValue(symbol)).join('');
	}
}
