import { Component, HostBinding, Inject, OnInit } from '@angular/core';

import { PromptRef } from '@app/shared/classes/PromptRef';
import { DIALOG_DATA, DIALOG_REF } from '@utils/constants/injectortokens';

@Component({
	selector: 'app-delete-recording-prompt',
	templateUrl: './delete-recording-prompt.component.html',
	styleUrls: ['./delete-recording-prompt.component.scss'],
})
export class DeleteRecordingPromptComponent implements OnInit {
	@HostBinding('class.n2p-dropdown-data') style: boolean = false;
	audioSrc: string;
	callData: any;
	constructor(@Inject(DIALOG_DATA) data: any, @Inject(DIALOG_REF) promptRef: PromptRef) {
		this.audioSrc = data.audioSrc;
		this.callData = data.callData;
	}

	ngOnInit(): void {
		this.style = true;
	}
}
