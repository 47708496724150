import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { DirectivesModule } from '@app/directives/directives.module';
import { DialogModule, IconModule, SvgModule } from '@n2p';
import { FileUploaderComponent } from '@n2p/file-uploader/file-uploader.component';
import { FileUploaderPopupComponent } from './file-uploader-popup.component';
import { InfoMessageModule } from '@n2p/info-message/info-message.module';

@NgModule({
	declarations: [FileUploaderComponent, FileUploaderPopupComponent],
	imports: [CommonModule, TranslateModule, DirectivesModule, SvgModule, DialogModule, IconModule, InfoMessageModule],
	entryComponents: [FileUploaderPopupComponent],
	exports: [FileUploaderComponent, FileUploaderPopupComponent],
})
export class FileUploaderModule {}
