import { Injectable } from '@angular/core';
import { ApiService } from '@app/services';
import { FeatureFlagsService } from '@app/services/feature-flags/feature-flags.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { formatAsE164 } from '@app/pages/phone-numbers/utils/formatters.utils';
import { SdkService } from '@app/services/sdk/sdk.service';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { IAccountPhoneNumberV2 } from './phone-numbers.domain';

@Injectable()
export class PhoneNumbersService {
	searching = false;
	private dataStore: {
		phoneNumbers: Array<any>;
	};
	private _phoneNumbers: BehaviorSubject<Array<any>>;
	private _usePhoneNumberService: boolean;

	constructor(
		private apiService: ApiService,
		private featureFlagsService: FeatureFlagsService,
		private sdkService: SdkService,
	) {
		this.dataStore = { phoneNumbers: [] };
		this._phoneNumbers = new BehaviorSubject<Array<any>>([]);
		this.featureFlagsService.usePhoneNumberService().subscribe(res => (this._usePhoneNumberService = res));
	}

	get phoneNumbers(): Observable<any> {
		return this._phoneNumbers.asObservable();
	}

	get allPhoneNumbers(): Array<any> {
		return this.dataStore.phoneNumbers;
	}

	getAvailablePhoneNumbers(areaCode, count): Observable<any> {
		const url = `/phonenumbers?areaCode=${areaCode}&count=${count}`;

		return this.apiService.get(url);
	}

	getConsecutivesPhoneNumbers(body): Observable<any> {
		const url =
			'/accounts/{accountId}/search/phonenumbers?bConsecutiveBumbersRspinMultipleArray=true&bConsecutiveNumbersRspinMultipleArray=true';

		return this.apiService.post(url, body);
	}

	getStats(): Observable<any> {
		const url = '/accounts/{accountId}/phonenumbers/stats';

		return this.apiService.get(url);
	}

	loadPhoneNumbers(): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = '/accounts/{accountId}/phonenumbers';
			this.apiService.get(url).subscribe(res => {
				this.dataStore.phoneNumbers = res.data || [];
				this._phoneNumbers.next({ ...this.dataStore }.phoneNumbers);
				resolve();
			});
		});
	}

	phoneNumbersPagination(skip, take): Promise<any> {
		this.dataStore.phoneNumbers = [];
		this._phoneNumbers.next({ ...this.dataStore }.phoneNumbers);

		return new Promise((resolve, reject) => {
			const url = `/accounts/{accountId}/phonenumbers?skip=${skip}&take=${take}`;
			this.apiService.get(url).subscribe(res => {
				this.dataStore.phoneNumbers = res.data || [];
				this._phoneNumbers.next({ ...this.dataStore }.phoneNumbers);
				resolve();
			});
		});
	}

	postPhoneNumbers(phones): Observable<any> {
		const url = this._usePhoneNumberService
			? `/catalogs/{catalogId}/phone-numbers`
			: '/accounts/{accountId}/phonenumbers';

		const body = this._usePhoneNumberService
			? {
					accountId: this.sdkService.claims.aid.toString(),
					nodeId: this.sdkService.claims['node.id'],
					phoneNumbers: phones.map(x => ({
						phoneNumber: formatAsE164(x.number),
						source: x.carrier,
						status: x.status,
					})),
			  }
			: phones;

		return this.apiService.post(url, body);
	}

	patchPhoneNumber(phone, ignoreUS911 = false): Observable<any> {
		const url = `/accounts/{accountId}/phonenumbers/${phone.number}${ignoreUS911 ? '?ignoreUS911=true' : ''}`;

		return this.apiService.patch(url, phone);
	}

	getAllPhoneNumbersForCatalog(): Observable<IRegularApiResponse<IAccountPhoneNumberV2[]>> {
		const url = '/catalogs/{catalogId}/phone-numbers';
		return this.apiService.get<IRegularApiResponse<IAccountPhoneNumberV2[]>>(url);
	}

	editPhoneNumber(lineNumber, replaceLineNumber, source: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = this._usePhoneNumberService
				? `/catalogs/{catalogId}/phone-numbers/replace`
				: `/accounts/{accountId}/phonenumbers/${lineNumber}/${replaceLineNumber}/replace`;
			const body = this._usePhoneNumberService
				? {
						phoneNumber: formatAsE164(lineNumber),
						replacePhoneNumber: formatAsE164(replaceLineNumber),
						source,
				  }
				: {};
			this.apiService
				.patch(url, body)
				.catch(err => {
					reject();

					return Observable.throw(err);
				})
				.subscribe(res => {
					if (res.errorMessages && res.errorMessages.length) {
						reject(res.errorMessages[0].message);
					} else {
						resolve(res);
					}
				});
		});
	}

	reassignPendingNumber(targetTemporaryNumber: string, pendingNumber: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = `/catalogs/{catalogId}/pending-phone-numbers/reassign`;
			this.apiService
				.put(url, {
					targetPhoneNumber: formatAsE164(targetTemporaryNumber),
					pendingNumber: formatAsE164(pendingNumber),
				})
				.catch(err => {
					reject();

					return Observable.throw(err);
				})
				.subscribe(res => {
					if (res.hasErrors && res.errorMessages?.length) {
						reject(res.errorMessages[0].message);
					} else {
						resolve(res);
					}
				});
		});
	}

	updatePendingPhoneNumber(temporaryNumber, pendingNumber) {
		return new Promise((resolve, reject) => {
			this.patchPhoneNumber(
				{
					number: temporaryNumber,
					pendingNumber: pendingNumber,
				},
				true,
			)
				.catch(err => {
					reject();

					return Observable.throw(err);
				})
				.subscribe(res => {
					if (res.hasErrors && res.errorMessages?.length) {
						reject(res.errorMessages[0].message);
					} else {
						resolve(res);
					}
				});
		});
	}

	replacePendingPhoneNumber(pendingNumber, newTemporaryNumber, oldTemporaryNumber) {
		if (this._usePhoneNumberService) {
			return this.reassignPendingNumber(newTemporaryNumber, pendingNumber);
		}

		const cleanUpPromise =
			oldTemporaryNumber == pendingNumber ? Promise.resolve() : this.updatePendingPhoneNumber(oldTemporaryNumber, null);

		return cleanUpPromise.then(() => {
			return this.updatePendingPhoneNumber(newTemporaryNumber, pendingNumber);
		});
	}

	getCallerIdUsers(lineNumber): Observable<any> {
		const url = `/accounts/{accountId}/callerId/${lineNumber}/users`;

		return this.apiService.get(url);
	}
}
