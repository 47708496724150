import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared';
import { EntityPhoneDropdownDataModule } from '@app/pages/special-extensions/components/edit-special-extensions/entity-phone-dropdown-data/entity-phone-dropdown-data.module';
import { TeamMembersComponent } from '@app/pages/teammembers/teammembers.component';
import { UpdatePasswordComponent } from '@app/pages/teammembers/update-password/update-password.component';
import { NewServiceAddressComponent } from '@app/pages/teammembers/newserviceaddress/new-service-address.component';
import { ServiceaddressconfirmationComponent } from '@app/pages/teammembers/newserviceaddress/serviceaddressconfirmation/serviceaddressconfirmation.component';
import { EditTeamMemberProfileComponent } from '@app/pages/teammembers/edit-team-member-profile/edit-team-member-profile.component';
import { EditTeamMemberCompanyComponent } from '@app/pages/teammembers/edit-team-member-company/edit-team-member-company.component';
import { EditTeamMemberCustomMohComponent } from '@app/pages/teammembers/edit-team-member-custom-moh/edit-team-member-custom-moh.component';
import { CompanyTabPhoneNumbersComponent } from '@app/pages/teammembers/edit-team-member-company/company-tab-phone-numbers/company-tab-phone-numbers.component';
import { EditTeamMemberComponent } from '@app/pages/teammembers/edit-team-member/edit-team-member.component';
import { TeamMembersDevicesComponent } from '@app/pages/teammembers/devices/devices.component';
import { DevicesDeleteComponent } from '@app/pages/teammembers/devices/desk-phone/delete-devices.component';
import { AssignToDataMembersComponent } from '@app/pages/teammembers/devices/desk-phone/assign-to-data-members/assign-to-data-members.component';
import { DevicesAddEditComponent } from '@app/pages/teammembers/devices/desk-phone/add-edit-devices.component';
import { CallerIdMainComponent } from '@app/pages/teammembers/devices/caller-id-main/caller-id-main.component';
import { CallerIdChipComponent } from '@app/pages/teammembers/devices/caller-id-chip/caller-id-chip.component';
import { CallerIdComponent } from '@app/pages/teammembers/devices/caller-id/caller-id.component';
import { DeleteTeamMemberComponent } from '@app/pages/teammembers/DeleteTeamMember/delete-team-member.component';
import { UsersAssignedDepartmentsComponent, UsersSelectDataMembersComponent } from '@app/pages/teammembers/components';
import { AdminResetPasswordComponent } from '@app/pages/teammembers/admin-reset-password/admin-reset-password.component';
import { AddTeamMemberComponent } from '@app/pages/teammembers/add-team-member/add-team-member.component';
import { PhoneNumberFormatterPipe, PipesModule } from '@app/pipes';
import {
	AssignedNumbersModule,
	AvatarModule,
	DialogModule,
	InputModule,
	LoaderModule,
	SvgModule,
	SwitchModule,
} from '@n2p';
import { TimeZoneDropdownModule } from '@n2p/time-zone-dropdown/time-zone-dropdown.module';
import { DomesticNumberErrorAlertComponent } from './components/domestic-number-error-alert/domestic-number-error-alert.component';
import { DropdownModule } from '@app/n2p-components';
import { AssignedToDropdownModule } from '@app/n2p-components/assigned-to-dropdown';
import { AudioPlayerModule } from '@n2p/audio-player';
import { FileUploaderModule } from '@n2p/file-uploader';
import { InputExtensionModule } from '@n2p/input-extension/input-extension.module';
import { RadioButtonModule } from '@n2p/radio-button/radio-button.module';
import { AddDepartmentModule } from '@app/pages/departments/adddepartment/adddepartment.module';
import { ServiceAddressDropdownComponent } from './service-address-dropdown/service-address-dropdown.component';
import { EditVoicemailModule } from '@n2p/edit-voicemail/edit-voicemail.module';
import { EditTeamMemberLicensesComponent } from './edit-team-member-licenses/edit-team-member-licenses.component';
import { EditableLicensesListComponent } from './edit-team-member-licenses/editable-licenses-list/editable-licenses-list.component';
import { CallOptionsModule } from '@app/pages/teammembers/call-options/call-options.module';
import { RingGroupCallsStatusComponent } from '@app/pages/teammembers/components/ring-group-calls-status/ring-group-calls-status.component';
import { TeamMemberService } from '@app/services/caller-id/team-member.service';
import { ShowHideModule } from '@app/directives/show-hide/show-hide.module';
import { PredictedAddressModule } from '@n2p/predicted-address/predicted-address.module';
import { NewServiceAddressComponentUs } from '@app/pages/teammembers/newserviceaddress/forms/us/new-service-address-us.component';
import { NewServiceAddressComponentBr } from '@app/pages/teammembers/newserviceaddress/forms/br/new-service-address-br.component';
import { NewServiceAddressComponentMx } from '@app/pages/teammembers/newserviceaddress/forms/mx/new-service-address-mx.component';
import { NewServiceAddressComponentAr } from '@app/pages/teammembers/newserviceaddress/forms/ar/new-service-address-ar.component';
import { EditTeamMemberWarningComponent } from '@app/pages/teammembers/edit-team-member-warning/edit-team-member-warning.component';
import { ExternalModule } from '@n2p/external/external.module';

@NgModule({
	imports: [
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		PipesModule,
		TimeZoneDropdownModule,
		AssignedNumbersModule,
		PipesModule,
		SvgModule,
		DropdownModule,
		AssignedToDropdownModule,
		InputModule,
		EntityPhoneDropdownDataModule,
		SwitchModule,
		AudioPlayerModule,
		FileUploaderModule,
		DialogModule,
		InputExtensionModule,
		AvatarModule,
		LoaderModule,
		RadioButtonModule,
		AddDepartmentModule,
		EditVoicemailModule,
		CallOptionsModule,
		ShowHideModule,
		PredictedAddressModule,
		ExternalModule,
	],
	declarations: [
		TeamMembersComponent,
		UpdatePasswordComponent,
		NewServiceAddressComponent,
		NewServiceAddressComponentUs,
		NewServiceAddressComponentBr,
		NewServiceAddressComponentMx,
		NewServiceAddressComponentAr,
		ServiceaddressconfirmationComponent,
		EditTeamMemberProfileComponent,
		EditTeamMemberCompanyComponent,
		EditTeamMemberCustomMohComponent,
		CompanyTabPhoneNumbersComponent,
		EditTeamMemberComponent,
		TeamMembersDevicesComponent,
		DevicesDeleteComponent,
		DevicesAddEditComponent,
		AssignToDataMembersComponent,
		CallerIdMainComponent,
		CallerIdComponent,
		CallerIdChipComponent,
		DeleteTeamMemberComponent,
		UsersSelectDataMembersComponent,
		UsersAssignedDepartmentsComponent,
		AdminResetPasswordComponent,
		AddTeamMemberComponent,
		DomesticNumberErrorAlertComponent,
		ServiceAddressDropdownComponent,
		EditTeamMemberLicensesComponent,
		EditableLicensesListComponent,
		RingGroupCallsStatusComponent,
		EditTeamMemberWarningComponent,
	],
	entryComponents: [
		TeamMembersComponent,
		UpdatePasswordComponent,
		NewServiceAddressComponent,
		EditTeamMemberComponent,
		DevicesDeleteComponent,
		DevicesAddEditComponent,
		AssignToDataMembersComponent,
		CallerIdComponent,
		CallerIdChipComponent,
		DeleteTeamMemberComponent,
		UsersSelectDataMembersComponent,
		UsersAssignedDepartmentsComponent,
		AdminResetPasswordComponent,
		AddTeamMemberComponent,
		DomesticNumberErrorAlertComponent,
		EditTeamMemberWarningComponent,
	],
	exports: [TeamMembersComponent],
	providers: [PhoneNumberFormatterPipe, TeamMemberService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TeamMembersModule {}
