export enum IAccountContactMethod {
	ADDRESS,
	EMAIL,
	PHONE,
}

export enum IAccountContactType {
	BILLING,
	CONTACT,
	COMPANY,
	SERVICE,
}

export interface IAccountContact {
	id: number;
	accountId: number;
	contactMethod: IAccountContactMethod;
	contactType: IAccountContactType;
	parentContactId: number;
	externalId: number;
	contactName: string;
	companyName: string;
	firstName: string;
	lastName: string;
	address1: string;
	address2: string;
	locality?: string;
	city: string;
	state: string;
	zip: string;
	country: string;
	phone: string;
	email: string;
	comments: string;
	status: string;
	type: IAccountContactType;
}

export type AccountContactPartial = Partial<IAccountContact>;
