import { Directive, HostListener } from '@angular/core';

import { LeftSideNavService } from '@n2p/left-side-nav/left-side-nav.service';

@Directive({
	selector: '[n2p-left-side-nav-toggler]',
})
export class LeftSideNavTogglerDirective {
	constructor(private leftSideNavService: LeftSideNavService) {}

	@HostListener('click') onClick(): void {
		this.leftSideNavService.leftSideNavOpened = !this.leftSideNavService.leftSideNavOpened;
	}
}
