import { Pipe, PipeTransform } from '@angular/core';
import { formatPhoneNumber } from 'n2p-ui-library/utils/phone-number/phone-number.util';
import { IFormatParams } from 'n2p-ui-library/utils/phone-number/phone-number.domain';
import { CountryCode, CountryCallingCode, getCountryCallingCode, NumberFormat } from 'libphonenumber-js';
import { Subscription } from 'rxjs/Subscription';

import { IAccount } from '@app/services/web-apis/accounts/api-accounts.domain';
import { ApiAccountsService } from '@app/services';
import { ADDITIONAL_FORMATTERS, ICountry, ITransformParams } from './phone-number-formatter.domain';

@Pipe({
	name: 'phoneNumberFormatter',
	pure: false,
})
export class PhoneNumberFormatterPipe implements PipeTransform {
	private account: IAccount;
	private country: ICountry = { code: 'US', prefix: '1' as CountryCallingCode };
	private lastNumber: string;
	private lastNumberFormat: string | undefined;
	private lastResult: string;
	private lastAccount: IAccount;
	private subscription: Subscription = new Subscription();

	static NATIONAL_FORMAT_COUNTRY_CODES: string[] = ['BR'];

	constructor(private accountService: ApiAccountsService) {
		this.subscription = this.accountService.account$.subscribe((accountInfo: IAccount) => {
			if (accountInfo) {
				this.account = accountInfo;
				this.country = {
					code: accountInfo.country as CountryCode,
					prefix: getCountryCallingCode(accountInfo.country as CountryCode),
				};
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	transform(params?: ITransformParams | string | number, args?: any): string {
		if (!params) {
			return '';
		}

		const formatParams: ITransformParams = typeof params === 'object' ? params : { number: String(params) };

		if (
			formatParams.number !== this.lastNumber ||
			(formatParams.number === this.lastNumber && formatParams.numberFormat !== this.lastNumberFormat) ||
			this.account !== this.lastAccount
		) {
			this.lastNumber = formatParams.number;
			this.lastNumberFormat = formatParams.numberFormat;
			this.lastResult = this.format(formatParams);
			this.lastAccount = this.account;
		}

		return this.lastResult;
	}

	private format(params: ITransformParams): string {
		const { numberFormat = 'auto', ...restParams } = params;
		const mainFormatter = (): string => {
			switch (numberFormat) {
				case 'simple': {
					return String(params.number);
				}
				case 'national': {
					return formatPhoneNumber({
						country: this.country,
						nationalFormat: true,
						...restParams,
						// Converting to string just in case
						number: String(params.number || ''),
					});
				}
				default: {
					return formatPhoneNumber({
						country: this.country,
						nationalFormat: PhoneNumberFormatterPipe.NATIONAL_FORMAT_COUNTRY_CODES.includes(this.country.code),
						...(params as IFormatParams),
						// Converting to string just in case
						number: String(params.number || ''),
					});
				}
			}
		};
		const additionalFormatter = ADDITIONAL_FORMATTERS[this.country.code] || ((res: string) => res);

		return additionalFormatter(mainFormatter(), params);
	}
}
