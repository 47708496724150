import { ApplicationRef, ComponentFactoryResolver, ElementRef, Injectable, Injector } from '@angular/core';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';

import { GlobalLoaderComponent } from '@n2p/loader/global-loader.component';

export interface Loader {
	show(): void;
	hide(): void;
	getIsLoading(): boolean;
}

@Injectable()
export class GlobalLoaderService {
	loaderIsShowing: boolean = false;
	constructor(
		private _componentFactoryResolver: ComponentFactoryResolver,
		private _appRef: ApplicationRef,
		private injector: Injector,
	) {}

	create(element: ElementRef): Loader {
		const domPortalOutlet = new DomPortalOutlet(
			element.nativeElement,
			this._componentFactoryResolver,
			this._appRef,
			undefined,
		);
		/**
		 * Add an empty injector because of:
		 * https://idtjira.atlassian.net/browse/UI-3518
		 * Looks like portal CDK can't access global injector
		 * This is the simplest solution
		 * Other possible solution to investigate - use ComponentPortal through DI or add in as a provider
		 */
		const portal = new ComponentPortal(GlobalLoaderComponent, null, this.injector);

		return {
			show: this.show.bind(this, portal, domPortalOutlet, element),
			hide: this.hide.bind(this, portal, element),
			getIsLoading: this.getIsLoading.bind(this),
		};
	}

	private show(
		portal: ComponentPortal<GlobalLoaderComponent>,
		domPortalOutlet: DomPortalOutlet,
		element: ElementRef,
	): void {
		if (this.loaderIsShowing) return;
		element.nativeElement.style.position = 'relative';
		if (!portal.isAttached) portal.attach(domPortalOutlet);
		this.loaderIsShowing = true;
	}

	private hide(portal: ComponentPortal<GlobalLoaderComponent>, element: ElementRef): void {
		if (!this.loaderIsShowing) return;
		element.nativeElement.style.position = '';
		if (portal.isAttached) portal.detach();
		this.loaderIsShowing = false;
	}

	private getIsLoading(): boolean {
		return this.loaderIsShowing;
	}
}
