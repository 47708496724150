import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

import { PhoneNumberFormatterPipe } from '@app/pipes';
import {
	DROPDOWN_ADDITIONAL_DATA,
	DROPDOWN_DATA,
	DROPDOWN_DATA_CONFIG,
	DROPDOWN_DATA_FILTER,
	DROPDOWN_PAGE,
	DROPDOWN_PAGE_NEXT,
} from '@n2p/dropdown/dropdown.injectors';
import { matchSearchString } from '@utils/helpers/functions';
import { FeatureFlagsService, AuthStorageData, LicensesService } from '@app/services';

type EntityType =
	| 'team-member'
	| 'department'
	| 'welcome-menu'
	| 'special-extension'
	| 'directory'
	| 'hangup-call'
	| 'repeat-menu';

@Component({
	selector: 'app-if-call-no-answer-data-dropdown',
	templateUrl: './if-call-no-answer-data-dropdown.component.html',
	styleUrls: ['./if-call-no-answer-data-dropdown.component.scss'],
	providers: [PhoneNumberFormatterPipe],
})
export class IfCallNoAnswerDataDropdownComponent implements OnInit {
	additionalData: any;
	hideHangUpCall: boolean;
	hideRepeatMenu: boolean;
	dataControl: FormControl;
	pageSubject: Observable<number>;
	currentLevel: number;
	entityToShow: string;
	searchStr: string;
	selectedTempItem: any;
	isCallServerV3User: boolean;

	entities = [];

	constructor(
		@Inject(DROPDOWN_DATA) data: any,
		@Inject(DROPDOWN_PAGE) page: Observable<number>,
		@Inject(DROPDOWN_PAGE_NEXT) private nextPage: any,
		@Inject(DROPDOWN_DATA_CONFIG) private config: any,
		@Inject(DROPDOWN_DATA_FILTER) private searchControl: FormControl,
		@Inject(DROPDOWN_ADDITIONAL_DATA) additionalData: any,
		private phoneNumberFormatter: PhoneNumberFormatterPipe,
		private featureFlags: FeatureFlagsService,
		private authStorageDataService: AuthStorageData,
		private translateService: TranslateService,
		private licensesService: LicensesService,
	) {
		this.additionalData = additionalData;
		this.hideHangUpCall = this.additionalData.hideHangUpCall;
		this.hideRepeatMenu = this.additionalData.hideRepeatMenu;
		this.dataControl = data;
		this.pageSubject = page;
		this.pageSubject.subscribe(p => {
			this.currentLevel = p;

			// user selected back up to the 0 level after searching & viewing result
			// lets show original data
			if (p === 0 && this.searchStr) {
				this.searchStr = '';
			}
		});

		this.entities.push({
			type: 'team-member',
			title: this.translateService.instant('FORWARD_CALLS_TO_DATA.TEAM_MEMBERS'),
		});
		this.entities.push({
			type: 'department',
			title: this.translateService.instant('FORWARD_CALLS_TO_DATA.DEPARTMENTS'),
		});
		if (!this.additionalData.hideRingGroups) {
			this.entities.push({
				type: 'ring-group',
				title: this.translateService.instant('FORWARD_CALLS_TO_DATA.RING_GROUPS'),
			});
		}
		this.entities.push({
			type: 'welcome-menu',
			title: this.translateService.instant('FORWARD_CALLS_TO_DATA.WELCOME_MENUS'),
		});
		if (!this.additionalData.hideRinger) {
			this.entities.push({
				type: 'special-extension',
				title: this.translateService.instant('FORWARD_CALLS_TO_DATA.RINGER'),
				subtext: this.translateService.instant('FORWARD_CALLS_TO_DATA.RINGER_SUBTEXT'),
			});
		}
	}

	get data(): Array<any> {
		const isSearching = !!this.searchStr;
		const onLastLevel = this.currentLevel === 2;

		return isSearching && onLastLevel
			? [] // if the user searched and is on last level, we should only show 'phone' and 'voicemail' options
			: isSearching
			? this.dataControl.value.filter(
					i =>
						!matchSearchString(this.searchStr, [
							this.translateService.instant('FORWARD_CALLS_TO_DATA.HANGUP_CALL'),
							this.translateService.instant('FORWARD_CALLS_TO_DATA.VOICE_MAIL'),
							this.translateService.instant('FORWARD_CALLS_TO_DATA.PHONE'),
							this.translateService.instant('FORWARD_CALLS_TO_DATA.REPEAT_MENU'),
							this.translateService.instant('FORWARD_CALLS_TO_DATA.COMPANY_DIRECTORY'),
						]) && matchSearchString(this.searchStr, [i.value, i.subValue, i.number, i.extension]),
			  )
			: this.dataControl.value.filter(i => i.type === this.entityToShow);
	}

	hasEntity(entity): boolean {
		return this.dataControl.value.find(i => i.type === entity);
	}

	showEntities(entityType: EntityType): void {
		this.entityToShow = entityType;
		this.nextPage();
	}

	noChildrenSelection(item): boolean {
		const index = this.dataControl.value.findIndex(i => i.type === item.type);
		return item.noChildren && this.dataControl.value[index].selected;
	}

	firstLevelClicked(item): void {
		if (item.noChildren) {
			const index = this.entities.findIndex(entity => entity.type === item.type);
			this.entities[index].selected = true;
			this.additionalItemClicked(item.type);
			return;
		}

		this.hasEntity(item.type) && this.showEntities(item.type);
	}

	secondLevelClicked(item): void {
		if (item.type === 'team-member' || item.type === 'department') {
			this.selectedTempItem = item;
			this.nextPage();
			// if a search string exists, it means no entity type was selected
			// so pagenumber would still be 0, so we increment it again to get to the third page
			if (this.searchStr) this.nextPage();
		} else this.itemClicked(item);
	}

	itemClicked(item, redirectToVoiceMail = false, thirdLevel = false): void {
		const shouldNotUnselected =
			this.config.isRequired && this.dataControl.value.filter(i => i.selected).length === 1 && item.selected;
		if (
			(item.type === 'team-member' || item.type === 'department') &&
			item.redirectToVoiceMail === redirectToVoiceMail &&
			shouldNotUnselected
		) {
			return;
		} else if (!(item.type === 'team-member' || item.type === 'department') && shouldNotUnselected) return;

		if (this.config.singleSelection) {
			for (const option of this.dataControl.value) {
				if (option.value !== item.value) {
					option.selected = false;
				}
			}
		}

		item.selected = item.selected && thirdLevel ? item.redirectToVoiceMail !== redirectToVoiceMail : !item.selected;

		item.redirectToVoiceMail = redirectToVoiceMail;
		this.dataControl.updateValueAndValidity();
	}

	onAddForwardingNumberClicked(num): void {
		if (num) {
			const item = {
				value: this.phoneNumberFormatter.transform({ number: num }),
				number: num,
				selected: false,
				type: 'phone-number',
			};
			this.dataControl.value.push(item);
			this.itemClicked(item);
		}
	}

	additionalItemClicked(type: EntityType): void {
		const index = this.dataControl.value.findIndex(i => i.type === type);
		this.clearAllSelected(this.dataControl.value[index]);
		this.dataControl.value[index].selected = !this.dataControl.value[index].selected;
		this.dataControl.updateValueAndValidity();
	}

	hangUpCallClicked(): void {
		this.additionalItemClicked('hangup-call');
	}

	repeatMenuClicked(): void {
		this.additionalItemClicked('repeat-menu');
	}

	companyDirectoryClicked(): void {
		this.additionalItemClicked('directory');
	}

	ngOnInit(): void {
		this.isCallServerV3User = this.authStorageDataService.isV3CallServer;
		this.searchControl.valueChanges.subscribe(value => (this.searchStr = value));
		if (this.dataControl.value && this.dataControl.value.length) {
			this.selectedTempItem = this.dataControl.value.filter(i => i.selected)[0];
		}

		if (!this.additionalData.hideCompanyDirectory) {
			forkJoin([this.featureFlags.companyDirectory(), this.featureFlags.enableCompanyDirectory30()]).subscribe(
				([companyDirectory, companyDirectory30]) => {
					if (companyDirectory && (!this.isCallServerV3User || companyDirectory30)) {
						this.entities.push({
							type: 'directory',
							title: this.translateService.instant('FORWARD_CALLS_TO_DATA.COMPANY_DIRECTORY'),
							noChildren: true,
							selected: false,
						});
					}
				},
			);
		}

		if (!this.additionalData.hideCallQueues) {
			this.licensesService.getLicensesWithPrivate().subscribe(licenses => {
				const isCallQueuesLicenseEnabled = licenses.some(license => license.licenseCode === 'customer.callqueue');
				if (isCallQueuesLicenseEnabled) {
					this.entities.push({
						type: 'call-queue',
						title: this.translateService.instant('FORWARD_CALLS_TO_DATA.CALL_QS'),
					});
				}
			});
		}
	}

	private clearAllSelected(item): void {
		for (const option of this.dataControl.value) {
			if (option.value !== item.value) {
				option.selected = false;
			}
		}
	}
}
