import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

interface CacheContent {
	expiry: number;
	value: any;
}

export class CacheService {
	readonly DEFAULT_MAX_AGE: number = 300000;
	private cache: Map<string, CacheContent> = new Map<string, CacheContent>();
	private keyStore: Map<string, BehaviorSubject<any>> = new Map<string, BehaviorSubject<any>>();

	subscribeToKey(key): Observable<any> {
		return this.keyStore.get(key).asObservable();
	}

	get(key: string): any {
		if (this.cache.has(key)) {
			return this.cache.get(key).value;
		}

		return;
	}

	set(key: string, value: any, maxAge: number = this.DEFAULT_MAX_AGE): void {
		this.cache.set(key, { value, expiry: Date.now() + maxAge });
		if (this.keyStore.has(key)) this.keyStore.get(key).next(value);
		else this.keyStore.set(key, new BehaviorSubject<any>(value));
	}

	delete(key: string): void {
		this.cache.delete(key);
		this.keyStore.delete(key);
	}
}
