import { Component, Inject } from '@angular/core';

import { MULTISELECT_CHIP, MULTISELECT_CHIP_CLICK_CALLBACK } from 'utils/constants/injectortokens';

@Component({
	selector: 'app-forward-calls-to-chip',
	templateUrl: './forward-calls-to-chip.component.html',
	styleUrls: ['./forward-calls-to-chip.component.scss'],
})
export class ForwardCallsToChipComponent {
	chip: any;
	callback: any;
	text = '';
	imgUrl: string;
	constructor(@Inject(MULTISELECT_CHIP) chip: any, @Inject(MULTISELECT_CHIP_CLICK_CALLBACK) callback: any) {
		this.chip = chip;
		this.callback = callback;
		if (this.chip.type === 'tm') {
			const userInfo = this.chip.item.userInfo;
			if (!userInfo) {
				this.text = this.chip.item.name;
			} else {
				this.text = userInfo.name;
				const avatar = userInfo.avatars && userInfo.avatars.length ? userInfo.avatars[1] : undefined;

				if (avatar) {
					this.imgUrl = avatar.url;
				}
			}
		} else if (this.chip.type === 'd') {
			this.text = `${this.chip.item.name}`;
		} else if (this.chip.type === 'r') {
			this.text = `${this.chip.item.name}`;
		} else if (this.chip.type === 'w') {
			this.text = `${this.chip.item.name}`;
		} else if (this.chip.type === 'e') {
			this.text = `${this.chip.item.name}`;
		} else if (this.chip.type === 'ext') {
			// -------- TODO there seems to be an 'chip.type' for two different uses and 'ext' and possible a 'phone number'?
			this.text = this.chip.item && this.chip.item.id ? this.chip.item.id : this.chip.number || '';
		} else if (this.chip.type === 'empty') {
			this.text = this.chip.name;
		}
	}

	close = () => {
		this.callback(this.chip);
	};
}
