import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
	/* tslint:disable */
	selector: 'n2p-input-label',
	templateUrl: './input-label.component.html',
	styleUrls: ['./input-label.component.scss'],
	encapsulation: ViewEncapsulation.None,
	/* tslint:enable */
})
export class InputLabelComponent {
	@Input() required: boolean;
	@Input() disabled: boolean = false;
}
