import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
	selector: 'n2p-call-recording-toggle',
	templateUrl: './call-recording-toggle.component.html',
	styleUrls: ['./call-recording-toggle.component.scss'],
})
export class CallRecordingToggleComponent {
	@Input() pageName: string;
	@Input() isCallRecordingActive: boolean = false;
	@Output() onToggle: EventEmitter<any> = new EventEmitter();

	constructor() {}

	setRecording(): void {
		this.isCallRecordingActive = !this.isCallRecordingActive;
		this.onToggle.emit(this.isCallRecordingActive);
	}
}
