import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router } from '@angular/router';

import { AccountPolicyType } from '@app/services/web-apis/jwt/jwt.domain';
import { JwtService } from '@app/services';

@Injectable({
	providedIn: 'root',
})
export class PolicyGuard implements CanLoad, CanActivate {
	constructor(private jwtService: JwtService, private router: Router) {}

	canLoad(route: Route): boolean {
		return this.checkAccess(route.data.policy, route.data.redirectUrl, route.path);
	}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		return this.checkAccess(route.data.policy, route.data.redirectUrl);
	}

	private checkAccess(policy: AccountPolicyType, redirectUrl: string, route?: string): boolean;
	private checkAccess(policy: AccountPolicyType[], redirectUrl: string, route?: string): boolean;
	private checkAccess(policy: AccountPolicyType | AccountPolicyType[], redirectUrl: string, route?: string): boolean {
		const arr = Array.isArray(policy) ? policy : [policy];
		const hasPolicy = arr.every(x => this.jwtService.hasPolicy(x));

		if (!hasPolicy) {
			this.router.navigate([redirectUrl], route && { queryParams: { route } });
			return false;
		}
		return true;
	}
}
