import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { SnackbarService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { FileSizePipe } from '@app/pipes/file-size/file-size.pipe';

@Directive({
	selector: '[app-drag-and-drop-files]',
})
export class DragAndDropFilesDirective {
	@Output() fileChanged = new EventEmitter<any>();
	@Input() maxFileSize = 4e6;
	@Input() supportedFileTypes = ['.mp3', '.m4a', '.wav'];
	@Input() bodySectionRef: ElementRef;
	@Input() showErrors = true;

	constructor(
		private dropZone: ElementRef<HTMLDivElement>,
		private snackbarService: SnackbarService,
		private translate: TranslateService,
	) {
		this.initEvents();
	}

	initEvents(): void {
		const dropZoneNativeElement = this.dropZone.nativeElement;
		const events = ['dragenter', 'dragover', 'dragleave', 'drop'];
		const highlightEnterEvents = ['dragenter', 'dragover'];
		const highlightLeaveEvents = ['dragleave', 'drop'];

		events.forEach(eventName => {
			dropZoneNativeElement.addEventListener(eventName, this.preventDefaults, false);
		});

		highlightEnterEvents.forEach(eventName => {
			dropZoneNativeElement.addEventListener(
				eventName,
				() => {
					dropZoneNativeElement.classList.add('highlight');
				},
				false,
			);
		});

		highlightLeaveEvents.forEach(eventName => {
			dropZoneNativeElement.addEventListener(
				eventName,
				() => {
					dropZoneNativeElement.classList.remove('highlight');
				},
				false,
			);
		});

		dropZoneNativeElement.addEventListener(
			'drop',
			e => {
				const dt = e.dataTransfer;
				const files = dt.files;
				let text = '';
				if (files[0] && files[0].size > this.maxFileSize) {
					text = this.translate.instant('GLOBALS.MOH.FILE_TOO_LARGE', {
						size: new FileSizePipe().transform(this.maxFileSize),
					});
				}

				const fileExt = files[0].name.substr(files[0].name.lastIndexOf('.'));
				if (this.supportedFileTypes.findIndex(i => i === fileExt.toLowerCase()) === -1) {
					text = this.translate.instant('GLOBALS.MOH.FILE_WRONG_FORMAT', {
						formats: this.supportedFileTypes?.join(', ') || '',
					});
				}

				if (text && this.showErrors) {
					this.snackbarService.create({
						text,
						status: 'danger',
						connectTo: this.bodySectionRef,
					});

					return;
				}

				this.fileChanged.emit(files);
			},
			false,
		);
	}

	private preventDefaults = (e): void => {
		e.preventDefault();
		e.stopPropagation();
	};
}
