import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableComponent } from './table.component';
import { AvatarModule } from '@n2p/avatar';
import { TableHeaderComponent } from './table-header';
import { TableDataComponent } from './table-data';
import { PaginationModule } from '@n2p/pagination';

@NgModule({
	imports: [CommonModule, AvatarModule, PaginationModule],
	declarations: [TableComponent, TableDataComponent, TableHeaderComponent],
	exports: [TableComponent, TableDataComponent, TableHeaderComponent, AvatarModule],
})
export class Table {}
