import { Component, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-page-header-popup',
	templateUrl: './page-header-popup.component.html',
	styleUrls: ['./page-header-popup.component.scss'],
})
export class PageHeaderPopupComponent {
	hide: boolean = false;
}
