import { Component, Inject, OnInit } from '@angular/core';

import { INPUT_ERROR_MESSAGE, INPUT_ERROR_PROP_NAME, INPUT_MAX_LENGTH } from '@n2p/input/input.injectors';

@Component({
	selector: 'n2p-input-field-error-max-length',
	templateUrl: './input-field-error-max-length.component.html',
	styleUrls: ['./input-field-error-max-length.component.scss'],
})
export class InputFieldErrorMaxLengthComponent implements OnInit {
	maxLength: number;
	propName: string;
	errorMessage: string;
	constructor(
		@Inject(INPUT_MAX_LENGTH) inputMaxLength = 50,
		@Inject(INPUT_ERROR_PROP_NAME) errorPropName: string,
		@Inject(INPUT_ERROR_MESSAGE) errorMessage: string,
	) {
		this.maxLength = inputMaxLength;
		this.propName = errorPropName;
		this.errorMessage = errorMessage;
	}

	ngOnInit(): void {}
}
