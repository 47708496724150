import { Directive, EventEmitter, Input, HostBinding, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { take } from 'rxjs/operators/take';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { takeWhile } from 'rxjs/operators/takeWhile';

import { DragService, DragSource } from '@app/services';

export interface DropSortableEvent {
	data: any;
	newIndex: number;
	previousIndex: number;
}

@Directive({
	selector: '[appDragSort]',
})
export class DragSortDirective implements OnInit, OnDestroy {
	dragImg: HTMLElement;

	private ngUnsubscribe: Subject<void> = new Subject<void>();

	@Input() item;
	@Input() itemIndex: number;
	@Input() sortableElSelector: string;
	private startingIndex: number;

	@Output() dropSortable: EventEmitter<DropSortableEvent> = new EventEmitter();

	constructor(private dragService: DragService) {}

	@HostBinding('draggable') get draggable() {
		return true;
	}
	@HostBinding('class.collapsed') collapsed = false;
	@HostBinding('class.dragging') dragging = false;

	@HostListener('dragstart', ['$event']) onDragStart(event) {
		this.dragging = true;
		this.startingIndex = this.itemIndex;
		this.dragService.dragSource$.next({
			element: event.target,
			index: this.itemIndex,
		});
	}

	@HostListener('dragenter', ['$event']) onDragEnter(event) {
		this.dragService.dragSource$
			.pipe(
				takeWhile(dragSource => Boolean(dragSource)),
				take(1),
			)
			.subscribe((dragSource: DragSource) => {
				const dragEl = dragSource.element;
				const dragElIndex = dragSource.index;
				const textNodeType = 3;
				const target = event.target.nodeType === textNodeType ? event.target.parentNode : event.target;
				const sortableEl = target.closest(this.sortableElSelector);

				if (dragEl !== sortableEl) {
					if (isBefore(dragEl, sortableEl)) {
						sortableEl.parentNode.insertBefore(dragEl, sortableEl);
						this.dragService.dragSource$.next(
							Object.assign({}, dragSource, {
								index: dragElIndex - 1,
							}),
						);
					} else {
						sortableEl.parentNode.insertBefore(dragEl, sortableEl.nextSibling);
						this.dragService.dragSource$.next(
							Object.assign({}, dragSource, {
								index: dragElIndex + 1,
							}),
						);
					}
				}
			});

		event.preventDefault();

		/**
		 * Returns boolean indicating if socond node in the list is before the first one.
		 * @param {any} firstNode
		 * @param {any} secondNode
		 * @returns {boolean}
		 */
		function isBefore(firstNode, secondNode): boolean {
			if (firstNode.parentNode === secondNode.parentNode) {
				for (let cur = firstNode; cur; cur = cur.previousSibling) {
					if (cur == secondNode) {
						return true;
					}
				}
			}
			return false;
		}
	}

	@HostListener('dragover', ['$event']) onDragOver(event) {
		// Prevent default to allow drop.
		event.preventDefault();
	}

	@HostListener('dragend', ['$event']) onDragEnd(event) {
		this.dragging = false;
		this.dragService.dragSource$.next(null);
		this.dragService.collapse$.next(false);
	}

	/*
  when an item is dropped, a callback is triggerd from the dropped item
  dropSortable can be used to reorder the non-visible sortable array
  and apply an extra logic your sortable array needs. example:

  <item *ngFor="mySortableArray" dropSortable="onDropSortable($event)"></item

  onDropSortable(event){
    this.mySortableArray.sort((a, b) => {
      return b - a;
    });
  }
  */
	@HostListener('drop', ['$event']) onDrop(event) {
		this.dragService.dragSource$
			.pipe(
				takeWhile(dragSource => Boolean(dragSource)),
				take(1),
			)
			.subscribe((dragSource: DragSource) => {
				let data = this.item;
				this.dropSortable.emit({ data, newIndex: dragSource.index, previousIndex: this.startingIndex });
				this.startingIndex = dragSource.index;
			});
	}

	ngOnInit() {
		this.dragService.collapse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((collapsedState: boolean) => {
			this.collapsed = collapsedState;
		});
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
