import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@app/shared';
import { DepartmentsComponent } from '@app/pages/departments/departments.component';
import { DepartmentsAssignedUserComponent } from '@app/pages/departments/components';
import { EditDepartmentComponent } from '@app/pages/departments/edit-department/edit-department.component';
import { DepartmentTabComponent } from '@app/pages/departments/edit-department/department-tab/department-tab.component';
import { OptionsTabComponent } from '@app/pages/departments/edit-department/options-tab/options-tab.component';
import { SortableUserComponent } from '@app/pages/departments/edit-department/sortable-user/sortable-user.component';
import { DirectivesModule } from '@app/directives/directives.module';
import { AssignedNumbersModule, AvatarModule, DropdownModule, InputModule, LoaderModule } from '@n2p';
import { DialogDeleteEntityModule } from '@n2p/dialog/dialog-delete-entity/dialog-delete-entity.module';
import { InputExtensionModule } from '@n2p/input-extension/input-extension.module';
import { AssignedToDropdownModule } from '@n2p/assigned-to-dropdown';
import { PipesModule } from '@app/pipes';
import { RadioButtonModule } from '@n2p/radio-button/radio-button.module';
import { CallRecordingToggleModule } from '@app/n2p-components/call-recording-toggle/call-recording-toggle.module';
import { AddDepartmentModule } from '@app/pages/departments/adddepartment/adddepartment.module';
import { AudioPlayerModule } from '@n2p/audio-player';
import { EditVoicemailModule } from '@n2p/edit-voicemail/edit-voicemail.module';

@NgModule({
	imports: [
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
		InputModule,
		AssignedNumbersModule,
		InputExtensionModule,
		AssignedToDropdownModule,
		PipesModule,
		DropdownModule,
		DialogDeleteEntityModule,
		RadioButtonModule,
		AvatarModule,
		LoaderModule,
		CallRecordingToggleModule,
		AddDepartmentModule,
		AudioPlayerModule,
		EditVoicemailModule,
	],
	declarations: [
		DepartmentsComponent,
		DepartmentsAssignedUserComponent,
		EditDepartmentComponent,
		DepartmentTabComponent,
		OptionsTabComponent,
		SortableUserComponent,
	],
	entryComponents: [DepartmentsComponent, DepartmentsAssignedUserComponent, EditDepartmentComponent],
	exports: [DepartmentsComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DepartmentsModule {}
