export enum VoicemailUploadedType {
	DEFAULT = 'default',
	CUSTOM = 'custom',
	RECORDED = 'recorded',
}

export enum RecordingStatus {
	ACTIVE = 'A',
	ABANDONED = 'B',
	FAILED = 'F',
	PROCESSING = 'P',
	RECORDED = 'R',
	UNAVAILABLE = 'U',
}

export enum VoicemailType {
	USER = 'user',
	DEPARTMENT = 'department',
}

export const TRY_AGAIN = 'TRY_AGAIN';
