import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InitialsComponentBackground } from 'n2p-ui-library/constants';

import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { ApiDepartmentsService, ApiPhoneNumbersService, ApiUsersService, SnackbarService } from '@app/services';
import { ChipComponent } from '@app/shared';
import { sortObjsByProp } from '@utils/helpers/functions';
import { IErrorMessage, IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import {
	IDepartment,
	IDepartmentLight,
	IDepartmentUser,
} from '@app/services/web-apis/departments/api-departments.domain';
import { IUser } from '@app/services/web-apis/users/api-users.domain';
import { IAccountPhone } from '@app/services/web-apis/phone-numbers/api-phone-numbers.domain';
import { noLeadTrailWhitespaceValidator } from '@utils/helpers/validators';
import { Observable } from 'rxjs/Observable';

@Component({
	selector: 'app-add-department',
	templateUrl: './adddepartment.component.html',
	styleUrls: ['./adddepartment.component.scss'],
	moduleId: module.id,
})
export class AddDepartmentsComponent implements OnInit {
	initialsBgColor: InitialsComponentBackground = InitialsComponentBackground.PRIMARY_DARK_1;
	extension: number = 0;
	deptId: number;

	allusersForNewDept: Array<any> = [];
	isExistingDept: boolean = false;
	//All loaders
	showLoaderUsers: boolean = false;
	isLoading: boolean = false;
	dataLoaded: boolean = false;

	// cleaned up data here
	allUsers: any[] = [];
	numbers: IAccountPhone[] = [];
	chipComponent: any = ChipComponent;

	departmentFormGroup: FormGroup;
	@Output() onSuccess: EventEmitter<IDepartment> = new EventEmitter();
	@Output() onCancel: EventEmitter<void> = new EventEmitter();

	constructor(
		@Inject(DIALOG_DATA) public dialogData: any,
		@Inject(DIALOG_REF) private dialogRef: any,
		private snackbarService: SnackbarService,
		private apiUsers: ApiUsersService,
		private apiDepartments: ApiDepartmentsService,
		private apiPhoneNumbers: ApiPhoneNumbersService,
		private translate: TranslateService,
		private fb: FormBuilder,
	) {
		/*
            allow numbers and users to be passed in to avoid loading time
        */
		if (this.dialogData && this.dialogData.accountNumbers) {
			this.numbers = this.dialogData.accountNumbers.map((n: IAccountPhone) => {
				return { ...n, type: 'ext' };
			});
		}

		this.departmentFormGroup = this.fb.group({
			name: ['', [Validators.required, Validators.maxLength(30), noLeadTrailWhitespaceValidator]],
			extension: ['', [Validators.required]],
			assignedNumbers: [[]],
			assignedTo: [[]],
		});
	}

	ngOnInit(): void {
		const forkJoinArray = [];

		/*
            if numbers and users werent passed in, lets fetch them dynamically
        */
		if (!this.numbers || !this.numbers.length) {
			forkJoinArray.push(this.apiPhoneNumbers.getAllPhoneNumbers());
		}

		if (!forkJoinArray.length) {
			forkJoin(forkJoinArray).subscribe((result: any[]) => {
				if (result[0]) {
					this.numbers = result[0]['data'].map((n: IAccountPhone) => {
						return { ...n, type: 'ext' };
					});
				}
				if (result[1]) {
					this.allUsers = result[1]['data'].map((n: IUser) => {
						return { ...n, type: 'tm' };
					});
				}
				this.dataLoaded = true;
			});
		}

		this.apiUsers.getAllUsers(false, false).subscribe((res: IRegularApiResponse<IUser[]>) => {
			const dropdownData = res.data.map((user: IUser) => ({
				...user,
				value: `${user.firstName} ${user.lastName}`,
				subValue: user.extension,
				selected: false,
				type: 'team-member',
			}));
			this.allUsers = sortObjsByProp('value', dropdownData);
		});
	}

	/**
    Add Department under account id and extenstion
    * @param deptName
    * @param extenstion
    */
	createdept(): void {
		this.isLoading = true;
		const newDepartment: IDepartmentLight = {
			name: this.departmentFormGroup.get('name').value,
			extension: this.departmentFormGroup.get('extension').value,
		} as IDepartmentLight;
		const members: IDepartmentUser[] = this.departmentFormGroup
			.get('assignedTo')
			.value.map((i: IDepartmentUser) => ({ userId: i.userId }));
		// let lines = this.departmentNumbers;
		const lines = this.departmentFormGroup.get('assignedNumbers').value;
		this.apiDepartments.createDepartment(newDepartment).subscribe(
			(res: IRegularApiResponse<IDepartment>) => {
				this.isLoading = false;
				if (res.hasError || res.errorMessages.length) {
					let errors: string = '';
					res.errorMessages.map((m: IErrorMessage) => (errors += `${m.message} `));
					this.snackbarService.create({
						status: 'danger',
						text: errors,
						connectTo: null,
					});
				} else {
					this.deptId = res.data.deptId;
					this.isExistingDept = true;

					const additionalRequests: Observable<any>[] = [this.addUsersToDepartment(this.deptId, members)];
					if (lines && lines.length > 0) {
						additionalRequests.push(this.addLinesToDepartment(this.deptId, lines));
					}
					forkJoin(additionalRequests).subscribe(() => {
						this.dialogRef && this.dialogRef.dismiss(true);
						this.onSuccess.emit(res.data);
					});
				}
			},
			(error: IRegularApiResponse<unknown>) => {
				this.isLoading = false;
				let errors = error.errorMessages.join(' ');
				this.snackbarService.create({
					status: 'danger',
					text: errors,
					connectTo: null,
				});
			},
		);
	}

	/*
        TODO: this is available on PUT for departments
        when it's available on POST simply add users to the map sent to the initial request
    */
	addUsersToDepartment(departmentId: number, members: IDepartmentUser[]): Observable<IRegularApiResponse<string>> {
		const deptMembers = members.map(m => ({ userId: m.userId }));
		return this.apiDepartments.updateDepartmentUsers(departmentId, deptMembers);
	}

	/*
        TODO: This really needs to be exposed through the department request, too much room for silent failure
        add "lines" array to POST /departments when it's available
    */
	addLinesToDepartment(departmentId: number, lines: number[]): Observable<any[]> {
		return forkJoin(
			lines.map(line => {
				let lineChange = {
					number: line,
					routeToId: departmentId,
					routeType: 'department',
					routesTo: `${departmentId}`,
					status: 'A',
				};
				return this.apiPhoneNumbers.updatePhoneNumber(lineChange);
			}),
		);
	}

	/**
   Gell All users under account id
   */
	GetAllUsers(): void {
		this.showLoaderUsers = true;
		this.apiUsers.getAllUsers(false).subscribe(
			(result: IRegularApiResponse<IUser[]>) => {
				this.showLoaderUsers = false;
				const data = result.data;
				this.allUsers = data;
				this.allusersForNewDept = this.allUsers;

				this.allusersForNewDept.forEach((user: any) => {
					user.FullName = `${user.firstName} ${user.lastName}`;
					user.isAddedUser = false;
				});
			},
			() => {
				this.showLoaderUsers = false;
			},
			() => {},
		);
	}

	//cancel delete pop
	cancelNewDept(): void {
		this.dialogRef && this.dialogRef.dismiss();
		this.onCancel.emit();
	}
}
