import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

import { ApiService } from '@app/services/api';
import { IRegularApiResponse } from '../common-api.domain';
import { IDecryptResponse } from './api-token.domain';
import { getAuthUrl } from '@app/Common';
import { JwtService } from '@app/services';
import { environment } from '@env/environment';

const typeMap = {
	reset: 'resetpassword',
	change: 'userchangepassword',
};

@Injectable()
export class ApiTokenService {
	private location: Location = this.document.defaultView.location;

	constructor(
		private apiService: ApiService,
		private httpClient: HttpClient,
		private jwtService: JwtService,
		@Inject(DOCUMENT) private document: Document,
	) {}

	resetPassword(email: string, type: 'reset' | 'change' = 'reset'): Observable<IRegularApiResponse<string>> {
		const body = {
			data: email,
			type: typeMap[type],
		};

		return this.apiService.post('/tokens', body);
	}

	resetPasswordWithAuthServer(username: string): Observable<any> {
		const callback = `${environment.api_auth_url}/connect/authorize?${this.jwtService.REDIRECT_PARAMS.toString()}`;
		return this.httpClient.post(`${getAuthUrl()}/account/forgotPassword`, {
			username,
			callback,
		});
	}

	decryptPassword(token: string): Observable<IRegularApiResponse<IDecryptResponse>> {
		return this.apiService.get(`/tokens/${token}`);
	}
}
