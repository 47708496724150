import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEqual } from 'lodash/fp';

import { ApiScheduleService, SnackbarService } from '@app/services';
import { DialogRef } from '@n2p/dialog/dialogRef';
import { ValidationExceptions } from '@app/Common/constants/errors';

@Component({
	selector: 'app-save-btn',
	templateUrl: './save-btn.component.html',
	styleUrls: ['./save-btn.component.scss'],
})
export class SaveBtnComponent implements OnInit {
	@Input() label: string;
	@Input() scheduleForm: FormGroup;
	@Input() dialogRef: DialogRef;
	@Input() isAdd = true;
	@Input() footerRef: ElementRef;
	isSaving: boolean;
	scheduleFormClone: any;
	constructor(
		private apiScheduService: ApiScheduleService,
		private snackbarService: SnackbarService,
		private translate: TranslateService,
	) {}

	get hasChanges(): boolean {
		return !isEqual(this.scheduleForm.value, this.scheduleFormClone);
	}

	async save(): Promise<any> {
		this.isSaving = true;

		this.scheduleForm.patchValue({ name: this.scheduleForm.get('name').value.trim() });

		if (!this.scheduleForm.get('name').value) {
			this.scheduleForm.get('name').setValue('');
			this.scheduleForm.get('name').setErrors({ empty: true });

			this.isSaving = false;

			return;
		}

		const schedule = this.apiScheduService.buildScheduleObject(this.scheduleForm, true);

		let res;
		this.isAdd
			? (res = await this.apiScheduService.postSchedule(schedule))
			: (res = await this.apiScheduService.putSchedule(schedule));

		if (res.hasError) {
			if (res.errorMessages && res.errorMessages.length) {
				if (res.errorMessages[0].code === ValidationExceptions.scheduleNameInUse) {
					this.scheduleForm.get('name').setErrors({ nameInUse: true });
				} else if (res.errorMessages[0].code === ValidationExceptions.nameProvidedTooLong) {
					this.scheduleForm.get('name').setErrors({ nameTooLong: true });
				} else if (res.errorMessages[0].code === ValidationExceptions.nameWasNotProvided) {
					this.scheduleForm.get('name').setErrors({ nameRequired: true });
				} else {
					this.snackbarService.create({
						status: 'danger',
						text: this.translate.instant('GLOBALS.GENERAL_ERROR'),
						connectTo: { nativeElement: this.footerRef },
					});
				}
			} else {
				this.snackbarService.create({
					status: 'danger',
					text: this.translate.instant('GLOBALS.GENERAL_ERROR'),
					connectTo: { nativeElement: this.footerRef },
				});
			}

			return (this.isSaving = false);
		}

		this.dialogRef.dismiss(true);
	}

	ngOnInit(): void {
		const scheduleFormSubscription = this.scheduleForm.valueChanges.subscribe(value => {
			if (value.doneInit) {
				this.scheduleFormClone = cloneDeep(this.scheduleForm.value);
				scheduleFormSubscription.unsubscribe();
			}
		});
	}
}
