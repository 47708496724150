import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AssignedNumbersModule, AvatarModule, DropdownModule, SvgModule } from '@n2p';
import { PhoneNumbersComponent } from './phone-numbers.component';
import { SharedModule } from '@app/shared/shared.module';
import { PhoneNumbersResultsComponent } from './phone-numbers-results/phone-numbers-results.component';
import { PhoneNumbersService } from './phone-numbers-service/phone-numbers.service';
import { ReplacePhoneNumberComponent } from './replace-phone-number/replace-phone-number.component';
import { SearchPhoneNumberInputComponent } from './search-phone-number-input/search-phone-number-input.component';
import { RemovePhoneNumberDialogComponent } from './remove-phone-number-dialog/remove-phone-number-dialog.component';
import { PipesModule } from '@app/pipes/pipes.module';
import { DirectivesModule } from '@app/directives/directives.module';
import { InputModule } from '@app/n2p-components/input/input.module';
import { AssignedToDropdownModule } from '@app/n2p-components/assigned-to-dropdown';
import { ShowHideModule } from '@app/directives/show-hide/show-hide.module';
import { EditPhoneNumberCallerIdSettingsDialogComponent } from './edit-phone-number-caller-id-settings-dialog/edit-phone-number-caller-id-settings-dialog.component';
import { RadioButtonModule } from '@n2p/radio-button/radio-button.module';
import { PhoneNumberTooltipComponent } from '@app/pages/phone-numbers/phone-number-tooltip/phone-number-tooltip.component';
import { ExternalModule } from '@n2p/external/external.module';
import { EditFailoverNumberComponent } from './edit-failover-number/edit-failover-number.component';
import { DeleteFailoverComponent } from './edit-failover-number/delete-failover-component/delete-failover.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ReactiveFormsModule,
		PipesModule,
		DirectivesModule,
		InputModule,
		FormsModule,
		AvatarModule,
		SvgModule,
		AssignedToDropdownModule,
		DropdownModule,
		ShowHideModule,
		RadioButtonModule,
		AssignedNumbersModule,
		ExternalModule,
	],
	declarations: [
		PhoneNumbersComponent,
		PhoneNumbersResultsComponent,
		ReplacePhoneNumberComponent,
		SearchPhoneNumberInputComponent,
		RemovePhoneNumberDialogComponent,
		EditPhoneNumberCallerIdSettingsDialogComponent,
		PhoneNumberTooltipComponent,
		EditFailoverNumberComponent,
		DeleteFailoverComponent,
	],
	entryComponents: [
		PhoneNumbersResultsComponent,
		ReplacePhoneNumberComponent,
		RemovePhoneNumberDialogComponent,
		EditPhoneNumberCallerIdSettingsDialogComponent,
		PhoneNumberTooltipComponent,
		EditFailoverNumberComponent,
		DeleteFailoverComponent,
	],
	providers: [PhoneNumbersService],
	exports: [PhoneNumbersComponent, PhoneNumberTooltipComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PhoneNumbersModule {}
