import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '@app/services';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';

@Injectable({
	providedIn: 'root',
})
export class ApiInternationalCallingService {
	private baseUrl: string = '/accounts/{accountId}/internationalCalls';
	constructor(private apiService: ApiService) {}

	getInternationalCallingState(): Observable<IRegularApiResponse<boolean>> {
		return this.apiService.get(`${this.baseUrl}`);
	}

	changeInternationalCallingState(isActive: boolean): Observable<IRegularApiResponse<boolean>> {
		return this.apiService.put(
			`${this.baseUrl}`,
			`${isActive}`,
			{},
			{ headers: { 'Content-Type': 'application/json' } },
		);
	}
}
