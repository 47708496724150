import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import {
	ApiDepartmentsService,
	ApiRingGroupsService,
	ApiSpecialExtensionsService,
	ApiUsersService,
	ApiWelcomeMenusService,
} from '@app/services';
import { PhoneNumberFormatterPipe } from '@app/pipes';
import { sortObjsByProp } from '@utils/helpers/functions';
import { AssignedToDropdownDataComponent } from './assigned-to-dropdown-data/assigned-to-dropdown-data.component';

@Component({
	selector: 'n2p-assigned-to-dropdown',
	templateUrl: './assigned-to-dropdown.component.html',
	styleUrls: ['./assigned-to-dropdown.component.scss'],
	providers: [
		PhoneNumberFormatterPipe,
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => AssignedToDropdownComponent),
		},
	],
})
export class AssignedToDropdownComponent implements OnInit, OnDestroy, ControlValueAccessor {
	@Input() label: string;
	@Input() isTypeHover: boolean;
	@Input() hasError = false;
	@Input() isDisabled = false;
	@Input() required = false;
	@Input() singleSelection = false;
	@Input() assignedToPromise?: Promise<any>;
	@Input() placeholder = this.translate.instant('GLOBALS.UNASSIGNED');
	@Input() singleLevel = false;
	@Input() confirmReassign: boolean;
	@Input() showDeleteOption: boolean;
	@Input() hiddenEntities: Array<any>;
	@Input() availableEntityTypes: Array<string>;
	@Output() onClosed = new EventEmitter<{}>();
	assignedToDataComponent = AssignedToDropdownDataComponent;
	dropdownChoiceData: Array<any>;
	assignedToControl = new FormControl([]);
	private _value: Array<any>;
	private subscriptions = [];
	private _data: Array<any>;
	onChange = value => {};
	onTouch = () => {};
	constructor(
		private apiUsers: ApiUsersService,
		private apiDepartments: ApiDepartmentsService,
		private apiRingGroups: ApiRingGroupsService,
		private apiWelcomeMenus: ApiWelcomeMenusService,
		private apiSpecialExtensions: ApiSpecialExtensionsService,
		private translate: TranslateService,
	) {}

	@Input() set data(value) {
		this._data = value;
		this.dropdownChoiceData = this._data;
	}

	get data(): any {
		return this._data;
	}

	dropdownClosed(ev): void {
		this.onClosed.emit(ev);
	}

	set value(val) {
		this._value = val;
	}

	get value(): Array<any> {
		return this._value;
	}

	writeValue(value): void {
		this.value = value;
		this.onChange(value);
	}

	registerOnChange(fn): void {
		this.onChange = fn;
	}

	registerOnTouched(fn): void {
		this.onTouch = fn;
	}

	ngOnInit(): void {
		if (this.data) {
			this.dropdownChoiceData = this.data;
		} else if (this.assignedToPromise) {
			this.assignedToPromise.then(res => {
				this.dropdownChoiceData = this.populateDropdownData(res);
			});
		} else {
			const promiseAll = [
				this.apiUsers.getAllUsersLW().toPromise(),
				this.apiDepartments.getAllDepartmentsLW().toPromise(),
				this.apiRingGroups.getAllRingGroups().toPromise(),
				this.apiWelcomeMenus.getAllWelcomeMenusLW().toPromise(),
				this.apiSpecialExtensions.getAllSpecialExtensionsLW().toPromise(),
			];
			Promise.all(promiseAll).then(res => {
				this.dropdownChoiceData = this.populateDropdownData(res);
			});
		}
		setTimeout(() => {
			this.assignedToControl = new FormControl(this.value);
			this.subscriptions.push(this.assignedToControl.valueChanges.subscribe(value => this.writeValue(value)));
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	private populateDropdownData = (res: Array<any>) => {
		const users = (!res[0].hasError && res[0].data.items) || [];
		const departments = (!res[1].hasError && res[1].data.items) || [];
		const ringGroups = (!res[2].hasError && res[2].data) || [];
		const welcomeMenus = (!res[3].hasError && res[3].data.items) || [];
		const specialExtensions = (!res[4].hasError && res[4].data.items) || [];

		const concatData = [
			...this.mapEntities(users, 'team-member', tm => `${tm.firstName} ${tm.lastName}`),
			...this.mapEntities(departments, 'department'),
			...this.mapEntities(ringGroups, 'ring-group'),
			...this.mapEntities(welcomeMenus, 'welcome-menu'),
			...this.mapEntities(specialExtensions, 'special-extension'),
		];

		if (this.showDeleteOption) {
			concatData.push({ value: 'Delete', type: 'delete' });
		}

		return sortObjsByProp('value', concatData);
	};

	private mapEntities = (entityArray: Array<any>, type: string, valueFn?: Function) => {
		entityArray = entityArray.map(entity => ({
			...entity,
			value: (valueFn && valueFn(entity)) || entity.name,
			selected: false,
			subValue: type !== 'welcome-menu' ? entity.extension : undefined,
			type,
		}));

		if (this.hiddenEntities && this.hiddenEntities.length) {
			entityArray = entityArray.filter(i => !this.hiddenEntities.includes(i.value));
		}

		return entityArray;
	};
}
