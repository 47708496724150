import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { SnackbarService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { FileSizeLevels } from '@app/Common/constants/common';
import { FileSizePipe } from '@app/pipes/file-size/file-size.pipe';

@Component({
	selector: 'n2p-file-uploader',
	templateUrl: './file-uploader.component.html',
	styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit {
	@Input() maxFileSize: number = 4 * FileSizeLevels.MEGABYTE;
	@Input() loading: boolean = false;
	@Input() supportedFileTypes: string[] = ['.mp3', '.m4a', '.wav'];
	@Input() message: string = 'Drag and drop here to upload';
	@Input() showSupportedTypesMessage: boolean = false;
	@Output() fileChanged: EventEmitter<FileList> = new EventEmitter();
	@ViewChild('dropZone', { static: true }) dropZone: ElementRef;
	@ViewChild('fileInput', { static: false }) fileInput: ElementRef;
	@Input() bodySectionRef: ElementRef;

	supportedTypesMessageOptions: { formats: string; size: string };

	constructor(private snackbarService: SnackbarService, private translateService: TranslateService) {}

	ngOnInit(): void {
		const fileSizePipe = new FileSizePipe();

		this.supportedTypesMessageOptions = {
			formats: this.supportedFileTypes.join(', '),
			size: fileSizePipe.transform(this.maxFileSize),
		};
	}

	handleFileChange(ev): void {
		if (!ev.target.files || !ev.target.files.length) return;
		if (ev.target.files[0].size > this.maxFileSize) {
			this.snackbarService.create({
				text: this.translateService.instant('GLOBALS.MOH.FILE_TOO_LARGE', {
					size: this.supportedTypesMessageOptions.size || '',
				}),
				status: 'danger',
				connectTo: this.bodySectionRef,
			});

			return;
		}

		const fileExt = ev.target.files[0].name.substr(ev.target.files[0].name.lastIndexOf('.'));
		if (this.supportedFileTypes.findIndex((i: string) => i === fileExt.toLowerCase()) === -1) {
			this.snackbarService.create({
				text: this.translateService.instant('GLOBALS.MOH.FILE_WRONG_FORMAT', {
					formats: this.supportedFileTypes?.join(', ') || '',
				}),
				status: 'danger',
				connectTo: this.bodySectionRef,
			});

			return;
		}

		this.handleChange(ev.target.files);
	}

	handleChange(files: FileList): void {
		this.fileChanged.emit(files);
	}

	selectFile(): void {
		this.fileInput.nativeElement.click();
	}
}
