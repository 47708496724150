import { HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';

import { CacheManagerService } from '@app/services/cache-manager/cache-manager.service';
import { HeaderModule } from '@n2p/header/header.module';
import { LeftSideNavModule } from '@n2p/left-side-nav/left-side-nav.module';
import { environment } from 'environments/environment';
import { isImpersonated } from '@utils/helpers/impersonation';

// services
import {
	AccountService,
	AuthService,
	CacheService,
	CallHistoryService,
	CommonService,
	DeviceServices,
	DialogService,
	DragService,
	GlobalLoaderService,
	PhoneNumberService,
	PromptService,
	SnackbarService,
	AuthStorageData,
	WelcomeMenuService,
	GlobalEventsService,
	UserAgentService,
	JwtService,
} from '@app/services';
import { DepartmentService } from '@app/services/department/department.service';
import {
	APP_STORAGE_IMPLEMENTATION,
	APP_STORAGE_PREFIX,
	AppConditionalStorageService,
	AppLocalStorageService,
	AppSessionStorageService,
} from '@app/services/storage/storage.service';

// components
import { CompanyComponent } from './pages/company/company.component';
import { DeleteRecordingPromptComponent } from '@app/pages/call-history/components/delete-recording-prompt/delete-recording-prompt.component';
import { RecordingPopupComponent } from '@app/pages/call-history/components/recording-popup/recording-popup.component';
import { AppComponent } from './app.component';
import { AppContainerComponent } from './pages/app-container/app-container.component';
import { DeleteVoicemailPromptComponent, VoicemailPopupComponent } from '@app/pages/call-history/components';
import { DevicesAssignedPromptComponent } from './pages/devices/components/devices-assigned-prompt/devices-assigned-prompt.component';
import { PageHeaderPopupComponent } from './pages/app-container/components/page-header-popup/page-header-popup.component';

// modules
import { ApplicationRef, DoBootstrap, Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from './translation/translation.module';
import { InputModule } from '@app/n2p-components/input/input.module';
import { TeamMembersModule } from '@app/pages/teammembers/teammembers.module';
import { WebApisModule } from '@app/services/web-apis.module';
import { PhoneNumbersModule } from '@app/pages/phone-numbers/phone-numbers.module';
import { DirectivesModule } from './directives/directives.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { DepartmentsModule } from '@app/pages/departments/departments.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PipesModule } from '@app/pipes';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ChartsModule } from 'ng2-charts';
import { SharedModule } from '@app/shared';
import { WelcomemenuModule } from '@app/pages/welcomemenu/welcomemenu.module';
import { LoaderModule, SvgModule } from '@n2p';

// translations
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import localeEs from '@angular/common/locales/es';
import localeEsAR from '@angular/common/locales/es-AR';
import { SchedulesModule } from '@app/pages/schedules/schedules.module';
import { AuthGuard } from '@app/guards/auth/auth.guard';
import { ReceptionistConsoleModule } from '@app/pages/receptionist-console/receptionist-console.module';
import { PublicFeatureFlagsService } from '@app/services/feature-flags/public-feature-flags.service';
import { NoPermissionsComponent } from './pages/no-permissions/no-permissions.component';
import { DialogGenericConfirmComponent } from '@n2p/dialog/dialog-generic-confirm/dialog-generic-confirm.component';

@NgModule({
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		ChartsModule,
		FormsModule,
		HttpClientModule,
		ReactiveFormsModule,
		AppRoutingModule,
		SharedModule,
		OverlayModule,
		PortalModule,
		PhoneNumbersModule,
		DirectivesModule,
		PipesModule,
		WebApisModule,
		TranslationModule,
		InputModule,
		HeaderModule,
		TeamMembersModule,
		DepartmentsModule,
		WelcomemenuModule,
		LeftSideNavModule,
		SchedulesModule,
		LoaderModule,
		SvgModule,
		ReceptionistConsoleModule,
	],
	declarations: [
		AppComponent,
		CompanyComponent,
		AppContainerComponent,
		VoicemailPopupComponent,
		DeleteVoicemailPromptComponent,
		DevicesAssignedPromptComponent,
		RecordingPopupComponent,
		DeleteRecordingPromptComponent,
		PageHeaderPopupComponent,
		NoPermissionsComponent,
	],
	providers: [
		JwtService,
		CacheManagerService,
		CommonService,
		DepartmentService,
		DeviceServices,
		DialogService,
		CallHistoryService,
		PromptService,
		CacheService,
		WelcomeMenuService,
		SnackbarService,
		GlobalLoaderService,
		PhoneNumberService,
		AccountService,
		DragService,
		AuthGuard,
		AuthStorageData,
		AuthService,
		GlobalEventsService,
		UserAgentService,
		AppLocalStorageService,
		AppSessionStorageService,
		AppConditionalStorageService,
		{
			provide: APP_STORAGE_PREFIX,
			useValue: '', // empty prefix means that storage keys won't be prefixed
		},
		{
			provide: APP_STORAGE_IMPLEMENTATION,
			useValue: isImpersonated ? 'session' : 'local',
		},
		{
			provide: APP_BASE_HREF,
			useFactory: () => environment.base_href || '/',
		},
	],
	entryComponents: [
		VoicemailPopupComponent,
		DeleteVoicemailPromptComponent,
		DevicesAssignedPromptComponent,
		RecordingPopupComponent,
		DeleteRecordingPromptComponent,
		AppComponent,
		PageHeaderPopupComponent,
		DialogGenericConfirmComponent,
	],
})
export class AppModule implements DoBootstrap {
	constructor(private publicFeatureFlags: PublicFeatureFlagsService, private authService: AuthService) {}

	ngDoBootstrap(appRef: ApplicationRef): void {
		this.bootstrapNewRelic();
		this.bootstrapLocals();
		this.authService.init().subscribe(data => {
			appRef.bootstrap(AppComponent);
		});
	}

	private bootstrapLocals(): void {
		// TODO shouldn't this load data based on locale not all languages for everyone?
		// TODO this should be in the app component not module
		registerLocaleData(localeEn, 'en');
		registerLocaleData(localePt, 'pt');
		registerLocaleData(localeEs, 'es');
		registerLocaleData(localeEsAR, 'es-AR');
	}

	private bootstrapNewRelic(): void {
		// newrelic set up for non local env
		if (!environment.local && environment.nr_app_id) {
			try {
				(window as any).NREUM.info = {
					beacon: 'bam.nr-data.net',
					errorBeacon: 'bam.nr-data.net',
					licenseKey: '8e1758c1ad',
					applicationID: environment.nr_app_id,
					sa: 1,
				};
			} catch (e) {
				// TODO handle failed newrelic load
			}
		}
	}
}
