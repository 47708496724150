import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

@Component({
	selector: 'app-menu-options',
	templateUrl: './menu-options.component.html',
	styleUrls: ['./menu-options.component.scss'],
})
export class MenuOptionsComponent implements OnInit, OnDestroy {
	keys: Array<any>;
	selectedKeys = [];
	optionsSubscription: Subscription;
	@Input() formGroup: FormGroup;
	constructor(private fb: FormBuilder) {}

	addMenuOption(): void {
		const formGroup = this.createFormGroup();
		(this.formGroup.get('options') as FormArray).push(formGroup);
	}

	removeMenuOption(index): void {
		(this.formGroup.get('options') as FormArray).removeAt(index + 1);
	}

	ngOnInit(): void {
		const formArray = this.formGroup.get('options') as FormArray;
		formArray.controls.sort((fg1: FormGroup, fg2: FormGroup) => {
			const one = fg1.controls['key'].value[0] ? fg1.controls['key'].value[0].value : undefined;
			const two = fg2.controls['key'].value[0] ? fg2.controls['key'].value[0].value : undefined;
			if (one === 'timeout') return -1;
			else if (two === 'timeout') return 1;

			if (one === '*') return 1;
			else if (two === '*') return -1;

			if (one === '#') return 1;
			else if (two === '#') return -1;

			return one < two ? -1 : 1;
		});
		this.initKeys();

		this.optionsSubscription = this.formGroup.get('options').valueChanges.subscribe(value => {
			setTimeout(() => {
				this.selectedKeys = this.getSelectedKeys(value);
			});
		});

		this.selectedKeys = this.getSelectedKeys(this.formGroup.get('options').value);
	}

	ngOnDestroy(): void {
		this.optionsSubscription.unsubscribe();
	}

	private getSelectedKeys = (options): Array<any> => {
		return options.map(i => {
			if (Array.isArray(i.key) && i.key.length) {
				return i.key[0].value;
			}

			return -1;
		});
	};

	private createFormGroup(): FormGroup {
		return this.fb.group({
			key: new FormControl([], [Validators.required]),
			forwardCallsTo: [[], [Validators.required]],
		});
	}

	private initKeys(): void {
		const formArray = this.formGroup.get('options') as FormArray;
		this.keys = [];
		for (let i = 0; i <= 9; i++) {
			this.keys.push({
				value: i.toString(),
				key: i.toString(),
				selected: this.keySelected(formArray, i),
			});
		}
		this.keys.push({
			value: '#',
			key: '#',
			selected: this.keySelected(formArray, '#'),
		});
		this.keys.push({
			value: '*',
			key: '*',
			selected: this.keySelected(formArray, '*'),
		});
	}

	private keySelected = (formArray, i): boolean => {
		return (
			formArray.controls.findIndex(
				(fg: FormGroup) =>
					Array.isArray(fg.controls['key'].value) &&
					fg.controls['key'].value.length &&
					fg.controls['key'].value[0].value === i.toString(),
			) !== -1
		);
	};
}
