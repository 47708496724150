export class Address {
	address1 = '';
	address2 = '';
	locality = '';
	city = '';
	state = '';
	zip = '';
	country = 'US';
	profileId?: number = undefined;

	constructor(data?: object) {
		if (data) {
			this.set = data;
		}
	}

	set set(data: object) {
		Object.keys(data).forEach(key => {
			if (this.hasOwnProperty(key) && data[key]) {
				this[key] = data[key];
			}
		});
	}

	get toJSON(): object {
		const json = Object.assign({}, this);

		return json;
	}
}
