import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShowHideDirective } from './show-hide.directive';
import { IfDidlessCountryDirective } from '@app/directives/show-hide/if-didless-country.directive';
import { UnlessDidlessCountryDirective } from '@app/directives/show-hide/unless-didless-country.directive';
import { IfCountriesDirective } from '@app/directives/show-hide/if-countries.directive';
import { UnlessCountriesDirective } from '@app/directives/show-hide/unless-countries.directive';

@NgModule({
	declarations: [
		ShowHideDirective,
		IfDidlessCountryDirective,
		UnlessDidlessCountryDirective,
		IfCountriesDirective,
		UnlessCountriesDirective,
	],
	exports: [
		ShowHideDirective,
		IfDidlessCountryDirective,
		UnlessDidlessCountryDirective,
		IfCountriesDirective,
		UnlessCountriesDirective,
	],
	imports: [CommonModule],
})
export class ShowHideModule {}
