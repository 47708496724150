import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { InitialsValue } from 'n2p-ui-library';

import { IUser } from '@app/services/web-apis/users/api-users.domain';

import { getAvatarUrlFromData } from '@utils/helpers/functions';
import { ApiAccountsService, JwtService } from '@app/services';
import { supportContactMap } from '@app/Common';

@Component({
	selector: 'n2p-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	animations: [
		trigger('fadeInOut', [
			state(
				'void',
				style({
					opacity: 0,
				}),
			),
			transition('void <=> *', animate(300)),
		]),
		trigger('slideInOut', [
			state(
				'void',
				style({
					transform: 'translateX(100%)',
				}),
			),
			transition('void <=> *', animate(300)),
		]),
	],
})
export class SidebarComponent implements OnChanges {
	@Input() isPremium: boolean;
	@Input() user: IUser;
	@Input() shouldHuddleBeHidden: boolean;
	@Input() shouldRconBeHidden: boolean;
	@Input() shouldWallboardBeHidden: boolean;
	@Input() shouldPremiumUISwitcherBeHidden: boolean;
	@Input() huddleUrl: string;
	@Input() wallboardUrl: string;

	@Input() visible = false;

	@Output() onClose = new EventEmitter();
	@Output() onClickProfile = new EventEmitter<IUser>();
	@Output() onHuddleClick = new EventEmitter();
	@Output() onRconClick = new EventEmitter();
	@Output() onWallboardClick = new EventEmitter();
	@Output() onSignOutClick = new EventEmitter();
	@Output() onToggleSPOG = new EventEmitter();

	isImpersonated: boolean = this.jwtService.isImpersonated();

	public isVisible = this.visible;

	public get avatarUrl(): string {
		return getAvatarUrlFromData(this.user, '120');
	}

	public get avatarInitials(): InitialsValue {
		return {
			firstName: this.user.firstName,
			lastName: this.user.lastName,
		};
	}

	constructor(private jwtService: JwtService, private apiAccountService: ApiAccountsService) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.visible && changes.visible.currentValue !== this.isVisible) {
			this.isVisible = changes.visible.currentValue;
		}
	}

	public hide(): void {
		this.isVisible = false;
		this.onClose.emit();
	}

	public handleChangeSwitcher(): void {
		this.hide();
		this.onToggleSPOG.emit();
	}

	public handleClickProfile(): void {
		this.hide();
		this.onClickProfile.emit(this.user);
	}

	public handleHuddleClick(): void {
		this.hide();
		this.onHuddleClick.emit();
	}

	public handleRconClick(): void {
		this.hide();
		this.onRconClick.emit();
	}

	public handleWallboardClick(): void {
		this.hide();
		this.onWallboardClick.emit();
	}

	public handleSignOutClick(): void {
		this.hide();
		this.onSignOutClick.emit();
	}

	public get supportInfo(): { email: string; phone: string } {
		const accountCountry = this.apiAccountService.account?.country || 'US';
		return supportContactMap[accountCountry] || supportContactMap.US;
	}
}
