import { Component, HostListener, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { POPUP_ADDITIONAL_DATA, POPUP_CLICK_CALLBACK } from '@n2p/popup/popup.injectors';
import { AuthStorageData, ApiAccountsService } from '@app/services';
import { supportContactMap } from '@app/Common';

@Component({
	selector: 'n2p-help-support-popup',
	templateUrl: './help-support-popup.component.html',
	styleUrls: ['./help-support-popup.component.scss'],
})
export class HelpSupportPopupComponent {
	constructor(
		@Inject(POPUP_CLICK_CALLBACK) private popupClicked: any,
		@Inject(POPUP_ADDITIONAL_DATA) private additionData: any,
		private translate: TranslateService,
		private accountsService: ApiAccountsService,
		private authStorageDataService: AuthStorageData,
	) {}

	@HostListener('click', ['$event']) onClick(ev: Event): void {
		ev.stopPropagation();
	}

	get supportContact(): { email: string; phone: string } {
		return supportContactMap[this.accountsService.account?.country] || supportContactMap.US;
	}

	openDialPad(event: Event): void {
		this.additionData.toggleDialPad(event, this.supportContact.phone);
		this.clicked();
	}

	get supportEmail(): string {
		const body = encodeURI(
			`




---------
${this.translate.instant('GLOBALS.ACCOUNT_ID')}: ${this.authStorageDataService.accountId}
${this.translate.instant('GLOBALS.USER')}: ${this.authStorageDataService.userId}
${this.translate.instant('GLOBALS.USER_NAME')}: ${this.authStorageDataService.userName}
`,
		);

		return `mailto:${this.supportContact.email}?body=${body}`;
	}

	clicked(): void {
		this.popupClicked();
	}
}
