import { Component, Inject, Injector, OnInit } from '@angular/core';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { DIALOG_COMPONENT, DIALOG_DATA, DIALOG_REF } from '@app/n2p-components/dialog/dialog.injectors';
import { DialogRef } from '@app/n2p-components/dialog/dialogRef';

@Component({
	selector: 'app-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
	hideX: boolean = false;
	component: any;
	constructor(
		@Inject(DIALOG_DATA) private data: any,
		@Inject(DIALOG_REF) private modalRef: DialogRef,
		@Inject(DIALOG_COMPONENT) private modalComponent: any,
		private injector: Injector,
	) {}

	createInjector(data, ref): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(DIALOG_DATA, data);
		injectorTokens.set(DIALOG_REF, ref);

		return new PortalInjector(this.injector, injectorTokens);
	}
	close = (): void => {
		this.modalRef.dismiss();
	};

	ngOnInit(): void {
		if (this.modalComponent) {
			this.component = new ComponentPortal(
				this.modalComponent,
				undefined,
				this.createInjector(this.data, this.modalRef),
			);
		}
		// ability to hide the x at the top right
		setTimeout(() => {
			if (Object.hasOwnProperty.call(this.data, 'hideX')) {
				this.hideX = this.data.hideX;
			}
		});
	}
}
