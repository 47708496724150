import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MAX_ROWS } from '@app/Common';

@Component({
	selector: 'n2p-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
})
export class TableComponent {
	@Input() maxRows = MAX_ROWS;
	@Input() gridStyles = {
		'grid-template-columns': 'auto',
		'grid-row-gap': '25px',
		'grid-row-column': '5px',
	};
	@Input() showData = true;
	@Input() paginate = true;
	@Input() totalRows = 0;
	@Input() currentPage = 1;

	@Output() pageSelected: EventEmitter<number> = new EventEmitter();

	changePage(event: any): void {
		this.pageSelected.emit(event);
	}

	get showPagination(): boolean {
		return this.totalRows > 1 && this.paginate;
	}
}
