import { InjectionToken } from '@angular/core';

export const POPUP_REF = new InjectionToken<{}>('POPUP_REF');
export const POPUP_HOVERED = new InjectionToken<{}>('POPUP_HOVERED');
export const POPUP_CONTENT = new InjectionToken<{}>('POPUP_CONTENT');
export const POPUP_CONFIG = new InjectionToken<{}>('POPUP_CONFIG');
export const POPUP_COMPONENT = new InjectionToken<{}>('POPUP_COMPONENT');
export const POPUP_COMPONENT_DATA = new InjectionToken<{}>('POPUP_COMPONENT_DATA');
export const POPUP_CLICK_CALLBACK = new InjectionToken<{}>('POPUP_CLICK_CALLBACK');
export const POPUP_DATA = new InjectionToken<{}>('POPUP_DATA');
export const POPUP_ADDITIONAL_DATA = new InjectionToken<{}>('POPUP_ADDITIONAL_DATA');
