export const RING_COUNT = 'ringCount';

export const UNASSIGNED = '';
export const TEAM_MEMBER = 'user';
export const DEPARTMENT = 'department';
export const RING_GROUP = 'ringGroup';
export const MULTI_AUTO_ATTENDANT = 'maa';
export const WELCOME_MENU = 'menu';
export const SPECIAL_EXTENSION = 'specialExtension';
export const DIRECTORY = 'directory';
export const PHONE_NUMBER = 'phone';
export const ACCOUNT = 'account';
export const FAX = 'fax';
export const INTERCOM = 'intercom';
export const RINGER = 'ringer';
export const PAGER = 'pager';
export const REMOTE_VMA = 'RMA';
export const CALL_QUEUE = 'callQueue';
export const VIRTUAL_FAX = 'virtualFax';
export const PORTING = 'porting';

export const VALID_UNASSIGNED = 'unassigned';
export const VALID_TEAM_MEMBER = 'team-member';
export const VALID_DEPARTMENT = 'department';
export const VALID_RING_GROUP = 'ring-group';
export const VALID_WELCOME_MENU = 'welcome-menu';
export const VALID_SPECIAL_EXTENSION = 'special-extension';
export const VALID_DIRECTORY = 'directory';
export const VALID_PHONE_NUMBER = 'phone-number';
export const VALID_ACCOUNT = 'all-company';
export const VALID_CALL_QUEUE = 'call-queue';
export const VALID_VIRTUAL_FAX = 'virtual-fax';
export const VALID_PORTING = 'porting';

export const ENTITY_TYPE_MAP: { [key: string]: string } = {
	[UNASSIGNED]: VALID_UNASSIGNED, // represents 'unassigned' numbers

	[PORTING]: VALID_PORTING,

	[TEAM_MEMBER]: VALID_TEAM_MEMBER,

	[DEPARTMENT]: VALID_DEPARTMENT,

	[RING_GROUP]: VALID_RING_GROUP,

	[MULTI_AUTO_ATTENDANT]: VALID_WELCOME_MENU,
	[WELCOME_MENU]: VALID_WELCOME_MENU,

	[FAX]: VALID_SPECIAL_EXTENSION,
	[INTERCOM]: VALID_SPECIAL_EXTENSION,
	[RINGER]: VALID_SPECIAL_EXTENSION,
	[PAGER]: VALID_SPECIAL_EXTENSION,
	[REMOTE_VMA]: VALID_SPECIAL_EXTENSION,
	[SPECIAL_EXTENSION]: VALID_SPECIAL_EXTENSION,

	[DIRECTORY]: VALID_DIRECTORY,

	[ACCOUNT]: VALID_ACCOUNT,

	[PHONE_NUMBER]: VALID_PHONE_NUMBER,

	[CALL_QUEUE]: VALID_CALL_QUEUE,

	[VIRTUAL_FAX]: VALID_VIRTUAL_FAX,
};

export const ENTITY_TYPE_MAP_KEYS: string[] = Object.keys(ENTITY_TYPE_MAP);
export const ENTITY_TYPE_MAP_VALUES: string[] = ENTITY_TYPE_MAP_KEYS.map(k => ENTITY_TYPE_MAP[k]);

export const getValidType = (type: string): string => {
	if (ENTITY_TYPE_MAP_VALUES.includes(type)) {
		return type;
	}

	if (ENTITY_TYPE_MAP_VALUES.includes(ENTITY_TYPE_MAP[type])) {
		return ENTITY_TYPE_MAP[type];
	}

	return '';
};

export const isValidType = (type: string): boolean => {
	return (
		ENTITY_TYPE_MAP_VALUES.includes(type) ||
		ENTITY_TYPE_MAP_KEYS.includes(type) ||
		ENTITY_TYPE_MAP_VALUES.includes(ENTITY_TYPE_MAP[type])
	);
};

export enum EXTENSION_CODES {
	FAX = 'FX',
	FIVE_NINE = 'FN',
	INTERCOM = 'IC',
	PAGER = 'PG',
	RINGER = 'RN',
	REMOTE_VM = 'RMA',
}
