import {
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Injector,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';

import {
	ADDITIONAL_OPTIONS,
	MULTISELECT_CHIP_MORE_REF,
	MULTISELECT_DATA,
	MULTISELECT_DATA_CLICK_CALLBACK,
	MULTISELECT_DATA_CONFIG,
	MULTISELECT_DATA_FILTER,
	MULTISELECT_FORM_GROUP,
} from 'utils/constants/injectortokens';
import { AbstractControlValueAccessor } from '@app/Common/classes/AbstractControlValueAccessor';
import { MultiSelectDropdownChipMoreComponent } from '../multi-select-dropdown-chip';
import { MULTISELECT_CHIP_CLICK_CALLBACK } from '@utils/constants/injectortokens';

@Component({
	selector: 'app-multi-select-dropdown',
	templateUrl: './multi-select-dropdown.component.html',
	styleUrls: ['./multi-select-dropdown.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			/* tslint:disable */
			useExisting: forwardRef(() => MultiSelectDropdownComponent),
			/* tslint:enable */
			multi: true,
		},
	],
})
export class MultiSelectDropdownComponent extends AbstractControlValueAccessor implements OnInit {
	@Input() bottomCutOff = 270;
	@Input() forceChipRender = false;
	@Input() disabled = false;
	@Input() isOptional = false;
	@Input() isRequired = false;
	@Input() buttonId = 'multi-select-dropdown-btn';
	_data: any = undefined;
	isOpen = false;
	filterStr: string;
	@ViewChild('buttonRef', { static: true }) buttonRef: ElementRef;
	@Input() buttonHeight?: string;
	@Input() buttonInnerHeight?: string;
	@Input() noBorder = false;
	@Input() config: any = {};
	@Input() formGroup: FormGroup;
	@Input() label: string;
	@Input() dropDownDataComponent: any;
	@Input() dropDownChipComponent: any;
	@Input() findIndex: any;
	@Input() maxSelected = 999;
	@Output() selectedChange: EventEmitter<any> = new EventEmitter<any>(undefined);
	@Output() chipRemoved: EventEmitter<any> = new EventEmitter<any>(undefined);
	filter: BehaviorSubject<string>;
	@Input() selectedData: Array<any> = [];
	dataRef: OverlayRef;
	@Input() hideInputElem: boolean = false;
	@ViewChild('moreChipDropdown', { static: true }) moreChipDropdown: ElementRef;
	@Input() additionalOptions: any;

	constructor(private injector: Injector, private overlay: Overlay) {
		super();
		this.filter = new BehaviorSubject<string>(this.filterStr);
		this.buttonId = `${this.buttonId}_${document.getElementsByClassName('multi-select-dropdown-btn').length + 1}`;
	}

	@Input('data') set data(d) {
		this._data = d;
		if (this.isOpen) {
			this.close();
			this.open();
		}
	}

	get selectedArray(): Array<any> {
		if (this.isRequired && this.value) {
			if (this.value.length === 1) {
				return [{ ...this.value[0], removable: false }];
			} else {
				return this.value;
			}
		}

		return this.value;
	}

	get maxChipAllowed(): number {
		if (this.forceChipRender) {
			return this.selectedArray.length;
		}
		const width = this.buttonRef.nativeElement.clientWidth;

		return width <= 240 ? 0 : Math.round(width / 240);
	}

	filterChange(): void {
		this.filter.next(this.filterStr);
	}

	dataClickCallback = (item, i) => {
		const index = this.findIndex(this.selectedData, item);
		if (index === -1) {
			if (this.selectedData.length < this.maxSelected) {
				this.selectedData.push(item);
			} else {
				this.selectedData[this.selectedData.length - 1] = item;
			}
		} else {
			this.selectedData.splice(index, 1);
		}
		this.selectedChange.emit(this.selectedData);
		this.value = this.selectedData;
		if (this.maxSelected === 1 && this.selectedData.length) {
			this.close();
		}
		this.filterStr = undefined;
		this.filterChange();
	};

	chipClickCallback = item => {
		const selectedIndex = this.findIndex(this.selectedData, item);
		this.selectedData.splice(selectedIndex, 1);
		this.chipRemoved.emit(item);
		this.selectedChange.emit(this.selectedData);
		this.value = this.selectedData;
	};

	close(): void {
		this.isOpen = false;
		this.dataRef.dispose();
	}

	open(): void {
		if (this.isOpen || this.disabled) {
			return;
		}

		this.isOpen = true;
		const config = new OverlayConfig({
			backdropClass: 'multi-select-dropdown-data',
			width: this.buttonRef.nativeElement.offsetWidth,
		});

		const buttonElem = this.buttonRef.nativeElement;
		const diff = window.innerHeight - buttonElem.getBoundingClientRect()['y'];
		const onTop = diff < this.bottomCutOff;
		config.scrollStrategy = this.overlay.scrollStrategies.reposition();
		config.positionStrategy = this.overlay
			.position()
			.connectedTo(
				this.buttonRef,
				{
					originY: onTop ? 'top' : 'bottom',
					originX: 'start',
				},
				{
					overlayY: onTop ? 'bottom' : 'top',
					overlayX: 'start',
				},
			)
			.withOffsetY(onTop ? -8 : 8);

		config.hasBackdrop = true;

		this.dataRef = this.overlay.create(config);

		this.dataRef.backdropClick().subscribe(() => {
			this.close();
		});

		const component = new ComponentPortal(this.dropDownDataComponent, undefined, this.createDataComponentInjector());
		this.dataRef.attach(component);
	}

	showChipMore(ev): void {
		ev.stopPropagation();
		const config = new OverlayConfig({
			backdropClass: 'multi-select-dropdown-data',
		});
		config.scrollStrategy = this.overlay.scrollStrategies.reposition();
		config.positionStrategy = this.overlay
			.position()
			.connectedTo(
				this.moreChipDropdown,
				{
					originY: 'center',
					originX: 'end',
				},
				{
					overlayY: 'center',
					overlayX: 'start',
				},
			)
			.withOffsetX(10);

		config.hasBackdrop = true;
		const overlayRef = this.overlay.create(config);
		overlayRef.backdropClick().subscribe(() => overlayRef.dispose());
		const portal = new ComponentPortal(
			MultiSelectDropdownChipMoreComponent,
			undefined,
			this.createChipMoreInjector(overlayRef),
		);
		overlayRef.attach(portal);
	}

	ngOnInit(): void {
		this.value = this.selectedData;
		if (!this.dropDownDataComponent) {
			throw new Error('Dropdown Component must be provided');
		}
		if (!this.findIndex) {
			throw new Error('findIndex must be provided');
		}
		const { isRequired, isOptional, disabled, config } = this;

		// attach some config items that were passed down to this component
		this.config = {
			...config,
			isRequired,
			isOptional,
			disabled,
		};
	}

	private createDataComponentInjector(): PortalInjector {
		const injectorTokens = new WeakMap();
		if (this.formGroup) {
			injectorTokens.set(MULTISELECT_FORM_GROUP, this.formGroup);
		}
		injectorTokens.set(ADDITIONAL_OPTIONS, this.additionalOptions ? this.additionalOptions : {});
		injectorTokens.set(MULTISELECT_DATA, this._data);
		injectorTokens.set(MULTISELECT_DATA_CLICK_CALLBACK, this.dataClickCallback);
		injectorTokens.set(MULTISELECT_DATA_FILTER, this.filter.asObservable());
		injectorTokens.set(MULTISELECT_DATA_CONFIG, this.config);

		return new PortalInjector(this.injector, injectorTokens);
	}

	private createChipMoreInjector(overlayRef): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(MULTISELECT_DATA, this.selectedData.slice(this.maxChipAllowed) || []);
		injectorTokens.set(MULTISELECT_CHIP_CLICK_CALLBACK, this.chipClickCallback);
		injectorTokens.set(MULTISELECT_CHIP_MORE_REF, overlayRef);

		return new PortalInjector(this.injector, injectorTokens);
	}
}
