export enum IUserType {
	STANDARD = 'standard',
	REMOTE = 'remote',
}

export enum LoginType {
	IMPERSONATE = 'impersonate',
	NEW_USER = 'new_user',
	STANDARD = 'standard',
}

export enum SIGN_IN_GRANT_TYPE {
	PASSWORD = 'password',
	CODE = 'code',
}
