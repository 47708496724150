import { Component, HostListener, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { ApiAccountsService, ApiUsersService, AuthService } from '@app/services';
import { EditTeamMemberComponent } from '@app/pages/teammembers/edit-team-member/edit-team-member.component';
import { DialogService } from '@n2p';
import { POPUP_CLICK_CALLBACK } from '@n2p/popup/popup.injectors';
import { IUser } from '@app/services/web-apis/users/api-users.domain';
import { IAccount } from '@app/services/web-apis/accounts/api-accounts.domain';

@Component({
	selector: 'n2p-profile-popup',
	templateUrl: './profile-popup.component.html',
	styleUrls: ['./profile-popup.component.scss'],
})
export class ProfilePopupComponent implements OnDestroy {
	user: IUser;
	accountInfo: IAccount;
	subscriptions: Subscription = new Subscription();

	constructor(
		@Inject(POPUP_CLICK_CALLBACK) private popupClicked: any,
		private dialogService: DialogService,
		private authService: AuthService,
		private userService: ApiUsersService,
		private accountsApi: ApiAccountsService,
	) {}

	@HostListener('click', ['$event']) onClick(ev): void {
		ev.stopPropagation();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	editUser(): void {
		this.subscriptions.add(this.accountsApi.account$.subscribe(value => (this.accountInfo = value)));
		this.clicked();
		this.userService.getUser().subscribe(({ data }) => {
			this.user = data;
			this.dialogService.create(
				EditTeamMemberComponent,
				{ ...data, canCreateDidlessUsers: this.accountInfo.canCreateDidlessUsers },
				{
					width: 800,
				},
			);
		});
	}

	signOut(): void {
		this.clicked();
		this.authService.signOut();
	}

	clicked(reloadUser: boolean = false): void {
		this.popupClicked(reloadUser);
	}
}
