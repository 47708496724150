import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import {
	IBlockedNumber,
	IEventBlockNumberBase,
	IEventBlockNumber,
	IEventUnblockNumber,
} from '@app/services/web-apis/call-blocking/api-call-blocking.domain';
import { GlobalEventsService } from '@app/services';
import { CallBlockingHelperService } from '@app/services/call-blocking-helper/call-blocking-helper.service';
import { ApiCallBlockingService } from '@app/services/web-apis/call-blocking/api-call-blocking.service';
import { PhoneNumberFormatterPipe } from '@app/pipes';

@Component({
	selector: 'app-block-caller',
	templateUrl: './block-caller.component.html',
	styleUrls: ['./block-caller.component.scss'],
	providers: [PhoneNumberFormatterPipe],
})
export class BlockCallerComponent implements OnInit, OnDestroy {
	private subscription: Subscription = new Subscription();
	blockingData: IEventBlockNumber;
	unblockingData: IEventUnblockNumber;

	loading: boolean;

	constructor(
		private globalEventsService: GlobalEventsService,
		private callBlockingService: ApiCallBlockingService,
		private callBlockingHelperService: CallBlockingHelperService,
		private phoneNumberFormatter: PhoneNumberFormatterPipe,
	) {}

	ngOnInit(): void {
		this.subscription.add(
			this.globalEventsService.blockCallerEvent.subscribe(data => {
				this.blockingData = {
					...data,
					phoneNumber: this.phoneNumberFormatter.transform({
						number: data.phoneNumber,
						emptyOnInvalid: false,
						numberFormat: 'E.164',
					}),
				};
			}),
		);
		this.subscription.add(
			this.globalEventsService.unblockCallerEvent.subscribe(data => {
				this.unblockingData = data;
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	closeBlockingPopup(closeType: 'success' | 'fail' | 'cancel', number?: IBlockedNumber): void {
		BlockCallerComponent.callPopupAction(closeType, this.blockingData, number);
		this.blockingData = undefined;
	}

	closeUnblockingPopup(closeType: 'success' | 'fail' | 'cancel', number?: IBlockedNumber): void {
		BlockCallerComponent.callPopupAction(closeType, this.unblockingData, number);
		this.unblockingData = undefined;
	}

	unblockCall(): void {
		this.loading = true;

		this.callBlockingService.deleteBlockedNumbers([this.unblockingData.blockedNumber]).subscribe(
			() => {
				this.unblockingData.onSuccess && this.unblockingData.onSuccess(this.unblockingData.blockedNumber);
				this.loading = false;
				this.closeUnblockingPopup('success');
			},
			err => {
				this.loading = false;
				this.closeUnblockingPopup('fail');
			},
		);
	}

	private static callPopupAction(
		closeType: 'success' | 'fail' | 'cancel',
		data: IEventBlockNumberBase,
		number?: IBlockedNumber,
	): void {
		if (closeType === 'success' && number && data.onSuccess) {
			data.onSuccess(number);
		}
		if (closeType === 'fail' && data.onFail) {
			data.onFail();
		}
		if (closeType === 'cancel' && data.onCancel) {
			data.onCancel();
		}
	}
}
