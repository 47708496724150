import { Component, Inject, Input } from '@angular/core';

import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { DialogRef } from '@n2p/dialog/dialogRef';
import { DialogGenericConfirmData } from '@n2p/dialog/dialog-generic-confirm/dialog-generic-confirm.domain';

@Component({
	selector: 'n2p-dialog-generic-confirm',
	templateUrl: './dialog-generic-confirm.component.html',
	styleUrls: ['./dialog-generic-confirm.component.scss'],
})
export class DialogGenericConfirmComponent {
	constructor(
		@Inject(DIALOG_DATA) public data: DialogGenericConfirmData,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
	) {}

	confirm(): void {
		this.dialogRef.dismiss(true);
	}

	cancel(): void {
		this.dialogRef.dismiss(false);
	}

	change(value: boolean): void {
		this.dialogRef.onChange.emit(value);
	}
}
