import { AvailableFeatures } from '@app/services/feature-flags/features.domain';

export type SupportedLanguageCode = 'en' | 'es' | 'pt' | 'es-AR' | 'en-CA' | 'fr-CA' | 'fr';

export const DEFAULT_LANGUAGE: SupportedLanguageCode = 'en';

export const SUPPORTED_LANGUAGE_VALUES: SupportedLanguageCode[] = [
	DEFAULT_LANGUAGE,
	'pt',
	'es',
	'es-AR',
	'en-CA',
	'fr-CA',
	'fr',
];

export const SUPPORTED_LANGUAGE_VALUES_FOR_SELECTION: SupportedLanguageCode[] = SUPPORTED_LANGUAGE_VALUES.filter(
	language => !language.includes('-'),
);

export const SUPPORTED_LANGUAGES_FEATURE_FLAGS: {
	[language: string]: AvailableFeatures;
} = {
	es: 'UILanguageSpanish',
	pt: 'UILanguagePortuguese',
	fr: 'UILanguageFrench',
};

export const UI_LANGUAGE_STORAGE_KEY = 'ui_language_code';
