import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { Observable, combineLatest } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { map, take } from 'rxjs/operators';
import { PhoneNumber } from 'libphonenumber-js/types';

import { ApiAccountsService, ApiUsersService } from '@app/services';
import { IAccount } from '@app/services/web-apis/accounts/api-accounts.domain';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { BRAZIL, MEXICO } from '@app/Common';

@Component({
	selector: 'app-phone-number-form',
	templateUrl: './phone-number-form.component.html',
	styleUrls: ['./phone-number-form.component.scss'],
})
export class PhoneNumberFormComponent implements OnInit, OnDestroy {
	@Output() onSubmit: EventEmitter<string> = new EventEmitter();
	@Output() onCancel: EventEmitter<string> = new EventEmitter();
	@Output() onDelete: EventEmitter<string> = new EventEmitter();

	@Input() submitBtnText: string = this.translate.instant('PHONE_NUMBER_FORM.SUBMIT_BTN');
	@Input() showDeleteButton: boolean = false;
	@Input() addPositiveStyle: boolean;
	@Input() label: string = this.translate.instant('PHONE_NUMBER_FORM.LABEL');
	@Input() initValue?: string;
	@Input() isClosable: boolean = false;
	@Input() forwardingNumberlist: string[] = [];
	@Input() restrictToAccountCountry: boolean;
	@Input() isValidateNationalFormat: boolean;
	@Input() loading: boolean;
	@Input() required: boolean = true;
	@Input() restrictedPhoneNumbers: string[] = [];

	lastSumbit: 'submit' | 'delete' = 'submit';

	public accountCountry$: Observable<CountryCode> = this.accountService.account$.pipe(
		map((accountInfo: IAccount) => (accountInfo.country || 'US') as CountryCode),
	);

	formGroup: FormGroup;

	isInFocus: boolean = false;
	isForceRestrictToAccountCountry$: Observable<boolean> = this.apiTenantService.isAccountCountries$([
		...MEXICO,
		...BRAZIL,
	]);

	private subscription: Subscription = new Subscription();

	private currentAccountCountry: CountryCode | '' = '';

	private currentParsedPhoneNumber: PhoneNumber | undefined;

	constructor(
		private fb: FormBuilder,
		private translate: TranslateService,
		private accountService: ApiAccountsService,
		private apiTenantService: ApiTenantService,
	) {}

	ngOnInit(): void {
		this.formGroup = this.fb.group({
			phoneNumber: new FormControl('', this.required && Validators.required),
		});

		this.subscription = combineLatest([
			this.formGroup.get('phoneNumber').valueChanges,
			this.accountCountry$,
			this.isForceRestrictToAccountCountry$,
		]).subscribe(([phoneNumberChanges, countryCode, forceRestrictToAccountCountry]) => {
			this.currentAccountCountry = countryCode;
			this.formGroup.markAsTouched();

			if (forceRestrictToAccountCountry || this.restrictToAccountCountry) {
				this.currentParsedPhoneNumber = this.validateCountryRestrictedNumber(phoneNumberChanges, countryCode);
			} else {
				this.currentParsedPhoneNumber = this.validateInternationalNumber(phoneNumberChanges);
			}
			if (this.restrictedPhoneNumbers.length > 0) {
				this.validateOnPhonesRestrictions(phoneNumberChanges);
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	submit(e: Event): void {
		e.preventDefault();
		this.lastSumbit = 'submit';
		if (this.formGroup.valid) {
			this.accountCountry$.pipe(take(1)).subscribe(accountCountry => {
				switch (accountCountry) {
					case 'BR':
					case 'MX':
						return this.onSubmit.emit(this.currentParsedPhoneNumber.number);
					default:
						this.onSubmit.emit(this.formGroup.value.phoneNumber);
				}
			});
		}
	}

	cancel(e: Event): void {
		e.preventDefault();
		this.onCancel.emit();
	}

	delete(e: Event): void {
		e.preventDefault();
		this.lastSumbit = 'delete';
		this.onDelete.emit();
	}

	toggleFocus(): void {
		this.isInFocus = !this.isInFocus;
	}

	isDuplicateNumber(): boolean {
		return this.formGroup.invalid && this.formGroup.get('phoneNumber').errors.isDuplicateNumber;
	}

	hasRestrictedPhoneNumber(): boolean {
		return this.formGroup.invalid && this.formGroup.get('phoneNumber').errors.hasRestrictedPhoneNumber;
	}

	getInvalidMessage(): string {
		if (!this.formGroup.invalid || !this.formGroup.touched) {
			return '';
		} else {
			if (this.isDuplicateNumber()) {
				return this.translate.instant('PHONE_NUMBER_FORM.DUPLICATE_NUMBER');
			}
			if (this.hasRestrictedPhoneNumber()) {
				return this.translate.instant('PHONE_NUMBER_FORM.USER_PHONE_NUMBER');
			} else {
				return this.restrictToAccountCountry
					? this.translate.instant('PHONE_NUMBER_FORM.DESC', {
							countryCode: this.currentAccountCountry,
					  })
					: this.translate.instant('PHONE_NUMBER_FORM.INVALID');
			}
		}
	}

	private validateCountryRestrictedNumber(value: string, countryCode: CountryCode): PhoneNumber | undefined {
		const control = this.formGroup.get('phoneNumber');
		if (!this.required && !value && control.valid) return undefined;

		// Looking for a duplicated number
		if (this.forwardingNumberlist.some(i => i === value)) {
			control.setErrors({ isDuplicateNumber: true });
		}

		// Parsing phone number with libphonenumber and hanlding its exception
		let phoneNumber;
		try {
			phoneNumber = parsePhoneNumber(value, countryCode);
		} catch (error) {
			control.setErrors({ invalidNumber: true });
			return phoneNumber;
		}

		switch (countryCode) {
			case 'BR': {
				if (!phoneNumber.isValid() || phoneNumber.nationalNumber !== value) {
					control.setErrors({ invalidNumber: true });
				}
				return phoneNumber;
			}
			case 'MX':
			default: {
				if (!phoneNumber.isValid() || phoneNumber.country !== countryCode) {
					control.setErrors({ invalidNumber: true });
				}
				return phoneNumber;
			}
		}
	}

	private validateInternationalNumber(value: string): PhoneNumber | undefined {
		const control = this.formGroup.get('phoneNumber');
		if (!this.required && !value && control.valid) return undefined;

		try {
			const phoneNumber = parsePhoneNumber(value);
			const valid = phoneNumber.isValid();
			// libphonenumber-js treats +11[0-9]{10} or +111[0-9]{10} as valid, hence additional check
			const usInvalid = valid && value.startsWith('+1') && value.length >= 13;
			if (!valid || usInvalid) {
				control.setErrors({ invalidNumber: true });
			}

			if (this.forwardingNumberlist.some(i => i === value)) {
				control.setErrors({ isDuplicateNumber: true });
			}

			return phoneNumber;
		} catch (ex) {
			control.setErrors({ invalidNumber: true });
		}
	}

	private validateOnPhonesRestrictions(value: string): void {
		const errorName = 'hasRestrictedPhoneNumber';
		const control = this.formGroup.get('phoneNumber');
		const errors = control.errors;

		const hasUserLineId = this.restrictedPhoneNumbers.some((phone: any) => phone === value.replace(/^\+/, ''));
		if (hasUserLineId) {
			control.setErrors({ ...errors, [errorName]: true });
		} else {
			if (errors) {
				delete errors[errorName];
				control.setErrors(errors);
			}
		}
	}
}
