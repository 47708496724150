import { Component, EventEmitter, Input, Output } from '@angular/core';

import { getInitialsFromString } from 'utils/helpers/dropdown';

@Component({
	selector: 'app-shared-tag',
	templateUrl: './tag.component.html',
	styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
	@Input() iconWidth = 14;
	@Input() iconHeight = 14;
	@Input() tagWidth = 30;
	@Input() tagHeight = 30;
	@Input() removable = true;
	@Input() text: string;
	@Input() type: string;
	@Input() iconName: string;
	@Input() iconFill: string;
	@Input() wrapperBackground = '#e5e5e5';
	@Input() iconBackground: string;
	@Input() iconOnly: boolean;
	@Input() imgUrl: string;
	@Input() data: any;
	@Input() hideAvatar = false;
	@Input() initials: string;
	@Input() showVoicemailIcon: boolean;

	@Output() closeCallback: EventEmitter<any> = new EventEmitter();

	getInitialsFromString: Function = getInitialsFromString;

	handleClose(event): void {
		this.closeCallback.emit(this.data);
		event.stopPropagation();
	}

	get getInitials(): any {
		if (this.text && !this.initials) {
			this.initials = this.getInitialsFromString(this.text, this.type);
		}

		return this.initials;
	}
}
