import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvgModule } from '@n2p/svg';
import { PopupComponent } from './popup.component';
import { PopupDirective } from './popup.directive';

@NgModule({
	declarations: [PopupComponent, PopupDirective],
	imports: [CommonModule, OverlayModule, PortalModule, SvgModule],
	entryComponents: [PopupComponent],
	exports: [PopupDirective],
})
export class PopupModule {}
