import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs';

import { IUserRole } from '@app/services/web-apis/users/api-users.domain';
import { AuthStorageData } from '@app/services/token';

@Injectable()
export class CommonService {
	// TODO replace with user service. All "Common" are really user related
	/*Global variables declaration */
	// public userToken: string;
	webRtcEnabled: boolean;

	toggleDialerSub: Subject<void> = new Subject();

	// TODO refactor only 2 components use this
	/* Data transfer from one component to antoher component  */
	private messageSource = new BehaviorSubject<any>('');
	currentMessage = this.messageSource.asObservable();

	constructor(private authStorageDataService: AuthStorageData) {}

	get userId(): string | undefined {
		return this.authStorageDataService.userId;
	}

	changeMessage(message: any) {
		this.messageSource.next(message);
	}

	/* Determine if the logged in user is an admin*/
	isAdmin(): boolean {
		return this.userRole === 'admin';
	}

	get userRole(): IUserRole {
		return this.authStorageDataService.userRole as IUserRole;
	}
}
