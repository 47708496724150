import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isSameDay } from 'n2p-ui-library/utils/date.util';

import { DateFormatterService, FORMATS } from '@app/services/date-formatter/date-formatter.service';

@Component({
	selector: 'date-range-picker',
	templateUrl: './date-range-picker.component.html',
	styleUrls: ['./date-range-picker.component.scss'],
	// This is fixes bug: https://idtjira.atlassian.net/browse/N2P2-2235
	// TODO: investigate, what exactly calls so many updates, and provide better and more reliable solution
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent implements OnInit {
	dayNames: Array<String> = [];
	constructor(private translate: TranslateService, private dateFormatter: DateFormatterService) {}

	@Input() selectedStart: Date;
	@Input() selectedEnd: Date;
	@Input() viewYear: number = new Date().getFullYear();
	@Input() viewMonth: number = new Date().getMonth();
	@Input() maxEnd: any;
	@Input() maxStart: any;
	@Input() showToggle: boolean;
	@Input() rangeSingleToggle: 'range' | 'single' = 'range';
	@Input() singleDaysSelected: Array<any> = [];

	@Output() rangeChange = new EventEmitter();

	ngOnInit(): void {
		this.dayNames = this.dateFormatter.weekdays;
	}

	get maxEndDate(): Date {
		return new Date(new Date(this.maxEnd).setHours(0, 0, 0, 0));
	}

	get leftMonthName(): string {
		return this.dateFormatter.format(new Date(this.viewYear, this.viewMonth), FORMATS.MONTH_OF_YEAR_MED);
	}

	get rightMonthName(): string {
		const month = this.viewMonth + 1 > 11 ? 0 : this.viewMonth + 1;
		const year = this.viewMonth + 1 > 11 ? this.viewYear + 1 : this.viewYear;
		return this.dateFormatter.format(new Date(year, month), FORMATS.MONTH_OF_YEAR_MED);
	}

	get monthLeft(): Array<any> {
		return this.dayList(this.viewYear, this.viewMonth, this.selectedStart, this.selectedEnd, this.maxEnd);
	}

	get monthRight(): Array<any> {
		const year = this.viewMonth + 1 > 11 ? this.viewYear + 1 : this.viewYear;
		const month = this.viewMonth + 1 > 11 ? 0 : this.viewMonth + 1;
		return this.dayList(year, month, this.selectedStart, this.selectedEnd, this.maxEnd);
	}

	get nextMonthAvailable(): boolean {
		return !this.maxEnd || this.viewMonth < this.maxEndDate.getMonth();
	}

	/*
		generate a calendar
	*/
	dayList(year: number, month: number, selectedStart?: any, selectedEnd?: any, maxEnd?: any): Array<any> {
		const firstDayOfMonth = new Date(year, month, 1, 0, 0, 0, 0);
		let viewMonth = month,
			days = [[], [], [], [], [], []];

		for (let i = 0; i < 42; i++) {
			let week = Math.floor(i / 7),
				offset = firstDayOfMonth.getDay(),
				dayObj: any = {},
				startDiff,
				endDiff;
			const day = new Date(year, viewMonth, i + 1 - offset, 0, 0, 0, 0);
			const start = new Date(selectedStart).setHours(0, 0, 0, 0);
			const end = new Date(selectedEnd).setHours(0, 0, 0, 0);

			if (selectedStart) {
				dayObj.selectedStart = start === day.getTime();
			}

			if (selectedEnd) {
				dayObj.selectedEnd = end === day.getTime();
			}

			if (selectedStart && selectedEnd) {
				dayObj.highlighted = day.getTime() >= start && day.getTime() <= end;
			}

			if (maxEnd) {
				dayObj.disabled = this.maxEndDate.getTime() - day.getTime() < 0;
			}

			dayObj = {
				...dayObj,
				number: day.getDate(),
				ref: day,
				inMonth: day.getMonth() === viewMonth,
			};
			days[week].push(dayObj);
		}
		return days;
	}

	/*
		select a new day
	*/
	selectDay(day: any): void {
		switch (this.rangeSingleToggle) {
			case 'range': {
				this.selectRangeDay(day);
				break;
			}
			case 'single': {
				this.selectSingleDay(day);
				break;
			}
			default:
				throw Error(`Ivalid date range picker toggle value!`);
		}
	}

	nextMonth(): void {
		if (this.maxEnd && this.viewMonth >= this.maxEndDate.getMonth()) {
			return;
		}
		if (this.viewMonth + 1 > 11) {
			this.viewMonth = 0;
			this.viewYear += 1;
		} else this.viewMonth += 1;
	}

	prevMonth(): void {
		if (this.viewMonth - 1 < 0) {
			this.viewMonth = 11;
			this.viewYear -= 1;
		} else this.viewMonth -= 1;
	}

	dayIsSelected(day): boolean {
		return this.singleDaysSelected.findIndex(i => isSameDay(new Date(i), new Date(day))) !== -1;
	}

	toggleRangeSingleBtnGroup(selection): void {
		this.rangeSingleToggle = selection;
		this.singleDaysSelected = [];
		this.selectedStart = undefined;
		this.selectedEnd = undefined;
		this.rangeChange.emit([]);
	}

	private selectRangeDay(day): void {
		if (!this.selectedStart) {
			this.selectedStart = day;
			this.selectedEnd = undefined;

			return;
		}

		if (this.selectedEnd) {
			// compare with maxStart
			this.selectedEnd = undefined;
			this.selectedStart = day;

			return;
		}

		// out of acceptable range
		if (this.maxEnd && this.maxEndDate.getTime() - day.getTime() < 0) {
			return;
		}

		// dont allow selected an end before the current start
		if (day.getTime() - this.selectedStart.getTime() < 0) {
			return;
		}
		// compare with max end
		this.selectedEnd = day;
		this.rangeChange.emit({
			startDate: this.selectedStart,
			endDate: this.selectedEnd,
		});
	}

	private selectSingleDay(day): void {
		const index = this.singleDaysSelected.findIndex(i => isSameDay(new Date(i), new Date(day)));
		if (index === -1) this.singleDaysSelected.push(day);
		else this.singleDaysSelected.splice(index, 1);
		this.rangeChange.emit(this.singleDaysSelected);
	}
}
