import { Component, Inject, OnInit } from '@angular/core';

import { INPUT_ERROR_MESSAGE, INPUT_ERROR_PROP_NAME } from '@n2p/input/input.injectors';

@Component({
	selector: 'n2p-input-field-error-required',
	templateUrl: './input-field-error-required.component.html',
	styleUrls: ['./input-field-error-required.component.scss'],
})
export class InputFieldErrorRequiredComponent implements OnInit {
	propName: string;
	errorMessage: string;
	constructor(@Inject(INPUT_ERROR_PROP_NAME) errorPropName: string, @Inject(INPUT_ERROR_MESSAGE) errorMessage: string) {
		this.propName = errorPropName;
		this.errorMessage = errorMessage;
	}

	ngOnInit(): void {}
}
