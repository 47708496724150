import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SchedulesComponent } from '@app/pages/schedules/schedules.component';

@NgModule({
	imports: [CommonModule, RouterModule.forChild([{ path: '', component: SchedulesComponent }])],
	declarations: [],
	exports: [RouterModule],
})
export class SchedulesRoutingModule {}
