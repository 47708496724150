// tslint:disable:no-console
import { environment } from '@env/environment';
import { getStorageOutsideOfAngular } from '@utils/helpers/impersonation';

const consoleUserInfo = (): void => {
	const localOrSessionStorage = getStorageOutsideOfAngular();
	const userInfo = {
		environment: environment.env,
		accountId: localOrSessionStorage.getItem('account_id'),
		userId: localOrSessionStorage.getItem('user_id'),
		tabId: sessionStorage.getItem('tab_id'),
		browserId: localStorage.getItem('browser_id'),
		messengerAccountId: localOrSessionStorage.getItem('messenger_account_id'),
	};

	console.log('%c Session information: ', 'color: #0095ff; font-size: 32px;');

	for (let property in userInfo) {
		console.log(
			'%c * ' + property + ': ' + '%c' + userInfo[property],
			'color: #ff9800; font-size: 14px; margin: 3px 0',
			'color: #cceaff; font-size: 13px;',
		);
	}
};

export default consoleUserInfo;
