import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ICdr } from '@app/services/callhistory/callhistory.domain';
import { ApiUsersService, FeatureFlagsService } from '@app/services';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { ARGENTINA, BRAZIL, MEXICO } from '@app/Common';
import { environment } from '@env/environment';

@Component({
	selector: 'app-details-from',
	templateUrl: './details-from.component.html',
	styleUrls: ['./details-from.component.scss'],
})
export class DetailsFromComponent {
	@Input() call: ICdr;

	public isCountryWithSimpleFormatting$: Observable<boolean> = this.apiTenantService.isAccountCountries$([
		...BRAZIL,
		...MEXICO,
		...ARGENTINA,
	]);

	shouldShowClickToCall$: Observable<boolean> = combineLatest([
		this.apiUsersService.user$.pipe(map(user => user.userId)),
		this.featureFlagsService.callHistoryClickToCall(),
		of(environment.is_admin_app),
		of(environment.is_pam_console),
	]).pipe(
		map(([currentUserId, featureEnabled, isAdminApp, isPamConsole]) => {
			const from = this.call && this.call.from;
			return !isAdminApp && !isPamConsole && from && featureEnabled && (!from.userId || currentUserId !== from.userId);
		}),
	);

	constructor(
		private apiUsersService: ApiUsersService,
		private featureFlagsService: FeatureFlagsService,
		private apiTenantService: ApiTenantService,
	) {}

	get callRules(): any {
		const { call } = this;
		const shouldShowExt =
			call.from.number && call.from.number.length <= this.apiTenantService.accountMaxExtensionLength;
		const shouldShowDirect = !shouldShowExt && call.from.userDisplayName;
		const shouldShowSpecialExtension = call.from.userBusinessClass && call.from.userBusinessClass !== 'US';
		// extension is in from.number
		const shouldShowNum = shouldShowExt || shouldShowDirect || call.from.callerId;
		const shouldShowCallerId = !shouldShowDirect && !shouldShowExt && !!call.from.callerId;

		return {
			top: {
				shouldShowSpecialExtension,
			},
			bot: {
				shouldShow: shouldShowNum || shouldShowExt,
				shouldShowDirect,
				shouldShowNum,
				shouldShowExt,
				shouldShowCallerId,
			},
		};
	}
}
