import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

import { DIALOG_DATA, DIALOG_REF } from '@utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { AccountService } from '@app/services/account/account.service';
import { getMultiDropDownDataType } from '@utils/helpers/functions';
import { PhoneNumberService } from '@app/services/phone-number/phone-number.service';
import { ForwardCallsToDataComponent } from '@app/shared/forward-calls-to/forward-calls-to-data/forward-calls-to-data.component';
import { ForwardCallsToChipComponent } from '@app/shared/forward-calls-to/forward-calls-to-chip/forward-calls-to-chip.component';

@Component({
	selector: 'app-remove-account-item-dialog',
	templateUrl: './remove-account-item-dialog.component.html',
	styleUrls: ['./remove-account-item-dialog.component.scss'],
})
export class RemoveAccountItemDialogComponent implements OnInit {
	readonly unassignedType = {
		selected: true,
		item: {
			number: '',
		},
		type: 'unassigned',
	};
	individually = false;
	data: Array<any> = [];
	config = { showUnassignedNumber: true, showAddForwardingNumber: false, showDeleteNumber: true };
	accountItem: any;
	formGroup: FormGroup;
	dataComponent: any = ForwardCallsToDataComponent;
	chipComponent: any = ForwardCallsToChipComponent;
	isDeleting = false;
	constructor(
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		@Inject(DIALOG_DATA) dialogData: { accountItem: any },
		private fb: FormBuilder,
		private accountService: AccountService,
		private phoneNumberService: PhoneNumberService,
	) {
		this.accountItem = dialogData.accountItem;
	}

	buildFormGroupArray(): Array<FormGroup> {
		return this.accountItem.lines.map(i => {
			const number = i.lineId;
			const fg = this.fb.group({});
			fg.addControl(number, new FormControl([this.unassignedType]));

			return fg;
		});
	}

	findIndex = (selected: Array<any>, data) => {
		return selected.findIndex(i => i.item['id'] === data.item['id']);
	};

	toggleIndividually(): void {
		this.individually = !this.individually;
	}

	buildModel(selected, line): any {
		let model;
		const phone = {};
		phone['number'] = line.lineId;
		phone['routeToId'] = selected.item['id'];
		phone['routeType'] = getMultiDropDownDataType(selected.type);
		if (selected.type === 'tm') {
			const userInfo = selected.item.userInfo;
			if (userInfo) {
				phone['routesTo'] = selected.item.userInfo.name;
				phone['userInfo'] = selected.item.userInfo;
			} else {
				phone['routesTo'] = `${selected.item.firstName} ${selected.item.lastName}`;
				// tslint:disable-next-line:no-null-keyword
				phone['userInfo'] = null;
			}
			model = { ...phone, routesTo: phone['routeToId'].toString() };
		} else if (selected.type === 'unassigned') {
			phone['routeType'] = '';
			// tslint:disable-next-line:no-null-keyword
			phone['routesTo'] = null;
			// tslint:disable-next-line:no-null-keyword
			phone['routeToId'] = null;
			// tslint:disable-next-line:no-null-keyword
			phone['userInfo'] = null;
			model = { number: phone['number'], status: 'A', routesTo: 'disconnect' };
		} else {
			phone['routesTo'] = selected.item.name;
			// tslint:disable-next-line:no-null-keyword
			phone['userInfo'] = null;
			model = { ...phone, routesTo: phone['routeToId'].toString() };
		}

		return model;
	}

	selectedChanged(data): void {
		this.data[4] = data;
	}

	remove(): void {
		this.isDeleting = true;
		const subscriptions = [];
		this.accountItem.lines.forEach(line => {
			let control;
			if (!this.individually) {
				control = this.formGroup.get('forwardCallsTo').value;
			} else {
				const formArray = this.formGroup.get('individually') as FormArray;
				control = formArray.controls.filter(i => i.get(line.lineId))[0].get(line.lineId).value;
			}

			if (control && control.length) {
				const selected = control[0];
				if (selected.type === 'delete') {
					subscriptions.push(this.phoneNumberService.delete(line.lineId));
				} else {
					const model = this.buildModel(selected, line);
					subscriptions.push(this.phoneNumberService.patch(model, true));
				}
			}
		});
		if (subscriptions.length) {
			Observable.forkJoin(subscriptions).subscribe(res => {
				this.isDeleting = false;
				this.dialogRef.dismiss(true);
			});
		} else {
			this.dialogRef.dismiss(true);
		}
	}

	cancel(): void {
		this.dialogRef.dismiss(false);
	}

	ngOnInit(): void {
		this.formGroup = this.fb.group({
			forwardCallsTo: [[this.unassignedType]],
			individually: this.fb.array(this.buildFormGroupArray()),
		});
		this.accountService
			.getConsolidatedEntities()
			.map(res =>
				res.data.reduce((accumulator, value) => {
					if (!accumulator[value.type]) {
						accumulator[value.type] = [];
					}
					accumulator[value.type].push(value);

					return accumulator;
				}, {}),
			)
			.subscribe(result => {
				this.filterData(result);
				this.data = [
					{ data: result.user },
					{ data: result.department },
					{ data: result.ringGroup },
					{ data: result.maa },
					{ data: result.specialExtension },
					this.unassignedType,
				];
			});
	}

	private filterData(data: any): void {
		if (data[this.accountItem.filteringType]) {
			data[this.accountItem.filteringType] = data[this.accountItem.filteringType].filter(
				i => i.id != this.accountItem.id,
			);
		}
	}
}
