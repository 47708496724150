import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { IDropdownAction, IDropdownOption } from 'n2p-ui-library/components/dropdown/dropdown.domain';
import { DropdownActionType } from 'n2p-ui-library/constants';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import {
	IAccountContact,
	IAccountContactMethod,
	IAccountContactType,
} from '@app/services/web-apis/contacts/api-contacts.domain';
import { ApiContactsService } from '@app/services/web-apis/contacts/api-contacts.service';
import { ApiAccountsService, SnackbarService } from '@app/services';
import { take, filter } from 'rxjs/operators';

@Component({
	selector: 'app-service-address-dropdown',
	templateUrl: './service-address-dropdown.component.html',
	styleUrls: ['./service-address-dropdown.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ServiceAddressDropdownComponent),
		},
	],
})
export class ServiceAddressDropdownComponent implements OnInit, ControlValueAccessor {
	@Input() required: boolean = true;
	isAddressesLoading: boolean = true;
	addressOptions: IDropdownOption[];
	addressActions: IDropdownAction[];
	isNewAddressPopup: boolean = false;
	selectedAddress: number;

	onTouchedFn: () => void;
	onChangeFn: (value: number) => void;

	constructor(
		private apiContactsService: ApiContactsService,
		private translate: TranslateService,
		private snackbarService: SnackbarService,
		private accountsService: ApiAccountsService,
	) {}

	ngOnInit(): void {
		this.isAddressesLoading = true;
		this.accountsService.account$
			.pipe(
				filter(val => Boolean(val)),
				take(1),
			)
			.subscribe(({ country }) => {
				this.addressActions =
					country !== 'CA'
						? [
								{
									title: this.translate.instant('ADD_TEAM_MEMBER_PAGE.ADD_NEW_ADDRESS'),
									type: DropdownActionType.PRIMARY,
								},
						  ]
						: [];
			});
		this.apiContactsService.getContacts(IAccountContactMethod.ADDRESS, IAccountContactType.SERVICE).subscribe(
			data => {
				if (data.length) {
					this.addressOptions = data.map(item => {
						return {
							title: this._getOptionTitle(item),
							value: item.id,
						};
					});
					this.selectedAddress || this.setSelectedAddress(<number>this.addressOptions[0].value);
				}

				this.isAddressesLoading = false;
			},
			() => {
				this.isAddressesLoading = false;
				this.snackbarService.createDanger(
					this.translate.instant('GLOBALS.FETCH_ERROR', {
						entity: this.translate.instant('PROFILE_PAGE.ADDRESS'),
					}),
				);
			},
		);
	}

	writeValue(value: number): void {
		this.selectedAddress = value;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedFn = fn;
	}

	registerOnChange(fn: any): void {
		this.onChangeFn = fn;
	}

	showNewAddressPopup(): void {
		this.isNewAddressPopup = true;
	}

	hideNewAddressPopup(): void {
		this.isNewAddressPopup = false;
	}

	private _getOptionTitle(address: IAccountContact): string {
		const { address1, address2, locality, zip, city, state } = address;
		return [
			address1 ? `${address1}, ` : '',
			address2 ? `${address2}, ` : '',
			locality ? `${locality} ` : '',
			`${city}, `,
			`${state}`,
			zip ? `, ${zip}` : '',
		].join('');
	}

	handleAddAddressSuccess(address: IAccountContact): void {
		this.addressOptions = this.addressOptions.concat([
			{
				title: this._getOptionTitle(address),
				value: address.id,
			},
		]);
		this.setSelectedAddress(address.id);
		this.hideNewAddressPopup();
	}

	setSelectedAddress(value: number): void {
		this.selectedAddress = value;
		this.onTouchedFn && this.onTouchedFn();
		this.onChangeFn && this.onChangeFn(this.selectedAddress);
	}
}
