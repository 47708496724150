import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formValueChanged',
})
export class FormValueChangedPipe implements PipeTransform {
	transform(formValue: any, initialValue: any): boolean {
		if (formValue && initialValue) {
			const keys = Object.keys(initialValue);

			for (let key of keys) {
				if (initialValue[key] !== formValue[key]) {
					return true;
				}
			}
		}

		return false;
	}
}
