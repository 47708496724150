import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { TranslateService } from '@ngx-translate/core';

import { DateFormatter } from 'n2p-ui-library/services/date-formatter/date-formatter.service';

import { ApiTimezoneService } from '@app/services/web-apis/timezone/api-timezone.service';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';

@Injectable({
	providedIn: 'root',
})
export class DateFormatterService {
	private formatterTz: DateFormatter;
	private formatterLocal: DateFormatter;
	private subscription: Subscription = new Subscription();

	private currentTimezone: string | undefined;

	constructor(
		private translateService: TranslateService,
		private timezoneService: ApiTimezoneService,
		private tenantService: ApiTenantService,
	) {
		this.createLocalFormatter(this.locale);
		this.createTzFormatter(this.locale);
		this.subscription.add(
			this.translateService.onLangChange.subscribe(() => {
				this.createLocalFormatter(this.locale);
				this.createTzFormatter(this.locale);
			}),
		);
		this.subscription.add(
			this.timezoneService.currentTimezone$.subscribe(({ format }) => {
				this.currentTimezone = format;
				this.createTzFormatter(this.locale, format);
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	get locale$(): Observable<string> {
		return this.translateService.onLangChange.pipe(map(() => this.locale));
	}

	get locale(): string {
		const lang = this.translateService.currentLang || this.translateService.getDefaultLang();
		const countryCode = this.tenantService.countryCode || 'US';

		if (lang.split('-').length > 1) return lang;

		return `${lang}-${countryCode}`;
	}

	get weekdays(): string[] {
		return this.formatterLocal.getWeekdaysShort();
	}

	get isMilitaryTime(): boolean {
		return this.formatterLocal.format(new Date().setHours(13, 0, 0, 0), FORMATS.TIME).split(':')[0] === '13';
	}

	format(date: Date | number | string, formatString: string): string {
		return date ? this.formatterLocal.format(date, formatString) : '';
	}

	formatTz(date: Date | number | string, formatString: string): string {
		return date ? this.formatterTz.format(date, formatString) : '';
	}

	getCustomFormatter(timeZone: string, locale?: string): DateFormatter {
		return new DateFormatter(locale !== undefined ? locale : this.formatterTz.locale, timeZone);
	}

	private createLocalFormatter(locale: string): void {
		try {
			if (!this.formatterLocal || this.formatterLocal.locale !== locale) {
				this.formatterLocal = new DateFormatter(locale);
			}
		} catch (e) {
			// In case of improper locale we create default 'en' formatter
			this.formatterLocal = new DateFormatter(this.translateService.getDefaultLang());
		}
	}

	private createTzFormatter(locale: string, timezone: string = this.currentTimezone): void {
		try {
			if (!this.formatterTz || this.formatterTz.locale !== locale || this.formatterTz.timeZone !== timezone) {
				this.formatterTz = new DateFormatter(locale, timezone);
			}
		} catch (e) {
			// In case of improper locale or timezone we create default 'en' + 'UTC' formatter
			this.formatterTz = new DateFormatter(this.translateService.getDefaultLang(), 'UTC');
		}
	}
}

export const FORMATS = DateFormatter.formats;
