import { Component, Inject, OnInit } from '@angular/core';

import { MULTISELECT_CHIP, MULTISELECT_CHIP_CLICK_CALLBACK } from 'utils/constants/injectortokens';
import { IPhone } from '@app/Common/interfaces/IPhone';

@Component({
	selector: 'app-assigned-numbers-chip',
	templateUrl: './assigned-numbers-chip.component.html',
	styleUrls: ['./assigned-numbers-chip.component.scss'],
})
export class AssignedNumbersChipComponent implements OnInit {
	phone: IPhone;
	clickCallback: any;
	removable = true;

	constructor(@Inject(MULTISELECT_CHIP) phone: IPhone, @Inject(MULTISELECT_CHIP_CLICK_CALLBACK) clickCallback: any) {
		this.phone = phone;
		// only assign a value if it is defined, otherwise an undefined value would be falsy
		if ((phone as any).removable !== undefined) {
			this.removable = (phone as any).removable;
		}
		this.clickCallback = clickCallback;
	}

	close = () => {
		this.clickCallback(this.phone);
	};

	ngOnInit() {}
}
