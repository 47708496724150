import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { DropdownService } from './dropdown.service';

interface ListItem {
	type: string;
	text: string;
	imgUrl?: string;
	iconName?: string;
	children?: ListItem[];
	parentList?: ListItem[];
	index: number;
	listId: string;
}

@Component({
	selector: 'shared-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	providers: [DropdownService],
})
export class DropdownComponent implements OnInit, OnDestroy {
	@Input() title: string;
	@Input() additionalTitle: string;
	@Input() placeholder: string;
	@Input() listPosition = 'bottom';
	@Input() active = false;
	@Input() data: ListItem;
	@Input() allowMultiSelect = false;
	@Input() addedListItems: ListItem[];
	@Input() scroll = false;
	@Input() defaultItemText: string;

	activeListItemIndex: number;

	@Output() isActive: EventEmitter<boolean> = new EventEmitter();
	@Output() itemsChanged: EventEmitter<any> = new EventEmitter();

	//Subscriptions
	dropDownServiceSub: Subscription;

	constructor(private dropdownService: DropdownService) {}

	ngOnInit() {
		if (!this.addedListItems) {
		}

		//set the service's array so it can add remove items
		this.dropdownService.addedListItems = this.addedListItems;
		this.dropdownService.defaultItemText = this.defaultItemText;

		//set the service's flag so it correctly adds/removes items
		this.dropdownService.allowMultiSelect = this.allowMultiSelect;

		this.dropDownServiceSub = this.dropdownService.addedListItemsSubject.subscribe((selectedItems: any) =>
			this.itemsChanged.emit(selectedItems),
		);
	}

	ngOnDestroy() {
		if (this.dropDownServiceSub) {
			this.dropDownServiceSub.unsubscribe();
		}
	}

	setActiveListItemIndex(event: any, index: number, item: ListItem) {
		//if item was clicked and it was already active, hide it, else switch item index
		this.activeListItemIndex = index !== this.activeListItemIndex ? index : undefined;
	}

	toggleActive(value: boolean) {
		if (value !== undefined) {
			this.active = value;
			this.isActive.emit(this.active);
		} else {
			this.active = !this.active;
			this.isActive.emit(this.active);
		}
		if (!this.active) {
			this.activeListItemIndex = undefined;
		}
	}
}
