import { NgModule } from '@angular/core';

import { CallBlockingConfirmPopupComponent } from '@n2p/call-blocking-confirm-popup/call-blocking-confirm-popup.component';
import { SharedModule } from '@app/shared';
import { SvgModule } from '@n2p';

@NgModule({
	declarations: [CallBlockingConfirmPopupComponent],
	exports: [CallBlockingConfirmPopupComponent],
	imports: [SharedModule, SvgModule],
})
export class CallBlockingConfirmPopupModule {}
