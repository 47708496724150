import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[app-numeric]',
})
export class NumericDirective {
	private decimalCounter = 0;
	private navigationKeys = [
		'Backspace',
		'Delete',
		'Tab',
		'Escape',
		'Enter',
		'Home',
		'End',
		'ArrowLeft',
		'ArrowRight',
		'Clear',
		'Copy',
		'Paste',
	];
	@Input() decimal? = false;
	inputElement: HTMLInputElement;

	constructor(public el: ElementRef) {
		this.inputElement = el.nativeElement;
	}

	@HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
		if (
			this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
			(this.decimal && e.key === '.' && this.decimalCounter < 1) || // Allow: only one decimal point
			(['a', 'c', 'v', 'x'].includes(e.key) && // Allow: Ctrl or Cmd + (A,C,V,X)
				e.ctrlKey === true) ||
			e.metaKey === true ||
			e.key === '+' // Allow: + sign for phone numbers
		) {
			// let it happen, don't do anything
			return;
		}
		// Ensure that it is a number and stop the keypress
		if (e.key === ' ' || isNaN(Number(e.key))) {
			e.preventDefault();
		}
	}

	@HostListener('keyup', ['$event']) onKeyUp(e: KeyboardEvent) {
		if (!this.decimal) {
			return;
		} else {
			this.decimalCounter = this.el.nativeElement.value.split('.').length - 1;
		}
	}

	@HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
		const pastedInput: string = event.clipboardData.getData('text/plain');
		let pasted = false;
		if (!this.decimal) {
			pasted = document.execCommand('insertText', false, pastedInput.replace(/[^0-9]/g, ''));
		} else if (this.isValidDecimal(pastedInput)) {
			pasted = document.execCommand('insertText', false, pastedInput.replace(/[^0-9.]/g, ''));
		}
		if (pasted) {
			event.preventDefault();
		}
	}

	@HostListener('drop', ['$event']) onDrop(event) {
		const textData = event.dataTransfer.getData('text');
		this.inputElement.focus();

		let pasted = false;
		if (!this.decimal) {
			pasted = document.execCommand('insertText', false, textData.replace(/[^0-9]/g, ''));
		} else if (this.isValidDecimal(textData)) {
			pasted = document.execCommand('insertText', false, textData.replace(/[^0-9.]/g, ''));
		}
		if (pasted) {
			event.preventDefault();
		}
	}

	isValidDecimal(string: string): boolean {
		return string.split('.').length <= 2;
	}
}
