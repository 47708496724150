import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { DateRangePickerComponent } from './date-range-picker.component';
import { ButtonGroupModule } from '@n2p/button-group';
import { SvgModule } from '@n2p/svg';

@NgModule({
	imports: [CommonModule, SvgModule, ButtonGroupModule, TranslateModule],
	declarations: [DateRangePickerComponent],
	exports: [DateRangePickerComponent],
})
export class DateRangePicker {}
