import { Component, HostBinding, Inject, OnInit } from '@angular/core';

import { MULTISELECT_DATA, MULTISELECT_DATA_CLICK_CALLBACK } from 'utils/constants/injectortokens';

@Component({
	selector: 'app-multi-select-data-simple-checked',
	templateUrl: './multi-select-data-simple-checked.component.html',
	styleUrls: ['./multi-select-data-simple-checked.component.scss'],
})
export class MultiSelectDataSimpleCheckedComponent implements OnInit {
	private prevSelected;

	@HostBinding('class.n2p-dropdown-data') style = false;
	constructor(
		@Inject(MULTISELECT_DATA) public items: any,
		@Inject(MULTISELECT_DATA_CLICK_CALLBACK) private callback: any,
	) {
		this.prevSelected = items.find(i => i.selected);
	}

	ngOnInit() {
		this.style = true;
	}

	public handleClick(item) {
		// dont unhighlight the highlighted one
		if (this.prevSelected.text !== item.text) {
			item.selected = !item.selected;
			this.callback(item);
		}
	}

	public trackItem = (index, item) => {
		return item && item.text;
	};
}
