import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InitialsComponentBackground } from 'n2p-ui-library/constants';

import { AddWelcomeMenuCancelPromptComponent } from './add-welcome-menu-cancel-prompt/add-welcome-menu-cancel-prompt.component';
import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { getEntityObjectByType } from 'utils/helpers/functions';
import {
	CacheService,
	DialogService,
	GlobalLoaderService,
	SnackbarService,
	AuthStorageData,
	WelcomeMenuService,
	FeatureFlagsService,
} from '@app/services';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-addwelcomemenu',
	templateUrl: './addwelcomemenu.component.html',
	styleUrls: ['./addwelcomemenu.component.scss'],
	providers: [GlobalLoaderService],
})
export class AddwelcomemenuComponent implements OnInit, OnDestroy {
	initialsBgColor: InitialsComponentBackground = InitialsComponentBackground.PRIMARY_DARK_1;
	formGroup: FormGroup;
	saving = false;
	@ViewChild('addWelcomeMenuHeader', { static: true }) header: ElementRef;
	@ViewChild('addWelcomeMenuBody', { static: true }) body: ElementRef;
	@ViewChild('addWelcomeMenuFooter', { static: true }) footer: ElementRef;
	@ViewChild('addWelcomeMenuRef', { static: true }) addWelcomeMenuRef: ElementRef;
	isScrolling = undefined;
	mouseIsDown = false;
	scrollAtTop = true;
	scrollAtBottom = false;
	private isExtensionValidating: boolean = false;
	oldDialogDismiss: any;
	private bounceMethod: any;
	allCompanyLabel: string;
	typesWithZeroDataId = ['repeat', 'phone'];
	initialExtension: null | string = null;
	showAdvancedWelcomeMenuConfiguration: Observable<boolean>;
	constructor(
		private fb: FormBuilder,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		@Inject(DIALOG_DATA) private dialogData: any,
		private welcomeMenuService: WelcomeMenuService,
		private cacheService: CacheService,
		private globalLoaderService: GlobalLoaderService,
		private dialogSerice: DialogService,
		private translate: TranslateService,
		private authStorageDataService: AuthStorageData,
		private translateService: TranslateService,
		private snackbarService: SnackbarService,
		private tenantService: ApiTenantService,
		private featureFlagService: FeatureFlagsService,
	) {
		this.allCompanyLabel = this.translateService.instant('SEND_SMS_TO.ALL');
		let wmName: string;
		this.dialogData && this.dialogData.welcomeMenuCount
			? (wmName = this.translate.instant('ADD_WELCOME_MENU.NEW_WM') + ` ${this.dialogData.welcomeMenuCount + 1}`)
			: (wmName = this.buildWMName());
		this.showAdvancedWelcomeMenuConfiguration = featureFlagService.isAdvancedWelcomeMenuConfigurationEnabled();
		this.formGroup = this.fb.group({
			id: [''],
			name: [wmName, [Validators.required, Validators.maxLength(30)]],
			assignedNumbers: [[]],
			menuOptionSelected: [true],
			menuId: [''],
			destinationListid: [''],
			extension: ['', Validators.required],
			destinationid: [''],
			options: this.fb.array([
				this.fb.group({
					key: new FormControl('timeout', [Validators.required]),
					forwardCallsTo: [[], [Validators.required]],
				}),
			]),
			greeting: [''],
			recordGreeting: [''],
			customGreeting: [''],
			greetingBase64: [''],
			greetingFile: [''],
			footer: [],
			sendSMSTo: [[{ value: this.allCompanyLabel, type: 'all-company' }]],
			transcription: [''],
			extensionDialingEnabled: [true],
			playPromptAfterSelectionEnabled: [true],
			bargingMenuPromptAllowed: [true],
		});
	}

	save(): void {
		this.saving = true;
		const wm = this.buildWMModel();
		this.doSaveAction(wm).then(
			(res: any) => {
				this.saving = false;
				this.oldDialogDismiss(res);
			},
			(res: any) => {
				let text: string;

				// get error message
				if (res.errorMessages.length) {
					if (res.errorMessages[0].code === '-10') {
						text = this.translateService.instant('ADD_WELCOME_MENU.ERROR_NAME_DUPLICATE');
					} else {
						text = this.translate.instant('GLOBALS.GENERAL_ERROR');
					}
				} else {
					text = this.translateService.instant('ADD_WELCOME_MENU.ERROR_UNKNOWN');
				}

				// show error
				this.snackbarService.create({
					status: 'danger',
					text,
					connectTo: this.addWelcomeMenuRef,
				});

				// stop loading
				this.saving = false;
			},
		);
	}

	cancel(): void {
		if (!this.formGroup.get('id').value) return;
		const dialogRef = this.dialogSerice.create(AddWelcomeMenuCancelPromptComponent, {
			width: 440,
			height: 278,
		});
		const loader = this.globalLoaderService.create(this.addWelcomeMenuRef);

		dialogRef.onDismiss().subscribe(res => {
			if (res) {
				loader.show();
				this.welcomeMenuService.removeWelcomeMenu({ id: this.formGroup.get('id').value }).then(_ => {
					this.oldDialogDismiss();
					loader.hide();
				});
			}
		});
	}

	ngOnInit(): void {
		this.formGroup.get('footer').setValue(this.footer);
		this.oldDialogDismiss = this.dialogRef.dismiss;
		this.dialogRef.dismiss = this.cancel.bind(this);

		// TODO make this a service
		this.body.nativeElement.addEventListener('mousedown', () => {
			this.mouseIsDown = true;
		});

		this.body.nativeElement.addEventListener('mouseup', () => {
			this.mouseIsDown = false;
			this.scrollingIsDone();
		});

		this.body.nativeElement.addEventListener('scroll', () => {
			clearTimeout(this.isScrolling);
			this.isScrolling = setTimeout(() => {
				if (!this.mouseIsDown) {
					this.scrollingIsDone();
				}
			}, 100);
		});

		this.formGroup.get('menuOptionSelected').valueChanges.subscribe(value => {
			if (!value) {
				this.formGroup.removeControl('options');
				this.formGroup.addControl('forwardCallsTo', new FormControl([], [Validators.required]));
			} else {
				this.formGroup.removeControl('forwardCallsTo');
				this.formGroup.addControl(
					'options',
					this.fb.array([
						this.fb.group({
							key: new FormControl(0, [Validators.required]),
							forwardCallsTo: [[], [Validators.required]],
						}),
					]),
				);
			}
		});

		const wm = {
			name: this.formGroup.get('name').value,
			lines: [],
			menu: {
				status: 'A',
				multiAutoAttendantMenuItem: [],
			},
		};
		const loader = this.globalLoaderService.create(this.addWelcomeMenuRef);
		loader.show();
		this.welcomeMenuService.addWelcomeMenu(wm).then(
			(res: any) => {
				this.formGroup.get('id').setValue(res.data.id);
				this.formGroup.get('menuId').setValue(res.data.menu.id);
				this.formGroup.get('destinationListid').setValue(res.data.menu.destinationListid);
				this.formGroup.get('extension').setValue(res.data.extension);
				this.formGroup.get('destinationid').setValue(res.data.menu.destinationid);
				loader.hide();
				this.initialExtension = res.data.extension;
				this.welcomeMenuService.getGreeting(res.data.id).subscribe(greet => {
					this.formGroup.get('greetingBase64').setValue(greet.data[0].file);
				});
				if (res.data.businessFeatures) {
					this.formGroup.get('extensionDialingEnabled').setValue(res.data.businessFeatures.extensionDialingEnabled);
					this.formGroup
						.get('playPromptAfterSelectionEnabled')
						.setValue(res.data.businessFeatures.playPromptAfterSelectionEnabled);
					this.formGroup.get('bargingMenuPromptAllowed').setValue(res.data.businessFeatures.bargingMenuPromptAllowed);
				}
			},
			(err: any) => {
				this.oldDialogDismiss({
					hasError: true,
					message: this.translate.instant('GLOBALS.GENERAL_ERROR'),
				});
			},
		);
	}

	extensionValidating(validating: boolean): void {
		this.isExtensionValidating = validating;
	}

	ngOnDestroy(): void {
		this.cacheService.delete('forward_calls_to_data');

		/*We have to clear cache here because the data updates often and create bugs.
		The profit of using it is boosting performance in case of multiple components on the page*/
		this.cacheService.delete('IF_CALL_NOT_ANSWER_KEY');
	}

	private buildWMName(counter = 1): any {
		const wms = this.welcomeMenuService.dataStore.welcomeMenus;
		const wmName = this.translate.instant('ADD_WELCOME_MENU.WM') + ` ${wms.length + counter}`;
		if (wms.find(i => String(i.name).toLowerCase() === String(wmName).toLowerCase())) {
			return this.buildWMName(counter + 1);
		}

		return wmName;
	}

	private buildWMModel(): any {
		const sendExtension = this.initialExtension !== this.formGroup.get('extension').value;
		return {
			id: this.formGroup.get('id').value,
			name: this.formGroup.get('name').value,
			// we should check if extension has been updated cause server will send an error if we will send
			// extension that already exists even in welcome menu that we are creating now
			...(sendExtension && { extension: this.formGroup.get('extension').value }),
			lines: this.formGroup
				.get('assignedNumbers')
				.value.filter(p => p !== 'unassigned')
				.map(n => {
					return {
						lineId: n,
						status: 'A',
					};
				}),
			menu: {
				smsDestination:
					!this.tenantService.accountCountryIsDidLess &&
					this.formGroup.get('sendSMSTo').value &&
					this.formGroup.get('sendSMSTo').value.length
						? this.formGroup.get('sendSMSTo').value.map(i => {
								return getEntityObjectByType(i, 'A', this.authStorageDataService.accountId);
						  })[0]
						: undefined,
				status: 'A',
				id: this.formGroup.get('menuId').value,
				destinationListid: this.formGroup.get('destinationListid').value,
				destinationid: this.formGroup.get('destinationid').value,
				multiAutoAttendantMenuItem: this.formGroup.get('options').value.map(i => {
					const entity = getEntityObjectByType(i.forwardCallsTo[0]);

					return {
						posAck: true,
						rings: 5,
						orderBy: Array.isArray(i.key) ? i.key[0].value : i.key,
						dataid: this.typesWithZeroDataId.includes(entity.type) ? 0 : 1,
						data: entity.data.toString(),
						type: entity.type,
						status: 'A',
						redirectToVoicemail: entity.type === 'repeat' ? false : i.forwardCallsTo[0].redirectToVoiceMail,
					};
				}),
				businessFeatures: {
					extensionDialingEnabled: this.formGroup.get('extensionDialingEnabled').value,
					playPromptAfterSelectionEnabled: this.formGroup.get('playPromptAfterSelectionEnabled').value,
					bargingMenuPromptAllowed: this.formGroup.get('bargingMenuPromptAllowed').value,
				},
			},
		};
	}

	private doSaveAction(wm): Promise<any> {
		return this.welcomeMenuService.editWelcomeMenu(wm);
	}

	private scrollingIsDone(): void {
		const scrollTop = this.body.nativeElement.scrollTop;
		const isAtBottom = scrollTop + this.body.nativeElement.clientHeight === this.body.nativeElement.scrollHeight;

		this.scrollAtTop = scrollTop === 0;

		this.scrollAtBottom = isAtBottom;
	}
}
