import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AssignedNumbersComponent } from './assigned-numbers.component';
import { DropdownModule } from '@n2p/dropdown/dropdown.module';

import { SvgModule } from '@n2p/svg';
import { DirectivesModule } from '@app/directives/directives.module';
import { PipesModule } from '@app/pipes';
import { IconModule } from '@n2p/icon';
import { ReassignNumbersPromptComponent } from '@n2p/assigned-numbers/components/reassign-numbers-prompt/reassign-numbers-prompt.component';
import { ExternalModule } from '@n2p/external/external.module';
import { ShowHideModule } from '@app/directives/show-hide/show-hide.module';

@NgModule({
	declarations: [AssignedNumbersComponent, ReassignNumbersPromptComponent],
	imports: [
		CommonModule,
		DropdownModule,
		ReactiveFormsModule,
		SvgModule,
		DirectivesModule,
		PipesModule,
		IconModule,
		TranslateModule,
		ExternalModule,
		ShowHideModule,
	],
	exports: [AssignedNumbersComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AssignedNumbersModule {}
