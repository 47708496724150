import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IScheduleRuleTimeSetting } from '@app/services/web-apis/schedules/api-schedules.domain';
import { DateFormatterService, FORMATS } from '@app/services';

@Pipe({
	name: 'scheduleTime',
})
export class ScheduleTimePipe implements PipeTransform {
	constructor(private translate: TranslateService, private dateFormatter: DateFormatterService) {}

	transform(time: IScheduleRuleTimeSetting | string, showAllDayString: boolean = true): string {
		return typeof time === 'string'
			? time
			: showAllDayString && this.isAllDay(time)
			? this.translate.instant('SCHEDULES_PAGE.ALL_DAY')
			: `${this.formatTime(time.start)} - ${this.formatTime(time.end)}`;
	}

	private isAllDay(time: IScheduleRuleTimeSetting): boolean {
		if (time.start && time.end) {
			const start = this.parseTime(time.start);
			const end = this.parseTime(time.end);
			return start.getHours() === 0 && start.getMinutes() === 0 && end.getHours() === 23 && end.getMinutes() === 59;
		}
		return false;
	}

	private parseTime(time: string): Date {
		const regex = /(\d{1,2}):(\d\d)(.+)?/;
		const [, hoursStr = '00', minutesStr = '00', amPm = 'am'] = time.match(regex) || [];
		const hours = parseInt(hoursStr, 10);
		const minutes = parseInt(minutesStr, 10);
		return new Date(
			new Date().setHours(
				amPm.toLowerCase().trim() === 'pm' ? (hours === 12 ? 12 : hours + 12) : hours === 12 ? 0 : hours,
				minutes,
				0,
				0,
			),
		);
	}

	private formatTime(time: string): string {
		return this.dateFormatter
			.format(this.parseTime(time), FORMATS.TIME)
			.replace(/\s/g, '')
			.toLowerCase();
	}
}
