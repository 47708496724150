import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
	transform(value: any, search?: string): any {
		if (!search) {
			return value;
		}

		return value.replace(search, `<mark class="highlight">${search}</mark>`);
	}
}
