import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '@app/services/api';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { IAudioContent, IAudioContentInput } from '@app/services/web-apis/audio/api-audio.domain';

@Injectable()
export class ApiAudioService {
	private baseUrl = '/accounts/{accountId}/users';

	constructor(private apiService: ApiService) {}

	// TODO update support for actual audio types not just integers
	// 0 = Uploaded File
	// 1 = Text To Speech
	// 2 = Call Back
	// 3 = Music On Hold
	// 5 = Name
	// 6 = VoiceMail
	// 7 = Default

	getUserAudio(userId: string = '{userId}', audioType: string): Observable<IRegularApiResponse<IAudioContent>> {
		return this.apiService.get(`${this.baseUrl}/${userId}/audio`, { audioType });
	}

	createUserAudio(
		userId: string = '{userId}',
		audioType: string,
		phone: string = '',
		audioContent: string = '',
	): Observable<IRegularApiResponse<IAudioContent>> {
		const body: IAudioContentInput = { audioContent, phone };

		return this.apiService.post(`${this.baseUrl}/${userId}/audio`, body, { audioType });
	}

	resetUserAudio(userId: string = '{userId}', audioType: string = '6'): Observable<IRegularApiResponse<string>> {
		// TODO why is this needed in query and body?
		const query = { audioType };

		return this.apiService.post(`${this.baseUrl}/${userId}/audio/reset`, {}, query);
	}

	getAudioRecordingStatus(recordingId: string): Observable<IRegularApiResponse<string>> {
		return this.apiService.get(`/accounts/{accountId}/recordings/${recordingId}`);
	}
}
