import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';

import {
	DROPDOWN_DATA,
	DROPDOWN_DATA_CONFIG,
	DROPDOWN_DATA_FILTER,
	DROPDOWN_PAGE,
	DROPDOWN_PAGE_NEXT,
} from '@n2p/dropdown/dropdown.injectors';
import { matchSearchString } from '@utils/helpers/functions';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { PhoneCategory } from '@app/pages/special-extensions/special-extensions.constants';
import { JwtService } from '@app/services';

type Entity = 'team-member' | 'department' | 'ring-group' | 'welcome-menu';

@Component({
	selector: 'app-entity-phone-dropdown-data',
	templateUrl: './entity-phone-dropdown-data.component.html',
	styleUrls: ['./entity-phone-dropdown-data.component.scss'],
})
export class EntityPhoneDropdownDataComponent implements OnInit {
	entityControl: FormControl;
	pageSubject: Observable<number>;
	entityToShow: string;
	searchStr: string;
	entities = [
		{ type: PhoneCategory.Unassigned, title: this.translate.instant('EDIT_SPECIAL_EXTENSION.UNASSIGNED_NUMBERS') },
		{ type: PhoneCategory.TeamMember, title: this.translate.instant('LEFT_NAVBAR.COMPANY_BAR.TEAM_MEMBERS') },
		{ type: PhoneCategory.Department, title: this.translate.instant('LEFT_NAVBAR.COMPANY_BAR.DEPARTMENTS') },
		{ type: PhoneCategory.RingGroup, title: this.translate.instant('LEFT_NAVBAR.COMPANY_BAR.RING_GROUPS') },
		{ type: PhoneCategory.WelcomeMenu, title: this.translate.instant('LEFT_NAVBAR.COMPANY_BAR.WELCOME_MENUS') },
		{
			type: PhoneCategory.SpecialExtension,
			title: this.translate.instant('LEFT_NAVBAR.COMPANY_BAR.SPECIAL_EXTENSIONS'),
		},
	];
	constructor(
		@Inject(DROPDOWN_DATA) data: any,
		@Inject(DROPDOWN_PAGE) page: Observable<number>,
		@Inject(DROPDOWN_PAGE_NEXT) private nextPage: any,
		@Inject(DROPDOWN_DATA_CONFIG) private config: any,
		@Inject(DROPDOWN_DATA_FILTER) private searchControl: FormControl,
		private translate: TranslateService,
		private tenantService: ApiTenantService,
		private jwtService: JwtService,
	) {
		this.pageSubject = page;
		this.entityControl = data;

		if (this.tenantService.isUSAccount() && this.jwtService.hasPolicy('unite.user.callerid.port')) {
			// put "Porting numbers" group after "Unassigned numbers" groups
			const unassignedIndex = this.entities.findIndex(g => g.type === PhoneCategory.Unassigned);
			this.entities.splice(unassignedIndex + 1, 0, {
				type: PhoneCategory.Porting,
				title: this.translate.instant('EDIT_SPECIAL_EXTENSION.PORTING_NUMBERS'),
			});
		}
	}

	get entityData(): Array<any> {
		return this.searchStr
			? this.entityControl.value.filter(i =>
					matchSearchString(this.searchStr, [i.value, i.subValue, i.number, i.extension]),
			  )
			: this.entityControl.value.filter(i => i.category === this.entityToShow);
	}

	hasEntity(entityType): boolean {
		return this.entityControl.value.find(i => i.category === entityType);
	}

	showEntityOptions = (entity: Entity): void => {
		this.entityToShow = entity;
		this.nextPage();
	};

	itemClicked(item): void {
		if (this.config.singleSelection) {
			this.clearAllSelected(item);
		}
		item.selected = !item.selected;
		this.entityControl.updateValueAndValidity();
	}

	ngOnInit(): void {
		this.searchControl.valueChanges.subscribe(value => (this.searchStr = value));
	}

	private clearAllSelected(item): void {
		for (const entity of this.entityControl.value) {
			if (entity.value !== item.value) entity.selected = false;
		}
	}
}
