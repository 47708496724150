import { Component, ElementRef, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs';

import { ApiDepartmentsService } from '@app/services';
import { IUser } from '@app/services/web-apis/users/api-users.domain';
import { IDepartmentUser } from '@app/services/web-apis/departments/api-departments.domain';

@Component({
	selector: 'app-departments-assigned-user',
	templateUrl: './departments-assigned-user.component.html',
	styleUrls: ['./departments-assigned-user.component.scss'],
})
export class DepartmentsAssignedUserComponent implements OnInit {
	private membersFormControlSubscription: Subscription;

	users: Array<any> = [];
	departmentMembers: Array<IUser> = [];
	department: any;
	hovered: boolean = false;
	opened: boolean = false;
	@ViewChild('dropdown', { static: true }) dropdown: ElementRef;
	formGroup: FormGroup;
	filter: BehaviorSubject<string>;
	elementId: string = 'departments-user';
	@Output() assignedUser: EventEmitter<IDepartmentUser[]> = new EventEmitter();
	membersFormControl: FormControl = new FormControl([]);

	constructor(private apiDepartments: ApiDepartmentsService) {
		this.filter = new BehaviorSubject<string>('');
		this.elementId += '_' + (document.getElementsByClassName('departments-user').length + 1);
	}

	ngOnInit(): void {
		this.membersFormControlSubscription = this.membersFormControl.valueChanges.subscribe(value => {
			this.assignedUser.emit(value);
		});
	}

	ngOnDestroy(): void {
		this.membersFormControlSubscription.unsubscribe();
	}

	@Input('users') set usersSetter(users: any[]) {
		this.users = users;

		this.updateSelectedData();
	}

	@Input('department') set departmentSetter(department: any) {
		this.department = department;
		this.updateSelectedData();
	}

	saveMemberChanges(event: any): void {
		const isTheSame =
			this.departmentMembers.length === event.members.length &&
			this.departmentMembers.every((m: IUser, i: number) => m.userId === event.members[i].userId);

		if (!isTheSame) {
			const users = event.members.map(m => {
				return {
					userId: m.userId,
				};
			});

			this.apiDepartments.updateDepartmentUsers(this.department.deptId, users).subscribe(res => {
				if (!res.hasError) {
					this.departmentMembers = event.members;
				}
			});
		}
	}

	private updateSelectedData(): void {
		if (this.users && this.department) {
			this.users = this.users.map(p => {
				return {
					...p,
					selected: this.department.members.findIndex(i => i.userId === p.userId) !== -1,
				};
			});

			this.departmentMembers = this.users.filter(u => u.selected);
			this.membersFormControl.patchValue(this.users.filter(u => u.selected));
		}
	}
}
