import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostListener, Inject, Injector, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { POPUP_COMPONENT, POPUP_CONFIG, POPUP_CONTENT, POPUP_HOVERED, POPUP_REF } from '@n2p/popup/popup.injectors';

export interface PopupConfig {
	position: string;
	action: string;
	margin: number;
}

@Component({
	selector: 'n2p-popup',
	templateUrl: './popup.component.html',
	styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {
	popupConfig: PopupConfig;
	popupComponent: any;
	popupContent: any;
	popupContentHtml;
	constructor(
		@Inject(POPUP_REF) private popupRef: OverlayRef,
		@Inject(POPUP_CONFIG) popupConfig: PopupConfig,
		@Inject(POPUP_HOVERED) private popupHovered: any,
		private domSanitizer: DomSanitizer,
		private injector: Injector,
	) {
		this.popupConfig = popupConfig;
		this.popupComponent = this.injector.get(POPUP_COMPONENT, null);
		this.popupContent = this.injector.get(POPUP_CONTENT, null);
	}

	@HostListener('mouseenter') entering(): void {
		this.popupHovered();
	}

	@HostListener('mouseleave') leaving(): void {
		if (this.popupConfig.action === 'hover') {
			this.closePopup();
		}
	}

	closePopup(): void {
		this.popupRef.dispose();
	}

	ngOnInit(): void {
		if (this.popupContent && this.popupContent.nativeElement) {
			this.popupContentHtml = this.domSanitizer.bypassSecurityTrustHtml(this.popupContent.nativeElement.innerHTML);
		}
	}
}
