import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { POPUP_CLICK_CALLBACK, POPUP_DATA } from '@n2p/popup/popup.injectors';
import { RCON_ROUTE } from '@app/Common';
import { ConstantsService } from '@app/services/constants/constants.service';
import { environment } from '@env/environment';

@Component({
	selector: 'n2p-voice-mail-popup',
	templateUrl: './voice-mail-popup.component.html',
	styleUrls: ['./voice-mail-popup.component.scss'],
})
export class VoiceMailPopupComponent implements OnInit {
	voiceMails: Array<any>;

	isAppMode: boolean = this.constantsService.isAppMode();

	allCallsLinkBlank: string = (environment.base_href || '') + '/calls?tab=voicemails';

	constructor(
		private router: Router,
		private constantsService: ConstantsService,
		@Inject(POPUP_CLICK_CALLBACK) private callback: any,
		@Inject(POPUP_DATA) voiceMails: any,
	) {
		this.voiceMails = voiceMails;
	}

	@HostListener('click', ['$event']) onClick(ev): void {
		ev.stopPropagation();
	}

	close(): void {
		this.callback();
	}

	ngOnInit(): void {}

	get isOnRconPage(): boolean {
		return this.router.url === RCON_ROUTE;
	}
}
