import { Component, OnInit, Input } from '@angular/core';

import { environment } from 'environments/environment';

@Component({
	selector: 'shared-svg',
	templateUrl: './svg.component.html',
	styleUrls: ['./svg.component.scss'],
})
export class SvgComponent implements OnInit {
	@Input() class: string;
	@Input() fill: string;
	@Input() width: number;
	@Input() height: number;

	@Input() set iconName(iconName) {
		this.url = `images/${iconName}.svg?v=${environment.version}#${iconName}`;
	}

	url: string;

	constructor() {}

	ngOnInit() {}
}
