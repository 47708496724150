import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import {
	MULTISELECT_DATA,
	MULTISELECT_DATA_CLICK_CALLBACK,
	MULTISELECT_DATA_FILTER,
	MULTISELECT_FORM_GROUP,
} from 'utils/constants/injectortokens';
import { Observable } from 'rxjs/Observable';
import { FormGroup } from '@angular/forms';

import { IPhone } from '@app/Common/interfaces/IPhone';
import { PromptService } from '@app/services/prompt/prompt.service';
//import {AssignedNumbersPromptComponent} from "../assigned-numbers-prompt/assigned-numbers-prompt.component";

import { Decision } from '@app/shared/classes/PromptRef';

@Component({
	selector: 'app-caller-id',
	templateUrl: './caller-id.component.html',
	styleUrls: ['./caller-id.component.scss'],
})
export class CallerIdComponent implements OnInit, OnDestroy {
	getAllCallerId: any[];

	@HostBinding('class.n2p-dropdown-data') style = false;
	subscription: any;
	phones: Array<IPhone>;
	filteredPhones: Array<IPhone>;
	clickCallback: any;
	selected: Array<IPhone> = [];
	constructor(
		@Inject(MULTISELECT_DATA_CLICK_CALLBACK) clickCallBack: any,
		@Inject(MULTISELECT_FORM_GROUP) private formGroup: FormGroup,
		@Inject(MULTISELECT_DATA) data: Array<IPhone>,
		@Inject(MULTISELECT_DATA_FILTER) private filter: Observable<string>,
		private promptService: PromptService,
	) {
		this.phones = data;
		this.filteredPhones = this.phones;
		this.clickCallback = clickCallBack;
	}

	clicked(phone: IPhone) {
		if (!phone.selected && phone.routesTo) {
			const promptRef = this.promptService.create(
				{
					name: this.formGroup.get('name').value,
					phone: phone,
				},
				{
					width: 440,
					height: 334,
				},
			);
			promptRef.onDismiss().then(decision => {
				if (decision === Decision.CANCEL) return;

				phone.selected = true;
				this.clickCallback(phone);
			});
		} else {
			//turn off the other items because this is only a single
			const selectedIndex = this.phones.findIndex(p => p.selected && p.lineId !== phone.lineId);
			if (selectedIndex !== -1) {
				this.phones[selectedIndex].selected = false;
			}

			phone.selected = !phone.selected;
			this.clickCallback(phone);
		}
	}

	ngOnInit() {
		this.style = true;
		this.subscription = this.filter.subscribe(str => {
			if (str) {
				this.filteredPhones = this.phones.filter(p => p.lineId.indexOf(str) !== -1);
			} else {
				this.filteredPhones = this.phones;
			}
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
