import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ForwardCallsToDataComponent } from './forward-calls-to-data/forward-calls-to-data.component';
import 'rxjs/add/observable/forkJoin';
import { ForwardCallsToChipComponent } from './forward-calls-to-chip/forward-calls-to-chip.component';
import { CacheService } from '@app/services/cache/cache.service';
import { AccountService } from '@app/services/account/account.service';

@Component({
	selector: 'app-forward-calls-to',
	templateUrl: './forward-calls-to.component.html',
	styleUrls: ['./forward-calls-to.component.scss'],
})
export class ForwardCallsToComponent implements OnInit {
	readonly CACHE_SERVICE_KEY = 'forward_calls_to_data';
	data: Array<any> = [];
	dataComponent: any = ForwardCallsToDataComponent;
	chipComponent: any = ForwardCallsToChipComponent;
	itemsProp: any;
	@Input() bottomCutOff: number;
	@Input() id: any;
	@Input() label: string;
	@Input() formGroup: FormGroup;
	@Input() repeatMenu: boolean;
	@Input() showThirdLvl: boolean;
	config: {
		showRepeatMenu: boolean;
		showThirdLvl: boolean;
	};
	constructor(private cacheService: CacheService, private accountService: AccountService) {
		this.itemsProp = {
			tm: 'userId',
			d: 'deptId',
			r: 'id',
			ext: 'number',
			w: 'id',
			e: 'id',
		};
	}

	findIndex = (selected: Array<any>, data) => {
		return selected.findIndex(i => i.item['id'] === data.item['id']);
	};

	ngOnInit(): void {
		this.config = {
			showRepeatMenu: this.repeatMenu,
			showThirdLvl: this.showThirdLvl,
		};
		const data = this.cacheService.get(this.CACHE_SERVICE_KEY);
		if (!data) {
			const subscription = this.accountService.getConsolidatedEntities().map(res =>
				res.data.reduce((accumulator, value) => {
					if (!accumulator[value.type]) {
						accumulator[value.type] = [];
					}
					accumulator[value.type].push(value);

					return accumulator;
				}, {}),
			);
			const promise = new Promise(resolve => {
				subscription.subscribe(result => {
					this.dataResponseHandler(result);
					resolve(result);
				});
			});
			this.cacheService.set(this.CACHE_SERVICE_KEY, promise);
		} else if (data.then) {
			data.then(result => {
				this.dataResponseHandler(result);
			});
		} else {
			this.data = data;
		}
	}

	private dataResponseHandler(result): void {
		this.data = [{ data: result.user }, { data: result.department }, { data: result.ringGroup }, { data: result.maa }];
		this.cacheService.set(this.CACHE_SERVICE_KEY, this.data);
		try {
			if (this.id && this.id.value) {
				this.data[3].data = this.data[3].data.filter(i => i.id !== this.id.value);
			}
		} catch (e) {
			// show error
		}
	}
}
