import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'n2p-button-group',
	templateUrl: './button-group.component.html',
	styleUrls: ['./button-group.component.scss'],
	// encapsulation: ViewEncapsulation.None,
})
export class ButtonGroupComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
