import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { combineLatest, map, tap } from 'rxjs/operators';

import {
	ForwardStatus,
	ForwardType,
	ICallForwardingRules,
	IForwardToExtended,
	IUserLight,
	IUserLine,
	RuleType,
	RuleTypeBase,
} from '@app/services/web-apis/users/api-users.domain';
import { FeatureType, OwnerType } from '@app/services/web-apis/features/api-features.domain';
import {
	isIndividualRuleType,
	processCallForwardNumbers,
	RULE_TYPES_MAP,
} from '@app/pages/teammembers/call-options/call-options.constants';
import { ApiUsersService } from '@app/services/web-apis/users/api-users.service';
import { ApiFeaturesService } from '@app/services/web-apis/features/api-features.service';
import { ApiAccountsService } from '@app/services/web-apis/accounts/api-accounts.service';
import { IScheduleRule } from '@app/services/web-apis/schedules/api-schedules.domain';
import { ApiTimezoneService } from '@app/services';

@Injectable()
export class CallOptionsService {
	private maxRingsSubj: BehaviorSubject<number> = new BehaviorSubject(0);
	private callRecordingSubj: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private usersSubj: BehaviorSubject<IUserLight[]> = new BehaviorSubject([]);
	private userLines: IUserLine[] = [];

	private callForwardingRulesSubj: BehaviorSubject<ICallForwardingRules | null> = new BehaviorSubject(null);
	private callForwardingRulesNextSubj: BehaviorSubject<ICallForwardingRules | null> = new BehaviorSubject(null);

	readonly INDIVIDUAL_RINGS_VALUE: number = -1;
	readonly MIN_RINGS_VALUE: number = 2;

	userId: number | string;

	get userPhones(): IUserLine[] {
		return this.userLines;
	}

	get maxRings$(): Observable<number> {
		return this.maxRingsSubj.asObservable();
	}

	get maxRings(): number {
		return this.maxRingsSubj.value;
	}

	get callRecording$(): Observable<boolean> {
		return this.callRecordingSubj.asObservable();
	}

	get callRecording(): boolean {
		return this.callRecordingSubj.value;
	}

	get users$(): Observable<IUserLight[]> {
		return this.usersSubj.asObservable();
	}

	get users(): IUserLight[] {
		return this.usersSubj.value;
	}

	get callForwardingRules$(): Observable<ICallForwardingRules | null> {
		return this.callForwardingRulesSubj.asObservable();
	}

	get callForwardingRules(): ICallForwardingRules | null {
		return this.callForwardingRulesSubj.value;
	}

	get callForwardingRulesNext$(): Observable<ICallForwardingRules | null> {
		return this.callForwardingRulesNextSubj.asObservable();
	}

	get callForwardingRulesNext(): ICallForwardingRules | null {
		return this.callForwardingRulesNextSubj.value;
	}

	get callForwardingRulesNextClone(): ICallForwardingRules | null {
		if (!this.callForwardingRulesNext) {
			return null;
		}

		return {
			...this.callForwardingRulesNext,
			forwardTo: this.callForwardingRulesNext.forwardTo.map(item => ({ ...item })),
		};
	}

	get callForwardToggle$(): Observable<boolean> {
		return this.callForwardingRulesNext$.pipe(
			map(callForwardingRules => {
				return !!callForwardingRules && callForwardingRules.callForwardToggle;
			}),
		);
	}

	get callForwardToggle(): boolean {
		return !!this.callForwardingRulesNext && this.callForwardingRulesNext.callForwardToggle;
	}

	get ruleType$(): Observable<RuleType> {
		return this.callForwardingRulesNext$.pipe(
			map(callForwardingRules => {
				return callForwardingRules ? callForwardingRules.ruletype : RuleTypeBase.OFF;
			}),
		);
	}

	get ruleType(): RuleType {
		return this.callForwardingRulesNext ? this.callForwardingRulesNext.ruletype : RuleTypeBase.OFF;
	}

	get isCallForwardingOff$(): Observable<boolean> {
		return this.callForwardToggle$.pipe(
			combineLatest(this.ruleType$),
			map(([callForwardToggle, ruleType]) => !callForwardToggle || ruleType === RuleTypeBase.OFF),
		);
	}

	get isCallForwardingOff(): boolean {
		return !this.callForwardToggle || this.ruleType === RuleTypeBase.OFF;
	}

	get posAck$(): Observable<boolean> {
		return this.callForwardingRulesNext$.pipe(
			map(callForwardingRules => !!callForwardingRules && callForwardingRules.posAck),
		);
	}

	get callScreeningFlag$(): Observable<boolean> {
		return this.callForwardingRulesNext$.pipe(
			map(callForwardingRules => !!callForwardingRules && callForwardingRules.callScreeningFlag),
		);
	}

	get callerId$(): Observable<boolean> {
		return this.callForwardingRulesNext$.pipe(
			map(callForwardingRules => !!callForwardingRules && callForwardingRules.callerIdFlag),
		);
	}

	get forwardNumbers$(): Observable<IForwardToExtended[]> {
		return this.callForwardingRulesNext$.pipe(
			combineLatest(this.users$),
			map(([callForwardingRules, users]) => processCallForwardNumbers(callForwardingRules, users)),
			map(numbers => numbers.filter(number => number.status !== ForwardStatus.DELETED)),
		);
	}

	get forwardNumbers(): IForwardToExtended[] {
		return processCallForwardNumbers(this.callForwardingRulesNext, this.users);
	}

	get noForwardNumbers$(): Observable<boolean> {
		return this.forwardNumbers$.pipe(
			map(forwardNumbers => {
				return !forwardNumbers.length;
			}),
		);
	}

	get noForwardNumbers(): boolean {
		return !this.forwardNumbers.length;
	}

	get isIndividualRuleType(): boolean {
		return (
			!this.callForwardingRulesNext ||
			isIndividualRuleType(this.callForwardingRulesNext.ruletype) ||
			this?.callForwardingRulesNext?.rings === this.INDIVIDUAL_RINGS_VALUE
		);
	}

	get rings(): number {
		return this.isIndividualRuleType ? this.INDIVIDUAL_RINGS_VALUE : this.callForwardingRulesNext.rings;
	}

	constructor(
		private apiUsersService: ApiUsersService,
		private apiFeaturesService: ApiFeaturesService,
		private apiAccountsService: ApiAccountsService,
		private timezoneService: ApiTimezoneService,
	) {}

	fetchAccountPolicy(): Observable<number> {
		return this.apiAccountsService.getAccountPolicy().pipe(
			map(response => {
				const maxRings = response.data[0].maxRingsPhone;

				this.maxRingsSubj.next(maxRings);

				return maxRings;
			}),
		);
	}

	fetchCallRecording(): Observable<boolean> {
		return this.apiFeaturesService.getFeature(OwnerType.USERS, this.userId, FeatureType.RECORD).pipe(
			tap(result => {
				this.callRecordingSubj.next(result);
			}),
		);
	}

	fetchUsers(): Observable<IUserLight[]> {
		return this.apiUsersService.getAllUsersLight().pipe(
			tap(users => {
				this.usersSubj.next(users);
			}),
		);
	}

	fetchCallForwardingRules(): Observable<ICallForwardingRules> {
		return this.apiUsersService.getUserCallForwardRules(this.userId).pipe(
			tap(callForwardingRules => {
				this.callForwardingRulesSubj.next(callForwardingRules);
				this.callForwardingRulesNextSubj.next(callForwardingRules);
			}),
		);
	}

	fetchAssignedPhoneNumbers(): Observable<IUserLine[]> {
		return this.apiUsersService.getUserLines(this.userId).pipe(
			tap(data => {
				this.userLines = data;
			}),
		);
	}

	toggleCallRecording(): void {
		this.apiFeaturesService
			.saveFeature(OwnerType.USERS, this.userId, FeatureType.RECORD, !this.callRecording)
			.subscribe(() => {
				this.callRecordingSubj.next(!this.callRecording);
			});
	}

	toggleCallForwardToggle(): void {
		this.prepareCallForwardingRules({ callForwardToggle: !this.callForwardToggle });
	}

	updateRuleType(ruleType: RuleType): void {
		this.prepareCallForwardingRules({ ruletype: ruleType });
	}

	togglePosAck(): void {
		let { posAck, callScreeningFlag } = this.callForwardingRulesNext;

		if (!posAck) {
			callScreeningFlag = false;
		}

		this.prepareCallForwardingRules({ posAck: !posAck, callScreeningFlag });
	}

	toggleCallScreeningFlag(): void {
		this.prepareCallForwardingRules({ callScreeningFlag: !this.callForwardingRulesNext.callScreeningFlag });
	}

	toggleCallerId(): void {
		this.prepareCallForwardingRules({ callerIdFlag: !this.callForwardingRulesNext.callerIdFlag });
	}

	updateRings(rings: number): void {
		if (this.callForwardingRulesNext) {
			let { ruletype: ruleType, forwardTo } = this.callForwardingRulesNextClone;
			const isPrevIndividual = this.isIndividualRuleType;
			const isCurrIndividual = rings === this.INDIVIDUAL_RINGS_VALUE;

			if (!isCurrIndividual) {
				forwardTo.forEach(i => (i.rings = rings));
			}

			this.prepareCallForwardingRules({
				ruletype: isPrevIndividual !== isCurrIndividual ? RULE_TYPES_MAP[ruleType] : ruleType,
				rings,
				forwardTo,
			});
		}
	}

	addForwardNumber(value: string): void {
		const { forwardTo } = this.callForwardingRulesNextClone;
		const sameNumber = forwardTo.find(n => n.value === value && n.status !== ForwardStatus.DELETED);

		if (!sameNumber) {
			forwardTo.push({
				sequence: forwardTo.filter(n => n.status !== ForwardStatus.DELETED).length + 1,
				type: ForwardType.PHONE,
				status: ForwardStatus.CREATED,
				rings:
					this?.callForwardingRulesNext?.rings === this.INDIVIDUAL_RINGS_VALUE
						? this.MIN_RINGS_VALUE
						: this?.callForwardingRulesNext?.rings,
				value,
			});

			this.prepareCallForwardingRules({ forwardTo });
		}
	}

	deleteForwardNumber(sequence: number | string): void {
		const { forwardTo } = this.callForwardingRulesNextClone;
		const deletingIndex = forwardTo.findIndex(number => {
			return number.sequence.toString() === sequence.toString() && number.status !== ForwardStatus.DELETED;
		});
		const deletingNumber = forwardTo[deletingIndex];

		if (deletingNumber) {
			if (deletingNumber.status === ForwardStatus.CREATED) {
				forwardTo.splice(deletingIndex, 1);
			} else {
				deletingNumber.status = ForwardStatus.DELETED;
				forwardTo
					.filter(item => item.status !== ForwardStatus.DELETED)
					.forEach((item, i) => {
						item.sequence = i + 1;
					});
			}
		}

		this.prepareCallForwardingRules({ forwardTo });
	}

	updateForwardNumber(sequence: number | string, rings: number): void {
		const { forwardTo } = this.callForwardingRulesNextClone;
		const updatingNumber = forwardTo.find(number => number.sequence.toString() === sequence.toString());

		updatingNumber.rings = rings;

		this.prepareCallForwardingRules({ forwardTo });
	}

	updateForwardingSchedule(rules?: IScheduleRule[]): void {
		let forwardSchedule = undefined;
		if (rules) {
			const scheduleName = this.callForwardingRulesNext.forwardSchedule
				? this.callForwardingRulesNext.forwardSchedule.name
				: '';
			const { abbreviation: timezone } = this.timezoneService.getCurrentUsersTimezone();
			forwardSchedule = {
				name: scheduleName || `CallForwardSchedule_${Date.now()}`,
				timezone,
				rules,
			};
		}
		this.prepareCallForwardingRules({ forwardSchedule });
	}

	prepareCallForwardingRules(updateValue: Partial<ICallForwardingRules>): ICallForwardingRules {
		this.callForwardingRulesNextSubj.next({
			...this.callForwardingRulesNext,
			...updateValue,
		});
		return this.callForwardingRulesNext;
	}

	commitCallForwardingRules(): Observable<ICallForwardingRules> {
		const data = this.callForwardingRulesNext;
		data.posAck = !!data.posAck;
		data.forwardTo = data.forwardTo.map(number => ({
			...number,
			status: number.status === ForwardStatus.CREATED ? ForwardStatus.ACTIVE : number.status,
		}));

		return this.apiUsersService.updateUserCallForwardRules(this.userId, data).pipe(
			tap(callForwardingRules => {
				this.callForwardingRulesSubj.next(callForwardingRules);
				this.callForwardingRulesNextSubj.next(callForwardingRules);
			}),
			map(() => this.callForwardingRulesNext),
		);
	}
}
