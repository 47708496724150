import { Component, Directive, Inject, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { TOOLTIP_COMPONENT, TOOLTIP_POSITION, TOOLTIP_TEXT } from 'utils/constants/injectortokens';

@Component({
	selector: 'app-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
	text: string;
	position: string;
	tooltipComponentHTML: any;
	tooltipComponent: any;
	constructor(
		@Inject(TOOLTIP_TEXT) tooltipText: string,
		@Inject(TOOLTIP_POSITION) position: string,
		@Inject(TOOLTIP_COMPONENT) tooltipComponent: any,
		private domSanitizer: DomSanitizer,
	) {
		this.text = tooltipText;
		this.position = position;
		this.tooltipComponent = tooltipComponent;
	}

	ngOnInit(): void {
		if (this.tooltipComponent && this.tooltipComponent.nativeElement) {
			this.tooltipComponentHTML = this.domSanitizer.bypassSecurityTrustHtml(
				this.tooltipComponent.nativeElement.innerHTML,
			);
		}
	}
}
