import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api';
import { Observable } from 'rxjs/Observable';
import { first } from 'rxjs/operators';

import {
	IDepartment,
	IDepartmentUserLight,
	IExtendedDepartment,
} from '@app/services/web-apis/departments/api-departments.domain';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';

@Injectable()
export class ApiDepartmentsService {
	private baseUrl: string = '/accounts/{accountId}/departments';

	constructor(private apiService: ApiService) {}

	// department

	getUserDepartments(userId: string = '{userId}'): Observable<any> {
		return this.apiService.get(`/accounts/{accountId}/users/${userId}/departments`);
	}

	createDepartment(department: object): Observable<IRegularApiResponse<IDepartment>> {
		return this.apiService.post(this.baseUrl, department);
	}

	updateDepartment(department: object): Observable<IRegularApiResponse<IExtendedDepartment>> {
		return this.apiService.put(`${this.baseUrl}/${department['deptId']}`, department);
	}

	getDepartmentCallForwardRules(departmentId: string): Observable<any> {
		return this.apiService.get(`${this.baseUrl}/${departmentId}/callForwardRules`);
	}

	createDepartmentCallForwardRules(departmentId: string, rule: object): Observable<any> {
		return this.apiService.post(`${this.baseUrl}/${departmentId}/callForwardRules`, rule);
	}

	// departments

	getAllDepartments(includeLineNumbers: boolean = true): Observable<any> {
		const query = {
			includeLineNumbersFlag: includeLineNumbers ? 'Y' : 'N',
		};

		return this.apiService.get(this.baseUrl, query);
	}

	getAllDepartmentsLW(skip: number = 0, take: number = 0): Observable<any> {
		return this.apiService.get(`${this.baseUrl}/light?skip=${skip}&take=${take}`);
	}

	updateDepartmentUsers(deptId: number, users: IDepartmentUserLight[]): Observable<IRegularApiResponse<string>> {
		return this.apiService.patch(`${this.baseUrl}/${deptId}/users`, users).pipe(first());
	}
}
