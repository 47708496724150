import { Directive, HostListener, Input, OnDestroy } from '@angular/core';

@Directive({
	selector: '[appEscapeHandler]',
})
export class EscapeHandlerDirective implements OnDestroy {
	/**
	 * Input function to call on escape press
	 * @public
	 */
	@Input() appEscapeHandler: Function;

	/**
	 * Main host listener function. Checks if current modal is the top one
	 */
	@HostListener('document:keydown.escape') escapeHandler() {
		if (this.instanceId === EscapeHandlerDirective.activeInstanceId) {
			this.appEscapeHandler();
		}
	}

	/**
	 * Instance id for top level modal tracking
	 * @private
	 */
	private readonly instanceId: number = 0;
	/**
	 * Active or top level instance id
	 * @private
	 */
	private static activeInstanceId: number = 0;

	/**
	 * Assign correct instance id and active instance id
	 */
	constructor() {
		EscapeHandlerDirective.activeInstanceId += 1;
		this.instanceId = EscapeHandlerDirective.activeInstanceId;
	}

	/**
	 * Track correct active instance id
	 */
	ngOnDestroy() {
		EscapeHandlerDirective.activeInstanceId -= 1;
	}
}
