import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ShowHideDirective } from '@app/directives/show-hide/show-hide.directive';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { CountryCode } from 'libphonenumber-js';

@Directive({
	selector: '[n2pIfCountries]',
})
export class IfCountriesDirective extends ShowHideDirective {
	@Input() set n2pIfCountries(countries: CountryCode[]) {
		this.n2pShowHide = this.tenantService.isAccountCountries$(countries);
	}

	constructor(templateRef: TemplateRef<any>, viewContainer: ViewContainerRef, private tenantService: ApiTenantService) {
		super(templateRef, viewContainer);
	}
}
