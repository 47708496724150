import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'shared-addsection',
	templateUrl: './addsection.component.html',
	styleUrls: ['./addsection.component.scss'],
})
export class AddsectionComponent implements OnInit {
	@Input() iconName = 'add';
	@Input() text: string;

	@Output() addClicked: EventEmitter<any> = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	addSection() {
		this.addClicked.emit();
	}
}
