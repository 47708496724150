import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '@app/services/api/api.service';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { ICallerId, ICndInfo } from '@app/services/web-apis/special-extensions/api-special-extensions.domain';
import { IPutCallerIdParams } from '@app/services/devices/devices.domain';

const WEBRTC_STORED_KEY = 'webrtc';

@Injectable()
export class DeviceServices {
	constructor(private apiService: ApiService) {}
	//GET-GET /api/accounts/{accountId}/users/{userId}/webrtcDevice
	getWebRtc(selectedUserId: string = '{userId}') {
		return this.apiService.get(`/accounts/{accountId}/users/${selectedUserId}/webrtcDevice`).pipe(
			tap(({ hasError, data }) => {
				if (hasError) return;
				localStorage.setItem(WEBRTC_STORED_KEY, `${data.status === 'A'}`);
			}),
		);
	}
	putWebRtc(webrtcDevice: string, status: string, selectedUserId: string) {
		return this.apiService
			.put(`/accounts/{accountId}/users/${selectedUserId}/devices/${webrtcDevice}/webrtcdevice`, {
				status: status,
			})
			.pipe(
				tap(({ hasError, data }) => {
					if (hasError) return;
					localStorage.setItem(WEBRTC_STORED_KEY, `${data.status === 'A'}`);
				}),
			);
	}
	getSipDeviceRings(selectedUserId: string) {
		return this.apiService.get(`/accounts/{accountId}/users/${selectedUserId}`);
	}
	putSipDeviceRings(sipdevicerings: string, selectedUserId: string) {
		return this.apiService.put(`/accounts/{accountId}/users/${selectedUserId}`, { sipDeviceRings: sipdevicerings });
	}
	getVoiceMailStatus(selectedUserId: string) {
		return this.apiService.get(`/accounts/{accountId}/users/${selectedUserId}`);
	}
	putVoiceMailStatus(selectedUserId: string, voicemailstatus: any) {
		return this.apiService.put(`/accounts/{accountId}/users/${selectedUserId}`, { voicemailEnabled: voicemailstatus });
	}
	getCallerId(selectedUserId: string | number): Observable<IRegularApiResponse<ICallerId>> {
		return this.apiService.get(`/accounts/{accountId}/users/${selectedUserId}/cnd`);
	}
	putCallerId({ userId, isActive, objectId, type, setting }: IPutCallerIdParams): Observable<IRegularApiResponse<any>> {
		const body = {
			id: 'cnd',
			isSubscribed: true,
			isActive,
			objectId,
			type,
			setting,
		};

		return this.apiService.put(`/accounts/{accountId}/users/${userId}/cnd`, body);
	}
	getRegisteredCallerId(selectedUserId: string | number): Observable<IRegularApiResponse<ICndInfo>> {
		return this.apiService.get(`/accounts/{accountId}/users/${selectedUserId}/cndinfo`);
	}
}
