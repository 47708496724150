import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { ShowHideDirective } from '@app/directives/show-hide/show-hide.directive';
@Directive({
	selector: '[n2pUnlessDidlessCountry]',
})
export class UnlessDidlessCountryDirective extends ShowHideDirective {
	constructor(templateRef: TemplateRef<any>, viewContainer: ViewContainerRef, private tenantService: ApiTenantService) {
		super(templateRef, viewContainer);

		this.n2pShowHide = this.tenantService.accountCountryIsDidLess$.pipe(map(value => !value));
	}
}
