import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractControlValueAccessor } from '@app/Common/classes/AbstractControlValueAccessor';

@Component({
	selector: 'shared-switch',
	templateUrl: './switch.component.html',
	styleUrls: ['./switch.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SwitchComponent),
			multi: true,
		},
	],
})
export class SwitchComponent extends AbstractControlValueAccessor {
	isDisabled: boolean;

	switch(): void {
		if (!this.isDisabled) {
			this.value = !this.value;
		}
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}
}
