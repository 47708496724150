import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SvgModule } from '@n2p';
import { LeftSideNavService } from '@n2p/left-side-nav/left-side-nav.service';
import { TooltipModule } from '@n2p/tooltip/tooltip.module';
import { LeftSideNavComponent } from './left-side-nav.component';
import { LeftSideNavTogglerDirective } from './left-side-nav-toggler.directive';

@NgModule({
	declarations: [LeftSideNavComponent, LeftSideNavTogglerDirective],
	imports: [CommonModule, SvgModule, TooltipModule, RouterModule, TranslateModule],
	providers: [LeftSideNavService],
	exports: [LeftSideNavComponent, LeftSideNavTogglerDirective],
})
export class LeftSideNavModule {}
