import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/services';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { ILicense, ILicenseBase, IUpdateLicenseData } from '@app/services/licenses/licenses.domain';
import { Cacheable } from '@app/decorators/cacheable.decorator';

@Injectable({
	providedIn: 'root',
})
export class LicensesService {
	constructor(private apiService: ApiService) {}

	static checkHasActiveLicenses(license?: ILicense): boolean {
		return license?.quantity > 0 || license?.unlimited;
	}

	getLicenses(): Observable<ILicense[]> {
		return this.apiService.get<IRegularApiResponse<ILicense[]>>('/accounts/{accountId}/licenses').pipe(
			map(res => {
				if (res.hasError) {
					const message = res.errorMessages ? res.errorMessages[0].message : 'Unknown Error';
					throw new Error(message);
				}
				return res.data;
			}),
		);
	}

	@Cacheable() getLicensesWithPrivate(): Observable<ILicense[]> {
		return this.apiService
			.get<IRegularApiResponse<ILicense[]>>('/accounts/{accountId}/licenses?includePrivate=true')
			.pipe(
				map(res => {
					if (res.hasError) {
						const message = res.errorMessages ? res.errorMessages[0].message : 'Unknown Error';
						throw new Error(message);
					}
					return res.data;
				}),
			);
	}

	getLicenseByCode(code: string): Observable<ILicense> {
		return this.apiService.get<IRegularApiResponse<ILicense>>(`/accounts/{accountId}/licenses/code/${code}`).pipe(
			map(res => {
				if (res.hasError) {
					const message = res.errorMessages ? res.errorMessages[0].message : 'Unknown Error';
					throw new Error(message);
				}
				return res.data;
			}),
		);
	}

	getLicensesForRegularUser(userId: number): Observable<ILicenseBase[]> {
		return this.apiService.get<IRegularApiResponse<ILicenseBase[]>>(`/accounts/{accountId}/licenses/${userId}`).pipe(
			map(res => {
				if (res.hasError) {
					const message = res.errorMessages ? res.errorMessages[0].message : 'Unknown Error';
					throw new Error(message);
				}
				return res.data;
			}),
		);
	}

	updateUsersInLicense(users: IUpdateLicenseData[]): Observable<ILicense[]> {
		return this.apiService.post<IRegularApiResponse<ILicense[]>>('/accounts/{accountId}/licenses/assign', users).pipe(
			map(res => {
				if (res.hasError) {
					const message = res.errorMessages ? res.errorMessages[0].message : 'Unknown Error';
					throw new Error(message);
				}
				return res.data;
			}),
		);
	}
}
