import { ENV, IEnvironment } from '@env/environment.domain';

export const environment: IEnvironment = {
	get env(): ENV {
		return window.FETCHED_ENV_DATA.N2P_ENV;
	},
	get production(): boolean {
		return window.FETCHED_ENV_DATA.N2P_PRODUCTION;
	},
	get local(): boolean {
		return window.FETCHED_ENV_DATA.N2P_LOCAL;
	},
	get api_url(): string {
		return window.FETCHED_ENV_DATA.N2P_API_URL;
	},
	get api_auth_url(): string {
		return window.FETCHED_ENV_DATA.N2P_API_AUTH_URL;
	},
	get api_profile_settings(): string {
		return window.FETCHED_ENV_DATA.N2P_API_PROFILE_SETTINGS;
	},
	get web_dialer_url(): string {
		return window.FETCHED_ENV_DATA.N2P_WEB_DIALER_URL;
	},
	get rcon_url(): string {
		return window.FETCHED_ENV_DATA.N2P_RCON_URL;
	},
	get messenger_api_url(): string {
		return window.FETCHED_ENV_DATA.N2P_MESSENGER_API_URL;
	},
	get version(): number {
		return window.FETCHED_ENV_DATA.N2P_VERSION;
	},
	get nr_app_id(): string {
		return window.FETCHED_ENV_DATA.N2P_NR_APP_ID;
	},
	get enableProxy(): boolean {
		return window.FETCHED_ENV_DATA.N2P_ENABLE_PROXY;
	},
	get logglyKey(): string {
		return window.FETCHED_ENV_DATA.N2P_LOGGLY_KEY;
	},
	get huddleUrl(): string {
		return window.FETCHED_ENV_DATA.N2P_HUDDLE_URL;
	},
	get messenger_webclient(): string {
		return window.FETCHED_ENV_DATA.N2P_MESSENGER_WEBCLIENT;
	},
	get external_apps_url(): string {
		return window.FETCHED_ENV_DATA.N2P_EXTERNAL_APPS_URL || '/assets/dist-apps';
	},
	get porting_url(): string {
		return window.FETCHED_ENV_DATA.N2P_PORTING;
	},
	get is_pam_console(): boolean {
		const [domain = ''] = window.location.hostname.split('.');
		return domain.toLowerCase().includes('pam-console') || window.FETCHED_ENV_DATA.N2P_IS_PAM_CONSOLE || false;
	},
	get is_admin_app(): boolean {
		return window.FETCHED_ENV_DATA.N2P_IS_ADMIN_APP || false;
	},
	get base_href(): string {
		return window.FETCHED_ENV_DATA.N2P_SERVE_DOMAIN || '';
	},
	get sip_trunking(): string {
		return (
			window.FETCHED_ENV_DATA.N2P_SIP_TRUNKING || window.FETCHED_ENV_DATA.N2P_EXTERNAL_APPS_URL || '/assets/dist-apps'
		);
	},
	get wallboard_url(): string {
		return window.FETCHED_ENV_DATA.N2P_WALLBOARD_URL;
	},
	needToLoadEnv: true,
};
