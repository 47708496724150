import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DIALOG_REF } from 'utils/constants/injectortokens';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { ApiFailoverService, DialogService, SnackbarService } from '@app/services';
import {
	IFailoverPhoneNumber,
	ISaveFailoverPhoneNumber,
} from '@app/services/web-apis/phone-numbers-failover/api-phone-numbers-failover.domain';
import { TranslateService } from '@ngx-translate/core';
import { PhoneNumberFormatterPipe } from '@app/pipes';
import { DeleteFailoverComponent } from './delete-failover-component/delete-failover.component';
import { formatAsE164 } from '@app/pages/phone-numbers/utils/formatters.utils';

@Component({
	selector: 'app-edit-failover-number',
	templateUrl: './edit-failover-number.component.html',
	styleUrls: ['./edit-failover-number.component.scss'],
	providers: [PhoneNumberFormatterPipe],
})
export class EditFailoverNumberComponent {
	phoneNumber: string;
	failoverInfo?: IFailoverPhoneNumber;

	isLoading: boolean;

	constructor(
		@Inject(DIALOG_DATA) data: { phoneNumber: string; failoverInfo: IFailoverPhoneNumber },
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		private failoverService: ApiFailoverService,
		private snackbarService: SnackbarService,
		private translate: TranslateService,
		private phoneNumberFormatter: PhoneNumberFormatterPipe,
		private dialogService: DialogService,
	) {
		this.phoneNumber = data.phoneNumber;
		this.failoverInfo = data.failoverInfo ? { ...data.failoverInfo } : ({} as IFailoverPhoneNumber);
	}

	get failoverPhoneNumber(): string {
		return this.failoverInfo?.failoverPhoneNumber;
	}

	get isEdit(): boolean {
		return !!this.failoverInfo.failoverPhoneNumber;
	}

	async submitHandle(value: string): Promise<void> {
		value = value ? formatAsE164(value) : null;
		if (value === this.failoverPhoneNumber) return void this.closeHandle();

		const model = {
			failoverPhoneNumber: value,
			phoneNumber: formatAsE164(this.phoneNumber),
		} as ISaveFailoverPhoneNumber;

		this.isLoading = true;
		const result = await this.failoverService.createOrUpdateFailoverNumbers(model).toPromise();
		if (result.hasError) {
			const error = result.errorMessages?.[0]?.code
				? this.translate.instant(`PHONE_NUMBERS_PAGE.FAILOVER.ERRORS.${result.errorMessages?.[0]?.code}`, {
						phoneNumber: this.phoneNumberFormatter.transform({ number: this.phoneNumber }),
						failoverNumber: this.phoneNumberFormatter.transform({ number: value }),
				  })
				: this.translate.instant('GLOBALS.GENERAL_ERROR');

			this.snackbarService.createDanger(error);
		} else {
			this.createSuccessSnackbar(this.failoverPhoneNumber ? 'CHANGED' : 'SETUP');
		}
		this.isLoading = false;
		this.dialogRef.dismiss(
			result.hasError ? (this.failoverInfo.failoverPhoneNumber ? this.failoverInfo : null) : result.data,
		);
		this.closeHandle();
	}

	async deleteHandle(): Promise<void> {
		const dialogRef = this.dialogService.create(DeleteFailoverComponent, this.failoverInfo, {
			width: 450,
		});

		dialogRef.onDismiss().subscribe(async isDeleting => {
			if (!isDeleting) return;

			const model = {
				failoverPhoneNumber: null,
				phoneNumber: formatAsE164(this.phoneNumber),
			} as ISaveFailoverPhoneNumber;

			this.isLoading = true;
			const result = await this.failoverService.createOrUpdateFailoverNumbers(model).toPromise();
			if (result.hasError) {
				const error = result.errorMessages?.[0]?.code
					? this.translate.instant(`PHONE_NUMBERS_PAGE.FAILOVER.ERRORS.${result.errorMessages?.[0]?.code}`, {
							phoneNumber: this.phoneNumberFormatter.transform({ number: this.phoneNumber }),
							failoverNumber: this.phoneNumberFormatter.transform({ number: this.failoverPhoneNumber }),
					  })
					: this.translate.instant('GLOBALS.GENERAL_ERROR');

				this.snackbarService.createDanger(error);
			} else {
				this.createSuccessSnackbar('DELETED');
			}
			this.isLoading = false;
			this.dialogRef.dismiss(result.hasError ? this.failoverInfo : model);
			this.closeHandle();
		});
	}

	closeHandle = (): void => {
		this.dialogRef.complete();
	};

	createSuccessSnackbar = (type: IGreenSnackbarType) => {
		const phoneNumber = this.phoneNumberFormatter.transform({ number: this.phoneNumber });
		this.snackbarService.createSuccess(
			this.translate.instant(`PHONE_NUMBERS_PAGE.FAILOVER.${type}`, { phoneNumber: phoneNumber }),
		);
	};
}

type IGreenSnackbarType = 'CHANGED' | 'SETUP' | 'DELETED';
