import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ApiService } from '@app/services/api/api.service';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import {
	IFailoverPhoneNumber,
	FailoverPhoneNumbers,
	ISaveFailoverPhoneNumber,
} from './api-phone-numbers-failover.domain';

@Injectable({
	providedIn: 'root',
})
export class ApiFailoverService {
	private baseUrl: string = '/catalogs/{catalogId}/failovers';

	constructor(private apiService: ApiService) {}

	getFailoverNumbers(): Observable<IRegularApiResponse<FailoverPhoneNumbers>> {
		return this.apiService.get<IRegularApiResponse<FailoverPhoneNumbers>>(this.baseUrl);
	}

	getFailoverNumber(phoneNumber: string): Observable<IRegularApiResponse<IFailoverPhoneNumber>> {
		return this.apiService.get<IRegularApiResponse<IFailoverPhoneNumber>>(`${this.baseUrl}/${phoneNumber}/'`);
	}

	createOrUpdateFailoverNumbers(
		entity: ISaveFailoverPhoneNumber,
	): Observable<IRegularApiResponse<IFailoverPhoneNumber>> {
		return this.apiService.patch(this.baseUrl, entity);
	}
}
