import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-menu-options-sticky-header',
	templateUrl: './menu-options-sticky-header.component.html',
	styleUrls: ['./menu-options-sticky-header.component.scss'],
})
export class MenuOptionsStickyHeaderComponent implements OnInit {
	@Input() controls: any;
	constructor() {}

	ngOnInit() {}
}
