import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Report } from '@app/pages/analytics/models';
import { AppConditionalStorageService } from '@app/services/storage/storage.service';

const camelToSnakeCase = (str: string) =>
	`${str[0].toLowerCase()}${str.substr(1).replace(/[A-Z]/g, symbol => `_${symbol.toLowerCase()}`)}`;

class KeysDescriptor {
	userId: string = '';
	accountId: string = '';
	accountName: string = '';
	userRole: string = '';
	messengerAccountId: string = '';
	timezone: string = '';
	impersonate: boolean = false;
	userName: string = '';
	rememberLogin: boolean = false;
	analyticsReportType: Report | null = null;
	callServerVersion: string = '';
	uiLanguageCode: string = '';
}
const DTO_KEYS = Object.keys(new KeysDescriptor());

@Injectable()
export class AuthStorageData extends KeysDescriptor {
	constructor(private usedStorage: AppConditionalStorageService) {
		super();
		DTO_KEYS.forEach(key => {
			const storageKey = camelToSnakeCase(key);
			Object.defineProperty(this, key, {
				get: <T>(): T => this.get(storageKey),
				set: <T>(value: T): void => this.set(storageKey, value),
			});
		});
	}

	get<T = any>(key: string): T {
		return this.usedStorage.getItem(key);
	}

	set<T = any>(key: string, value: T): void {
		return this.usedStorage.setItem(key, value);
	}

	reset(): void {
		DTO_KEYS.forEach(key => this.usedStorage.removeItem(camelToSnakeCase(key)));
	}

	onChange<T = any>(key: string): Observable<T> {
		return this.usedStorage.onChange(key);
	}

	get isV3CallServer(): boolean {
		return this.callServerVersion === 'unite3';
	}
}
