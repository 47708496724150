import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'n2p-switch',
	templateUrl: './switch.component.html',
	styleUrls: ['./switch.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SwitchComponent),
			multi: true,
		},
	],
})
export class SwitchComponent implements ControlValueAccessor {
	@Input() mini: boolean = false;
	value;
	onChange = value => {};
	onRegister = () => {};
	constructor() {}

	writeValue(val) {
		this.value = val;
		this.onChange(this.value);
	}

	registerOnChange(fn) {
		this.onChange = fn;
	}

	registerOnTouched(fn) {
		this.onRegister = fn;
	}

	switch() {
		this.writeValue(!this.value);
	}
}
