import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { DirectivesModule } from '@app/directives/directives.module';
import { AvatarModule, IconModule, SvgModule } from '@n2p';
import { AddForwardingNumberModule } from '@n2p/add-forwarding-number/add-forwarding-number.module';
import { DropdownModule } from '@n2p/dropdown';
import { IfCallNoAnswerDropdownComponent } from './if-call-no-answer-dropdown.component';
import { IfCallNoAnswerDataDropdownComponent } from './if-call-no-answer-data-dropdown/if-call-no-answer-data-dropdown.component';

@NgModule({
	declarations: [IfCallNoAnswerDropdownComponent, IfCallNoAnswerDataDropdownComponent],
	imports: [
		CommonModule,
		DropdownModule,
		ReactiveFormsModule,
		SvgModule,
		DirectivesModule,
		AvatarModule,
		IconModule,
		AddForwardingNumberModule,
		TranslateModule,
	],
	entryComponents: [IfCallNoAnswerDataDropdownComponent],
	exports: [IfCallNoAnswerDropdownComponent],
})
export class IfCallNoAnswerDropdownModule {}
