import { v4 as uuid } from 'uuid';

export const traceIdInterceptor = config => {
	return {
		...config,
		headers: {
			...(config.headers || {}),
			'x-client-trace-id': uuid()
				.toString()
				.replace(/-/g, ''),
		},
	};
};
