import {
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	HostListener,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
} from '@angular/forms';
import { parsePhoneNumber } from 'libphonenumber-js';
import { ICountry } from 'n2p-ui-library/utils/countries.domain';
import { CountryNumberUtil } from 'n2p-ui-library/utils/country-number.util';

const PLUS = '+';

const COUNTER_CONTROL_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => PhoneNumberComponent),
	multi: true,
};

const VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => PhoneNumberComponent),
	multi: true,
};

@Component({
	selector: 'n2p-international-phone-number',
	templateUrl: './phone-number.component.html',
	styleUrls: ['./phone-number.component.scss'],
	host: {
		'(document:click)': 'hideDropdown($event)',
	},
	providers: [COUNTER_CONTROL_ACCESSOR, VALIDATOR],
})
export class PhoneNumberComponent implements OnInit, ControlValueAccessor, Validator {
	// input
	@Input() placeholder = 'Enter phone number'; // default
	@Input() maxlength = 15; // default

	@Input() defaultCountry: string;
	@Input() required: boolean;
	@Input() allowDropdown = true;
	@Input() type = 'text';

	@Output() onCountryCodeChanged: EventEmitter<any> = new EventEmitter();
	@Output() toggleFocus: EventEmitter<void> = new EventEmitter<void>();

	// ELEMENT REF
	phoneComponent: ElementRef;

	// CONTROL VALUE ACCESSOR FUNCTIONS
	onTouch: Function;
	onModelChange: Function;

	countries: ICountry[] = [];
	selectedCountry: ICountry;
	countryFilter: string;
	showDropdown = false;
	phoneNumber = '';
	value = '';

	@ViewChild('phoneNumberInput', { static: true }) phoneNumberInput: ElementRef;

	constructor(phoneComponent: ElementRef) {
		this.phoneComponent = phoneComponent;
	}

	ngOnInit(): void {
		CountryNumberUtil.loadCountries().then(countries => {
			this.countries = countries;
			this.checkAndUpdateInput();
		});
	}

	/**
	 * Opens the country selection dropdown
	 */
	displayDropDown() {
		if (this.allowDropdown) {
			this.showDropdown = !this.showDropdown;
			this.countryFilter = '';
		}
	}

	/**
	 * Hides the country selection dropdown
	 * @param event
	 */
	hideDropdown(event: Event) {
		if (!this.phoneComponent.nativeElement.contains(event.target)) {
			this.showDropdown = false;
		}
	}

	/**
	 * Sets the selected country code to given country
	 * @param event
	 * @param countryCode
	 */
	updateSelectedCountry(event: Event, countryCode: string) {
		event.preventDefault();
		this.updatePhoneInput(countryCode);
		this.onCountryCodeChanged.emit(countryCode);
		this.updateValue();
		// focus on phone number input field
		setTimeout(() => this.phoneNumberInput.nativeElement.focus());
	}

	/**
	 * Updates the phone number
	 * @param event
	 */
	updatePhoneNumber(event: Event) {
		if (this.startsWithPlus(this.phoneNumber)) {
			this.findPrefix(this.phoneNumber.split(PLUS)[1]);
		} else {
			this.selectedCountry = null;
		}

		this.updateValue();
	}

	/**
	 * shows the dropdown with keyboard event
	 * @param event
	 */
	@HostListener('document:keypress', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
		if (this.showDropdown) {
			this.countryFilter = `${this.countryFilter}${event.key}`;
		}
	}

	/**
	 * @param prefix
	 */
	private findPrefix(prefix: string) {
		if (this.countries.length) {
			let foundPrefixes: ICountry[] = this.countries.filter((country: ICountry) => prefix.startsWith(country.dialCode));
			if (foundPrefixes && foundPrefixes.length) {
				this.selectedCountry = this.reducePrefixes(foundPrefixes);
			} else {
				this.selectedCountry = null;
			}
		} else {
			this.selectedCountry = null;
		}
	}

	/**
	 *
	 * @param fn
	 */
	registerOnTouched(fn: Function) {
		this.onTouch = fn;
	}

	/**
	 *
	 * @param fn
	 */
	registerOnChange(fn: Function) {
		this.onModelChange = fn;
	}

	/**
	 *
	 * @param value
	 */
	writeValue(value: string) {
		this.value = value || '';
		this.phoneNumber = this.value;

		this.checkAndUpdateInput();
	}

	/**
	 * Validation
	 * @param c
	 */
	validate(c: FormControl): ValidationErrors | null {
		let value = c.value;
		// let selectedDialCode = this.getSelectedCountryDialCode();
		let validationError: ValidationErrors = {
			phoneEmptyError: {
				valid: false,
			},
		};

		if (this.required && !value) {
			// if (value && selectedDialCode)
			//     value = value.replace(/\s/g, '').replace(selectedDialCode, '');

			// if (!value) return validationError;
			return validationError;
		}

		if (value) {
			// validating number using the google's lib phone
			try {
				let phoneNumber = parsePhoneNumber(value);
				let isValidNumber = phoneNumber.isValid();

				// should check length because the lib checks pass
				// when we have numbers like +11[0-9]{10} or +111[0-9]{10}
				// might be problem is in the "libphonenumber-js"?
				if (phoneNumber.country === 'US') isValidNumber = value.length < 13;
				return isValidNumber ? null : validationError;
			} catch (ex) {
				return validationError;
			}
		}
		return null;
	}

	/**
	 * Updates the value and trigger changes
	 */
	private updateValue() {
		this.value = this.phoneNumber.replace(/ /g, '');
		this.onModelChange(this.value);
		this.onTouch();
	}

	/**
	 * Updates the input
	 * @param countryCode
	 */
	private updatePhoneInput(countryCode: string): void {
		this.showDropdown = false;

		let newInputValue: string;

		if (!this.selectedCountry) newInputValue = '';
		else {
			newInputValue = this.startsWithPlus(this.phoneNumber)
				? `${this.phoneNumber.split(PLUS)[1].substr(this.selectedCountry.dialCode.length, this.phoneNumber.length)}`
				: this.phoneNumber;
		}

		this.selectedCountry = this.countries.find((country: ICountry) => country.countryCode === countryCode);
		if (this.selectedCountry) {
			this.phoneNumber = `${PLUS}${this.selectedCountry.dialCode} ${newInputValue.replace(/ /g, '')}`;
		} else {
			this.phoneNumber = `${newInputValue.replace(/ /g, '')}`;
		}
	}

	/**
	 * Util function to check if given text starts with plus sign
	 * @param text
	 */
	private startsWithPlus(text: string): boolean {
		return text.startsWith(PLUS);
	}

	/**
	 * Reduced the prefixes
	 * @param foundPrefixes
	 */
	private reducePrefixes(foundPrefixes: ICountry[]): ICountry {
		return foundPrefixes.reduce((first: ICountry, second: ICountry) =>
			first.dialCode.length > second.dialCode.length ? first : second,
		);
	}

	private checkAndUpdateInput(): void {
		if (this.startsWithPlus(this.value)) {
			this.findPrefix(this.value.split(PLUS)[1]);
			if (this.selectedCountry) {
				this.updatePhoneInput(this.selectedCountry.countryCode);
			}
		}

		if (this.defaultCountry) {
			this.updatePhoneInput(this.defaultCountry);
		}
	}
}
