import { Component, Input, Inject, OnInit } from '@angular/core';

import { MULTISELECT_CHIP, MULTISELECT_CHIP_CLICK_CALLBACK } from 'utils/constants/injectortokens';
import { IPhone } from '@app/Common/interfaces/IPhone';

@Component({
	selector: 'app-caller-id-chip',
	templateUrl: './caller-id-chip.component.html',
	styleUrls: ['./caller-id-chip.component.scss'],
})
export class CallerIdChipComponent implements OnInit {
	@Input() userId: any;
	phone: IPhone;
	clickCallback: any;
	constructor(@Inject(MULTISELECT_CHIP) phone: IPhone, @Inject(MULTISELECT_CHIP_CLICK_CALLBACK) clickCallback: any) {
		this.phone = phone;
		this.clickCallback = clickCallback;
	}

	close = () => {
		this.clickCallback(this.phone);
	};

	ngOnInit() {}
}
