import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { combineLatest } from 'rxjs';

import { environment } from '@env/environment';

import { LeftSideNavService } from '@n2p/left-side-nav/left-side-nav.service';
import {
	FeatureFlagsService,
	AuthStorageData,
	UserAgentService,
	CommonService,
	GlobalEventsService,
	LicensesService,
	JwtService,
} from '@app/services';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { tap } from 'rxjs/operators';

@Component({
	selector: 'n2p-left-side-nav',
	templateUrl: './left-side-nav.component.html',
	styleUrls: ['./left-side-nav.component.scss'],
})
export class LeftSideNavComponent implements OnInit, OnDestroy {
	currentUrl: string;
	isWideScreen: boolean;
	isCallServerV3User: boolean;
	isEnableCallHistory30: boolean;
	isCallQueuesLicenseEnabled: boolean;
	isPhoneNumbersMenuEnabled: boolean;

	subscription: Subscription = new Subscription();

	isAnalyticsPageEnabled: Observable<boolean> = this.featureFlagsService.isAnalyticsPageEnabled();
	isSamlEnabled: Observable<boolean> = this.featureFlagsService.saml();
	isEditAuthEnabled: Observable<boolean> = this.featureFlagsService.isEdit2FAEnabled();
	isTermsAndPoliciesEnabled: Observable<boolean> = this.featureFlagsService.termsAndPolicies();
	isSipTrunkingEnabled: boolean = this.jwtService.hasPolicy('siptrunk') && this.commonService.isAdmin();
	isSipTrunkingApp: boolean = this.jwtService.hasPolicy('siptrunk.account.only');
	isImpersonated: boolean = this.jwtService.isImpersonated();
	public isAdmin: boolean = this.commonService.isAdmin();

	constructor(
		public leftSideNavService: LeftSideNavService,
		private router: Router,
		private authStorageDataService: AuthStorageData,
		private jwtService: JwtService,
		private featureFlagsService: FeatureFlagsService,
		private userAgentService: UserAgentService,
		private tenantService: ApiTenantService,
		private commonService: CommonService,
		private licensesService: LicensesService,
		private globalEventsService: GlobalEventsService,
	) {}

	@HostListener('window:resize', ['$event']) getScreenSize(): void {
		const WIDE_SCREEN_WIDTH = 1680; //value depends on media query in scss file

		this.isWideScreen = window.innerWidth >= WIDE_SCREEN_WIDTH;
	}

	ngOnInit(): void {
		this.isCallServerV3User = this.authStorageDataService.isV3CallServer;
		this.featureFlagsService.enableCallHistory30().subscribe(result => (this.isEnableCallHistory30 = result));
		this.licensesService.getLicensesWithPrivate().subscribe(result => {
			this.isCallQueuesLicenseEnabled = result.some(license => license.licenseCode === 'customer.callqueue');
		});
		this.featureFlagsService
			.phoneNumbersMenuEnabled()
			.pipe(tap(enabled => (this.isPhoneNumbersMenuEnabled = enabled)))
			.subscribe();

		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.currentUrl = (event.url || '').split('?')[0];
			}
		});

		this.currentUrl = (this.router.url || '').split('?')[0];

		this.getScreenSize();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	get shouldAnalyticsBeHidden(): boolean {
		if (this.isSipTrunkingApp) return true;
	}

	get shouldDashboardBeHidden(): boolean {
		if (environment.is_admin_app) return true;
		if (this.isSipTrunkingApp) return true;
	}

	get shouldCallHistoryBeHidden(): boolean {
		if (this.isSipTrunkingApp) return true;
	}

	get shouldMessengerBeHidden(): boolean {
		let isRestricted: boolean;
		if (environment.is_pam_console || environment.is_admin_app) return true;
		if (this.isSipTrunkingApp) return true;

		if (!this.userAgentService.isBrowser()) return true;
		if (this.jwtService.hasScope('impersonate')) return !this.jwtService.hasScope('messenger');
		this.subscription.add(
			combineLatest([
				this.tenantService.isBrazilAccount$(),
				this.tenantService.isMexicoAccount$(),
				this.tenantService.isArgentinaAccount$(),
			]).subscribe(([isBrazil, isMexico, isArgentina]) => (isRestricted = isBrazil || isMexico || isArgentina)),
		);
		return isRestricted || false;
	}

	get isUsedInBrowser(): boolean {
		return this.userAgentService.isBrowser();
	}

	get isSettingsIconShown(): Observable<boolean> | boolean {
		if (!this.isUsedInBrowser) return false;
		if (!this.isSipTrunkingApp) return true;

		return this.isTermsAndPoliciesEnabled || this.isSamlEnabled || this.isEditAuthEnabled;
	}

	get settingsRouterLink(): string {
		if (!this.isSipTrunkingApp) {
			return '/settings/schedules';
		}

		if (this.isTermsAndPoliciesEnabled) {
			return '/settings/termsandpolicies';
		}

		if (this.isSamlEnabled) {
			return '/settings/saml';
		}

		if (this.isEditAuthEnabled) {
			return '/settings/edit-auth';
		}
	}

	get iconParamsLogo() {
		const isOctober = new Date().getMonth() === 9;
		return {
			name: isOctober ? 'logo-us-october' : 'logo-us',
			height: isOctober ? 57 : 42,
			width: 199,
		};
	}
}
