import { FeaturesInfo } from '@app/services/feature-flags/features.domain';

export interface INewRelicAnalyticsData {
	userId: number;
	accountId: number;
	userType: string;
}

export interface IGoogleAnalyticsData {
	accountId: number;
	accountName: string;
	userId: number;
	userType: string;
	userRole: string;
	impersonate: boolean;
	tenantId: string;
	uniteCountry: string;
	browserId: string;
}

export enum IUserType {
	STANDARD = 'standard',
	REMOTE = 'remote',
}

export const userTypeFromUser = (user: { canCreatePhysicalDevices: boolean }): IUserType => {
	return user.canCreatePhysicalDevices ? IUserType.STANDARD : IUserType.REMOTE;
};
