import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-edit-team-member-warning',
	templateUrl: './edit-team-member-warning.component.html',
})
export class EditTeamMemberWarningComponent implements OnInit {
	constructor() {}
	ngOnInit(): void {}
}
