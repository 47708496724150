import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';

const PHONE_NUMBER_REGEX = /(\+\d{1,3} ?\(\d+\) ?)?(\d+ ?- ?)+\d+/g;
const EMAIL_REGEX = /\S+@\S+\.\S+/g;

@Component({
	selector: 'app-no-permission',
	templateUrl: './no-permissions.component.html',
	styleUrls: ['./no-permissions.component.scss'],
})
export class NoPermissionsComponent {
	routeParam: string;
	countryCode: string = this.tenantService.countryCode;
	title: string;
	subTitle: string;
	isRCON: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private tenantService: ApiTenantService,
	) {
		this.route.queryParams.subscribe(p => {
			this.title = this.translateService.instant('NO_PERMISSIONS_PAGE.TITLE', { page: p.route });
			this.subTitle = this.transformToTextWithLinks(
				this.translateService.instant(`NO_PERMISSIONS_PAGE.SUPPORT_${this.countryCode}`),
			);
			this.routeParam = p.route;

			if (p.route === 'receptionist-console') {
				this.isRCON = true;
			}
		});
	}

	private removeCountryCodeAndBraces = (phoneNumber: string): string => {
		return phoneNumber.replace(/ |\+1|\)|\(/g, match => {
			return match === ')' ? '-' : '';
		});
	};

	private transformToTextWithLinks = (text: string): string => {
		const textWithRef = text
			.replace(PHONE_NUMBER_REGEX, phone => {
				return `<a href="tel:${this.removeCountryCodeAndBraces(phone)}">${phone}</a>`;
			})
			.replace(EMAIL_REGEX, email => {
				return `<a href="mailto:${email}">${email}</a>`;
			});

		return `<div>${textWithRef}</div>`;
	};
}
