import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { AudioPlayerService } from '@app/services/audio-player/audio-player.service';

@Component({
	selector: 'app-audio-player',
	templateUrl: './audio-player.component.html',
	styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit, OnDestroy {
	@Input() id: string;
	@Input() size = 36;
	@Input() audioType = 'welcome-menu';

	isPlaying = false;
	isLoading = true;
	audio = new Audio();
	playerId = '';

	subscription: Subscription;

	@HostListener('window:resize') onResize() {
		this.pauseAudio();
	}

	constructor(private audioPlayerService: AudioPlayerService) {}

	ngOnInit() {
		this.loadAudio();

		this.playerId = `${this.audioType}-${this.id}`;

		this.subscription = this.audioPlayerService.playerStarted$.subscribe(playerId => {
			if (playerId !== this.playerId) {
				this.pauseAudio();
			}
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	audioState(): string {
		if (this.isLoading) {
			return 'loading';
		}
		if (this.isPlaying) {
			return 'playing';
		}
		return 'paused';
	}

	icon(): string {
		let icon = '';

		switch (this.audioState()) {
			case 'loading':
				icon = 'progress';
				break;
			case 'playing':
				icon = 'pauseIcon';
				break;
			case 'paused':
				icon = 'play';
				break;
			default:
				throw Error(`Ivalid audio state!`);
		}
		return icon;
	}

	onClick() {
		this.isPlaying ? this.pauseAudio() : this.playAudio();
	}

	playAudio = () => {
		this.audioPlayerService.startPlayer(this.playerId);
		this.audio.play();
		this.isPlaying = true;
	};

	pauseAudio() {
		this.audio.pause();
		this.isPlaying = false;
	}

	loadAudio() {
		this.isLoading = true;

		let loadFunction;
		let audioSource;
		const audioPrefix = 'data:audio/mp3;base64,';

		switch (this.audioType) {
			case 'welcome-menu':
				loadFunction = this.audioPlayerService.getWelcomeMenu;
				break;
			default:
				throw Error(`The type ${this.audioType} doesn't have a mapping into the load function`);
		}

		loadFunction(this.id).subscribe(result => {
			if (!result.hasError) {
				switch (this.audioType) {
					case 'welcome-menu':
						audioSource = result.data[0].file;
						break;
					default:
						throw Error(`The type ${this.audioType} doesn't have a mapping into the audio source`);
				}

				this.audio.addEventListener('ended', () => {
					this.isPlaying = false;
					this.audio.currentTime = 0;
				});

				this.audio.src = `${audioPrefix}${audioSource}`;
			}

			this.isLoading = false;
		});
	}
}
