import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { ApiExtensionsService } from '@app/services';
import { ExtensionTypes } from '@app/services/web-apis/extensions/api-extensions.service';

import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { ValidationExceptions } from '@app/Common/constants/errors';

@Directive({
	selector: '[n2p-input-extension]',
})
export class InputExtensionDirective implements OnInit, OnDestroy {
	@Input('n2p-input-extension') type: ExtensionTypes;
	@Input() refresh: Subject<any>;
	@Output() loading: EventEmitter<boolean> = new EventEmitter();
	@Output() changeAndValid: EventEmitter<void> = new EventEmitter<void>();

	private subscription: Subscription;
	private initialValue: string;

	constructor(private form: NgControl, private apiExtensions: ApiExtensionsService) {}

	ngOnInit(): void {
		if (!this.control.value) {
			this.getNextExtension();
		} else {
			// save initial value
			this.initialValue = this.control.value;
			this.subscribeToChange();
		}

		if (this.refresh) {
			this.refresh.subscribe(() => this.getNextExtension());
		}
	}

	ngOnDestroy(): void {
		if (this.subscription) this.subscription.unsubscribe();
	}

	// reference to control
	get control(): AbstractControl {
		return this.form.control;
	}

	private getNextExtension(): void {
		this.loading.emit(true);
		this.control.setValue('');
		this.apiExtensions.getNextExtension(this.type).subscribe(res => {
			if (!res.hasError) {
				this.control.setValue(res.data, { emitEvent: false });
				if (!this.subscription) this.subscribeToChange();
				this.changeAndValid.emit();
			}
			this.loading.emit(false);
		});
	}

	private validateExtension(): void {
		// allow reset to initial value
		if (this.control.value === this.initialValue) {
			this.changeAndValid.emit();
			return;
		}

		this.loading.emit(true);
		this.apiExtensions.validateExtension(this.control.value).subscribe(res => {
			const { hasError = false, errorMessages = [{ message: '', code: '' }] } = res;

			if (hasError) {
				const [firstError] = errorMessages;

				switch (firstError.code) {
					case ValidationExceptions.extensionInUse: {
						this.control.setErrors({ inUse: true });
						break;
					}
					case ValidationExceptions.extensionUnsupported: {
						this.control.setErrors({ unsupported: true });
						break;
					}
					default: {
						this.control.setErrors({ invalid: true });
					}
				}
			} else {
				this.changeAndValid.emit();
			}
			this.loading.emit(false);
		});
	}

	private subscribeToChange(): void {
		this.subscription = this.control.valueChanges.debounceTime(500).subscribe(value => {
			if (value) this.validateExtension();
		});
	}
}
