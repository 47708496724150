import { Injectable } from '@angular/core';

import { DateFormatterService, FORMATS } from '@app/services/date-formatter/date-formatter.service';

@Injectable()
export class ScheduleService {
	constructor(private dateFormatter: DateFormatterService) {}

	getRuleDays = (rule, all = false, max = 2): string => {
		let dayList = '';

		if (rule && rule.days) {
			const days = rule.days;
			if (days.weekDays && days.weekDays.length > 0) {
				if (days.isRange) {
					dayList = this.getDayOfWeekRange(days.weekDays);
				} else {
					days.weekDays.forEach(d => {
						if (dayList) {
							dayList += ', ';
						}
						dayList += this.getDayOfWeek(d);
					});
				}
			} else if (days.dates && days.dates.length > 0) {
				if (days.isRange) {
					const from = this.dateFormatter.format(days.dates[0], FORMATS.DATE_MED);
					const to = this.dateFormatter.format(days.dates[days.dates.length - 1], FORMATS.DATE_MED);
					dayList = `${from} - ${to}`;
				} else {
					let i = 0;
					days.dates.forEach(d => {
						if (i < max || all) {
							if (dayList) {
								dayList += ', ';
							}
							dayList += this.dateFormatter.format(d, FORMATS.DATE_MED);
						} else if (i === max) {
							dayList += '...';
						}

						i += 1;
					});
				}
			}
		}

		return dayList;
	};

	dayOfWeekIsRange(days = []): boolean {
		if (days.length < 3) return false;
		let current = days[0];
		const allowedChances = current === 1 && days[days.length - 1] === 7 ? 2 : 1;
		let chances = 0;
		for (let i = 1; i < days.length; i++) {
			if (days[i] - current !== 1) {
				chances++;
				if (chances === allowedChances) return false;
			}
			current = days[i];
		}

		return true;
	}

	getTimeStr(time: { start: string; end: string }): string {
		if (
			time.start
				.toLowerCase()
				.split(' ')
				.join() === '12:00am' &&
			time.end
				.toLowerCase()
				.split(' ')
				.join() === '11:59pm'
		) {
			return 'All Day';
		}

		return `${time.start} - ${time.end}`;
	}

	getDayOfWeekRange = (days): string => {
		// the assumption is that that days WILL be in a range
		// the days are in order from smallest to greatest
		let start: number = days[0]; //start with number 1
		let end: number = days[0]; //start with number 1

		let upto = 0;
		let done = false;
		days.forEach(d => {
			if (!done) {
				if (d === start + upto) {
					//keep adding 1 to the value and see if we are consecutive
					end = d;
				} else {
					//there is a gap that must mean that
					//the number we just got is the end of the range
					//the next number is the beginning of the range
					end = upto;
					start = d;
					done = true;
				}
				upto += 1;
			}
		});

		return `${this.getDayOfWeek(start)} - ${this.getDayOfWeek(end)}`;
	};

	getDayOfWeek = (day: number): string => {
		//day 1 = sunday, 2 = monday .....
		return this.dateFormatter.weekdays[day - 1];
	};
}
