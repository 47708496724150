import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ImpersonatedBannerComponent } from './impersonated-banner.component';
import { SvgModule } from '@app/n2p-components/svg';

@NgModule({
	declarations: [ImpersonatedBannerComponent],
	imports: [CommonModule, SvgModule, TranslateModule],
	exports: [ImpersonatedBannerComponent],
})
export class ImpersonatedBannerModule {}
