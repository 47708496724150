import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

import { PromptService } from '@app/services';
import {
	DROPDOWN_ADDITIONAL_DATA,
	DROPDOWN_DATA,
	DROPDOWN_DATA_CONFIG,
	DROPDOWN_DATA_FILTER,
	DROPDOWN_PAGE,
	DROPDOWN_PAGE_NEXT,
} from '@n2p/dropdown/dropdown.injectors';
import { matchSearchString } from '@utils/helpers/functions';

import { Decision } from '@n2p/prompt/promptRef';

type Entity = 'team-member' | 'department' | 'ring-group' | 'welcome-menu';

@Component({
	selector: 'n2p-assigned-to-dropdown-data',
	templateUrl: './assigned-to-dropdown-data.component.html',
	styleUrls: ['./assigned-to-dropdown-data.component.scss'],
})
export class AssignedToDropdownDataComponent implements OnInit {
	entityControl: FormControl;
	pageSubject: Observable<number>;
	entityToShow: string;
	searchStr: string;
	entities = [
		{ type: 'team-member', title: 'ASSIGNED_TO_DROPDOWN.TEAM_MEMBERS' },
		{ type: 'department', title: 'ASSIGNED_TO_DROPDOWN.DEPARTMENTS' },
		{ type: 'ring-group', title: 'ASSIGNED_TO_DROPDOWN.RING_GROUPS' },
		{ type: 'welcome-menu', title: 'ASSIGNED_TO_DROPDOWN.WELCOME_MENUS' },
		{ type: 'special-extension', title: 'ASSIGNED_TO_DROPDOWN.SPECIAL_EXTENSIONS' },
		{ type: 'call-queue', title: 'ASSIGNED_TO_DROPDOWN.CALL_QUEUE' },
	];
	singleLevel: boolean;
	availableEntityTypes: Array<any>;
	confirmReassign: boolean;
	showDeleteOption: boolean;
	constructor(
		@Inject(DROPDOWN_DATA) data: any,
		@Inject(DROPDOWN_PAGE) page: Observable<number>,
		@Inject(DROPDOWN_PAGE_NEXT) private nextPage: any,
		@Inject(DROPDOWN_DATA_CONFIG) private config: any,
		@Inject(DROPDOWN_ADDITIONAL_DATA) private additionalData: any = {},
		@Inject(DROPDOWN_DATA_FILTER) private searchControl: FormControl,
		private promptService: PromptService,
	) {
		this.pageSubject = page;
		this.entityControl = data;
		this.singleLevel = additionalData.singleLevel;

		if (additionalData.availableEntityTypes) {
			this.availableEntityTypes = this.entities.filter(entity => {
				return additionalData.availableEntityTypes.find(element => element === entity.type);
			});
		}
		this.confirmReassign = additionalData.confirmReassign;
		this.showDeleteOption = additionalData.showDeleteOption;
	}

	get entityData(): Array<any> {
		return this.searchStr
			? this.entityControl.value.filter(i =>
					matchSearchString(this.searchStr, [i.value, i.subValue, i.number, i.extension]),
			  )
			: this.singleLevel
			? this.entityControl.value
			: this.entityControl.value.filter(i => i.type === this.entityToShow);
	}

	hasEntity(entityType): boolean {
		return this.entityControl.value.find(i => i.type === entityType);
	}

	showEntityOptions = (entity: Entity): void => {
		this.entityToShow = entity;
		this.nextPage();
	};

	itemClicked(item): void {
		if (this.confirmReassign) {
			this.promptService.showReassignDialog(item).then(decision => {
				if (decision === Decision.CANCEL) return;
				this.handleSelection(item);
			});
		} else {
			this.handleSelection(item);
		}
	}

	handleSelection(item): void {
		const selected = !item.selected;
		if (this.config.singleSelection) {
			this.clearAllSelected();
		}
		item.selected = selected;
		this.entityControl.updateValueAndValidity();
	}

	unassignedClicked = (): void => {
		this.clearAllSelected();
		this.entityControl.updateValueAndValidity();
	};

	deleteClicked = (): void => {
		const index = this.entityControl.value.findIndex(i => i.type === 'delete');
		if (index !== -1) {
			this.clearAllSelected();
			this.entityControl.value[index].selected = true;
			this.entityControl.updateValueAndValidity();
		}
	};

	ngOnInit(): void {
		this.searchControl.valueChanges.subscribe(value => (this.searchStr = value));
	}

	private clearAllSelected(): void {
		for (const entity of this.entityControl.value) {
			entity.selected = false;
		}
	}
}
