import { Pipe, PipeTransform } from '@angular/core';

import { FileSizeLevels } from '@app/Common/constants/common';

@Pipe({
	name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
	transform(value: number): string {
		if (value < FileSizeLevels.KILOBYTE) {
			return `${value}B`;
		} else if (value < FileSizeLevels.MEGABYTE) {
			return `${Math.floor(value / FileSizeLevels.KILOBYTE)}KB`;
		} else if (value < FileSizeLevels.GIGABYTE) {
			return `${Math.floor(value / FileSizeLevels.MEGABYTE)}MB`;
		}
		return `${Math.floor(value / FileSizeLevels.GIGABYTE)}GB`;
	}
}
