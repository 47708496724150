export enum IBlockedNumberLevel {
	ALL = 'all',
	MINE = 'mine',
}

export interface IBlockAnonymousCallers {
	IsActive: boolean;
	Level: IBlockedNumberLevel;
}

export interface IBlockedNumber {
	Id: number;
	Number: string;
	Description: string;
	Level: IBlockedNumberLevel;
	isMine?: boolean;
}

export interface ICallBlockingData {
	BlockAnonymousCallers: IBlockAnonymousCallers;
	BlockedNumbers: IBlockedNumber[];
	Total: number;
}

export interface ICallBlockingDataExtended extends ICallBlockingData {
	pageNumber: number;
	searchQuery: string;
	pageSize: number;
}

export const DEFAULT_CALL_BLOCKING_DATA: ICallBlockingDataExtended = {
	BlockAnonymousCallers: {
		IsActive: true,
		Level: IBlockedNumberLevel.MINE,
	},
	BlockedNumbers: [],
	Total: 0,
	pageNumber: 0,
	searchQuery: '',
	pageSize: 0,
};

export interface IEventBlockNumberBase {
	phoneNumber: string;
	onSuccess?: (blockedNumber: IBlockedNumber) => void;
	onFail?: () => void;
	onCancel?: () => void;
}

export interface IEventBlockNumber extends IEventBlockNumberBase {
	phoneNumber: string;
}

export interface IEventUnblockNumber extends IEventBlockNumberBase {
	blockedNumber: IBlockedNumber;
}
